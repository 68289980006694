import {
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import Loader from "../../../../components/loader";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  statusContext,
  archivedStatusContext,
  switchStatusContext,
} from "../../../../context/serviceContext";
import Services from "../../../../api/services";
import { useLocation } from "react-router-dom";
import useToast from "../../../../hooks/useToast";
import { regexPatterns } from "../../../../config/regex";

export default function ApkConfig({
  refreshApi,
  appDetailsRefresh,
  sendDataToDetailPage,
}) {
  const { showSuccess, showError } = useToast();

  const [clickDisable, setClickDisable] = useState(true);
  const [cancelDisable, setCancelDisable] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [load, setLoad] = useState(false);
  const [clientVersionList, setClientVersionList] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [configData, setConfigData] = useState({
    configName: "",
    description: "",
    platform: "",
    googleAppId: "",
    packageSuffix: "DEV",
    deploymentType: "DEV",
    distributionEmail: "",
    gatewaydomain: "",
    piwikSdk: false,
    locationService: false,
    storagePermission: false,
  });

  const [configError, setConfigError] = useState({
    configName: false,
    configNameMsg: "",
    description: false,
    descriptionMsg: "",
    googleAppId: false,
    googleAppIdMsg: "",
    platform: false,
    platformMsg: "",

    packageSuffix: false,
    packageSuffixMsg: "",
    distributionEmail: false,
    distributionEmailMsg: "",
    gatewaydomain: false,
    gatewaydomainMsg: "",
  });

  const areAllFieldsFilled =
    configData.configName !== "" &&
    configData.description !== "" &&
    configData.platform !== "" &&
    configData.distributionEmail !== "" &&
    configError.distributionEmailMsg === "" &&
    configError.configNameMsg === "" &&
    configError.descriptionMsg === "";

  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const [clientVersionListLoaded, setClientVersionListLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const selectedAppId = location.state.clickedAppId;

  const handleInputChange = (e) => {
    const newData = { ...configData };
    newData[e.target.name] = e.target.value;
    setCancelDisable(false);

    switch (e.target.name) {
      case "configName":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setConfigError({
            ...configError,
            configName: true,
            configNameMsg: "This field is required",
          });
          setClickDisable(true);
        } else {
          if (!regexPatterns.appConfigRegex.test(e.target.value)) {
            setConfigError({
              ...configError,
              configName: true,
              configNameMsg: "No special characters are allowed",
            });
            setClickDisable(true);
          } else if (!regexPatterns.appNameLengthRegex.test(e.target.value)) {
            setConfigError({
              ...configError,
              configName: true,
              configNameMsg: " Cannot exceed more than 32 characters ",
            });
            setClickDisable(true);
          } else {
            setConfigError({
              ...configError,
              configName: false,
              configNameMsg: "",
            });
            setClickDisable(false);
          }
        }
        break;

      case "description":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setConfigError({
            ...configError,
            description: true,
            descriptionMsg: "This field is required",
          });
          setClickDisable(true);
        } else {
          if (!regexPatterns.descriptionRegex.test(e.target.value)) {
            setConfigError({
              ...configError,
              description: true,
              descriptionMsg: "No special characters are allowed",
            });
            setClickDisable(true);
          } else if (
            !regexPatterns.descriptionLengthRegex.test(e.target.value)
          ) {
            setConfigError({
              ...configError,
              description: true,
              descriptionMsg: "Cannot exceed more than 500 characters ",
            });
            setClickDisable(true);
          } else {
            setConfigError({
              ...configError,
              description: false,
              descriptionMsg: "",
            });
            setClickDisable(false);
          }
        }
        break;
      case "googleAppId":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setConfigError({
            ...configError,
            googleAppId: true,
            googleAppIdMsg: "This field is required",
          });
        } else {
          setConfigError({
            ...configError,
            googleAppId: false,
            googleAppIdMsg: "",
          });
          configData?.googleAppId !== e.target.value
            ? setClickDisable(true)
            : setClickDisable(false);
        }
        break;

      case "platform":
        if (e.target.value === "") {
          setConfigError({
            ...configError,
            platform: true,
            platformMsg: "This field is required",
          });
          setClickDisable(true);
        } else {
          setConfigError({
            ...configError,
            platform: false,
            platformMsg: "",
          });
          setClickDisable(false);
        }
        break;

      case "packageSuffix":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setConfigError({
            ...configError,
            packageSuffix: true,
            packageSuffixMsg: "This field is required",
          });
          setClickDisable(true);
        } else {
          setConfigError({
            ...configError,
            packageSuffix: false,
            packageSuffixMsg: "",
          });
          setClickDisable(false);
        }
        break;

      case "gatewaydomain":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setConfigError({
            ...configError,
            gatewaydomain: true,
            gatewaydomainMsg: "This field is required",
          });
          setClickDisable(true);
        } else {
          setConfigError({
            ...configError,
            gatewaydomain: false,
            gatewaydomainMsg: "",
          });
          setClickDisable(false);
        }
        break;

      case "distributionEmail":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setConfigError({
            ...configError,
            distributionEmail: true,
            distributionEmailMsg: "This field is required",
          });
          setClickDisable(true);
        } else {
          if (!regexPatterns.emailRegex.test(e.target.value)) {
            setConfigError({
              ...configError,
              distributionEmail: true,
              distributionEmailMsg: "Enter a valid list of email addresses",
            });
            setClickDisable(true);
          } else {
            setConfigError({
              ...configError,
              distributionEmail: false,
              distributionEmailMsg: "",
            });
            setClickDisable(false);
          }

          const distributionEmailList = newData.distributionEmail
            .replace(/,\s+/g, ",")
            .split(",");
          distributionEmailList.pop();
          const enteredEmail = e.target.value.split(",");
          if (
            distributionEmailList.includes(
              enteredEmail[enteredEmail.length - 1].trim()
            )
          ) {
            newData[e.target.name] = distributionEmailList;
          }
        }
        break;

      default:
        break;
    }

    setConfigData(newData);
  };

  const handleChange = (event) => {
    setCancelDisable(false);
    const { name, checked } = event.target;

    switch (name) {
      case "piwikSdk":
        setConfigData({
          ...configData,
          piwikSdk: checked ? ["piwik sdk"] : "",
        });
        setClickDisable(false);
        break;

      case "locationService":
        setConfigData({
          ...configData,
          locationService: checked ? 1 : 0,
        });
        setClickDisable(false);
        break;

      case "storagePermission":
        setConfigData({
          ...configData,
          storagePermission: checked ? 1 : 0,
        });
        setClickDisable(false);
        break;

      case "videoAdvertising":
        setConfigData({
          ...configData,
          videoAdvertising: checked ? 1 : 0,
        });
        setClickDisable(false);
        break;

      default:
        break;
    }
  };

  const handleSave = () => {
    const type = "DEV";
    const applicationId = selectedAppId;
    let payload = {
      name: configData?.configName,
      description: configData?.description,
      googleAppId: configData?.googleAppId,

      distributionEmail: configData?.distributionEmail,
      includeSdks: configData?.piwikSdk ? configData?.piwikSdk : null,
      locationPermission: configData?.locationService,
      storagePermission: configData?.storagePermission,
      platform: configData?.platform,
      applicationType: "wrap",
    };
    setLoad(true);

    setDisableButton(true);
    Services.updateWrapApkConfig(type, applicationId, payload)
      .then((res) => {
        if (res?.data.status === "success") {
          showSuccess(res?.data.message);
          setLoad(false);
          setDisableButton(false);
          appDetailsRefresh(true);
        } else {
          showError(res?.data.message);
          setLoad(false);
          setDisableButton(false);
          appDetailsRefresh(true);
        }
        setClickDisable(false);
        handleCancel();
      })
      .catch((err) => {});
    appDetailsRefresh(false);
  };
  const handleCancel = () => {
    setConfigError({
      configName: false,
      configNameMsg: "",
      description: false,
      descriptionMsg: "",
      platform: false,
      platformMsg: "",
      googleAppId: false,
      googleAppIdMsg: "",

      distributionEmail: false,
      distributionEmailMsg: "",
    });
    getWrapApkConfig();
    setLoad(false);
    setClickDisable(true);
    setDisableButton(false);
    setCancelDisable(true);
  };

  async function getWrapApkConfig() {
    setLoading(true);
    Services.getWrapApkConfig({
      applicationId: selectedAppId,
      deploymentType: "DEV",
    })
      .then((res) => {
        if (res?.status === 200) {
          sendDataToDetailPage(res?.data);
          setConfigData({
            ...configData,
            configName: res?.data?.name ? res?.data?.name : "",
            description: res?.data?.description ? res?.data?.description : "",
            platform:
              res?.data?.platform === null
                ? value
                : res?.data?.platform === undefined
                ? value
                : res?.data?.platform === ""
                ? value
                : res?.data?.platform,

            distributionEmail: res?.data?.distributionEmail
              ? res?.data?.distributionEmail
              : "",
            locationService: res?.data?.locationPermission
              ? res?.data?.locationPermission
              : 0,
            storagePermission: res?.data?.storagePermission
              ? res?.data?.storagePermission
              : 0,

            piwikSdk: res?.data?.includeSdks ? [res?.data?.includeSdks] : "",
            googleAppId: res?.data.googleAppId,
          });
          if (!res?.data?.platform) {
            setClickDisable(false);
          }
          setLoading(false);
        }
      })
      .catch((err) => {});
  }
  const [value, setValue] = useState();

  async function getClientVersionDetails() {
    try {
      const res = await Services.clientVersionsList({
        productId: "1",
        deploymentType: "DEV",
      });
      if (res?.status === 200 && res.data.length > 0) {
        setClientVersionList(res?.data);
        setClientVersionListLoaded(true);
        setValue(res.data[0].id);
      }
    } catch (err) {}
  }

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (refreshApi) {
      setTriggerRefresh(true);
    }
  }, [refreshApi]);

  useEffect(() => {
    if (triggerRefresh) {
      setLoading(true);
      getWrapApkConfig();
      setTriggerRefresh(false);
    }
    // eslint-disable-next-line
  }, [triggerRefresh]);

  useEffect(() => {
    getWrapApkConfig();
    getClientVersionDetails();
    // eslint-disable-next-line
  }, [value]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" mt="2px">
        {loading ? (
          <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
        ) : null}
      </Box>
      <Box
        sx={{
          display: { xs: "grid", md: "flex" },
          gap: "40px",
        }}
      >
        <>
          <Box
            sx={{
              width: {
                xs: "50px",
                sm: "366px",
                md: "400px",
                lg: "400px",
                xl: "500px",
              },
            }}
          >
            <TextField
              sx={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                "& input::placeholder": {
                  color: "#757373",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  opacity: "1",
                  fontWeight: "400",
                },
              }}
              InputProps={{
                style: {
                  fontSize: 16,
                  fontWeight: 400,
                  letterSpacing: 0.5,
                  color: "rgba(0, 0, 0, 0.87)",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
              value={
                configData?.configName !== null &&
                configData?.configName !== undefined
                  ? configData?.configName
                  : ""
              }
              error={!!configError?.configName}
              onChange={handleInputChange}
              name="configName"
              placeholder="Name"
              variant="standard"
              helperText={
                configError?.configName ? configError?.configNameMsg : ""
              }
            />
            {configData?.configName && (
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#01537A",
                }}
              >
                Name
              </Typography>
            )}
            <TextField
              sx={{
                width: "100%",
                marginBottom: "10px",
                marginTop: "10px",
                "& input::placeholder": {
                  color: "#757373",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  opacity: "1",
                  fontWeight: "400",
                },
              }}
              error={!!configError?.description}
              onChange={handleInputChange}
              InputProps={{
                style: {
                  fontSize: 16,
                  fontWeight: 400,
                  letterSpacing: 0.5,
                  color: "rgba(0, 0, 0, 0.87)",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
              placeholder="Description"
              name="description"
              variant="standard"
              value={
                configData?.description !== null &&
                configData?.description !== undefined
                  ? configData?.description
                  : ""
              }
              helperText={
                configError?.description ? configError?.descriptionMsg : ""
              }
            />
            {configData?.description && (
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#01537A",
                }}
              >
                Description
              </Typography>
            )}
            <FormControl
              variant="standard"
              sx={{ width: "100%", marginTop: "15px" }}
            >
              {clientVersionListLoaded ? (
                <Select
                  inputProps={{
                    style: {
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  inputlabelprops={{
                    style: { fontSize: 16, fontWeight: 400 },
                  }}
                  onChange={handleInputChange}
                  value={configData?.platform || ""}
                  error={configError?.platform}
                  name="platform"
                >
                  {clientVersionList.length > 0 ? (
                    clientVersionList?.map((item) => (
                      <MenuItem value={item?.id} key={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No data found</MenuItem>
                  )}
                </Select>
              ) : (
                ""
              )}

              <FormHelperText
                sx={{
                  color: "#d32f2f",
                }}
              >
                {configError?.platform ? configError?.platformMsg : ""}
              </FormHelperText>
            </FormControl>

            {configData?.platform && (
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#01537A",
                }}
              >
                Datafree Platform
              </Typography>
            )}
            {/* <TextField
              sx={{
                width: "100%",
                marginBottom: "10px",
                marginTop: "10px",
                "& input::placeholder": {
                  color: "#757373",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  opacity: "1",
                  fontWeight: "400",
                },
              }}
              inputlabelprops={{
                style: { fontSize: 16, fontWeight: 400 },
              }}
              InputProps={{
                style: {
                  fontSize: 16,
                  fontWeight: 400,
                  letterSpacing: 0.5,
                  color: "rgba(0, 0, 0, 0.87)",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
              value={configData?.googleAppId ? configData?.googleAppId : ""}
              name="googleAppId"
              variant="standard"
              onChange={handleInputChange}
            />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                letterSpacing: "0.4px",
                color: "#01537A",
                marginTop: "5px",
                marginBottom: "0px",
              }}
            >
              Google App ID
            </Typography> */}
            {/* <TextField
              sx={{
                width: "100%",
                marginTop: "10px",
              }}
              variant="standard"
              value={configData?.packageSuffix ? configData?.packageSuffix : ""}
              error={!!configError?.packageSuffix}
              onChange={handleInputChange}
              name="packageSuffix"
              helperText={
                configError?.packageSuffix ? configError?.packageSuffixMsg : ""
              }
              inputlabelprops={{
                style: { fontSize: 16, fontWeight: 400 },
              }}
              InputProps={{
                style: {
                  fontSize: 16,
                  fontWeight: 400,
                  letterSpacing: 0.5,
                  color: "rgba(0, 0, 0, 0.87)",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
            />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                letterSpacing: "0.4px",
                color: "#01537A",
                marginTop: "5px",
                marginBottom: "0px",
              }}
            >
              Package Suffix
            </Typography> */}
            {/* <TextField
              sx={{
                width: "100%",
              }}
              inputlabelprops={{
                style: { fontSize: 16, fontWeight: 400 },
              }}
              InputProps={{
                style: {
                  fontSize: 16,
                  fontWeight: 400,
                  letterSpacing: 0.5,
                  color: "rgba(0, 0, 0, 0.87)",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
              name="gatwayDomain"
              value={
                applicationDetails?.data.gatewayDomain
                  ? applicationDetails?.data.gatewayDomain
                  : ""
              }
              variant="standard"
            />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                letterSpacing: "0.4px",
                color: "#01537A",
                marginTop: "5px",
              }}
            >
              Datafree Gateway domain
            </Typography> */}
            <TextField
              sx={{
                width: "100%",
                marginBottom: "10px",
                marginTop: "10px",
                "& input::placeholder": {
                  color: "#757373",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  opacity: "1",
                  fontWeight: "400",
                },
              }}
              InputProps={{
                style: {
                  fontSize: 16,
                  fontWeight: 400,
                  letterSpacing: 0.5,
                  color: "rgba(0, 0, 0, 0.87)",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
              placeholder="Distribution Email"
              value={
                configData?.distributionEmail !== null &&
                configData?.distributionEmail !== undefined
                  ? configData?.distributionEmail
                  : ""
              }
              error={!!configError?.distributionEmail}
              onChange={handleInputChange}
              name="distributionEmail"
              variant="standard"
              helperText={
                configError?.distributionEmail
                  ? configError?.distributionEmailMsg
                  : ""
              }
            />
            {configData?.distributionEmail && (
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#01537A",
                }}
              >
                Distribution Email
              </Typography>
            )}
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#01537A",
              }}
            >
              Include SDKs
            </Typography>
            <FormGroup>
              <FormControlLabel
                checked={Boolean(configData.piwikSdk)}
                name="piwikSdk"
                onChange={handleChange}
                control={<Checkbox />}
                label="piwik sdk"
                disabled={accessPermissionFields()}
                style={{ color: "var(--black-06, rgba(0, 0, 0, 0.60))" }}
              />
            </FormGroup>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#01537A",
                marginTop: "30px",
              }}
            >
              App Permissions
            </Typography>
            <FormGroup>
              <FormControlLabel
                checked={Boolean(configData.locationService)}
                name="locationService"
                onChange={handleChange}
                control={<Checkbox />}
                label="Allow App to request Location service Permission to install"
                disabled={accessPermissionFields()}
                style={{ color: "var(--black-06, rgba(0, 0, 0, 0.60))" }}
              />
              <FormControlLabel
                checked={Boolean(configData.storagePermission)}
                name="storagePermission"
                onChange={handleChange}
                control={<Checkbox />}
                label="Allow App to request Storage Permission on install"
                disabled={accessPermissionFields()}
                style={{ color: "var(--black-06, rgba(0, 0, 0, 0.60))" }}
              />
            </FormGroup>
          </Box>
        </>
      </Box>

      <Box
        sx={{
          width: {
            xs: "50px",
            sm: "382px",
            md: "622px",
            lg: "622px",
            xl: "100%",
          },
          display: "flex",

          gap: "10px",
          justifyContent: "space-between",
          paddingTop: "30px",
          paddingRight: "20px",
          paddingBottom: "30px",
        }}
      >
        <Button
          onClick={handleCancel}
          className={accessPermissionFields() ? "disable" : null}
          disabled={cancelDisable}
          sx={{
            "&.Mui-disabled": {
              color: "#757373",
              textTransform: "capitalize",
              padding: " 5px 40px 5px 40px",
              borderColor: "#757373 !important",
            },
            padding: " 5px 40px 5px 40px",
            width: "fit-content",
            color: accessPermissionFields() ? "#373737" : "#01537A",
            borderColor: accessPermissionFields()
              ? "#373737"
              : "#01537A !important",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          className={
            accessPermissionFields() || disableButton ? "disable" : null
          }
          onClick={handleSave}
          disabled={!areAllFieldsFilled || clickDisable}
          sx={{
            padding: "0px 50px",
            width: "fit-content",
            justify: "space-between",
            background: accessPermissionFields()
              ? "#373737!important"
              : "#01537A !important",
            textTransform: "capitalize",
            "&.Mui-disabled": {
              background: "#757373 !important",
              color: "white",
              textTransform: "capitalize",
            },
          }}
          variant="contained"
        >
          <Box sx={{ display: "flex", alignContent: "center" }}>
            <span style={{ marginRight: "5px" }}>Save</span>
            {load && (
              <Loader
                type={"spinningBubbles"}
                color={"white"}
                height={20}
                width={20}
              />
            )}
          </Box>
        </Button>
      </Box>
    </>
  );
}
