import React from "react";
import { Box, Typography, IconButton, InputBase, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "./dataTable";
import Loader from "react-loading";
import moment from "moment";
import ColumnGroupDataTable from "./columnGroupDataTable";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CSVLink } from "react-csv";
import Tooltips from "../../components/tooltips";
import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import useToast from "../../hooks/useToast";
import { formatNumberWithSpaces } from "../../utils/formatNumber";

export default function TableView({
  data,
  resData,
  loading,
  handleSearch,
  totalCount,
  csvDatas,
  companyName,
  role,
}) {
  const { showSuccess, showWarning } = useToast();
  const nameComparator = (v1, v2, param1, param2) => {
    if (v1.vals !== "" && v2.vals !== "") {
      return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
    }
  };

  // eslint-disable-next-line
  const columns = [
    {
      flex: 1,
      field: "date",
      headerName: "Date",

      width: 180,
      sortComparator: nameComparator,
      valueGetter: (value, row) => ({
        vals: row?.date,
      }),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.date
                ? moment(params?.row?.date).format("DD MMM YYYY")
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "datafree",
      headerName: "#Datafree (MB)",

      width: 180,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.free,
      }),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.free
                ? formatNumberWithSpaces(params?.row?.free)
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "paid",
      headerName: "Paid (MB)",

      width: 180,
      valueGetter: (value, row) => ({
        vals: row?.paid,
      }),
      headerClassName: "super-app-theme--header",
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.paid
                ? formatNumberWithSpaces(params?.row?.paid)
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "wifi",
      headerName: "Wifi (MB)",

      width: 180,
      valueGetter: (value, row) => ({
        vals: row?.wifi,
      }),
      headerClassName: "super-app-theme--header",
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.wifi
                ? formatNumberWithSpaces(params?.row?.wifi)
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "other",
      headerName: "Other (MB)",

      width: 180,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.other,
      }),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.other
                ? formatNumberWithSpaces(params?.row?.other)
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "totalData",
      headerName: "Total Data MB",

      width: 180,
      valueGetter: (value, row) => ({
        vals: row?.total,
      }),
      headerClassName: "super-app-theme--header",
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.total
                ? formatNumberWithSpaces(params?.row?.total)
                : ""}
            </span>
          </div>
        );
      },
    },
  ];
  const todayColumns = [
    {
      flex: 1,
      field: "month",
      headerName: "",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.hourofday,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span> {params?.row?.hourofday ? params?.row?.hourofday : 0}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "datafree1",
      headerName: "Datafree",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.uploaddatafree,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.uploaddatafree
                ? formatNumberWithSpaces(params?.row?.uploaddatafree)
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "paid1",
      headerName: "Paid",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.uploadpaid,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.uploadpaid
                ? formatNumberWithSpaces(params?.row?.uploadpaid)
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "wifi1",
      headerName: "Wifi",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.uploadwifi,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.uploadwifi
                ? formatNumberWithSpaces(params?.row?.uploadwifi)
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "other1",
      headerName: "Other",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.uploadunclassified,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.uploadunclassified
                ? params?.row?.uploadunclassified
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "datafree2",
      headerName: "Datafree",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.downloaddatafree,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.downloaddatafree
                ? params?.row?.downloaddatafree
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "paid2",
      headerName: "Paid",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.downloadpaid,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.downloadpaid
                ? formatNumberWithSpaces(params?.row?.downloadpaid)
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "wifi2",
      headerName: "Wifi",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.downloadwifi,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.downloadwifi
                ? formatNumberWithSpaces(params?.row?.downloadwifi)
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "other2",
      headerName: "Other",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.downloadunclassified,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.downloadunclassified
                ? params?.row?.downloadunclassified
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "totalUsage",
      headerName: "Total Usage(MB)",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.totaldatamb,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.totaldatamb
                ? formatNumberWithSpaces(params?.row?.totaldatamb)
                : ""}
            </span>
          </div>
        );
      },
    },
  ];
  const columnGroupingModel = [
    {
      groupId: "hourOfDay",
      headerName: "Hour of day (UTC)",
      description: "",

      children: [{ field: "month" }],
    },
    {
      groupId: "upload",
      description: "",
      headerName: "Upload(MB)",

      children: [
        { field: "datafree1" },
        { field: "paid1" },
        { field: "wifi1" },
        { field: "other1" },
      ],
    },
    {
      groupId: "download",
      description: "",
      headerName: "Download(MB)",

      children: [
        { field: "datafree2" },
        { field: "paid2" },
        { field: "wifi2" },
        { field: "other2" },
      ],
    },
    {
      groupId: "total",
      description: "",
      headerName: "Total(MB)",

      children: [{ field: "totalUsage" }],
    },
  ];
  const singleRangeColumns = [
    {
      flex: 1,
      field: "month",
      headerName: "",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.date,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.date
                ? moment(params?.row?.date).format("DD MMM YYYY")
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "total",
      headerName: "Total",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.totalusers,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.totalusers ? params?.row?.totalusers : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "new",
      headerName: "New",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.newusers,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span> {params?.row?.newusers ? params?.row?.newusers : 0}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "existing",
      headerName: "Existing",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.existingusers,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.existingusers ? params?.row?.existingusers : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "datafree",
      headerName: "Datafree",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.free,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.free
                ? formatNumberWithSpaces(params?.row?.free)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "paid",
      headerName: "Paid",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.paid,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.paid
                ? formatNumberWithSpaces(params?.row?.paid)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "wifi",
      headerName: "Wifi",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.wifi,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.wifi
                ? formatNumberWithSpaces(params?.row?.wifi)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "other",
      headerName: "Other",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.other,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.other
                ? formatNumberWithSpaces(params?.row?.other)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "totalUsage",
      headerName: "Total Usage (MB)",

      width: 150,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.total,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.total
                ? formatNumberWithSpaces(params?.row?.total)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "usagePerUser",
      headerName: "Usage per User",

      width: 200,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.mbperuser,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.mbperuser
                ? formatNumberWithSpaces(params?.row?.mbperuser)
                : 0}
            </span>
          </div>
        );
      },
    },
  ];
  const singleRangeColumnGroupingModel = [
    {
      groupId: "month",
      headerName: "Month",
      description: "",
      children: [{ field: "month" }],
    },
    {
      groupId: "users",
      description: "",
      headerName: "Users",

      children: [{ field: "total" }, { field: "new" }, { field: "existing" }],
    },
    {
      groupId: "dataUsage",
      description: "",
      headerName: "Data Usage(MB)",

      children: [
        { field: "datafree" },
        { field: "paid" },
        { field: "wifi" },
        { field: "other" },
        { field: "totalUsage" },
      ],
    },
    {
      groupId: "dataUsagePerUSer",
      description: "",
      headerName: "Data Usage per User (MB)",
      headerRender: () => (
        <div>
          <span>Data Usage per User (MB)</span>
        </div>
      ),

      children: [{ field: "usagePerUser" }],
    },
  ];
  const csvDownloadButton = () => {
    showWarning("No report to export");
  };
  const csvDownload = () => {
    showSuccess("CSV file successfully exported.");
  };

  return (
    <Box
      sx={{
        width: { xs: "64%", md: "70%", lg: "72%", xl: "81%" },
      }}
    >
      {resData && !loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              {data?.application === "all" ? (
                data?.dataUsage === "TODAY" ? (
                  <Box
                    component={"span"}
                    sx={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                      }}
                    >
                      Data usage for all apps in
                      <span
                        style={{
                          color: "#0094CD",
                          textTransform: "capitalize",
                          marginLeft: "5px",
                        }}
                      >
                        {role === "ROLE_USER"
                          ? companyName
                            ? companyName
                            : ""
                          : data?.publisher?.label
                          ? data?.publisher?.label
                          : ""}
                      </span>
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Typography
                      component={"span"}
                      sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                      }}
                    >
                      Data usage for all apps in
                      <span
                        style={{
                          color: "#0094CD",
                          textTransform: "capitalize",
                          marginLeft: "5px",
                        }}
                      >
                        {role === "ROLE_USER"
                          ? companyName
                            ? companyName
                            : ""
                          : data?.publisher?.label
                          ? data?.publisher?.label
                          : localStorage.getItem("switchRole") === "customer"
                          ? localStorage.getItem("publisherName")
                          : ""}
                      </span>
                    </Typography>
                  </>
                )
              ) : data?.dataUsage === "TODAY" ? (
                <Box
                  component={"span"}
                  sx={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "#373737",
                    }}
                  >
                    Data Usage per Hour for
                    <span
                      style={{
                        color: "#0094CD",
                        textTransform: "capitalize",
                        marginLeft: "5px",
                      }}
                    >
                      {data?.selectApplication?.label
                        ? data?.selectApplication?.label
                        : ""}
                    </span>
                  </Typography>
                  <Tooltips type="usagePerHr">
                    <InfoOutlinedIcon
                      sx={{
                        color: "#373737",
                        fontSize: "23px",
                        marginTop: "1px",
                      }}
                    />
                  </Tooltips>
                </Box>
              ) : (
                <>
                  <Typography
                    component={"span"}
                    sx={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "#373737",
                    }}
                  >
                    Data usage for{" "}
                    <span
                      style={{ color: "#0094CD", textTransform: "capitalize" }}
                    >
                      {data?.selectApplication?.label
                        ? data?.selectApplication?.label
                        : ""}
                    </span>
                  </Typography>
                </>
              )}
            </Typography>

            {csvDatas ? (
              csvDatas?.length === 0 ? (
                <Button
                  onClick={csvDownloadButton}
                  variant="contained"
                  sx={{
                    boxShadow: "0px 1px 0px 0px #01537A",
                    textTransform: "none",
                    padding: "10px 24px ",
                    color: "white",
                    background: "#01537A!important",
                    borderRadius: "5px",
                    fontWeight: 400,
                    fontSize: "15px",
                    width: "140.22px",
                    height: "38.33px",
                    letterSpacing: "0.0em",
                  }}
                >
                  Export to CSV{" "}
                </Button>
              ) : (
                <CSVLink
                  onClick={csvDownload}
                  style={{
                    textTransform: "none",
                    padding: "10px 24px",
                    color: "white",
                    background: "#01537A",
                    boxShadow: "0px 1px 0px 0px #01537A",
                    cursor: "pointer",
                    border: "1px",
                    borderRadius: "5px",
                    textDecoration: "none",
                    fontSize: "15px",
                  }}
                  data={csvDatas}
                  filename="data.csv"
                >
                  {" "}
                  Export to CSV
                </CSVLink>
              )
            ) : (
              ""
            )}
          </Box>
          <Box
            component="form"
            sx={{
              p: "0px 4px",
              display: "flex",
              alignItems: "center",
              width: "60%",
              border: "1px solid #7E7E7E",
              borderRadius: "5px",
              marginBottom: "30px",
            }}
          >
            <IconButton sx={{ p: "10px" }}>
              <SearchIcon sx={{ color: "black" }} />
            </IconButton>
            <InputBase
              sx={{
                "& input::placeholder": {
                  color: "rgba(90, 86, 86, 1)",
                  fontSize: "16px",
                  opacity: "1",
                },
              }}
              width="100%"
              placeholder="Search"
              onKeyDown={handleSearch}
            ></InputBase>
          </Box>
          {data?.application === "all" ? (
            data?.dataUsage === "RANGE" ? (
              <Box
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#01537A !important",
                    color: "white",
                  },
                }}
              >
                <DataTable
                  columns={columns}
                  rows={resData?.resultData ? resData?.resultData : []}
                  rowThreshold={0}
                />
              </Box>
            ) : data?.dataUsage === "TODAY" ? (
              <Box sx={{ width: "100%" }}>
                <ColumnGroupDataTable
                  rows={resData?.resultData ? resData?.resultData : []}
                  columns={todayColumns}
                  columnGroupingModel={columnGroupingModel}
                  hideFooter={true}
                  autoHeight
                />
              </Box>
            ) : (
              ""
            )
          ) : (
            <>
              {data?.dataUsage === "RANGE" ? (
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <ColumnGroupDataTable
                    rows={resData?.resultData ? resData?.resultData : []}
                    columns={singleRangeColumns}
                    columnGroupingModel={singleRangeColumnGroupingModel}
                    hideFooter={true}
                    autoHeight
                  />
                </Box>
              ) : (
                <ColumnGroupDataTable
                  rows={resData?.resultData ? resData?.resultData : []}
                  columns={todayColumns}
                  columnGroupingModel={columnGroupingModel}
                  hideFooter={true}
                  autoHeight
                />
              )}
            </>
          )}

          {!loading && totalCount >= 10 && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            ></Box>
          )}
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader
            type="spokes"
            color="#01537A"
            delay={0}
            height={40}
            width={40}
          />
        </Box>
      )}
    </Box>
  );
}
