import React, { createContext, useState, useEffect } from 'react';
import Services from '../api/services';

export const AppVersionContext = createContext();

export const AppVersionProvider = ({ children }) => {
  const [appVersion, setAppVersion] = useState(null);

  useEffect(() => {
    const fetchAppVersion = async () => {
        Services.appInfo().then((res) => {
            setAppVersion(res?.data?.version);
          });
    };

    fetchAppVersion();
  }, []);

  return (
    <AppVersionContext.Provider value={appVersion}>
      {children}
    </AppVersionContext.Provider>
  );
};
