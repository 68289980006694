import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import breadfolder from "../assets/img/breadcrumb.png";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  switchStatusDispatchContext,
  archivedStatusDispatchContext,
} from "../context/serviceContext";
import { companyDetailPage } from "../utils/roleHelper";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    maxWidth: 270,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "0px 2.68852px 2.68852px rgba(0, 0, 0, 0.25)",
    borderRadius: "2px",
  },
}));

const pStyle = {
  fontSize: "16px",
  marginBottom: "7px",
  color: "#000000",
};

export default function BreadCrumb({ value, other }) {
  let switchStatusContextVal = useContext(switchStatusDispatchContext);
  let archivedStatusContextVal = useContext(archivedStatusDispatchContext);
  let navigate = useNavigate();
  let levelLength = 0;
  let arrayLength = value?.parentCompany.length;
  if (other !== "") {
    levelLength = arrayLength + 1;
  } else {
    levelLength = arrayLength;
  }
  const switchDashs = (item) => {
    const userRole = localStorage.getItem("role");
    const type = item?.type
      ? item?.type
      : item?.companyType
      ? item?.companyType
      : "";
    if (type === "BINU" && userRole === "ROLE_ADMIN") {
      return true;
    } else if (
      type === "LICENSEE" &&
      (userRole === "ROLE_LICENSEE" || userRole === "ROLE_ADMIN")
    ) {
      return true;
    } else if (
      type === "CHANNEL" &&
      (userRole === "ROLE_LICENSEE" ||
        userRole === "ROLE_ADMIN" ||
        userRole === "ROLE_CHANNEL")
    ) {
      return true;
    } else if (
      (type === "CUSTOMER" || type === "RESELLER") &&
      (userRole === "ROLE_LICENSEE" ||
        userRole === "ROLE_ADMIN" ||
        userRole === "ROLE_CHANNEL" ||
        userRole === "ROLE_USER")
    ) {
      return true;
    }
  };

  const statusUpdate = (status) => {
    if (status === "Suspended") {
      localStorage.setItem("switchStatus", true);
      localStorage.setItem("archivedStatus", false);
      archivedStatusContextVal(false);
      switchStatusContextVal(true);
    } else if (status === "Archived") {
      localStorage.setItem("switchStatus", false);
      switchStatusContextVal(false);
      localStorage.setItem("archivedStatus", true);
      archivedStatusContextVal(true);
    } else {
      localStorage.setItem("switchStatus", false);
      localStorage.setItem("archivedStatus", false);
      archivedStatusContextVal(false);
      switchStatusContextVal(false);
    }
  };

  const switchDash = (item) => {
    const userRole = localStorage.getItem("role");
    const type = item?.type
      ? item?.type
      : item?.companyType
      ? item?.companyType
      : "";

    if (type === "BINU" && userRole === "ROLE_ADMIN") {
      navigate("/");
      statusUpdate(item?.status);
    } else if (
      type === "LICENSEE" &&
      (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE")
    ) {
      statusUpdate(item?.status);
      if (userRole === "ROLE_ADMIN") {
        companyDetailPage(item?.companyId, "LICENSEE");
        navigate("/licensee");
      } else {
        navigate("/");
      }
    } else if (
      type === "CHANNEL" &&
      (userRole === "ROLE_ADMIN" ||
        userRole === "ROLE_LICENSEE" ||
        userRole === "ROLE_CHANNEL")
    ) {
      statusUpdate(item?.status);
      if (userRole === "ROLE_ADMIN") {
        companyDetailPage(item?.companyId, "CHANNEL");
        navigate("/channel");
      } else if (userRole === "ROLE_LICENSEE") {
        companyDetailPage(item?.companyId, "CHANNEL");
        navigate("/channel");
      } else {
        navigate("/");
      }
    } else if (
      (type === "CUSTOMER" || type === "RESELLER") &&
      (userRole === "ROLE_ADMIN" ||
        userRole === "ROLE_LICENSEE" ||
        userRole === "ROLE_CHANNEL" ||
        userRole === "ROLE_USER")
    ) {
      statusUpdate(item?.status);
      if (userRole === "ROLE_USER") {
        navigate("/");
      } else {
        companyDetailPage(item?.companyId, "CUSTOMER");
        navigate("/customer");
      }
    }
  };

  return (
    <Box sx={{ padding: "10px 0px 30px 0px" }}>
      <Typography
        component={"span"}
        sx={{
          color: "#5A5656",
          fontWeight: "400",
          lineHeight: "42.61px",
          width: "300px",
          height: "18px",
          fontSize: "12px",
        }}
      >
        {levelLength <= 3 ? (
          <div style={{ position: "absolute" }}>
            {value?.parentCompany.length > 0 &&
              value?.parentCompany.map((item, index) => (
                <span
                  className={
                    switchDashs(item) ? "pointer-cursor" : "default-cursor"
                  }
                  key={index}
                  onClick={() => switchDash(item)}
                  style={{
                    cursor: switchDashs(item) ? "pointer" : "default",
                    textTransform: "capitalize",
                  }}
                >
                  {index !== arrayLength - 1 ? (
                    item.name + " > "
                  ) : (
                    <span
                      style={{
                        color: other ? "#5A5656" : "#01537A",
                      }}
                    >
                      {" "}
                      {item.name}
                    </span>
                  )}
                </span>
              ))}
            {other !== "" ? (
              <span
                style={{
                  color: "#01537A",
                }}
              >
                {" > " + other}
              </span>
            ) : (
              ""
            )}
          </div>
        ) : levelLength <= 5 ? (
          other === "" ? (
            <div style={{ position: "absolute" }}>
              <span
                className={
                  switchDashs(value?.parentCompany[0])
                    ? "pointer-cursor"
                    : "default-cursor"
                }
                onClick={() => switchDash(value?.parentCompany[0])}
                style={{
                  cursor: switchDashs(value?.parentCompany[0])
                    ? "pointer"
                    : "default",
                  textTransform: "capitalize",
                }}
              >
                {value?.parentCompany[0].name + " > "}
              </span>
              <Typography component={"span"}>
                <HtmlTooltip
                  placement="bottom"
                  title={
                    <React.Fragment>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {value?.parentCompany.length > 0 &&
                          value?.parentCompany.map((item, index) =>
                            index !== 0 && index !== arrayLength - 1 ? (
                              <Typography
                                className={
                                  switchDashs(item)
                                    ? "pointer-cursor"
                                    : "default-cursor"
                                }
                                key={index}
                                component={"span"}
                                sx={pStyle}
                                onClick={() => switchDash(item)}
                                style={{
                                  cursor: switchDashs(item)
                                    ? "pointer"
                                    : "default",
                                  textTransform: "capitalize",
                                }}
                              >
                                {" > " + item?.name}
                              </Typography>
                            ) : (
                              ""
                            )
                          )}
                      </Box>
                    </React.Fragment>
                  }
                >
                  <img
                    src={breadfolder}
                    alt=""
                    style={{ position: "relative", top: "5px" }}
                  ></img>
                </HtmlTooltip>
              </Typography>
              <span
                className={
                  switchDashs(value?.parentCompany[arrayLength - 1])
                    ? "pointer-cursor"
                    : "default-cursor"
                }
                onClick={() =>
                  switchDash(value?.parentCompany[arrayLength - 1])
                }
                style={{
                  cursor: switchDashs(value?.parentCompany[arrayLength - 1])
                    ? "pointer"
                    : "default",
                  textTransform: "capitalize",
                  color: "#01537A",
                }}
              >
                {" > " + value?.parentCompany[arrayLength - 1].name}
              </span>
            </div>
          ) : (
            <div style={{ position: "absolute" }}>
              <span
                className={
                  switchDashs(value?.parentCompany[0])
                    ? "pointer-cursor"
                    : "default-cursor"
                }
                onClick={() => switchDash(value?.parentCompany[0])}
                style={{
                  cursor: switchDashs(value?.parentCompany[0])
                    ? "pointer"
                    : "default",
                  textTransform: "capitalize",
                }}
              >
                {value?.parentCompany[0].name + " > "}
              </span>
              <Typography component={"span"}>
                <HtmlTooltip
                  placement="bottom-end"
                  title={
                    <React.Fragment>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {value?.parentCompany.length > 0 &&
                          value?.parentCompany.map((item, index) =>
                            index !== 0 ? (
                              <Typography
                                className={
                                  switchDashs(item)
                                    ? "pointer-cursor"
                                    : "default-cursor"
                                }
                                key={index}
                                component={"span"}
                                sx={pStyle}
                                onClick={() => switchDash(item)}
                                style={{
                                  cursor: switchDashs(item)
                                    ? "pointer"
                                    : "default",
                                  textTransform: "capitalize",
                                }}
                              >
                                {" > " + item?.name}
                              </Typography>
                            ) : (
                              ""
                            )
                          )}
                      </Box>
                    </React.Fragment>
                  }
                >
                  <img
                    src={breadfolder}
                    alt=""
                    style={{ position: "relative", top: "5px" }}
                  ></img>
                </HtmlTooltip>
              </Typography>

              {other !== "" ? (
                <span
                  style={{
                    color: "#01537A",
                  }}
                >
                  {" > " + other}
                </span>
              ) : (
                ""
              )}
            </div>
          )
        ) : (
          ""
        )}
      </Typography>
    </Box>
  );
}
