import {
  Container,
  Paper,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import Layout from "../../components/layout";
import { Box, Divider, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import defaultUser from "../../assets/img/defaultUser.svg";
import CreateIcon from "@mui/icons-material/Create";
import Services from "../../api/services";
import BreadCrumb from "../../components/breadCrumb";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import useToast from "../../hooks/useToast";

export default function PublisherAddUser() {
  const { showSuccess, showError } = useToast();
  let navigate = useNavigate();
  let regExp =
    /^[A-Za-z0-9_!#$%&'*+=?`{|}~^.-]*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i;
  const [imgSrc, setImgSrc] = useState(defaultUser);
  const [loading, setLoading] = useState(false);
  const [addUserClick, setAddUserClick] = useState(false);
  const [load, setLoad] = useState(false);
  const [breadCrumbData, setBreadCrumbData] = useState();
  const [userData, setUserData] = useState({
    userName: "",
    email: "",
    companyId: localStorage.getItem("companyId"),
  });
  const [userError, setUserError] = useState({
    userName: false,
    userMsg: "",
    email: "",
    emailMsg: "",
  });
  const validate = (data) => {
    const userErrors = { ...userError };
    let count = 0;
    if (data?.userName === "") {
      userErrors.userName = true;
      userErrors.userMsg = "This field is required";
      count++;
    } else {
      userErrors.userName = false;
      userErrors.userMsg = "";
    }
    if (data?.email === "") {
      userErrors.email = true;
      userErrors.emailMsg = "This field is required";

      count++;
    } else if (regExp.test(data?.email) === false) {
      userErrors.email = true;
      userErrors.emailMsg = "Enter a valid Email ID";

      count++;
    } else {
      userErrors.email = false;
      userErrors.emailMsg = "";
    }
    setUserError(userErrors);
    return count;
  };

  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const copyData = { ...userData };
    copyData["fileobj"] = event.target.files[0];
    setUserData(copyData);
    setImgSrc(URL.createObjectURL(event.target.files[0]));
  };

  const handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    let errorCount = validate(userData);
    if (errorCount === 0) {
      setLoad(true);
      setAddUserClick(true);
      formData.append("name", userData["userName"]);
      formData.append("email", userData["email"]);
      formData.append("companyId", userData["companyId"]);
      formData.append("userType", "publisher");
      formData.append(
        "userImage",
        userData["fileobj"] ? userData["fileobj"] : ""
      );
      formData.append("editorId", localStorage.getItem("userid"));
      Services.createUser(formData)
        .then((res) => {
          setLoad(false);
          setAddUserClick(false);
          if (res?.data.status === "success") {
            localStorage.setItem("parentCompId", userData?.companyId);
            navigate("/user-details/", {
              state: { clickedUserId: res.data.id },
            });
            showSuccess(res?.data.message);
          } else {
            showError(res?.data.message);
          }
        })
        .catch((err) => {
          setAddUserClick(false);
        });
    }
  };

  const handleClear = () => {
    setUserData({
      userName: "",
      email: "",
      companyId: localStorage.getItem("companyId"),
    });
    setUserError({
      userName: false,
      userMsg: "",
      email: "",
      emailMsg: "",
    });
    setImgSrc(defaultUser);
  };

  const handleAddService = (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "userName") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          userName: true,
          userMsg: "This field is required",
        });
      } else {
        setUserError({
          ...userError,
          userName: false,
          userMsg: "",
        });
      }
    } else if (e.target.name === "email") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          email: true,
          emailMsg: "This field is required",
        });
      } else {
        if (regExp.test(e.target.value) === false) {
          setUserError({
            ...userError,
            email: true,
            emailMsg: "Enter a valid Email ID",
          });
        } else {
          setUserError({
            ...userError,
            email: false,
            emailMsg: "",
          });
        }
      }
    }

    setUserData(newData);
  };

  const getBreadCrumbList = () => {
    setLoading(true);
    Services.breadCrumbList(localStorage.getItem("companyId"))
      .then((res) => {
        setBreadCrumbData(res?.data);
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBreadCrumbList();
  }, []);

  return (
    <CookiesProvider>
      <Layout>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt="2px"
        >
          {loading ? (
            <Loader
              type={"spokes"}
              color={"#01537A"}
              height={40}
              width={40}
              loading={loading.toString()}
            />
          ) : null}
        </Box>
        <Box sx={{ padding: "0px 0px 15px 25px" }}>
          {breadCrumbData?.parentCompany ? (
            <BreadCrumb value={breadCrumbData} other="AddUser" />
          ) : (
            ""
          )}
        </Box>
        <Box>
          <Container maxWidth="xxl">
            <Box
              sx={{
                boxShadow: 2,
                padding: "20px 0px 20px 56px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  lineHeight: "30px",
                  color: "#01537A",
                  fontSize: "33px",
                }}
              >
                Add User
              </Typography>
              <Divider
                sx={{
                  backgroundColor: "#0094CD !important",
                  borderRadius: "6px",
                  marginTop: "20px",
                  borderBottomWidth: 1,
                  marginBottom: "20px",
                }}
              ></Divider>
              <Box
                sx={{
                  maxWidth: {
                    xs: "100%",
                    sm: "500px",
                    md: "550px",
                    lg: "750px",
                  },
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "18px",
                    lineHeight: "30px",
                    color: "#01537A",
                    marginTop: "20px",
                  }}
                >
                  User Details
                </Typography>
                <Divider
                  sx={{
                    borderRadius: "6px",
                    borderBottomWidth: 1,
                    marginBottom: "20px",
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    gap: "27px",
                    marginBottom: "50px",
                    flexDirection: { xs: "column", md: "row" },
                    rowGap: 2,
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        position: "relative",
                        width: "125px",
                        height: "125px",
                        textAlign: "center",
                        fontSize: "0.875rem",
                        fontWeight: "700",
                        marginBottom: "10px",
                      }}
                    >
                      <Paper
                        style={{
                          background: "#fff",
                          width: "125px",
                          height: "125px",
                          borderRadius: "14PX",
                          color: "#01537A",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <img
                          style={{ borderRadius: "50%", padding: "2px 2px" }}
                          alt="Datafree"
                          src={imgSrc === null ? defaultUser : imgSrc}
                          rounded="lg"
                          width="125px"
                          height="125px"
                        />
                      </Paper>
                      <input
                        style={{ display: "none" }}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                      />

                      <IconButton
                        onClick={handleClick}
                        style={{
                          position: "absolute",
                          backgroundColor: "#01537A",
                          color: "#FFFFFF",
                          width: "22.71px",
                          height: "22.71px",
                          top: imgSrc === defaultUser ? "" : "98px",
                          fontSize: "15px",
                          right: "14px",
                          bottom: "14px",
                        }}
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <CreateIcon fontSize="11px" />
                      </IconButton>
                    </Box>
                    <Typography
                      sx={{
                        color: "#01537A",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                      * optional
                    </Typography>
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { xs: "400px", md: "565px" },
                      width: "100%",
                      rowGap: "7px",
                      marginTop: "5px",
                    }}
                    autoComplete="off"
                  >
                    <TextField
                      required
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          letterSpacing: 0.5,
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                      inputlabelprops={{
                        style: { fontSize: 16, fontWeight: 400 },
                      }}
                      value={userData?.userName}
                      error={!!userError?.userName}
                      onChange={handleAddService}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                      name="userName"
                      variant="standard"
                      helperText={userError?.userName ? userError?.userMsg : ""}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        letterSpacing: "0.4px",
                        color: "#01537A",
                      }}
                    >
                      User Name
                    </Typography>
                    <TextField
                      required
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          letterSpacing: 0.5,
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                      inputlabelprops={{
                        style: { fontSize: 16, fontWeight: 400 },
                      }}
                      value={userData?.email}
                      error={!!userError?.email}
                      onChange={handleAddService}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                      name="email"
                      variant="standard"
                      helperText={userError?.email ? userError?.emailMsg : ""}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        letterSpacing: "0.4px",
                        color: "#01537A",
                      }}
                    >
                      Email
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "95%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    className="cancel-btn"
                    sx={{
                      width: "150px",
                      height: "40px",
                      fontWeight: "400",
                      color: "#01537A",
                      textTransform: "capitalize",
                      backgroundColor: "white",
                      border: "1px solid #01537A",
                    }}
                    variant="outlined"
                    onClick={handleClear}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="save-btn"
                    variant="contained"
                    onClick={handleSave}
                    disabled={addUserClick}
                    sx={{
                      width: "  150px",
                      height: " 40px",
                      fontWeight: "400",
                      color: "#01537A",
                      textTransform: "capitalize",
                      backgroundColor: "white",
                      border: "1px solid #01537A",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <span style={{ marginRight: "5px" }}>Add User</span>
                      {load ? (
                        <Loader
                          type={"spinningBubbles"}
                          color={"white"}
                          height={20}
                          width={20}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Layout>
    </CookiesProvider>
  );
}
