import React from "react";
import { useState, useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import Layout from "../../components/layout";
import { Divider, Box, Container } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BasicDetailsDirects from "./basicDetailsDirects";
import Services from "../../api/services";
import { useNavigate, useLocation } from "react-router-dom";
import VersionPanel from "../../components/Applications/versionPanel";
import ChangeLog from "../../components/Applications/changeLog";
import Loader from "../../components/loader";
import BreadCrumb from "../../components/breadCrumb";
import useToast from "../../hooks/useToast";
import CheckIcon from "@mui/icons-material/Check";

import DdirectProduction from "../../components/Applications/D-Direct/Production/production";
import SdirectProduction from "../../components/Applications/S-Direct/Production/production";
import ConnectProduction from "../../components/Applications/Connect/Production/production";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
  color: "#1B2935",
  fontWeight: 400,
  fontSize: "18px",
  textTransform: "capitalize",
  letterSpacing: "0.5px",
  background: "white",
  borderRadius: "5px 5px 0px 0px",
  border: "1px solid ",
  borderColor: "#01537A",

  "&.Mui-selected": {
    background: "#01537A",
    color: "white",
  },
  "&.MuiTab-root": {
    minHeight: "0px",
  },
};

export default function AppDetailsDirects() {
  const location = useLocation();
  const selectedAppId = location.state?.clickedAppId;
  let navigate = useNavigate();
  const applicationPopUp = localStorage.getItem("applicationPopUp");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState();
  const [applicationData, setApplicationData] = useState();
  const [appId, setAppId] = useState();
  const [value, setValue] = React.useState(0);
  const [loadData, setLoadData] = useState(false);
  // eslint-disable-next-line

  const { showWarning } = useToast();

  const [customerData, setCustomerData] = useState();

  const [resData, setResData] = useState();
  const getApplicationDetails = () => {
    setLoading(true);
    const payload = {
      companyId: localStorage.getItem("companyId"),
      applicationId: selectedAppId,
    };
    Services.getApplicationDetails(payload)
      .then((res) => {
        if (res?.status === 200) {
          setResData(res?.data);
          if (res?.data?.status === "failed") {
            navigate("/");
            showWarning(res?.data?.message);
          } else {
            setAppId(res?.data?.prodAppId);
            setApplicationData(res);
            const customerId = res?.data?.publisherId;
            Services.LicenseeAccountSettings({ companyId: customerId })
              .then((res) => {
                setLoading(false);
                setCustomerData(res?.data);
              })
              .catch((err) => {});
            setProductType(res.data.productType);
          }

          if (res.data?.status === "Deleted") {
            localStorage.setItem("applicationStatus", "deleted");
          } else {
            localStorage.setItem("applicationStatus", "active");
          }
        }
      })
      .catch((err) => {});
  };

  const handleLoading = (data) => {
    setLoading(data);
  };

  const ApplicationPopUp = () => {
    if (applicationPopUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleDataChange = (newData) => {
    setLoadData(newData);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [appDetailApi, setAppDetailApi] = useState();

  const appDetailsRefresh = (data) => {
    setAppDetailApi(data);
  };

  const [prodDataConfig, setProdDataConfig] = useState("");

  const handleProdData = (data) => {
    setProdDataConfig(data);
  };

  useEffect(() => {
    if (appDetailApi) {
      getApplicationDetails();
    }
    // eslint-disable-next-line
  }, [appDetailApi]);

  useEffect(() => {
    getApplicationDetails();
    ApplicationPopUp();

    //eslint-disable-next-line
  }, []);

  return (
    <CookiesProvider>
      <Layout>
        <Container maxWidth="xxl">
          <Divider
            sx={{
              backgroundColor: "#0094CD",
              borderRadius: "6px",
              marginTop: "5px",
              borderBottomWidth: 2,
            }}
          ></Divider>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="2px"
          >
            {loading ? (
              <Loader
                type={"spokes"}
                color={"#01537A"}
                height={40}
                width={40}
                loading={loading.toString()}
              />
            ) : null}
          </Box>

          <Box
            component={"span"}
            sx={{
              color: "#5A5656",
              fontWeight: "400",
              lineHeight: "42.61px",
              width: "300px",
              height: "18px",
              padding: "0px 0px 15px 0px",
              fontSize: { xs: "10px", md: "12px" },
            }}
          >
            {customerData?.parentCompany && !loading ? (
              <BreadCrumb value={customerData} other="Application" />
            ) : (
              ""
            )}
          </Box>

          <BasicDetailsDirects
            productType={productType}
            applicationDetails={applicationData}
            sendLoadingStatus={handleLoading}
            onDataUpdate={handleDataChange}
            loadData={loadData}
          />

          <Box sx={{ width: "100%", marginTop: "30px" }}>
            <Box>
              <Tabs
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#01537A",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  icon={
                    value === 0 ? (
                      <CheckIcon
                        sx={{
                          color: "white",
                          fontSize: "23px",
                        }}
                      />
                    ) : (
                      ""
                    )
                  }
                  sx={tabStyle}
                  style={{
                    marginRight: "2px",
                  }}
                  iconPosition="start"
                  label="Production"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={tabStyle}
                  style={{
                    marginRight: "2px",
                  }}
                  label="Change Log"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Box
                sx={{
                  background: "rgba(1, 83, 122, 0.1)",

                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "3px",
                    padding: "20px",
                  }}
                >
                  <VersionPanel
                    applicationDetails={applicationData}
                    appId={appId}
                  />
                  {productType === "#datafree DIRECT" ? (
                    <SdirectProduction
                      applicationDetails={applicationData}
                      appDetailsRefresh={appDetailsRefresh}
                    />
                  ) : productType === "D-Direct" ? (
                    <DdirectProduction
                      applicationDetails={applicationData}
                      apiRefresh={appDetailsRefresh}
                      sendDataToDetailPage={handleProdData}
                    />
                  ) : productType === "Connect Workspace" ? (
                    <ConnectProduction
                      applicationDetails={applicationData}
                      appDetailsRefresh={appDetailsRefresh}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Container
                maxWidth="xxl"
                sx={{
                  background: "rgba(1, 83, 122, 0.1)",
                  padding: "40px 20px",
                }}
              >
                <ChangeLog tab={value} />
              </Container>
            </TabPanel>
            <Box
              sx={{
                width: "100%",
                paddingBottom: "20px",
                paddingRight: "40px",
                display: "flex",
                justifyContent: "flex-end",
                background: "rgba(1, 83, 122, 0.1)",
              }}
            ></Box>
          </Box>
        </Container>
      </Layout>
    </CookiesProvider>
  );
}
