import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Services from "../../api/services";
import useToast from "../../hooks/useToast";
import Loader from "../../components/loader";
export default function MainPage() {
  const { showSuccess, showError } = useToast();
  const [load, setLoad] = useState(false);
  const tld = process.env.REACT_APP_TLD;
  const [userData, setUserData] = useState({
    appId: "",
  });
  const [error, setError] = useState({
    appId: false,
    appIdMsg: "",
  });
  const numericRegex = /^\d*$/;
  const handleInputChange = async (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "appId") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setError({
          ...error,
          appId: true,
          appIdMsg: "This field is required",
        });
        setDisableButton(true);
      } else if (!numericRegex.test(e.target.value)) {
        setError({
          ...error,
          appId: true,
          appIdMsg: "only digits are allowed",
        });
        setDisableButton(true);
      } else {
        setError({
          ...error,
          appId: false,
          appIdMsg: "",
        });
        setDisableButton(false);
      }
    }
    setUserData(newData);
  };
  const [disableButton, setDisableButton] = useState(true);
  const handleWorkBench = () => {
    if (userData?.appId) {
      setDisableButton(true);
      setLoad(true);
      Services.checkReachApp({ appId: userData?.appId })
        .then((res) => {
          setDisableButton(false);
          if (res?.data?.success) {
            setLoad(false);
            setDisableButton(false);
            showSuccess(res?.data?.success);
            setTimeout(() => {
              window.open(
                `https://reachwb.${tld}/${userData?.appId}`,
                "dfworkbench"
              );
            }, 1000);
          } else {
            setLoad(false);
            showError(res?.data?.error);
            setDisableButton(true);
          }
        })
        .catch((err) => {});
    } else {
      setDisableButton(true);
    }
  };
  return (
    <Box
      elevation={10}
      style={{
        height: "100vh",
        width: "fit-content",
        margin: "auto",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Card
        style={{
          width: "100%",
          background: "#fff",
        }}
      >
        <CardContent
          sx={{
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              justifyContent: "center",
              alignContent: "center",
            }}
            autoComplete="off"
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "40px",
                padding: "20px",
              }}
            >
              Datafree Workbench
            </Typography>
            <TextField
              label="App Id"
              name="appId"
              //label="Outlined"
              value={userData?.appId}
              onChange={handleInputChange}
              error={error?.appId}
              helperText={error?.appId ? error?.appIdMsg : ""}
              fullWidth
            />

            <Button
              fullWidth
              disabled={disableButton}
              variant="contained"
              onClick={handleWorkBench}
              style={{
                background: "#01537A",
                marginTop: "15px",

                padding: "7px",
                borderRadius: "5px",
                fontSize: "16px",
                fontWeight: "400",
              }}
              sx={{
                color: "#fff !important",
                textTransform: "none",
                "&.Mui-disabled": {
                  cursor: "not-allowed !important",
                  pointerEvents: "unset !important",
                },
              }}
              type="submit"
              color="primary"
              size="large"
              // startIcon={
              //   <img
              //     src={workBench}
              //     alt="workBench"
              //     width="15px"
              //     height="15px"
              //   />
              // }
            >
              {load ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>
                    Launch New Workbench
                  </span>
                  <Loader
                    type={"spinningBubbles"}
                    color={"#ffff"}
                    height={20}
                    width={20}
                  />
                </Box>
              ) : (
                <span>Launch New Workbench</span>
              )}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
