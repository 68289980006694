import React, { useState, useEffect, useContext } from "react";
import DataTable from "./dataTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {
  Container,
  Typography,
  Button,
  ButtonGroup,
  TextField,
  InputAdornment,
  Paper,
  MenuItem,
  Select,
  FormControl,
  Divider,
} from "@mui/material";
import Services from "../../api/services";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import DoneIcon from "@mui/icons-material/Done";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import Loader from "../../components/loader";
import PaginationHeader from "./paginationHeader";
import PaginationRounded from "../pagination";
import {
  gridStringOrNumberComparator,
  gridNumberComparator,
} from "@mui/x-data-grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PopUp from "./popUp";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ModalViewButton from "./modalViewButton";
import {
  statusContext,
  switchStatusContext,
  archivedStatusContext,
} from "../../context/serviceContext";
import DialogActions from "@mui/material/DialogActions";
import ProgressBar from "./progressBar";
import { WarningMessages } from "../../config/messages";
import useToast from "../../hooks/useToast";
import ConfirmationPopup from "../../components/confirmationPopup";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    maxWidth: "100%",
    height: "auto",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "0px 2.68852px 2.68852px rgba(0, 0, 0, 0.25)",
    borderRadius: "3px",
  },
}));

const idComparator = (v1, v2, param1, param2) => {
  return gridNumberComparator(v1.Id, v2.Id, param1, param2);
};

const nameComparator = (v1, v2, param1, param2) => {
  if (v1.vals !== "" && v2.vals !== "") {
    return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
  }
};
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      boxShadow: 2,
      border: "none",
      maxWidth: "150px",
      color: "#000 !important",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderTopRightRadius: "6px",
      borderTopLeftRadius: "6px",
      fontSize: "13px",
      marginTop: "10px",
      "& .Mui-focused ": {
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
        color: "#000 !important",
      },
      "& .MuiMenuItem-root": {
        color: "#000",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        fontSize: "16px",
        backgroundColor: "transparent",
        padding: "5px",
      },
      "& .MuiMenuItem-root: last-child": {
        color: "#000",
        borderBottom: "none",
        fontSize: "16px",
        backgroundColor: "#fff",
        "&:focus": {
          color: "#01537A",
        },
      },
      "& .Mui-selected:hover": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .Mui-selected": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .MuiBox-root:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        borderRadius: "6px",
        borderBottom: "none",
      },
      "& .MuiBox-root": {
        padding: "8px 10px",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: 2,
        paddingTop: "15px",
        borderRadius: "6px",
      },
      "& .MuiList-root": {
        padding: "0 15px",
      },
    },
  },
};

const NewIcon = (props) => (
  <KeyboardArrowDownIcon {...props} sx={{ color: "#01537A !important" }} />
);

const Stack1 = styled(Box)({
  color: "#01537A",
  fontWeight: "400",
  fontSize: "12px",
  letterSpacing: "0.4px",
  width: "100%",
  lineHeight: "20px",
});

const StackBox = styled(Box)({
  padding: "11px",
  width: "170px",
});

const Stack2 = styled(Box)({
  color: "rgba(0, 0, 0, 0.87)",
  fontWeight: "400",
  fontSize: "16px",
  width: "100%",
});

const textStyle = {
  color: "#0094CD",
  fontWeight: 500,
  fontSize: "14px",
  cursor: "pointer",
};

const textStyle2 = {
  color: "#231F20",
  fontWeight: "400",
  fontSize: "14px",
  width: "169px",
  height: "15px",
};

const Applications = () => {
  const { showSuccess, showError, showWarning } = useToast();
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState();
  const [confirmationBox, setConfirmationBox] = useState(false);

  const handleConfirmation = () => {
    items.pop();
    document.getElementById("status" + rowData?.appId).innerHTML =
      rowData?.status;
    setRowData({
      ...rowData,
      status: "",
      appId: "",
    });
    setConfirmationBox(false);
  };
  const confirmSave = () => {
    setConfirmationBox(false);
  };

  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const userRole = localStorage.getItem("role");
  const [items, setItems] = useState([]);
  const [rowData, setRowData] = useState({
    appId: "",
    value: "",
    prodId: "",
    status: "",
    devId: "",
  });
  const getData = (role, componentMap) => {
    return componentMap[role] || "";
  };
  const warnMsg = getData(rowData?.value?.value, {
    Suspended: `Are your sure you want to Suspend this Application? `,
    Deleted: `Are your sure you want to Delete this Application? `,
    Live: `Are your sure you want to make this application Live? `,
  });

  const openConfirmationBox = (appId, value, prodId, status, devId) => {
    setRowData({
      ...rowData,
      appId: appId,
      value: value,
      prodId: prodId,
      status: status,
      devId: devId,
    });
    handleStatus(appId, value, prodId, status, devId);
    setConfirmationBox(true);
  };

  const handleStatus = (appId, value, prodId, status, devId) => {
    const temp = [...items];
    const result = items.find(({ applicationId }) => applicationId === appId);
    const Indexs = items.findIndex(
      ({ applicationId }) => applicationId === appId
    );
    if (result !== undefined) {
      temp[Indexs][value.name] = value.value;
      setItems(temp);
    } else {
      value.name === "revenueType"
        ? setItems([
            ...items,
            {
              applicationId: appId,
              revenueType: value.name === "revenueType" && value.value,
            },
          ])
        : setItems([
            ...items,
            {
              applicationId: appId,
              currentStatus: value.name === "status" && value.value,
              appId: !prodId ? devId : prodId,
              oldStatus: value.name === "status" && status,
            },
          ]);
    }
  };

  DetailPanelContent.propTypes = {
    row: PropTypes.object.isRequired,
  };

  const getAppType = (item) => {
    return getData(item.productId, {
      1: "Wrap",
      2: "Max",
      3: "Switch",
      4: "Reach",
      5: "S-Direct",
      7: "D-Direct",
      8: "Connect",
    });
  };

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const changePagination = (event, value) => {
    setPage(value - 1);
  };

  const handlePageSize = (e) => {
    setSize(e.target.value);
    setPage(0);
  };
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const clickedCompId = location.state?.clickedCompId;

  async function getApplications() {
    setLoading(true);
    Services.getApplications({ publisherId: clickedCompId }, page, size)
      .then((res) => {
        if (res.status === 200) {
          setResData(res.data.applicationDetails);
          setTotalCount(res?.data.totalCount);
          setLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  const cancelUpdate = () => {
    getApplications();
  };
  useEffect(() => {
    getApplications();
    //eslint-disable-next-line
  }, [page, size]);

  CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
  };

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    //eslint-disable-next-line
    []
  );

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const accessPermissionButton = (btnName) => {
    if (statusContextVal) {
      if (archivedStatusContextVal) {
        return `${btnName}-btn disable`;
      } else {
        return `${btnName}-disable-btn disable`;
      }
    } else {
      if (archivedStatusContextVal) {
        return `${btnName}-btn disable`;
      } else {
        return `${btnName}-btn`;
      }
    }
  };
  const shouldShowEditIcon = (rowProp) => {
    const productId = rowProp.productId.productId;
    return !accessPermissionFields() && ![2, 5, 8, 6, 7].includes(productId);
  };

  function DetailPanelContent({ row: rowProp }) {
    const apiRef = useGridApiContext();

    const expandRows = apiRef.current.state.detailPanel.expandedRowIds;

    expandRows.map((item) =>
      item !== rowProp.applicationId
        ? apiRef.current.toggleDetailPanel(item)
        : ""
    );

    const [saveData, setSaveData] = useState({
      applicationId: "",
      appName: "",
      status: "",
      editorId: localStorage.getItem("userid"),
    });

    const [saveError, setSaveError] = useState({
      appName: false,
      nameMsg: "",
    });

    const closeConfirmationBox = () => {
      setSaveData({
        ...saveData,
        status: appStatus,
      });
      setConfirmPopUp(false);
    };
    const [appStatus, setAppStatus] = useState();
    const [confirmPopUp, setConfirmPopUp] = useState(false);
    const confirmSave = () => {
      setConfirmPopUp(false);
    };
    const setStatus = (stat, oldStatus) => {
      setConfirmPopUp(true);
      setAppStatus(oldStatus);
      setSaveData({
        ...saveData,
        status: stat,
      });
    };

    const handleClose = () => {
      setSaveClick(false);
      apiRef.current.toggleDetailPanel(rowProp.applicationId);
    };

    const [saveClick, setSaveClick] = useState(false);

    const handleSave = (status, prodId, devId) => {
      if (saveData?.appName === "") {
        setSaveError({
          ...saveError,
          appName: true,
          nameMsg: "This field is required",
        });
      } else {
        setSaveClick(true);
        let payload = {
          applicationId: saveData?.applicationId ? saveData?.applicationId : "",
          appName: saveData?.appName ? saveData?.appName : "",
          currentStatus: saveData?.status ? saveData?.status : "",
          oldStatus: status,
          editorId: localStorage.getItem("userid"),
          appId: !prodId ? devId : prodId,
          revenueId: saveData?.revenueId ? saveData?.revenueId : "",
        };
        Services.updateApplication(payload, saveData?.applicationId)
          .then((res) => {
            setSaveClick(false);
            if (res?.data.status === "success") {
              showSuccess(res?.data.message);
              getApplications();
              handleClose();
            } else {
              showError(res?.data.message);
              getApplications();
              handleClose();
            }
          })
          .catch((err) => {});
      }
    };

    const handleEdit = (e) => {
      const newData = { ...saveData };
      newData[e.target.name] = e.target.value;

      if (e.target.name === "appName") {
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setSaveError({
            ...saveError,
            appName: true,
            nameMsg: "This field required",
          });
        } else {
          setSaveError({
            ...saveError,
            appName: false,
            nameMsg: "",
          });
        }
      }
      setSaveData(newData);
    };

    const accessPermission = (status, checkStatus, productType) => {
      if (userRole === "ROLE_ADMIN") {
        if (
          accessControls() &&
          !switchStatusContextVal &&
          !archivedStatusContextVal
        ) {
          if (
            productType === 2 ||
            productType === 5 ||
            productType === 6 ||
            productType === 7 ||
            productType === 8
          ) {
            if (status === checkStatus) {
              return "btn-disable-active disable";
            } else {
              return "btn-disable-nonactive disable";
            }
          } else {
            if (status === checkStatus) {
              return "btn-active";
            } else {
              return "btn-nonactive";
            }
          }
        } else {
          if (archivedStatusContextVal) {
            if (status === checkStatus) {
              return "btn-active disable";
            } else {
              return "btn-nonactive disable";
            }
          } else if (
            productType === 2 ||
            productType === 5 ||
            productType === 6 ||
            productType === 7 ||
            productType === 8
          ) {
            if (status === checkStatus) {
              return "btn-disable-active disable";
            } else {
              return "btn-disable-nonactive disable";
            }
          } else {
            if (status === checkStatus) {
              return "btn-active";
            } else {
              return "btn-nonactive";
            }
          }
        }
      } else if (status === checkStatus) {
        return "btn-disable-active disable";
      } else {
        return "btn-disable-nonactive disable";
      }
    };

    const checkProductId = (rowProp) => {
      const productId = rowProp.productId.productId;
      return accessPermissionFields() || [2, 8, 5, 6, 7].includes(productId);
    };

    useEffect(() => {
      const newData = { ...saveData };
      newData["applicationId"] = rowProp.applicationId;
      newData["status"] = rowProp.status;
      newData["appName"] = rowProp.name;
      newData["revenueId"] = rowProp.revenueId;
      setSaveData(newData);
      //eslint-disable-next-line
    }, [rowProp]);
    return (
      <Dialog
        open={true}
        maxWidth="xl"
        scroll={"body"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              marginTop: "10px",
              marginLeft: "34px",
              fontSize: "24px",
              color: "#01537A",
              padding: { lg: "20px 0px", md: "20px 0px" },
            }}
          >
            Update Application Details
          </Typography>
          <CloseSharpIcon
            sx={{
              color: "#01537A",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: "0px 40px" }}>
            <Box
              sx={{
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
                display: "flex",
                columnGap: { lg: 10, md: 5, xs: 3 },
              }}
            >
              <Paper
                sx={{
                  maxWidth: { xs: "380px", md: "450px", lg: "400px" },
                  width: "100%",
                  height: "auto",
                  padding: "30px",
                  display: "flex",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  justifyContent: "space-between",
                  marginTop: { xs: "20px", md: "0px" },
                }}
              >
                <Stack1 direction="column" spacing={2}>
                  <StackBox>Created </StackBox>
                  <StackBox>App Type</StackBox>
                  <StackBox>Customer ID</StackBox>
                  <StackBox>Free Domain</StackBox>
                  <StackBox>Gateway Domain</StackBox>
                </Stack1>
                <Stack2 direction="column" spacing={2}>
                  <StackBox>
                    {rowProp?.created
                      ? moment(rowProp?.created).format("DD MMM YYYY HH:mm")
                      : "-"}
                  </StackBox>
                  <StackBox>
                    {rowProp?.productId ? getAppType(rowProp?.productId) : "-"}{" "}
                  </StackBox>
                  <StackBox>
                    {rowProp?.publisherId ? rowProp?.publisherId : "-"}
                  </StackBox>
                  <StackBox>
                    {rowProp?.freeDomain ? rowProp?.freeDomain : "-"}
                  </StackBox>
                  <StackBox>
                    {rowProp?.gatewayDomain ? rowProp?.gatewayDomain : "-"}
                  </StackBox>
                </Stack2>
              </Paper>
              <Box sx={{ marginTop: { xs: "20px", md: "30px", lg: "0px" } }}>
                <Box>
                  <TextField
                    sx={{
                      maxWidth: {
                        xs: "380px",
                        md: "450px",
                        lg: "560px",
                        xl: "650px",
                      },
                      width: "100%",
                      color: "#231F20",
                    }}
                    name="appName"
                    onChange={handleEdit}
                    value={saveData?.appName}
                    error={saveError?.appName}
                    helperText={saveError?.appName ? saveError?.nameMsg : ""}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {shouldShowEditIcon(rowProp) && (
                            <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                          )}
                        </InputAdornment>
                      ),
                      readOnly: checkProductId(rowProp),
                    }}
                  />
                  <Typography
                    sx={{
                      width: "85px",
                      height: "24px",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "24px",
                      letterSpacing: "0.4px",
                      color: "#01537A",
                    }}
                  >
                    App Name
                  </Typography>
                </Box>

                {(userRole === "ROLE_ADMIN" ||
                  userRole === "ROLE_LICENSEE") && (
                  <Box
                    sx={{
                      marginTop: "20px",
                    }}
                  >
                    <FormControl
                      variant="standard"
                      sx={{
                        maxWidth: {
                          xs: "380px",
                          md: "450px",
                          lg: "560px",
                          xl: "650px",
                        },
                        width: "100%",
                        color: "#231F20",
                      }}
                    >
                      <Select
                        inputProps={{
                          style: {
                            fontSize: 16,
                            fontWeight: 400,
                            letterSpacing: 0.5,
                            color: "rgba(0, 0, 0, 0.87)",
                          },
                        }}
                        inputlabelprops={{
                          style: { fontSize: 16, fontWeight: 400 },
                        }}
                        value={saveData?.revenueId ? saveData?.revenueId : ""}
                        readOnly={accessPermissionFields() && true}
                        name="revenueId"
                        IconComponent={NewIcon}
                        onChange={handleEdit}
                      >
                        {rowProp.revenueList &&
                          rowProp.revenueList.map((item, index) => (
                            <MenuItem value={item?.rtId} key={index}>
                              {item?.revType}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <Typography
                      sx={{
                        width: "85px",
                        height: "24px",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "24px",
                        letterSpacing: "0.4px",
                        color: "#01537A",
                      }}
                    >
                      Revenue Type
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    marginTop: "27px",
                  }}
                >
                  <Typography
                    sx={{
                      width: "45px",
                      height: "15px",
                      fontWeight: "400",
                      color: "#1B2935",
                      marginBottom: "8px",
                    }}
                  >
                    Status
                  </Typography>
                  <ButtonGroup
                    sx={{
                      width: {
                        xs: "380px",
                        md: "450px",
                        lg: "565px",
                        xl: "650px",
                      },
                      height: { xs: "32px", lg: "38px" },
                      fontSize: { xs: "12px", lg: "14px" },
                      fontWeight: 400,
                    }}
                  >
                    <Button
                      type="button"
                      sx={{
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                      startIcon={
                        saveData?.status === "Live" ? (
                          <DoneIcon xs={{ fontColor: "#01537A !important" }} />
                        ) : (
                          ""
                        )
                      }
                      className={accessPermission(
                        "Live",
                        saveData?.status,
                        rowProp?.productId?.productId
                      )}
                      onClick={() => setStatus("Live", rowProp?.status)}
                    >
                      Live
                    </Button>
                    <Button
                      type="button"
                      sx={{
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                      startIcon={
                        saveData?.status === "Deleted" ? (
                          <DoneIcon sx={{ fontColor: "#01537A" }} />
                        ) : (
                          ""
                        )
                      }
                      className={accessPermission(
                        "Deleted",
                        saveData?.status,
                        rowProp?.productId?.productId
                      )}
                      onClick={() => setStatus("Deleted", rowProp?.status)}
                    >
                      Deleted
                    </Button>
                    <Button
                      type="button"
                      sx={{
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                      startIcon={
                        saveData?.status === "Suspended" ? (
                          <DoneIcon sx={{ fontColor: "#01537A" }} />
                        ) : (
                          ""
                        )
                      }
                      className={accessPermission(
                        "Suspended",
                        saveData?.status,
                        rowProp?.productId?.productId
                      )}
                      onClick={() => setStatus("Suspended", rowProp?.status)}
                    >
                      Suspended
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
            <Divider
              sx={{
                color: "#DFDFDF",
                alignContent: "center",
                marginBottom: "20px",
                marginTop: "60px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", lg: "flex-end" },
                padding: "30px 0px",
                gap: 3,
              }}
            >
              <Button
                className={accessPermissionButton("modal-cancel")}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                sx={{
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "unset !important",
                  },
                }}
                className={accessPermissionButton("modal-save")}
                disabled={saveClick}
                variant="contained"
                onClick={(e) =>
                  handleSave(rowProp?.status, rowProp?.prodId, rowProp?.devId)
                }
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Save</span>
                  {saveClick ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"white"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </Box>
          </Box>
        </DialogContent>
        {confirmPopUp && (
          <ConfirmationPopup
            open={confirmPopUp}
            handleClose={closeConfirmationBox}
            message={getData(saveData?.status, {
              Suspended: `Are your sure you want to Suspend this Application? `,
              Deleted: `Are your sure you want to Delete this Application? `,
              Live: `Are your sure you want to make this application Live? `,
            })}
            confirmSave={confirmSave}
            type={"warning"}
            buttonMode={getData(saveData?.status, {
              Suspended: "Suspend",
              Deleted: "Delete",
              Live: "Live",
            })}
          />
        )}
      </Dialog>
    );
  }

  const accessControls = () => {
    if (userRole !== "ROLE_USER") {
      return true;
    } else {
      return false;
    }
  };
  const columnsHeading = [
    {
      flex: 1,
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      valueGetter: (value, row) => ({
        vals: row.name,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <HtmlTooltip
            placement="bottom-end"
            title={
              <React.Fragment>
                <PopUp value={params?.row} />
              </React.Fragment>
            }
          >
            <div>
              <span style={textStyle}>{params.row.name}</span>
            </div>
          </HtmlTooltip>
        );
      },
    },

    {
      flex: 1,
      field: "type",
      headerName: "Type",
      headerClassName: "super-app-theme--header",
      width: 100,
      valueGetter: (value, row) => ({
        vals: getAppType(row),
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span style={textStyle2}>
              {getAppType(params?.row?.productId) + " App"}
            </span>
          </div>
        );
      },
    },

    {
      flex: 1,
      field: "prodId",
      headerName: "Prod ID",
      width: 100,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        Id: row.prodId,
      }),
      sortComparator: idComparator,
      renderCell: (params) => {
        return (
          <div>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <span sx={textStyle2}>
                {params.row.prodId === null ? "-" : params.row.prodId}
              </span>
            </Box>
          </div>
        );
      },
    },

    {
      field: "freeDomain",
      headerName: "Free Domain",
      minWidth: 140,
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.freeDomain,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex" }}>
            <span style={textStyle2}>
              {params.row.freeDomain ? params.row.freeDomain : "-"}
            </span>
          </div>
        );
      },
    },

    {
      field: "gatewayDomain",
      headerName: "Gateway Domain",
      minWidth: 160,
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (value, row) => ({
        vals: row.gatewayDomain,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex" }}>
            <span style={textStyle2}>
              {params.row.gatewayDomain ? params.row.gatewayDomain : "-"}
            </span>
          </div>
        );
      },
    },
    {
      field: "created",
      headerName: "Created",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => ({
        vals: row.created,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex" }}>
            <span style={textStyle2}>
              {params.row?.created
                ? moment(params.row?.created).format("DD MMM YYYY, HH:mm")
                : "-"}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "nat",
      headerName: "NAT Reqd",
      width: 110,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.natGatewayReq,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex" }}>
            <span style={textStyle2}>
              {params.row?.natGatewayReq ? "True" : "False"}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.status,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <>
            {userRole === "ROLE_ADMIN" &&
            accessControls() &&
            !accessPermissionFields() &&
            params.row.productId.productId !== 2 &&
            params.row.productId.productId !== 5 &&
            params.row.productId.productId !== 8 &&
            params.row.productId.productId !== 6 &&
            params.row.productId.productId !== 7 ? (
              <FormControl
                variant="standard"
                sx={{
                  minWidth: 135,
                  backgroundColor: "transparent !important",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "400",
                  marginTop: "10px",
                }}
              >
                <Select
                  sx={{ backgroundColor: "transparent !important" }}
                  labelId="demo-simple-select-standard-label"
                  id={"status" + params.row.applicationId}
                  defaultValue={params.row.status}
                  onChange={(e) =>
                    openConfirmationBox(
                      params.row.applicationId,
                      e.target,
                      params.row.prodId,
                      params.row.status,
                      params.row.devId
                    )
                  }
                  name="status"
                  MenuProps={MenuProps}
                  disableUnderline={true}
                  IconComponent={NewIcon}
                >
                  <MenuItem value="Live">Live</MenuItem>
                  <MenuItem value="Suspended">Suspended</MenuItem>
                  <MenuItem value="Deleted">Deleted</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <div style={{ display: "flex" }}>
                <span style={textStyle2}>{params.row?.status}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "revenueType",
      headerName: "Revenue Type",
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        Id: row.revenueId,
      }),
      sortComparator: idComparator,
      renderCell: (params) => {
        return (
          <>
            {(userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE") &&
            !accessPermissionFields() ? (
              <FormControl
                variant="standard"
                sx={{
                  minWidth: 120,
                  backgroundColor: "transparent !important",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "400",
                  marginTop: "10px",
                }}
              >
                <Select
                  sx={{ backgroundColor: "transparent !important" }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  defaultValue={
                    params.row.revenueId ? params.row.revenueId : ""
                  }
                  onChange={(e) =>
                    handleStatus(params.row.applicationId, e.target)
                  }
                  name="revenueType"
                  MenuProps={MenuProps}
                  disableUnderline={true}
                  IconComponent={NewIcon}
                >
                  {params?.row?.revenueList &&
                    params?.row?.revenueList.map((item, index) => (
                      <MenuItem value={item?.rtId} key={index}>
                        {item?.revType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : (
              <div style={{ display: "flex" }}>
                <span style={textStyle2}>{params.row?.revenueType}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.row.applicationId} />
      ),
    },
  ];

  const [saveClick, setSaveClick] = useState(false);
  const handleSave = () => {
    if (items.length > 0) {
      setOpen(true);
    } else {
      showWarning(WarningMessages?.noActivity);
    }
  };
  function CustomDetailPanelToggle() {
    return <ModalViewButton />;
  }

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setUpdateStatus(false);
    setItems([]);
  };

  const [bulkLoading, setBulkLoading] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const handleBulkSave = () => {
    setBulkLoading(true);
    setSaveClick(true);
    setUpdateStatus(true);
    Services.bulkUpdation(items)
      .then((res) => {
        if (res?.data.status === "Success") {
          setBulkLoading(false);
          cancelUpdate();
        }
        setSaveClick(false);
      })
      .catch((err) => {});
  };

  const mainContent = () => {
    if (resData && !loading) {
      return (
        <>
          {totalCount !== 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                pb: "5px",
                pr: "2px",
              }}
            >
              <PaginationHeader
                page={page}
                size={size}
                dataLength={resData.length}
                totalCount={totalCount}
              />
            </Box>
          ) : null}
          <Box
            sx={{
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor:
                  switchStatusContextVal && statusContextVal
                    ? "#373737"
                    : "#01537A !important",
                color: "white",
              },
            }}
          >
            <DataTable
              rows={resData}
              columns={columnsHeading}
              tableName="usersPanel"
              loading={loading}
              getDetailPanelContent={getDetailPanelContent}
            />
          </Box>
        </>
      );
    } else {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
        </Box>
      );
    }
  };

  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box>
      <Container
        maxWidth="xxl"
        style={{
          padding: "0",
        }}
      >
        {mainContent()}

        {!loading && totalCount >= 10 && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Typography
              component="span"
              variant="body2"
              sx={{ color: "#01537A" }}
            >
              Items per page :{" "}
            </Typography>
            <FormControl variant="standard">
              <Select
                value={size}
                sx={{
                  width: "55px",
                  margin: "0px 10px 0px 5px",
                  backgroundColor: "#8080802b",
                  color: "#000",
                  borderBottom: "1px solid #01537A",
                  textAlign: "center",
                  "&:after": {
                    borderBottom: "1.5px solid #01537A",
                  },
                  "&hover": {
                    borderBottom: "1px solid #01537A",
                  },
                }}
                onChange={handlePageSize}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>{Number(10).toLocaleString()}</MenuItem>
                <MenuItem value={25}>{Number(25).toLocaleString()}</MenuItem>
                <MenuItem value={50}>{Number(50).toLocaleString()}</MenuItem>
                <MenuItem value={100}>{Number(100).toLocaleString()}</MenuItem>
              </Select>
            </FormControl>
            <PaginationRounded
              changeHandle={changePagination}
              page={page + 1}
              Count={totalCount}
              size={size}
            />
          </Box>
        )}
        {!loading && totalCount > 0 && accessControls() && (
          <Box
            sx={{
              padding: "20px 0px 10px 0px",
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              className={accessPermissionButton("cancel")}
              variant="outlined"
              onClick={cancelUpdate}
            >
              Cancel
            </Button>

            <Button
              className={accessPermissionButton("save")}
              variant="contained"
              disabled={saveClick}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Box>
        )}
      </Container>
      <Dialog open={open} onClose={handleClose} scroll={"body"} maxWidth="md">
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                marginTop: "10px",
                color: "#01537A",
                fontWeight: 400,
                fontSize: "24px",
                lineHeight: "46px",
              }}
            >
              Bulk Update
            </Typography>
            <CloseSharpIcon
              sx={{
                color: "#01537A",
                cursor: "pointer",
                fontSize: "30px",
              }}
              onClick={handleClose}
            />
          </Box>
          {updateStatus && (
            <Typography
              sx={{ padding: "0px 30px 0px 10px", color: "#373737" }}
            >{`Updated ${items.length}  ${
              items.length > 1 ? "records :" : "record :"
            } `}</Typography>
          )}
        </DialogTitle>

        <DialogContent>
          {bulkLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "60px 40px 80px 30px",
                width: { xs: "380px", sm: "400px", md: "600px", xl: "700px" },
              }}
            >
              <ProgressBar value={progress} />
            </Box>
          ) : updateStatus && !bulkLoading ? (
            <Box
              sx={{
                padding: "20px 30px 20px 10px",
                display: "flex",
                width: { xs: "380px", sm: "400px", md: "600px", xl: "700px" },
              }}
            >
              <Typography
                sx={{ color: "#4CAF50" }}
              >{`Success: ${" "}`}</Typography>
              <Typography sx={{ color: "#373737" }}>{`${" " + items.length} ${
                items.length > 1 ? "Records" : "Record"
              } Updated Successfully`}</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                padding: "10px 30px 0px 30px",
              }}
            >
              <Typography
                sx={{
                  color: "#01537A",
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "30px",
                }}
              >
                You are updating {items.length} records , are you sure you want
                to make these changes? Some of these changes might be permanent.
              </Typography>

              <Divider
                sx={{
                  color: "#DFDFDF",
                  alignContent: "center",
                  marginTop: "40px",
                }}
              />
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          {!bulkLoading && !updateStatus && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 42px 30px 47px",
                gap: 2,
              }}
            >
              <Button
                className="modal-cancel-btn"
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                sx={{
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "unset !important",
                  },
                }}
                className="modal-save-btn"
                disabled={saveClick}
                onClick={handleBulkSave}
                variant="contained"
              >
                Save
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>

      {confirmationBox && (
        <ConfirmationPopup
          open={confirmationBox}
          handleClose={handleConfirmation}
          confirmSave={confirmSave}
          message={warnMsg}
          type={"warning"}
          buttonMode={getData(rowData?.value?.value, {
            Suspended: "Suspend",
            Deleted: "Delete",
            Live: "Live",
          })}
        />
      )}
    </Box>
  );
};

export default Applications;
