import React, { useEffect, useContext } from "react";
import Layout from "../../components/layout";
import { CookiesProvider } from "react-cookie";
import { Box, Divider, Typography, Paper, IconButton } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useResponsive from "../../components/useResponsive";
import { useState } from "react";
import Users from "../../components/User/users";
import ChangeLog from "../../components/AccountSettings/changeLog";
import Services from "../../api/services";
import defaultImg from "../../assets/img/defaultUserImg.png";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LogoPopUp from "../../components/logoPopUp";
import BreadCrumb from "../../components/breadCrumb";
import {
  switchStatusContext,
  archivedStatusContext,
  statusContext,
} from "../../context/serviceContext";
import Loader from "../../components/loader";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../context/userContext";
import useToast from "../../hooks/useToast";

const API_URL = process.env.REACT_APP_API_URL;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
  color: "#01537A",
  fontWeight: 500,
  "&.Mui-selected": {
    color: "#01537A",
  },
};

export default function LicenseeUserDetail() {
  const { showWarning } = useToast();
  const isMobile = useResponsive("down", "md");
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState(null);
  const [imgPopup, setImgPopUp] = useState(false);
  const userRole = localStorage.getItem("role");
  const [imgSrc, setImgSrc] = useState(defaultImg);
  const navigate = useNavigate();
  const uploadType = "user";
  const { userImage } = useUser();
  const switchStatusContextValue = JSON.parse(useContext(switchStatusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const statusContextVal = JSON.parse(useContext(statusContext));
  const accessPermissionFields = () => {
    if (switchStatusContextValue) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();
  const userId = location.state?.clickedUserId;
  const [userData, setUserData] = useState();
  const [roleData, setRoleData] = useState([]);
  const [customerData, setCustomerData] = useState();
  const getUserDetails = () => {
    setLoading(true);
    Services.userDetails({
      userId: userId,
      companyId: localStorage.getItem("companyId"),
    })
      .then((res) => {
        if (res?.data?.status === "failed") {
          navigate("/");
          showWarning(res?.data?.message);
          return;
        }
        setResData(res.data);
        setUserData(res.data.userDetails[0]);
        if (res.data.multipleRoleDetails[0].role !== "admin") {
          const customerId = localStorage.getItem("parentCompId");
          Services.LicenseeAccountSettings({ companyId: customerId })
            .then((res) => {
              setCustomerData(res?.data);
              setLoading(false);
            })
            .catch((err) => {});
        }
        if (
          res.data.userDetails[0].userImage !== null &&
          res.data.userDetails[0].userImage !== undefined
        ) {
          setImgSrc(API_URL + res.data.userDetails[0].userImage);
        }
        setRoleData(res.data.multipleRoleDetails);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const handleImgPop = () => {
    setImgPopUp(false);
  };
  const getImage = () => {
    setImgPopUp(true);
  };
  const refreshData = (imgValue) => {
    setImgSrc(imgValue);
  };

  const [breadCrumbData, setBreadCrumbData] = useState();
  const getBreadCrumbList = () => {
    Services.breadCrumbList(localStorage.getItem("companyId"))
      .then((res) => {
        setBreadCrumbData(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUserDetails();
    getBreadCrumbList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserDetails();

    if (userId === localStorage.getItem("userid")) {
      setImgSrc(userImage);
    }
    //eslint-disable-next-line
  }, [userImage]);
  return (
    <CookiesProvider>
      <Layout>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt="2px"
        >
          {loading ? (
            <Loader
              type={"spokes"}
              color={"#01537A"}
              height={40}
              width={40}
              loading={loading.toString()}
            />
          ) : null}
        </Box>
        <Box sx={{ padding: "0px 0px 15px 25px" }}>
          {customerData?.parentCompany && !loading ? (
            <BreadCrumb
              value={customerData}
              other={
                userData?.name.charAt(0).toUpperCase() + userData?.name.slice(1)
              }
            />
          ) : (
            ""
          )}
          {resData?.multipleRoleDetails[0].role === "admin" &&
          breadCrumbData?.parentCompany ? (
            <BreadCrumb
              value={breadCrumbData}
              other={
                userData?.name.charAt(0).toUpperCase() + userData?.name.slice(1)
              }
            />
          ) : (
            ""
          )}
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              padding: { xs: "10px 30px 40px 40px", md: "10px 30px 25px 90px" },
              columnGap: { xs: "0px", md: "27px" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingLeft: "60px",
                width: "172px",
                height: "171px",
                p: 1,
                m: 1,
                textAlign: "center",
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              <Paper
                style={{
                  background: "#fff",
                  width: "148px",
                  height: "148px",
                  borderRadius: "14PX",
                  color: "#01537A",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "6px 6px",
                }}
              >
                <img
                  style={{ borderRadius: "50%", padding: "2px 2px" }}
                  alt="Datafree"
                  src={imgSrc === null ? defaultImg : imgSrc}
                  rounded="lg"
                  width="135px"
                  height="135px"
                />
              </Paper>
              <IconButton
                style={{
                  position: "absolute",
                  background: !accessPermissionFields() ? "#01537A" : "#373737",
                  color: "#ffffff",
                  width: "22.71px",
                  height: "22.71px",
                  top: imgSrc === defaultImg ? "112px" : "120px",
                  fontSize: "15px",
                  right: "36px",
                  bottom: "0px",
                  cursor: !accessPermissionFields() ? "pointer" : "default",
                }}
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={!accessPermissionFields() ? getImage : null}
              >
                <EditOutlinedIcon fontSize="11px" />
              </IconButton>
            </Box>

            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                padding: { xs: "12px", md: 0 },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "400px",
                  fontSize: { xs: "30px", sm: "35px", md: "40px" },
                  lineHeight: "18px",
                  letterSpacing: "0.5px",
                  color: "#757373 !important",
                  fontStyle: "normal",
                }}
              >
                {userData && userData.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "26.11px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                  paddingTop: "20px",
                }}
              >
                User Details -{" "}
                {userRole === "ROLE_ADMIN"
                  ? "Admin View"
                  : userRole === "ROLE_LICENSEE"
                  ? "Licensee View"
                  : userRole === "ROLE_CHANNEL"
                  ? "Channel View"
                  : ""}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "13px",
                  lineHeight: "26px",
                  letterSpacing: "0.4px",
                  paddingTop: "20px",
                }}
              >
                <span style={{ color: "#01537A" }}> Email Address </span>{" "}
                <span style={{ marginLeft: "50px" }}>
                  {" "}
                  {userData && userData.email}
                </span>
              </Typography>
            </Box>
            {imgPopup && (
              <LogoPopUp
                open={imgPopup}
                handleClose={handleImgPop}
                imgs={imgSrc}
                defaultImg={defaultImg}
                compId={userId}
                refreshData={refreshData}
                uploadType={uploadType}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "0px 25px 0px 25px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Tabs
              sx={{
                maxwidth: {
                  xs: "402px",
                  md: "613px",
                  lg: "1440px",
                  xl: "1440px",
                },
                "& .MuiButtonBase-root-MuiTab-root": {
                  padding: " 15px 22px 15px 20px",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#01537A",
                },
              }}
              variant="scrollable"
              scrollButtons={isMobile ? true : false}
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tabStyle} label="users" {...a11yProps(0)} />
              <Tab sx={tabStyle} label="change log" {...a11yProps(1)} />
              <Tab sx={tabStyle} label="Activity Log" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <Divider
            sx={{
              color: "#DFDFDF",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              marginTop: "20px",
              borderBottomWidth: 2,
              width: "98%",
            }}
          ></Divider>

          <TabPanel value={value} index={0}>
            {loading && resData === null ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Loader
                  type={"spokes"}
                  color={"#01537A"}
                  height={40}
                  width={40}
                />
              </Box>
            ) : (
              <Users
                tab={value}
                userData={userData}
                roleData={roleData}
                refreshData={getUserDetails}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangeLog tab={value} type={"userDetailLog"} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ChangeLog tab={value} />
          </TabPanel>
        </Box>
      </Layout>
    </CookiesProvider>
  );
}
