import React, {useContext} from 'react';
import Box from '@mui/material/Box';
import Header from './header';
import Footer from './footer';
import Meta from './meta';
import ScrollToTop from "./scrollToTop"
import Banner from './banner';
import { switchStatusContext, statusContext } from '../context/serviceContext';
export default function Layout({ children}) {
    const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
    const statusContextVal = JSON.parse(useContext(statusContext));
    return (
        <Box>
            <Meta/>
            <Header />
            {(switchStatusContextVal && statusContextVal) && <Banner /> }
            <ScrollToTop />
            {children}
            <Footer />
        </Box>
    );
}