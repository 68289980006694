import React from "react";
import {
  ListItemText,
  ListItemAvatar,
  List,
  ListItem,
  Container,
  Button,
} from "@mui/material";
import Loader from "../../components/loader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import licenseeLogo from "../../assets/img/icon1.png";
import appLogo from "../../assets/img/Icons2.svg";
import channelLogo from "../../assets/img/Icons4.svg";
import userLogo from "../../assets/img/Icons8.svg";
import customerLogo from "../../assets/img/Icons7.svg";
import clockIcon from "../../assets/img/clockIcon.svg";
import dataIcon from "../../assets/img/dataIcon.svg";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 14,
    height: "35px",
    textAlign: "center",
    padding: "9px",
    backgroundColor: "#DFDFDF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
  },
}));

export default function SearchList(props) {
  const PRODUCT_WRAP = 1;
  const PRODUCT_MAX = 2;
  const PRODUCT_SWITCH = 3;
  const PRODUCT_REACH = 4;
  const PRODUCT_DIRECT = 5;
  const PRODUCT_CONNECT = 8;
  const PRODUCT_DDIRECT = 7;
  const handleChildData = () => {
    props.onData();
  };

  const imageUrl = (item) => {
    switch (true) {
      case item?.type === "CHANNEL":
        return channelLogo;
      case item?.type === "LICENSEE":
        return licenseeLogo;
      case item?.type === "CUSTOMER" || item?.type === "RESELLER":
        return customerLogo;
      case props.currentTab === 2:
        return userLogo;
      default:
        return appLogo;
    }
  };

  const getType = (item) => {
    switch (true) {
      case item?.role === "user" || item?.role === "reseller":
        return "Customer User";
      case item?.role === "admin":
        return "Admin";
      case item?.role === "licensee":
        return "Licensee User";
      case item?.role === "channel" || item?.role === "accountmanager":
        return "Channel User";
      default:
        return item?.role ? item?.role.toLowerCase() + " User" : "";
    }
  };

  const customerData = (item) => {
    const userRole = localStorage.getItem("role");
    switch (userRole) {
      case "ROLE_ADMIN":
        return (
          <div>
            <span>{`${
              item?.parentCompany?.[1]?.name
                ? item?.parentCompany?.[1]?.name
                : ""
            }`}</span>
            <span>{` > ${
              item?.parentCompany?.[2]?.name
                ? item?.parentCompany?.[2]?.name
                : ""
            }`}</span>
          </div>
        );
      case "ROLE_LICENSEE":
        return (
          <div>
            <span>{`${
              item?.parentCompany?.[1]?.name
                ? item?.parentCompany?.[1]?.name
                : ""
            }`}</span>
            <span>{` > ${
              item?.parentCompany?.[2]?.name
                ? item?.parentCompany?.[2]?.name
                : ""
            }`}</span>
          </div>
        );
      case "ROLE_CHANNEL":
        return (
          <div>
            <span>{`${
              item?.parentCompany?.[1]?.name
                ? item?.parentCompany?.[1]?.name
                : ""
            }`}</span>
            <span>{` > ${
              item?.parentCompany?.[2]?.name
                ? item?.parentCompany?.[2]?.name
                : ""
            }`}</span>
          </div>
        );
      case "ROLE_USER":
        return (
          <div>
            <span>{`${item?.parentCompanies?.parentCompanyId?.name}`}</span>
            <span>{` > ${item?.parentCompanies?.name}`}</span>
          </div>
        );
      default:
        break;
    }
  };
  const userData = (item) => {
    switch (true) {
      case item?.type === "CUSTOMER" || item?.type === "RESELLER":
        return (
          <div>
            <span>{`${item?.parentCompanies?.parentCompanyId?.name}`}</span>
            <span>{` > ${item?.parentCompanies?.name}`}</span>
          </div>
        );
      case item?.type === "CHANNEL":
        return <div>{`${item?.parentCompanies?.name}`}</div>;
      case item?.type === "LICENSEE":
        return <div>{`${item?.parentCompanies?.name}`}</div>;
      case item?.publisherName:
        return (
          <div>
            <span>{item?.parentCompanies?.parentCompanyId?.name}</span>
            <span>{` > ${item?.parentCompanies?.name}`}</span>
            <span>{` > ${item?.publisherName}`}</span>
          </div>
        );
      case item?.role === "user":
        return (
          <div>
            <span>{item?.parentCompanies?.parentCompanyId?.name}</span>
            <span>{` > ${item?.parentCompanies?.name}`}</span>
            <span>{` > ${item?.owner}`}</span>
          </div>
        );
      case item?.role === "channel":
        return (
          <div>
            <span>{item?.parentCompanies?.parentCompanyId?.name}</span>
            <span>{`${item?.parentCompanies?.name}`}</span>
            <span>{` > ${item?.owner}`}</span>
          </div>
        );

      case item?.role === "licensee":
        return (
          <div>
            <span>{` ${item?.parentCompanies?.name}`}</span>
            <span>{`${item?.owner}`}</span>
          </div>
        );
      case item?.role === "admin":
        return <div>{item?.owner}</div>;

      default:
        return <div>{item?.owner}</div>;
    }
  };
  const userDetails = (item) => {
    if (item?.companyId) {
      return (
        <>
          <span
            style={{
              color: "#5A5656",
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "15px",
            }}
          >
            {item?.type
              ? item?.type === "CUSTOMER" || item?.type === "RESELLER"
                ? "Customer"
                : item?.type.toLowerCase()
              : ""}
            {item?.companyType
              ? item?.tycompanyTypepe === "CUSTOMER" ||
                item?.companyType === "RESELLER"
                ? "Customer"
                : item?.companyType.toLowerCase()
              : ""}
          </span>
          {item?.companyId}
          {` | ${item?.status}`}
        </>
      );
    } else if (item?.applicationId) {
      switch (item?.productId.productId) {
        case PRODUCT_DIRECT:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  S-Direct App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              <span
                style={{ marginTop: "8px" }}
              >{`ID: ${item?.applicationId}`}</span>
              <span>{`Endpoint: ${item?.endpoint}`}</span>
              <span>{`Datafree Domain: ${item?.domain}`}</span>
              {item.port.length > 0
                ? item?.port.map((port, index) =>
                    index + 1 === item?.port.length
                      ? `${port?.portProtocol?.protocol} ${
                          port?.portStart !== null ? port?.portStart + "," : ""
                        }  ${port?.portEnd !== null ? port?.portEnd : ""}`
                      : `${port?.portProtocol?.protocol} ${
                          port?.portStart !== null ? port?.portStart + "," : ""
                        }  ${port?.portEnd !== null ? port?.portEnd : ""} | `
                  )
                : ""}
              <span style={{ marginTop: "8px" }}>
                {item.deploymentDetails.length > 0
                  ? item?.deploymentDetails.map((apps, index) => (
                      <span key={index}>
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ))
                  : ""}
                {dataUsage(item)}
              </span>
            </>
          );
        case PRODUCT_DDIRECT:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  D-Direct App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              <span
                style={{ marginTop: "8px" }}
              >{`ID: ${item?.applicationId}`}</span>
              <span>{`Client domain: ${item?.clientDomain}`}</span>
              <span>{`Endpoint: ${item?.endpoint}`}</span>
              <span>{`Datafree Domain: ${item?.domain}`}</span>

              <span style={{ marginTop: "8px" }}>
                {item.deploymentDetails.length > 0
                  ? item?.deploymentDetails.map((apps, index) => (
                      <span key={index}>
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ))
                  : ""}
                {dataUsage(item)}
              </span>
            </>
          );
        case PRODUCT_CONNECT:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  Connect App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              <span
                style={{ marginTop: "8px" }}
              >{`ID: ${item?.applicationId}`}</span>
              <span>{`Authentication: ${item?.authentication}`}</span>
              <span style={{ marginBottom: "8px" }}>{`No. of Apps: ${
                item?.appsCount ? item?.appsCount : "N/A"
              }`}</span>
              <span style={{ marginTop: "8px" }}>
                {item.deploymentDetails.length > 0
                  ? item?.deploymentDetails.map((apps, index) => (
                      <span key={index}>
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ))
                  : ""}
                {dataUsage(item)}
              </span>
            </>
          );
        case PRODUCT_WRAP:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  Wrap App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              {item.deploymentDetails.length > 0
                ? item?.deploymentDetails.map((apps, index) =>
                    apps?.deploymentType === "PROD" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Production </span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                        {dataUsage(item)}
                      </span>
                    ) : apps?.deploymentType === "DEV" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Development</span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </>
          );
        case PRODUCT_MAX:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  Max App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              {item.deploymentDetails.length > 0
                ? item?.deploymentDetails.map((apps, index) =>
                    apps?.deploymentType === "PROD" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Production </span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                        {dataUsage(item)}
                      </span>
                    ) : apps?.deploymentType === "DEV" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Development</span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </>
          );
        case PRODUCT_REACH:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  Reach App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              <span>{`${item.startUrl ? item.startUrl : "N/A"} | ${
                item.domain ? item.domain : "N/A"
              }`}</span>
              {item.deploymentDetails.length > 0
                ? item?.deploymentDetails.map((apps, index) =>
                    apps?.deploymentType === "PROD" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Production </span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                        {dataUsage(item)}
                      </span>
                    ) : apps?.deploymentType === "DEV" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Development</span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </>
          );
        case PRODUCT_SWITCH:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  Switch App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              <span>{`${item.domain ? item.domain : "N/A"}`}</span>
              {item.deploymentDetails.length > 0
                ? item?.deploymentDetails.map((apps, index) =>
                    apps?.deploymentType === "PROD" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Production </span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                        {dataUsage(item)}
                      </span>
                    ) : apps?.deploymentType === "DEV" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Development</span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </>
          );
        default:
          return "";
      }
    } else if (item?.userId) {
      return (
        <>
          <span>
            <span
              style={{
                color: "#5A5656",
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: "15px",
              }}
            >
              {" "}
              {getType(item)}
            </span>
            {` | ${item?.userVerified}`}
          </span>
        </>
      );
    }
  };

  const getCounts = (item) => {
    if (item?.companyId) {
      switch (true) {
        case item?.type === "LICENSEE":
          return (
            <>
              <span
                style={{
                  color: "#01537A",
                  textTransform: "capitalize",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {item?.noOfChannels
                  ? `${item?.noOfChannels} Channels`
                  : `0 Channels`}
                {item?.noOfPublishers
                  ? ` | ${item?.noOfPublishers} Customers`
                  : ` | 0 Customers`}
                {item?.noOfApps ? ` | ${item?.noOfApps} Apps` : ` | 0 Apps`}
              </span>
            </>
          );

        case item?.type === "CHANNEL":
          return (
            <>
              <span
                style={{
                  color: "#01537A",
                  textTransform: "capitalize",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {item?.noOfPublishers
                  ? `${item?.noOfPublishers} Customers`
                  : `0 Customers`}
                {item?.noOfApps ? ` | ${item?.noOfApps} Apps` : ` | 0 Apps`}
              </span>
            </>
          );
        case item?.type === "CUSTOMER" ||
          item?.type === "RESELLER" ||
          item?.companyType === "CUSTOMER":
          return (
            <>
              <span
                style={{
                  color: "#01537A",
                  textTransform: "capitalize",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {item?.noOfApps ? `${item?.noOfApps} Apps` : `0 Apps`}
              </span>
            </>
          );

        default:
          break;
      }
    }
  };

  const getDeploymentTypes = (item) => {
    if (item?.applicationId) {
      return (
        <>
          <span
            style={{
              color: "#01537A",
              textTransform: "capitalize",
              fontWeight: 500,
              fontSize: "16px",
              marginTop: "5px",
            }}
          >
            {item.deploymentDetails.length > 0
              ? item?.deploymentDetails.map((apps, index) => (
                  <span style={{ marginRight: "15px" }} key={index}>
                    {`${
                      apps?.deploymentType === "PROD"
                        ? "Production"
                        : apps?.deploymentType === "DEV"
                        ? "Development"
                        : ""
                    }`}{" "}
                  </span>
                ))
              : ""}
          </span>
        </>
      );
    }
  };
  const dataUsage = (item) => {
    if (item?.applicationId) {
      return (
        <Box
          component="span"
          sx={{
            color: "#5A5656",
            fontWeight: "300",
            fontSize: "13px",
            display: "flex",
          }}
        >
          <LightTooltip
            title="Last 28 day Datafree data usage"
            placement="right-start"
          >
            <img src={dataIcon} alt=""></img>
          </LightTooltip>
          <span style={{ marginTop: "1px", marginLeft: "5px" }}>
            {item.prodDataUsage28Days
              ? item.prodDataUsage28Days !== null
                ? item?.prodDataUsage28Days + " MB"
                : " N/A"
              : "N/A"}
          </span>
        </Box>
      );
    }
  };
  const updateActivity = (item) => {
    return (
      <>
        <Box
          component="span"
          sx={{
            color: "#5A5656",
            fontWeight: "300",
            fontSize: "13px",
            display: "flex",
          }}
        >
          <LightTooltip title="Last Activity" placement="right-start">
            <img src={clockIcon} alt=""></img>
          </LightTooltip>
          <span style={{ marginTop: "1px", marginLeft: "5px" }}>
            {moment(item).format("YYYY MMM DD hh:mm:ss")}
          </span>
        </Box>
      </>
    );
  };
  const ActivityDetails = (item) => {
    if (item?.lastActivity) {
      return (
        <>
          <Box
            component="span"
            sx={{
              color: "#5A5656",
              fontWeight: "300",
              fontSize: "13px",
              display: "flex",
            }}
          >
            <LightTooltip title="Last Activity" placement="right-start">
              <img src={clockIcon} alt=""></img>
            </LightTooltip>
            <span style={{ marginTop: "1px", marginLeft: "5px" }}>
              {moment(item?.lastActivity).format("YYYY MMM DD hh:mm:ss")}
            </span>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Box
            component="span"
            sx={{
              color: "#5A5656",
              fontWeight: "300",
              fontSize: "13px",
              display: "flex",
            }}
          >
            <LightTooltip title="Last Activity" placement="right-start">
              <img src={clockIcon} alt=""></img>
            </LightTooltip>
            <span style={{ marginTop: "1px", marginLeft: "5px" }}>N/A</span>
          </Box>
        </>
      );
    }
  };

  const selectedData = (selectedValue) => {
    props.selectedValues(selectedValue);
  };

  const switchedUser = JSON.parse(localStorage.getItem("switchedUser"));
  const switchRole = localStorage.getItem("switchRole");

  return (
    <Container
      maxWidth="xxl"
      style={{
        padding: "0",
        marginTop: props.changeBtn ? "0px" : "15px",
      }}
    >
      <List
        sx={{
          width: props.changeBtn && "100%",
          bgcolor: "background.paper",
        }}
      >
        {!props?.loading ? (
          (switchRole === "user" || switchRole === "customer") &&
          switchedUser ? (
            props.customerData && (
              <Box sx={{ display: "flex" }}>
                <ListItem
                  alignItems="flex-start"
                  style={{
                    padding: "0",
                    marginTop: props.changeBtn ? "0px" : "8px",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        height: 67,
                        width: 67,
                        mr: 2,
                      }}
                      alt="Remy Sharp"
                      src={imageUrl(props.customerData)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    style={{
                      color: "#0000008a",
                      fontSize: "14px",
                      textTransform: "uppercase",
                    }}
                    fontWeight="500"
                    primary={
                      <Typography
                        sx={{ display: "flex" }}
                        variant="body2"
                        component="span"
                        style={{
                          color: "rgba(0, 0, 0, 0.54)",
                          fontWeight: "500",
                          fontSize: "14px",
                          letterSpacing: "1.31822px",
                        }}
                        textTransform="uppercase"
                      >
                        {customerData(props.customerData)}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          sx={{ display: "flex" }}
                          component="label"
                          variant="body2"
                          style={{
                            color: "#01537A",
                            fontWeight: "500",
                            fontSize: "22px",
                          }}
                          textTransform="uppercase"
                        >
                          <span>{props.customerData?.name}</span>
                        </Typography>
                        <Typography
                          sx={{ display: "flex", flexDirection: "column" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                          textTransform="none"
                          style={{
                            color: "#5A5656",
                            fontWeight: "300",
                            fontSize: "16px",
                          }}
                        >
                          {userDetails(props.customerData)}
                          {props.customerData?.email ? (
                            <span>{props.customerData?.email}</span>
                          ) : (
                            ""
                          )}
                          {!props.customerData?.applicationId ? (
                            <span style={{ margnTop: "8px" }}>
                              {ActivityDetails(props.customerData)}
                            </span>
                          ) : (
                            ""
                          )}
                          {getCounts(props.customerData)}
                          {getDeploymentTypes(props.customerData)}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </Box>
            )
          ) : (
            props.data?.map((item, index) => (
              <Box key={index} sx={{ display: "flex" }}>
                <ListItem
                  alignItems="flex-start"
                  style={{
                    padding: "0",
                    marginTop: props.changeBtn ? "0px" : "8px",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        height: 67,
                        width: 67,
                        mr: 2,
                      }}
                      alt="Remy Sharp"
                      src={imageUrl(item)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    style={{
                      color: "#0000008a",
                      fontSize: "14px",
                      textTransform: "uppercase",
                    }}
                    fontWeight="500"
                    primary={
                      <Typography
                        sx={{ display: "flex" }}
                        variant="body2"
                        component="span"
                        style={{
                          color: "rgba(0, 0, 0, 0.54)",
                          fontWeight: "500",
                          fontSize: "14px",
                          letterSpacing: "1.31822px",
                        }}
                        textTransform="uppercase"
                      >
                        {userData(item)}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          sx={{ display: "flex" }}
                          component="label"
                          variant="body2"
                          style={{
                            color: "#01537A",
                            fontWeight: "500",
                            fontSize: "22px",
                          }}
                          textTransform="uppercase"
                        >
                          <span>{item?.name}</span>
                        </Typography>
                        <Typography
                          sx={{ display: "flex", flexDirection: "column" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                          textTransform="none"
                          style={{
                            color: "#5A5656",
                            fontWeight: "300",
                            fontSize: "16px",
                          }}
                        >
                          {userDetails(item)}
                          {item?.email ? <span>{item?.email}</span> : ""}
                          {!item?.applicationId ? (
                            <span style={{ margnTop: "8px" }}>
                              {ActivityDetails(item)}
                            </span>
                          ) : (
                            ""
                          )}
                          {getCounts(item)}
                          {getDeploymentTypes(item)}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
                {props.changeBtn ? (
                  <Button
                    className="btn-nonactive"
                    sx={{
                      width: "150px",
                      height: "40px",
                      alignItems: "center",
                      textTransform: "capitalize",
                      marginTop: "10px",
                    }}
                    onClick={handleChildData}
                  >
                    Change
                  </Button>
                ) : (
                  <Button
                    className="btn-nonactive"
                    sx={{
                      width: "150px",
                      height: "40px",
                      alignItems: "center",
                      textTransform: "capitalize",
                      marginTop: "10px",
                    }}
                    onClick={() => selectedData(item)}
                  >
                    Select
                  </Button>
                )}
              </Box>
            ))
          )
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="1rem"
          >
            <Loader
              type={"spokes"}
              color={"#01537A"}
              height={40}
              width={40}
            />
          </Box>
        )}
      </List>
    </Container>
  );
}
