import React, { useState } from "react";
import DataTable from "./dataTable";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Divider,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ScheduleReport from "./scheduleReport";
import Loader from "../../components/loader";
import moment from "moment";
import PaginationRounded from "../pagination";
import Services from "../../api/services";
import ConfirmationPopup from "../../components/confirmationPopup";
import {
  gridNumberComparator,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";
import UpdateScheduleReport from "./updateScheduleReport";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltips from "../../components/tooltips";
import useToast from "../../hooks/useToast";

export default function ScheduledTab({
  handleRowDelete,
  handleScheduleSearch,
  scheduleSearch,
  handleClickOpen,
  scheduleResData,
  scheduleLoading,
  handleClickClose,
  callScheduleReport,
  open,
  scheduleTotalCount,
  size,
  handlePageSize,
  changePagination,
  page,
  role,
  disableDelete,
  setConfirmPopUp,
  confirmPopUp,
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    distributionList: "",
    dayOfWeek: "",
  });
  const warnMsg = `Are your sure you want to delete this report? \n  This action cannot be undone.`;
  const [reportId, setReportId] = useState();
  const { showSuccess, showWarning } = useToast();
  const handleRowEdit = (data) => {
    setLoading(true);
    Services.getScheduleReport(data)
      .then((res) => {
        if (res?.status === 200) {
          setReportId(res?.data?.reportId);
          setData({
            ...data,
            distributionList: res?.data?.distributionEmail
              ? res?.data?.distributionEmail
              : "",
            dayOfWeek: res?.data?.dayOfWeek ? res?.data?.dayOfWeek : "",
          });
          setLoading(false);
        }
      })
      .catch((err) => {});
  };
  const idComparator = (v1, v2, param1, param2) => {
    return gridNumberComparator(v1.Id, v2.Id, param1, param2);
  };
  const nameComparator = (v1, v2, param1, param2) => {
    if (v1.vals !== "" && v2.vals !== "") {
      return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
    }
  };
  const emailRegex =
    /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,[\s]*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;

  const [saveClick, setSaveClick] = useState(false);

  const [userError, setUserError] = useState({
    distributionList: false,
    distributionListMsg: "",
    dayOfWeek: false,
    dayOfWeekMsg: "",
  });

  const days = [
    { id: 1, name: "Sunday" },
    { id: 2, name: "Monday" },
    { id: 3, name: "Tuesday" },
    { id: 4, name: "Wednesday" },
    { id: 5, name: "Thursday" },
    { id: 6, name: "Friday" },
    { id: 7, name: "Saturday" },
  ];
  const [currentRow, setCurrentRow] = useState("");
  const [editPanelOpen, setEditPanelOpen] = useState(false);
  const handleEditPanelClose = () => {
    setEditPanelOpen(false);
  };
  const confirmClick = () => {
    setConfirmPopUp(false);
    handleRowDelete(currentRow);
  };
  const closeConfirmationBox = () => {
    setConfirmPopUp(false);
  };
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "distributionList") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          distributionList: true,
          distributionListMsg: "This field is required",
        });
      } else {
        if (!emailRegex.test(e.target.value)) {
          setUserError({
            ...userError,
            distributionList: true,
            distributionListMsg: "Enter a valid list of email addresses",
          });
        } else {
          setUserError({
            ...userError,
            distributionList: false,
            distributionListMsg: "",
          });
        }

        const distributionEmailList = newData.distributionList
          .replace(/,\s+/g, ",")
          .split(",");
        distributionEmailList.pop();
        const enteredEmail = e.target.value.split(",");
        if (
          distributionEmailList.includes(
            enteredEmail[enteredEmail.length - 1].trim()
          )
        ) {
          newData[e.target.name] = distributionEmailList;
        }
      }
    } else if (e.target.name === "dayOfWeek") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          dayOfWeek: true,
          dayOfWeekMsg: "This field is required",
        });
      } else {
        setUserError({
          ...userError,
          dayOfWeek: false,
          dayOfWeekMsg: "",
        });
      }
    }
    setData(newData);
  };

  const validate = (data) => {
    const userErrors = { ...userError };
    const objString = JSON.stringify(data?.distributionList);
    const hasComma = objString.includes(",");
    let count = 0;

    if (data?.dayOfWeek === "") {
      userErrors.dayOfWeek = true;
      userErrors.dayOfWeekMsg = "This field is required";
      count++;
    } else {
      userErrors.dayOfWeek = false;
      userErrors.dayOfWeekMsg = "";
    }
    if (hasComma) {
      const alertEmailList = objString.replace(/,\s+/g, ",").split(",");
      alertEmailList.pop();
      const enteredEmail = objString.split(",");
      if (
        alertEmailList.includes(enteredEmail[enteredEmail.length - 1].trim())
      ) {
        data.distributionList = alertEmailList;
        userErrors.distributionList = true;
        userErrors.distributionListMsg = "Email already exists.";
        count++;
      }
    }
    if (data.distributionList === "") {
      userErrors.distributionList = true;
      userErrors.distributionListMsg = "This field is required";
      count++;
    } else if (!emailRegex.test(data.distributionList)) {
      userErrors.distributionList = true;
      userErrors.distributionListMsg = "Enter a valid list of email addresses";
      count++;
    } else {
      userErrors.distributionList = false;
      userErrors.distributionListMsg = "";
    }

    setUserError(userErrors);
    return count;
  };
  const handleSave = () => {
    let errorCount = validate(data);
    if (errorCount === 0) {
      let payload = {
        reportId: reportId,
        dayOfWeek: data?.dayOfWeek,
        distributionEmail: data?.distributionList,
      };
      setSaveClick(true);
      Services.updateScheduleReport(payload)
        .then((res) => {
          if (res?.data?.status === "success") {
            showSuccess(res?.data.message);
            callScheduleReport(true);
            handleCancel();
          } else {
            showWarning(res?.data.message);
            handleCancel();
          }
        })
        .catch((err) => {});
    }
  };

  const handleCancel = () => {
    setUserError({
      distributionList: false,
      distributionListMsg: "",
      dayOfWeek: false,
      dayOfWeekMsg: "",
    });
    setData({
      distributionList: "",

      dayOfWeek: "",
    });
    handleEditPanelClose();
    setSaveClick(false);
  };

  const renderValueOfDays = (selected, placeholder) => {
    if (!selected) {
      return (
        <Typography
          sx={{
            color: "#000000",
            fontSize: "16px",
          }}
        >
          {placeholder}
        </Typography>
      );
    }

    return selected;
  };

  const columns = [
    {
      flex: 1,
      field: "reportId",
      headerName: "Report ID",

      width: 141,
      valueGetter: (value, row) => ({
        Id: row.reportID,
      }),
      headerClassName: "super-app-theme--header",
      sortComparator: idComparator,
      renderCell: (params) => {
        return (
          <div>
            <span>{params?.row?.reportID ? params.row.reportID : ""}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "application",
      headerName: "Application",

      width: 159,
      sortable: false,
      valueGetter: (value, row) => ({
        vals: row.applicationID,
      }),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <span>
              {params?.row?.applicationID ? params?.row?.applicationID : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "appId",
      headerName: "Datafree App ID",

      width: 199,
      valueGetter: (value, row) => ({
        vals: row.appID,
      }),
      headerClassName: "super-app-theme--header",
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span>{params?.row?.appID ? params?.row?.appID : ""}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "distributionList",
      headerName: "Distribution List",

      width: 350,
      valueGetter: (value, row) => ({
        vals: row.distributionEmail,
      }),
      headerClassName: "super-app-theme--header",
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span>
              {params?.row?.distributionEmail
                ? params?.row?.distributionEmail
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "dow",
      headerName: "DOW",

      width: 160,
      sortable: true,
      sortComparator: nameComparator,
      renderHeader: () => (
        <>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "15px",
              color: "#fff",
            }}
          >
            DOW
          </Typography>
          <Tooltips type="dow">
            <InfoOutlinedIcon
              sx={{
                fontSize: "18px",
                color: "#fff",
                marginBottom: "2px",
                marginLeft: "8px",
              }}
            />
          </Tooltips>
        </>
      ),
      valueGetter: (value, row) => ({
        vals: row.DOW,
      }),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <span>{params?.row?.DOW ? params?.row?.DOW : ""}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "created",
      headerName: "Created",

      width: 310,
      sortComparator: nameComparator,
      valueGetter: (value, row) => ({
        vals: row.createdDate,
      }),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <span>
              {params.row.createdDate
                ? moment(
                    params?.row?.createdDate ? params?.row?.createdDate : ""
                  ).format("Do MMM YYYY [GMT]")
                : "-"}
            </span>
          </div>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      flex: 1,
      field: "actions",
      headerName: "Actions",
      minWidth: 180,
      disableClickEventBubbling: true,
      sortable: false,

      renderCell: (params) => {
        const onDelete = (e) => {
          setConfirmPopUp(true);
          setCurrentRow(params?.row?.reportID);
        };
        const onEdit = (e) => {
          const currentRow = params?.row?.reportID;
          setEditPanelOpen(true);
          handleRowEdit(currentRow);
        };
        return (
          <Box
            sx={{
              display: "flex",
              gap: "30px",
              marginTop: "14px",
            }}
          >
            {disableDelete ? (
              <DeleteOutlineOutlinedIcon
                sx={{
                  color: "#B3261E",
                  cursor: "pointer",
                  fontSize: "1.2rem",
                }}
              />
            ) : (
              <DeleteOutlineOutlinedIcon
                sx={{
                  color: "#B3261E",
                  cursor: "pointer",
                  fontSize: "1.2rem",
                }}
                onClick={onDelete}
              />
            )}

            {role !== "ROLE_USER" && (
              <>
                <CreateOutlinedIcon
                  sx={{
                    color: "#01537A",
                    cursor: "pointer",
                    fontSize: "1.2rem",
                  }}
                  onClick={onEdit}
                />
              </>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          autoComplete="off"
          type="search"
          sx={{
            width: "45%",
            "& input::placeholder": {
              color: "black",
              fontSize: "16px",
              opacity: "1",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon sx={{ fontSize: "25px" }} />
              </InputAdornment>
            ),
          }}
          onKeyDown={handleScheduleSearch}
          name="scheduleSearch"
          defaultValue={scheduleSearch ? scheduleSearch : ""}
          placeholder="Search"
          variant="standard"
        />

        <Button
          variant="outlined"
          sx={{
            textTransform: "capitalize",
            padding: "6px 16px ",
            color: "#01537A",

            border: "1px solid #01537A!important ",
          }}
          onClick={handleClickOpen}
        >
          Schedule new Reports
        </Button>
      </Box>
      <Divider
        sx={{
          color: "#757373",
          alignContent: "center",
          marginBottom: "30px",
          marginTop: "5px",
        }}
      />
      {scheduleResData?.length !== 0 && !scheduleLoading ? (
        <>
          <Box
            sx={{
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#01537A !important",
                color: "white",
              },
            }}
          >
            <DataTable
              columns={columns}
              rows={scheduleResData}
              loading={scheduleLoading}
            />
          </Box>
          {!scheduleLoading && scheduleTotalCount >= 10 && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Typography
                component="span"
                variant="body2"
                sx={{ color: "#01537A" }}
              >
                Items per page :{" "}
              </Typography>
              <FormControl variant="standard">
                <Select
                  value={size}
                  sx={{
                    width: "55px",
                    margin: "0px 10px 0px 5px",
                    backgroundColor: "#8080802b",
                    color: "#000",
                    borderBottom: "1px solid #01537A",
                    textAlign: "center",
                    "&:after": {
                      borderBottom: "1.5px solid #01537A",
                    },
                    "&hover": {
                      borderBottom: "1px solid #01537A",
                    },
                  }}
                  onChange={handlePageSize}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>{Number(10).toLocaleString()}</MenuItem>
                  <MenuItem value={25}>{Number(25).toLocaleString()}</MenuItem>
                  <MenuItem value={50}>{Number(50).toLocaleString()}</MenuItem>
                  <MenuItem value={100}>
                    {Number(100).toLocaleString()}
                  </MenuItem>
                </Select>
              </FormControl>
              <PaginationRounded
                changeHandle={changePagination}
                page={page + 1}
                Count={scheduleTotalCount}
                size={size}
              />
            </Box>
          )}
        </>
      ) : scheduleResData?.length === 0 && !scheduleLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1rem",
            padding: "1rem",
            color: "#5A5656",
            height: "285px",
            width: "100%",
          }}
        >
          No scheduled reports to show
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader
            type={"spokes"}
            color={"#01537A"}
            height={40}
            width={40}
          />
        </Box>
      )}
      <ScheduleReport
        open={open}
        handleClose={handleClickClose}
        callScheduleReport={callScheduleReport}
      />
      {confirmPopUp && (
        <ConfirmationPopup
          open={confirmPopUp}
          handleClose={closeConfirmationBox}
          message={warnMsg}
          confirmSave={confirmClick}
          type="warning"
          buttonMode="Deleted"
        />
      )}
      <UpdateScheduleReport
        editPanelOpen={editPanelOpen}
        handleEditPanelClose={handleEditPanelClose}
        loading={loading}
        handleChange={handleChange}
        userError={userError}
        data={data}
        saveClick={saveClick}
        handleSave={handleSave}
        renderValueOfDays={renderValueOfDays}
        days={days}
        handleCancel={handleCancel}
      />
    </>
  );
}
