import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "chartjs-adapter-date-fns";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { Divider, Typography } from "@mui/material";
import Loader from "react-loading";
import { formatNumberWithSpaces } from "../utils/formatNumber";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

export default function Graph({
  heading,
  yAxis,
  xAxis,
  resData,
  type,
  loading,
  selected,
}) {
  const [allRange, setAllRange] = useState([]);
  const [transformedData, setTransformedData] = useState({
    totaldatafreegb: [],
    totaldatagb: [],
  });

  useEffect(() => {
    if (type === "dashboard") {
      setAllRange(resData ?? []);
    }
  }, [resData, type]);

  function getAll(categories, inputData) {
    if (categories?.length !== 0) {
      return categories.reduce((acc, category) => {
        acc[category] = inputData.map((item) => [
          new Date(item.date).getTime(),
          item[category],
        ]);
        return acc;
      }, {});
    }
  }

  useEffect(() => {
    let categories = [];
    if (type === "dashboard") {
      categories = ["totaldatafreegb", "otherdata"];
    }
    const data = getAll(categories, allRange);
    setTransformedData(data);
  }, [allRange, type]);

  const xAxisLabel =
    type === "dashboard" ? resData && resData.map((item) => item.date) : [];

  const option = {
    tooltip: {
      hideEmptySeries: false,
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let total = 0;
        let yAxis = "";
        let content = "";
        const xAxisValue = w.globals.seriesX[0][dataPointIndex];

        const formattedXAxisValue = moment(xAxisValue).format("DD/MM/YYYY");

        content += `<div class="header-box"><span style= "font-size: 16px;"> Summary</span><span  style="font-weight:600 ;font-size: 16px;"> :</span><span  style="font-weight:600 ;margin-left:5px ;font-size: 16px;">${formattedXAxisValue}</span><br></div>`;

        series.forEach((s, index) => {
          const name = w.globals.seriesNames[index];
          const value = s[dataPointIndex];
          const color = w.globals.stroke.colors[index];

          total += value;
          yAxis += `<div style="display: flex; justify-content: space-between; align-items: center;  width: calc(100% - 10px);;">
    
              <div style="display: flex;">
              <div style="color:${color};font-size: 30px;">&#x25CF;</div>
               <div style="font-size: 16px;margin-top:10px">${name}</div>
               </div>
               <div style="font-size: 16px;margin-top:5px"> ${
                 isNaN(value) ? 0 : formatNumberWithSpaces(value)
               }<br>
               </div>
               </div>`;
        });

        content += `<div class="middle-box">${yAxis}</div>`;
        content +=
          type !== "singleRange2"
            ? `<div class="footer-box"><span style="font-size: 16px;">Total ${
                type === "singleRange3" ? "Users" : ""
              }</span><span style="font-size: 16px; margin-left:17px"> ${
                isNaN(total) ? 0 : formatNumberWithSpaces(total)
              }</span></div>`
            : "";

        return '<div class="custom-tooltip">' + content + "</div>";
      },
    },
    chart: {
      id: "area-datetime",
      type: "area",
      stacked: true,
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    colors:
      type === "allRange"
        ? [
            "rgba(76, 175, 80, 1)",
            "rgba(251, 140, 0, 1)",
            "rgba(0, 148, 205, 1)",
            "rgba(1, 83, 122, 1)",
          ]
        : type === "allToday"
        ? ["rgba(1, 83, 122, 1)", "rgba(0, 148, 205, 1)"]
        : type === "dashboard"
        ? ["rgba(0, 148, 205, 1)", "rgba(1, 83, 122, 1)"]
        : type === "singleRange1"
        ? [
            "rgba(76, 175, 80, 1)",
            "rgba(251, 140, 0, 1)",
            "rgba(0, 148, 205, 1)",
            "rgba(1, 83, 122, 1)",
          ]
        : type === "singleRange2"
        ? ["rgba(1, 83, 122, 1)"]
        : type === "singleRange3"
        ? ["rgba(76, 175, 80, 1)", "rgba(251, 140, 0, 1)"]
        : type === "dataUsageUpDown"
        ? ["rgba(1, 83, 122, 0.7)", "rgba(76, 175, 80, 0.7)"]
        : [
            "rgba(76, 175, 80, 1)",
            "rgba(251, 140, 0, 1)",
            "rgba(0, 148, 205, 1)",
            "rgba(1, 83, 122, 1)",
          ],
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      borderColor: "#D3D6DF",
      strokeDashArray: 7,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },

      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: true,
      seriesName: undefined,
      opposite: false,
      reversed: false,
      logarithmic: false,
      logBase: 10,
      tickAmount: undefined,
      forceNiceScale: false,
      floating: false,
      decimalsInFloat: undefined,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "12px",
          fontWeight: 300,
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
      },
      axisBorder: {
        show: false,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      title: {
        text: yAxis,
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      crosshairs: {
        show: false,
        position: "back",
        stroke: {
          color: "#b6b6b6",
          width: 2,
          dashArray: 0,
        },
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
    },
    stroke: {
      colors:
        type === "allRange"
          ? [
              "rgba(76, 175, 80, 1)",
              "rgba(251, 140, 0, 1)",
              "rgba(0, 148, 205, 1)",
              "rgba(1, 83, 122, 1)",
            ]
          : type === "allToday"
          ? ["rgba(1, 83, 122, 1)", "rgba(251, 140, 0, 1)"]
          : type === "dashboard"
          ? ["rgba(0, 148, 205, 1)", "rgba(1, 83, 122, 1)"]
          : type === "singleRange1"
          ? [
              "rgba(76, 175, 80, 1)",
              "rgba(251, 140, 0, 1)",
              "rgba(0, 148, 205, 1)",
              "rgba(1, 83, 122, 1)",
            ]
          : type === "singleRange2"
          ? ["rgba(1, 83, 122, 1)"]
          : type === "singleRange3"
          ? ["rgba(76, 175, 80, 1)", "rgba(251, 140, 0, 1)"]
          : type === "dataUsageUpDown"
          ? ["rgba(1, 83, 122, 0.7)", "rgba(76, 175, 80, 0.7)"]
          : [
              "rgba(76, 175, 80, 1)",
              "rgba(251, 140, 0, 1)",
              "rgba(0, 148, 205, 1)",
              "rgba(1, 83, 122, 1)",
            ],
      width: 2,
    },
    fill: {
      type: "solid",
      colors:
        type === "allRange"
          ? [
              "rgba(76, 175, 80, 0.15)",
              "rgba(251, 140, 0, 0.15)",
              "rgba(0, 148, 205, 0.15)",
              "rgba(1, 83, 122, 0.15)",
            ]
          : type === "allToday"
          ? ["rgba(1, 83, 122, 0.15)", "rgba(251, 140, 0, 0.15)"]
          : type === "dashboard"
          ? ["rgba(0, 148, 205,  0.15)", "rgba(1, 83, 122,  0.15)"]
          : type === "singleRange1"
          ? [
              "rgba(76, 175, 80, 0.15)",
              "rgba(251, 140, 0, 0.15)",
              "rgba(0, 148, 205, 0.15)",
              "rgba(1, 83, 122, 0.15)",
            ]
          : type === "singleRange2"
          ? ["rgba(1, 83, 122, 0.15)"]
          : type === "singleRange3"
          ? ["rgba(76, 175, 80, 0.15)", "rgba(251, 140, 0, 0.15)"]
          : type === "dataUsageUpDown"
          ? ["rgba(1, 83, 122, 0.7)", "rgba(76, 175, 80, 0.7)"]
          : [
              "rgba(76, 175, 80, 0.15)",
              "rgba(251, 140, 0, 0.15)",
              "rgba(0, 148, 205, 0.15)",
              "rgba(1, 83, 122, 0.15)",
            ],
    },
    title: {
      text: heading,
      align: "left",
      style: {
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Roboto",
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    legend: {
      position: "bottom",
      showForSingleSeries: false,
      horizontalAlign: "center",
      onItemHover: {
        highlightDataSeries: false,
      },
      onItemClick: {
        toggleDataSeries: false, // Prevents series from being toggled
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",

        fillColors:
          type === "allRange"
            ? [
                "rgba(76, 175, 80, 1)",
                "rgba(251, 140, 0, 1)",
                "rgba(0, 148, 205, 1)",
                "rgba(1, 83, 122, 1)",
              ]
            : type === "allToday"
            ? ["rgba(1, 83, 122, 1)", "rgba(251, 140, 0, 1)"]
            : type === "dashboard"
            ? ["rgba(0, 148, 205, 1)", "rgba(1, 83, 122, 1)"]
            : type === "singleRange1"
            ? [
                "rgba(76, 175, 80, 1)",
                "rgba(251, 140, 0, 1)",
                "rgba(0, 148, 205, 1)",
                "rgba(1, 83, 122, 1)",
              ]
            : type === "singleRange2"
            ? ["rgba(1, 83, 122, 1)"]
            : type === "singleRange3"
            ? ["rgba(76, 175, 80, 1)", "rgba(251, 140, 0, 1)"]
            : type === "dataUsageUpDown"
            ? ["rgba(1, 83, 122, 0.7)", "rgba(76, 175, 80, 0.7)"]
            : [
                "rgba(76, 175, 80, 1)",
                "rgba(251, 140, 0, 1)",
                "rgba(0, 148, 205, 1)",
                "rgba(1, 83, 122, 1)",
              ],
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    xaxis: {
      type: xAxisLabel && xAxisLabel.length < 32 ? "category" : "datetime",
      labels:
        xAxisLabel?.length < 32
          ? {
              formatter: function (val, timestamp) {
                return moment(timestamp).format("DD/MM");
              },
              style: {
                colors: [],
                fontSize: "12px",
                fontWeight: 700,
              },
              rotate: -45,
            }
          : {
              rotate: -45,
              style: {
                colors: [],
                fontSize: "12px",
                fontWeight: 700,
              },
            },

      title: {
        text: xAxis,
        offsetX: -10,
        offsetY: xAxisLabel?.length <= 31 ? -14 : 0,
        style: {
          color: undefined,
          fontSize: "12px",
          fontWeight: 600,
        },
      },
      axisBorder: {
        show: true,
        color: "#01537A",
        height: 1,
        width: "100%",
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#01537A",
        height: 6,
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
    },
  };

  const series =
    type === "dashboard"
      ? [
          {
            name: "Datafree Data",
            data: transformedData?.totaldatafreegb ?? [],
          },
          {
            name: "Other Data",
            data: transformedData?.otherdata ?? [],
          },
        ]
      : [];

  const showData = () => {
    if (resData) {
      if (Array.isArray(resData)) {
        if (resData.length === 0) {
          return true;
        } else {
          if (type === "allRange") {
            if (
              resData?.resultData?.free &&
              resData?.resultData?.free.map((item) => item[0]).length === 0
            ) {
              return true;
            } else {
              return false;
            }
          } else if (type === "singleRange1") {
            if (
              resData?.resultData?.free &&
              resData?.resultData?.free.map((item) => item[0]).length === 0
            ) {
              return true;
            } else {
              return false;
            }
          } else if (type === "singleRange2") {
            if (
              resData?.userDataUsage?.datausagePerUser?.mbperuser &&
              resData?.userDataUsage?.datausagePerUser?.mbperuser.map(
                (item) => item[0]
              ).length === 0
            ) {
              return true;
            } else {
              return false;
            }
          } else if (type === "singleRange3") {
            if (
              resData?.totalUsers?.usersReport?.existingusers &&
              resData?.totalUsers?.usersReport?.existingusers.map(
                (item) => item[0]
              ).length === 0
            ) {
              return true;
            } else {
              return false;
            }
          } else if (type === "dashboard") {
            if (
              resData?.totaldatafreegb &&
              resData?.totaldatafreegb.map((item) => item[0]).length === 0
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else {
        if (resData?.resultData && resData?.resultData.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      if (type === "allRange") {
        if (
          resData?.resultData?.free &&
          resData?.resultData?.free.map((item) => item[0]).length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else if (type === "singleRange1") {
        if (
          resData?.resultData?.free &&
          resData?.resultData?.free.map((item) => item[0]).length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else if (type === "singleRange2") {
        if (
          resData?.userDataUsage?.datausagePerUser?.mbperuser &&
          resData?.userDataUsage?.datausagePerUser?.mbperuser.map(
            (item) => item[0]
          ).length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else if (type === "singleRange3") {
        if (
          resData?.totalUsers?.usersReport?.existingusers &&
          resData?.totalUsers?.usersReport?.existingusers.map((item) => item[0])
            .length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else if (type === "dashboard") {
        if (
          resData?.totaldatafreegb &&
          resData?.totaldatafreegb.map((item) => item[0]).length === 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (type === "allRange") {
        if (
          resData?.resultData?.free &&
          resData?.resultData?.free.map((item) => item[0]).length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else if (type === "singleRange1") {
        if (
          resData?.resultData?.free &&
          resData?.resultData?.free.map((item) => item[0]).length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else if (type === "singleRange2") {
        if (
          resData?.userDataUsage?.datausagePerUser?.mbperuser &&
          resData?.userDataUsage?.datausagePerUser?.mbperuser.map(
            (item) => item[0]
          ).length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else if (type === "singleRange3") {
        if (
          resData?.totalUsers?.usersReport?.existingusers &&
          resData?.totalUsers?.usersReport?.existingusers.map((item) => item[0])
            .length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else if (type === "dashboard") {
        if (
          resData?.totaldatafreegb &&
          resData?.totaldatafreegb.map((item) => item[0]).length === 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
  return (
    <Box sx={{ padding: "10px" }}>
      {showData() && !loading ? (
        <>
          {type !== "dashboard" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    letterSpacing: "0px",
                  }}
                >
                  {heading}
                </Typography>

                <MenuIcon
                  sx={{
                    color: "#6e8192",
                    fontSize: "21px",
                  }}
                />
              </Box>

              <Divider
                sx={{ marginBottom: "20px", border: "1px solid #B1AEAE" }}
              />
            </>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
              padding: "1rem",
              color: "#5A5656",
              height: "285px",
              width: "100%",
            }}
          >
            No reports to show
          </Box>
        </>
      ) : loading ? (
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            height: "300px",
            alignItems: "center",
          }}
        >
          <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
        </Box>
      ) : (
        <Box sx={{ height: { lg: "360px", xl: "500px" } }}>
          <ReactApexChart
            options={option}
            series={series}
            type="area"
            height="100%"
            width="100%"
          />
        </Box>
      )}
    </Box>
  );
}
