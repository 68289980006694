import React, { createContext, useState, useContext } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userImage, setUserImage] = useState();

  const updateUserImage = (newImage) => {
    setUserImage(newImage);
  };

  return (
    <UserContext.Provider value={{ userImage, updateUserImage }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
