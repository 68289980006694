import React from "react";
import { useState, useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import {
  Box,
  Container,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  FormControl,
  Paper,
  FormHelperText,
  IconButton,
  InputBase,
  Button,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SearchIcon from "@mui/icons-material/Search";
import reach from "../../assets/img/reach.svg";
import whiteReach from "../../assets/img/whiteReach.svg";
import wrap from "../../assets/img/wrap.svg";
import whiteWrap from "../../assets/img/whiteWrap.svg";
import whiteSwitch from "../../assets/img/whiteSwitch.svg";
import Switch from "../../assets/img/switch.svg";
import CompanySearchModal from "../../components/Applications/companySearchModal";
import SearchList from "../../components/Applications/searchList";
import AddReachApplication from "../../components/Applications/Reach/createApplication";
import AddWrapApplication from "../../components/Applications/Wrap/createApplication";
import AddSwitchApplication from "../../components/Applications/Switch/createApplication";
import greyReach from "../../assets/img/greyReach.svg";
import greyWrap from "../../assets/img/greyWrap.svg";
import greySwitch from "../../assets/img/greySwitch.svg";
import Services from "../../api/services";
import BreadCrumb from "../../components/breadCrumb";
import Loader from "../../components/loader";
import useToast from "../../hooks/useToast";
import { regexPatterns } from "../../config/regex";

export default function AddApplication() {
  const [selectedApp, setSelectedApp] = useState("");
  const [listOpen, setListOpen] = useState(false);
  const [allData, setAllData] = useState();
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [searchData, setSearchData] = useState();
  const [role, setRole] = useState();
  const [gatewayDomains, setGatewayDomains] = useState([]);
  const [customerData, setCustomerData] = useState();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [breadCrumbData, setBreadCrumbData] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [load, setLoad] = useState(false);
  // eslint-disable-next-line
  let pattern = /^https:\/\/[^/].*/;
  let regexSubdomain = /^[a-zA-Z][a-zA-Z0-9]{2,}$/i;
  let appNameLengthRegex = /^.{0,32}$/;
  const { showSuccess, showError } = useToast();

  let maxBytesContentPattern =
    /(?!0+$)(^[0-9]+$)|(^[0-9]+(.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(,[0-9]{2,4})+(.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;

  let contentTypePattern = /^[a-z]+\/(\*|[a-z]+[-+.a-z0-9]*)$/m;
  const switchRole = localStorage.getItem("switchRole");
  const switchedUser = JSON.parse(localStorage.getItem("switchedUser"));

  let navigate = useNavigate();
  const [userData, setUserData] = useState({
    applicationName: "",
    websiteURL: "",
    isNatGatewayRequired: 0,
    subDomain: "",
    gatewayDomain: "",
    publisherId:
      localStorage.getItem("role") === "ROLE_USER"
        ? localStorage.getItem("companyId")
        : (switchRole === "user" || switchRole === "customer") && switchedUser
        ? localStorage.getItem("customerId")
        : "",
    applicationType: "",
    deploymentType: "DEV",
    editorId: localStorage.getItem("userid"),
    serverProvider: "",
    maxBytes: "",
    contentType: "",
  });
  const [userError, setUserError] = useState({
    applicationName: false,
    applicationMsg: "",
    websiteURL: false,
    websiteURLMsg: "",
    subDomain: false,
    subDomainMsg: "",
    pubField: false,
    pubFieldMsg: "",
    gatewayDomain: false,
    gatewayDomainMsg: "",
    natGateway: false,
    natGatewayMsg: "",
    sudDomainError: "",
    subDomainErr: false,
  });
  const areAllReachFieldsFilled =
    userData.applicationName !== "" &&
    userError.applicationMsg === "" &&
    userData.gatewayDomain !== "" &&
    userData.websiteURL !== "" &&
    userError.gatewayDomainMsg === "" &&
    userError.natGatewayMsg === "" &&
    userError.subDomainMsg === "" &&
    pattern.test(userData.websiteURL) === true &&
    regexSubdomain.test(userData.subDomain) === true &&
    userData.subDomain !== "";

  const areAllWrapFieldsFilled =
    userData.applicationName !== "" &&
    userError.applicationMsg === "" &&
    userData.gatewayDomain !== "" &&
    userData.websiteURL !== "" &&
    userError.gatewayDomainMsg === "" &&
    userError.natGatewayMsg === "" &&
    pattern.test(userData.websiteURL) === true;

  const areAllSwitchFieldsFilled =
    userData.applicationName !== "" &&
    userError.applicationMsg === "" &&
    userData.gatewayDomain !== "" &&
    userError.gatewayDomainMsg === "" &&
    userError.natGatewayMsg === "";

  const getRoles = () => {
    const userRole = localStorage.getItem("role");
    if (userRole === "ROLE_ADMIN") {
      setRole("admin");
    } else if (userRole === "ROLE_LICENSEE") {
      setRole("licensee");
    } else if (userRole === "ROLE_CHANNEL") {
      setRole("channel");
    } else {
      setRole("customer");
    }
  };

  const handleData = () => {
    setSearchModalOpen(true);
  };

  const searchModal = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchData(e.target.value.trim());
      setUserError({
        ...userError,
        pubField: false,
        pubFieldMsg: "",
      });
      setSearchModalOpen(true);
    }
  };

  const companyValues = async (data) => {
    const copyData = { ...userData };
    copyData["publisherId"] = data.companyId;
    setUserData(copyData);
    setAllData([data]);
    setSearchModalOpen(false);
    setListOpen(true);
    setUserError({
      ...userError,
      gatewayDomain: false,
      gatewayDomainMsg: "",
      natGateway: false,
      natGatewayMsg: "",
      subDomain: false,
      subDomainMsg: "",
    });
    getData(data?.companyId, userData?.isNatGatewayRequired);

    if (gatewayDomains?.length === 1) {
      const data = await checkDomainExist(
        userData?.subDomain,
        gatewayDomains[0]?.gateway
      );
      if (data?.status === true) {
        setUserError({
          ...userError,
          gatewayDomain: true,
          gatewayDomainMsg: data?.message,
          subDomain: false,
          subDomainMsg: "",
        });
      } else if (data?.status === false) {
        setUserError({
          ...userError,
          gatewayDomain: false,
          gatewayDomainMsg: "",
          subDomain: false,
          subDomainMsg: "",
        });
      }
    }
  };
  const handleInputChange = async (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "applicationName") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          applicationName: true,
          applicationMsg: "This field is required",
        });
      } else {
        Services.checkApplicationName({ applicationName: e.target.value })
          .then((res) => {
            if (
              e.target.value === "" ||
              e.target.value.match(/^ *$/) !== null
            ) {
              setUserError({
                ...userError,
                applicationName: true,
                applicationMsg: "This field is required",
              });
            } else if (res.data.status === true) {
              setUserError({
                ...userError,
                applicationName: true,
                applicationMsg: "Application name already exists",
              });
            } else if (!regexPatterns.appNameRegex.test(e.target.value)) {
              setUserError({
                ...userError,
                applicationName: true,
                applicationMsg: "No special characters are allowed",
              });
            } else if (!appNameLengthRegex.test(e.target.value)) {
              setUserError({
                ...userError,
                applicationName: true,
                applicationMsg: "Cannot exceed more than 32 characters",
              });
            } else {
              setUserError({
                ...userError,
                applicationName: false,
                applicationMsg: "",
              });
            }
          })
          .catch((err) => {});
      }
    }

    if (e.target.name === "websiteURL") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          websiteURL: true,
          websiteURLMsg: "This field is required",
        });
      } else {
        if (pattern.test(e.target.value) === false) {
          setUserError({
            ...userError,
            websiteURL: true,
            websiteURLMsg:
              "Enter your website’s current start URL starting with https://",
          });
        } else {
          setUserError({
            ...userError,
            websiteURL: false,
            websiteURLMsg: "",
          });
        }
      }
    } else if (e.target.name === "subDomain") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          subDomain: true,
          subDomainMsg: "This field is required",
        });
      } else {
        Services.gatewayDomainExists({
          subDomain: e.target.value,
          gatewayDomain: newData?.gatewayDomain,
        })
          .then((res) => {
            if (
              e.target.value === "" ||
              e.target.value.match(/^ *$/) !== null
            ) {
              setUserError({
                ...userError,
                subDomain: true,
                subDomainMsg: "This field is required",
              });
            } else if (res.data.status === true) {
              setUserError({
                ...userError,
                subDomain: true,
                subDomainMsg: res.data.message,
                gatewayDomain: false,
                gatewayDomainMsg: "",
              });
            } else if (!/^[a-zA-Z][a-zA-Z0-9]{2,}$/.test(e.target.value)) {
              setUserError({
                ...userError,
                subDomain: true,
                subDomainMsg:
                  "It must be 3 or more characters long,start with a letter and made up of letters and numbers only",
              });
            } else {
              setUserError({
                ...userError,
                subDomain: false,
                subDomainMsg: "",
                gatewayDomain: false,
                gatewayDomainMsg: "",
              });
            }
          })
          .catch((err) => {});
      }
    } else if (e.target.name === "gatewayDomain") {
      if (e.target.value === "") {
        setUserError({
          ...userError,
          gatewayDomain: true,
          gatewayDomainMsg: "This field is required",
        });
      } else {
        Services.gatewayDomainExists({
          subDomain: newData.subDomain,
          gatewayDomain: e.target.value,
        })
          .then((res) => {
            if (
              e.target.value === "" ||
              e.target.value.match(/^ *$/) !== null
            ) {
              setUserError({
                ...userError,
                gatewayDomain: true,
                gatewayDomainMsg: "This field is required",
              });
            } else if (res.data.status === true) {
              setUserError({
                ...userError,
                gatewayDomain: true,
                gatewayDomainMsg: res.data.message,
                subDomain: false,
                subDomainMsg: "",
              });
            } else {
              setUserError({
                ...userError,
                subDomain: false,
                subDomainMsg: "",
                gatewayDomain: false,
                gatewayDomainMsg: "",
              });
            }
          })
          .catch((err) => {});
      }
    } else if (e.target.name === "maxBytes") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          maxBytes: true,
          maxBytesMsg: "This field is required",
        });
      } else {
        if (!maxBytesContentPattern.test(e.target.value)) {
          setUserError({
            ...userError,
            maxBytes: true,
            maxBytesMsg: "Invalid Format",
          });
        } else {
          setUserError({
            ...userError,
            maxBytes: false,
            maxBytesMsg: "",
          });
        }
      }
    } else if (e.target.name === "contentType") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          contentType: true,
          contentTypeMsg: "This field is required",
        });
      } else {
        if (!contentTypePattern.test(e.target.value)) {
          setUserError({
            ...userError,
            contentType: true,
            contentTypeMsg: "Please enter a valid content type",
          });
        } else {
          setUserError({
            ...userError,
            contentType: false,
            contentTypeMsg: "",
          });
        }
      }
    } else if (e.target.name === "serverProvider") {
      if (e.target.value === "") {
        setUserError({
          ...userError,
          serverProvider: true,
          serverProviderMsg: "This field is required",
        });
      } else {
        setUserData({
          ...userData,
          serverProvider: e.target.value,
        });
        setUserError({
          ...userError,
          serverProvider: false,
          serverProviderMsg: "",
        });
      }
    }

    setUserData(newData);
  };

  const handleGatewayDomain = (data) => {};

  const searchChange = (e) => {
    if (e.target.value !== "") {
      setUserError({
        ...userError,
        pubField: false,
        pubFieldMsg: "",
      });
    } else {
      setUserError({
        ...userError,
        pubField: true,
        pubFieldMsg: "Please select a Customer",
      });
    }
  };
  const handleAppSwitch = (appValue) => {
    setUserData({
      ...userData,
      applicationName: "",
      websiteURL: "",
      isNatGatewayRequired: 0,
      subDomain: "",
      gatewayDomain:
        gatewayDomains.length === 1 ? gatewayDomains[0].gateway : "",
      applicationType: appValue,
      serverProvider: "",
      maxBytes: "",
      contentType: "",
    });

    setUserError({
      ...userError,
      applicationName: false,
      applicationMsg: "",
      websiteURL: false,
      websiteURLMsg: "",
      subDomain: false,
      subDomainMsg: "",
      sudDomainError: "",
      subDomainErr: false,
      serverProvider: false,
      serverProviderMsg: "",
      maxBytes: false,
      maxBytesMsg: "",
      contentType: false,
      contentTypeMsg: "",
    });
  };
  const [domainLoad, setDomainLoad] = useState();
  const getData = async (publisherId, natGatewayReqd) => {
    try {
      const appValue = localStorage.getItem("appValue");
      const res = await Services.getGatewayDomain({
        natGatewayReqd: natGatewayReqd,
        publisherId: publisherId,
        productId:
          appValue === "reach"
            ? 4
            : appValue === "wrap"
            ? 1
            : appValue === "switch"
            ? 3
            : 4,
      });
      if (res?.status === 200) {
        setGatewayDomains(res?.data?.gatewayDomains);
        setDomainLoad(true);
      }
    } catch (error) {}
  };

  const changeApp = (appValue) => {
    if (customerData) {
      companyValues(customerData);
    }
    const copyData = { ...userData };
    copyData["applicationType"] = appValue;

    if (userData.publisherId !== "") {
      setUserData(copyData);
      if (appValue === "reach") {
        setSelectedApp("reach");
      } else if (appValue === "wrap") {
        setSelectedApp("wrap");
      } else if (appValue === "switch") {
        setSelectedApp("switch");
      }
      localStorage.setItem("appValue", appValue);
      getData(userData.publisherId, 0);
    }
    handleAppSwitch(appValue);
  };

  const checkDomainExist = async (subDomain, gatewayDomain) => {
    const { data } = await Services.gatewayDomainExists({
      subDomain: subDomain,
      gatewayDomain: gatewayDomain,
    });
    return data;
  };

  const handleSwitch = (e) => {
    const value = e.target.checked ? 1 : 0;
    setUserData({
      ...userData,
      isNatGatewayRequired: value,
    });
    setUserError({
      ...userError,
      gatewayDomain: false,
      gatewayDomainMsg: "",
      subDomain: false,
      subDomainMsg: "",
    });
    getData(userData?.publisherId, value);
  };

  const disableTextField = () => {
    if (userData.publisherId && userData.applicationType !== "") {
      return true;
    } else {
      return false;
    }
  };

  const getBreadCrumbList = () => {
    setLoading(true);
    Services.breadCrumbList(localStorage.getItem("companyId"))
      .then((res) => {
        setBreadCrumbData(res?.data);
        setLoading(false);
      })

      .catch((err) => {});
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoad(true);
    setDisableButton(true);
    if (userData.applicationType === "reach") {
      Services.createApplication(userData)
        .then((res) => {
          if (res?.data.status === "success") {
            setLoad(false);
            setDisableButton(false);
            localStorage.setItem("applicationPopUp", true);
            handleCancel();
            showSuccess(res?.data.message);
            navigate("/application-details/", {
              state: { clickedAppId: res.data.applicationId },
            });
          } else {
            setLoad(false);
            setDisableButton(false);

            handleCancel();
            showError(res?.data.message);
          }
        })
        .catch((err) => {});
    } else if (userData.applicationType === "wrap") {
      let payload = {
        applicationName: userData?.applicationName,
        applicationType: userData?.applicationType,
        deploymentType: userData?.deploymentType,
        gatewayDomain: userData?.gatewayDomain,
        publisherId: userData?.publisherId,
        websiteURL: userData?.websiteURL,
        editorId: userData?.editorId,
        isNatGatewayRequired: userData?.isNatGatewayRequired,
      };
      Services.createApplication(payload)
        .then((res) => {
          if (res?.data.status === "success") {
            setLoad(false);
            setDisableButton(false);
            localStorage.setItem("applicationPopUp", true);
            handleCancel();
            showSuccess(res?.data.message);
            navigate("/application-details/", {
              state: { clickedAppId: res.data.applicationId },
            });
          } else {
            setLoad(false);
            setDisableButton(false);

            handleCancel();
            showError(res?.data.message);
          }
        })
        .catch((err) => {});
    } else if (userData.applicationType === "switch") {
      let payload = {
        applicationName: userData?.applicationName,
        applicationType: userData?.applicationType,
        deploymentType: userData?.deploymentType,
        gatewayDomain: userData?.gatewayDomain,
        publisherId: userData?.publisherId,
        editorId: userData?.editorId,
        isNatGatewayRequired: userData?.isNatGatewayRequired,
      };
      Services.createApplication(payload)
        .then((res) => {
          if (res?.data.status === "success") {
            setLoad(false);
            setDisableButton(false);
            localStorage.setItem("applicationPopUp", true);
            handleCancel();
            showSuccess(res?.data.message);
            navigate("/application-details/", {
              state: { clickedAppId: res.data.applicationId },
            });
          } else {
            setLoad(false);
            setDisableButton(false);
            handleCancel();
            showError(res?.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  const handleCancel = () => {
    setUserError({
      applicationName: false,
      applicationMsg: "",
      websiteURL: false,
      websiteURLMsg: "",
      subDomain: false,
      subDomainMsg: "",
      pubField: false,
      pubFieldMsg: "",
      sudDomainError: "",
      subDomainErr: false,
    });
    setUserData({
      applicationName: "",
      websiteURL: "",
      isNatGatewayRequired: 0,
      subDomain: "",
      gatewayDomain: "",
      applicationType: "",
      deploymentType: "DEV",
      editorId: localStorage.getItem("userid"),
      serverProvider: "",
      maxBytes: "",
      contentType: "",
      publisherId:
        localStorage.getItem("role") === "ROLE_USER"
          ? localStorage.getItem("companyId")
          : (switchRole === "user" || switchRole === "customer") && switchedUser
          ? localStorage.getItem("customerId")
          : "",
    });
    setListOpen(false);
    setSelectedApp("");
    setLoad(false);
    setDisableButton(false);
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "ROLE_USER") {
      getData(localStorage.getItem("companyId"), userData.isNatGatewayRequired);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getRoles();
    setLoading(true);
    getBreadCrumbList();
  }, []);

  useEffect(() => {
    if (userData?.isNatGatewayRequired === 1) {
      if (domainLoad) {
        if (gatewayDomains.length === 0) {
          setUserError({
            ...userError,
            natGateway: true,
            natGatewayMsg:
              "There is no NAT Gateway service provisioned for this account. ",
          });
        } else {
          setUserError({
            ...userError,
            natGateway: false,
            natGatewayMsg: "",
          });
        }
      } else {
        setUserError({
          ...userError,
          natGateway: false,
          natGatewayMsg: "",
        });
      }
    } else {
      setUserError({
        ...userError,
        natGateway: false,
        natGatewayMsg: "",
      });
    }

    // eslint-disable-next-line
  }, [gatewayDomains, userData?.isNatGatewayRequired]);

  useEffect(() => {
    if (domainLoad) {
      const copyData = { ...userData };
      copyData["gatewayDomain"] =
        gatewayDomains.length === 1 ? gatewayDomains[0].gateway : "";
      setUserData(copyData);
      setDomainLoad(false);
    }
    // eslint-disable-next-line
  }, [domainLoad]);

  const customerDetails = () => {
    setLoading(true);
    if (switchedUser) {
      if (switchRole === "user" || switchRole === "customer") {
        const customerId = localStorage.getItem("customerId");
        Services.LicenseeAccountSettings({ companyId: customerId })
          .then((res) => {
            setLoading(false);
            setCustomerData(res?.data);
            if (res?.data) {
              companyValues(res?.data);
            }
          })
          .catch((err) => {});
      } else if (switchRole === "licensee") {
        const licenseeId = localStorage.getItem("licenseeId");
        Services.LicenseeAccountSettings({ companyId: licenseeId })
          .then((res) => {
            setLoading(false);
            setCustomerData(res?.data);
          })
          .catch((err) => {});
      } else if (switchRole === "channel") {
        const channelId = localStorage.getItem("channelId");
        Services.LicenseeAccountSettings({ companyId: channelId })
          .then((res) => {
            setLoading(false);
            setCustomerData(res?.data);
          })
          .catch((err) => {});
      }
    } else {
    }
  };

  useEffect(() => {
    customerDetails();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (switchRole) {
      setUserError({
        applicationName: false,
        applicationMsg: "",
        websiteURL: false,
        websiteURLMsg: "",
        subDomain: false,
        subDomainMsg: "",
        pubField: false,
        pubFieldMsg: "",
        sudDomainError: "",
        subDomainErr: false,
      });
      setUserData({
        applicationName: "",
        websiteURL: "",
        isNatGatewayRequired: 0,
        subDomain: "",
        gatewayDomain: "",
        publisherId:
          localStorage.getItem("role") === "ROLE_USER"
            ? localStorage.getItem("companyId")
            : (switchRole === "user" || switchRole === "customer") &&
              switchedUser
            ? localStorage.getItem("customerId")
            : "",
        applicationType: "",
        deploymentType: "DEV",
        editorId: localStorage.getItem("userid"),
        serverProvider: "",
        maxBytes: "",
        contentType: "",
      });
      setListOpen(false);
      setSelectedApp("");
    }

    //eslint-disable-next-line
  }, [switchedUser]);

  return (
    <CookiesProvider>
      <Layout>
        <Container maxWidth="xxl">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="2px"
          >
            {loading ? (
              <Loader
                type={"spokes"}
                color={"#01537A"}
                height={40}
                width={40}
                loading={loading.toString()}
              />
            ) : null}
          </Box>
          {/* breadcrumbs */}
          <Box
            component={"span"}
            sx={{
              color: "#5A5656",
              fontWeight: "400",
              lineHeight: "42.61px",
              width: "300px",
              height: "18px",

              padding: "0px 0px 15px 0px",
              fontSize: { xs: "10px", md: "12px" },
            }}
          >
            {switchedUser ? (
              customerData?.parentCompany && !loading ? (
                <BreadCrumb value={customerData} other="Add Application" />
              ) : (
                ""
              )
            ) : breadCrumbData?.parentCompany && !loading ? (
              <BreadCrumb value={breadCrumbData} other="Add Application" />
            ) : (
              ""
            )}
          </Box>
          {/* main box */}

          <Box
            sx={{
              width: "100%",
              boxShadow: 2,
              padding: "20px 0px 20px 56px",
              marginTop: "10px",
            }}
          >
            {/* heading */}
            <Typography
              sx={{
                fontWeight: "700",
                lineHeight: "30px",
                color: "#01537A",
                fontSize: "33px",
              }}
            >
              Add Application
            </Typography>
            <Divider
              sx={{
                backgroundColor: "#0094CD",
                borderRadius: "6px",
                marginTop: "20px",
                borderBottomWidth: 2,
                marginBottom: "20px",
              }}
            ></Divider>

            <Box
              sx={{
                width: { xs: "222px", sm: "600px", md: "500px", lg: "862px" },
              }}
            >
              {/* section 1 */}

              {localStorage.getItem("role") !== "ROLE_USER" && (
                <>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "30px",
                      color: "#01537A",
                      marginTop: "20px",
                    }}
                  >
                    1.Select Customer
                  </Typography>

                  <Divider
                    sx={{
                      borderRadius: "6px",
                      borderBottomWidth: 1,
                      marginBottom: "15px",
                      width: "100%",
                    }}
                  ></Divider>

                  <Typography
                    sx={{ color: "#373737", textTransform: "capitalize" }}
                  >
                    Customer
                  </Typography>

                  {(switchRole === "user" || switchRole === "customer") &&
                  switchedUser ? (
                    <Box
                      sx={{
                        maxWidth: {
                          xs: "100%",
                          sm: "500px",
                          md: "550px",
                          lg: "550px",
                        },
                        width: "100%",
                        fontSize: "34px",
                        display: "flex",
                      }}
                    >
                      <SearchList
                        customerData={customerData}
                        changeBtn={true}
                      />
                    </Box>
                  ) : listOpen ? (
                    <Box
                      sx={{
                        maxWidth: {
                          xs: "100%",
                          sm: "500px",
                          md: "550px",
                          lg: "550px",
                        },
                        width: "100%",
                        fontSize: "34px",
                        display: "flex",
                      }}
                    >
                      <SearchList
                        data={allData}
                        changeBtn={listOpen}
                        onData={handleData}
                      />
                    </Box>
                  ) : (
                    <FormControl
                      error={!!userError?.pubField}
                      sx={{ width: "100%" }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          fontSize: "34px",
                          display: "flex",
                          border: !userError?.pubField
                            ? "1px solid #01537A"
                            : "1.5px solid #d32f2f",
                          borderRadius: "4px",
                        }}
                      >
                        <Paper
                          component="form"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "38px",
                          }}
                        >
                          <IconButton sx={{ p: "10px" }} aria-label="menu">
                            <SearchIcon
                              style={{
                                color: "#01537A",
                              }}
                              sx={{
                                fontSize: "24px",
                              }}
                            />
                          </IconButton>
                          <InputBase
                            width="100%"
                            style={{ ml: 1, flex: 1, color: "#757373" }}
                            placeholder={`Search customer `}
                            onKeyDown={searchModal}
                            onChange={searchChange}
                          ></InputBase>
                        </Paper>
                      </Box>
                      <FormHelperText>{userError?.pubFieldMsg}</FormHelperText>
                    </FormControl>
                  )}
                </>
              )}
              {/* section 2  */}
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: userData.publisherId ? "#01537A" : "#373737",
                  marginTop: "20px",
                }}
              >
                {localStorage.getItem("role") !== "ROLE_USER"
                  ? "2.Select a product"
                  : "1.Select a product"}
              </Typography>
              <Divider
                sx={{
                  borderRadius: "6px",
                  borderBottomWidth: 1,
                  marginBottom: "15px",
                }}
              ></Divider>
              {/* reach wrap box */}
              <Box
                sx={{
                  display: { xs: "grid" },
                  columnGap: { xs: 2, md: 3, lg: 5, xl: 5 },
                  rowGap: { xs: 7, sm: 7, md: 5, lg: 4, xl: 6 },
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(3, 1fr)",
                    md: "repeat(5, 1fr)",
                    lg: "repeat(5, 1fr)",
                  },
                }}
              >
                {/* Reach APP */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    onClick={
                      userData.publisherId ? () => changeApp("reach") : null
                    }
                    sx={{
                      cursor: userData.publisherId ? "pointer" : "default",
                      background:
                        userData.applicationType === "reach"
                          ? "linear-gradient(to right bottom, #005075, #018EC5)"
                          : "white",
                      display: "flex",
                      boxShadow: "3",
                      height: "40px",
                      justifyContent: "center",
                      borderRadius: "20px 20px 0px 0px",
                      width: "160px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "17px",
                        marginRight: "24px",
                      }}
                    >
                      {userData.applicationType === "reach" ? (
                        <img
                          alt="reach"
                          src={whiteReach}
                          width="18px"
                          height="18px"
                        />
                      ) : userData.publisherId ? (
                        <img
                          alt="reach"
                          src={reach}
                          width="18px"
                          height="18px"
                        />
                      ) : (
                        <img
                          alt="greyReach"
                          src={greyReach}
                          width="18px"
                          height="18px"
                        />
                      )}
                      <Typography
                        sx={{
                          color:
                            userData.applicationType === "reach"
                              ? "white"
                              : userData.publisherId
                              ? "#01537A"
                              : "#757373",
                          fontSize: "18px",
                        }}
                      >
                        Reach
                      </Typography>
                    </Box>
                  </Box>
                  {userData.applicationType === "reach" ? (
                    <Box
                      sx={{
                        borderRadius: "0px 0px 6px 6px",
                        boxShadow: "3",
                        width: "160px",
                        background: "#01537A",
                        padding: "8px 10px",
                        height: "51px",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          color: "white",
                          fontWeight: "400",
                          fontSize: "12px",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box>
                          <Typography
                            component={"span"}
                            sx={{ fontSize: "12px" }}
                          >
                            Publish your{" "}
                          </Typography>
                          <Typography
                            component={"span"}
                            sx={{ fontWeight: "700", fontSize: "12px" }}
                          >
                            website{" "}
                            <Typography
                              component={"span"}
                              sx={{ fontSize: "12px" }}
                            >
                              on
                            </Typography>
                          </Typography>
                        </Box>
                        <Typography
                          sx={{ fontSize: "12px" }}
                          component={"span"}
                        >
                          on mobile browser{" "}
                        </Typography>
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        borderRadius: "0px 0px 6px 6px",
                        boxShadow: "3",
                        background: userData.publisherId
                          ? "#01537A"
                          : "#757373",
                        padding: "8px 10px",
                        width: "160px",
                        height: "51px",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          color: "white",
                          fontWeight: "400",
                          fontSize: "12px",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box>
                          <Typography
                            component={"span"}
                            sx={{ fontSize: "12px" }}
                          >
                            Make your{" "}
                          </Typography>
                          <Typography
                            component={"span"}
                            sx={{ fontWeight: "700", fontSize: "12px" }}
                          >
                            website{" "}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{ fontSize: "12px" }}
                          component={"span"}
                        >
                          #datafree{" "}
                        </Typography>
                      </Typography>
                    </Box>
                  )}
                </Box>
                {/* wrap APP */}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    onClick={
                      userData.publisherId ? () => changeApp("wrap") : null
                    }
                    sx={{
                      background:
                        userData.applicationType === "wrap"
                          ? "linear-gradient(to right bottom, #005075, #018EC5)"
                          : "white",
                      display: "flex",
                      boxShadow: "3",
                      height: "40px",
                      justifyContent: "center",
                      cursor: userData.publisherId ? "pointer" : "default",
                      borderRadius: "20px 20px 0px 0px",
                      width: "160px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "17px",
                        marginRight: "24px",
                      }}
                    >
                      {userData.applicationType === "wrap" ? (
                        <img
                          alt="wrap"
                          src={whiteWrap}
                          width="18px"
                          height="18px"
                        />
                      ) : userData.publisherId ? (
                        <img alt="wrap" src={wrap} width="18px" height="18px" />
                      ) : (
                        <img
                          alt="greyWrap"
                          src={greyWrap}
                          width="18px"
                          height="18px"
                        />
                      )}
                      <Typography
                        sx={{
                          color:
                            userData.applicationType === "wrap"
                              ? "white"
                              : userData.publisherId
                              ? "#01537A"
                              : "#757373",
                          fontSize: "18px",
                        }}
                      >
                        Wrap
                      </Typography>
                    </Box>
                  </Box>
                  {userData.applicationType === "wrap" ? (
                    <Box
                      sx={{
                        background: userData.publisherId
                          ? "#01537A"
                          : "#757373",
                        padding: "8px 7px",
                        borderRadius: "0px 0px 6px 6px",
                        boxShadow: "3",
                        width: "160px",
                        height: "51px",
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          color: "white",
                          fontWeight: "400",
                          fontSize: "12px",
                          padding: "0px 5px",
                        }}
                      >
                        Replicate existing
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontWeight: "400",
                          fontSize: "12px",
                          padding: "0px 5px",
                        }}
                      >
                        website as mobile app
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        background: userData.publisherId
                          ? "#01537A"
                          : "#757373",
                        padding: "8px 7px",
                        borderRadius: "0px 0px 6px 6px",
                        boxShadow: "3",
                        width: "160px",
                        height: "51px",
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          color: "white",
                          fontWeight: "400",
                          fontSize: "12px",
                          padding: "0px 5px",
                        }}
                      >
                        Replicate your website
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontWeight: "400",
                          fontSize: "12px",
                          padding: "0px 5px",
                        }}
                      >
                        as #datafree mobile app
                      </Typography>
                    </Box>
                  )}
                </Box>

                {/* switch APP */}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    onClick={
                      userData.publisherId ? () => changeApp("switch") : null
                    }
                    sx={{
                      background:
                        userData.applicationType === "switch"
                          ? "linear-gradient(to right bottom, #005075, #018EC5)"
                          : "white",
                      display: "flex",
                      height: "40px",
                      cursor: userData.publisherId ? "pointer" : "default",
                      justifyContent: "center",
                      borderRadius: "20px 20px 0px 0px",
                      width: "160px",
                      boxShadow: "3",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "17px",
                        marginRight: "24px",
                      }}
                    >
                      {userData.applicationType === "switch" ? (
                        <img
                          alt="switch"
                          src={whiteSwitch}
                          width="18px"
                          height="18px"
                        />
                      ) : userData.publisherId ? (
                        <img
                          alt="switch"
                          src={Switch}
                          width="18px"
                          height="18px"
                        />
                      ) : (
                        <img
                          alt="greySwitch"
                          src={greySwitch}
                          width="18px"
                          height="18px"
                        />
                      )}

                      <Typography
                        sx={{
                          color:
                            userData.applicationType === "switch"
                              ? "white"
                              : userData.publisherId
                              ? "#01537A"
                              : "#757373",
                          fontSize: "18px",
                        }}
                      >
                        Switch
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: userData.publisherId ? "#01537A" : "#757373",
                      padding: "8px 10px",
                      borderRadius: "0px 0px 6px 6px",
                      boxShadow: "3",
                      width: "160px",
                      height: "51px",
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        color: "white",
                        fontWeight: "400",
                        fontSize: "12px",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box>
                        <Typography
                          component={"span"}
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          Publish your existing
                        </Typography>
                      </Box>
                      <Typography
                        component={"span"}
                        sx={{
                          fontSize: "12px",
                        }}
                      >
                        app{" "}
                        {userData.applicationType !== "switch" && "#datafree"}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* section 3 */}
              {userData.applicationType && (
                <>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "30px",
                      color:
                        userData.publisherId && userData.applicationType
                          ? "#01537A"
                          : "#373737",
                      marginTop: "25px",
                    }}
                  >
                    {localStorage.getItem("role") !== "ROLE_USER"
                      ? "3.Application Details"
                      : "2.Application Details"}
                  </Typography>
                  <Divider
                    sx={{
                      borderRadius: "6px",
                      borderBottomWidth: 1,
                      marginBottom: "20px",
                    }}
                  ></Divider>
                  <TextField
                    sx={{
                      width: "100%",
                      "& input::placeholder": {
                        color:
                          userData.publisherId && userData.applicationType
                            ? "#01537A"
                            : "#373737",
                        fontSize: {
                          xs: "14px",
                          md: "12px",
                          lg: "14px",
                        },
                        opacity: "1",
                      },
                    }}
                    placeholder="Application Name"
                    required
                    inputProps={{
                      sx: {
                        fontSize: {
                          xs: "14px",
                          md: "12px",
                          lg: "16px",
                        },
                        fontWeight: 400,
                        letterSpacing: 0.5,
                        color: "rgba(0, 0, 0, 0.87)",
                      },
                    }}
                    inputlabelprops={{
                      style: { fontSize: 16, fontWeight: 400 },
                    }}
                    value={userData?.applicationName}
                    error={!!userError?.applicationName}
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                        </InputAdornment>
                      ),
                      readOnly: !disableTextField() ? true : false,
                    }}
                    name="applicationName"
                    variant="standard"
                    helperText={
                      userError?.applicationName
                        ? userError?.applicationMsg
                        : ""
                    }
                  />
                </>
              )}

              {userData?.applicationName !== "" && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: {
                      xs: "14px",
                      md: "12px",
                      lg: "14px",
                    },
                    letterSpacing: "0.4px",
                    color: "#01537A",
                  }}
                >
                  Application Name
                </Typography>
              )}

              {selectedApp === "reach" ? (
                <AddReachApplication
                  handleInputChange={handleInputChange}
                  userData={userData}
                  userError={userError}
                  handleSwitch={handleSwitch}
                  checked={Boolean(userData.isNatGatewayRequired)}
                  gatewayDomains={gatewayDomains}
                  onDataTransfer={handleGatewayDomain}
                />
              ) : selectedApp === "wrap" ? (
                <AddWrapApplication
                  handleInputChange={handleInputChange}
                  userData={userData}
                  userError={userError}
                  handleSwitch={handleSwitch}
                  checked={Boolean(userData.isNatGatewayRequired)}
                  gatewayDomains={gatewayDomains}
                />
              ) : selectedApp === "switch" ? (
                <AddSwitchApplication
                  handleInputChange={handleInputChange}
                  userData={userData}
                  userError={userError}
                  handleSwitch={handleSwitch}
                  checked={Boolean(userData.isNatGatewayRequired)}
                  gatewayDomains={gatewayDomains}
                />
              ) : (
                ""
              )}
            </Box>

            <Box
              sx={{
                padding: "30px 0px",

                width: { xs: "222px", sm: "600px", md: "500px", lg: "862px" },
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                className="cancel-btn"
                onClick={handleCancel}
                sx={{
                  color: "#01537A",
                  textTransform: "capitalize",
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className={disableButton ? "disable" : null}
                onClick={handleSave}
                disabled={
                  userData.applicationType === "reach"
                    ? !areAllReachFieldsFilled
                    : userData.applicationType === "wrap"
                    ? !areAllWrapFieldsFilled
                    : userData.applicationType === "switch"
                    ? !areAllSwitchFieldsFilled
                    : true
                }
                sx={{
                  padding: " 0px 50px 0px 50px",
                  width: "fit-content",
                  background: "#01537A !important",
                  textTransform: "capitalize",
                  "&.Mui-disabled": {
                    background: "#757373 !important",
                    color: "white",
                    textTransform: "capitalize",
                    padding: "0px 50px",
                  },
                }}
                variant="contained"
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>
                    Create App Definition
                  </span>
                  {load ? (
                    <Loader
                      type="spinningBubbles"
                      color="white"
                      delay={0}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </Box>
          </Box>
        </Container>
      </Layout>

      {searchModalOpen && (
        <CompanySearchModal
          type="customer"
          role={role}
          searchData={searchData}
          open={searchModalOpen}
          companyValues={companyValues}
          setSearchModalOpen={setSearchModalOpen}
        />
      )}
    </CookiesProvider>
  );
}
