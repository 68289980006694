import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import GroupedBarChart from "./groupedBarChart";
import StackedLineChart from "./stackedLineChart";
import Loader from "../../components/loader";
import BarChart from "./barChart";
import LineChart from "./lineChart";

export default function MonthlyChartView({
  resData,
  loading,
  data,
  companyName,
  role,
}) {
  return (
    <>
      {resData && !loading ? (
        <Box
          sx={{
            width: { xs: "70%", md: "70%", lg: "72%", xl: "81%" },
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "20px",
              marginBottom: "30px",
            }}
          >
            Summary Monthly Report for all applications in{" "}
            <span style={{ color: "#0094CD" }}>
              {role === "ROLE_USER"
                ? companyName
                  ? companyName
                  : " "
                : data?.publisher?.label
                ? data?.publisher?.label
                : ""}
            </span>
          </Typography>
          <Box
            sx={{
              marginBottom: "40px",
            }}
          >
            <GroupedBarChart resData={resData} />
          </Box>
          <Paper
            sx={{
              marginBottom: "40px",
            }}
          >
            <StackedLineChart
              heading={"Data Usage by Upload & Download"}
              yAxis={"Data Usage (MB)"}
              xAxis={"Month"}
              type={"dataUsageUpDown"}
              resData={resData}
            />
          </Paper>
          <Paper
            sx={{
              marginBottom: "40px",
            }}
          >
            <BarChart
              heading={"Summary total navigations"}
              yAxis={"Total Navigations"}
              xAxis={"Month"}
              resData={resData}
              type={"totalNavigation"}
            />
          </Paper>
          <Paper
            sx={{
              marginBottom: "40px",
            }}
          >
            <LineChart
              heading={"Summary total focus minutes"}
              yAxis={"Total Focus Time per User (min)"}
              xAxis={"Month"}
              resData={resData}
              type={"totalFocusMin"}
            />
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
            marginTop: "1rem",
            marginLeft: "30%",
          }}
        >
          <Loader
            type={"spokes"}
            color={"#01537A"}
            height={40}
            width={40}
          />
        </Box>
      )}
    </>
  );
}
