import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Services from "../../api/services";
import Loader from "../../components/loader";
import moment from "moment";
import useToast from "../../hooks/useToast";
import { regexPatterns } from "../../config/regex";

export default function ScheduleReport({
  open,
  handleClose,
  callScheduleReport,
}) {
  const { showSuccess, showWarning } = useToast();

  const [prodApplication, setProdApplication] = useState();
  const [saveClick, setSaveClick] = useState(false);
  const companyId = localStorage.getItem("companyId");
  const userid = localStorage.getItem("userid");
  const [data, setData] = useState({
    application: "",
    distributionList: "",
    dayOfWeek: "",
  });
  const [userError, setUserError] = useState({
    application: false,
    applicationMsg: "",
    distributionList: false,
    distributionListMsg: "",
    dayOfWeek: false,
    dayOfWeekMsg: "",
  });

  const days = [
    { id: 1, name: "Sunday" },
    { id: 2, name: "Monday" },
    { id: 3, name: "Tuesday" },
    { id: 4, name: "Wednesday" },
    { id: 5, name: "Thursday" },
    { id: 6, name: "Friday" },
    { id: 7, name: "Saturday" },
  ];

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "application") {
      if (e.target.value === "") {
        setUserError({
          ...userError,
          application: true,
          applicationMsg: "This field is required",
        });
      } else {
        setUserError({
          ...userError,
          application: false,
          applicationMsg: "",
        });
      }
    } else if (e.target.name === "distributionList") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          distributionList: true,
          distributionListMsg: "This field is required",
        });
      } else {
        if (!regexPatterns.emailRegex.test(e.target.value)) {
          setUserError({
            ...userError,
            distributionList: true,
            distributionListMsg: "Enter a valid list of email addresses",
          });
        } else {
          setUserError({
            ...userError,
            distributionList: false,
            distributionListMsg: "",
          });
        }

        const distributionEmailList = newData.distributionList
          .replace(/,\s+/g, ",")
          .split(",");
        distributionEmailList.pop();
        const enteredEmail = e.target.value.split(",");
        if (
          distributionEmailList.includes(
            enteredEmail[enteredEmail.length - 1].trim()
          )
        ) {
          newData[e.target.name] = distributionEmailList;
        }
      }
    } else if (e.target.name === "dayOfWeek") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          dayOfWeek: true,
          dayOfWeekMsg: "This field is required",
        });
      } else {
        setUserError({
          ...userError,
          dayOfWeek: false,
          dayOfWeekMsg: "",
        });
      }
    }
    setData(newData);
  };

  const validate = (data) => {
    const userErrors = { ...userError };
    const objString = JSON.stringify(data?.distributionList);
    const hasComma = objString.includes(",");
    let count = 0;
    if (data?.application === "") {
      userErrors.application = true;
      userErrors.applicationMsg = "This field is required";
      count++;
    } else {
      userErrors.application = false;
      userErrors.applicationMsg = "";
    }
    if (data?.dayOfWeek === "") {
      userErrors.dayOfWeek = true;
      userErrors.dayOfWeekMsg = "This field is required";
      count++;
    } else {
      userErrors.dayOfWeek = false;
      userErrors.dayOfWeekMsg = "";
    }
    if (hasComma) {
      const alertEmailList = objString.replace(/,\s+/g, ",").split(",");
      alertEmailList.pop();
      const enteredEmail = objString.split(",");
      if (
        alertEmailList.includes(enteredEmail[enteredEmail.length - 1].trim())
      ) {
        data.distributionList = alertEmailList;
        userErrors.distributionList = true;
        userErrors.distributionListMsg = "Email already exists.";
        count++;
      }
    }
    if (data.distributionList === "") {
      userErrors.distributionList = true;
      userErrors.distributionListMsg = "This field is required";
      count++;
    } else if (!regexPatterns.emailRegex.test(data.distributionList)) {
      userErrors.distributionList = true;
      userErrors.distributionListMsg = "Enter a valid list of email addresses";
      count++;
    } else {
      userErrors.distributionList = false;
      userErrors.distributionListMsg = "";
    }

    setUserError(userErrors);
    return count;
  };
  const handleSave = () => {
    let errorCount = validate(data);
    if (errorCount === 0) {
      let payload = {
        userId: userid,
        companyId: companyId,
        appID: data?.application,
        dow: data?.dayOfWeek,
        distEmail: data?.distributionList,
      };
      setSaveClick(true);
      Services.createReport(payload)
        .then((res) => {
          if (res?.data.status === "success") {
            showSuccess(res?.data.message);
            callScheduleReport(true);
            handleCancel();
          } else {
            showWarning(res?.data.message);
            handleCancel();
          }
        })
        .catch((err) => {});
    }
  };

  const handleCancel = () => {
    setUserError({
      application: false,
      applicationMsg: "",
      distributionList: false,
      distributionListMsg: "",
      dayOfWeek: false,
      dayOfWeekMsg: "",
    });
    setData({
      application: "",
      distributionList: "",

      dayOfWeek: "",
    });
    handleClose();
    setSaveClick(false);
  };

  const switchRole = localStorage.getItem("switchRole");
  const switchedUser = JSON.parse(localStorage.getItem("switchedUser"));
  async function getProdAppList() {
    const appCompanyId = () => {
      if (switchedUser) {
        if (switchRole === "licensee") {
          return localStorage.getItem("licenseeId");
        } else if (switchRole === "channel") {
          return localStorage.getItem("channelId");
        } else if (switchRole === "user" || switchRole === "customer") {
          return localStorage.getItem("customerId");
        }
      } else {
        return localStorage.getItem("companyId");
      }
    };
    Services.prodAppList({
      companyId: appCompanyId(),
    })
      .then((res) => {
        if (res?.status === 200) {
          setProdApplication(res?.data?.applications);
        }
      })
      .catch((err) => {});
  }

  const renderValueOfDays = (selected, placeholder) => {
    if (!selected) {
      return (
        <Typography
          sx={{
            color: "#000000",
            fontSize: "16px",
          }}
        >
          {placeholder}
        </Typography>
      );
    }

    return selected;
  };

  const renderValue = (selected, placeholder) => {
    if (!selected) {
      return (
        <Typography
          sx={{
            color: "#000000",
            fontSize: "16px",
          }}
        >
          {placeholder}
        </Typography>
      );
    }
    const selectedItem = prodApplication.find(
      (item) => item.appId === selected
    );

    return selectedItem
      ? `${selectedItem.appName} [${selectedItem.appId}]`
      : "";
  };

  useEffect(() => {
    getProdAppList();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (switchRole) {
      getProdAppList();
    }
    // eslint-disable-next-line
  }, [switchedUser]);
  useEffect(() => {
    if (data?.application) {
      const today = new Date();
      const dayOfWeek = moment(today).format("dddd");
      setData({
        ...data,
        dayOfWeek: dayOfWeek ? dayOfWeek : "",
      });
    }

    // eslint-disable-next-line
  }, [data?.application]);

  return (
    <Dialog maxWidth="xxl" open={open}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            paddingTop: "30px",
            paddingLeft: "23px",
            paddingBottom: "20px",
            fontSize: "24px",
            color: "rgb(1, 83, 122)",
          }}
        >
          Schedule Report
        </Typography>
        <CloseSharpIcon
          sx={{
            cursor: "pointer",
            color: "#01537A",
          }}
          onClick={handleCancel}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0px 50px 70px 50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <FormControl sx={{ width: "509px" }} variant="standard">
              <Select
                sx={{
                  ".MuiSvgIcon-root ": {
                    fill: "black !important",
                  },
                }}
                displayEmpty
                renderValue={(selected) =>
                  renderValue(selected, "Application ")
                }
                name="application"
                value={data.application || ""}
                error={userError?.application}
                onChange={handleChange}
              >
                {prodApplication &&
                  prodApplication.map((item, index) => (
                    <MenuItem key={index} value={item.appId}>
                      {item.appName}
                    </MenuItem>
                  ))}
              </Select>
              {data?.application && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    color: "#01537A",
                  }}
                >
                  Application
                </Typography>
              )}

              <FormHelperText
                sx={{
                  color: "#d32f2f",
                }}
              >
                {userError?.application ? userError?.applicationMsg : ""}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ width: "509px" }} variant="standard">
              <TextField
                sx={{
                  width: "100%",
                  "& input::placeholder": {
                    color: "black",
                    fontSize: "16px",
                    opacity: "1",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                name="distributionList"
                error={!!userError?.distributionList}
                value={data.distributionList}
                placeholder="Distribution List (Comma delimited email list)"
                variant="standard"
                helperText={
                  userError?.distributionList
                    ? userError?.distributionListMsg
                    : ""
                }
              />
              {data?.distributionList && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    color: "#01537A",
                  }}
                >
                  Distribution Email List
                </Typography>
              )}
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <FormControl sx={{ width: "509px" }} variant="standard">
              <Select
                sx={{
                  ".MuiSvgIcon-root ": {
                    fill: "black !important",
                  },
                }}
                displayEmpty
                renderValue={(selected) =>
                  renderValueOfDays(
                    selected,
                    "Day of the week (That the report will be return)"
                  )
                }
                name="dayOfWeek"
                value={data?.dayOfWeek || ""}
                error={userError?.dayOfWeek}
                onChange={handleChange}
              >
                {days &&
                  days.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
              {data?.dayOfWeek && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    color: "#01537A",
                  }}
                >
                  Day of the week
                </Typography>
              )}

              <FormHelperText
                sx={{
                  color: "#d32f2f",
                }}
              >
                {userError?.dayOfWeek ? userError?.dayOfWeekMsg : ""}
              </FormHelperText>
            </FormControl>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            paddingTop: "55px",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              padding: "3px 31px",
              textTransform: "none",
              border: "1px solid #01537A !important",
              color: "#01537A!important",
              width: "200px",
              height: "45px",
            }}
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            sx={{
              width: "200px",
              height: "45px",
              padding: "3px 43px",
              textTransform: "none",
              background: "#01537A!important",
              "&.Mui-disabled": {
                cursor: "not-allowed !important",
                pointerEvents: "unset !important",
              },
            }}
            variant="contained"
            onClick={handleSave}
            disabled={saveClick}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <span style={{ marginRight: "5px" }}>Save</span>
              {saveClick ? (
                <Loader
                  type={"spinningBubbles"}
                  color={"white"}
                  height={20}
                  width={20}
                />
              ) : (
                ""
              )}
            </Box>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
