import { Box, TextField } from "@mui/material";
import React, { useState } from "react";

import "react-toastify/dist/ReactToastify.css";
import PortsAndProtocols from "./portsAndProtocols";

export default function AppDetails({ applicationDetails, appDetailsRefresh }) {
  const commonTextFieldStyles = {
    width: "619px",
    marginTop: "10px",
    marginBottom: "10px",
    fontFamily: "Roboto",
    fontWeight: "400",
    backgroundColor: "#F5F5F5",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "42px",
      "& fieldset": {
        borderColor: "#000",
      },
      "&.Mui-disabled": {
        backgroundColor: "#F5F5F5",
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px 16px",
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(16px, 12px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(16px, -6px) scale(0.75)",
    },
  };

  const readOnlyInputProps = {
    readOnly: true,
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          gap: "14px",
          display: "flex",
          flexDirection: "column",
          padding: { xs: "10px", sm: "10px", md: "10px" },
          maxWidth: "100%",
          marginLeft: "5px",
        }}
      >
        <TextField
          sx={commonTextFieldStyles}
          id="outlined-required"
          label="Customer Endpoint IPV4 address"
          value={applicationDetails?.data?.directIpForward}
          InputProps={readOnlyInputProps}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          sx={commonTextFieldStyles}
          id="outlined-required"
          label="Datafree Domain"
          value={applicationDetails?.data?.gatewayDomain}
          InputProps={readOnlyInputProps}
          InputLabelProps={{ shrink: true }}
        />

        <PortsAndProtocols
          applicationDetails={applicationDetails}
          appDetailsRefresh={appDetailsRefresh}
        ></PortsAndProtocols>
      </Box>
    </>
  );
}
