import React from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Tooltips from "../../tooltips";

export default function WrapApp({
  handleInputChange,
  userData,
  userError,
  handleSwitch,
  checked,
  gatewayDomains,
}) {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#01537A",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const renderValue = (selected) => {
    if (!selected) {
      return (
        <Typography
          sx={{
            color: "#01537A",
            fontSize: "14px",
          }}
        >
          Select the gateway domain
        </Typography>
      );
    }

    return selected;
  };

  return (
    <Box>
      <TextField
        sx={{
          width: "100%",
          "& input::placeholder": {
            color: "#01537A",
            fontSize: "14px",
            opacity: "1",
          },
          marginTop: "10px",
        }}
        placeholder={`Enter your website’s current start URL starting with https://`}
        required
        inputProps={{
          sx: {
            fontSize: {
              xs: "14px",
              md: "12px",
              lg: "16px",
            },
            fontWeight: 400,
            letterSpacing: 0.5,
            color: "rgba(0, 0, 0, 0.87)",
          },
        }}
        inputlabelprops={{
          style: { fontSize: 16, fontWeight: 400 },
        }}
        value={userData?.websiteURL}
        error={!!userError?.websiteURL}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {userData?.websiteURL ? (
                ""
              ) : (
                <Tooltips type="wrapStartUrl">
                  <ErrorOutlineIcon
                    sx={{
                      cursor: "pointer",
                      bottom: "7px",
                      color: "#01537A",
                      fontSize: {
                        xs: "13px",
                        sm: "15px",
                        md: "16px",
                        lg: "19px",
                        xl: "19px",
                      },
                      position: "absolute",
                      left: {
                        xs: "50px",
                        sm: "398px",
                        md: "396px",
                        lg: "402px",
                        xl: "402px",
                      },
                    }}
                  />
                </Tooltips>
              )}

              <EditOutlinedIcon sx={{ fontSize: "15px" }} />
            </InputAdornment>
          ),
        }}
        name="websiteURL"
        variant="standard"
        helperText={userError?.websiteURL ? userError?.websiteURLMsg : ""}
      />
      {userData?.websiteURL !== "" && (
        <Box
          sx={{
            display: "flex",
            marginTop: "5px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              letterSpacing: "0.4px",
              color: "#01537A",
            }}
          >
            Start URL
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            letterSpacing: "0.4px",
            color: "#1B2935",
            lineHeight: "14.84px",
          }}
        >
          NAT Gateway Required
        </Typography>

        <Tooltips type="natGateway">
          <ErrorOutlineIcon
            sx={{
              marginLeft: "5px",
              color: "#01537A",
              fontSize: "15px",
              cursor: "pointer",
            }}
          />
        </Tooltips>
      </Box>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>No</Typography>
        <IOSSwitch
          name="isNatGatewayRequired"
          checked={checked}
          onChange={handleSwitch}
          inputProps={{ "aria-label": "ant design" }}
        />
        <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Yes</Typography>
      </Stack>
      {userError?.natGateway && (
        <FormHelperText
          sx={{
            color: "#d32f2f",
          }}
        >
          {userError?.natGateway ? userError?.natGatewayMsg : ""}
        </FormHelperText>
      )}
      {gatewayDomains.length === 1 ? (
        <TextField
          sx={{
            width: "100%",
            "& input::placeholder": {
              color: "#01537A",
              fontSize: "14px",
              opacity: "1",
            },
          }}
          placeholder={`Enter your website’s current start URL starting with https://`}
          required
          inputProps={{
            sx: {
              fontSize: {
                xs: "14px",
                md: "12px",
                lg: "16px",
              },
              fontWeight: 400,
              letterSpacing: 0.5,
              color: "rgba(0, 0, 0, 0.87)",
            },
          }}
          inputlabelprops={{
            style: { fontSize: 16, fontWeight: 400 },
          }}
          value={gatewayDomains[0].gateway}
          name="gatewayDomain"
          variant="standard"
        />
      ) : (
        <FormControl
          variant="standard"
          sx={{ width: "100%", marginTop: userError?.natGateway ? "" : "15px" }}
        >
          <Select
            displayEmpty
            renderValue={renderValue}
            inputProps={{
              sx: {
                fontSize: {
                  xs: "14px",
                  md: "12px",
                  lg: "16px",
                },
                fontWeight: 400,
                letterSpacing: 0.5,
                color: "rgba(0, 0, 0, 0.87)",
              },
            }}
            inputlabelprops={{
              sx: {
                fontSize: {
                  xs: "14px",
                  md: "12px",
                  lg: "36px",
                },
                fontWeight: 400,
              },
            }}
            onChange={handleInputChange}
            value={userData?.gatewayDomain ?? null}
            error={userError?.gatewayDomain}
            name="gatewayDomain"
          >
            {gatewayDomains.length > 0 ? (
              gatewayDomains.map((option, index) => (
                <MenuItem key={index} value={option.gateway}>
                  {option.gateway}
                </MenuItem>
              ))
            ) : (
              <MenuItem>No items found</MenuItem>
            )}
          </Select>
          <FormHelperText
            sx={{
              color: "#d32f2f",
            }}
          >
            {userError?.gatewayDomain ? userError?.gatewayDomainMsg : ""}
          </FormHelperText>
        </FormControl>
      )}

      {userData?.gatewayDomain !== "" && (
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
            color: "#01537A",
          }}
        >
          Gateway Domain
        </Typography>
      )}
    </Box>
  );
}
