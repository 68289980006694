import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";
import DataTable from "./dataTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation } from "react-router-dom";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Typography,
  TextField,
  Paper,
  Divider,
  Button,
  ButtonGroup,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import Loader from "../../components/loader";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import Service from "../../api/services";
import PaginationRounded from "../pagination";
import PaginationHeader from "./paginationHeader";
import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ModalViewButton from "./modalViewButton";
import {
  statusContext,
  switchStatusContext,
  archivedStatusContext,
} from "../../context/serviceContext";
import { WarningMessages } from "../../config/messages";
import useToast from "../../hooks/useToast";

const nameComparator = (v1, v2, param1, param2) => {
  if (v1.vals !== "" && v2.vals !== "") {
    return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
  }
};

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      boxShadow: 2,
      border: "none",
      maxWidth: "150px",
      color: "#000 !important",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderTopRightRadius: "6px",
      borderTopLeftRadius: "6px",
      marginTop: "10px",
      "& .Mui-focused ": {
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
        color: "#000 !important",
      },
      "& .MuiMenuItem-root": {
        color: "#000",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        fontSize: "16px",
        backgroundColor: "transparent",
        padding: "5px",
      },
      "& .MuiMenuItem-root: last-child": {
        color: "#000",
        borderBottom: "none",
        fontSize: "16px",
        backgroundColor: "#fff",
        "&:focus": {
          color: "#01537A",
        },
      },
      "& .Mui-selected:hover": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .Mui-selected": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .MuiBox-root:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        borderRadius: "6px",
        borderBottom: "none",
      },
      "& .MuiBox-root": {
        padding: "8px 10px",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: 2,
        paddingTop: "15px",
        borderRadius: "6px",
      },
      "& .MuiList-root": {
        padding: "0 15px",
      },
    },
  },
};

const NewIcon = (props) => (
  <KeyboardArrowDownIcon {...props} sx={{ color: "#01537A !important" }} />
);

const Stack1 = styled(Box)({
  color: "#01537A",
  fontWeight: 400,
  fontSize: "12px",
  letterSpacing: "0.4px",
  lineHeight: "24px",
  width: "150px",
  margintop: "5.5px",
});

const StackBox = styled(Box)({
  paddingBottom: "50px",
});

const Stack2 = styled(Box)({
  color: "rgba(0, 0, 0, 0.87)",
  fontWeight: 400,
  fontSize: "16px",
  width: "400px",
  height: "24px",
  lineHeight: "24px",
});

const LicenseeServicesPanel = forwardRef((props, ref) => {
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState();
  const userRole = localStorage.getItem("role");

  const [open, setOpen] = React.useState(false);
  const { showSuccess, showError, showWarning } = useToast();
  const handleClose = () => {
    setOpen(false);
    setItems([]);
  };

  const [bulkLoading, setBulkLoading] = useState(false);
  const handleBulkSave = () => {
    setBulkLoading(true);
    const newObject = {
      items: items,
      editorId: localStorage.getItem("userid"),
    };

    Service.licenseeServiceBulkUpdate(newObject)
      .then((res) => {
        if (res.data.status === "success") {
          showSuccess(res.data.message);
        } else if (res.data.status === "failed") {
          showError(res.data.message);
        }

        cancelUpdate();
        setBulkLoading(false);
        handleClose();
      })
      .catch((err) => {});
  };

  const [items, setItems] = useState([]);
  const handleStatus = (serviceId, value) => {
    const result = items.find(
      ({ licenseeServiceId }) => licenseeServiceId === serviceId
    );
    if (result !== undefined) {
      result["status"] = value;
    } else {
      setItems([
        ...items,
        {
          licenseeServiceId: serviceId,
          status: value,
        },
      ]);
    }
  };

  const accessControls = () => {
    if (userRole === "ROLE_ADMIN") {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionFields = () => {
    if (archivedStatusContextVal) {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionButton = (btnName) => {
    if (archivedStatusContextVal) {
      return `${btnName}-btn disable`;
    } else {
      return `${btnName}-btn`;
    }
  };

  function DetailPanelContent({ row: rowProp }) {
    const apiRef = useGridApiContext();

    const expandRows = apiRef.current.state.detailPanel.expandedRowIds;

    expandRows.map((item) =>
      item !== rowProp.serviceId ? apiRef.current.toggleDetailPanel(item) : ""
    );

    const handleClose = () => {
      setSaveError({
        description: false,
        descriptionMsg: "",
      });
      setSaveClick(false);
      apiRef.current.toggleDetailPanel(rowProp.serviceId);
    };

    const [saveData, setSaveData] = useState({
      licenseeServiceId: "",
      description: "",
      status: "",
      editorId: localStorage.getItem("userid"),
    });

    const [saveError, setSaveError] = useState({
      description: false,
      descriptionMsg: "",
    });

    const handleEdit = (e) => {
      const newData = { ...saveData };
      newData[e.target.name] = e.target.value;

      if (e.target.name === "description") {
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setSaveError({
            ...saveError,
            description: true,
            descriptionMsg: "This field is required",
          });
        } else {
          setSaveError({
            ...saveError,
            description: false,
            descriptionMsg: "",
          });
        }
      }
      setSaveData(newData);
    };

    const setStatus = (stat) => {
      setSaveData({
        ...saveData,
        status: stat,
      });
    };
    const [saveClick, setSaveClick] = useState(false);
    const updateServices = () => {
      if (saveData?.description === "") {
        setSaveError({
          ...saveError,
          description: true,
          descriptionMsg: "This field is required",
        });
      } else {
        setSaveClick(true);
        Service.updateLicenseeService(saveData, saveData?.licenseeServiceId)
          .then((res) => {
            if (res.data.status === "success") {
              showSuccess(res.data.message);
              setSaveClick(false);
              getLicenseeServices();
              handleClose();
            }
          })
          .catch((err) => {});
      }
    };

    const accessPermission = (status, checkStatus) => {
      if (accessControls() && !archivedStatusContextVal) {
        if (status === checkStatus) {
          return "btn-active";
        } else {
          return "btn-nonactive";
        }
      } else {
        if (userRole === "ROLE_LICENSEE") {
          if (status === checkStatus) {
            return "btn-disable-active disable";
          } else {
            return "btn-disable-nonactive disable";
          }
        } else {
          if (archivedStatusContextVal) {
            if (status === checkStatus) {
              return "btn-active disable";
            } else {
              return "btn-nonactive disable";
            }
          } else {
            if (status === checkStatus) {
              return "btn-active";
            } else {
              return "btn-nonactive";
            }
          }
        }
      }
    };

    useEffect(() => {
      const newData = { ...saveData };
      newData["licenseeServiceId"] = rowProp.serviceId;
      newData["description"] = rowProp.description;
      newData["status"] = rowProp.status;
      setSaveData(newData);
      //eslint-disable-next-line
    }, [rowProp]);
    return (
      <Dialog
        open={true}
        maxWidth="xl"
        scroll={"body"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              marginTop: "10px",
              marginLeft: "34px",
              fontSize: "24px",
              color: "#01537A",
              padding: { lg: "20px 0px", md: "20px 0px" },
            }}
          >
            Update Licensee Services Details
          </Typography>
          <CloseSharpIcon
            sx={{
              cursor: "pointer",
              color: "#01537A",
            }}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: "0px 40px" }}>
            <Box
              sx={{
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
                display: "flex",
                columnGap: { xl: 10, lg: 8, md: 5, xs: 3 },
              }}
            >
              <Box>
                <Box>
                  <Typography
                    sx={{
                      width: "45px",
                      height: "15px",
                      fontWeight: "400",
                      marginBottom: "8px",
                      color: "#1B2935",
                    }}
                  >
                    Status
                  </Typography>
                  <ButtonGroup
                    sx={{
                      width: {
                        xs: "380px",
                        sm: "400px",
                        md: "450px",
                        lg: "565px",
                        xl: "650px",
                      },
                      height: { xs: "35px", lg: "40px" },
                      fontWeight: 400,
                    }}
                  >
                    <Button
                      type="button"
                      sx={{
                        width: "100%",
                        fontSize: { xs: "12px", md: "14px", lg: "16px" },
                        textTransform: "capitalize",
                      }}
                      startIcon={
                        saveData?.status === "LIVE" ? (
                          <DoneIcon sx={{ fontColor: "#01537A" }} />
                        ) : (
                          ""
                        )
                      }
                      className={accessPermission("LIVE", saveData?.status)}
                      onClick={() => setStatus("LIVE")}
                    >
                      Live
                    </Button>

                    <Button
                      type="button"
                      sx={{
                        width: "100%",
                        fontSize: { xs: "12px", md: "14px", lg: "16px" },
                        textTransform: "capitalize",
                      }}
                      startIcon={
                        saveData?.status === "OFF" ? (
                          <DoneIcon sx={{ fontColor: "#01537A" }} />
                        ) : (
                          ""
                        )
                      }
                      className={accessPermission("OFF", saveData?.status)}
                      onClick={() => setStatus("OFF")}
                    >
                      Off
                    </Button>
                  </ButtonGroup>
                </Box>

                <Box sx={{ marginTop: "30px" }}>
                  <TextField
                    sx={{
                      width: {
                        xs: "380px",
                        sm: "400px",
                        md: "450px",
                        lg: "565px",
                        xl: "650px",
                      },
                      color: "#231F20",
                    }}
                    value={saveData?.description}
                    error={saveError?.description}
                    onChange={handleEdit}
                    variant="standard"
                    name="description"
                    helperText={
                      saveError?.description ? saveError?.descriptionMsg : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {!accessPermissionFields() && (
                            <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                          )}
                        </InputAdornment>
                      ),
                      readOnly: accessPermissionFields() && true,
                    }}
                  />

                  <Typography
                    sx={{
                      width: "85px",
                      height: "24px",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "24px",
                      letterSpacing: "0.4px",
                      color: "#01537A",
                    }}
                  >
                    Description
                  </Typography>
                </Box>
              </Box>

              <Paper
                sx={{
                  maxWidth: {
                    xs: "380px",
                    sm: "400px",
                    md: "450px",
                    lg: "450px",
                  },
                  width: "100%",
                  height: "389px",
                  padding: "40px",
                  display: "flex",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  justifyContent: "space-between",
                }}
              >
                <Stack1 direction="column" spacing={2}>
                  <StackBox
                    sx={{
                      width: "150px",
                      height: "24px",
                      lineHeight: "24px",
                      fontSize: "12px",
                      letterSpacing: "0.4px",
                    }}
                  >
                    Service Name{" "}
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "150px",
                      height: "24px",
                      lineHeight: "24px",
                      fontSize: "12px",
                      letterSpacing: "0.4px",
                    }}
                  >
                    Service Type
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "150px",
                      height: "24px",
                      lineHeight: "24px",
                      fontSize: "12px",
                      letterSpacing: "0.4px",
                    }}
                  >
                    Proxy Domain
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "150px",
                      height: "24px",
                      lineHeight: "24px",
                      fontSize: "12px",
                      letterSpacing: "0.4px",
                    }}
                  >
                    Zero Rated?
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "150px",
                      height: "24px",
                      lineHeight: "24px",
                      fontSize: "12px",
                      letterSpacing: "0.4px",
                    }}
                  >
                    Service Config Approved
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "150px",
                      height: "35px",
                      lineHeight: "24px",
                      fontSize: "12px",
                      letterSpacing: "0.4px",
                    }}
                  >
                    Supported Products
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "150px",
                      height: "35px",
                      lineHeight: "11.5px",
                      fontSize: "12px",
                      letterSpacing: "0.4px",
                    }}
                  >
                    NAT Gateway required to connect to Customer Endpoint
                  </StackBox>
                </Stack1>
                <Stack2
                  direction="column"
                  spacing={2}
                  sx={{
                    marginLeft: "25px",
                  }}
                >
                  <StackBox
                    sx={{
                      width: "188px",
                      height: "24px",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {rowProp?.name ? rowProp?.name : ""}
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "200px",
                      height: "24px",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {rowProp?.service ? rowProp?.service?.service : "-"}
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "200px",
                      height: "18px",
                      fontSize: "16px",
                      lineHeight: "18px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {rowProp?.proxyDomain ? rowProp?.proxyDomain : "-"}
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "188px",
                      height: "18px",
                      lineHeight: "18px",
                      letterSpacing: "0.5px",
                      fontSize: "16px",
                    }}
                  >
                    {rowProp?.zeroRated
                      ? rowProp?.zeroRated === 1
                        ? "Yes"
                        : "No"
                      : "-"}
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "174px",
                      height: "18px",
                      lineHeight: "18px",
                      letterSpacing: "0.5px",
                      fontSize: "16px",
                    }}
                  >
                    {rowProp?.serviceConfigApproved ? "True" : "False"}
                  </StackBox>
                  <StackBox
                    sx={{
                      height: "36px",
                      lineHeight: "18px",
                      letterSpacing: "0.5px",
                      fontSize: "16px",
                    }}
                  >
                    {rowProp?.supportedApps.length > 0
                      ? rowProp?.supportedApps.map((products, index) => (
                          <span key={index} style={{ display: "flex" }}>
                            {products}
                          </span>
                        ))
                      : "-"}
                  </StackBox>
                  <StackBox
                    sx={{
                      width: "174px",
                      height: "33px",
                      letterSpacing: "0.5px",
                      fontSize: "16px",
                    }}
                  >
                    {rowProp?.NATGatewayReq ? "True" : "False"}
                  </StackBox>
                </Stack2>
              </Paper>
            </Box>

            <Box sx={{ mt: "20px" }}>
              <Button
                className={accessPermissionButton("modal-cancel")}
                variant="outlined"
              >
                View IP Details
              </Button>

              <Divider
                sx={{
                  color: "#DFDFDF",
                  marginBottom: "30px",
                  marginTop: "20px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  padding: "30px 0px",
                }}
              >
                <Button
                  variant="outlined"
                  className={accessPermissionButton("modal-cancel")}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  sx={{
                    "&.Mui-disabled": {
                      cursor: "not-allowed !important",
                      pointerEvents: "unset !important",
                    },
                  }}
                  className={accessPermissionButton("modal-save")}
                  variant="contained"
                  onClick={updateServices}
                  disabled={saveClick}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <span style={{ marginRight: "5px" }}>Save</span>
                    {saveClick ? (
                      <Loader
                        type={"spinningBubbles"}
                        color={"white"}
                        height={20}
                        width={20}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  DetailPanelContent.propTypes = {
    row: PropTypes.object.isRequired,
  };

  CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
  };

  const handleSave = () => {
    if (items.length > 0) {
      setOpen(true);
    } else {
      showWarning(WarningMessages?.noActivity);
    }
  };

  const cancelUpdate = () => {
    getLicenseeServices();
  };

  function CustomDetailPanelToggle() {
    return <ModalViewButton />;
  }

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const changePagination = (event, value) => {
    setPage(value - 1);
  };

  const handlePageSize = (e) => {
    setSize(e.target.value);
    setPage(0);
  };
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const clickedCompId = location.state?.clickedCompId;

  async function getLicenseeServices() {
    setLoading(true);
    Service.LicenseeServices({ licenseeId: clickedCompId }, page, size)
      .then((res) => {
        setResData(res.data.serviceDetails);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((err) => {});
  }

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  useEffect(() => {
    getLicenseeServices();
    //eslint-disable-next-line
  }, [page, size]);

  useImperativeHandle(ref, () => ({
    getServiceList() {
      getLicenseeServices();
    },
  }));

  const textStyle = {
    color: "#0094CD",
    fontWeight: 500,
    fontSize: "14px",
  };

  const textStyle1 = {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 400,
    fontSize: "16px",
    letterSpacing: 0.5,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "200px",
  };

  const textStyle2 = {
    color: "#231F20",
    fontWeight: "400",
    fontSize: "14px",
    width: "169px",
    height: "15px",
  };
  const columns = [
    {
      flex: 1,
      field: "name",
      headerName: "Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.name,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return <Box sx={textStyle}>{params.row.name}</Box>;
      },
    },

    {
      field: "description",
      headerName: "Description",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.description,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return <Box sx={textStyle1}>{params.row.description}</Box>;
      },
    },

    {
      field: "service",
      headerName: "Service",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.service,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return <Box sx={textStyle2}>{params.row.service.service}</Box>;
      },
    },

    {
      field: "proxyDomain",
      headerName: "Proxy Domain",
      minWidth: 220,
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.proxyDomain,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <Box
              style={{ textStyle2 }}
              sx={{
                display: "flex",
              }}
            >
              {params.row.proxyDomain ? params.row.proxyDomain : "-"}
            </Box>
          </div>
        );
      },
    },

    {
      flex: 1,
      field: "zeroRated",
      headerName: "Zero Rated",
      width: 140,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.zeroRated,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <Box sx={textStyle2}>{params.row.zeroRated === 1 ? "Yes" : "No"}</Box>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.status,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <>
            {accessControls() && !accessPermissionFields() ? (
              <FormControl
                variant="standard"
                sx={{
                  minWidth: 80,
                  backgroundColor: "transparent !important",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "400",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
                <Select
                  sx={{ backgroundColor: "transparent !important" }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name="status"
                  defaultValue={params.row.status === "LIVE" ? "LIVE" : "OFF"}
                  onChange={(e) =>
                    handleStatus(params.row.serviceId, e.target.value)
                  }
                  MenuProps={MenuProps}
                  disableUnderline={true}
                  IconComponent={NewIcon}
                >
                  <MenuItem value="LIVE">Live</MenuItem>
                  <MenuItem value="OFF">Off</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <span>{params.row.status}</span>
            )}
          </>
        );
      },
    },

    {
      headerClassName: "super-app-theme--header",
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.row.serviceId} />
      ),
    },
  ];

  return (
    <Container
      maxWidth="xxl"
      style={{
        padding: "0",
      }}
    >
      {resData && !loading ? (
        <>
          {totalCount !== 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                pb: "5px",
                pr: "2px",
              }}
            >
              <PaginationHeader
                page={page}
                size={size}
                dataLength={resData.length}
                totalCount={totalCount}
              />
            </Box>
          ) : null}
          <Box
            sx={{
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor:
                  switchStatusContextVal && statusContextVal
                    ? "#373737"
                    : "#01537A !important",
                color: "white",
              },
            }}
          >
            <DataTable
              rows={resData}
              columns={columns}
              tableName="usersPanel"
              loading={loading}
              getDetailPanelContent={getDetailPanelContent}
            />
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
        </Box>
      )}

      {!loading && totalCount >= 10 ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Typography
            component="span"
            variant="body2"
            sx={{ color: "#01537A" }}
          >
            Items per page :{" "}
          </Typography>
          <FormControl variant="standard">
            <Select
              value={size}
              sx={{
                width: "55px",
                margin: "0px 10px 0px 5px",
                backgroundColor: "#8080802b",
                color: "#000",
                borderBottom: "1px solid #01537A",
                textAlign: "center",
                "&:after": {
                  borderBottom: "1.5px solid #01537A",
                },
                "&hover": {
                  borderBottom: "1px solid #01537A",
                },
              }}
              onChange={handlePageSize}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>{Number(10).toLocaleString()}</MenuItem>
              <MenuItem value={25}>{Number(25).toLocaleString()}</MenuItem>
              <MenuItem value={50}>{Number(50).toLocaleString()}</MenuItem>
              <MenuItem value={100}>{Number(100).toLocaleString()}</MenuItem>
            </Select>
          </FormControl>
          <PaginationRounded
            changeHandle={changePagination}
            page={page + 1}
            Count={totalCount}
            size={size}
          />
        </Box>
      ) : null}
      {!loading && totalCount > 0 && accessControls() && (
        <Box
          sx={{
            padding: "20px 0px 10px 0px",
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button
            className={accessPermissionButton("cancel")}
            variant="outlined"
            onClick={cancelUpdate}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            sx={{
              "&.Mui-disabled": {
                cursor: "not-allowed !important",
                pointerEvents: "unset !important",
              },
            }}
            className={accessPermissionButton("save")}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Box>
      )}
      <Dialog open={open} onClose={handleClose} scroll={"body"} maxWidth="xl">
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                marginTop: "10px",
                color: "#01537A",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "46px",
                width: "658px",
                height: "40px",
              }}
            >
              Bulk Update
            </Typography>
            {bulkLoading ? (
              ""
            ) : (
              <CloseSharpIcon
                sx={{
                  cursor: "pointer",
                  color: "#01537A",
                }}
                onClick={handleClose}
              />
            )}
          </Box>
        </DialogTitle>
        {bulkLoading ? (
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "12px 12px 74px 12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "12px",
                }}
              >
                <Loader
                  type={"bubbles"}
                  color={"#01537A"}
                  height={40}
                  width={60}
                />
              </Box>
              <Typography
                sx={{
                  top: "56px",
                  color: "#01537A",
                  fontWeight: "300",
                  fontSize: "20px",
                  lineHeight: "26.11px",
                  width: "100%",
                  alignContent: "center",
                }}
              >
                Updating {items.length} records.
              </Typography>
            </Box>
          </DialogContent>
        ) : (
          <DialogContent>
            <Box
              sx={{
                width: "100%",
                color: "#01537A",
                fontWeight: "300",
                fontSize: "20px",
                lineHeight: "26.11px",
                alignItems: "center",
                padding: "25px",
              }}
            >
              You are updating {items.length} records , are you sure you want to
              make these changes? Some of these changes might be permanent.
            </Box>
          </DialogContent>
        )}

        {!bulkLoading ? (
          <DialogActions>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 84px 30px 84px",
                gap: 2,
              }}
            >
              <Button
                className="modal-cancel-btn"
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                className="modal-save-btn"
                onClick={handleBulkSave}
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </DialogActions>
        ) : (
          ""
        )}
      </Dialog>
    </Container>
  );
});

export default LicenseeServicesPanel;
