import React, { useState } from "react";
import {
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Checkbox,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      boxShadow: "none",
      border: "1px solid #01537A",
      bgcolor: "#fff",
      maxWidth: "150px",
      color: "#01537A !important",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderTopRightRadius: "0",
      borderTopLeftRadius: "0",
      "& .Mui-focused ": {
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
        color: "#01537A !important",
      },
      "& .MuiOutlinedInput-root": {
        fontWeight: "700",
        color: "#01537A !important",
        borderColor: "#01537A",
      },
      "& .MuiMenuItem-root": {
        color: "#01537A",
        borderBottom: "1px solid #01537A",
        fontSize: "16px",
        backgroundColor: "#fff",
        padding: "2px",
      },
      "& .MuiMenuItem-root: last-child": {
        color: "#01537A",
        borderBottom: "none",
        fontSize: "16px",
        backgroundColor: "#fff",
        padding: "5px 0px",
      },
      "& .Mui-selected:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        color: "#01537A",
        borderRadius: '6px !important'
      },
      "& .Mui-selected": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        borderRadius: '6px',
      },
      "& .MuiBox-root:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        borderRadius: '6px'
      },
      "& .MuiBox-root": {
        padding: "5px 10px",
        width: "100%",
      },
      "& .MuiList-root": {
        padding: "0 15px",
      },
    },
  },
};

export default function MultipleSelectCheckmarks({handleChange, ...props}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    e.preventDefault();
    handleChange(e)
    handleClose();
  }
 
  return (
    <div>
      {(props.value &&props.menuValues) &&  <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={props.value}
        onChange={onChange}
        displayEmpty
        IconComponent={() => (
          <KeyboardArrowDownIcon
            htmlColor="#01537A"
            sx={{
              position: "absolute",
              right: "5px",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          />
        )}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        input={
          <OutlinedInput
            sx={{
              borderColor: "#01537A !important",
              width: props.width !== undefined ? props.width: "110px",
              color: "#01537A",
              borderRadius: "6px",
              fontSize: "16px",
              "& fieldset": { borderColor: "#01537A !important" },
            }}
          />
        }
        renderValue={(selected) =>
          selected !== "" ? selected.join(",") : ""
        }
        MenuProps={MenuProps}
      >
        <MenuItem value="All">
          <Checkbox
            checked={props.checked}
            indeterminate={
              props.value.length > 0 &&
              props.value.length < props.menuValues.length
            }
            sx={{
              color: "#01537A !important",
              "&.Mui-checked": {
                color: "#01537A !important",
              },
            }}
          />

          <ListItemText primary="All" />
        </MenuItem>
        {(props.menuValues && props.value) && props.menuValues.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox
              checked={props.value.indexOf(name) > -1}
              sx={{
                color: "#01537A !important",
                "&.Mui-checked": {
                  color: "#01537A !important",
                },
              }}
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select> }
     
    </div>
  );
}
