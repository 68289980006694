import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import Add from "../assets/img/Add.svg";
import AvatarLogo from "../assets/img/avatar.svg";
import Profileavatar from "../assets/img/noImages.png";
import Link from "@mui/material/Link";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  List,
  ListItem,
  Paper,
  ListItemAvatar,
  Container,
  Avatar,
  OutlinedInput,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import avatar from "../assets/img/defaultUserImg.png";
import Graph from "./graph";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LayersIcon from "@mui/icons-material/Layers";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Fab from "@mui/material/Fab";
import Services from "../api/services";
import moment from "moment";
import Loader from "../components/loader";
import SnackbarWrapper from "./snackBar";
import ReactApexChart from "react-apexcharts";
import PaginationRounded from "./userPagination";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import BreadCrumb from "./breadCrumb";
import {
  userContext,
  userDispatchContext,
  switchStatusDispatchContext,
  archivedStatusDispatchContext,
  switchStatusContext,
  archivedStatusContext,
  statusContext,
} from "../context/serviceContext";
import WrapIcon from "../assets/img/wrap-icon-image.png";
import switchAppIcon from "../assets/img/swichIcon.svg";
import reachDefaultIcon from "../assets/img/reachDefaultIcon.svg";
import { useUser } from "../context/userContext";
import useToast from "../hooks/useToast";
import { getSwitchedUserId } from "../utils/roleHelper";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#000000",
    fontSize: 14,
    fontWeight: 600,
    height: "35px",
    textAlign: "center",
    padding: "9px",
    backgroundColor: "#DFDFDF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "2px",
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#0094CD",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0094CD",
    width: "120px",
    height: "45px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    justifyContent: "center",
    padding: "15px",
    marginBottom: "5px",
  },
}));

export default function Dashboard() {
  const { showWarning } = useToast();
  const userContextVal = useContext(userContext);
  const userDispatchContextVal = useContext(userDispatchContext);
  let switchStatusContextVal = useContext(switchStatusDispatchContext);
  let archivedStatusDispatchContextVal = useContext(
    archivedStatusDispatchContext
  );
  const [allData, setAllData] = useState();
  const [latestPublisher, setLatestPublisher] = useState();
  const [latestApps, setLatestApps] = useState();
  const [isMount, setIsMount] = React.useState();
  const [activeUsers, setActiveUsers] = useState();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState();
  const [opens, setOpens] = React.useState(false);
  const { userImage } = useUser();
  const matches = useMediaQuery("(max-width:480px)");
  const switchRole = localStorage.getItem("switchRole");
  const switchStatusContextValue = JSON.parse(useContext(switchStatusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const statusContextVal = JSON.parse(useContext(statusContext));
  const [isAppHovered, setIsAppHovered] = useState(false);
  const [isActiveHovered, setIsActiveHovered] = useState(false);

  const accessPermissionFields = () => {
    if (switchStatusContextValue) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleOpen = () => {
    setOpens(true);
  };
  const handleCloses = () => {
    setOpens(false);
  };
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      sx: {
        boxShadow: "none",
        border: "1px solid #01537A",
        bgcolor: "#fff",
        maxWidth: "160px",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        borderTopRightRadius: "0",
        borderTopLeftRadius: "0",
        paddingRight: "8px",
        "& .Mui-focused ": {
          borderBottomRightRadius: "0",
          borderBottomLeftRadius: "0",
        },
        "& .MuiOutlinedInput-root": {
          fontWeight: "700",
          color: "#01537A",
        },
        "& .MuiMenuItem-root": {
          color: "#01537A",
          borderBottom: "1px solid #01537A",
          fontSize: "17px",
          backgroundColor: "#fff",
          padding: "6px 0px",
        },
        "& .MuiMenuItem-root: last-child": {
          color: "#01537A",
          borderBottom: "none",
          fontSize: "17px",
          backgroundColor: "#fff",
          padding: "5px 0px",
        },
        "& .Mui-selected": {
          backgroundColor: "rgba(1, 83, 122, 0.1)",
          borderRadius: "6px !important",
        },
        "& .Mui-selected:hover": {
          backgroundColor: "rgba(1, 83, 122, 0.1)",
          borderRadius: "6px !important",
          padding: "3px",
        },
        "& .MuiBox-root:hover": {
          backgroundColor: "rgba(1, 83, 122, 0.1)",
          borderRadius: "4px",
        },
        "& .MuiBox-root": {
          padding: "5px 10px",
          width: "100%",
        },
        "& .MuiList-root": {
          padding: "0 15px",
        },
        "& .MuiInputBase-input": {
          padding: "6px 10px 7px",
        },
      },
    },
  };

  const [companyData, setCompanyData] = useState();
  let API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const clickedCompId = location.state?.clickedCompId;

  async function getData() {
    setLoading(true);
    Services.AdminIndex({ companyId: getSwitchedUserId(clickedCompId) })
      .then((res) => {
        if (res?.data?.status === "failed") {
          navigate("/");
          showWarning(res?.data?.message);
          return;
        }
        setAllData(res.data);
        setLatestPublisher(res?.data?.publisherLatestChanges);
        setActiveUsers(res.data.recentlyActiveUsers);
        setCompanyData(res.data.companyDetails);
        setLoading(false);
        if (res.data.companyDetails?.status === "Suspended") {
          localStorage.setItem("switchStatus", true);
          switchStatusContextVal(true);
          localStorage.setItem("archivedStatus", false);
          archivedStatusDispatchContextVal(false);
        } else if (res.data.companyDetails?.status === "Archived") {
          localStorage.setItem("archivedStatus", true);
          archivedStatusDispatchContextVal(true);
          localStorage.setItem("switchStatus", false);
          switchStatusContextVal(false);
        } else {
          localStorage.setItem("switchStatus", false);
          switchStatusContextVal(false);
          localStorage.setItem("archivedStatus", false);
          archivedStatusDispatchContextVal(false);
        }

        if (
          res.data.notificationMessage !== null &&
          res.data.notificationMessage > 0
        ) {
          setOpen(true);
          setNotifyMessage(res.data?.notificationMessage[0].notification_text);
        }
      })
      .catch((err) => {});
  }

  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const getChanges = (changedVal) => {
    if (changedVal < 0) {
      return (
        <>
          {Math.abs(changedVal)}
          <ArrowDownwardIcon style={{ color: "#FF5252", fontSize: 14 }} />
        </>
      );
    } else if (changedVal > 0) {
      return (
        <>
          {Math.abs(changedVal)}
          <ArrowUpwardIcon style={{ color: "#4CAF50", fontSize: 14 }} />
        </>
      );
    } else {
      return "No Change";
    }
  };
  const addUser = () => {
    if (switchRole) {
      localStorage.setItem("customerId", getSwitchedUserId(clickedCompId));
      localStorage.setItem("switchedUser", JSON.stringify(true));
    } else {
      localStorage.setItem("customerId", localStorage.getItem("companyId"));
      localStorage.setItem("switchedUser", JSON.stringify(false));
    }
    navigate("/create-user");
  };
  const addApplication = () => {
    if (switchRole) {
      localStorage.setItem("customerId", getSwitchedUserId(clickedCompId));
      localStorage.setItem("switchedUser", JSON.stringify(true));
    } else {
      localStorage.setItem("customerId", localStorage.getItem("companyId"));
      localStorage.setItem("switchedUser", JSON.stringify(false));
    }
    navigate("/create-application");
  };
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter === 59) {
        getData();
        setCounter(0);
        graphDatas();
        topApps();
      } else {
        setCounter((counter) => counter + 1);
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };
    //eslint-disable-next-line
  }, [counter]);

  const redirectPages = (tabval, type) => {
    const userRole = localStorage.getItem("role");
    if (
      userRole === "ROLE_ADMIN" ||
      userRole === "ROLE_LICENSEE" ||
      userRole === "ROLE_CHANNEL"
    ) {
      localStorage.setItem("publisherId", companyData?.companyId);
      navigate("/customer/search", {
        state: {
          tabVal: tabval,
          type: type,
          publisherId: getSwitchedUserId(clickedCompId),
        },
      });
    } else {
      localStorage.setItem("publisherId", companyData?.companyId);
      navigate("/search", {
        state: {
          tabVal: tabval,
          type: type,
          publisherId: getSwitchedUserId(clickedCompId),
        },
      });
    }
  };

  const redirectViewall = (tabval) => {
    const userRole = localStorage.getItem("role");
    if (
      userRole === "ROLE_ADMIN" ||
      userRole === "ROLE_LICENSEE" ||
      userRole === "ROLE_CHANNEL"
    ) {
      localStorage.setItem("publisherId", companyData?.companyId);
      navigate("/customer/search", {
        state: {
          tabVal: tabval,
          sortType: "Last Changed",
          publisherId: getSwitchedUserId(clickedCompId),
        },
      });
    } else {
      localStorage.setItem("publisherId", companyData?.companyId);
      navigate("/search", {
        state: {
          tabVal: tabval,
          sortType: "Last Changed",
          publisherId: getSwitchedUserId(clickedCompId),
        },
      });
    }
  };
  const applicationDetailPage = (value) => {
    navigate("/application-details", {
      state: {
        clickedAppId: value?.applicationId,
        productType: value?.productId?.productType,
      },
    });
  };

  const switchApp = (value) => {
    if (value === "#datafree REACH") {
      return true;
    } else if (value === "#datafree WRAP") {
      return true;
    } else if (value === "#datafree SWITCH") {
      return true;
    } else if (value === "#datafree DIRECT") {
      return true;
    } else if (value === "D-Direct") {
      return true;
    } else if (value === "Connect Workspace") {
      return true;
    } else {
      return false;
    }
  };
  const goToChangeLog = (tabval) => {
    const userRole = localStorage.getItem("role");
    if (
      userRole === "ROLE_ADMIN" ||
      userRole === "ROLE_LICENSEE" ||
      userRole === "ROLE_CHANNEL"
    ) {
      navigate("/customer/accountsettings", {
        state: {
          tabVal: tabval,
          clickedCompId: getSwitchedUserId(clickedCompId),
        },
      });
    } else {
      navigate("/accountsettings", {
        state: {
          tabVal: tabval,
          clickedCompId: getSwitchedUserId(clickedCompId),
        },
      });
    }
  };
  const getprodType = (app) => {
    if (app?.productId) {
      return (
        <>
          <span>
            {app?.productId === 7
              ? "D-Direct"
              : app?.productId === 1
              ? "Wrap"
              : app?.productId === 2
              ? "Max"
              : app?.productId === 3
              ? "Switch"
              : app?.productId === 4
              ? "Reach"
              : app?.productId === 5
              ? "S-Direct"
              : "Connect"}
          </span>
        </>
      );
    }
  };

  const refreshData = () => {
    setCounter(0);
    getData();
    graphDatas();
    topApps();
  };

  const [selected, setSelected] = useState("7");
  const handleGraph = (event) => {
    setSelected(event.target.value);
  };
  function getAppIcon(app, productTypes) {
    const defaultIcon = "defaultappicon.svg";
    const productType = productTypes;

    if (app !== defaultIcon) {
      return API_URL + app;
    } else {
      if (productType === "#datafree WRAP") {
        return WrapIcon;
      } else if (productType === "#datafree REACH") {
        return reachDefaultIcon;
      } else if (productType === "#datafree SWITCH") {
        return switchAppIcon;
      } else if (productType === "#datafree DIRECT") {
        return switchAppIcon;
      } else if (productType === "D-Direct") {
        return switchAppIcon;
      } else if (productType === "Connect Workspace") {
        return switchAppIcon;
      } else {
        return AvatarLogo;
      }
    }
  }
  const [graphData, setGraphData] = useState();

  const [load, setLoad] = useState(false);
  async function graphDatas() {
    setLoad(true);
    Services.getGraph({
      reportPeriod: selected,
      companyId: getSwitchedUserId(clickedCompId),
    })
      .then((res) => {
        setGraphData(res?.data);
        setLoad(false);
      })
      .catch((error) => {});
  }

  async function topApps() {
    setLoading(true);
    Services.getTopApps({
      companyId: getSwitchedUserId(clickedCompId),
      type: "CUSTOMER",
    })
      .then((res) => {
        setLatestApps(res?.data?.topApps);
        setLoading(false);
      })
      .catch((error) => {});
  }

  const getName = (vals) => {
    return `${vals?.companyIdObj?.name.toLowerCase()} - ${
      vals?.companyIdObj?.companyType === "CUSTOMER"
        ? "Customer"
        : vals?.companyIdObj?.companyType.toLowerCase()
    }`;
  };
  const getApps = (vals) => {
    return (
      <>
        <span>
          {vals?.name.toLowerCase()}
          {" - "}
        </span>
        <span>{getprodType(vals?.productId)}</span>
      </>
    );
  };

  const getUserParent = (user) => {
    return `${user?.companyIdObj?.parentCompanyId?.name} ${
      user?.companyIdObj?.parentCompanyId?.companyType === "CUSTOMER"
        ? "Customer"
        : user?.companyIdObj?.parentCompanyId?.companyType.toLowerCase()
    }`;
  };

  const getAppsParent = (app) => {
    return `${app?.publisherId?.name} ${
      app?.publisherId?.companyType === "CUSTOMER"
        ? "Customer"
        : app?.publisherId?.companyType.toLowerCase()
    }`;
  };
  useEffect(() => {
    graphDatas();
    //eslint-disable-next-line
  }, [selected]);

  const [dataUsage, setDataUsage] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState("30%");
  const changePagination = (event, value) => {
    setPage(value - 1);
  };
  const [loads, setLoads] = useState(false);
  async function circularGraph() {
    setLoads(true);
    Services.dataUsageGraph({
      companyId: getSwitchedUserId(clickedCompId),
      page: page,
    })
      .then((res) => {
        setDataUsage(res?.data);
        if (res?.data?.dataUsageInPercentage.length === 0) {
          setSize("30%");
        } else if (res?.data?.dataUsageInPercentage.length <= 2) {
          setSize("70%");
        } else if (res?.data?.dataUsageInPercentage.length <= 4) {
          setSize("50%");
        } else {
          setSize("30%");
        }
        setLoads(false);
      })
      .catch((error) => {});
  }
  const colorWithValue = [
    "#01537A",
    "#0094CD",
    "#373737",
    "#8FACBA",
    "#757373",
  ];
  const colorWithoutValue = [
    "#1C00ff00",
    "#1C00ff00",
    "#1C00ff00",
    "#1C00ff00",
    "#1C00ff00",
  ];
  const state = {
    series: dataUsage
      ? dataUsage?.dataUsageInPercentage.length === 0
        ? [0, 0, 0, 0, 0]
        : dataUsage?.dataUsageInPercentage
      : [],
    options: {
      chart: {
        height: 200,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          track: {
            background: "#F2F6FC",
          },
          hollow: {
            margin: 5,
            size: size,
          },
          dataLabels: {
            show: false,
          },
        },
      },
      colors:
        dataUsage && dataUsage?.dataUsageInPercentage.length === 0
          ? colorWithoutValue
          : colorWithValue,
      stroke: {
        lineCap: "round",
        show: true,
      },
    },
  };

  const goToSettings = (companyID) => {
    localStorage.getItem("role") === "ROLE_ADMIN" ||
    localStorage.getItem("role") === "ROLE_LICENSEE" ||
    localStorage.getItem("role") === "ROLE_CHANNEL"
      ? navigate("/customer/accountsettings/", {
          state: { clickedCompId: companyID },
        })
      : navigate("/accountsettings", {
          state: { clickedCompId: companyID },
        });
  };

  const userDetailPage = (userId, userRole, companyId) => {
    localStorage.setItem("parentCompId", companyId);
    userRole !== null && localStorage.setItem("selectType", userRole);
    navigate("/user-details/", { state: { clickedUserId: userId } });
  };
  const navigateReporting = () => {
    if (switchRole) {
      localStorage.setItem("customerId", getSwitchedUserId(clickedCompId));
      localStorage.setItem("switchedUser", JSON.stringify(true));
      localStorage.setItem("publisherName", companyData?.name);
    } else {
      localStorage.setItem("customerId", localStorage.getItem("companyId"));
      localStorage.setItem("switchedUser", JSON.stringify(false));
    }
    navigate("/reporting");
  };
  useEffect(() => {
    circularGraph();
    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    setIsMount(true);
    getData();
    topApps();
    setLoading(true);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMount !== undefined) {
      getData();
      topApps();
      userDispatchContextVal(false);
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [userContextVal]);

  useEffect(() => {
    if (isMount !== undefined) {
      getData();
      topApps();
      setLoading(true);
    }
    //eslint-disable-next-line
  }, [userImage]);

  function formatNumberWithSpaces(number) {
    if (!Number.isFinite(number)) {
      return number;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return (
    <Box className="Wrapper">
      <Box>
        <Container maxWidth="xxl">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="4px"
          >
            {loading ? (
              <Loader
                type={"spokes"}
                color={"#01537A"}
                height={40}
                width={40}
              />
            ) : null}
          </Box>
          <Box
            className="WrapperHeader"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="25px 0px 20px 0px"
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                style={{
                  position: "relative",
                  margin: "auto",
                  width: "70px",
                  height: "70px",
                }}
              >
                <Paper
                  style={{
                    background: "#fff",
                    width: "70px",
                    height: "70px",
                    borderRadius: "50px",
                    color: "#01537A",
                    fontSize: "54px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {allData?.companyLogo !== null &&
                  allData?.companyLogo !== undefined ? (
                    <Avatar
                      alt="Datafree"
                      src={API_URL + allData?.companyLogo}
                      rounded="lg"
                      sx={{
                        width: "70px",
                        height: "70px",
                      }}
                    />
                  ) : (
                    <img
                      alt="Datafree"
                      src={Profileavatar}
                      rounded="lg"
                      width="45px"
                      height="45px"
                    />
                  )}
                </Paper>
                <LightTooltip title="Account Settings" placement="bottom-end">
                  <IconButton
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      right: "0px",
                      background: "#01537A",
                      color: "#fff",
                      fontSize: "15px",
                      width: "20px",
                      height: "20px",
                      top: "51px",
                    }}
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() =>
                      goToSettings(getSwitchedUserId(clickedCompId))
                    }
                  >
                    <SettingsOutlinedIcon fontSize="15px" />
                  </IconButton>
                </LightTooltip>
              </Box>
              <Box
                style={{
                  background: "#fff",
                  color: "#01537A",
                  fontSize: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  style={{
                    marginTop: "-24px",
                    marginLeft: "16px",
                    color: "#5A5656",
                    fontWeight: 400,
                    textTransform: "capitalize",
                    cursor: "pointer",
                  }}
                  sx={{
                    fontSize: { xs: "10px", md: "12px" },
                  }}
                >
                  {allData && allData?.parentCompany.length >= 0 ? (
                    <BreadCrumb value={allData} other="" />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    marginLeft: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#01537A",
                      fontWeight: "700",
                      fontSize: {
                        xs: "14px",
                        sm: "22px",
                        md: "33px",
                        lg: "33px",
                      },
                      textTransform: "capitalize",
                    }}
                  >
                    {companyData?.name.toLowerCase()}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#5A5656",
                      fontWeight: "400",
                      fontSize: {
                        xs: "10px",
                        sm: "16px",
                        md: "20px",
                        lg: "20px",
                      },
                      marginTop: "-5px",
                    }}
                  >
                    Customer Dashboard
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <BootstrapTooltip title="Since last refresh" placement="top">
                <RefreshIcon
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    color: "#01537A",
                    cursor: "pointer",
                  }}
                  sx={{
                    "&:focus": {
                      animation: "spin 2s linear infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(360deg)",
                        },
                        "100%": {
                          transform: "rotate(0deg)",
                        },
                      },
                    },
                  }}
                  onClick={refreshData}
                />
              </BootstrapTooltip>
              <Typography
                sx={{
                  marginLeft: "5px",
                  color: "#01537A",
                  fontWeight: "500",
                  fontSize: { xs: "9px", md: "12px", lg: "14px" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Last refresh :{" "}
                {counter === 0
                  ? "Just now"
                  : counter > 1
                  ? counter + " minutes ago"
                  : counter + " minute ago"}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        style={{
          backgroundColor: "#01537A",
          margin: "40px 0",
        }}
        sx={{
          height: { xs: "auto", lg: "200px" },
          padding: { xs: "15px", md: "25px" },
        }}
      >
        <Box
          sx={{
            display: "grid",
            columnGap: 0,
            rowGap: { xs: "2rem", lg: "0" },
            gridTemplateColumns: { md: "auto", lg: "repeat(3, 1fr)" },
            padding: { xs: "15px", md: "10px 10px 5px 0px" },
          }}
        >
          <Box
            sx={{
              display: "grid",
              columnGap: { xs: "2rem", md: "1rem", lg: "2rem", xl: "3rem" },
              rowGap: { xs: "2rem", lg: "1rem" },
              gridTemplateColumns: {
                xs: "auto",
                sm: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              },
              gridTemplateRows: "repeat(1, 120px)",
            }}
          >
            <Card
              sx={{ minWidth: { xs: 50, md: 132 } }}
              style={{
                backgroundColor: "#E6EEF2",
                overflow: "inherit",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <Fab
                onClick={() => !accessPermissionFields() && addApplication()}
                size="small"
                color="primary"
                aria-label="add"
                style={{
                  background: "#fff",
                  borderRadius: "50px",
                  position: "absolute",
                  top: "-20px",
                  right: "-20px",
                  color: "#01537A",
                  fontSize: "34px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "35px",
                  height: "32px",
                  zIndex: 2,
                }}
                sx={{
                  cursor: !accessPermissionFields() ? "pointer" : "default",
                }}
              >
                <img src={Add} alt="" height="28px"></img>
              </Fab>

              <CardContent
                onClick={() => redirectPages(3)}
                align="center"
                style={{ padding: "10px" }}
              >
                <Typography
                  sx={{ fontSize: 52 }}
                  color="#01537A"
                  lineHeight="55px"
                >
                  {allData ? allData?.applicationCount : 0}
                </Typography>
                <Typography style={{ color: "#01537A", fontSize: "20px" }}>
                  Applications
                </Typography>
                <Box
                  style={{
                    color: "#01537A",
                    fontSize: 13,
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <BootstrapTooltip
                    title="In the last 7 days"
                    placement="right"
                  >
                    <span style={{ display: "flex" }}>
                      {getChanges(allData?.appsChanges7days)}
                    </span>
                  </BootstrapTooltip>
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{ minWidth: { xs: 50, md: 132 } }}
              style={{
                backgroundColor: "#E6EEF2",
                overflow: "inherit",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <Fab
                onClick={() => !accessPermissionFields() && addUser()}
                size="small"
                color="primary"
                aria-label="add"
                style={{
                  color: "#01537A",
                  borderRadius: "50px",
                  position: "absolute",
                  top: "-20px",
                  right: "-20px",
                  background: "#fff",
                  fontSize: "34px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "35px",
                  height: "32px",
                  zIndex: 2,
                }}
                sx={{
                  cursor: !accessPermissionFields() ? "pointer" : "default",
                }}
              >
                <img src={Add} alt="" height="28px"></img>
              </Fab>
              <CardContent
                onClick={() => redirectPages(2)}
                align="center"
                style={{ padding: "10px" }}
              >
                <Typography
                  sx={{ fontSize: 52 }}
                  color="#01537A"
                  lineHeight="55px"
                >
                  {allData ? allData?.userCount : 0}
                </Typography>
                <Typography style={{ color: "#01537A", fontSize: "20px" }}>
                  Users
                </Typography>
                <Box
                  style={{
                    color: "#01537A",
                    fontSize: 13,
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <BootstrapTooltip
                    title="In the last 7 days"
                    placement="right"
                  >
                    <span style={{ display: "flex" }}>
                      {getChanges(allData?.usersChange7days)}
                    </span>
                  </BootstrapTooltip>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Box
            sx={{
              width: {
                xs: matches ? "320px" : "420px",
                sm: "560px",
                md: "530px",
                lg: "560px",
                xl: "570px",
              },
              height: { xs: "auto", sm: "320px" },
              backgroundColor: "#01537A",
              marginTop: { xs: "-20px", lg: "-95px" },
              borderRadius: "15px",
              padding: { xs: "5px", sm: "20px 37px 20px 37px" },
              display: "grid",
              columnGap: { xs: "0rem", md: "0rem" },
              rowGap: { xs: "1rem", lg: "1rem" },
              gridTemplateColumns: {
                xs: "auto",
                lg: "repeat(1, 1fr)",
              },
              alignItems: "center",
              margin: { xs: "auto", lg: "none" },
            }}
          >
            <Card
              sx={{
                minWidth: "100%",
                gridColumn: { xs: "auto", lg: "auto" },
                borderRadius: "8px",
              }}
              style={{
                backgroundColor: "#FFFFFF",
                overflow: "inherit",
                position: "relative",
              }}
            >
              <CardContent
                align="center"
                style={{
                  padding: "0px",
                  minHeight: "240px",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    color: "#373737",
                    fontWeight: 500,
                    fontSize: { xs: "14px", sm: "18px" },
                    textAlign: "left",
                    marginBottom: "-5px",
                    paddingLeft: "25px",
                    paddingTop: "12px",
                  }}
                >
                  Application Data Usage
                </Typography>

                <Box
                  style={{ color: "#01537A", fontSize: 11 }}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px 12px 12px 25px",
                      minWidth: "280px",
                    }}
                  >
                    {dataUsage &&
                    dataUsage?.eachAppDataUsageDetails.length > 0 &&
                    !loads ? (
                      dataUsage?.eachAppDataUsageDetails.map((datas, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            paddingTop: "18px",
                          }}
                          key={index}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              maxWidth: "145px",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: colorWithValue[index],
                                borderRadius: "50%",
                                marginTop: "3px",
                              }}
                            ></Box>
                            <Typography
                              component="span"
                              sx={{
                                marginLeft: "8px",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "#373737",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textAlign: "left",
                                width: "90px",
                                textTransform: "capitalize",
                              }}
                            >
                              {datas?.application?.name.toLowerCase()}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "#757373",
                              justifyItems: "left",
                            }}
                          >
                            {formatNumberWithSpaces(datas?.totalDataMB) + " MB"}
                          </Typography>
                        </Box>
                      ))
                    ) : dataUsage &&
                      dataUsage?.eachAppDataUsageDetails.length === 0 &&
                      !loads ? (
                      <Box
                        sx={{
                          display: "flex",
                          paddingTop: "55px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            maxWidth: "199px",
                            width: "100%",
                            color: "#000000",
                            fontSize: "18px",
                            textAlign: "left",
                            fontWeight: "400",
                          }}
                        >
                          You have no production apps, or your production apps
                          have logged no data
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>

                  {!loads ? (
                    <ReactApexChart
                      options={state.options}
                      series={state.series}
                      type="radialBar"
                      height={210}
                      style={{ marginTop: "5px", paddingRight: "20px" }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                {loads ? (
                  <Box sx={{ justifyContent: "center", display: "flex" }}>
                    <Loader
                      type={"spokes"}
                      color={"#01537A"}
                      delay={0}
                      height={25}
                      width={25}
                    />
                  </Box>
                ) : (
                  ""
                )}
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              >
                {dataUsage?.appsCount && dataUsage?.appsCount > 0 ? (
                  <PaginationRounded
                    page={page + 1}
                    Count={dataUsage?.appsCount}
                    size={5}
                    changeHandle={changePagination}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Card>
          </Box>
          <Box
            sx={{
              display: "grid",
              columnGap: { xs: "2rem", md: "1rem", lg: "2rem", xl: "3rem" },
              rowGap: { xs: "2rem", lg: "1rem" },
              gridTemplateColumns: {
                xs: "auto",
                sm: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              },
              gridTemplateRows: "repeat(1, 120px)",
            }}
          >
            <Card
              sx={{
                minWidth: { xs: 50, md: 132 },
                gridRow: "1",
                gridColumn: { xs: "auto", lg: "2/3" },
              }}
              style={{
                backgroundColor: "#E6EEF2",
                overflow: "inherit",
                position: "relative",
              }}
            >
              <Fab
                size="small"
                color="secondary"
                aria-label="add"
                style={{
                  background:
                    allData?.alertSentTodayApps > 0 ? "#FF5252" : "#757373",
                  borderRadius: "50px",
                  position: "absolute",
                  top: "-20px",
                  right: "-20px",
                  color: "#fff",
                  fontSize: "34px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 2,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    style={{
                      lineHeight: "10px",
                      fontWeight: "400",
                      color: "#fff",
                      fontSize: "14px",
                    }}
                  >
                    {allData?.alertSentTodayApps
                      ? allData?.alertSentTodayApps
                      : 0}
                  </Typography>
                  <Typography
                    style={{
                      lineHeight: "10px",
                      fontWeight: "300",
                      color: "#fff",
                      fontSize: "11px",
                      textTransform: "lowercase",
                    }}
                  >
                    new
                  </Typography>
                </Box>
              </Fab>

              <CardContent align="center" style={{ padding: "10px" }}>
                <Typography
                  sx={{ fontSize: 52 }}
                  color="#01537A"
                  lineHeight="55px"
                >
                  {allData?.appsExceedDataLimit
                    ? allData?.appsExceedDataLimit
                    : 0}
                </Typography>
                <Typography style={{ color: "#01537A", fontSize: "20px" }}>
                  Apps
                </Typography>
                <Box
                  style={{
                    color: "#01537A",
                    fontSize: 11,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <KeyboardArrowRightIcon
                    style={{ color: "#01537A", fontSize: 14 }}
                  />
                  80% Monthly Limits
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{
                minWidth: { xs: 50, md: 132 },
                gridRow: "1",
                gridColumn: { xs: "auto", lg: "3/3" },
              }}
              style={{
                backgroundColor: "#E6EEF2",
                overflow: "inherit",
                position: "relative",
              }}
            >
              <Fab
                size="small"
                color="secondary"
                aria-label="add"
                style={{
                  color: "#fff",
                  borderRadius: "50px",
                  position: "absolute",
                  top: "-20px",
                  right: "-20px",
                  background:
                    allData?.alertSentAppsCountResult > 0
                      ? "#FB8C00"
                      : "#757373",
                  fontSize: "34px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 2,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    style={{
                      lineHeight: "10px",
                      fontWeight: "400",
                      color: "#fff",
                      fontSize: "14px",
                    }}
                  >
                    {allData?.alertSentAppsCountResult
                      ? allData?.alertSentAppsCountResult
                      : 0}
                  </Typography>
                  <Typography
                    style={{
                      lineHeight: "10px",
                      fontWeight: "300",
                      color: "#fff",
                      fontSize: "11px",
                      textTransform: "lowercase",
                    }}
                  >
                    new
                  </Typography>
                </Box>
              </Fab>
              <CardContent align="center" style={{ padding: "10px" }}>
                <Typography
                  sx={{ fontSize: 52 }}
                  color="#01537A"
                  lineHeight="55px"
                >
                  {allData?.appsExceedDailyLimit
                    ? allData?.appsExceedDailyLimit
                    : 0}
                </Typography>
                <Typography style={{ color: "#01537A", fontSize: "20px" }}>
                  Apps
                </Typography>
                <Box
                  style={{
                    color: "#01537A",
                    fontSize: 13,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <KeyboardArrowRightIcon
                    style={{ color: "#01537A", fontSize: 14 }}
                  />
                  80% Daily Limit
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
      <Box>
        <Container maxWidth="xxl">
          <Box
            sx={{
              display: "grid",
              columnGap: { md: 3, lg: 10 },
              rowGap: 2,
              gridTemplateColumns: { xs: "auto", md: "repeat(3, 1fr)" },
              marginTop: "110px",
              minHeight: "700px",
            }}
          >
            <Card
              style={{
                backgroundColor: "#fff",
                overflow: "inherit",
                position: "relative",
              }}
            >
              <CardContent>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paper
                      style={{
                        background: "#01537A",
                        width: "42px",
                        height: "42px",
                        borderRadius: "50px",
                        color: "#fff",
                        fontSize: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "3px solid #fff",
                      }}
                    >
                      <LayersIcon fontSize="20px" />
                    </Paper>
                    <Typography
                      style={{
                        marginLeft: "15px",
                        fontWeight: "700",
                      }}
                      sx={{
                        fontSize: {
                          xs: "18px",
                          sm: "20px",
                          md: "16px",
                          lg: "20px",
                        },
                      }}
                    >
                      Top 5 Apps
                    </Typography>
                  </Box>
                  <Box>
                    <Link
                      style={{
                        marginLeft: "15px",
                        fontWeight: "600",
                        color: "#0094CD",
                        cursor: "pointer",
                      }}
                      sx={{
                        fontSize: { xs: "16px", md: "12px", lg: "16px" },
                      }}
                      onClick={() => redirectViewall(3)}
                    >
                      View All
                    </Link>
                  </Box>
                </Box>
                <Box>
                  {latestApps && latestApps.length > 0 ? (
                    latestApps.map((app, index) => (
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          bgcolor: "#F2F2F2",
                          borderRadius: "12px",
                          marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        key={index}
                        onMouseEnter={() => setIsAppHovered(index)}
                        onMouseLeave={() => setIsAppHovered(false)}
                        onClick={() =>
                          app?.application?.applicationId &&
                          applicationDetailPage(app?.application)
                        }
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <Paper
                              style={{
                                background: "#fff",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50px",
                                color: "#01537A",
                                fontSize: "34px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: switchApp(app?.application?.productType)
                                  ? "pointer"
                                  : "default",
                              }}
                            >
                              <Avatar
                                alt="Datafree"
                                src={getAppIcon(
                                  app?.icon,
                                  app?.application?.productType
                                )}
                                rounded="lg"
                                width="52px"
                                height="52px"
                              />
                            </Paper>
                          </ListItemAvatar>
                          <Box
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "0px",
                            }}
                          >
                            <Typography
                              style={{
                                color: "#23262F",
                                fontSize: "14px",
                                fontWeight: "600",
                                ...(switchApp(
                                  app?.apps?.productId?.productType
                                ) && {
                                  color:
                                    isAppHovered === index
                                      ? "#0094CD"
                                      : "#23262F",
                                  textDecoration:
                                    isAppHovered === index ? "underline" : "",
                                }),
                              }}
                            >
                              {app?.application?.name.toLowerCase() + " - "}{" "}
                              {getprodType(app?.application)}
                            </Typography>
                            <Typography
                              style={{
                                color: "#23262F",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {`${app?.application?.publisherName.toLowerCase()} ${
                                app?.application?.publisherType === "CUSTOMER"
                                  ? "Customer"
                                  : app?.apps?.publisherType.toLowerCase()
                              }`}
                            </Typography>
                            <Typography
                              style={{
                                color: "#B1AEAE",
                                fontSize: 12,
                                fontWeight: "400",
                              }}
                            >
                              {`${app.totalDataGB} GB`}
                            </Typography>
                          </Box>
                        </ListItem>
                      </List>
                    ))
                  ) : (
                    <Box sx={{ color: "#000", padding: "7px" }}>
                      You have no production apps, or your production apps have
                      logged no data
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
            <Card
              style={{
                backgroundColor: "#fff",
                overflow: "inherit",
                position: "relative",
              }}
            >
              <CardContent>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paper
                      style={{
                        background: "#01537A",
                        width: "42px",
                        height: "42px",
                        borderRadius: "50px",
                        color: "#fff",
                        fontSize: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "3px solid #fff",
                      }}
                    >
                      <LayersIcon fontSize="20px" />
                    </Paper>
                    <Typography
                      style={{
                        marginLeft: "15px",
                        fontWeight: "700",
                      }}
                      sx={{
                        fontSize: {
                          xs: "18px",
                          sm: "20px",
                          md: "14px",
                          lg: "20px",
                        },
                      }}
                    >
                      Latest Changes
                    </Typography>
                  </Box>
                  <Box>
                    <Link
                      style={{
                        marginLeft: "15px",
                        fontWeight: "600",
                        color: "#0094CD",
                        cursor: "pointer",
                      }}
                      sx={{
                        fontSize: { xs: "16px", md: "12px", lg: "16px" },
                      }}
                      onClick={() => goToChangeLog(1)}
                    >
                      View All
                    </Link>
                  </Box>
                </Box>
                <Box>
                  {latestPublisher && latestPublisher.length > 0 ? (
                    latestPublisher.map((pub, index) => (
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          bgcolor: "#F2F2F2",
                          borderRadius: "12px",
                          marginBottom: "15px",
                        }}
                        key={index}
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <Paper
                              style={{
                                background: "#fff",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50px",
                                color: "#01537A",
                                fontSize: "34px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {pub?.apps ? (
                                <Avatar
                                  alt="Datafree"
                                  src={getAppIcon(
                                    pub?.apps?.icon,
                                    pub?.apps?.productId?.productType
                                  )}
                                  rounded="lg"
                                  width="52"
                                  height="52"
                                />
                              ) : (
                                <Avatar
                                  alt="Datafree"
                                  src={
                                    pub?.users?.icon !== undefined
                                      ? API_URL + pub?.users?.icon
                                      : AvatarLogo
                                  }
                                  rounded="lg"
                                  width="52"
                                  height="52"
                                />
                              )}
                            </Paper>
                          </ListItemAvatar>
                          <Box
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "0px",
                            }}
                          >
                            <Typography
                              style={{
                                color: "#23262F",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              {pub?.users
                                ? getName(pub?.users)
                                : getApps(pub?.apps)}
                            </Typography>
                            <Typography
                              style={{
                                color: "#23262F",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {pub?.users
                                ? getUserParent(pub?.users)
                                : getAppsParent(pub?.apps)}
                            </Typography>
                            <Typography
                              style={{
                                color: "#B1AEAE",
                                fontSize: 12,
                                fontWeight: "400",
                              }}
                            >
                              {`${moment(pub.updatedDate).format(
                                "DD MMM YYYY, HH:mm,"
                              )} by ${
                                pub?.editorBy !== null
                                  ? pub?.editorBy
                                  : "Not Captured"
                              }`}
                            </Typography>
                          </Box>
                        </ListItem>
                      </List>
                    ))
                  ) : (
                    <Box sx={{ color: "#000", padding: "7px" }}>
                      No changes to see here.
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
            <Card
              style={{
                backgroundColor: "#fff",
                overflow: "inherit",
                position: "relative",
              }}
            >
              <CardContent>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paper
                      style={{
                        background: "#01537A",
                        width: "42px",
                        height: "42px",
                        borderRadius: "50px",
                        color: "#fff",
                        fontSize: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "3px solid #fff",
                      }}
                    >
                      <PeopleAltIcon fontSize="20px" />
                    </Paper>
                    <Typography
                      style={{
                        marginLeft: "15px",
                        fontWeight: "700",
                      }}
                      sx={{
                        fontSize: {
                          xs: "18px",
                          sm: "20px",
                          md: "14px",
                          lg: "20px",
                        },
                      }}
                    >
                      Recently Active
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      marginLeft: "15px",
                      fontWeight: "600",
                      color: "#0094CD",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    sx={{
                      fontSize: { xs: "16px", md: "12px", lg: "16px" },
                    }}
                    onClick={() => redirectViewall(2)}
                  >
                    View All
                  </Box>
                </Box>

                <Box>
                  {activeUsers && activeUsers.length > 0 ? (
                    activeUsers.map((user, index) => (
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          bgcolor: "#F2F2F2",
                          borderRadius: "12px",
                          marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        key={index}
                        onMouseEnter={() => setIsActiveHovered(index)}
                        onMouseLeave={() => setIsActiveHovered(false)}
                        onClick={() =>
                          user?.user.userId &&
                          userDetailPage(
                            user?.user.userId,
                            user?.user?.userRole,
                            user?.user?.companyId
                          )
                        }
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <Paper
                              style={{
                                background: "#fff",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50px",
                                color: "#01537A",
                                fontSize: "34px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <Avatar
                                alt=""
                                src={
                                  user?.user.userId ===
                                    localStorage.getItem("userid") &&
                                  userImage !== ""
                                    ? userImage
                                    : user?.user?.userImage !== null
                                    ? API_URL + user?.user?.userImage
                                    : avatar
                                }
                              />
                            </Paper>
                          </ListItemAvatar>
                          <Box
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "0px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color:
                                  isActiveHovered === index
                                    ? "#0094CD"
                                    : "#23262F",
                                textDecoration:
                                  isActiveHovered === index ? "underline" : "",
                              }}
                            >
                              {user?.user.name}
                            </Typography>
                            <Typography
                              style={{
                                color: "#23262F",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {user?.user.companyName}
                            </Typography>
                            <Typography
                              style={{
                                color: "#B1AEAE",
                                fontSize: 12,
                                fontWeight: "400",
                              }}
                            >
                              {moment(user.login_time).format(
                                "DD MMM YYYY, HH:mm"
                              )}
                            </Typography>
                          </Box>
                        </ListItem>
                      </List>
                    ))
                  ) : (
                    <Box sx={{ color: "#000", padding: "7px" }}>
                      No active users yet.
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
      <Box
        style={{
          background: "rgba(1, 83, 122, 0.1)",
          padding: "70px 0px",
          margin: "40px 0",
        }}
      >
        <Container maxWidth="xxl">
          <Card
            sx={{ minWidth: { xs: 50, md: 140 } }}
            style={{
              backgroundColor: "#fff",
              overflow: "inherit",
              position: "relative",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: { xs: "flex-start", md: "center", lg: "center" },
                  mb: { xs: "15px", md: "0", lg: "15px" },
                  flexDirection: { xs: "column", md: "row", lg: "row" },
                }}
              >
                <Box>
                  <Typography
                    style={{
                      fontSize: "20px",
                      textTransform: "uppercase",
                      fontWeight: "500",
                    }}
                  >
                    All Data usage over time
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: { xs: "15px", md: "0", lg: "0" },
                  }}
                >
                  <FormControl
                    sx={{
                      mr: 1,
                      maxWidth: 160,
                      mt: { xs: "10px", md: "0", lg: "0" },
                    }}
                    size="small"
                    style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#01537A",
                      borderColor: "#01537A",
                    }}
                  >
                    <Select
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#01537A",
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          htmlColor="#01537A"
                          sx={{
                            position: "absolute",
                            right: "5px",
                            cursor: "pointer",
                            color: "#1537A",
                          }}
                          onClick={handleOpen}
                        />
                      )}
                      open={opens}
                      onOpen={handleOpen}
                      onClose={handleCloses}
                      MenuProps={MenuProps}
                      defaultValue={selected}
                      onChange={handleGraph}
                      input={
                        <OutlinedInput
                          sx={{
                            borderColor: "#01537A !important",
                            color: "#01537A",
                            "& fieldset": { borderColor: "#01537A !important" },
                          }}
                        />
                      }
                    >
                      <MenuItem value="7">Last 7 Days</MenuItem>
                      <MenuItem value="14">Last 14 Days</MenuItem>
                      <MenuItem value="30">Last 30 Days</MenuItem>
                      <MenuItem value="60">Last 60 Days</MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    onClick={navigateReporting}
                    variant="contained"
                    style={{
                      background: "#01537A",
                      fontWeight: "400",
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                    sx={{
                      mt: { xs: "10px", md: "0", lg: "0" },
                    }}
                  >
                    Go to Reporting
                  </Button>
                </Box>
              </Box>
              <Graph
                resData={graphData}
                selected={selected}
                loading={load}
                yAxis={"Data usage (MB)"}
                xAxis={"Day of month"}
                type={"dashboard"}
              />
            </CardContent>
          </Card>
        </Container>
      </Box>
      <SnackbarWrapper
        open={open}
        onClose={handleClose}
        message={notifyMessage}
      />
    </Box>
  );
}
