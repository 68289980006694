import * as React from "react";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box, Typography } from "@mui/material";
import sortIcon from "../../../../assets/img/sortIcon.png";

export default function Websites({ applicationDetails }) {
  const applicationsGrid =
    applicationDetails?.data?.securityRules?.includedApps;

  const webApps = applicationsGrid.filter((app) => app.type === "web");
  const API_URL = process.env.REACT_APP_API_URL + "/image/s3/icon_images/";
  //const API_URL = "https://portalrefresh-api.xminds.com/image/s3/icon_images/";

  const rows = webApps.map((item, index) => ({
    id: index + 1,
    iconUrl: item.iconUrl,
    name: item.label,
    contentUrl: item.contentUrl || "-",
  }));

  const columns = [
    {
      field: "iconUrl",
      headerName: "Website Logo",
      width: 170,
      headerAlign: "center",
      align: "center",
      headerClassName: "data-grid-header",
      sortable: true,
      disableColumnMenu: true,
      resizable: false,
      renderCell: (params) => (
        <Typography>-</Typography>
        // <Box display="flex" alignItems="center">
        //   {params.row.iconUrl ? (
        //     <img
        //       src={`${API_URL}${params.row.iconUrl}`}
        //       style={{ width: "40px", height: "40px", borderRadius: "50%" }} // Adjust size and shape here
        //     />
        //   ) : (
        //     <Typography>-</Typography>
        //   )}
        // </Box>
      ),
    },
    {
      field: "name",
      headerName: "Website Name",
      width: 270,
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography>{params.row.name ? params.row.name : "-"}</Typography>
      ),
      headerClassName: "data-grid-header",
    },
    {
      field: "contentUrl",
      headerName: "Website URL",
      width: 700,
      headerAlign: "center",
      align: "center",
      sortable: true,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography>
          {params.row.contentUrl ? params.row.contentUrl : "-"}
        </Typography>
      ),
      headerClassName: "data-grid-header",
    },
  ];
  function CustomUnsortedIcon() {
    return <img src={sortIcon} alt="" />;
  }
  function CustomPagination(props) {
    return (
      <GridPagination
        {...props}
        showFirstButton
        showLastButton
        sx={{
          "& .MuiPaginationItem-root": {
            color: "#01537A",
          },
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        marginBottom: "40px",
        marginTop: "10px",
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowHeight={70}
        rowsPerPageOptions={[5, 10, 25, 50]}
        disableSelectionOnClick
        disableExtendRowFullWidth={true}
        disableColumnFilter
        disableColumnSelector
        disableColumnReorder
        components={{
          ColumnUnsortedIcon: CustomUnsortedIcon,
          ColumnSortedAscendingIcon: CustomUnsortedIcon,
          ColumnSortedDescendingIcon: CustomUnsortedIcon,
          Pagination: CustomPagination,
        }}
        sx={{
          "& .data-grid-header": {
            backgroundColor: "#01537A",
            color: "#FFFFFF",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#01537A",
          },

          "& .MuiDataGrid-columnHeader--sorted": {
            color: "black",
          },

          bgcolor: "#fff",
          borderRadius: "1px",
          padding: "0px",
          overflow: "none",
          border: "1px solid transparent",
          borderBottom: "1.14491px solid rgba(0, 0, 0, 0.12)",
          "& .Mui-focused ": {
            borderRadius: "0",
          },

          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-row": {
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& .MuiDataGrid-cell": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 500,
            color: "#fff",
            fontSize: "15px",
            zIndex: 9999,
          },
          "& .MuiSvgIcon-root": {
            color: "#F2F2F2",
          },
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
          },
        }}
      />
    </Box>
  );
}
