import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import ColumnGroupDataTable from "./columnGroupDataTable";
import Loader from "../../components/loader";
import moment from "moment";
import { CSVLink } from "react-csv";
import useToast from "../../hooks/useToast";
import { formatNumberWithSpaces } from "../../utils/formatNumber";

const columnGroupingModel = [
  {
    groupId: "month",
    headerName: "Month",
    description: "",

    children: [{ field: "month" }],
  },
  {
    groupId: "upload",
    description: "",
    headerName: "Upload(MB)",

    children: [
      { field: "datafreeUpload" },
      { field: "paidUpload" },
      { field: "wifiUpload" },
      { field: "otherUpload" },
    ],
  },
  {
    groupId: "download",
    description: "",
    headerName: "Download(MB)",

    children: [
      { field: "datafreeDownload" },
      { field: "paidDownload" },
      { field: "wifiDownload" },
      { field: "otherDownload" },
    ],
  },
  {
    groupId: "total",
    description: "",
    headerName: "Total(MB)",

    children: [{ field: "totalUsage" }],
  },
];
export default function MonthlyTableView({
  data,
  loading,
  resData,
  csvDatas,
  companyName,
  role,
}) {
  const { showSuccess, showWarning } = useToast();
  const columns = [
    {
      flex: 1,
      field: "month",
      headerName: "",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.month,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.month
                ? moment(params?.row?.month).format("MMM YYYY")
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "datafreeUpload",
      headerName: "Datafree",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.uploaddatafreeMB,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.uploaddatafreeMB
                ? formatNumberWithSpaces(params.row.uploaddatafreeMB)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "paidUpload",
      headerName: "Paid",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.uploadPaidMB,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.uploadPaidMB
                ? formatNumberWithSpaces(params?.row?.uploadPaidMB)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "wifiUpload",
      headerName: "Wifi",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.uploadWiFiMB,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.uploadWiFiMB
                ? formatNumberWithSpaces(params?.row?.uploadWiFiMB)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "otherUpload",
      headerName: "Other",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.uploadUnclassifiedMB,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.uploadUnclassifiedMB
                ? formatNumberWithSpaces(params?.row?.uploadUnclassifiedMB)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "datafreeDownload",
      headerName: "Datafree",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.downloaddatafreeMB,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.downloaddatafreeMB
                ? formatNumberWithSpaces(params?.row?.downloaddatafreeMB)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "paidDownload",
      headerName: "Paid",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.downloadPaidMB,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.downloadPaidMB
                ? formatNumberWithSpaces(params?.row?.downloadPaidMB)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "wifiDownload",
      headerName: "Wifi",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.downloadWiFiMB,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.downloadWiFiMB
                ? formatNumberWithSpaces(params?.row?.downloadWiFiMB)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "otherDownload",
      headerName: "Other",

      width: 120,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.downloadUnclassifiedMB,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.downloadUnclassifiedMB
                ? formatNumberWithSpaces(params?.row?.downloadUnclassifiedMB)
                : 0}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "totalUsage",
      headerName: "Total",

      width: 170,
      sortable: false,
      valueGetter: (value, row) => ({
        Id: row?.totalDataMB,
      }),
      renderCell: (params) => {
        return (
          <div>
            <span>
              {" "}
              {params?.row?.totalDataMB
                ? formatNumberWithSpaces(params?.row?.totalDataMB)
                : 0}
            </span>
          </div>
        );
      },
    },
  ];
  const csvDownloadButton = () => {
    showWarning("No report to export");
  };
  const csvDownload = () => {
    showSuccess("CSV file successfully exported.");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 400,
        width: { xs: "64%", md: "70%", lg: "72%", xl: "81%" },
      }}
    >
      {resData && !loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "20px",
                }}
              >
                Summary Monthly Report for all applications in
                <span style={{ color: "#0094CD", marginLeft: "5px" }}>
                  {role === "ROLE_USER"
                    ? companyName
                      ? companyName
                      : ""
                    : data?.publisher?.label
                    ? data?.publisher?.label
                    : localStorage.getItem("switchRole") === "customer"
                    ? localStorage.getItem("publisherName")
                    : ""}
                </span>
              </Typography>
            </Box>
            {csvDatas?.length === 0 ? (
              <Button
                onClick={csvDownloadButton}
                variant="contained"
                sx={{
                  boxShadow: "0px 1px 0px 0px #01537A",
                  textTransform: "none",
                  padding: "10px 24px ",
                  color: "white",
                  background: "#01537A!important",
                  borderRadius: "5px",
                  fontWeight: 400,
                  fontSize: "15px",
                  width: "140.22px",
                  height: "38.33px",
                  letterSpacing: "0.0em",
                }}
              >
                Export to CSV{" "}
              </Button>
            ) : (
              <CSVLink
                onClick={csvDownload}
                style={{
                  textTransform: "none",
                  padding: "10px 24px",
                  color: "white",
                  background: "#01537A",
                  boxShadow: "0px 1px 0px 0px #01537A",
                  cursor: "pointer",
                  border: "1px",
                  borderRadius: "5px",
                  textDecoration: "none",
                  fontSize: "15px",
                }}
                data={csvDatas}
                filename="data.csv"
              >
                {" "}
                Export to CSV
              </CSVLink>
            )}
          </Box>
          <Divider
            sx={{
              color: "#B1AEAE",
              backgroundColor: "#B1AEAE",
              width: "100%",
              borderBottomWidth: "1px",
              marginBottom: "20px",
            }}
          ></Divider>

          <ColumnGroupDataTable
            rows={resData}
            columns={columns}
            columnGroupingModel={columnGroupingModel}
            hideFooter={true}
            autoHeight
          />
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader
            type={"spokes"}
            color={"#01537A"}
            height={40}
            width={40}
          />
        </Box>
      )}
    </Box>
  );
}
