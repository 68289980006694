import React from 'react'
import Layout from "../../components/layout";
import {Box, TextField,Typography} from "@mui/material";
import { CookiesProvider } from "react-cookie";


export default function ServiceDetails() {
  return (
    <CookiesProvider>
        <Layout>
      <Box>
      
      <TextField
                  required
                  inputProps={{
                    style: {
                      fontSize: "40px",
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "#757373",
                      width:"690px",
                    },
                  }}
                 defaultValue="WGrom Reach"
                  name="companyName"
                  variant="standard"
                 
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "0.4px",
                    color: "#01537A",
                  }}
                >
                  Company Name
                </Typography>
      </Box>
        </Layout>
        </CookiesProvider>
  )
}
