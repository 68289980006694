import React from "react";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Loader from "../../components/loader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export default function UpdateScheduleReport({
  editPanelOpen,
  handleEditPanelClose,
  loading,
  handleChange,
  userError,
  data,
  saveClick,
  handleSave,
  renderValueOfDays,
  days,
  handleCancel,
}) {
  return (
    <Dialog maxWidth="xxl" open={editPanelOpen}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            paddingTop: "30px",
            paddingLeft: "23px",
            paddingBottom: "20px",
            fontSize: "24px",
            color: "rgb(1, 83, 122)",
          }}
        >
          Update Schedule Report
        </Typography>
        <CloseSharpIcon
          sx={{
            cursor: "pointer",
            color: "#01537A",
          }}
          onClick={handleCancel}
        />
      </DialogTitle>

      {!loading ? (
        <>
          <DialogContent
            sx={{
              padding: "0px 50px 70px 50px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "30px",
              }}
            >
              <FormControl sx={{ width: "509px" }} variant="standard">
                <TextField
                  sx={{
                    width: "100%",
                    "& input::placeholder": {
                      color: "black",
                      fontSize: "16px",
                      opacity: "1",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                  name="distributionList"
                  error={!!userError?.distributionList}
                  value={data.distributionList}
                  placeholder="Distribution List (Comma delimited email list)"
                  variant="standard"
                  helperText={
                    userError?.distributionList
                      ? userError?.distributionListMsg
                      : ""
                  }
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    color: "#01537A",
                  }}
                >
                  Distribution Email List
                </Typography>
              </FormControl>
              <FormControl sx={{ width: "509px" }} variant="standard">
                <Select
                  sx={{
                    ".MuiSvgIcon-root ": {
                      fill: "black !important",
                    },
                  }}
                  displayEmpty
                  renderValue={(selected) =>
                    renderValueOfDays(
                      selected,
                      "Day of the week (That the report will be return)"
                    )
                  }
                  name="dayOfWeek"
                  value={data?.dayOfWeek || ""}
                  error={userError?.dayOfWeek}
                  onChange={handleChange}
                >
                  {days &&
                    days.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    color: "#01537A",
                  }}
                >
                  Day of the week
                </Typography>
                <FormHelperText
                  sx={{
                    color: "#d32f2f",
                  }}
                >
                  {userError?.dayOfWeek ? userError?.dayOfWeekMsg : ""}
                </FormHelperText>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                paddingTop: "55px",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  width: "200px",
                  height: "45px",
                  padding: "3px 31px",
                  textTransform: "none",
                  border: "1px solid #01537A !important",
                  color: "#01537A!important",
                }}
                variant="outlined"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: "200px",
                  height: "45px",
                  padding: "3px 43px",
                  textTransform: "none",
                  background: "#01537A!important",
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "unset !important",
                  },
                }}
                variant="contained"
                onClick={handleSave}
                disabled={saveClick}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Save</span>
                  {saveClick ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"white"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </Box>
          </DialogContent>
        </>
      ) : (
        <DialogContent
          sx={{
            padding: "0px 50px 70px 50px",
            display: "flex",
            justifyContent: "center",
            height: "153px",
            width: { sm: "300px", md: "1164px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Loader
              type={"spokes"}
              color={"#01537A"}
              height={40}
              width={40}
            />
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
}
