import React, { createContext, useState, useEffect } from "react";

const userContext = createContext("");
const userDispatchContext = createContext("");
const statusContext = createContext("");
const statusDispatchContext = createContext("");
const switchStatusContext = createContext("");
const archivedStatusDispatchContext = createContext("");
const archivedStatusContext = createContext("");
const switchStatusDispatchContext = createContext("");
const userRoleContext = createContext("");
const userRoleDispatchContext = createContext();
const companyContext = createContext();
const companyDispatchContext = createContext();
function ContextProvider({ children }) {
  const [imageCheck, setImageCheck] = useState(false);
  const [statusCheck, setStatusCheck] = useState(false);
  const [switchStatusCheck, setSwitchStatusCheck] = useState(false);
  const [archivedStatusCheck, setArchivedStatusCheck] = useState(false);
  const [roleCheck, setRoleCheck] = useState();
  const [companyType, setCompanyType] = useState();
  const getStatus = () => {
    setStatusCheck(localStorage?.getItem("status"));
    setSwitchStatusCheck(localStorage?.getItem("switchStatus"));
    setArchivedStatusCheck(localStorage?.getItem("archivedStatus"));
    setRoleCheck(localStorage.getItem("userRole"));
    setCompanyType(localStorage.getItem("companyType"));
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <statusContext.Provider value={statusCheck}>
      <statusDispatchContext.Provider value={setStatusCheck}>
        <switchStatusContext.Provider value={switchStatusCheck}>
          <switchStatusDispatchContext.Provider value={setSwitchStatusCheck}>
            <archivedStatusContext.Provider value={archivedStatusCheck}>
              <archivedStatusDispatchContext.Provider
                value={setArchivedStatusCheck}
              >
                <userContext.Provider value={imageCheck}>
                  <userDispatchContext.Provider value={setImageCheck}>
                    <userRoleContext.Provider value ={roleCheck}>
                      <userRoleDispatchContext.Provider value={setRoleCheck}>
                        <companyContext.Provider value={companyType}>
                          <companyDispatchContext.Provider value={setCompanyType}>
                    {children}
                    </companyDispatchContext.Provider>
                    </companyContext.Provider>
                    </userRoleDispatchContext.Provider>
                    </userRoleContext.Provider>
                  </userDispatchContext.Provider>
                </userContext.Provider>
              </archivedStatusDispatchContext.Provider>
            </archivedStatusContext.Provider>
          </switchStatusDispatchContext.Provider>
        </switchStatusContext.Provider>
      </statusDispatchContext.Provider>
    </statusContext.Provider>
  );
}

export {
  userDispatchContext,
  userContext,
  statusContext,
  statusDispatchContext,
  switchStatusContext,
  switchStatusDispatchContext,
  archivedStatusContext,
  archivedStatusDispatchContext,
  ContextProvider,
  userRoleContext,
  userRoleDispatchContext, 
  companyContext,
  companyDispatchContext
};
