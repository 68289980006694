import React, { useContext } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import sortIcon from "../../assets/img/sortIcon.png";
import {
  switchStatusContext,
  statusContext,
} from "../../context/serviceContext";
import { useParams, useLocation } from "react-router-dom";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    border: "none",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1.14491px solid rgba(0, 0, 0, 0.12)",
  },
  "& .MuiDataGrid-cell": {
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: "15px",
    fontWeight: 400,
    textAlign: "left",
  },
}));
function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
      }}
    >
      No Results Found
    </Box>
  );
}

function CustomUnsortedIcon() {
  return <img src={sortIcon} alt="" />;
}

export default function DataTable(tableData) {
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));

  let params = useParams();
  const location = useLocation();
  const clickedUserId = location.state?.clickedUserId;
  let path = window.location.pathname;
  const rowId = (row) => {
    if (row?.id) return row?.id;
    else if (row?.companyId) return row?.companyId;
    else if (row?.applicationId) return row?.applicationId;
    else if (row?.serviceId) return row?.serviceId;
    else if (row?.userId) return row?.userId;
    else if (row?.buildId) return row?.buildId;
  };
  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <StyledDataGrid
        localeText={{ noRowsLabel: " No Results Found" }}
        rows={tableData?.loading ? [] : tableData.rows}
        columns={tableData.columns}
        pageSize={tableData.size}
        getDetailPanelContent={tableData?.getDetailPanelContent}
        getRowId={(row) => rowId(row)}
        loading={tableData?.loading}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoRowsOverlay,
          ColumnUnsortedIcon: CustomUnsortedIcon,
          ColumnSortedAscendingIcon: CustomUnsortedIcon,
          ColumnSortedDescendingIcon: CustomUnsortedIcon,
        }}
        autoHeight
        initialState={{
          pinnedColumns: { left: ["name"] },
        }}
        columnVisibilityModel={tableData?.columnVisibilityModel ?? {}}
        disableColumnPinning={false}
        disableColumnMenu
        hideFooter={true}
        hideCellRightBorder={true}
        rowThreshold={9}
        disableExtendRowFullWidth={true}
        sortingOrder={["desc", "asc"]}
        sx={{
          boxShadow: "none",
          bgcolor: "#fff",
          borderRadius: "1px",
          padding: "0px",
          overflow: "none",
          border: "1px solid transparent",
          borderBottom: "1.2px solid rgba(0, 0, 0, 0.12)",
          "& .Mui-focused ": {
            borderRadius: "0",
          },
          "& .MuiDataGrid-columnHeaders": {
            border: "none",
            backgroundColor:
              switchStatusContextVal && statusContextVal
                ? "#373737"
                : "#01537A",
            position:
              path === `/user-details/${clickedUserId}` && tableData.tab === 0
                ? "static"
                : "sticky",
            zIndex: 2,
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "space-between",
          },

          "& .MuiDataGrid-pinnedColumns": {
            zIndex: 1,
            boxShadow: "none",
            overflow: "hidden",
            backgroundColor: "rgb(255, 255, 255)",
            marginBottom: "1px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 500,
            color: "#fff",
            fontSize: "15px",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
          },
          "& .MuiDataGrid-detailPanel": {
            zIndex: 1,
          },
          "*::-webkit-scrollbar": {
            width: "5px",
            height: "9px",
          },
          "*::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor: "#D9D9D9",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor:
              switchStatusContextVal && statusContextVal
                ? "#373737"
                : "#01537A",
            outline: "1px solid #01537A",
            borderRadius: "6px",
          },
          "& .MuiDataGrid-container--top": {
            top: tableData?.tableType ? "0px" : "64px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "visible !important",
            marginTop: "0px !important",
          },
          "& .MuiDataGrid-main": {
            overflow: "visible",
            height: "auto",
            zIndex: 12,
          },
        }}
      ></StyledDataGrid>
    </Box>
  );
}
