import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function PaginationRounded(props) {
   let pageCount = Math.ceil(props.Count/props.size)
    return (
        <Stack spacing={2}>
            <Pagination
                sx={{
                    
                    "& .Mui-selected": {
                        backgroundColor: "#FFF !important",
                        color: "#0094CD",
                        borderColor: "#0094CD"
                    },
                    "& .MuiPaginationItem-root":{
                        minWidth: "25px !important",
                        height: "25px !important"
                    },
                    "& .Mui-disabled":{
                        backgroundColor: "#919EAB !important",
                        color: "#C4CDD5",
                        borderColor: "#919EAB"
                    }
                }}
                count={pageCount}
                page={props.page}
                variant="outlined"
                shape="rounded"
                onChange={props.changeHandle} 
            />
        </Stack>
    );
}