import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useLocation } from "react-router-dom";
import DataTable from "./dataTable";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import { styled } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PopUp from "./popUp";
import Service from "../../api/services";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import MultipleSelectCheckmarks from "../../components/filterWithSelect";
import PaginationRounded from "../pagination";
import PaginationHeader from "./paginationHeader";
import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import Loader from "../../components/loader";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import {
  switchStatusContext,
  archivedStatusContext,
  statusContext,
} from "../../context/serviceContext";
import ModalViewButton from "./modalViewButton";
import ProgressBar from "./progressBar";
import { WarningMessages } from "../../config/messages";
import useToast from "../../hooks/useToast";

const nameComparator = (v1, v2, param1, param2) => {
  if (v1.vals !== "" && v2.vals !== "") {
    return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
  }
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    maxWidth: "100%",
    height: "auto",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "0px 2.68852px 2.68852px rgba(0, 0, 0, 0.25)",
    borderRadius: "3px",
  },
}));

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    sx: {
      boxShadow: 2,
      border: "none",
      maxWidth: "150px",
      color: "#000 !important",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderTopRightRadius: "6px",
      borderTopLeftRadius: "6px",
      marginTop: "10px",
      "& .Mui-focused ": {
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
        color: "#000 !important",
      },
      "& .MuiMenuItem-root": {
        color: "#000",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        fontSize: "16px",
        backgroundColor: "transparent",
        padding: "5px",
      },
      "& .MuiMenuItem-root: last-child": {
        color: "#000",
        borderBottom: "none",
        fontSize: "16px",
        backgroundColor: "#fff",
        "&:focus": {
          color: "#01537A",
        },
      },
      "& .Mui-selected:hover": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .Mui-selected": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .MuiBox-root:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        borderRadius: "6px",
        borderBottom: "none",
      },
      "& .MuiBox-root": {
        padding: "8px 10px",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: 2,
        paddingTop: "15px",
        borderRadius: "6px",
      },
      "& .MuiList-root": {
        padding: "0 15px",
      },
    },
  },
};

const NewIcon = (props) => (
  <KeyboardArrowDownIcon {...props} sx={{ color: "#01537A !important" }} />
);

const UserDetailsPage = () => {
  const userRole = localStorage.getItem("role");
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const statusContextVal = JSON.parse(useContext(statusContext));
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState();

  const [open, setOpen] = React.useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const { showSuccess, showError, showWarning } = useToast();
  const handleClose = () => {
    setOpen(false);
    setUpdateStatus(false);
    setItems([]);
  };
  const [bulkLoading, setBulkLoading] = useState(false);
  const handleBulkSave = () => {
    setBulkLoading(true);
    setSaveClick(true);
    setUpdateStatus(true);
    const newObject = {
      items: items,
      editorId: localStorage.getItem("userid"),
      userId: clickedCompId,
    };
    Service.userBulkUpdation(newObject)
      .then((res) => {
        if (res?.data.status === "Success") {
          setBulkLoading(false);
          cancelUpdate();
        }
        setSaveClick(false);
      })
      .catch((err) => {});
  };

  const [items, setItems] = useState([]);
  const handleStatus = (userIds, value) => {
    const result = items.find(({ userId }) => userId === userIds);
    if (result !== undefined) {
      result["status"] = value;
    } else {
      setItems([
        ...items,
        {
          userId: userIds,
          status: value,
          editorId: localStorage.getItem("userid"),
        },
      ]);
    }
  };

  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const textStyle = {
    color: "#0094CD",
    fontWeight: 500,
    width: "37px",
    height: "15px",
    cursor: "pointer",
  };
  const textStyle1 = {
    color: "#0094CD",
    fontWeight: "400",
    fontSize: "14px",
    width: "144px",
    height: "15px",
    cursor: "pointer",
  };
  const textStyle2 = {
    color: "#231F20",
    fontWeight: "400",
    fontSize: "14px",
    width: "61px",
    height: "15px",
  };

  const Stack1 = styled(Box)({
    color: "#01537A",
    fontWeight: "400",
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "24px",
    width: "150px",
  });

  const StackBox = styled(Typography)({
    padding: "10px",
  });

  const Stack2 = styled(Box)({
    color: "#373737",
    fontWeight: "400",
    fontSize: "16px",
    width: "100%",
    lineHeight: "24px",
  });

  const switchRole = localStorage.getItem("switchRole");

  const accessControls = () => {
    if (
      userRole === "ROLE_ADMIN" ||
      (userRole === "ROLE_LICENSEE" &&
        (switchRole === "channel" ||
          switchRole === "user" ||
          switchRole === "customer")) ||
      (userRole === "ROLE_CHANNEL" &&
        (switchRole === "user" || switchRole === "customer"))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const accessPermissionButton = (btnName) => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return `${btnName}-disable-btn disable`;
      } else {
        return `${btnName}-btn`;
      }
    } else {
      if (archivedStatusContextVal) {
        return `${btnName}-btn disable`;
      } else {
        return `${btnName}-btn`;
      }
    }
  };

  function DetailPanelContent({ row: rowProp }) {
    const apiRef = useGridApiContext();

    const expandRows = apiRef.current.state.detailPanel.expandedRowIds;

    expandRows.map((item) =>
      item !== rowProp.userId ? apiRef.current.toggleDetailPanel(item) : ""
    );

    const close = () => {
      apiRef.current.toggleDetailPanel(rowProp.userId);
    };

    const [saveData, setSaveData] = useState({
      userName: "",
      userId: "",
      status: "",
      companyId: clickedCompId,
      editorId: localStorage.getItem("userid"),
    });

    const [editError, setEditError] = useState({
      userName: false,
      userNameMsg: "",
    });

    const accessPermission = (status, checkStatus) => {
      if (
        accessControls() &&
        !switchStatusContextVal &&
        !archivedStatusContextVal
      ) {
        if (status === checkStatus) {
          return "btn-active";
        } else {
          return "btn-nonactive";
        }
      } else {
        if (!accessControls()) {
          if (status === checkStatus) {
            return "btn-disable-active disable";
          } else {
            return "btn-disable-nonactive disable";
          }
        } else {
          if (statusContextVal) {
            if (status === checkStatus) {
              return "btn-disable-active disable";
            } else {
              return "btn-disable-nonactive disable";
            }
          } else if (archivedStatusContextVal) {
            if (status === checkStatus) {
              return "btn-active disable";
            } else {
              return "btn-nonactive disable";
            }
          } else {
            if (status === checkStatus) {
              return "btn-active";
            } else {
              return "btn-nonactive";
            }
          }
        }
      }
    };

    const handleChanges = (e) => {
      const newData = { ...saveData };
      newData[e.target.name] = e.target.value;
      if (e.target.name === "userName") {
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setEditError({
            ...editError,
            userName: true,
            userNameMsg: "This field required",
          });
        } else {
          setEditError({
            ...editError,
            userName: false,
            userNameMsg: "",
          });
        }
      }
      setSaveData(newData);
    };
    const setStatus = (status) => {
      setSaveData({
        ...saveData,
        status: status,
      });
    };

    const [load, setLoad] = useState(false);
    const [resetClick, setResetClick] = useState(false);
    const resetPassword = (email) => {
      setLoad(true);
      setResetClick(true);
      Service.resetPassword({ email: email })
        .then((res) => {
          setLoad(false);
          setResetClick(false);
          if (res.data.status === "success") {
            showSuccess(res.data.message);
          } else {
            showError(res.data.message);
          }
        })
        .catch((err) => {});
    };

    const [saveClick, setSaveClick] = useState(false);
    const handleUserSave = () => {
      if (
        saveData?.userName === "" ||
        saveData?.userName.match(/^ *$/) !== null
      ) {
        setEditError({
          ...editError,
          userName: true,
          userNameMsg: "This field is required",
        });
      } else {
        setSaveClick(true);
        Service.updateUser(saveData, saveData?.userId)
          .then((res) => {
            if (res?.data.status === "success") {
              showSuccess(res?.data.message);
              setSaveClick(false);
              getUserDetails();
              close();
            } else {
              showError(res?.data.message);
              setSaveClick(false);
              getUserDetails();
              close();
            }
          })
          .catch((err) => {});
      }
    };

    useEffect(() => {
      const newData = { ...saveData };
      newData["userId"] = rowProp.userId;
      newData["userName"] = rowProp.name;
      newData["status"] = rowProp.status;
      newData["companyId"] = clickedCompId;
      setSaveData(newData);

      //eslint-disable-next-line
    }, [rowProp]);
    return (
      <Dialog
        open={true}
        maxWidth="xl"
        scroll={"body"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              marginTop: "10px",
              marginLeft: "34px",
              fontSize: "24px",
              color: "#01537A",
              padding: { lg: "20px 0px", md: "20px 0px" },
            }}
          >
            Update User Details
          </Typography>
          <CloseSharpIcon
            sx={{
              cursor: "pointer",
              color: "#01537A",
            }}
            onClick={close}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: "0px 40px" }}>
            <Box
              sx={{
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
                display: "flex",
                paddingBottom: "60px",
                columnGap: { lg: 10, md: 5, xs: 3 },
              }}
            >
              <Paper
                sx={{
                  maxWidth: {
                    xs: "380px",
                    sm: "400px",
                    md: "450px",
                    lg: "453px",
                  },
                  width: "100%",
                  height: "auto",
                  padding: "30px",
                  display: "flex",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  justifyContent: "space-between",
                }}
              >
                <Stack1 direction="column" spacing={2}>
                  <StackBox>Created</StackBox>
                  <StackBox>Email</StackBox>
                </Stack1>
                <Stack2 direction="column" spacing={2}>
                  <StackBox>
                    {rowProp?.created
                      ? moment(rowProp?.created).format("DD MMM YYYY, HH:mm")
                      : "-"}
                  </StackBox>
                  <StackBox className="wrapText">
                    {rowProp?.email ? rowProp?.email : "-"}
                  </StackBox>
                </Stack2>
              </Paper>
              <Box
                sx={{
                  paddingTop: { xs: "20px", lg: "0px" },
                }}
              >
                <Box>
                  <FormControl
                    sx={{
                      marginTop: { xs: "10px", lg: "0px" },
                      width: {
                        xs: "380px",
                        sm: "400px",
                        md: "450px",
                        lg: "565px",
                        xl: "650px",
                      },
                    }}
                    variant="standard"
                  >
                    <TextField
                      sx={{
                        fontWeight: "400px",
                        fontSize: {
                          xs: "16px",
                          sm: "16px",
                          md: "16px",
                        },
                        lineHeight: "18px",
                        letterSpacing: "0.5px",
                        color: "rgba(0, 0, 0, 0.87) !important",
                        fontStyle: "normal",
                        padding: "4px 0 0px",
                      }}
                      name="userName"
                      variant="standard"
                      onChange={handleChanges}
                      onKeyUp={handleChanges}
                      error={!!editError?.userName}
                      required
                      helperText={
                        editError?.userName ? editError?.userNameMsg : ""
                      }
                      value={saveData?.userName}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!accessPermissionFields() && (
                              <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                            )}
                          </InputAdornment>
                        ),
                        readOnly: accessPermissionFields() && true,
                      }}
                    />
                  </FormControl>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "24px",
                      letterSpacing: "0.4px",
                      color: "#01537A",
                    }}
                  >
                    Username
                  </Typography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography
                    sx={{
                      width: "45px",
                      height: "15x",
                      fontWeight: "400",
                      color: "#1B2935",
                    }}
                  >
                    Status
                  </Typography>
                  <ButtonGroup
                    sx={{
                      fontWeight: 400,
                      width: {
                        xs: "380px",
                        sm: "400px",
                        md: "450px",
                        lg: "565px",
                        xl: "650px",
                      },
                      height: { xs: "35px", lg: "40px" },
                      fontSize: { xs: "14px", lg: "16px" },
                    }}
                  >
                    <Button
                      type="button"
                      sx={{
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                      startIcon={
                        saveData?.status === "Active" ? (
                          <DoneIcon xs={{ fontColor: "#01537A !important" }} />
                        ) : (
                          ""
                        )
                      }
                      className={accessPermission("Active", saveData?.status)}
                      onClick={() => setStatus("Active")}
                    >
                      Active
                    </Button>
                    <Button
                      type="button"
                      sx={{
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                      startIcon={
                        saveData?.status === "Deleted" ? (
                          <DoneIcon sx={{ fontColor: "#01537A" }} />
                        ) : (
                          ""
                        )
                      }
                      className={accessPermission("Deleted", saveData?.status)}
                      onClick={() => setStatus("Deleted")}
                    >
                      Deleted
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
            <Divider
              sx={{
                color: "#DFDFDF",
                alignContent: "center",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                justifyContent: { xs: "center", lg: "space-between" },
                rowGap: 3,
                padding: "30px 0px",
              }}
            >
              <Button
                className={accessPermissionButton("modal-cancel")}
                variant="outlined"
                onClick={close}
              >
                Cancel
              </Button>
              {accessControls() && !rowProp?.verified && (
                <Button
                  sx={{
                    "&.Mui-disabled": {
                      pointerEvents: "none !important",
                    },
                  }}
                  className={accessPermissionButton("modal-reset")}
                  variant="outlined"
                  disabled={resetClick}
                  onClick={() => resetPassword(rowProp.email)}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <span style={{ marginRight: "5px" }}>
                      Resend Invite Link
                    </span>
                    {load ? (
                      <Loader
                        type={"spinningBubbles"}
                        color={"#01537A"}
                        height={20}
                        width={20}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Button>
              )}
              <Button
                sx={{
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "unset !important",
                  },
                }}
                className={accessPermissionButton("modal-save")}
                disabled={saveClick}
                variant="contained"
                onClick={handleUserSave}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Save</span>
                  {saveClick ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"white"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  DetailPanelContent.propTypes = {
    row: PropTypes.object.isRequired,
  };

  CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
  };

  function CustomDetailPanelToggle() {
    return <ModalViewButton />;
  }

  const [saveClick, setSaveClick] = useState(false);
  const handleUserSave = () => {
    if (items.length > 0) {
      setOpen(true);
    } else {
      showWarning(WarningMessages?.noActivity);
    }
  };

  const columns = [
    {
      flex: 1,
      field: "name",
      headerName: "User",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.name,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <HtmlTooltip
            placement="bottom-end"
            title={
              <React.Fragment>
                <PopUp value={params?.row} />
              </React.Fragment>
            }
          >
            <div>
              <span style={textStyle}>{params.row.name} </span>
            </div>
          </HtmlTooltip>
        );
      },
    },

    {
      flex: 1,
      field: "email",
      headerName: "Email Address",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.email,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span
              style={textStyle1}
              onClick={() => (window.location = `mailto:${params.row.email}`)}
            >
              {" "}
              {params.row.email}
            </span>
          </div>
        );
      },
    },

    {
      field: "created",
      headerName: "Created",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.created,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span style={textStyle2}>
              {moment(params.row.created).format("DD MMM YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.status,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <>
            {accessControls() && !accessPermissionFields() ? (
              <FormControl
                variant="standard"
                sx={{
                  minWidth: 100,
                  backgroundColor: "transparent !important",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "400",
                  fontSize: "13px",
                  marginTop: "11px",
                }}
              >
                <Select
                  sx={{ backgroundColor: "transparent !important" }}
                  labelId="demo-simple-select-standard-label"
                  defaultValue={params.row.status}
                  onChange={(e) =>
                    handleStatus(params.row.userId, e.target.value)
                  }
                  MenuProps={MenuProps}
                  disableUnderline={true}
                  IconComponent={NewIcon}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Deleted">Deleted</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <div>
                <span style={textStyle2}>{params.row.status}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.row.userId} />
      ),
    },
  ];

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    //eslint-disable-next-line
    []
  );

  const handleTypefilter = (event) => {
    const {
      target: { value },
    } = event;
    if (value[value.length - 1] === "All") {
      setType(type.length === typeValues.length ? [] : typeValues);
      return;
    }
    setType(value);
  };

  let typeValues = ["Active", "Deleted"];
  const [type, setType] = React.useState(["Active"]);

  const isAllSelected =
    typeValues.length > 0 && type.length === typeValues.length;

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const changePagination = (event, value) => {
    setPage(value - 1);
  };

  const handlePageSize = (e) => {
    setSize(e.target.value);
    setPage(0);
  };
  const [totalCount, setTotalCount] = useState(0);

  const location = useLocation();
  const clickedCompId = location.state?.clickedCompId;
  async function getUserDetails() {
    setLoading(true);
    Service.LicenseeUsers(
      { companyId: clickedCompId, status: type },
      page,
      size
    )
      .then((res) => {
        setResData(res.data.userDetails);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      })
      .catch((err) => {});
  }

  const cancelUpdate = () => {
    getUserDetails();
  };

  useEffect(() => {
    getUserDetails();
    //eslint-disable-next-line
  }, [type, page, size]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          pb: "8px",
          pr: "5px",
        }}
      >
        <FormControl
          size="small"
          sx={{ minWidth: 120 }}
          style={{
            borderColor: "#01537A !important",
            borderRadius: "6px",
          }}
        >
          <InputLabel
            style={{ color: "#01537A" }}
            sx={{
              paddingRight: "5px",
              backgroundColor: "#fff",
            }}
          >
            Status
          </InputLabel>
          <MultipleSelectCheckmarks
            handleChange={handleTypefilter}
            value={type}
            menuValues={typeValues}
            suffix={"Status: "}
            width="160px"
            checked={isAllSelected}
          />
        </FormControl>
        {totalCount !== 0 ? (
          <Box sx={{ mt: "15px" }}>
            <PaginationHeader
              page={page}
              size={size}
              dataLength={resData.length}
              totalCount={totalCount}
            />
          </Box>
        ) : null}
      </Box>

      {resData && !loading ? (
        <Box
          sx={{
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor:
                switchStatusContextVal && statusContextVal
                  ? "#373737"
                  : "#01537A !important",
              color: "white",
            },
          }}
        >
          <DataTable
            columns={columns}
            rows={resData}
            rowThreshold={0}
            loading={loading}
            getDetailPanelContent={getDetailPanelContent}
          />
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
        </Box>
      )}

      {!loading && totalCount >= 10 && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Typography
            component="span"
            variant="body2"
            sx={{ color: "#01537A" }}
          >
            Items per page :{" "}
          </Typography>
          <FormControl variant="standard">
            <Select
              value={size}
              sx={{
                width: "55px",
                margin: "0px 10px 0px 5px",
                backgroundColor: "#8080802b",
                color: "#000",
                borderBottom: "1px solid #01537A",
                textAlign: "center",
                "&:after": {
                  borderBottom: "1.5px solid #01537A",
                },
                "&hover": {
                  borderBottom: "1px solid #01537A",
                },
              }}
              onChange={handlePageSize}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>{Number(10).toLocaleString()}</MenuItem>
              <MenuItem value={25}>{Number(25).toLocaleString()}</MenuItem>
              <MenuItem value={50}>{Number(50).toLocaleString()}</MenuItem>
              <MenuItem value={100}>{Number(100).toLocaleString()}</MenuItem>
            </Select>
          </FormControl>
          <PaginationRounded
            changeHandle={changePagination}
            page={page + 1}
            Count={totalCount}
            size={size}
          />
        </Box>
      )}
      {!loading && totalCount > 0 && accessControls() && (
        <Box
          sx={{
            padding: "20px 0px 10px 0px",
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button
            className={accessPermissionButton("cancel")}
            variant="outlined"
            onClick={cancelUpdate}
          >
            Cancel
          </Button>

          <Button
            sx={{
              "&.Mui-disabled": {
                pointerEvents: "none !important",
              },
            }}
            className={accessPermissionButton("save")}
            variant="contained"
            disabled={saveClick}
            onClick={() => handleUserSave()}
          >
            Save
          </Button>
        </Box>
      )}

      <Dialog open={open} onClose={handleClose} scroll={"body"} maxWidth="md">
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                marginTop: "10px",
                color: "#01537A",
                fontWeight: 400,
                fontSize: "24px",
                lineHeight: "46px",
              }}
            >
              Bulk Update
            </Typography>
            <CloseSharpIcon
              sx={{
                color: "#01537A",
                cursor: "pointer",
                fontSize: "30px",
              }}
              onClick={handleClose}
            />
          </Box>
          {updateStatus && (
            <Typography
              sx={{ padding: "0px 30px 0px 10px", color: "#373737" }}
            >{`Updated ${items.length}  ${
              items.length > 1 ? "records :" : "record :"
            } `}</Typography>
          )}
        </DialogTitle>

        <DialogContent>
          {bulkLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "60px 40px 80px 30px",
                width: { xs: "380px", sm: "400px", md: "600px", xl: "700px" },
              }}
            >
              <ProgressBar value={progress} />
            </Box>
          ) : updateStatus && !bulkLoading ? (
            <Box
              sx={{
                padding: "20px 30px 20px 10px",
                display: "flex",
                width: { xs: "380px", sm: "400px", md: "600px", xl: "700px" },
              }}
            >
              <Typography
                sx={{ color: "#4CAF50" }}
              >{`Success: ${" "}`}</Typography>
              <Typography sx={{ color: "#373737" }}>{`${" " + items.length} ${
                items.length > 1 ? "Records" : "Record"
              } Updated Successfully`}</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                padding: "10px 30px 0px 30px",
              }}
            >
              <Typography
                sx={{
                  color: "#01537A",
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "30px",
                }}
              >
                You are updating {items.length} records , are you sure you want
                to make these changes? Some of these changes might be permanent.
              </Typography>

              <Divider
                sx={{
                  color: "#DFDFDF",
                  alignContent: "center",
                  marginTop: "40px",
                }}
              />
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          {!bulkLoading && !updateStatus && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 42px 30px 47px",
                gap: 2,
              }}
            >
              <Button
                className="modal-cancel-btn"
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                sx={{
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "unset !important",
                  },
                }}
                className="modal-save-btn"
                disabled={saveClick}
                onClick={handleBulkSave}
                variant="contained"
              >
                Save
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default UserDetailsPage;
