import { Box, Container, Divider } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ApDefnition from "./apDefinition";
import DataUsage from "../Production/dataUsage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: " 10px", paddingLeft: "20px" }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const tabStyle = {
  color: "#1B2935",
  fontWeight: 500,
  "&.Mui-selected": {
    backgroundColor: "#D1E1E7 !important",
    color: "#1B2935",
  },
};

export default function Production({
  applicationDetails,
  apiRefresh,
  sendDataToDetailPage,
}) {
  const [value, setValue] = React.useState(0);
  const [isShow, setShow] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const appDetailsRefresh = (data) => {
    apiRefresh(data);
  };
  // eslint-disable-next-line
  const [dataFromConfig, setDataFromConfig] = useState("");

  const handleData = (data) => {
    setDataFromConfig(data);
    sendDataToDetailPage(data);
  };

  const getValidateStatus = (data) => {
    setShow(data);
  };

  return (
    <Container maxWidth="xxl">
      <Box
        sx={{
          width: "100%",
          background: "white",
          maxWidth: {
            xs: "210px",
            sm: "420px",
            md: "initial",
            lg: "initial",
            xl: "initial",
          },
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#01537A",
              },
            }}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab sx={tabStyle} label="APP DEFINITION" {...a11yProps(0)} />
            <Tab sx={tabStyle} label="DATA USAGE LIMITS" {...a11yProps(1)} />
          </Tabs>
          <Divider
            sx={{
              color: "#DFDFDF",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              marginTop: "15px",
              borderBottomWidth: 1,
            }}
          ></Divider>
          {/* {value === 1 && !isShow && (
           
          )} */}
        </Box>

        <>
          <TabPanel value={value} index={0}>
            <ApDefnition
              applicationDetails={applicationDetails}
              appDetailsRefresh={appDetailsRefresh}
              sendDataToDetailPage={handleData}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DataUsage
              applicationDetails={applicationDetails}
              appDetailsRefresh={appDetailsRefresh}
              getValidateStatus={getValidateStatus}
              isShow={isShow}
              setShow={setShow}
            />
          </TabPanel>
        </>
      </Box>
    </Container>
  );
}
