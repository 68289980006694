import React, { useState, useEffect } from "react";
import DataTable from "../AccountSettings/dataTable";
import PropTypes from "prop-types";
import { Box, Stack, Typography, Divider, Button } from "@mui/material";
import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import RowExpandIcon from "./rowExpandIcon";
import DownloadIcon from "@mui/icons-material/Download";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Services from "../../api/services";
import Loader from "../../components/loader";
import { useLocation } from "react-router-dom";
import moment from "moment";

export default function BuildHistory({
  handleBuildHistoryClose,
  buildHistory,
  deploymentId,
}) {
  const nameComparator = (v1, v2, param1, param2) => {
    if (v1.vals !== "" && v2.vals !== "") {
      return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
    }
  };
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    //eslint-disable-next-line
    []
  );
  const [open, setOpen] = useState(false);
  const [apkDownload, setApkDownload] = useState();
  const [aabDownload, setAabDownload] = useState();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (apk, aab) => {
    setApkDownload(apk);
    setAabDownload(aab);
    setOpen(true);
  };
  const location = useLocation();
  const selectedAppId = location.state.clickedAppId;
  const [loading, setLoading] = useState(false);
  let API_URL = process.env.REACT_APP_API_URL;

  const [resData, setResData] = useState();

  async function getBuildHistory() {
    setLoading(true);
    Services.getBuildHistory({ deploymentId: deploymentId })
      .then((res) => {
        setResData(res?.data);
        setLoading(false);
      })
      .catch((err) => {});
  }

  const downloadAPK = (apkUrl) => {
    const anchor = document.createElement("a");
    anchor.href = API_URL + apkUrl;
    anchor.download = API_URL + apkUrl;
    anchor.click();
    anchor.remove();
  };

  useEffect(() => {
    getBuildHistory();
    //eslint-disable-next-line
  }, []);

  function DetailPanelContent({ row: rowProp }) {
    const apiRef = useGridApiContext();

    const expandRows = apiRef.current.state.detailPanel.expandedRowIds;

    useEffect(() => {
      expandRows.forEach((item) => {
        if (item !== rowProp.buildId) {
          apiRef.current.toggleDetailPanel(item);
        }
      });
      // eslint-disable-next-line
    }, [expandRows, rowProp.buildId]);
    return (
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Stack direction="row" spacing={2}>
            {" "}
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Deployment ID
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Base Confiq ID
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Base Confiq Name
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Deployment Type
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Service Domain
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.deploymentId ? rowProp?.deploymentId : "--"}
              </Typography>

              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.configId ? rowProp?.configId : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.configName ? rowProp?.configName : "--"}
              </Typography>

              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.deploymentType ? rowProp?.deploymentType : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.proxyConfigDomain ? rowProp?.proxyConfigDomain : "--"}
              </Typography>
            </Stack>
          </Stack>
          <Typography
            sx={{
              fontWeight: "700",
              color: "#1B2935",
              marginTop: "29px",
            }}
          >
            Proxy Endpoint
          </Typography>

          <Stack
            sx={{
              marginBottom: "29px",
            }}
            direction="row"
            spacing={2}
          >
            {" "}
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Google App ID
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Distribution Email
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Build Type
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Status Date time
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Comments
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Version Tag
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.googleAppId ? rowProp?.googleAppId : "--"}
              </Typography>

              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.distributionEmail ? rowProp?.distributionEmail : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.buildType ? rowProp?.buildType : "--"}
              </Typography>

              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.statusDateTime
                  ? moment(rowProp?.statusDateTime).format("DD MMM YYYY HH:mm")
                  : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.comment ? rowProp?.comment : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.versionTag ? rowProp?.versionTag : "--"}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" spacing={10}>
            {" "}
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Generated by
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Created on
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.generatedBy ? rowProp?.generatedBy : "--"}
              </Typography>

              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.created
                  ? moment(rowProp?.created).format("DD MMM YYYY HH:mm")
                  : "--"}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: "700",
              color: "#1B2935",
            }}
          >
            App Attributes
          </Typography>
          <Stack direction="row" spacing={3}>
            {" "}
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Home URL
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                App Name
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                icon Url
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Splash Url
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Action Bar Colour
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Use Dark Action Bar Icons
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Status Bar Colour
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Accent Colour
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Location Permission
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Storage Permission
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.homeUrl ? rowProp?.homeUrl : "--"}
              </Typography>

              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.applicationName ? rowProp?.applicationName : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.iconUrl ? rowProp?.iconUrl : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.splashUrl ? rowProp?.splashUrl : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.actionBarColor ? rowProp?.actionBarColor : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.useDarkActionBarIcons !== ""
                  ? rowProp?.useDarkActionBarIcons === true
                    ? "true"
                    : rowProp?.useDarkActionBarIcons === false
                    ? "false"
                    : "--"
                  : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.statusBarColor ? rowProp?.statusBarColor : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.accentColor ? rowProp?.accentColor : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.locationPermission !== ""
                  ? rowProp?.locationPermission === true
                    ? "true"
                    : rowProp?.locationPermission === false
                    ? "false"
                    : "--"
                  : "--"}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "14px",
                }}
              >
                {rowProp?.storagePermission !== ""
                  ? rowProp?.storagePermission === true
                    ? "true"
                    : rowProp?.storagePermission === false
                    ? "false"
                    : "--"
                  : "--"}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  }
  DetailPanelContent.propTypes = {
    row: PropTypes.object.isRequired,
  };
  CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
  };
  function CustomDetailPanelToggle() {
    return <RowExpandIcon />;
  }

  const columns = [
    {
      flex: 1,
      field: "buildId",
      headerName: "Build ID",
      minWidth: 120,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.buildId,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.buildId ? params.row.buildId : ""}</span>
          </div>
        );
      },
    },

    {
      flex: 1,
      field: "date",
      headerName: "Date",
      minWidth: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.date,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span>
              {params.row.created
                ? moment(params.row.created).format("DD MMM YYYY HH:mm")
                : ""}
            </span>
          </div>
        );
      },
    },

    {
      field: "configName",
      headerName: "Base Config Name",
      minWidth: 220,
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.configName,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.configName ? params.row.configName : ""}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "versionCode",
      headerName: "Version Code",
      minWidth: 115,
      sortable: false,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.versionCode,
      }),

      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.versionCode ? params.row.versionCode : ""}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "versionName",
      headerName: "Version Name",
      minWidth: 120,
      sortable: false,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.versionName,
      }),

      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.versionName ? params.row.versionName : ""}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "binuVersion",
      headerName: "Datafree Version",
      minWidth: 135,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.binuVersion,
      }),

      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.versionTag ? params.row.versionTag : ""}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.status,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.status ? params.row.status : ""}</span>
          </div>
        );
      },
    },

    {
      flex: 1,
      headerClassName: "super-app-theme--header",
      field: "action",
      headerName: "",
      minWidth: 50,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {!row.aabUrl && !row.apkUrl ? (
            ""
          ) : (
            <DownloadIcon
              sx={{
                background: "#01537A",
                border: "1px solid #01537A",
                borderRadius: "50px",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => handleOpen(row.apkUrl, row.aabUrl)}
            />
          )}
        </>
      ),
    },

    {
      headerClassName: "super-app-theme--header",
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.row.buildId} />
      ),
    },
  ];

  return (
    <>
      <Dialog
        maxWidth="xxl"
        open={buildHistory}
        onClose={handleBuildHistoryClose}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#01537A",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "26px",

                marginTop: "10px",
              }}
            >
              APK Build History
            </Typography>
            <CloseSharpIcon
              onClick={handleBuildHistoryClose}
              sx={{
                color: "#757373",
                cursor: "pointer",
              }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "170px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>Application ID</Typography>
            <Typography>{selectedAppId ? selectedAppId : ""}</Typography>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            {resData && !loading ? (
              <Box
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#01537A !important",
                    color: "white",
                  },
                }}
              >
                <DataTable
                  columns={columns}
                  rows={resData}
                  rowThreshold={0}
                  loading={loading}
                  getDetailPanelContent={getDetailPanelContent}
                  tableType={"buildHistory"}
                />
              </Box>
            ) : resData === null && !loading ? (
              <Box
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#01537A !important",
                    color: "white",
                  },
                }}
              >
                <DataTable
                  columns={columns}
                  rows={[]}
                  rowThreshold={0}
                  loading={loading}
                  getDetailPanelContent={getDetailPanelContent}
                  tableType={"buildHistory"}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: { sm: "300px", md: "500px", lg: "1000px" },
                }}
              >
                <Loader
                  type={"spokes"}
                  color={"#01537A"}
                  height={40}
                  width={40}
                />
              </Box>
            )}
          </Box>

          <Divider
            sx={{
              color: "#DFDFDF",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              marginTop: "20px",
              borderBottomWidth: 1,
              width: "100%",
            }}
          ></Divider>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className="save-btn"
              onClick={handleBuildHistoryClose}
              variant="contained"
              sx={{
                marginTop: "15px",
              }}
            >
              Return
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="sm"
        open={open}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#01537A",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "26px",
                width: "302px",
                height: "27px",
                marginTop: "10px",
              }}
            >
              Download Wrap App
            </Typography>
            <CloseSharpIcon
              onClick={handleClose}
              sx={{
                color: "#757373",
                cursor: "pointer",
              }}
            />
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "40px",
              marginRight: "300px",
              marginTop: "20px",
            }}
          >
            <DownloadIcon
              sx={{
                background: !apkDownload ? "#757373" : "#01537A",
                border: !apkDownload
                  ? "1px solid #757373"
                  : "1px solid #01537A",
                borderRadius: "50px",
                color: "white",
              }}
            />
            <Typography
              className={!apkDownload ? "disable" : null}
              sx={{
                color: !apkDownload ? "#757373" : "#0094CD",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => downloadAPK(apkDownload)}
            >
              Download playstore Apk
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "40px",
              marginBottom: "20px",
            }}
          >
            <DownloadIcon
              sx={{
                background: !aabDownload ? "#757373" : "#01537A",
                border: !aabDownload
                  ? "1px solid #757373"
                  : "1px solid #01537A",
                borderRadius: "50px",
                color: "white",
              }}
            />
            <Typography
              className={!aabDownload ? "disable" : null}
              sx={{
                color: !aabDownload ? "#757373" : "#0094CD",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => downloadAPK(aabDownload)}
            >
              Download playstore Aab
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
