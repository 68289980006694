import Layout from "../../components/layout";
import { CookiesProvider } from "react-cookie";
import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Button,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import defaultImg from "../../assets/img/noImage.png";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Services from "../../api/services";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChangeLog from "../../components/AccountSettings/changeLog";
import Users from "../../components/AccountSettings/users";
import Publishers from "../../components/AccountSettings/publisher";
import Divider from "@mui/material/Divider";
import useResponsive from "../../components/useResponsive";
import DoneIcon from "@mui/icons-material/Done";
import ButtonGroup from "@mui/material/ButtonGroup";
import "../../App.css";
import moment from "moment";
import Loader from "../../components/loader";
import BreadCrumb from "../../components/breadCrumb";
import LogoPopUp from "../../components/logoPopUp";
import {
  userDispatchContext,
  switchStatusContext,
  statusContext,
  switchStatusDispatchContext,
  archivedStatusContext,
  archivedStatusDispatchContext,
} from "../../context/serviceContext";
import ConfirmationPopup from "../../components/confirmationPopup";
import { useNavigate, useLocation } from "react-router-dom";
import useToast from "../../hooks/useToast";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Stack1 = styled(Box)({
  color: "#01537A",
  fontWeight: "400",
  fontSize: "12px",
  letterSpacing: "0.4px",
  lineHeight: "24px",
  width: "150px",
  top: "5.5px",
});

const Stack2 = styled(Box)({
  color: "#373737",
  fontWeight: "400",
  fontSize: "16px",
  width: "200px",
  height: "24px",
  lineHeight: "24px",
});

const StackBox = styled(Typography)({
  padding: "10px",
  width: "185px",
});

const tabStyle = {
  color: "#01537A",
  fontWeight: 500,
  "&.Mui-selected": {
    color: "#01537A",
  },
};
export default function ChannelAccount() {
  const userDispatchContextVal = useContext(userDispatchContext);
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  let switchStatusDispatchContextVal = useContext(switchStatusDispatchContext);
  let archivedStatusDispatchContextVal = useContext(
    archivedStatusDispatchContext
  );
  let archivedStatusContextVal = useContext(archivedStatusContext);
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState(null);
  const [imgSrc, setImgSrc] = useState(defaultImg);
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const clickedCompId = location.state?.clickedCompId;
  const warnMsg = `Are your sure you want to archive this channel? \n This action cannot be undone and all users in this channel will be archived.`;
  const [msg, setMsg] = useState(warnMsg);
  const userRole = localStorage.getItem("role");
  const [value, setValue] = React.useState(0);
  const isMobile = useResponsive("down", "md");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [imgPopup, setImgPopUp] = useState(false);
  const handleImgPop = () => {
    setImgPopUp(false);
  };
  const { showSuccess, showWarning } = useToast();
  const refreshData = (imgValue) => {
    setImgSrc(imgValue);
    userDispatchContextVal(true);
  };

  const [editData, setEditData] = useState({
    name: "",
  });
  const [editError, setEditError] = useState({
    name: false,
    nameMsg: "",
  });

  const getImage = (e) => {
    setImgPopUp(true);
  };

  const handleChanges = (e) => {
    const newData = { ...editData };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "name") {
      if (editData?.name !== resData?.name) {
        checkName(e.target.value);
      }
    }
    setEditData(newData);
  };

  const [checkValue, setCheckValue] = useState();
  async function checkName(nameVal) {
    if (nameVal === "" || nameVal.match(/^ *$/) !== null) {
      setEditError({
        ...editError,
        name: true,
        nameMsg: "This field required",
      });
    } else {
      Services.checkCompanyName({ name: nameVal })
        .then((res) => {
          setCheckValue(res.data.status);
          if (res.data.status === true) {
            setEditError({
              ...editError,
              name: true,
              nameMsg: "Company name already exists",
            });
          } else {
            setEditError({
              ...editError,
              name: false,
              nameMsg: "",
            });
          }
        })
        .catch((err) => {});
    }
  }
  const navigate = useNavigate();
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [type, setType] = useState("warning");
  const [statusValue, setStatusValue] = useState("");

  const setStatus = (status) => {
    const newData = { ...editData };

    if (status === "Archived") {
      setConfirmPopUp(true);
    }
    newData["companyStatus"] = status;
    setEditData(newData);
  };

  const closeConfirmationBox = () => {
    setConfirmPopUp(false);
    setType("warning");
    setMsg(warnMsg);
    editData["companyStatus"] = statusValue;
  };

  const confirmClick = () => {
    setConfirmPopUp(false);
  };

  async function getBasicDetails() {
    setLoading(true);
    Services.LicenseeAccountSettings({ companyId: clickedCompId })
      .then((res) => {
        if (res?.data?.status === "failed") {
          navigate("/");
          showWarning(res?.data?.message);
          return;
        }
        setResData(res.data);
        setEditData({
          name: res?.data?.name,
          companyStatus: res?.data?.status,
          editorId: localStorage.getItem("userid"),
        });
        setStatusValue(res.data?.status);
        if (res.data?.status === "Suspended") {
          localStorage.setItem("switchStatus", true);
          switchStatusDispatchContextVal(true);

          localStorage.setItem("archivedStatus", false);
          archivedStatusDispatchContextVal(false);
        } else if (res.data?.status === "Archived") {
          localStorage.setItem("archivedStatus", true);
          archivedStatusDispatchContextVal(true);

          localStorage.setItem("switchStatus", false);
          switchStatusDispatchContextVal(false);
        } else {
          localStorage.setItem("switchStatus", false);
          switchStatusDispatchContextVal(false);

          localStorage.setItem("archivedStatus", false);
          archivedStatusDispatchContextVal(false);
        }

        if (res?.data?.logoImage !== null) {
          setImgSrc(API_URL + res?.data?.logoImage);
        } else {
          setImgSrc(defaultImg);
        }
        setLoading(false);
      })
      .catch((err) => {});
  }

  const cancelUpdate = () => {
    setEditError({
      name: false,
      nameMsg: "",
    });
    getBasicDetails();
    setLoad(false);
  };

  const [saveClick, setSaveClick] = useState(false);
  const updateDetails = () => {
    if (editData?.name === "") {
      setEditError({
        ...editError,
        name: true,
        nameMsg: "This field required",
      });
    } else if (checkValue === true) {
      setEditError({
        ...editError,
        name: true,
        nameMsg: "Company name already exists",
      });
    } else {
      setSaveClick(true);
      setLoad(true);
      Services.updateCompanyDetails(editData, clickedCompId)
        .then((res) => {
          setSaveClick(false);
          setLoad(false);
          if (res?.data?.status === "success") {
            showSuccess(res?.data.message);
            cancelUpdate();
          } else if (res?.data.status === "Error") {
            showWarning(res?.data.message);
            cancelUpdate();
          }
        })
        .catch((err) => {
          setSaveClick(false);
        });
    }
  };

  const accessControls = () => {
    if (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE") {
      return true;
    } else {
      return false;
    }
  };

  const accessPermission = (status, checkStatus) => {
    if (
      accessControls() &&
      !switchStatusContextVal &&
      !archivedStatusContextVal
    ) {
      if (status === checkStatus) {
        return "btn-active";
      } else {
        return "btn-nonactive";
      }
    } else {
      if (userRole === "ROLE_CHANNEL") {
        if (status === checkStatus) {
          return "btn-disable-active disable";
        } else {
          return "btn-disable-nonactive disable";
        }
      } else {
        if (statusContextVal) {
          if (status === checkStatus) {
            return "btn-disable-active disable";
          } else {
            return "btn-disable-nonactive disable";
          }
        } else if (archivedStatusContextVal) {
          if (status === checkStatus) {
            return "btn-active disable";
          } else {
            return "btn-nonactive disable";
          }
        } else {
          if (status === checkStatus) {
            return "btn-active";
          } else {
            return "btn-nonactive";
          }
        }
      }
    }
  };

  const accessPermissionFields = () => {
    if (statusContextVal || archivedStatusContextVal) {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionButton = (btnName) => {
    if (statusContextVal) {
      if (archivedStatusContextVal) {
        return `${btnName}-btn disable`;
      } else {
        return `${btnName}-disable-btn disable`;
      }
    } else {
      if (archivedStatusContextVal) {
        return `${btnName}-btn disable`;
      } else {
        return `${btnName}-btn`;
      }
    }
  };

  useEffect(() => {
    getBasicDetails();
    //eslint-disable-next-line
  }, []);
  return (
    <CookiesProvider>
      <Layout>
        <Box>
          <Box sx={{ marginLeft: "40px" }}>
            {resData !== null && !loading ? (
              <BreadCrumb value={resData} other="Account Settings" />
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: { xs: "10px 30px 40px 40px", md: "10px 30px 25px 90px" },
              columnGap: { xs: "0px", md: "27px" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingLeft: "60px",
                width: "172px",
                height: "171px",
                p: 1,
                m: 1,
                textAlign: "center",
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              <Paper
                style={{
                  background: "#fff",
                  width: "148px",
                  height: "148px",
                  borderRadius: "14PX",
                  color: "#01537A",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "6px 6px",
                }}
              >
                <img
                  style={{ borderRadius: "50%", padding: "2px 2px" }}
                  alt="Datafree"
                  src={imgSrc === null ? defaultImg : imgSrc}
                  rounded="lg"
                  width="135px"
                  height="135px"
                />
              </Paper>
              <IconButton
                style={{
                  position: "absolute",
                  background: accessPermissionFields() ? "#373737" : "#01537A",
                  color: "#FFFFFF",
                  width: "22.71px",
                  height: "22.71px",
                  top: imgSrc === defaultImg ? "112px" : "120px",
                  fontSize: "15px",
                  right: "36px",
                  bottom: "0px",
                }}
                className={accessPermissionFields() ? "disable" : null}
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={getImage}
              >
                <EditOutlinedIcon fontSize="11px" />
              </IconButton>
            </Box>

            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                padding: { xs: "12px", md: 0 },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "400px",
                  fontSize: { xs: "30px", sm: "35px", md: "40px" },
                  lineHeight: "18px",
                  letterSpacing: "0.5px",
                  color: "#757373 !important",
                  fontStyle: "normal",
                }}
              >
                {resData?.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "26.11px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                  paddingTop: "20px",
                }}
              >
                Channel Details -{" "}
                {userRole === "ROLE_ADMIN"
                  ? "Admin View"
                  : userRole === "ROLE_LICENSEE"
                  ? "Licensee View"
                  : userRole === "ROLE_CHANNEL"
                  ? "Channel View"
                  : ""}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "0px 25px 0px 25px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Tabs
              sx={{
                maxwidth: {
                  xs: "402px",
                  md: "613px",
                  lg: "1440px",
                  xl: "1440px",
                },
                "& .MuiButtonBase-root-MuiTab-root": {
                  padding: " 15px 22px 15px 20px",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#01537A",
                },
              }}
              variant="scrollable"
              scrollButtons={isMobile ? true : false}
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tabStyle} label="company" {...a11yProps(0)} />
              <Tab sx={tabStyle} label="change log" {...a11yProps(1)} />
              <Tab sx={tabStyle} label="users" {...a11yProps(2)} />
              <Tab sx={tabStyle} label="customers" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <Divider
            sx={{
              color: "#DFDFDF",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              marginTop: "20px",
              borderBottomWidth: 2,
            }}
          ></Divider>

          <TabPanel value={value} index={0}>
            {loading && resData === null ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Loader
                  type={"spokes"}
                  color={"#01537A"}
                  height={40}
                  width={40}
                />
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    flexDirection: {
                      xs: "column",
                      lg: "row",
                    },
                    display: "flex",
                    paddingBottom: "60px",
                  }}
                >
                  <Paper
                    sx={{
                      maxWidth: { xs: "380px", md: "450px", lg: "450px" },
                      width: "100%",
                      height: "230px",
                      padding: "30px",
                      display: "flex",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack1 direction="column" spacing={2} component="span">
                      <StackBox>Company ID </StackBox>
                      <StackBox>Company Type</StackBox>
                      <StackBox>Licensee Owner</StackBox>
                      <StackBox>Created</StackBox>
                    </Stack1>
                    <Stack2 direction="column" spacing={2} component="span">
                      <StackBox>
                        {resData?.companyId ? resData?.companyId : "-"}{" "}
                      </StackBox>
                      <StackBox>
                        {resData?.companyType ? resData?.companyType : "-"}
                      </StackBox>
                      <StackBox className="wrapText">
                        {resData?.owner ? resData?.owner : "-"}
                      </StackBox>
                      <StackBox>
                        {resData?.created
                          ? moment(resData?.created).format(
                              "DD MMM YYYY, HH:mm"
                            )
                          : "-"}{" "}
                      </StackBox>
                    </Stack2>
                  </Paper>
                  <Box
                    sx={{
                      paddingLeft: { xs: "0px", lg: "100px" },
                      paddingTop: { xs: "20px", lg: "0px" },
                    }}
                  >
                    <Box>
                      <FormControl
                        sx={{
                          width: {
                            xs: "380px",
                            md: "450px",
                            lg: "565px",
                            xl: "650px",
                          },
                        }}
                        variant="standard"
                      >
                        <TextField
                          sx={{
                            fontWeight: "400px",
                            fontSize: {
                              xs: "16px",
                              sm: "16px",
                              md: "16px",
                            },
                            lineHeight: "18px",
                            letterSpacing: "0.5px",
                            color: "rgba(0, 0, 0, 0.87) !important",
                            fontStyle: "normal",
                            padding: "4px 0 0px",
                          }}
                          name="name"
                          variant="standard"
                          onChange={handleChanges}
                          error={!!editError?.name}
                          helperText={editError?.name ? editError?.nameMsg : ""}
                          value={editData?.name}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {!accessPermissionFields() && (
                                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                                )}
                              </InputAdornment>
                            ),
                            readOnly: accessPermissionFields() && true,
                          }}
                        />
                      </FormControl>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "24px",
                          letterSpacing: "0.4px",
                          color: "#01537A",
                        }}
                      >
                        Company Name
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          color: "#1B2935",
                        }}
                      >
                        Status
                      </Typography>
                      <ButtonGroup
                        sx={{
                          width: {
                            xs: "380px",
                            md: "450px",
                            lg: "565px",
                            xl: "650px",
                          },
                          height: { xs: "35px", lg: "40px" },
                          fontWeight: 400,
                        }}
                      >
                        {statusValue === "New" && (
                          <Button
                            type="button"
                            sx={{
                              width: "100%",
                              textTransform: "capitalize",
                              fontSize: { xs: "12px", md: "14px", lg: "16px" },
                            }}
                            startIcon={
                              editData?.companyStatus === "New" ? (
                                <DoneIcon />
                              ) : (
                                ""
                              )
                            }
                            className={accessPermission(
                              "New",
                              editData?.companyStatus
                            )}
                            onClick={() => setStatus("New")}
                          >
                            New
                          </Button>
                        )}

                        <Button
                          type="button"
                          sx={{
                            width: "100%",
                            fontSize: { xs: "12px", md: "14px", lg: "16px" },
                            textTransform: "capitalize",
                          }}
                          startIcon={
                            editData?.companyStatus === "Approved" ? (
                              <DoneIcon />
                            ) : (
                              ""
                            )
                          }
                          className={accessPermission(
                            "Approved",
                            editData?.companyStatus
                          )}
                          onClick={() => setStatus("Approved")}
                        >
                          Approved
                        </Button>
                        <Button
                          type="button"
                          sx={{
                            width: "100%",
                            fontSize: { xs: "12px", md: "14px", lg: "16px" },
                            textTransform: "capitalize",
                          }}
                          startIcon={
                            editData?.companyStatus === "Suspended" ? (
                              <DoneIcon />
                            ) : (
                              ""
                            )
                          }
                          className={accessPermission(
                            "Suspended",
                            editData?.companyStatus
                          )}
                          onClick={() => setStatus("Suspended")}
                        >
                          Suspended
                        </Button>
                        {accessControls() && (
                          <Button
                            type="button"
                            sx={{
                              width: "100%",
                              fontSize: { xs: "12px", md: "14px", lg: "16px" },
                              textTransform: "capitalize",
                            }}
                            startIcon={
                              editData?.companyStatus === "Archived" ? (
                                <DoneIcon />
                              ) : (
                                ""
                              )
                            }
                            className={accessPermission(
                              "Archived",
                              editData?.companyStatus
                            )}
                            onClick={() => setStatus("Archived")}
                          >
                            Archived
                          </Button>
                        )}
                      </ButtonGroup>
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ color: "#DFDFDF" }} />
                <Box
                  sx={{
                    padding: "20px 0px 10px 10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                  }}
                >
                  <Button
                    className={accessPermissionButton("cancel")}
                    variant="outlined"
                    onClick={cancelUpdate}
                  >
                    Cancel
                  </Button>

                  <Button
                    sx={{
                      "&.Mui-disabled": {
                        cursor: "not-allowed !important",
                        pointerEvents: "unset !important",
                      },
                    }}
                    className={accessPermissionButton("save")}
                    disabled={saveClick}
                    variant="contained"
                    onClick={updateDetails}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <span style={{ marginRight: "5px" }}>Save</span>
                      {load ? (
                        <Loader
                          type={"spinningBubbles"}
                          color={"white"}
                          height={20}
                          width={20}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Button>
                </Box>
              </Box>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangeLog />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Users />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Publishers />
          </TabPanel>
        </Box>

        {imgPopup && (
          <LogoPopUp
            open={imgPopup}
            handleClose={handleImgPop}
            imgs={imgSrc}
            defaultImg={defaultImg}
            compId={clickedCompId}
            refreshData={refreshData}
          />
        )}

        {confirmPopUp && (
          <ConfirmationPopup
            open={confirmPopUp}
            handleClose={closeConfirmationBox}
            message={msg}
            confirmSave={confirmClick}
            type={type}
          />
        )}
      </Layout>
    </CookiesProvider>
  );
}
