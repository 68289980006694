import React,{useContext} from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {IconButton} from "@mui/material";
import { switchStatusContext, statusContext } from '../../context/serviceContext';

export default function RowExpandIcon() {
    const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
    const statusContextVal = JSON.parse(useContext(statusContext));
    return (
      <IconButton
        size="medium"
        
      >
        <KeyboardArrowDownIcon
          fontSize="inherit"
          sx={{
            color: switchStatusContextVal && statusContextVal ? "#373737 !important" : "#01537A !important",
            cursor: "pointer",
          }}
        />
      </IconButton>
    )
}
