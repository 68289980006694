import React from "react";
import DialogActions from "@mui/material/DialogActions";
import { Divider, Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function BuildApkPopUp({
  buildApkPopUp,
  buildApkPopUpClose,
  handleBuildApkOpen,
}) {
  return (
    <Dialog
      open={buildApkPopUp}
      maxWidth="xl"
      scroll={"body"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            color: "#01537A",
            fontSize: "24px",
          }}
        >
          {" "}
          Build APK
        </Typography>

        <CloseOutlinedIcon
          onClick={buildApkPopUpClose}
          sx={{ color: "#757373", fontSize: "20px", cursor: "pointer" }}
        />
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "16px",
            color: "#333333",
            marginBottom: "30px",
            marginTop: "10px",
          }}
        >
          Before creating the APK, please make sure the Google App Id is
          correct, this field will no longer be
          <br />
          editable upon creation of the APK
        </Typography>

        <Divider
          sx={{
            color: "#DFDFDF",
            backgroundColor: "#FFFFFF",
            borderRadius: "6px",
            borderBottomWidth: 1,
            marginTop: "40px",
          }}
        ></Divider>
      </DialogContent>
      <DialogActions
        sx={{
          paddingBottom: "20px",
          paddingRight: "30px",
          gap: "27px",
        }}
      >
        <Button
          onClick={buildApkPopUpClose}
          sx={{
            padding: " 5px 50px 5px 50px",
            width: "fit-content",
            color: "#01537A",
            borderColor: "#01537A !important",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          onClick={handleBuildApkOpen}
          sx={{
            padding: "5px 50px 5px 50px",
            width: "fit-content",
            background: "#01537A !important",
            textTransform: "capitalize",
            "&.Mui-disabled": {
              background: "#757373 !important",
              color: "white",
              textTransform: "capitalize",
              padding: "0px 50px",
            },
          }}
          variant="contained"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
