import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai"
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";

const SnackbarWrapper = ({open, onClose, message}) => {
  const content = <div style={{display:'flex',justifyContent:'center'}}> 
  <AiOutlineInfoCircle style={{fontSize: '21px'}}/> 
  <span style={{paddingTop:'2px', paddingLeft: '10px'}}>{message}</span>
  </div>;
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={onClose}
      message={content}
      sx={{ bottom: { xs: 0, sm: 0 }, 
      width: "100%", 
      textAlign:'center', 
      backgroundColor: '#01537A',
      left: '0px',
      right: '0px',
      "& .MuiSnackbarContent-root" : {
        backgroundColor: '#01537A',
        width: '100%',
        justifyContent: {xs:'flex-start' , sm: 'center'}
      },
      "& .MuiSnackbarContent-action" : {
        position:'absolute', 
        right: '15px ',
        marginLeft: '0px'
      }
      }}
      action={
        <Box sx={{color: '#fff'}}>
        <IconButton color="inherit" size="small" onClick={onClose} >
          <AiOutlineClose style={{fontSize: '21px'}} />
        </IconButton>
        </Box>
      }
    >
    </Snackbar>
  );
};

export default SnackbarWrapper;
