import React, { useState, useEffect, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../assets/img/logo-new.png";
import AvatarLogo from "../assets/img/noImage.png";
import Knowledge from "../assets/img/knowledge.svg";
import Reporting from "../assets/img/reporting.png";
import appIcon from "../assets/img/application.png";
import userIcon from "../assets/img/user.png";
import companyIcon from "../assets/img/company.png";
import defaultImg from "../assets/img/defaultUserImg.png";
import Button from "@mui/material/Button";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Services from "../api/services";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import LogoPopUp from "./logoPopUp";
import {
  userDispatchContext,
  userContext,
  statusContext,
  switchStatusDispatchContext,
  archivedStatusDispatchContext,
  companyDispatchContext,
  statusDispatchContext,
  switchStatusContext,
  archivedStatusContext,
} from "../context/serviceContext";
import { useUser } from "../context/userContext";
import useToast from "../hooks/useToast";
import { WarningMessages } from "../config/messages";
import { AppVersionContext } from "../context/appVersionContext";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Divider from "@mui/material/Divider";

const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
const CONGNITO_APP_CLIENT_ID = process.env.REACT_APP_CONGNITO_APP_CLIENT_ID;
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
const APP_VERSION = process.env.REACT_APP_VERSION;

const addMenu = [
  {
    id: 1,
    imageIcon: appIcon,
    title: "Add Application",
    path: "/create-application",
  },
  {
    id: 2,
    imageIcon: companyIcon,
    title: "Add Company",
    path: "/create-company",
  },
  { id: 3, imageIcon: userIcon, title: "Add User", path: "/create-user" },
];

const PublisheraddMenu = [
  {
    id: 1,
    imageIcon: appIcon,
    title: "Add Application",
    path: "/create-application",
  },
  { id: 3, imageIcon: userIcon, title: "Add User", path: "/create-user" },
];

const ResponsiveAppBar = ({ headerImg, refreshedImage }) => {
  const userDispatchContextVal = useContext(userDispatchContext);
  const userContextVal = useContext(userContext);
  const statusContextVal = JSON.parse(useContext(statusContext));
  const switchStatusDispatchContextVal = useContext(
    switchStatusDispatchContext
  );
  const archivedStatusDispatchContextVal = useContext(
    archivedStatusDispatchContext
  );
  const apiVersion = useContext(AppVersionContext);
  const { showWarning } = useToast();
  let statusDispatchContextVal = useContext(statusDispatchContext);
  let API_URL = process.env.REACT_APP_API_URL;
  const pathname = window.location.pathname;
  const location = useLocation();
  let searchKey = location.state ? location.state.key : "";
  const navigate = useNavigate();
  let params = useParams();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElSettings, setAnchorElSettings] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const { userImage } = useUser();
  const [roles, setRoles] = useState([]);
  const handleOpenSettingsMenu = (event) => {
    setSelectedIndex("");
    setAnchorElSettings(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSettingsMenu = () => {
    setAnchorElSettings(null);
    setAnchorEl(null);
  };
  const redirectPath = () => {
    Services.logout().then((res) => {
      if (res?.data?.status === "success") {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href =
          "https://" +
          COGNITO_DOMAIN +
          "/logout?client_id=" +
          CONGNITO_APP_CLIENT_ID +
          "&logout_uri=" +
          REDIRECT_URL;
      }
    });
  };

  const [allData, setAllData] = useState();
  async function getHeaderData() {
    const companyId = localStorage.getItem("companyId");
    Services.getHeaderData({ companyId: companyId })
      .then((res) => {
        const userActive = res?.data[0]?.isExist && res?.data[0]?.isExist;
        if (userActive === true) {
          setAllData(res.data[0]);
          localStorage.setItem("companyname", res.data[0]?.companyName);
          localStorage.setItem("userid", res.data[0]?.userId);
          if (
            res?.data[0]?.profileImage !== null &&
            res?.data[0]?.profileImage !== undefined
          ) {
            setImgSrc(API_URL + res?.data[0]?.profileImage);
          } else {
            setImgSrc(defaultImg);
          }
        } else {
          showWarning(WarningMessages?.userNotActive);

          setTimeout(redirectPath, 2000);
        }
      })
      .catch((err) => {});
  }
  const getUserRoles = async () => {
    const userCompanyRes = await Services.getUserCompany();
    if (userCompanyRes.data.length > 1) {
      setRoles(userCompanyRes.data);
    }
  };

  const goToSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let role = localStorage.getItem("role");
      if (role === "ROLE_ADMIN") {
        navigate("/search", { state: { key: e.target.value.trim() } });
      } else if (role === "ROLE_LICENSEE") {
        navigate("/search", {
          state: {
            key: e.target.value.trim(),
            licenseeId: allData?.companyId,
          },
        });
      } else if (role === "ROLE_CHANNEL") {
        navigate("/search", {
          state: {
            key: e.target.value.trim(),
            channelId: allData?.companyId,
          },
        });
      } else if (role === "ROLE_USER") {
        navigate("/search", {
          state: {
            key: e.target.value.trim(),
            publisherId: allData?.companyId,
          },
        });
      }
    }
  };
  const companyDispatchContextVal = useContext(companyDispatchContext);
  const Redirect = (e) => {
    e.preventDefault();
    switchStatusDispatchContextVal(localStorage.getItem("status"));
    localStorage.setItem("archivedStatus", false);
    archivedStatusDispatchContextVal(false);
    navigate("/");
  };
  const [selectedIndex, setSelectedIndex] = React.useState();
  const redirectPage = (path, id) => {
    if (!accessPermissionFields()) {
      setSelectedIndex(id);
      localStorage.setItem("switchedUser", JSON.stringify(false));
      navigate(path);
      localStorage.setItem("companyType", "customer");
      companyDispatchContextVal("customer");
    }
  };
  const knowledgeUrl = () => {
    window.open("https://help.datafree.tech/hc/en-us");
  };

  const [imgPopup, setImgPopUp] = useState(false);
  const handleImgPop = () => {
    setImgPopUp(false);
  };
  const getImage = (e) => {
    setImgPopUp(true);
  };
  const [imgSrc, setImgSrc] = useState(
    userImage !== "" ? userImage : defaultImg
  );

  const refreshData = (imgValue) => {
    refreshedImage(imgValue);
    getUserRoles();
    setImgSrc(imgValue);
    userDispatchContextVal(true);
    handleCloseUserMenu();
    getHeaderData();
  };
  const [role, setRole] = useState();
  const getRoles = () => {
    const userRole = localStorage.getItem("role");
    if (userRole === "ROLE_ADMIN") {
      setRole("admin");
    } else if (userRole === "ROLE_LICENSEE") {
      setRole("licensee");
    } else if (userRole === "ROLE_CHANNEL") {
      setRole("channel");
    } else {
      setRole("customer");
    }
  };

  const switchRole = (companyId, role, status, userName) => {
    localStorage.setItem("role", role);
    localStorage.setItem("companyId", companyId);
    localStorage.setItem("username", userName);
    if (status === "Suspended") {
      localStorage.setItem("status", true);
      localStorage.setItem("switchStatus", true);
      localStorage.setItem("archivedStatus", false);
      switchStatusDispatchContextVal(true);
      statusDispatchContextVal(true);
      archivedStatusDispatchContextVal(false);
    } else {
      localStorage.setItem("status", false);
      localStorage.setItem("switchStatus", false);
      localStorage.setItem("archivedStatus", false);
      switchStatusDispatchContextVal(false);
      statusDispatchContextVal(false);
      archivedStatusDispatchContextVal(false);
    }

    window.location.href = "/";
  };
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };
  const manageAccount = (companyID) => {
    navigate("/accountsettings/", {
      state: { clickedCompId: companyID },
    });
  };

  useEffect(() => {
    getRoles();
    getUserRoles();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getHeaderData();
    userDispatchContextVal(false);
    //eslint-disable-next-line
  }, [userContextVal]);

  const switchedUser = JSON.parse(localStorage.getItem("switchedUser"));
  useEffect(() => {
    if (!switchedUser) {
      setAnchorElSettings(null);
    }
    //eslint-disable-next-line
  }, [switchedUser]);

  useEffect(() => {
    getHeaderData();
    //eslint-disable-next-line
  }, [userImage]);

  return (
    <AppBar
      sx={{
        position: "sticky",
        zIndex: 999,
      }}
    >
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              flex={1}
              variant="h6"
              nowrap="true"
              sx={{
                display: { xs: "flex", md: "flex" },
                fontWeight: 700,
                textDecoration: "none",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  cursor: "pointer",
                }}
                onClick={Redirect}
              >
                <img
                  component="img"
                  style={{
                    display: { xs: "none", md: "block" },
                    height: 24,
                    width: 120,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                    mr: 1,
                  }}
                  alt="Datafree"
                  src={Logo}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                  cursor: "pointer",
                }}
                onClick={Redirect}
                style={{
                  height: "40px",
                }}
              >
                <img
                  component="img"
                  style={{
                    display: { xs: "none", md: "block" },
                  }}
                  width="40px"
                  heigt="40px"
                  alt="Datafree"
                  src={AvatarLogo}
                />
              </Box>
            </Box>
            {(pathname !== "/" ||
              localStorage.getItem("role") === "ROLE_USER") &&
            pathname !== `/licensee/${params.compId ? params.compId : ""}` &&
            pathname !== `/channel/${params.compId ? params.compId : ""}` &&
            pathname !==
              `/licensee/channel/${params.compId ? params.compId : ""}` &&
            pathname !== "/reporting" ? (
              <Box
                flex={1}
                style={{
                  background: "#01537A",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "flex" },
                }}
              >
                <Box
                  sx={{
                    maxWidth: { xs: "100%", md: "75%", lg: "720px" },
                    width: "100%",
                    fontSize: "34px",
                    display: "flex",
                    flex: "0 0 720px",
                  }}
                >
                  <Paper
                    component="form"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: { xs: "300px", md: "400px", lg: "640px" },
                      marginRight: { xs: "60px" },
                      height: "38px",
                      borderRadius: "20px !important",
                    }}
                  >
                    <IconButton sx={{ p: "10px" }} aria-label="menu">
                      <SearchIcon
                        style={{
                          color: "#000",
                        }}
                        sx={{
                          fontSize: { xs: "25px", md: "25px" },
                        }}
                      />
                    </IconButton>
                    <InputBase
                      width="100%"
                      style={{ ml: 1, flex: 1, color: "#373737" }}
                      placeholder={
                        localStorage.getItem("role") === "ROLE_USER" ||
                        pathname.includes("/customer")
                          ? "Search for applications or users"
                          : "Search for apps, users or companies"
                      }
                      onKeyDown={goToSearch}
                      id="searchKey"
                      defaultValue={searchKey ? searchKey : ""}
                    ></InputBase>
                  </Paper>
                </Box>
              </Box>
            ) : (
              ""
            )}
            <Box
              flex={1}
              justifyContent="flex-end"
              display={{ xs: "flex", md: "flex" }}
              alignItems="center"
            >
              <Box sx={{ flexShrink: 0, display: { xs: "flex", md: "flex" } }}>
                <Box
                  component="img"
                  sx={{
                    height: 22,
                    width: 22,
                    margin: "5px 0px",
                  }}
                  alt="Dataf-ree"
                  src={Reporting}
                />
                <Typography
                  onClick={() => redirectPage("/reporting", 1)}
                  sx={{
                    fontSize: "14px !important",
                    margin: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  VIEW REPORTING
                </Typography>
                {/* add */}
                <Box />
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : false}
                  variant="contained"
                  disableElevation
                  onClick={handleOpenSettingsMenu}
                  endIcon={
                    open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                  }
                  startIcon={<AddIcon />}
                  sx={{
                    backgroundColor: open ? "#0094cd40" : "unset",
                    borderLeft: "1px solid white",
                    borderRight: "1px solid white",
                    padding: "0px 20px 0px 15px",
                    borderRadius: "unset",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "unset",
                    },
                    endIcon: {
                      "& .MuiButton-endIcon": {
                        marginLeft: "15px !important",
                      },
                    },
                  }}
                >
                  Add
                </Button>
                {role === "customer" ? (
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElSettings}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElSettings)}
                    onClose={handleCloseSettingsMenu}
                  >
                    {PublisheraddMenu.map((items, index) => (
                      <MenuItem
                        onClick={() => redirectPage(items.path, items.id)}
                        key={index}
                        selected={items.id === selectedIndex}
                        sx={{
                          cursor: !accessPermissionFields()
                            ? "pointer"
                            : "default",
                        }}
                      >
                        <Box
                          style={{
                            position: "relative",
                            background: "transparent",
                            color: "transparent",
                            fontSize: "10px",
                            display: "flex",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            alt="Datafree"
                            src={items.imageIcon}
                            width="20"
                            height="20"
                          />{" "}
                        </Box>
                        <Typography textAlign="center">
                          {items.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                ) : (
                  <Menu
                    sx={{ mt: "50px" }}
                    id="menu-appbar"
                    anchorEl={anchorElSettings}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElSettings)}
                    onClose={handleCloseSettingsMenu}
                  >
                    {addMenu.map((items, index) => (
                      <MenuItem
                        onClick={() => redirectPage(items.path, items.id)}
                        key={index}
                        selected={items.id === selectedIndex}
                        sx={{ margin: "7px 0px" }}
                      >
                        <Box
                          style={{
                            position: "relative",
                            background: "transparent",
                            color: "transparent",
                            fontSize: "10px",
                            display: "flex",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            alt="Datafree"
                            src={items.imageIcon}
                            width="20"
                            height="20"
                          />{" "}
                        </Box>
                        <Typography textAlign="center">
                          {items.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                )}

                {/* end menu */}
                <Box
                  component="img"
                  sx={{
                    height: 30,
                    width: 50,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                    mr: 1,
                    cursor: "pointer",
                  }}
                  alt="Datafree"
                  src={Knowledge}
                  onClick={knowledgeUrl}
                />
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="Datafree"
                    src={
                      allData?.profileImage !== null &&
                      allData?.profileImage !== undefined
                        ? imgSrc
                        : defaultImg
                    }
                  />
                </IconButton>
                <Menu
                  padding="0px"
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Box padding="15px 25px">
                    <Box align="center">
                      <Box>
                        <Box
                          style={{
                            position: "relative",
                            margin: "auto",
                            width: "84px",
                            height: "84px",
                          }}
                        >
                          <Box
                            style={{
                              position: "relative",
                              background: "#fff",
                              width: "84px",
                              height: "84px",
                              borderRadius: "50px",
                              color: "#fff",
                              fontSize: "34px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid #757373",
                            }}
                          >
                            {allData?.profileImage !== null &&
                            allData?.profileImage !== undefined ? (
                              <Avatar
                                src={imgSrc}
                                alt="Datafree"
                                sx={{ width: "73px", height: "73px" }}
                              />
                            ) : (
                              <img
                                alt="Datafree"
                                src={defaultImg}
                                rounded="lg"
                                height="52px"
                                width="52px"
                              />
                            )}
                          </Box>
                          <IconButton
                            style={{
                              position: "absolute",
                              bottom: "3px",
                              right: "2px",
                              background: "#5A5656",
                              color: "#fff",
                              fontSize: "14px",
                              width: "20px",
                              height: "20px",
                            }}
                            className={statusContextVal ? "disable" : null}
                            onClick={getImage}
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            <CameraAltOutlinedIcon fontSize="14px" />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box marginTop="20px">
                        <Typography
                          style={{
                            textAlign: "center",
                            color: "#373737",
                            fontSize: "20px",
                            textTransform: "Capitalize",
                          }}
                        >
                          {allData
                            ? allData?.role === "ROLE_ADMIN"
                              ? "Admin"
                              : allData?.role === "ROLE_CHANNEL"
                              ? "Channel"
                              : allData?.role === "ROLE_LICENSEE"
                              ? "Licensee"
                              : allData?.role === "ROLE_USER"
                              ? "User"
                              : ""
                            : ""}{" "}
                          {" : "}
                          {allData ? allData?.userName : ""}
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            color: "#757373",
                            fontSize: "11px",
                            textTransform: "lowercase",
                          }}
                        >
                          {allData ? allData?.email : ""}
                        </Typography>
                        {localStorage.getItem("role") !== "ROLE_ADMIN" && (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              onClick={() =>
                                manageAccount(localStorage.getItem("companyId"))
                              }
                              variant="outlined"
                              style={{
                                border: "1px solid #01537A",
                                color: "#01537A",
                                fontSize: "14px",
                                textTransform: "capitalize",
                                marginTop: "15px",
                              }}
                            >
                              Manage your Account
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {roles && roles.length > 0
                    ? roles.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: { xs: "200", md: "300px" },

                            borderTop: "1px solid #01537A",
                            fontSize: "12px",
                            textTransform: "capitalize",
                            padding: "0px 0 5px 0",
                            cursor:
                              item?.companyStatus !== "Archived" &&
                              parseInt(
                                localStorage.getItem("companyId"),
                                10
                              ) !== item?.companyId
                                ? "pointer"
                                : "default",
                          }}
                        >
                          <Box
                            onClick={
                              item?.companyStatus !== "Archived" &&
                              parseInt(
                                localStorage.getItem("companyId"),
                                10
                              ) !== item?.companyId
                                ? () =>
                                    switchRole(
                                      item?.companyId,
                                      item.role,
                                      item?.companyStatus,
                                      item?.userName
                                    )
                                : null
                            }
                            sx={{
                              display: "flex",
                              padding: "5px 0px 5px 20px",
                            }}
                          >
                            <Box
                              sx={{
                                background: "white",
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                borderRadius: "50px",
                                width: "50px",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Avatar
                                alt="Datafree"
                                src={
                                  item?.logo !== null
                                    ? API_URL + item?.logo
                                    : AvatarLogo
                                }
                              />
                            </Box>

                            <Box
                              sx={{
                                justifyContent: "center",
                                display: "flex",
                                flexDirection: "column",
                                padding: "0px 0px 0px 10px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "500",
                                  fontSize: "13px",
                                  lineHeight: "19px",
                                  color: "#23262F",
                                }}
                              >
                                {item.companyName}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  lineHeight: "13px",
                                  fontWeight: "400",
                                  color: "#757373",
                                  textTransform: "capitalize !important",
                                }}
                                variant="capitalize"
                              >
                                {item.role === "ROLE_USER"
                                  ? "Customer"
                                  : item.role.toLowerCase().split("_")[1]}{" "}
                                User
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  color: " #757373",
                                  textTransform: "capitalize",
                                }}
                              >
                                <span
                                  style={{ fontSize: "13px", color: "#373737" }}
                                >
                                  Status:
                                </span>{" "}
                                {item?.companyStatus}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    : ""}
                  <Box
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid #01537A",
                      color: "#0094CD",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      textDecoration: "none",
                      padding: "8px 0 5px 0",
                    }}
                    onClick={redirectPath}
                  >
                    <Typography sx={{ fontSize: "13px", cursor: "pointer" }}>
                      Sign out
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      color: "#0094CD",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      textDecoration: "none",
                      padding: "8px 25px 5px 25px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px", color: "black" }}>
                        UI version:
                      </Typography>
                      <Typography sx={{ fontSize: "13px", color: "black" }}>
                        {APP_VERSION ? APP_VERSION : ""}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px", color: "black" }}>
                        API Version:
                      </Typography>
                      <Typography sx={{ fontSize: "13px", color: "black" }}>
                        {apiVersion ? apiVersion : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
        {imgPopup ? (
          <LogoPopUp
            open={imgPopup}
            handleClose={handleImgPop}
            imgs={imgSrc}
            defaultImg={defaultImg}
            compId={localStorage.getItem("userid")}
            refreshData={refreshData}
            uploadType={"user"}
          />
        ) : (
          ""
        )}
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
