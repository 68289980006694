import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StackedLineChart from "./stackedLineChart";
import Loader from "../../components/loader";
import Tooltips from "../../components/tooltips";
import BarChart from "./barChart";
import TimeSeriesStackChart from "./timeSeriesStackChart";
import TimeSeriesLineChart from "./timeSeriesLineChart";
export default function ChartView({
  data,
  resData,
  loading,
  role,
  companyName,
  range,
  startDate,
  endDate,
}) {
  return (
    <Box
      sx={{
        width: { xs: "70%", md: "70%", lg: "72%", xl: "81%" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "20px",
          }}
        >
          {data?.application === "all" ? (
            <>
              Data usage for all apps in{" "}
              <span style={{ color: "#0094CD", textTransform: "capitalize" }}>
                {role === "ROLE_USER"
                  ? companyName
                    ? companyName
                    : ""
                  : data?.publisher?.label
                  ? data?.publisher?.label
                  : ""}
              </span>
            </>
          ) : (
            <>
              Report for{" "}
              <span style={{ color: "#0094CD", textTransform: "capitalize" }}>
                {data?.selectApplication?.label
                  ? data?.selectApplication?.label
                  : ""}
              </span>
            </>
          )}
        </Typography>
      </Box>
      {resData?.resultData && !loading ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          {data?.application === "all" ? (
            data?.dataUsage === "RANGE" ? (
              <>
                <Box
                  sx={{
                    display: "grid",
                    marginBottom: "10px",
                    columnGap: {
                      xs: "2rem",
                      md: "1rem",
                      lg: "8px",
                      xl: "4rem",
                    },
                    rowGap: { xs: "2rem", lg: "1rem" },
                    gridTemplateColumns: {
                      xs: "auto",
                      sm: "repeat(2, 1fr)",
                      md: "repeat(2, 1fr)",
                    },
                    gridTemplateRows: "repeat(1, 1fr)",
                  }}
                >
                  <Card
                    sx={{
                      minWidth: {
                        xs: "187px",
                        sm: "100px",
                        md: "108px",
                        lg: "113px",
                        xl: "187px",
                      },
                      minHeight: "70px",
                      height: "73px",
                      background: "#FFFFFF",
                      border: "1px solid rgba(0, 148, 205, 1)",
                      boxShadow: " 4px 4px 12px 0px #00000026",
                    }}
                  >
                    <CardContent
                      align="center"
                      sx={{
                        padding: "0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "35px", md: "32px", xl: "35px" },
                          color: "#01537A",
                          lineHeight: "48px",
                          fontWeight: "700",
                        }}
                      >
                        {!resData?.data[0]?.totaldatausage
                          ? 0
                          : resData?.data[0]?.totaldatausage}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",

                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#01537A",
                            fontSize: { xs: "14px", md: "12px", xl: "14px" },
                            lineHeight: "22px",
                          }}
                        >
                          Total Data Usage (MB)
                        </Typography>
                        <Tooltips type="totalDataUsage">
                          <InfoOutlinedIcon
                            sx={{
                              color: "#01537A",
                              fontSize: "20px",
                              marginLeft: "3px",
                            }}
                          />
                        </Tooltips>
                      </Box>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      minWidth: {
                        xs: "187px",
                        sm: "100px",
                        md: "108px",
                        lg: "113px",
                        xl: "187px",
                      },
                      minHeight: "70px",
                      height: "73px",
                      background: "#FFFFFF",
                      border: "1px solid rgba(0, 148, 205, 1)",
                      boxShadow: " 4px 4px 12px 0px #00000026",
                    }}
                  >
                    <CardContent
                      align="center"
                      sx={{
                        padding: "0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "35px", md: "32px", xl: "35px" },
                          color: "#01537A",
                          lineHeight: "48px",
                          fontWeight: "700",
                        }}
                      >
                        {!resData?.data[0]?.datafreedatausage
                          ? 0
                          : resData?.data[0]?.datafreedatausage}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",

                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#01537A",
                            fontSize: { xs: "14px", md: "12px", xl: "14px" },
                            lineHeight: "22px",
                          }}
                        >
                          Total Datafree Data Usage (MB)
                        </Typography>
                        <Tooltips type="dataFreeUsage">
                          <InfoOutlinedIcon
                            sx={{
                              color: "#01537A",
                              fontSize: "20px",
                              marginLeft: "3px",
                            }}
                          />
                        </Tooltips>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
                <Box
                  sx={{
                    boxShadow: "0px 1px 4px 0px #00000040",
                    marginBottom: "40px",
                    borderRadius: "6.17px",

                    height:
                      resData?.resultData?.length === 0
                        ? "388px"
                        : "fit-content",
                  }}
                >
                  <TimeSeriesStackChart
                    heading={"Data Usage by Type"}
                    yAxis={"Data Usage (MB)"}
                    xAxis={"Day of Month"}
                    resData={resData}
                    type="allRange"
                    range={range}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Box>
              </>
            ) : data?.dataUsage === "TODAY" ? (
              <>
                <Box
                  sx={{
                    display: "grid",
                    columnGap: {
                      xs: "2rem",
                      md: "1rem",
                      lg: "8px",
                      xl: "4rem",
                    },
                    rowGap: { xs: "2rem", lg: "1rem" },
                    gridTemplateColumns: {
                      xs: "auto",
                      sm: "repeat(4, 1fr)",
                      md: "repeat(4, 1fr)",
                    },
                    gridTemplateRows: "repeat(1, 120px)",
                  }}
                >
                  <Card
                    sx={{
                      minWidth: {
                        xs: "187px",
                        sm: "100px",
                        md: "108px",
                        lg: "113px",
                        xl: "187px",
                      },
                      minHeight: "70px",
                      height: "73px",
                      background: "#FFFFFF",
                      border: "1px solid rgba(0, 148, 205, 1)",
                      boxShadow: " 4px 4px 12px 0px #00000026",
                    }}
                  >
                    <CardContent
                      align="center"
                      sx={{
                        padding: "0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "35px", md: "32px", xl: "35px" },
                          color: "#01537A",
                          lineHeight: "48px",
                          fontWeight: "700",
                        }}
                      >
                        {!resData?.data[0]?.datafreedownload
                          ? 0
                          : resData?.data[0]?.datafreedownload}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",

                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#01537A",
                            fontSize: { xs: "14px", md: "12px", xl: "14px" },
                            lineHeight: "22px",
                          }}
                        >
                          Total Datafree Download (MB)
                        </Typography>
                        <Tooltips type="totalDataFreeDownload">
                          <InfoOutlinedIcon
                            sx={{
                              color: "#01537A",
                              fontSize: "20px",
                              marginLeft: "3px",
                            }}
                          />
                        </Tooltips>
                      </Box>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      minWidth: {
                        xs: "187px",
                        sm: "100px",
                        md: "108px",
                        lg: "113px",
                        xl: "187px",
                      },
                      minHeight: "70px",
                      height: "73px",
                      background: "#FFFFFF",
                      border: "1px solid rgba(0, 148, 205, 1)",
                      boxShadow: " 4px 4px 12px 0px #00000026",
                    }}
                  >
                    <CardContent
                      align="center"
                      sx={{
                        padding: "0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "35px", md: "32px", xl: "35px" },
                          color: "#01537A",
                          lineHeight: "48px",
                          fontWeight: "700",
                        }}
                      >
                        {!resData?.data[0]?.totaldownload
                          ? 0
                          : resData?.data[0]?.totaldownload}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",

                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#01537A",
                            fontSize: { xs: "14px", md: "12px", xl: "14px" },
                            lineHeight: "22px",
                          }}
                        >
                          Total Download (MB)
                        </Typography>
                        <Tooltips type="totalDownload">
                          <InfoOutlinedIcon
                            sx={{
                              color: "#01537A",
                              fontSize: "20px",
                              marginLeft: "3px",
                            }}
                          />
                        </Tooltips>
                      </Box>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      minWidth: {
                        xs: "187px",
                        sm: "100px",
                        md: "108px",
                        lg: "113px",
                        xl: "187px",
                      },
                      minHeight: "70px",
                      height: "73px",
                      background: "#FFFFFF",
                      border: "1px solid rgba(0, 148, 205, 1)",
                      boxShadow: " 4px 4px 12px 0px #00000026",
                    }}
                  >
                    <CardContent
                      align="center"
                      sx={{
                        padding: "0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "35px", md: "32px", xl: "35px" },
                          color: "#01537A",
                          lineHeight: "48px",
                          fontWeight: "700",
                        }}
                      >
                        {!resData?.data[0]?.datafreeupload
                          ? 0
                          : resData?.data[0]?.datafreeupload}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",

                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#01537A",
                            fontSize: { xs: "14px", md: "12px", xl: "14px" },
                            lineHeight: "22px",
                          }}
                        >
                          Total Datafree Upload (MB)
                        </Typography>
                        <Tooltips type="totalDatafreeUpload">
                          <InfoOutlinedIcon
                            sx={{
                              color: "#01537A",
                              fontSize: "20px",
                              marginLeft: "3px",
                            }}
                          />
                        </Tooltips>
                      </Box>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      minWidth: {
                        xs: "187px",
                        sm: "100px",
                        md: "108px",
                        lg: "113px",
                        xl: "187px",
                      },
                      minHeight: "70px",
                      height: "73px",
                      background: "#FFFFFF",
                      border: "1px solid rgba(0, 148, 205, 1)",
                      boxShadow: " 4px 4px 12px 0px #00000026",
                    }}
                  >
                    <CardContent
                      align="center"
                      sx={{
                        padding: "0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "35px", md: "32px", xl: "35px" },
                          color: "#01537A",
                          lineHeight: "48px",
                          fontWeight: "700",
                        }}
                      >
                        {!resData?.data[0]?.totalupload
                          ? 0
                          : resData?.data[0]?.totalupload}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",

                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#01537A",
                            fontSize: { xs: "14px", md: "12px", xl: "14px" },
                            lineHeight: "22px",
                          }}
                        >
                          Total Upload (MB)
                        </Typography>
                        <Tooltips type="totalUpload">
                          <InfoOutlinedIcon
                            sx={{
                              color: "#01537A",
                              fontSize: "20px",
                              marginLeft: "3px",
                            }}
                          />
                        </Tooltips>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
                <Box
                  sx={{
                    boxShadow: "0px 1px 4px 0px #00000040",
                    marginBottom: "40px",
                    borderRadius: "6.17px",
                  }}
                >
                  <StackedLineChart
                    heading={"Data Usage per Hour Type"}
                    yAxis={"Data Usage"}
                    xAxis={"Hour of Day"}
                    resData={resData}
                    type="allToday"
                  />
                </Box>
              </>
            ) : (
              ""
            )
          ) : (
            <>
              {data?.dataUsage === "RANGE" ? (
                <>
                  <Box>
                    <Box
                      sx={{
                        display: "grid",
                        marginBottom: "10px",
                        columnGap: {
                          xs: "2rem",
                          md: "1rem",
                          lg: "8px",
                          xl: "4rem",
                        },
                        rowGap: { xs: "2rem", lg: "1rem" },
                        gridTemplateColumns: {
                          xs: "auto",
                          sm: "repeat(2, 1fr)",
                          md: "repeat(2, 1fr)",
                        },
                        gridTemplateRows: "repeat(1, 1fr)",
                      }}
                      >
                         <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.data[0]?.totaldatausage
                              ? 0
                              : resData?.data[0]?.totaldatausage}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Total Data Usage (MB)
                            </Typography>
                            <Tooltips type="totalDataUsage">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.data[0]?.datafreedatausage
                              ? 0
                              : resData?.data[0]?.datafreedatausage}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Total Datafree Data Usage (MB)
                            </Typography>
                            <Tooltips type="dataFreeUsage">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                     
                    </Box>
                    <Box
                      sx={{
                        boxShadow: "0px 1px 4px 0px #00000040",
                        marginBottom: "40px",
                        borderRadius: "6.17px",
                      }}
                    >
                      <TimeSeriesStackChart
                        heading={"Data Usage by Type"}
                        yAxis={"Data Usage (MB)"}
                        xAxis={"Day of Month"}
                        resData={resData}
                        type="singleRange1"
                        range={range}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "grid",
                        marginBottom: "10px",
                        columnGap: {
                          xs: "2rem",
                          md: "1rem",
                          lg: "8px",
                          xl: "4rem",
                        },
                        rowGap: { xs: "2rem", lg: "1rem" },
                        gridTemplateColumns: {
                          xs: "auto",
                          sm: "repeat(2, 1fr)",
                          md: "repeat(2, 1fr)",
                        },
                        gridTemplateRows: "repeat(1, 1fr)",
                      }}
                    >
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.userDataUsage?.avgUserDataUsage
                              ? 0
                              : resData?.userDataUsage?.avgUserDataUsage}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Average User Data Usage (MB)
                            </Typography>
                            <Tooltips type="avgUserData">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.userDataUsage?.totalUserDataUsage
                              ? 0
                              : resData?.userDataUsage?.totalUserDataUsage}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Total User Data Usage (MB)
                            </Typography>
                            <Tooltips type="totalUserDataUsage">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box
                      sx={{
                        boxShadow: "0px 1px 4px 0px #00000040",
                        marginBottom: "40px",
                        borderRadius: "6.17px",
                      }}
                    >
                      <TimeSeriesStackChart
                        heading={"User Datafree Data Usage"}
                        yAxis={"Datafree Data Usage  per User (MB)"}
                        xAxis={"Day of Month"}
                        resData={resData}
                        type={"singleRange2"}
                        range={range}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "grid",
                        marginBottom: "10px",
                        columnGap: {
                          xs: "2rem",
                          md: "1rem",
                          lg: "8px",
                          xl: "4rem",
                        },
                        rowGap: { xs: "2rem", lg: "1rem" },
                        gridTemplateColumns: {
                          xs: "auto",
                          sm: "repeat(3, 1fr)",
                          md: "repeat(3, 1fr)",
                        },
                        gridTemplateRows: "repeat(1, 1fr)",
                      }}
                    >
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.totalUsers?.totalUsersToday
                              ? 0
                              : resData?.totalUsers?.totalUsersToday}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Daily Active Users
                            </Typography>
                            <Tooltips type="dailyActiveUsers">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.totalUsers?.totalUsers7Days
                              ? 0
                              : resData?.totalUsers?.totalUsers7Days}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Weekly Active Users
                            </Typography>
                            <Tooltips type="weeklyActiveUsers">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.totalUsers?.totalUsers28Day
                              ? 0
                              : resData?.totalUsers?.totalUsers28Day}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Monthly Active Users
                            </Typography>
                            <Tooltips type="monthlyActiveUsers">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box
                      sx={{
                        boxShadow: "0px 1px 4px 0px #00000040",
                        marginBottom: "40px",
                        borderRadius: "6.17px",
                      }}
                    >
                      <TimeSeriesStackChart
                        heading={"Total Users"}
                        yAxis={"Total Users"}
                        xAxis={"Day of Month"}
                        resData={resData}
                        type={"singleRange3"}
                        range={range}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "grid",
                        marginBottom: "10px",
                        columnGap: {
                          xs: "2rem",
                          md: "1rem",
                          lg: "8px",
                          xl: "4rem",
                        },
                        rowGap: { xs: "2rem", lg: "1rem" },
                        gridTemplateColumns: {
                          xs: "auto",
                          sm: "repeat(3, 1fr)",
                          md: "repeat(3, 1fr)",
                        },
                        gridTemplateRows: "repeat(1, 1fr)",
                      }}
                    >
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.focusMinsReport?.avgFocusMins
                              ? 0
                              : resData?.focusMinsReport?.avgFocusMins}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Average Focus Time (min)
                            </Typography>
                            <Tooltips type="avgFocusTime">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.focusMinsReport?.maxFocusMins
                              ? 0
                              : resData?.focusMinsReport?.maxFocusMins}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Max Focus Time (min)
                            </Typography>
                            <Tooltips type="maxFocusTime">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.focusMinsReport?.minFocusMins
                              ? 0
                              : resData?.focusMinsReport?.minFocusMins}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Min Focus Time (min)
                            </Typography>
                            <Tooltips type="minFocusTime">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box
                      sx={{
                        boxShadow: "0px 1px 4px 0px #00000040",
                        marginBottom: "40px",
                        borderRadius: "6.17px",
                      }}
                    >
                      <TimeSeriesLineChart
                        heading={"Focus time minutes"}
                        yAxis={"Total Focus Time per User (min)"}
                        xAxis={"Date"}
                        resData={resData}
                        type={"focusTimeMin"}
                        range={range}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "grid",
                        marginBottom: "10px",
                        columnGap: {
                          xs: "2rem",
                          md: "1rem",
                          lg: "8px",
                          xl: "4rem",
                        },
                        rowGap: { xs: "2rem", lg: "1rem" },
                        gridTemplateColumns: {
                          xs: "auto",
                          sm: "repeat(2, 1fr)",
                          md: "repeat(2, 1fr)",
                        },
                        gridTemplateRows: "repeat(1, 1fr)",
                      }}
                    >
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.NavigationReportPerUser?.avgNavUses
                              ? 0
                              : resData?.NavigationReportPerUser?.avgNavUses}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Average Navigations
                            </Typography>
                            <Tooltips type="avgNavigation">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          minWidth: {
                            xs: "187px",
                            sm: "100px",
                            md: "108px",
                            lg: "113px",
                            xl: "187px",
                          },
                          minHeight: "70px",
                          height: "73px",
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 148, 205, 1)",
                          boxShadow: " 4px 4px 12px 0px #00000026",
                        }}
                      >
                        <CardContent
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "35px", md: "32px", xl: "35px" },
                              color: "#01537A",
                              lineHeight: "48px",
                              fontWeight: "700",
                            }}
                          >
                            {!resData?.NavigationReportPerUser?.totalNavUsers
                              ? 0
                              : resData?.NavigationReportPerUser?.totalNavUsers}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#01537A",
                                fontSize: {
                                  xs: "14px",
                                  md: "12px",
                                  xl: "14px",
                                },
                                lineHeight: "22px",
                              }}
                            >
                              Total Navigations
                            </Typography>
                            <Tooltips type="totalNavigation">
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#01537A",
                                  fontSize: "20px",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltips>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box
                      sx={{
                        boxShadow: "0px 1px 4px 0px #00000040",
                        marginBottom: "40px",
                        borderRadius: "6.17px",
                      }}
                    >
                      <BarChart
                        heading={"Total Navigations per user "}
                        yAxis={"Total Navigations"}
                        xAxis={"Date"}
                        resData={resData}
                        type={"totalNavUser"}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "grid",
                      columnGap: {
                        xs: "2rem",
                        md: "1rem",
                        lg: "8px",
                        xl: "4rem",
                      },
                      rowGap: { xs: "2rem", lg: "1rem" },
                      gridTemplateColumns: {
                        xs: "auto",
                        sm: "repeat(4, 1fr)",
                        md: "repeat(4, 1fr)",
                      },
                      gridTemplateRows: "repeat(1, 120px)",
                    }}
                  >
                    <Card
                      sx={{
                        minWidth: {
                          xs: "187px",
                          sm: "100px",
                          md: "108px",
                          lg: "113px",
                          xl: "187px",
                        },
                        minHeight: "70px",
                        height: "73px",
                        background: "#FFFFFF",
                        border: "1px solid rgba(0, 148, 205, 1)",
                        boxShadow: " 4px 4px 12px 0px #00000026",
                      }}
                    >
                      <CardContent
                        align="center"
                        sx={{
                          padding: "0px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "35px", md: "32px", xl: "35px" },
                            color: "#01537A",
                            lineHeight: "48px",
                            fontWeight: "700",
                          }}
                        >
                          {!resData?.data[0]?.datafreedownload
                            ? 0
                            : resData?.data[0]?.datafreedownload}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",

                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#01537A",
                              fontSize: { xs: "14px", md: "12px", xl: "14px" },
                              lineHeight: "22px",
                            }}
                          >
                            Total Datafree Download (MB)
                          </Typography>
                          <Tooltips type="totalDataFreeDownload">
                            <InfoOutlinedIcon
                              sx={{
                                color: "#01537A",
                                fontSize: "20px",
                                marginLeft: "3px",
                              }}
                            />
                          </Tooltips>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card
                      sx={{
                        minWidth: {
                          xs: "187px",
                          sm: "100px",
                          md: "108px",
                          lg: "113px",
                          xl: "187px",
                        },
                        minHeight: "70px",
                        height: "73px",
                        background: "#FFFFFF",
                        border: "1px solid rgba(0, 148, 205, 1)",
                        boxShadow: " 4px 4px 12px 0px #00000026",
                      }}
                    >
                      <CardContent
                        align="center"
                        sx={{
                          padding: "0px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "35px", md: "32px", xl: "35px" },
                            color: "#01537A",
                            lineHeight: "48px",
                            fontWeight: "700",
                          }}
                        >
                          {!resData?.data[0]?.totaldownload
                            ? 0
                            : resData?.data[0]?.totaldownload}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",

                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#01537A",
                              fontSize: { xs: "14px", md: "12px", xl: "14px" },
                              lineHeight: "22px",
                            }}
                          >
                            Total Download (MB)
                          </Typography>
                          <Tooltips type="totalDownload">
                            <InfoOutlinedIcon
                              sx={{
                                color: "#01537A",
                                fontSize: "20px",
                                marginLeft: "3px",
                              }}
                            />
                          </Tooltips>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card
                      sx={{
                        minWidth: {
                          xs: "187px",
                          sm: "100px",
                          md: "108px",
                          lg: "113px",
                          xl: "187px",
                        },
                        minHeight: "70px",
                        height: "73px",
                        background: "#FFFFFF",
                        border: "1px solid rgba(0, 148, 205, 1)",
                        boxShadow: " 4px 4px 12px 0px #00000026",
                      }}
                    >
                      <CardContent
                        align="center"
                        sx={{
                          padding: "0px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "35px", md: "32px", xl: "35px" },
                            color: "#01537A",
                            lineHeight: "48px",
                            fontWeight: "700",
                          }}
                        >
                          {!resData?.data[0]?.datafreeupload
                            ? 0
                            : resData?.data[0]?.datafreeupload}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",

                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#01537A",
                              fontSize: { xs: "14px", md: "12px", xl: "14px" },
                              lineHeight: "22px",
                            }}
                          >
                            Total Datafree Upload (MB)
                          </Typography>
                          <Tooltips type="totalDatafreeUpload">
                            <InfoOutlinedIcon
                              sx={{
                                color: "#01537A",
                                fontSize: "20px",
                                marginLeft: "3px",
                              }}
                            />
                          </Tooltips>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card
                      sx={{
                        minWidth: {
                          xs: "187px",
                          sm: "100px",
                          md: "108px",
                          lg: "113px",
                          xl: "187px",
                        },
                        minHeight: "70px",
                        height: "73px",
                        background: "#FFFFFF",
                        border: "1px solid rgba(0, 148, 205, 1)",
                        boxShadow: " 4px 4px 12px 0px #00000026",
                      }}
                    >
                      <CardContent
                        align="center"
                        sx={{
                          padding: "0px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "35px", md: "32px", xl: "35px" },
                            color: "#01537A",
                            lineHeight: "48px",
                            fontWeight: "700",
                          }}
                        >
                          {!resData?.data[0]?.totalupload
                            ? 0
                            : resData?.data[0]?.totalupload}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",

                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#01537A",
                              fontSize: { xs: "14px", md: "12px", xl: "14px" },
                              lineHeight: "22px",
                            }}
                          >
                            Total Upload (MB)
                          </Typography>
                          <Tooltips type="totalUpload">
                            <InfoOutlinedIcon
                              sx={{
                                color: "#01537A",
                                fontSize: "20px",
                                marginLeft: "3px",
                              }}
                            />
                          </Tooltips>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                  <Box
                    sx={{
                      boxShadow: "0px 1px 4px 0px #00000040",
                      marginBottom: "40px",
                      borderRadius: "6.17px",
                    }}
                  >
                    <StackedLineChart
                      heading={"Data Usage per Hour Type"}
                      yAxis={"Data Usage"}
                      xAxis={"Hour of Day"}
                      resData={resData}
                      type="allToday"
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Loader
            type={"spokes"}
            color={"#01537A"}
            delay={0}
            height={40}
            width={40}
          />
        </Box>
      )}
    </Box>
  );
}
