import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box, Typography, Divider, Paper } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ReactApexChart from "react-apexcharts";

import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function GroupedBarChart({ resData }) {
  const labels = resData
    ? resData.map((item) => moment(item.month).format("MMM 'YY"))
    : [];
  const option = {
    tooltip: {
      enabled: true,

      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let total = 0;
        let yAxis = "";
        let content = "";

        const xAxisLabel = resData
          ? resData.map((item) => moment(item.month).format("MMMM YYYY"))
          : [];

        const label = xAxisLabel[dataPointIndex];
        const dateLabel = label;

        content += `<div class="header-box"><span style= "font-size: 16px;"> Summary</span><span  style="font-weight:600 ;font-size: 16px;"> :</span><span  style="font-weight:600 ;margin-left:5px ;font-size: 16px;">${dateLabel}</span><br></div>`;

        series.forEach((s, index) => {
          const name = w.globals.seriesNames[index];
          const value = s[dataPointIndex];
          const color = w.globals.colors[index];
          total += value;
          yAxis += `<div style="display: flex; justify-content: space-between; align-items: center;  width: calc(100% - 10px);;">

          <div  style="display: flex;">
          <div style="color:${color};font-size: 30px;">&#x25CF;</div>
           <div style="font-size: 16px;margin-top:10px">${name}</div>
           </div>
           <div style="font-size: 16px;margin-top:5px "> ${
             isNaN(value) ? 0 : value
           }<br>
           </div>
           </div>`;
        });

        content += `<div class="middle-box">${yAxis}</div>`;
        content += `<div class="footer-box"><span style="font-size: 16px;">Total </span><span style="font-size: 16px; margin-left:17px"> ${
          isNaN(total) ? 0 : total
        }</span></div>`;

        return '<div class="custom-tooltip">' + content + "</div>";
      },
    },

    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    colors: ["#4CAF50", "#FB8C00", "#0094CD", "#01537A"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: "Summary Monthly Report",
      align: "left",
      style: {
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Roboto",
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    legend: {
      showForSingleSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      onItemHover: {
        highlightDataSeries: false
      },
      onItemClick: {
        toggleDataSeries: false, // Prevents series from being toggled
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    grid: {
      show: true,
      borderColor: "#D3D6DF",
      strokeDashArray: 7,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    yaxis: {
      show: true,
      showAlways: false,
      showForNullSeries: true,
      seriesName: undefined,
      opposite: false,
      reversed: false,
      logarithmic: false,
      logBase: 10,
      tickAmount: undefined,
      forceNiceScale: false,
      floating: false,
      decimalsInFloat: undefined,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "12px",
          fontWeight: 300,
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
      },
      axisBorder: {
        show: false,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      title: {
        text: "Data Usage (MB)",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      crosshairs: {
        show: false,
        position: "back",
        stroke: {
          color: "#b6b6b6",
          width: 1,
          dashArray: 0,
        },
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
    },
    xaxis: {
      type: "category",
      categories: labels,

      title: {
        text: "Month",
        offsetX: 0,
        offsetY: 0,
        style: {
          padding: 4,
          color: undefined,
          fontSize: "12px",
          fontWeight: 600,
        },
      },
      labels: {
        style: {
          colors: [],
          fontSize: "12px",
          fontWeight: 700,
        },
      },
      axisBorder: {
        show: true,
        color: "#B1AEAE",
        height: 1,
        width: "100%",
      },
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        height: 6,
      },
    },
  };

  const series = [
    {
      name: "Datafree ",
      data: resData
        ? resData.map((item) => parseInt(item.totaldatafreeMB))
        : [],
    },
    {
      name: "Paid",
      data: resData ? resData.map((item) => parseInt(item.totalPaidMB)) : [],
    },
    {
      name: "Wifi",
      data: resData ? resData.map((item) => parseInt(item.totalWiFiMB)) : [],
    },

    {
      name: "Other",
      data: resData
        ? resData.map((item) => parseInt(item.totalUnclassifiedMB))
        : [],
    },
  ];
  return (
    <>
      <Paper
        sx={{
          padding: "10px",
        }}
      >
        {resData?.length !== 0 ? (
          <Box sx={{ height: { lg: "290px", xl: "500px" } }}>
            <ReactApexChart
              options={option}
              series={series}
              type="bar"
              height="100%"
              width="100%"
            />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  letterSpacing: "0px",
                }}
              >
                Summary Monthly Report
              </Typography>

              <MenuIcon
                sx={{
                  color: "#6e8192",

                  fontSize: "21px",
                }}
              />
            </Box>

            <Divider sx={{ marginBottom: "20px" }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
                padding: "1rem",
                color: "#5A5656",
                height: "285px",
                width: "100%",
              }}
            >
              No reports to show
            </Box>
          </>
        )}
      </Paper>
    </>
  );
}
