import React from 'react'
import DialogActions from "@mui/material/DialogActions";
import { Divider,  Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function GoogleAppIdPopUp({
    googleAppIdPopUp,
    googleAppIdPopUpClose,
    platform,
    googleAppId
  
}) {
  return (
    <Dialog
    open={googleAppIdPopUp}
    maxWidth="xl"
    scroll={"body"}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          fontWeight: "400",
          color: "#01537A",
          fontSize: "24px",
        }}
      >
        {" "}
        Information
      </Typography>

      <CloseOutlinedIcon
        onClick={googleAppIdPopUpClose}
        sx={{ color: "#757373", fontSize: "20px", cursor: "pointer" }}
      />
    </DialogTitle>
    <DialogContent>
{!platform && !googleAppId ?(<Typography
        sx={{
          fontWeight: "400",
          fontSize: "16px",
          color: "#333333",
          marginBottom: "30px",
          marginTop: "10px",
        }}
      >
       You cannot build a Production APK until you have specified the Google App Id and Datafree platform{'('}Version Tag{')'}.
      </Typography>):!platform ?(<Typography
        sx={{
          fontWeight: "400",
          fontSize: "16px",
          color: "#333333",
          marginBottom: "30px",
          marginTop: "10px",
        }}
      >
       You cannot build a Production APK until you have specified the Datafree platform{'('}Version Tag{')'}.
      </Typography>):!googleAppId?(<Typography
        sx={{
          fontWeight: "400",
          fontSize: "16px",
          color: "#333333",
          marginBottom: "30px",
          marginTop: "10px",
        }}
      >
       You cannot build a Production APK until you have specified the Google App Id.
      </Typography>):""}
      

      <Divider
        sx={{
          color: "#DFDFDF",
          backgroundColor: "#FFFFFF",
          borderRadius: "6px",
          borderBottomWidth: 1,
          marginTop: "20px",
        }}
      ></Divider>
    </DialogContent>
    <DialogActions
      sx={{
        paddingBottom: "20px",
        paddingRight: "30px",
        justifyContent:"center"
      }}
    >
      <Button
        onClick={googleAppIdPopUpClose}
        sx={{
          padding: " 5px 50px 5px 50px",
          width: "fit-content",
          color: "#01537A",
          borderColor: "#01537A !important",
          textTransform: "capitalize",
        }}
        variant="outlined"
      >
        OK
      </Button>

     
    </DialogActions>
  </Dialog>
  )
}
