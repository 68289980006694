import { createTheme } from "@mui/material/styles";



export const theme = createTheme({


 
  
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(1, 83, 122, 0.1)",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          boxes: {
            backgroundColor: "red",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#01537A",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
        outlined:{
          width:"164px",
        },
        standard: {
          backgroundColor: 'transparent',
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    }, 
    MuiInput:{
      styleOverrides: {
        root:{
          '&:hover:not(.Mui-disabled)::before' :{
            borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important'
        }
     
      }
    }
    } 

  },
});


