import React, { useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useState } from "react";
import AddRole from "./addRole";
import DataTable from "../AccountSettings/dataTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import Services from "../../api/services";
import { useParams, useLocation } from "react-router-dom";
import useResponsive from "../useResponsive";
import Loader from "../../components/loader";
import PopUp from "../AccountSettings/popUp";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { userRoleContext } from "../../context/serviceContext";
import {
  switchStatusContext,
  statusContext,
  archivedStatusContext,
} from "../../context/serviceContext";
import "../../App.css";
import { WarningMessages } from "../../config/messages";
import useToast from "../../hooks/useToast";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    maxWidth: "100%",
    height: "auto",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "0px 2.68852px 2.68852px rgba(0, 0, 0, 0.25)",
    borderRadius: "3px",
  },
}));
const StackBox = styled(Box)({
  padding: "10px",
});
const Stack2 = styled(Typography)({
  color: "#373737",
  fontWeight: "400",
  fontSize: "16px",
  width: "200px",
  height: "24px",
  lineHeight: "24px",
});

const Stack1 = styled(Typography)({
  color: "#01537A",
  fontWeight: "400",
  fontSize: "12px",
  letterSpacing: "0.4px",
  lineHeight: "24px",
  width: "150px",
  top: "5.5px",
});
const textStyle = {
  color: "#0094CD",
  fontWeight: 500,
  width: "37px",
  height: "15px",
  cursor: "pointer",
};
const textStyle2 = {
  color: "#231F20",
  fontWeight: "400",
  fontSize: "14px",
  width: "61px",
  height: "15px",
};
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    sx: {
      boxShadow: 2,
      border: "none",
      maxWidth: "150px",
      color: "#000 !important",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderTopRightRadius: "6px",
      borderTopLeftRadius: "6px",
      marginTop: "10px",
      "& .Mui-focused ": {
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
        color: "#000 !important",
      },
      "& .MuiMenuItem-root": {
        color: "#000",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        fontSize: "16px",
        backgroundColor: "transparent",
        padding: "5px",
      },
      "& .MuiMenuItem-root: last-child": {
        color: "#000",
        borderBottom: "none",
        fontSize: "16px",
        backgroundColor: "#fff",
        "&:focus": {
          color: "#01537A",
        },
      },
      "& .Mui-selected:hover": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .Mui-selected": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .MuiBox-root:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        borderRadius: "6px",
        borderBottom: "none",
      },
      "& .MuiBox-root": {
        padding: "8px 10px",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: 2,
        paddingTop: "15px",
        borderRadius: "6px",
      },
      "& .MuiList-root": {
        padding: "0 15px",
      },
    },
  },
};

const NewIcon = (props) => (
  <KeyboardArrowDownIcon {...props} sx={{ color: "#01537A !important" }} />
);

export default function Users({ tab, userData, roleData, refreshData }) {
  const switchRole = localStorage.getItem("switchRole");
  const userRoleContextVal = useContext(userRoleContext);
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const [addRoleOpen, setAddRoleOpen] = useState(false);
  const largeDev = useResponsive("up", "xl");
  const [saveClick, setSaveClick] = useState(false);
  const [change, setChange] = useState(false);
  const [resetClick, setResetClick] = useState(false);
  const [buttonType, setButtonType] = useState();
  const params = useParams();
  const [isValid, setIsValid] = useState(false);
  const [items, setItems] = useState([]);
  const userRole = localStorage.getItem("role");
  const [role, setRole] = useState("admin");
  const [load, setLoad] = useState(false);
  const getRoles = () => {
    const userRole = localStorage.getItem("role");
    if (userRole === "ROLE_ADMIN") {
      setRole("admin");
    } else if (userRole === "ROLE_LICENSEE") {
      setRole("licensee");
    } else if (userRole === "ROLE_CHANNEL") {
      setRole("channel");
    } else {
      setRole("customer");
    }
  };

  const { showSuccess, showError, showWarning } = useToast();
  const location = useLocation();
  const clickedUserId = location.state?.clickedUserId;

  const setUserName = () => {
    const copyData = { ...saveData };
    copyData["userName"] = userData?.name;
    setSaveData(copyData);
  };

  const addChange = () => {
    setAddRoleOpen(true);
  };
  const handleCancel = () => {
    setLoad(false);
    setSaveClick(false);
    setAddRoleOpen(false);
    setUserName();
    setChange(false);
    setIsValid(false);
    clearData();
  };

  const handleStatus = (companyIds, value) => {
    setChange(true);
    const result = items.find(({ companyId }) => companyId === companyIds);
    if (result !== undefined) {
      result["status"] = value;
    } else {
      setItems([
        ...items,
        {
          companyId: companyIds,
          status: value,
        },
      ]);
    }
  };

  const clearData = () => {
    setUserError({
      userName: false,
      userNameMsg: "",
    });
    setSaveData({
      userName: userData?.name,
      editorId: localStorage.getItem("userid"),
    });
    setItems([]);
    refreshData();
    setAddRoleOpen(false);
  };

  const validate = (data) => {
    const userErrors = { ...userError };
    let count = 0;
    if (data?.userName === "") {
      userErrors.userName = true;
      userErrors.userNameMsg = "This field is required";
      count++;
    } else {
      userErrors.userName = false;
      userErrors.userNameMsg = "";
    }
    if (data?.companyId === "" && addRoleOpen) {
      setIsValid(true);
      count++;
    } else {
      setIsValid(false);
    }
    setUserError(userErrors);
    return count;
  };
  const handleSave = () => {
    let errorCount = validate(saveData);
    if (errorCount === 0) {
      setButtonType("save");
      if (change === true && addRoleOpen) {
        setLoad(true);
        setSaveClick(true);
        Services.addNewRole(saveData)
          .then((res) => {
            setLoad(false);
            setSaveClick(false);
            if (res?.data.status === "Success") {
              clearData();
              showSuccess(res?.data.message);
            } else if (res?.data.status === "failed") {
              showError(res?.data.message);
            }
          })
          .catch((err) => {});
      } else if (change === true && !addRoleOpen) {
        setLoad(true);
        setSaveClick(true);
        if (items.length > 0) {
          saveData["userCompanies"] = items;
        }
        Services.roleStatusChange(clickedUserId, saveData)
          .then((res) => {
            setLoad(false);
            setSaveClick(false);
            if (res?.data.status === "success") {
              clearData();
              showSuccess(res?.data.message);
            } else if (res?.data.status === "failed") {
              showError(res?.data.message);
            }
          })
          .catch((err) => {});
      } else {
        showWarning(WarningMessages?.noActivity);
      }
    }
  };

  const handleChanges = (e) => {
    setChange(true);
    const copyData = { ...saveData };
    copyData[e.target.name] = e.target.value;
    if (e.target.name === "userName") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          userName: true,
          userNameMsg: "This field is required",
        });
      } else {
        setUserError({
          ...userError,
          userName: false,
          userNameMsg: "",
        });
      }
    }
    copyData["userName"] = e.target.value;
    copyData["userId"] = clickedUserId;
    setSaveData(copyData);
  };

  const [saveData, setSaveData] = useState({
    userName: "",
    editorId: localStorage.getItem("userid"),
  });
  const [userError, setUserError] = useState({
    userName: false,
    userNameMsg: "",
  });
  const changeData = (data) => {
    setChange(data);
  };

  const companyDetails = (data) => {
    const copyData = { ...saveData };
    copyData["status"] = data.roleStatus;
    copyData["companyId"] = data.companyId;
    if (data.companyId !== undefined) {
      setChange(true);
    }
    copyData["role"] = data.type;
    setSaveData(copyData);
  };

  const resetPassword = (email) => {
    setButtonType("reset");
    setLoad(true);
    setResetClick(true);
    Services.resetPassword({ email: email })
      .then((res) => {
        setLoad(false);
        setResetClick(false);
        if (res.data.status === "success") {
          showSuccess(res.data.message);
        } else if (res.data.status === "failed") {
          showError(res.data.message);
        }
      })
      .catch((err) => {});
  };

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };
  const accessPermissionButton = (btnName) => {
    if (statusContextVal) {
      if (archivedStatusContextVal) {
        return `${btnName}-btn disable`;
      } else {
        return `${btnName}-disable-btn disable`;
      }
    } else {
      if (archivedStatusContextVal) {
        return `${btnName}-btn disable`;
      } else {
        return `${btnName}-btn`;
      }
    }
  };

  const accessControls = (roles) => {
    if (
      userRole === "ROLE_ADMIN" ||
      (userRole === "ROLE_LICENSEE" &&
        (roles === "channel" ||
          roles === "user" ||
          roles === "reseller" ||
          switchRole === "user" ||
          switchRole === "customer" ||
          switchRole === "channel")) ||
      (userRole === "ROLE_CHANNEL" &&
        (roles === "user" ||
          roles === "reseller" ||
          switchRole === "user" ||
          switchRole === "customer"))
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getRoles();
    setUserName();
    //eslint-disable-next-line
  }, [userData]);

  const columns = [
    {
      field: "role",
      flex: 1,
      headerName: "Role",
      headerClassName: "super-app-theme--header",
      width: largeDev ? 300 : 150,
      renderCell: (params) => {
        return (
          <div>
            <span style={textStyle}>{params.row.role}</span>
          </div>
        );
      },
    },
    {
      field: "companyName",
      flex: 1,
      headerName: "Company Name",
      width: 300,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <HtmlTooltip
            placement="bottom"
            title={<PopUp value={params?.row?.companyDetail} />}
          >
            <div>
              <span style={textStyle}>{params.row.companyName}</span>
            </div>
          </HtmlTooltip>
        );
      },
    },

    {
      field: "status",
      flex: 1,
      headerName: "Status",
      width: largeDev ? 200 : 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {accessControls(params.row.role) && !accessPermissionFields() ? (
              <FormControl
                variant="standard"
                sx={{
                  minWidth: { xs: 115, xl: 120 },
                  backgroundColor: "transparent !important",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "400",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
                <Select
                  sx={{ backgroundColor: "transparent !important" }}
                  labelId="demo-simple-select-standard-label"
                  defaultValue={params.row.userStatus}
                  onChange={(e) =>
                    handleStatus(
                      params.row.companyDetail?.companyId,
                      e.target.value
                    )
                  }
                  MenuProps={MenuProps}
                  disableUnderline={true}
                  IconComponent={NewIcon}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Deleted">Deleted</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <div>
                <span style={textStyle2}>{params.row.userStatus}</span>
              </div>
            )}
          </>
        );
      },
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          flexDirection: {
            xs: "column",
            lg: "row",
          },

          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "60px",
        }}
      >
        <Box>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <FormControl
              sx={{
                width: {
                  xs: "380px",
                  md: "450px",
                  lg: "420px",
                  xl: "450px",
                },
              }}
              variant="standard"
            >
              <TextField
                sx={{
                  fontWeight: "400px",
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "16px",
                  },
                  lineHeight: "18px",
                  letterSpacing: "0.5px",
                  color: "rgba(0, 0, 0, 0.87) !important",
                  fontStyle: "normal",
                  padding: "4px 0 0px",
                }}
                name="userName"
                type="text"
                variant="standard"
                onChange={handleChanges}
                error={!!userError?.userName}
                required
                helperText={userError?.userName ? userError?.userNameMsg : ""}
                value={saveData?.userName ? saveData?.userName : ""}
                InputProps={{
                  autoComplete: "off",
                  readOnly: accessPermissionFields() && true,
                  endAdornment: (
                    <InputAdornment position="end">
                      {!accessPermissionFields() && (
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "24px",
                letterSpacing: "0.4px",
                color: "#01537A",
                paddingBottom: "30px",
              }}
            >
              User Name
            </Typography>
          </Box>
          <Paper
            sx={{
              maxWidth: { xs: "380px", md: "450px", lg: "450px" },
              width: "100%",
              height: "185px",
              padding: "30px",
              display: "flex",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              justifyContent: "space-between",
            }}
          >
            <Stack1 direction="column" spacing={2} component="span">
              <StackBox>Email Address </StackBox>
              <StackBox>Created</StackBox>
              <StackBox>Verified</StackBox>
            </Stack1>
            <Stack2 component="span" direction="column" spacing={2}>
              <StackBox className="wrapText">
                {userData && userData.email}
              </StackBox>
              <StackBox>
                {userData &&
                  moment(userData.created).format("DD MMM YYYY, HH:mm")}
              </StackBox>
              <StackBox>{userData && userData.verified}</StackBox>
            </Stack2>
          </Paper>
        </Box>
        <Box
          sx={{
            maxWidth: { xs: "400px", md: "596px", lg: "610px", xl: "830px" },
            width: "100%",
            paddingTop: { xs: "20px", lg: "0px" },
          }}
        >
          {addRoleOpen ? (
            <>
              <AddRole
                changeData={changeData}
                companyDetails={companyDetails}
                validData={isValid}
              />
            </>
          ) : role === "admin" &&
            localStorage.getItem("selectType") !== "admin" ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "10px",
              }}
            >
              <Button onClick={addChange} className="save-btn" size="small">
                add new role
              </Button>
            </Box>
          ) : (
            ""
          )}

          {!addRoleOpen && (
            <Box
              sx={{
                marginTop: { lg: role !== "admin" && "50px" },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor:
                      switchStatusContextVal && statusContextVal
                        ? "#373737"
                        : "#01537A !important",
                    color: "white",
                  },
                }}
              >
                <DataTable
                  tab={tab}
                  columns={columns}
                  rows={roleData}
                  rowThreshold={0}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Divider sx={{ color: "#DFDFDF", width: "100%" }}></Divider>
      <Box
        sx={{
          padding: { xs: "20px 0px 10px 0px", xl: "20px 15px 10px 0px" },
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          gap: 2,
        }}
      >
        <Button
          className={accessPermissionButton("cancel")}
          variant="outlined"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        {userData?.verified === "No" ? (
          (!addRoleOpen && role === "admin") ||
          (!addRoleOpen &&
            userRoleContextVal !== "licensee" &&
            role === "licensee") ||
          (!addRoleOpen &&
            userRoleContextVal !== "channel" &&
            role === "channel") ? (
            <Button
              className={accessPermissionButton("modal-reset")}
              variant="outlined"
              disabled={resetClick}
              onClick={() => resetPassword(userData.email)}
              sx={{
                height: "40px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <span style={{ marginRight: "5px" }}>Resend Invite Link</span>
                {load && buttonType === "reset" ? (
                  <Loader
                    type={"spinningBubbles"}
                    color={"#01537A"}
                    height={20}
                    width={20}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Button>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <Button
          sx={{
            "&.Mui-disabled": {
              cursor: "not-allowed !important",
              pointerEvents: "unset !important",
            },
          }}
          className={accessPermissionButton("save")}
          onClick={() => handleSave()}
          variant="contained"
          disabled={saveClick}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <span style={{ marginRight: "5px" }}>Save</span>
            {load && buttonType === "save" ? (
              <Loader
                type={"spinningBubbles"}
                color={"white"}
                height={20}
                width={20}
              />
            ) : (
              ""
            )}
          </Box>
        </Button>
      </Box>
    </Box>
  );
}
