import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { Box, Typography, TextField, Stack } from "@mui/material";
import Loader from "react-loading";
import { styled } from "@mui/material/styles";

export default function DataUsage({ applicationDetails }) {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#01537A",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const commonTextFieldStyles = {
    width: "619px", // Full width
    marginTop: "10px",
    marginBottom: "10px",
    fontFamily: "Roboto",
    fontWeight: "400",
    backgroundColor: "#F5F5F5",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px", // Border radius for the entire TextField
      height: "42px", // Fixed height for the TextField
      "& fieldset": {
        borderColor: "#000", // Border color
      },
      "&.Mui-disabled": {
        backgroundColor: "#F5F5F5", // Background color for disabled state
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px 16px", // Padding inside the input
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(16px, 12px) scale(1)", // Label position when not shrunk
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(16px, -6px) scale(0.75)", // Label position when shrunk
    },
  };

  const readOnlyInputProps = {
    readOnly: true,
  };
  const [loading, setLoading] = useState(false);
  function formatBytes(bytes) {
    let decimals = 1;
    if (bytes === 0) return "0";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" mt="2px">
        {loading ? (
          <Loader
            type="spokes"
            color="#01537A"
            delay={0}
            height={40}
            width={40}
          />
        ) : null}
      </Box>
      <Box>
        <Box
          sx={{
            width: "100%",
            gap: "12px",
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <TextField
            sx={commonTextFieldStyles}
            id="outlined-required"
            label="Monthly App-datafree alert"
            value={
              applicationDetails?.data?.dataUsageAlert
                ? formatBytes(applicationDetails?.data?.dataUsageAlert)
                : ""
            }
            InputProps={readOnlyInputProps}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            sx={commonTextFieldStyles}
            id="outlined-required"
            label="Alert Email Addresses"
            value={applicationDetails?.data?.alertEmail}
            InputProps={readOnlyInputProps}
            InputLabelProps={{ shrink: true }}
          />

          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#1B2935",
                padding: "10px 0",
                marginTop: "1px",
              }}
            >
              Include Daily Datafree Data Limit Alerting?
            </Typography>

            <Stack direction="row" spacing={1}>
              <Typography
                sx={{
                  color: "#757373",
                }}
              >
                No
              </Typography>
              <IOSSwitch
                disabled={false}
                sx={{
                  "& .MuiSwitch-thumb": {
                    cursor: "default",
                  },
                  "& .MuiSwitch-switchBase": {
                    cursor: "default",
                  },
                }}
                checked={Boolean(applicationDetails?.data?.maxBytesPerDay)}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography
                sx={{
                  color: "#757373",
                }}
              >
                Yes
              </Typography>
            </Stack>
          </Box>
          {applicationDetails?.data?.maxBytesPerDay && (
            <>
              <TextField
                sx={commonTextFieldStyles}
                id="outlined-required"
                label="Daily Datafree Datalimit (Bytes)"
                value={
                  applicationDetails?.data?.maxBytesPerDay
                    ? formatBytes(applicationDetails?.data?.maxBytesPerDay)
                    : ""
                }
                InputProps={readOnlyInputProps}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                sx={commonTextFieldStyles}
                id="outlined-required"
                label="Alert Action"
                value={
                  applicationDetails?.data?.maxBytesPerDayAction === 0
                    ? "Send Alerts Only"
                    : "Send Alerts and Stop App"
                }
                InputProps={readOnlyInputProps}
                InputLabelProps={{ shrink: true }}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
