import React from 'react'
import Loader from "react-loading";

export default function Loaders({color,height,width,loading,type}) {
  return (
    <Loader
    type={type}
    color= {color}
    delay={0}
    height={height}
    width={width}
    loading={loading}
  />
  )
}
