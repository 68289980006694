import { Box, TextField } from "@mui/material";
import React from "react";

import "react-toastify/dist/ReactToastify.css";

export default function VpnServiceDetails({ applicationDetails }) {
  const commonTextFieldStyles = {
    width: "619px",
    marginTop: "10px",
    marginBottom: "10px",
    fontFamily: "Roboto",
    fontWeight: "400",
    backgroundColor: "#F5F5F5",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "42px",
      "& fieldset": {
        borderColor: "#000",
      },
      "&.Mui-disabled": {
        backgroundColor: "#F5F5F5",
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px 16px",
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(16px, 12px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(16px, -6px) scale(0.75)",
    },
  };

  const readOnlyInputProps = {
    readOnly: true,
  };
  function formatBytes(bytes) {
    let decimals = 1;
    if (bytes === 0) return "0";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }
  return (
    <>
      <Box>
        <Box
          sx={{
            width: "100%",
            gap: "12px",
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <TextField
            sx={commonTextFieldStyles}
            id="outlined-required"
            label="Hosted Region"
            value={applicationDetails?.data?.vpnRegion}
            InputProps={readOnlyInputProps}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            sx={commonTextFieldStyles}
            id="outlined-required"
            label="VPN service"
            value={applicationDetails?.data?.vpnService}
            InputProps={readOnlyInputProps}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            sx={commonTextFieldStyles}
            id="outlined-required"
            label="Monthly data Limit(GB)"
            value={
              applicationDetails?.data?.dataUsageAlert
                ? formatBytes(applicationDetails?.data?.dataUsageAlert)
                : ""
            }
            InputProps={readOnlyInputProps}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            sx={commonTextFieldStyles}
            id="outlined-required"
            label="Daily data Limit(GB)"
            value={
              applicationDetails?.data?.maxBytesPerDay
                ? formatBytes(applicationDetails?.data?.maxBytesPerDay)
                : ""
            }
            InputProps={readOnlyInputProps}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            sx={commonTextFieldStyles}
            id="outlined-required"
            label="Usage alert email"
            value={applicationDetails?.data?.alertEmail}
            InputProps={readOnlyInputProps}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            sx={commonTextFieldStyles}
            id="outlined-required"
            label="Per User Monthly Data Quota(MB)"
            value={
              applicationDetails?.data?.maxBytesPerUser
                ? formatBytes(applicationDetails?.data?.maxBytesPerUser)
                : ""
            }
            InputProps={readOnlyInputProps}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>
    </>
  );
}
