import Layout from "../../components/layout";
import Dashboard from "../../components/channelDashboard";
import { CookiesProvider } from "react-cookie";
import UserDashboard from "../../components/publisherDashboard";
export default function Channel() {
  const path = window.location.pathname.split("/");
  let role = "";
  if (path.length === 2) {
    role = path[1];
    localStorage.setItem("switchRole", role);
  } else {
    localStorage.removeItem("switchRole");
  }
  return (
    <CookiesProvider>
      <Layout>{role === "customer" ? <UserDashboard /> : <Dashboard />}</Layout>
    </CookiesProvider>
  );
}
