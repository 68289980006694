import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Divider,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Avatar,
  Popover,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { ChromePicker } from "react-color";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import SignalWifiStatusbar4BarIcon from "@mui/icons-material/SignalWifiStatusbar4Bar";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import appDp from "../../../../assets/img/appDp.svg";
import calendar from "../../../../assets/img/calendar.svg";
import rewards from "../../../../assets/img/reward.svg";
import address from "../../../../assets/img/location.svg";
import payment from "../../../../assets/img/payment.svg";
import offer from "../../../../assets/img/offers.svg";
import referFriend from "../../../../assets/img/referFriend.svg";
import {
  statusContext,
  archivedStatusContext,
  switchStatusContext,
} from "../../../../context/serviceContext";
import Services from "../../../../api/services";
import { useLocation } from "react-router-dom";
import useToast from "../../../../hooks/useToast";
import Loader from "../../../../components/loader";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import imgFrame from "../../../../assets/img/imgFrame.png";
import cam from "../../../../assets/img/cam.svg";
import ChangeHistoryRoundedIcon from "@mui/icons-material/ChangeHistoryRounded";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import { WarningMessages } from "../../../../config/messages";
import { regexPatterns } from "../../../../config/regex";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#01537A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export default function LookAndFeel({
  applicationDetails,
  refreshApi,
  appDetailsRefresh,
}) {
  const [currentColorKey, setCurrentColorKey] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const selectedAppId = location.state.clickedAppId;
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [isValid, setValid] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [clickDisable, setClickDisable] = useState(false);
  const [cancelDisable, setCancelDisable] = useState(true);
  const [userData, setUserData] = useState({
    barIcon: 0,
    statusBar: "#303f9f",
    actionBar: "#3f51b5",
    accentColor: "#ff4081",
    bgColor: "#ffffff",
    fontColor: "#ffffff",
  });

  const [userError, setUserError] = useState({
    statusBar: false,
    statusBarMsg: "",
    actionBar: false,
    actionBarMsg: "",
    accentColor: false,
    accentColorMsg: "",
  });

  const { showSuccess } = useToast();

  const open = Boolean(anchorEl);
  const id = open ? "color-picker-popover" : undefined;

  const areAllFieldsFilled =
    userData.statusBar !== "" &&
    userError.statusBarMsg === "" &&
    userData.actionBar !== "" &&
    userError.actionBarMsg === "" &&
    userData.accentColor !== "" &&
    userError.accentColorMsg === "";

  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );

  const handleInputChange = (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    setCancelDisable(false);

    switch (e.target.name) {
      case "statusBar":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            statusBar: true,
            statusBarMsg: "This field is required",
          });
        } else {
          if (regexPatterns.colorRegex.test(e.target.value) === false) {
            setUserError({
              ...userError,
              statusBar: true,
              statusBarMsg: "Please enter a valid format",
            });
          } else {
            setUserError({
              ...userError,
              statusBar: false,
              statusBarMsg: "",
            });

            userData?.statusBar !== e.target.value
              ? setValid(true)
              : setValid(false);
          }
        }
        break;

      case "actionBar":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            actionBar: true,
            actionBarMsg: "This field is required",
          });
        } else {
          if (regexPatterns.colorRegex.test(e.target.value) === false) {
            setUserError({
              ...userError,
              actionBar: true,
              actionBarMsg: "Please enter a valid format",
            });
          } else {
            setUserError({
              ...userError,
              actionBar: false,
              actionBarMsg: "",
            });
            userData?.actionBar !== e.target.value
              ? setValid(true)
              : setValid(false);
          }
        }
        break;

      case "accentColor":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            accentColor: true,
            accentColorMsg: "This field is required",
          });
        } else {
          if (regexPatterns.colorRegex.test(e.target.value) === false) {
            setUserError({
              ...userError,
              accentColor: true,
              accentColorMsg: "Please enter a valid format",
            });
          } else {
            setUserError({
              ...userError,
              accentColor: false,
              accentColorMsg: "",
            });
            userData?.accentColor !== e.target.value
              ? setValid(true)
              : setValid(false);
          }
        }
        break;

      default:
        break;
    }

    setUserData(newData);
  };

  const handleClick = (event, colorKey) => {
    setAnchorEl(event.currentTarget);
    setCurrentColorKey(colorKey);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (newColor) => {
    setCancelDisable(false);

    switch (currentColorKey) {
      case "statusBar":
        setUserData({
          ...userData,
          statusBar: newColor.hex,
          fontColor: newColor.hex === "#ffffff" ? "#000000" : "#ffffff",
        });
        setUserError({
          ...userError,
          statusBar: false,
          statusBarMsg: "",
        });
        setValid(true);
        break;

      case "actionBar":
        setUserData({
          ...userData,
          actionBar: newColor.hex,
          fontColor: newColor.hex === "#ffffff" ? "#000000" : "#ffffff",
        });
        setUserError({
          ...userError,
          actionBar: false,
          actionBarMsg: "",
        });
        setValid(true);
        break;

      case "accentColor":
        setUserData({
          ...userData,
          accentColor: newColor.hex,
          fontColor: newColor.hex === "#ffffff" ? "#000000" : "#ffffff",
        });
        setUserError({
          ...userError,
          accentColor: false,
          accentColorMsg: "",
        });
        setValid(true);
        break;

      default:
        break;
    }
  };

  const handleSave = () => {
    const type = "DEV";
    const applicationId = selectedAppId;
    let payload = {
      actionBarColor: userData?.actionBar,
      statusBarColor: userData?.statusBar,
      accentColor: userData?.accentColor,
      darkActionIcon: userData?.barIcon,
      applicationType: "wrap",
    };
    setLoad(true);
    setClickDisable(true);
    Services.updateWrapLookFeel(type, applicationId, payload)
      .then((res) => {
        if (res?.data.status === "success") {
          showSuccess(WarningMessages?.updated);
          appDetailsRefresh(true);
        }
        handleCancel();
      })
      .catch((err) => {});
    appDetailsRefresh(false);
  };
  const handleCancel = () => {
    setUserError({
      statusBar: false,
      statusBarMsg: "",
      actionBar: false,
      actionBarMsg: "",
      accentColor: false,
      accentColorMsg: "",
    });

    setValid(false);
    setLoad(false);
    setClickDisable(false);
    getWrapLookFeel();
    setCancelDisable(true);
  };

  const handleSwitch = (e) => {
    setValid(true);
    const value = e.target.checked ? 1 : 0;
    setCancelDisable(false);
    if (value === 1) {
      setUserData({
        ...userData,
        barIcon: value,
        bgColor: "#d6d6d6",
        statusBar: "#ffffff",
        fontColor: "#000000",
        actionBar: "#ffffff",
      });
    } else {
      setUserData({
        ...userData,
        barIcon: value,
        bgColor: "white",
        statusBar: "#303f9f",
        fontColor: "#ffffff",
        actionBar: "#3f51b5",
      });
    }
  };

  async function getWrapLookFeel() {
    setLoading(true);
    Services.getWrapLookFeel({
      applicationId: selectedAppId,
      deploymentType: "DEV",
    })
      .then((res) => {
        setLoading(false);
        setUserData({
          ...userData,
          barIcon: res?.data?.darkActionIcon ? res?.data?.darkActionIcon : 0,
          statusBar: res?.data?.statusBarColor
            ? res?.data?.statusBarColor
            : "#303f9f",

          actionBar: res?.data?.actionBarColor
            ? res?.data?.actionBarColor
            : "#3f51b5",
          accentColor: res?.data?.accentColor
            ? res?.data?.accentColor
            : "#ff4081",
          fontColor:
            res?.data?.statusBarColor === "#ffffff" ? "#000000" : "#ffffff",
        });
      })
      .catch((err) => {});
  }

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (refreshApi) {
      setTriggerRefresh(true);
    }
  }, [refreshApi]);

  useEffect(() => {
    if (triggerRefresh) {
      setLoading(true);
      getWrapLookFeel();
      setTriggerRefresh(false);
    }
    // eslint-disable-next-line
  }, [triggerRefresh]);

  useEffect(() => {
    getWrapLookFeel();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" mt="2px">
        {loading ? (
          <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
        ) : null}
      </Box>
      <Box
        sx={{
          width: {
            xs: "50px",
            sm: "408px",
            md: "641px",
            lg: "100%",
            xl: "100%",
          },
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: { md: "40px", xl: "130px" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              padding: "10px",
              width: {
                sm: "380px",
                md: "400px",
                lg: "600px",
                xl: "65%",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: "#01537A",
                fontSize: "18px",
              }}
            >
              Register content access details
            </Typography>
            <Typography
              sx={{
                color: "#01537A",
                fontWeight: "400",
                fontSize: "12px",
              }}
            >
              Optional * - These can be edited later through the Publication
              Designer
            </Typography>
            <Divider
              sx={{
                borderRadius: "6px",
                borderBottomWidth: 1,
                marginBottom: "20px",
              }}
            ></Divider>
            <TextField
              sx={{
                width: "100%",
              }}
              value={userData?.statusBar}
              error={!!userError?.statusBar}
              onChange={handleInputChange}
              name="statusBar"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      onClick={(event) => {
                        !accessPermissionFields() &&
                          handleClick(event, "statusBar");
                      }}
                      sx={{
                        cursor: accessPermissionFields()
                          ? "default"
                          : "pointer",
                        border:
                          (userError?.statusBarMsg ||
                            userData?.statusBar === "#ffffff") &&
                          "1px solid #212121",
                        background: userData?.statusBar,
                        width: "20px",
                        height: "20px",
                      }}
                    ></Box>
                    <Popover
                      id={id}
                      open={open && currentColorKey === "statusBar"}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <ChromePicker
                        color={userData?.statusBar}
                        onChange={handleColorChange}
                      />
                    </Popover>
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
              helperText={userError?.statusBar ? userError?.statusBarMsg : ""}
            />
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                color: "#01537A",
                marginBottom: "15px",
              }}
            >
              Status Bar Colour
            </Typography>
            <TextField
              sx={{
                width: "100%",
              }}
              value={userData?.actionBar}
              error={!!userError?.actionBar}
              onChange={handleInputChange}
              name="actionBar"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      onClick={(event) => {
                        !accessPermissionFields() &&
                          handleClick(event, "actionBar");
                      }}
                      sx={{
                        cursor: accessPermissionFields()
                          ? "default"
                          : "pointer",
                        border:
                          (userError?.actionBarMsg ||
                            userData?.actionBar === "#ffffff") &&
                          "1px solid #212121",
                        background: userData?.actionBar,
                        width: "20px",
                        height: "20px",
                      }}
                    ></Box>
                    <Popover
                      id={id}
                      open={open && currentColorKey === "actionBar"}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <ChromePicker
                        color={userData?.actionBar}
                        onChange={handleColorChange}
                      />
                    </Popover>
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
              helperText={userError?.actionBar ? userError?.actionBarMsg : ""}
            />
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                color: "#01537A",
                marginBottom: "15px",
              }}
            >
              Action Bar Colour
            </Typography>
            <TextField
              sx={{
                width: "100%",
              }}
              value={userData?.accentColor}
              error={!!userError?.accentColor}
              onChange={handleInputChange}
              name="accentColor"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      onClick={(event) => {
                        !accessPermissionFields() &&
                          handleClick(event, "accentColor");
                      }}
                      sx={{
                        cursor: accessPermissionFields()
                          ? "default"
                          : "pointer",
                        border:
                          (userError?.accentColorMsg ||
                            userData?.accentColor === "#ffffff") &&
                          "1px solid #212121",
                        background: userData?.accentColor,
                        width: "20px",
                        height: "20px",
                      }}
                    ></Box>
                    <Popover
                      id={id}
                      open={open && currentColorKey === "accentColor"}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <ChromePicker
                        color={userData?.accentColor}
                        onChange={handleColorChange}
                      />
                    </Popover>
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
              helperText={
                userError?.accentColor ? userError?.accentColorMsg : ""
              }
            />
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                color: "#01537A",
              }}
            >
              Accent colour
            </Typography>
            <Box
              sx={{
                marginTop: "15px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#1B2935",
                }}
              >
                Use Dark Action Bar Icons
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>No</Typography>
                <IOSSwitch
                  name="includeDailyDataFreeLimit"
                  checked={Boolean(userData?.barIcon)}
                  onChange={handleSwitch}
                  inputProps={{ "aria-label": "ant design" }}
                  disabled={accessPermissionFields()}
                />
                <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  Yes
                </Typography>
              </Stack>
            </Box>
          </Box>
          {/* mobile panel */}
          <Box sx={{ position: "relative" }}>
            <img
              style={{ position: "absolue" }}
              src={imgFrame}
              alt="imgFrame"
              width="137.158px"
              height="279px"
            />
            <img
              style={{
                position: "absolute",
                left: "33px",
                bottom: "309px",
                zIndex: "2",
              }}
              src={cam}
              alt="cam"
            />
            <Box
              sx={{
                position: "absolute",
                bottom: "56px",
                width: "118px",
                left: "9px",
              }}
            >
              {/* header */}
              <Box
                sx={{
                  border: "1px solid ",
                  borderColor: userData?.statusBar,
                  borderRadius: "22px 22px 0px 0px",
                  background: userData?.statusBar,
                  height: "9.27px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  color: userData.fontColor,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "4px",
                    marginLeft: "9px",

                    lineHeight: "10.81px",
                  }}
                >
                  12:30
                </Typography>

                <Box
                  sx={{
                    lineHeight: "10.81px",
                    fontSize: "4px",
                    marginRight: "3px",
                  }}
                >
                  <SignalWifiStatusbar4BarIcon
                    sx={{
                      fontSize: "5px",
                      lineHeight: "10.81px",
                    }}
                  />
                  <SignalCellular4BarIcon
                    sx={{
                      fontSize: "5px",
                      lineHeight: "10.81px",
                    }}
                  />
                  <BatteryFullIcon
                    sx={{
                      fontSize: "5px",
                      lineHeight: "10.81px",
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  background: userData?.statusBar,
                  borderColor: userData?.statusBar,
                  display: "flex",
                  color: userData.fontColor,
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "4px",
                }}
              >
                <Box sx={{ display: "flex", gap: "3px" }}>
                  <KeyboardBackspaceIcon
                    sx={{
                      fontSize: "6px",
                      height: "9px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "6px",
                      fontWeight: 700,
                    }}
                  >
                    Business Insurance
                  </Typography>
                </Box>

                <MoreVertIcon
                  sx={{
                    fontSize: "6px",
                    height: "9px",
                  }}
                />
              </Box>
              {/* main box */}
              <Box
                sx={{
                  background: "white",
                  width: "100%",
                  height: "100%",
                  borderColor: userData.fontColor,
                  borderTop: " 0.5px solid var(--grey-4, #B1AEAE)",
                  borderBottom: "0.5px solid var(--grey-4, #B1AEAE)",
                }}
              >
                {/* profile pic panel */}
                <Box
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: "6px",
                    padding: "3px",
                    marginBottom: "10px",
                  }}
                >
                  <Avatar
                    src={appDp}
                    alt="Application Dp"
                    rounded="lg"
                    sx={{
                      width: "27px",
                      height: "27px",
                    }}
                  />
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "8px",
                        color: "#1A1B2D",
                      }}
                    >
                      Kyle Green
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        color: "#535763",
                        fontSize: "6px",
                      }}
                    >
                      kyle@gmail.com
                    </Typography>
                  </Box>
                </Box>
                {/* calendar box */}
                <Box
                  sx={{
                    background: userData?.accentColor
                      ? userData?.accentColor
                      : "black",
                    height: "27px",
                    borderRadius: "6px !important",
                    margin: "2px",

                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    paddingRight: "6px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "2px",
                      padding: "6px",
                    }}
                  >
                    <img
                      src={calendar}
                      width={"14px"}
                      height={"14px"}
                      alt="calender"
                    />
                    <Typography
                      sx={{
                        fontWeight: "600",
                        color: "white",
                        fontSize: "8px",
                      }}
                    >
                      Calendar
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      background: "#B1E5FC",
                      fontSize: "5px",

                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",

                      borderRadius: "2px !important",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "5px",
                        fontWeight: "600",
                        padding: "2px 4px",
                      }}
                    >
                      2
                    </Typography>
                  </Box>
                </Box>
                {/* reward box */}
                <Box
                  sx={{
                    height: "27px",
                    borderRadius: "6px !important",
                    margin: "2px",

                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    paddingRight: "6px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "2px",
                      padding: "6px",
                    }}
                  >
                    <Avatar
                      src={rewards}
                      alt="calender"
                      sx={{
                        width: "14px",
                        height: "14px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "600",
                        color: "#535763",
                        fontSize: "8px",
                        marginTop: "1px",
                      }}
                    >
                      Rewards
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      background: " #FB9B9B",
                      fontSize: "5px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      borderRadius: "2px !important",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "5px",
                        fontWeight: "600",
                        padding: "2px 4px",
                      }}
                    >
                      2
                    </Typography>
                  </Box>
                </Box>
                {/* address box */}
                <Box
                  sx={{
                    height: "27px",
                    borderRadius: "6px !important",
                    margin: "2px",

                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    paddingRight: "6px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "2px",
                      padding: "6px",
                    }}
                  >
                    <Avatar
                      src={address}
                      alt="calender"
                      sx={{
                        width: "14px",
                        height: "14px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "600",
                        color: "#535763",
                        fontSize: "8px",
                        marginTop: "1px",
                      }}
                    >
                      Address
                    </Typography>
                  </Box>
                </Box>
                {/* payment box */}
                <Box
                  sx={{
                    height: "27px",
                    borderRadius: "6px !important",
                    margin: "2px",

                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    paddingRight: "6px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "2px",
                      padding: "6px",
                    }}
                  >
                    <img
                      src={payment}
                      alt="calender"
                      width={"14px"}
                      height={"12px"}
                    />
                    <Typography
                      sx={{
                        fontWeight: "600",
                        color: "#535763",
                        fontSize: "8px",
                        marginTop: "1px",
                      }}
                    >
                      Payments Methods
                    </Typography>
                  </Box>
                </Box>
                {/* offers box */}
                <Box
                  sx={{
                    height: "27px",
                    borderRadius: "6px !important",
                    margin: "2px",

                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    paddingRight: "6px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "2px",
                      padding: "6px",
                    }}
                  >
                    <img
                      src={offer}
                      alt="calender"
                      width={"14px"}
                      height={"14px"}
                    />
                    <Typography
                      sx={{
                        fontWeight: "600",
                        color: "#535763",
                        fontSize: "8px",
                        marginTop: "1px",
                      }}
                    >
                      Offers
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      background: "#B5EBCD",
                      fontSize: "5px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",

                      borderRadius: "2px !important",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "5px",
                        fontWeight: "600",
                        padding: "2px 4px",
                      }}
                    >
                      2
                    </Typography>
                  </Box>
                </Box>
                {/* referFriend box */}
                <Box
                  sx={{
                    height: "27px",
                    borderRadius: "6px !important",
                    marginLeft: "2px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    paddingRight: "6px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "2px",
                      padding: "6px",
                    }}
                  >
                    <img
                      src={referFriend}
                      alt="calender"
                      width={"14px"}
                      height={"14px"}
                    />
                    <Typography
                      sx={{
                        fontWeight: "600",
                        color: "#535763",
                        fontSize: "8px",
                        marginTop: "1px",
                      }}
                    >
                      Refer a Friend
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  borderRadius: "0px 0px 12px 12px",
                  background: userData?.actionBar,
                  display: "flex",
                  padding: "4px 13px ",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <ChangeHistoryRoundedIcon
                  sx={{
                    transform: "rotate(271deg)",
                    fontSize: "12px",
                    color: userData.fontColor,
                  }}
                />
                <CircleOutlinedIcon
                  sx={{
                    fontSize: "12px",
                    color: userData.fontColor,
                  }}
                />
                <CheckBoxOutlineBlankRoundedIcon
                  sx={{
                    fontSize: "12px",
                    color: userData.fontColor,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* cancel and save */}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "10px",
            justifyContent: "space-between",
            paddingTop: "30px",
            paddingRight: "20px",
            paddingBottom: "30px",
          }}
        >
          <Button
            className={accessPermissionFields() ? "disable" : null}
            onClick={handleCancel}
            disabled={cancelDisable}
            sx={{
              "&.Mui-disabled": {
                color: "#757373",
                textTransform: "capitalize",
                padding: " 5px 40px 5px 40px",
                borderColor: "#757373 !important",
              },
              padding: " 5px 40px 5px 40px",
              width: "fit-content",
              color: accessPermissionFields() ? "#373737" : "#01537A",
              borderColor: accessPermissionFields()
                ? "#373737"
                : "#01537A !important",
              textTransform: "capitalize",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={
              accessPermissionFields() || clickDisable ? "disable" : null
            }
            onClick={handleSave}
            disabled={!areAllFieldsFilled || !isValid}
            sx={{
              padding: " 0px 50px 0px 50px",
              width: "fit-content",
              background: accessPermissionFields()
                ? "#373737!important"
                : !isValid
                ? "#828282 !important"
                : "#01537A !important",
              textTransform: "capitalize",
              "&.Mui-disabled": {
                background: "#757373 !important",
                color: "white",
                textTransform: "capitalize",
                padding: "0px 50px",
              },
            }}
            variant="contained"
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <span style={{ marginRight: "5px" }}>Save</span>
              {load ? (
                <Loader
                  type={"spinningBubbles"}
                  color={"white"}
                  height={20}
                  width={20}
                />
              ) : (
                ""
              )}
            </Box>
          </Button>
        </Box>
      </Box>
    </>
  );
}
