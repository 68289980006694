import { useLocation } from "react-router-dom";
import ApplicationDetails from "../../../src/pages/applications/applicationDetails";
import AppDetailsDirects from "../../../src/pages/applications/appDetailsDirects.js";

const isDirectProductType = (productType) => {
  const directProductTypes = [
    "#datafree DIRECT",
    "Connect Workspace",
    "D-Direct",
  ];
  return directProductTypes.includes(productType);
};
export default function ApplicationDetailsPage() {
  const location = useLocation();
  const productType = location.state?.productType;

  if (productType === "#datafree MAX") {
    return null;
  }

  return (
    <div>
      {isDirectProductType(productType) ? (
        <AppDetailsDirects />
      ) : (
        <ApplicationDetails />
      )}
    </div>
  );
}
