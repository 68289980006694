import React, { useEffect, useState, useContext, useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Layout from "../layout";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SearchList from "../New Search/searchList";
import Listview from "../../assets/img/newListView.svg";
import Gridview from "../../assets/img/newTableView.svg";
import SelectCheckmarks from "../filterWithOutSelect";
import PaginationRounded from "../pagination";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Loader from "../../components/loader";
import useResponsive from "../useResponsive";
import { useCookies } from "react-cookie";
import Service from "../../api/services";
import {
  switchStatusDispatchContext,
  archivedStatusDispatchContext,
} from "../../context/serviceContext";
import AdvancedFilter from "../advancedFilter";
import AdvancedFilterLabel from "../advancedFilterLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import useToast from "../../hooks/useToast";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const AntTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#01537A",
  },
});
const AntTab = styled(Tab)({
  color: "rgba(0, 0, 0, 0.54)",
  fontWeight: "500",
  "&.Mui-selected": {
    color: "#01537A",
  },
});
export default function LicenseeSearch() {
  const initialRender = useRef(true);
  let switchStatusContextVal = useContext(switchStatusDispatchContext);
  let archivedStatusDispatchContextVal = useContext(
    archivedStatusDispatchContext
  );
  const location = useLocation();
  const isMobile = useResponsive("down", "md");
  const [value, setValue] = useState(
    location?.state?.tabVal ? location?.state?.tabVal : 0
  );
  // eslint-disable-next-line
  const [searchName, setSearchName] = useState(location?.state?.key);
  // eslint-disable-next-line
  const [showMetaData, setShowMetaData] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [resData, setResData] = useState(0);
  const [sortValues] = useState(["Last Changed", "A-Z", "Z-A"]);
  const handleChange = (e, newValue) => {
    setValue(newValue);
    setType([]);
    setState([]);
    setAllData([]);
    setPage(0);
    setSelected("");
    setRevenue([]);
    dropDownValueSet(newValue);
    setFilterClose(false);
    setOwnCompanys({
      value: "",
      label: "",
      id: "",
    });
    setOwnCompany("");
    setAppOwnCompany("");
  };

  const { showError } = useToast();

  const iconStyle = {
    marginLeft: "8px",
  };
  const [page, setPage] = React.useState(0);
  const changePagination = (event, value) => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setPage(value - 1);
  };
  const tabStyle = {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textAlign: "center",
  };
  const [allData, setAllData] = useState([]);
  const [load, setLoad] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [selected, setSelected] = useState(
    location?.state?.sortType ? location?.state?.sortType : ""
  );
  const searchKey = location?.state?.key;
  const [filterClose, setFilterClose] = useState(false);
  const handleDataFromFilter = (data) => {
    setFilterClose(data);
  };
  const [type, setType] = React.useState(
    location?.state?.type ? [location?.state?.type] : []
  );

  const handleTypefilter = (value) => {
    setPage(0);
    if (type.includes(value)) {
      setType(type.filter((item) => item !== value));
    } else {
      setType([...type, value]);
    }
  };
  const stateValue = () => {
    if (value === 0) {
      return ["Active"];
    } else if (value === 1) {
      // eslint-disable-next-line
      return ["Approved"];
    } else if (value === 2) {
      return ["Active"];
    } else if (value === 3) {
      return ["Live"];
    }
  };
  const [state, setState] = React.useState(stateValue());

  const handleStatefilter = (value) => {
    setPage(0);
    if (state.includes(value)) {
      setState(state.filter((item) => item !== value));
    } else {
      setState([...state, value]);
    }
  };

  const [status, setStatus] = React.useState([]);
  const handleStatusfilter = (value) => {
    setPage(0);
    if (status.includes(value)) {
      setStatus(status.filter((item) => item !== value));
    } else {
      setStatus([...status, value]);
    }
  };

  const [system, setSystem] = React.useState([]);

  const handleSystemfilter = (value) => {
    setPage(0);
    if (system.includes(value)) {
      setSystem(system.filter((item) => item !== value));
    } else {
      setSystem([...system, value]);
    }
  };

  const [revenue, setRevenue] = React.useState([]);

  const handleRevenueFilter = (value) => {
    setPage(0);
    if (revenue.includes(value)) {
      setRevenue(revenue.filter((item) => item !== value));
    } else {
      setRevenue([...revenue, value]);
    }
  };

  const [size, setSize] = useState(10);

  const handlePageSize = (e) => {
    setSize(e.target.value);
    setPage(0);
  };

  const getGridView = () => {
    if (gridView === false) {
      setAllData([]);
      setGridView(true);
      setPage(0);
      setSize(10);
    }
  };

  const getTableView = () => {
    if (gridView === true) {
      setAllData([]);
      setGridView(false);
      setPage(0);
      setSize(25);
    }
  };
  const handleSortfilter = (event) => {
    setSelected(event.target.value);
  };

  let typeValues = [];
  let stateValues = [];
  let systemValues = [];
  let statusValue = [];
  if (value === 0) {
    stateValues = ["Active", "Deleted"];
  } else if (value === 1) {
    typeValues = ["Customer", "Channel"];
    stateValues = ["Approved", "New", "Suspended", "Archived"];
  } else if (value === 2) {
    typeValues = ["Customer", "Channel", "Licensee"];
    stateValues = ["Active", "Deleted"];
    statusValue = ["Yes", "No"];
  } else {
    typeValues = [
      "Reach",
      "Wrap",
      "Switch",
      "Max",
      "D-Direct",
      "S-Direct",
      "Connect",
    ];
    stateValues = ["Live", "Deleted", "Suspended"];
    systemValues = ["Dev", "Prod"];
  }
  const topRef = useRef(null);
  const [companyDatas, setCompanyData] = useState();
  const [ownCompany, setOwnCompany] = useState();
  const [ownAppCompany, setAppOwnCompany] = useState();
  const [ownCompanys, setOwnCompanys] = useState({
    value: "",
    label: "",
    id: "",
  });
  const getOwnCompany = (e, value) => {
    e.preventDefault();
    setPage(0);
    if (value !== null) {
      setOwnCompanys(value);
      setOwnCompany(value.id);
    } else {
      setOwnCompanys({
        value: "",
        label: "",
        id: "",
      });
      setOwnCompany("");
    }
  };

  const getAppsOwnCompany = (e, value) => {
    e.preventDefault();
    setPage(0);
    if (value !== null) {
      setOwnCompanys(value);
      setAppOwnCompany(value.id);
    } else {
      setOwnCompanys({
        value: "",
        label: "",
        id: "",
      });
      setAppOwnCompany("");
    }
  };

  const [revenueType, setRevenueType] = useState([]);

  const dropDownValueSet = (value) => {
    if (value === 0) {
      setState(["Active"]);
    } else if (value === 1) {
      if (location?.state?.type === undefined) {
        setState(["Approved"]);
        setType(["Customer"]);
        setRevenue(revenueType);
      } else {
        setState(["Approved"]);
        setRevenue(revenueType);
        setType([location?.state?.type]);
      }
    } else if (value === 2) {
      setState(["Active"]);
      setType(["Customer", "Channel", "Licensee"]);
      setStatus(["Yes", "No"]);
    } else {
      setState(["Live"]);
      setSystem(["Prod"]);

      setType([
        "Reach",
        "Wrap",
        "Switch",
        "Max",
        "D-Direct",
        "S-Direct",
        "Connect",
      ]);
    }
    setSelected();
  };
  const [companyName, setCompanyName] = useState();
  const searchList = () => {
    let stateArray = [];
    let statusArray = [];
    let envArray = [];
    if (value === 2) {
      status.map((el) =>
        el === "Yes"
          ? stateArray.push(1)
          : el === "No"
          ? stateArray.push(0)
          : el === "All"
          ? stateArray.push(...[1, 0])
          : stateArray.push(el)
      );

      statusArray = state.map((el) =>
        el === "Active" ? "Active" : el === "Deleted" ? "Deleted" : el
      );
    }
    if (value === 3) {
      envArray = system.map((el) =>
        el === "Dev" ? "DEV" : el === "Prod" ? "PROD" : el
      );
    }
    let companyArray = type.map((el) =>
      el === "Customer"
        ? "CUSTOMER"
        : el === "Channel"
        ? "CHANNEL"
        : el === "Licensee"
        ? "LICENSEE"
        : el
    );
    let userArray = [];
    type.map((el) =>
      el === "Customer"
        ? userArray.push("user")
        : el === "Channel"
        ? userArray.push("channel", "accountmanager")
        : el === "Licensee"
        ? userArray.push("licensee")
        : el === "Admin"
        ? userArray.push("admin")
        : el === "All"
        ? userArray.push(...["publisher", "channel", "licensee", "admin"])
        : userArray.push(el)
    );
    let appArray = type.map((el) =>
      el === "Reach"
        ? "4"
        : el === "Wrap"
        ? "1"
        : el === "Switch"
        ? "3"
        : el === "Max"
        ? "2"
        : el === "S-Direct"
        ? "5"
        : el === "Connect"
        ? "8"
        : el === "D-Direct"
        ? "7"
        : el
    );
    setLoad(true);
    setShowMetaData(false);
    Service.Search(
      value === 0
        ? {
            metadata: searchKey ? searchKey : "",
            statusAll: state,
            sort: selected,
            type: "all",
            companyId: location?.state?.licenseeId
              ? location?.state?.licenseeId
              : localStorage.getItem("licenseeId"),
            owningCompany: ownCompany,
          }
        : value === 2
        ? {
            metadata: searchKey ? searchKey : "",

            userRole: userArray,

            verified: stateArray,

            owningCompany: ownCompany,
            sort: selected,
            type: "user",
            companyId: location?.state?.licenseeId
              ? location?.state?.licenseeId
              : localStorage.getItem("licenseeId"),
            userStatus: statusArray,
          }
        : value === 3
        ? {
            metadata: searchKey ? searchKey : "",

            appProduct: appArray,
            appStatus: state,

            appEnv: envArray,
            sort: selected,
            type: "application",
            companyId: location?.state?.licenseeId
              ? location?.state?.licenseeId
              : localStorage.getItem("licenseeId"),
            revenueType: revenue,

            owningCompany: ownAppCompany,
          }
        : {
            metadata: searchKey ? searchKey : "",

            companyType: companyArray,

            companyStatus: state,
            owningCompany: ownCompany,
            sort: selected,
            type: "company",
            companyId: location?.state?.licenseeId
              ? location?.state?.licenseeId
              : localStorage.getItem("licenseeId"),
          },
      page,
      size
    )
      .then((res) => {
        if (res.data.status === "failed") {
          showError(res.data.message);
        } else {
          localStorage.setItem("licenseeId", location?.state?.licenseeId);
          setCompanyName(res?.data?.companyName);
          setShowMetaData(true);
          setLoad(false);
          setAllData(
            value === 0
              ? res.data.finalSearchResult
              : value === 1
              ? res.data.finalSearchResult
              : value === 2
              ? res.data.finalSearchResult
              : value === 3
              ? res.data.finalSearchResult
              : []
          );
          setRevenueType(res?.data?.revenueTypes);

          setTotalData(allData?.length);
          setTotalDataCount(
            value === 0
              ? res.data.totalCount
              : value === 1
              ? res.data.companiesCount
              : value === 2
              ? res.data.usersCount
              : value === 3
              ? res.data.appsCount
              : 0
          );
          setResData(res?.data);
          if (res.data?.companyStatus === "Suspended") {
            localStorage.setItem("switchStatus", true);
            switchStatusContextVal(true);

            localStorage.setItem("archivedStatus", false);
            archivedStatusDispatchContextVal(false);
          } else if (res.data?.companyStatus === "Archived") {
            localStorage.setItem("archivedStatus", true);
            archivedStatusDispatchContextVal(true);

            localStorage.setItem("switchStatus", false);
            switchStatusContextVal(false);
          } else {
            localStorage.setItem("switchStatus", false);
            switchStatusContextVal(false);

            localStorage.setItem("archivedStatus", false);
            archivedStatusDispatchContextVal(false);
          }
        }
      })
      .catch((error) => {});
  };

  const filterOptions = createFilterOptions({
    stringify: ({ label }) => `${label}`,
  });

  const [cookies, setCookie] = useCookies(["searchArray"]);
  const searches = cookies.searchArray !== undefined ? cookies.searchArray : [];
  const cookieArray = () => {
    let valuesArray = [];
    let flag = 0;
    if (
      searchKey !== undefined &&
      searchKey !== "" &&
      !localStorage.getItem("switchRole")
    ) {
      if (searches.length > 0) {
        for (let j = 0; j < searches.length; j++) {
          if (
            Number(localStorage.getItem("userid")) === Number(searches[j].id)
          ) {
            valuesArray = searches[j].values;
            if (valuesArray.length < 5) {
              for (let i = 0; i < valuesArray.length; i++) {
                if (searchKey !== valuesArray[i]) {
                  flag = 1;
                } else {
                  flag = 0;
                  break;
                }
              }
              if (flag === 1) {
                valuesArray.push(searchKey);
              }
            } else {
              for (let i = 0; i < valuesArray.length; i++) {
                if (searchKey !== valuesArray[i]) {
                  flag = 1;
                } else {
                  flag = 0;
                  break;
                }
              }
              if (flag === 1) {
                valuesArray.splice(0, 1);
                valuesArray.push(searchKey);
              }
            }
          } else {
            const userPresent = searches.filter(
              (noval) =>
                Number(localStorage.getItem("userid")) === Number(noval.id)
            );
            if (userPresent.length === 0) {
              searches.push({
                values: [searchKey],
                role: localStorage.getItem("role"),
                id: localStorage.getItem("userid"),
              });
            }
          }
        }
      } else {
        searches.push({
          values: [searchKey],
          role: localStorage.getItem("role"),
          id: localStorage.getItem("userid"),
        });
      }
    }
    setCookie("searchArray", searches, { path: "/" });
  };
  const getCompanyList = () => {
    let companyArray = type.map((el) =>
      el === "Customer"
        ? "CUSTOMER"
        : el === "Channel"
        ? "CHANNEL"
        : el === "Licensee"
        ? "LICENSEE"
        : el
    );
    let userArray = type.map((el) =>
      el === "Customer"
        ? "customer"
        : el === "Channel"
        ? "channel"
        : el === "Licensee"
        ? "licensee"
        : el === "Admin"
        ? "admin"
        : el
    );
    Service.getCompanies(
      value === 1
        ? {
            type: "company",
            role: companyArray,
            companyId: location?.state?.licenseeId
              ? location?.state?.licenseeId
              : localStorage.getItem("licenseeId"),
          }
        : value === 2
        ? {
            type: "user",
            role: userArray,
            companyId: location?.state?.licenseeId
              ? location?.state?.licenseeId
              : localStorage.getItem("licenseeId"),
          }
        : value === 3
        ? {
            type: "application",
            companyId: location?.state?.licenseeId
              ? location?.state?.licenseeId
              : localStorage.getItem("licenseeId"),
          }
        : value === 0
        ? {
            type: "all",
            companyId: location?.state?.licenseeId
              ? location?.state?.licenseeId
              : localStorage.getItem("licenseeId"),
          }
        : []
    )
      .then((res) => {
        setCompanyData(res.data);
      })
      .catch((err) => {});
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleClearFilter = () => {
    setState([]);
    setStatus([]);
    setSystem([]);
    setType([]);
    setRevenue([]);
    setOwnCompanys({
      value: "",
      label: "",
      id: "",
    });
    setOwnCompany("");
    setAppOwnCompany("");
  };
  const switchRole = localStorage.getItem("switchRole");

  const navigate = useNavigate();

  const path = window.location.pathname.split("/");
  let role = "";
  if (path.length === 3) {
    role = path[1];
    localStorage.setItem("switchRole", role);
  } else {
    localStorage.removeItem("switchRole");
  }

  useEffect(() => {
    getCompanyList();
    //eslint-disable-next-line
  }, [value, type]);

  useEffect(() => {
    dropDownValueSet(value);
    cookieArray();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSearchName(location?.state?.key);
  }, [location]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setShowMetaData(true);
      setLoad(true);
      searchList();
      if (localStorage.getItem("switchRole") !== "licensee") {
        localStorage.removeItem("switchRole");
      }
    }
    //eslint-disable-next-line
  }, [
    type,
    state,
    value,
    page,
    gridView,
    size,
    system,
    ownCompany,
    selected,
    revenue,
    ownAppCompany,
    status,
    searchKey,
  ]);

  return (
    <Layout>
      <div ref={topRef} />
      <Container className="Wrapper" maxWidth="xxl" disableGutters>
        <Box
          mt={2}
          style={{
            width: "100%",
          }}
        >
          {!switchRole ? (
            ""
          ) : (
            <Box
              sx={{
                display: "flex",
                padding: "0 29px",
                gap: "42px",
                marginTop: "30px",
                marginBottom: "20px",
              }}
            >
              <Box
                sx={{
                  marginTop: "3px",
                }}
              >
                <Button
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  sx={{
                    gap: "13px",
                    color: "#01537A",
                    border: "1px solid #01537A !important",
                    width: "116px",
                    height: "44px",
                    fontSize: "18px",
                    fontWeight: "400",
                  }}
                >
                  BACK
                </Button>
              </Box>

              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {" "}
                <Typography
                  sx={{
                    fontSize: "32px",
                    fontWeight: 700,
                    color: "#01537A",
                    lineHeight: "26px",
                    letterSpacing: "1.32px",
                    textTransform: "uppercase",
                  }}
                >
                  {companyName && companyName}
                </Typography>{" "}
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#01537A",
                    lineHeight: "28px",
                    letterSpacing: "1.32px",
                    textTransform: "capitalize",
                  }}
                >
                  {switchRole && switchRole}
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              padding: "0 15px",
            }}
            sx={{ maxWidth: { xs: "100%" } }}
          >
            <AntTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={isMobile ? true : false}
              allowScrollButtonsMobile
            >
              <AntTab
                label={
                  resData
                    ? `All(${
                        resData?.totalCount === 0 ? 0 : resData?.totalCount
                      })`
                    : "All"
                }
                style={tabStyle}
              />
              <AntTab
                label={
                  resData
                    ? `COMPANIES(${
                        resData?.companiesCount === 0
                          ? 0
                          : resData?.companiesCount
                      })`
                    : "COMPANIES"
                }
                style={tabStyle}
              />
              <AntTab
                label={
                  resData
                    ? `USERS(${
                        resData?.usersCount === 0 ? 0 : resData?.usersCount
                      })`
                    : "USERS"
                }
                style={tabStyle}
              />
              <AntTab
                label={
                  resData
                    ? `APPLICATIONS(${
                        resData?.appsCount === 0 ? 0 : resData?.appsCount
                      })`
                    : "APPLICATIONS"
                }
                style={tabStyle}
              />
            </AntTabs>
            <Box sx={{ flexShrink: 0, display: { xs: "flex", md: "flex" } }}>
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <Box
                  sx={{
                    display: { xs: "flex", md: "flex" },
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "25px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "#757373",
                    }}
                  >
                    View
                  </Typography>
                  <Box>
                    <IconButton
                      type="submit"
                      sx={
                        gridView
                          ? {
                              p: "10px",
                              borderRadius: "100px",
                              backgroundColor: "#01537A1A",
                              gap: "10px",
                              width: "44px",
                              height: "44px",
                            }
                          : {
                              p: "10px",

                              gap: "10px",
                              width: "44px",
                              height: "44px",
                              borderRadius: "100px",
                            }
                      }
                      aria-label="Listview"
                      className={load ? "disable" : null}
                      onClick={getGridView}
                    >
                      <img
                        component="img"
                        style={{
                          height: 28,
                          width: 28,
                          maxHeight: { xs: 233, md: 150 },
                          maxWidth: { xs: 350, md: 220 },
                          mr: 1,
                        }}
                        alt="Listview"
                        src={Listview}
                      />
                    </IconButton>
                    <IconButton
                      type="submit"
                      aria-label="Tableview"
                      sx={
                        !gridView
                          ? {
                              borderRadius: "100px",
                              backgroundColor: "#01537A1A",

                              width: "44px",
                              height: "44px",
                            }
                          : {
                              width: "44px",
                              height: "44px",
                              borderRadius: "100px",
                            }
                      }
                      className={load ? "disable" : null}
                      onClick={getTableView}
                    >
                      <img
                        component="img"
                        style={{
                          height: 28,
                          width: 28,
                          maxHeight: { xs: 30, md: 38 },
                          maxWidth: { xs: 30, md: 38 },
                          mr: 1,
                        }}
                        alt="Gridview"
                        src={Gridview}
                      />
                    </IconButton>{" "}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              "& .css-19kzrtu": {
                padding: "0px 20px",
              },
            }}
          >
            <TabPanel value={value} index={0}>
              <Box
                sx={{
                  background: "white",
                  paddingTop: "10px",
                  display: "flex",
                  gap: "12px",
                  marginBottom: filterClose && "7px",
                  position: "sticky !important",
                  alignSelf: "flex-start",
                  top: "60px",
                  zIndex: 67,
                }}
              >
                <AdvancedFilterLabel sentToFilter={handleDataFromFilter} />
                <FormControl
                  size="small"
                  sx={{ m: 1, minWidth: 100, backgroundColor: "#ffffff" }}
                  style={{ borderColor: "#01537A", borderRadius: "4px" }}
                >
                  <SelectCheckmarks
                    handleChange={handleSortfilter}
                    menuValues={sortValues}
                    value={selected}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: filterClose ? "" : "flex",
                  gap: "20px",
                }}
              >
                {!filterClose && (
                  <AdvancedFilter
                    handleClearFilter={handleClearFilter}
                    handleStatefilter={handleStatefilter}
                    state={state}
                    stateValues={stateValues}
                    setState={setState}
                    value={value}
                    handleKeyPress={handleKeyPress}
                    filterOptions={filterOptions}
                    companyDatas={companyDatas}
                    ownCompanys={ownCompanys}
                    getAppsOwnCompany={getAppsOwnCompany}
                    getOwnCompany={getOwnCompany}
                    load={load}
                    iconStyle={iconStyle}
                  />
                )}

                {totalDataCount === 0 && resData && !load ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: filterClose ? "center" : "stretch",
                      justifyContent: "center",
                      marginTop: "1rem",
                      marginLeft: filterClose ? "0px" : "35%",
                      color: "#5A5656",
                      fontSize: "16px",
                    }}
                  >
                    No results found
                  </Box>
                ) : load ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: filterClose ? "center" : "stretch",
                      justifyContent: "center",
                      marginTop: "1rem",
                      marginLeft: filterClose ? "0px" : "35%",
                    }}
                  >
                    <Loader
                      type={"spokes"}
                      color={"#01537A"}
                      height={40}
                      width={40}
                    />
                  </Box>
                ) : (
                  <SearchList
                    filterClose={filterClose}
                    data={load ? [] : allData}
                    viewMode={gridView}
                    pageCount={totalData}
                    loading={load}
                    currentTab={value}
                    Count={totalDataCount}
                    size={size}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box
                sx={{
                  background: "white",
                  paddingTop: "10px",
                  display: "flex",
                  gap: "12px",
                  marginBottom: filterClose && "7px",
                  position: "sticky !important",
                  alignSelf: "flex-start",
                  top: "60px",
                  zIndex: 67,
                }}
              >
                <AdvancedFilterLabel sentToFilter={handleDataFromFilter} />
                <FormControl
                  size="small"
                  sx={{ m: 1, minWidth: 100, backgroundColor: "#ffffff" }}
                  style={{ borderColor: "#01537A", borderRadius: "4px" }}
                >
                  <SelectCheckmarks
                    handleChange={handleSortfilter}
                    menuValues={sortValues}
                    value={selected}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: filterClose ? "" : "flex",
                  gap: "20px",
                }}
              >
                {!filterClose && (
                  <AdvancedFilter
                    handleClearFilter={handleClearFilter}
                    handleTypefilter={handleTypefilter}
                    type={type}
                    typeValues={typeValues}
                    setType={setType}
                    handleStatefilter={handleStatefilter}
                    state={state}
                    stateValues={stateValues}
                    setState={setState}
                    handleRevenueFilter={handleRevenueFilter}
                    revenue={revenue}
                    revenueType={revenueType}
                    setRevenue={setRevenue}
                    value={value}
                    handleKeyPress={handleKeyPress}
                    filterOptions={filterOptions}
                    companyDatas={companyDatas}
                    ownCompanys={ownCompanys}
                    getAppsOwnCompany={getAppsOwnCompany}
                    getOwnCompany={getOwnCompany}
                    load={load}
                    iconStyle={iconStyle}
                  />
                )}

                {totalDataCount === 0 && resData && !load ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: filterClose ? "center" : "stretch",
                      justifyContent: "center",
                      marginTop: "1rem",
                      marginLeft: filterClose ? "0px" : "35%",
                      color: "#5A5656",
                      fontSize: "16px",
                    }}
                  >
                    No results found
                  </Box>
                ) : load ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: filterClose ? "center" : "stretch",
                      justifyContent: "center",
                      marginTop: "1rem",
                      marginLeft: filterClose ? "0px" : "35%",
                    }}
                  >
                    <Loader
                      type={"spokes"}
                      color={"#01537A"}
                      height={40}
                      width={40}
                    />
                  </Box>
                ) : (
                  <SearchList
                    filterClose={filterClose}
                    data={load ? [] : allData}
                    viewMode={gridView}
                    pageCount={totalData}
                    loading={load}
                    currentTab={value}
                    Count={totalDataCount}
                    size={size}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box
                sx={{
                  background: "white",
                  paddingTop: "10px",
                  display: "flex",
                  gap: "12px",
                  marginBottom: filterClose && "7px",
                  position: "sticky !important",
                  alignSelf: "flex-start",
                  top: "60px",
                  zIndex: 67,
                }}
              >
                <AdvancedFilterLabel sentToFilter={handleDataFromFilter} />
                <FormControl
                  size="small"
                  sx={{ m: 1, minWidth: 100, backgroundColor: "#ffffff" }}
                  style={{ borderColor: "#01537A", borderRadius: "4px" }}
                >
                  <SelectCheckmarks
                    handleChange={handleSortfilter}
                    menuValues={sortValues}
                    value={selected}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: filterClose ? "" : "flex",
                  gap: "20px",
                }}
              >
                {!filterClose && (
                  <AdvancedFilter
                    handleClearFilter={handleClearFilter}
                    handleTypefilter={handleTypefilter}
                    type={type}
                    typeValues={typeValues}
                    setType={setType}
                    handleStatefilter={handleStatefilter}
                    state={state}
                    stateValues={stateValues}
                    setState={setState}
                    handleStatusfilter={handleStatusfilter}
                    status={status}
                    statusValue={statusValue}
                    setStatus={setStatus}
                    value={value}
                    handleKeyPress={handleKeyPress}
                    filterOptions={filterOptions}
                    companyDatas={companyDatas}
                    ownCompanys={ownCompanys}
                    getAppsOwnCompany={getAppsOwnCompany}
                    getOwnCompany={getOwnCompany}
                    load={load}
                    iconStyle={iconStyle}
                  />
                )}

                {totalDataCount === 0 && resData && !load ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: filterClose ? "center" : "stretch",
                      justifyContent: "center",
                      marginTop: "1rem",
                      marginLeft: filterClose ? "0px" : "35%",
                      color: "#5A5656",
                      fontSize: "16px",
                    }}
                  >
                    No results found
                  </Box>
                ) : load ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: filterClose ? "center" : "stretch",
                      justifyContent: "center",
                      marginTop: "1rem",
                      marginLeft: filterClose ? "0px" : "35%",
                    }}
                  >
                    <Loader
                      type={"spokes"}
                      color={"#01537A"}
                      height={40}
                      width={40}
                    />
                  </Box>
                ) : (
                  <SearchList
                    filterClose={filterClose}
                    data={load ? [] : allData}
                    viewMode={gridView}
                    pageCount={totalData}
                    loading={load}
                    currentTab={value}
                    Count={totalDataCount}
                    size={size}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Box
                sx={{
                  background: "white",
                  paddingTop: "10px",
                  display: "flex",
                  gap: "12px",
                  marginBottom: filterClose && "7px",
                  position: "sticky !important",
                  alignSelf: "flex-start",
                  top: "60px",
                  zIndex: 67,
                }}
              >
                <AdvancedFilterLabel sentToFilter={handleDataFromFilter} />
                <FormControl
                  size="small"
                  sx={{ m: 1, minWidth: 100, backgroundColor: "#ffffff" }}
                  style={{ borderColor: "#01537A", borderRadius: "4px" }}
                >
                  <SelectCheckmarks
                    handleChange={handleSortfilter}
                    menuValues={sortValues}
                    value={selected}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: filterClose ? "" : "flex",
                  gap: "20px",
                }}
              >
                {!filterClose && (
                  <AdvancedFilter
                    handleRevenueFilter={handleRevenueFilter}
                    revenue={revenue}
                    revenueType={revenueType}
                    setRevenue={setRevenue}
                    handleClearFilter={handleClearFilter}
                    handleTypefilter={handleTypefilter}
                    type={type}
                    typeValues={typeValues}
                    setType={setType}
                    handleSystemfilter={handleSystemfilter}
                    system={system}
                    systemValues={systemValues}
                    setSystem={setSystem}
                    handleStatefilter={handleStatefilter}
                    state={state}
                    stateValues={stateValues}
                    setState={setState}
                    value={value}
                    handleKeyPress={handleKeyPress}
                    filterOptions={filterOptions}
                    companyDatas={companyDatas}
                    ownCompanys={ownCompanys}
                    getAppsOwnCompany={getAppsOwnCompany}
                    getOwnCompany={getOwnCompany}
                    load={load}
                    iconStyle={iconStyle}
                  />
                )}

                {totalDataCount === 0 && resData && !load ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: filterClose ? "center" : "stretch",
                      justifyContent: "center",
                      marginTop: "1rem",
                      marginLeft: filterClose ? "0px" : "35%",
                      color: "#5A5656",
                      fontSize: "16px",
                    }}
                  >
                    No results found
                  </Box>
                ) : load ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: filterClose ? "center" : "stretch",
                      justifyContent: "center",
                      marginTop: "1rem",
                      marginLeft: filterClose ? "0px" : "35%",
                    }}
                  >
                    <Loader
                      type={"spokes"}
                      color={"#01537A"}
                      height={40}
                      width={40}
                    />
                  </Box>
                ) : (
                  <SearchList
                    filterClose={filterClose}
                    data={load ? [] : allData}
                    viewMode={gridView}
                    pageCount={totalData}
                    loading={load}
                    currentTab={value}
                    Count={totalDataCount}
                    size={size}
                  />
                )}
              </Box>
            </TabPanel>
            {!load && allData.length > 0 ? (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: "#01537A" }}
                >
                  Items per page :{" "}
                </Typography>
                <FormControl variant="standard">
                  <Select
                    value={size}
                    sx={{
                      width: "55px",
                      margin: "0px 10px 0px 5px",
                      backgroundColor: "#8080802b",
                      color: "#000",
                      borderBottom: "1px solid #01537A",
                      textAlign: "center",
                      "&:after": {
                        borderBottom: "1.5px solid #01537A",
                      },
                      "&hover": {
                        borderBottom: "1px solid #01537A",
                      },
                    }}
                    onChange={handlePageSize}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>
                      {Number(10).toLocaleString()}
                    </MenuItem>
                    <MenuItem value={25}>
                      {Number(25).toLocaleString()}
                    </MenuItem>
                    <MenuItem value={50}>
                      {Number(50).toLocaleString()}
                    </MenuItem>
                    <MenuItem value={100}>
                      {Number(100).toLocaleString()}
                    </MenuItem>
                  </Select>
                </FormControl>
                <PaginationRounded
                  changeHandle={changePagination}
                  page={page + 1}
                  Count={totalDataCount}
                  size={size}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}
