import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

import {
  Divider,
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import Services from "../../api/services";
import Loader from "../../components/loader";

export default function BuildApk({
  buildApk,
  handleBuildApkChange,
  handleBuildApkClose,
  handleBuildApkSave,
  userData,
  userError,
  type,
  configId,
  setUserData,
  setDeploymentId,
  disableButton,
  load,
}) {
  const [buildTypesList, setBuildTypesList] = useState([]);
  const location = useLocation();
  const selectedAppId = location.state.clickedAppId;
  const [buildApkLoad, setBuildApkLoad] = useState(false);
  async function getBuildTypesDetails() {
    setBuildApkLoad(true);
    try {
      const res = await Services.buildTypesList({
        applicationId: selectedAppId,
        deploymentType: type,
      });
      if (res?.status === 200 && res.data.length > 0) {
        setBuildTypesList(res?.data);
        setBuildApkLoad(false);
      }
    } catch (err) {}
  }

  async function getBuildApkDetails() {
    setBuildApkLoad(true);
    try {
      const res = await Services.getBuildApk({
        configId: configId,
      });
      if (res?.status === 200) {
        setDeploymentId(res?.data?.deploymentId);
        setUserData({
          ...userData,
          applicationId: res?.data?.applicationid
            ? res?.data?.applicationid
            : "",
          entryPoint: res?.data?.entryPointConfig
            ? res?.data?.entryPointConfig
            : "",
          distributionEmail: res?.data?.distributionEmail
            ? res?.data?.distributionEmail
            : "",
          packageSuffix: res?.data?.packageSuffix
            ? res?.data?.packageSuffix
            : "",
          comments: res?.data?.comment ? res?.data?.comment : "",
          versionCode: res?.data?.versionCode ? res?.data?.versionCode : "",
          versionName: res?.data?.versionName ? res?.data?.versionName : "",
          buildTypes: res?.data?.buildTypes ? res?.data?.buildTypes : "",
        });
        setBuildApkLoad(false);
      }
    } catch (err) {}
  }

  useEffect(() => {
    getBuildTypesDetails();
    getBuildApkDetails();
    //eslint-disable-next-line
  }, []);

  return (
    <Dialog
      maxWidth="sm"
      open={buildApk}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "#01537A",
              fontWeight: "400",
              fontSize: "24px",
              lineHeight: "26px",
              width: "302px",
              height: "27px",
              marginTop: "10px",
            }}
          >
            Build Apk
          </Typography>
          <CloseSharpIcon
            onClick={handleBuildApkClose}
            sx={{
              color: "#757373",
              cursor: "pointer",
            }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {buildApkLoad ? (
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
          </Box>
        ) : (
          ""
        )}
        <Box>
          <Typography
            sx={{
              width: "100%",
              fontSize: "14px",
              marginTop: "8px",
            }}
          >
            Application ID
          </Typography>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="standard"
            value={userData?.applicationId ? userData?.applicationId : ""}
            error={!!userError?.applicationId}
            name="applicationId"
            helperText={
              userError?.applicationId ? userError?.applicationIdMsg : ""
            }
          />
          <Typography
            sx={{
              width: "100%",
              marginTop: "40px",
              fontSize: "14px",
            }}
          >
            Server Entry Point Config
          </Typography>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="standard"
            value={userData?.entryPoint ? userData?.entryPoint : ""}
            error={!!userError?.entryPoint}
            name="entryPoint"
            helperText={userError?.entryPoint ? userError?.entryPointMsg : ""}
          />
          <Typography
            sx={{
              width: "100%",
              marginTop: "40px",
              fontSize: "14px",
            }}
          >
            Version Code
          </Typography>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                </InputAdornment>
              ),
            }}
            value={userData?.versionCode ? userData?.versionCode : ""}
            error={!!userError?.versionCode}
            onChange={handleBuildApkChange}
            name="versionCode"
            helperText={userError?.versionCode ? userError?.versionCodeMsg : ""}
          />
          <Typography
            sx={{
              width: "100%",
              marginTop: "40px",
              fontSize: "14px",
            }}
          >
            Version Name
          </Typography>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                </InputAdornment>
              ),
            }}
            value={userData?.versionName ? userData?.versionName : ""}
            error={!!userError?.versionName}
            onChange={handleBuildApkChange}
            name="versionName"
            helperText={userError?.versionName ? userError?.versionNameMsg : ""}
          />
          <Typography
            sx={{
              width: "100%",
              marginTop: "40px",
              fontSize: "14px",
            }}
          >
            Comments
          </Typography>
          <TextField
            sx={{
              width: "100%",
            }}
            multiline
            maxRows={Infinity}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ alignSelf: "flex-end", marginBottom: "7px" }}
                >
                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={userData?.comments ? userData?.comments : ""}
            error={!!userError?.comments}
            onChange={handleBuildApkChange}
            name="comments"
            helperText={userError?.comments ? userError?.commentsMsg : ""}
          />
          <Typography
            sx={{
              color: "#757373",
              marginTop: "40px",
            }}
          >
            A link to the generated APK will be emailed to any valid addresses
            noted in the Email Distribution List
          </Typography>
          <Typography
            sx={{
              width: "100%",
              marginTop: "10px",
              fontSize: "14px",
            }}
          >
            Email Distribution
          </Typography>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="standard"
            value={
              userData?.distributionEmail ? userData?.distributionEmail : ""
            }
            error={!!userError?.distributionEmail}
            name="distributionEmail"
            helperText={
              userError?.distributionEmail
                ? userError?.distributionEmailMsg
                : ""
            }
          />
          <Typography
            sx={{
              width: "100%",
              marginTop: "40px",
              fontSize: "14px",
            }}
          >
            Build Types
          </Typography>
          <FormControl
            variant="standard"
            sx={{ width: "100%", marginTop: "15px" }}
          >
            <Select
              inputProps={{
                style: {
                  fontSize: 16,
                  fontWeight: 400,
                  letterSpacing: 0.5,
                  color: "rgba(0, 0, 0, 0.87)",
                },
              }}
              inputlabelprops={{
                style: { fontSize: 16, fontWeight: 400 },
              }}
              onChange={handleBuildApkChange}
              value={userData?.buildTypes || ""}
              error={userError?.buildTypes}
              name="buildTypes"
            >
              {buildTypesList.length > 0 ? (
                buildTypesList?.map((item) => (
                  <MenuItem value={item?.id} key={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No data found</MenuItem>
              )}
            </Select>
            <FormHelperText
              sx={{
                color: "#d32f2f",
              }}
            >
              {userError?.buildTypes ? userError?.buildTypesMsg : ""}
            </FormHelperText>
          </FormControl>
          <Typography
            sx={{
              width: "100%",
              marginTop: "40px",
              fontSize: "14px",
            }}
          >
            Package Suffix Override
          </Typography>
          <TextField
            sx={{
              width: "100%",
              marginBottom: "40px",
            }}
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                </InputAdornment>
              ),
            }}
            value={userData?.packageSuffix ? userData?.packageSuffix : ""}
            error={!!userError?.packageSuffix}
            onChange={handleBuildApkChange}
            name="packageSuffix"
            helperText={
              userError?.packageSuffix ? userError?.packageSuffixMsg : ""
            }
          />
          <Divider
            sx={{
              color: "#DFDFDF",
              backgroundColor: "#FFFFFF",
              width: "100%",
              borderBottomWidth: 1,
              marginBottom: "20px",
            }}
          ></Divider>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            className="cancel-btn"
            onClick={handleBuildApkClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              gap: "10px",
            }}
          >
            <Button
              className={disableButton ? "disable" : null}
              sx={{
                padding: " 0px 50px 0px 50px",
                width: "fit-content",
                background: "#01537A !important",
                textTransform: "capitalize",
              }}
              onClick={handleBuildApkSave}
              variant="contained"
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <span style={{ marginRight: "5px" }}>Save</span>
                {load ? (
                  <Loader
                    type={"spinningBubbles"}
                    color={"white"}
                    height={20}
                    width={20}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
