export const getSwitchedUserId = (clickedCompId) => {
  const companyId = localStorage.getItem("companyId");
  const switchRole = localStorage.getItem("switchRole");
  const userIds = {
    customer: localStorage.getItem("customerId"),
    licensee: localStorage.getItem("licenseeId"),
    channel: localStorage.getItem("channelId"),
  };
  return userIds[switchRole] || companyId || clickedCompId || null;
};

export const getRoleMap = () => ({
  LICENSEE: {
    role: "licensee",
    idKey: "licenseeId",
    path: "/licensee",
  },
  CHANNEL: {
    role: "channel",
    idKey: "channelId",
    path: "/channel",
  },
  CUSTOMER: {
    role: "customer",
    idKey: "customerId",
    path: "/customer",
  },
});

export const companyDetailPage = (id, type) => {
  const roleMap = getRoleMap();
  const selectedRole = roleMap[type];

  if (selectedRole) {
    localStorage.setItem("switchRole", selectedRole.role);
    localStorage.setItem(selectedRole.idKey, id);

    Object.keys(roleMap).forEach((key) => {
      if (key !== type) {
        localStorage.removeItem(roleMap[key].idKey);
      }
    });
  }
};
