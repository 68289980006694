import React, { useState, useEffect } from "react";
import { Box, Typography, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Paper, TextField, InputAdornment } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { Container } from "@mui/system";
import Filter from "../../assets/img/filter.svg";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Services from "../../api/services";
import SearchList from "../Company/searchList";
import PaginationRounded from "../../components/pagination";

const iconStyle = {
  marginLeft: "8px",
};

export default function CompanySearchModal({
  type,
  open,
  searchData,
  companyValues,
  role,
  ownType,
  setSearchModalOpen,
}) {
  const [load, setLoad] = useState(false);
  const switchRole = localStorage.getItem("switchRole");
  const switchedUser = JSON.parse(localStorage.getItem("switchedUser"));
  const [licenseeData, setLicenseeData] = useState([]);
  const licenseeCompanyId = () => {
    if (role === "admin") {
      if (switchRole) {
        if (switchedUser) {
          if (switchRole === "licensee") {
            return localStorage.getItem("licenseeId");
          } else if (switchRole === "channel") {
            return localStorage.getItem("channelId");
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else if (role === "licensee") {
      if (switchRole) {
        if (switchedUser) {
          if (switchRole === "channel") {
            return localStorage.getItem("channelId");
          }
        } else {
          return localStorage.getItem("companyId");
        }
      } else {
        return localStorage.getItem("companyId");
      }
    } else if (role === "channel") {
      return localStorage.getItem("companyId");
    }
  };
  const [licenseeCompany, setLicenseeCompany] = useState(licenseeCompanyId());
  const [licenseeCompanys, setLicenseeCompanys] = useState({
    value: "",
    label: "",
    id: "",
  });

  const [page, setPage] = React.useState(0);
  const [totalDataCount, setTotalDataCount] = React.useState(0);
  const changePagination = (event, value) => {
    setPage(value - 1);
  };
  const handleModelClose = () => {
    setSearchModalOpen(false);
  };
  const getLicenseeList = () => {
    setLoad(true);
    Services.licenseeList()
      .then((res) => {
        setLicenseeData(res?.data);
        setLoad(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getLicenseeList();
  }, []);

  const getLicenseeCompany = (e, value) => {
    e.preventDefault();
    if (value !== null) {
      setLicenseeCompanys(value);
      setLicenseeCompany(value.id);
    } else {
      setLicenseeCompanys({
        value: "",
        label: "",
        id: "",
      });
      setLicenseeCompany(licenseeCompanyId());
    }
  };

  const filterOptions = createFilterOptions({
    stringify: ({ label }) => `${label}`,
  });

  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState(searchData);
  const [searchFieldValue, setSearchFieldValue] = useState(searchData);
  const goToSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchFieldValue(e.target.value.trim());
      setSearchKey(e.target.value.trim());
    }
  };

  const searchHandle = (e) => {
    setSearchFieldValue(e.target.value);
  };
  const clearData = () => {
    setSearchKey("");
    setSearchFieldValue("");
    document.getElementById("searchKey").innerText = " ";
  };

  const companySearch = () => {
    setLoading(true);
    Services.owningCompanySearch(
      {
        type: type,
        companyId:
          licenseeCompany !== "" && licenseeCompany !== undefined
            ? licenseeCompany
            : "",
        metadata: searchKey,
      },
      page
    )
      .then((res) => {
        setAllData(res?.data?.companyDetails);
        setTotalDataCount(res?.data?.companyCount);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const getData = (data) => {
    companyValues(data);
  };

  const filterIcon = () => {
    if (role === "admin") {
      if (switchedUser) {
        if (switchRole === "licensee") {
          return false;
        } else {
          return false;
        }
      } else {
        if (type === "customer") {
          return true;
        } else {
          return false;
        }
      }
    } else if (role === "licensee") {
      if (switchedUser) {
        if (switchRole) {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const licenseeFilter = () => {
    if (role === "admin") {
      if (switchedUser) {
        if (switchRole === "licensee") {
          return false;
        } else {
          return false;
        }
      } else {
        if (type === "customer") {
          return true;
        } else {
          return false;
        }
      }
    } else if (role === "licensee") {
      if (switchedUser) {
        if (switchRole) {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    companySearch();
    //eslint-disable-next-line
  }, [licenseeCompany, searchKey, page]);
  return (
    <>
      <Dialog
        open={open}
        scroll={"body"}
        maxWidth="xl"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          "& .MuiDialog-paper ": {
            padding: "10px 50px 0px 0px",
          },
        }}
      >
        <DialogTitle
          sx={{ display: "flex", justifyContent: "space-between" }}
          id="scroll-dialog-title"
        >
          <Typography
            sx={{
              color: "#01537A",
              fontWeight: 400,
              fontSize: "24px",
              width: "270px",
              textTransform: "capitalize",
            }}
          >
            {`Search ${ownType}`}
          </Typography>
          <CloseSharpIcon
            sx={{
              color: "#01537A",
              cursor: "pointer",
            }}
            onClick={handleModelClose}
          />
        </DialogTitle>
        <DialogContent>
          <Box>
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                paddingLeft: { xs: "50px", md: "90px" },
              }}
            >
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "35px",
                  border: "1px solid #01537A",
                  minWidth: { xs: "380px", sm: "400px", md: "600px" },
                }}
              >
                <IconButton aria-label="menu">
                  <SearchIcon
                    style={{
                      color: "#01537A",
                      fontSize: "20px",
                    }}
                  />
                </IconButton>
                <InputBase
                  width="50%"
                  style={{ ml: 1, flex: 1, color: "#373737" }}
                  placeholder="Search for companies"
                  onKeyDown={goToSearch}
                  id="searchKey"
                  value={searchFieldValue}
                  onChange={searchHandle}
                ></InputBase>
                <IconButton aria-label="search" onClick={clearData}>
                  <CloseSharpIcon
                    style={{
                      color: "#01537A",
                      fontSize: "20px",
                      transform: "rotate(90deg)",
                    }}
                  />
                </IconButton>
              </Paper>
            </Container>
            <Divider
              sx={{
                alignContent: "center",
                color: "#DFDFDF",
                margin: "20px 0px 20px 0px",
              }}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "20px",
                position: "relative",
                marginLeft: "15px",
              }}
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
              }}
            >
              <Box
                style={{
                  display: "flex",
                }}
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", md: "center" },
                }}
              >
                {filterIcon() && (
                  <Box sx={{ marginTop: 2 }}>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{
                        fontSize: "12px",
                        color: "#01537A",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        component="img"
                        style={{
                          height: 18,
                          width: 18,
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                          marginRight: 10,
                        }}
                        sx={{
                          marginLeft: { xs: 15, md: 25 },
                        }}
                        alt="Filter"
                        src={Filter}
                      />
                      Filter:
                    </Typography>
                  </Box>
                )}
                {licenseeFilter() && (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    sx={{
                      marginLeft: { xs: "25px", sm: "15px" },
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#373737",
                      }}
                    >
                      Licensee
                    </Typography>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      loading={load}
                      onChange={(e, value) => getLicenseeCompany(e, value)}
                      ListboxProps={{
                        sx: {
                          border: "1px solid #01537A",
                          borderRadius: "8px",
                        },
                      }}
                      value={licenseeCompanys ? licenseeCompanys : ""}
                      key={licenseeCompanys ? licenseeCompanys : ""}
                      options={licenseeData.map((option) => ({
                        value: option?.companyId,
                        id: option?.companyId,
                        label: option?.name,
                      }))}
                      sx={{
                        minWidth: 240,
                        color: "#1537A",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                          borderRadius: "8px",
                          "& .MuiAutocomplete-input": {
                            padding: "5px 4px 5px 4px",
                          },
                        },
                        "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator":
                          {
                            visibility: "hidden",
                          },
                      }}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => {
                        const { label, value } = option;
                        return (
                          <span
                            {...props}
                            value={value}
                            style={{ color: "#01537A" }}
                            key={value}
                          >
                            {label}
                          </span>
                        );
                      }}
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start" sx={iconStyle}>
                                  <SearchIcon
                                    style={{
                                      color: "#01537AAB",
                                      fontSize: "22px",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Search owning licensee"
                            sx={{
                              "& .MuiAutocomplete-option": {
                                color: "#01537A",
                              },
                              "& input::placeholder": {
                                color: "#757373",
                                fontSize: "14px",
                              },
                              "& .MuiInputBase-input": {
                                borderWidth: `1px !important`,
                                position: "relative",
                                fontSize: 16,
                                padding: "1px 5px",
                                borderRadius: "8px",
                                color: "#01537A",
                              },
                              "& fieldset": {
                                borderWidth: `1px !important`,
                                padding: "0px !important",
                                borderColor: "#01537A !important",
                              },
                            }}
                          />{" "}
                        </>
                      )}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {!loading && allData.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Typography>No Result Found</Typography>
              </Box>
            )}

            <SearchList
              data={allData}
              loading={loading}
              selectedValues={getData}
            />

            {!loading && allData.length > 0 ? (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <PaginationRounded
                  changeHandle={changePagination}
                  page={page + 1}
                  Count={totalDataCount}
                  size={"3"}
                />
              </Box>
            ) : null}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
