import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  InputAdornment,
  FormGroup,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Stack,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import React, { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  switchStatusContext,
  statusContext,
  archivedStatusContext,
} from "../../../../context/serviceContext";
import { useLocation } from "react-router-dom";
import Loader from "../../../../components/loader";
import Services from "../../../../api/services";
import useToast from "../../../../hooks/useToast";
import { WarningMessages } from "../../../../config/messages";
import { regexPatterns } from "../../../../config/regex";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#01537A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Security({ applicationDetails, appDetailsRefresh }) {
  const { showSuccess } = useToast();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickDisable, setClickDisable] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [cancelDisable, setCancelDisable] = useState(true);
  const [userData, setUserData] = useState({
    monthlyLimit: "",
    alertEmail: "",
    includeDailyDataFreeLimit: 0,
    maxBytesContent: "",
    testWhitelist: "",
    useWhiteList: false,
    actionRequired: "0",
    maxDailySpend: "",
  });
  const [userError, setUserError] = useState({
    monthlyLimit: false,
    monthlyLimitMsg: "",
    alertEmail: false,
    alertEmailMsg: "",
    maxBytesContent: false,
    maxBytesContentMsg: "",
    testWhitelist: false,
    testWhitelistMsg: "",
    maxDailySpend: false,
    maxDailySpendMsg: "",
  });

  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );

  const location = useLocation();
  const clickedAppId = location.state?.clickedAppId;

  const handleChange = (event) => {
    setCancelDisable(false);
    const value = event.target.checked ? 1 : 0;

    setUserData({
      ...userData,
      useWhiteList: value,
    });
    setClickDisable(false);
  };

  const handleSwitch = (e) => {
    setCancelDisable(false);
    const typeValue = e.target.checked ? 1 : 0;
    if (typeValue === 0) {
      setUserData({
        ...userData,
        maxDailySpend: "",
        actionRequired: "0",
        includeDailyDataFreeLimit: typeValue,
      });
      setClickDisable(false);
      setUserError({
        ...userError,
        maxDailySpend: false,
        maxDailySpendMsg: "",
      });
    } else if (typeValue === 1) {
      setUserData({
        ...userData,
        includeDailyDataFreeLimit: typeValue,
        maxDailySpend: "",
        actionRequired: "0",
      });
      setClickDisable(false);
    }
  };
  function formatBytes(bytes) {
    let decimals = 1;
    if (bytes === 0) return "0";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  const convertToBytes = (value) => {
    const units = {
      B: 1,
      KB: 1024,
      MB: 1024 * 1024,
      GB: 1024 * 1024 * 1024,
      TB: 1024 * 1024 * 1024 * 1024,
    };
    const unitMatch = value.match(/[a-zA-Z]+$/);
    let unit = "B"; // Default unit to bytes if not provided
    let number = parseFloat(value);

    if (unitMatch) {
      unit = unitMatch[0];
      number = value.replace(unit, "").trim(); // Remove the unit from the value
    }

    return number * (units[unit.toUpperCase()] || 1);
  };

  const handleInputChange = (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    setCancelDisable(false);

    switch (e.target.name) {
      case "monthlyLimit":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            monthlyLimit: false,
            monthlyLimitMsg: "",
          });
        } else {
          if (regexPatterns.pattern.test(e.target.value) === false) {
            setUserError({
              ...userError,
              monthlyLimit: true,
              monthlyLimitMsg: "Invalid Format",
            });
            setClickDisable(true);
          } else if (regexPatterns.pattern.test(e.target.value) === true) {
            const dailyInKB = convertToBytes(userData?.maxDailySpend);

            const monthlyInKB = convertToBytes(e.target.value);
            if (dailyInKB > monthlyInKB) {
              setUserError({
                ...userError,
                monthlyLimit: false,
                monthlyLimitMsg: "",
                maxDailySpend: true,
                maxDailySpendMsg:
                  "Max daily spend should not be greater than monthly limit.",
              });
              setClickDisable(true);
            } else {
              setUserError({
                ...userError,
                monthlyLimit: false,
                monthlyLimitMsg: "",
                maxDailySpend: false,
                maxDailySpendMsg: "",
              });
              setClickDisable(false);
            }
          } else {
            setUserError({
              ...userError,

              monthlyLimit: false,
              monthlyLimitMsg: "",
            });
            setClickDisable(false);
          }
        }
        break;

      case "alertEmail":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,

            alertEmail: false,
            alertEmailMsg: "",
          });
          setClickDisable(false);
        } else {
          if (!regexPatterns.emailRegex.test(e.target.value)) {
            setUserError({
              ...userError,
              alertEmail: true,
              alertEmailMsg: "Enter a valid list of email addresses",
            });
            setClickDisable(true);
          } else {
            setUserError({
              ...userError,
              alertEmail: false,
              alertEmailMsg: "",
            });
            setClickDisable(false);
          }

          const alertEmailList = newData.alertEmail
            .replace(/,\s+/g, ",")
            .split(",");
          alertEmailList.pop();
          const enteredEmail = e.target.value.split(",");
          if (
            alertEmailList.includes(
              enteredEmail[enteredEmail.length - 1].trim()
            )
          ) {
            newData[e.target.name] = alertEmailList;
          }
        }
        break;

      case "maxBytesContent":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            maxBytesContent: false,
            maxBytesContentMsg: "",
          });
        } else {
          if (regexPatterns.pattern.test(e.target.value) === false) {
            setUserError({
              ...userError,
              maxBytesContent: true,
              maxBytesContentMsg: "Invalid Format",
            });
            setClickDisable(true);
          } else {
            setUserError({
              ...userError,
              maxBytesContent: false,
              maxBytesContentMsg: "",
            });
            setClickDisable(false);
          }
        }
        break;

      case "maxBytes":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            maxBytes: false,
            maxBytesMsg: "",
          });
        } else {
          if (regexPatterns.maxBytesRegex.test(e.target.value) === false) {
            setUserError({
              ...userError,
              maxBytes: true,
              maxBytesMsg: "only digits accepted",
            });
            setClickDisable(true);
          } else {
            setUserError({
              ...userError,
              maxBytes: false,
              maxBytesMsg: "",
            });
            setClickDisable(false);
          }
        }
        break;

      case "testWhitelist":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            testWhitelist: false,
            testWhitelistMsg: "",
          });
        } else {
          const hasInvalidLine = e.target.value
            .split("\n")
            .some((line) => !regexPatterns.testWhitelistRegex.test(line));
          if (hasInvalidLine) {
            setUserError({
              ...userError,
              testWhitelist: true,
              testWhitelistMsg: "Please enter a test whitelist",
            });
            setClickDisable(true);
          } else {
            setUserError({
              ...userError,
              testWhitelist: false,
              testWhitelistMsg: "",
            });
            setClickDisable(false);
          }

          const alertEmailList = newData.testWhitelist.split("\n");
          alertEmailList.pop();
          const enteredEmail = e.target.value.split("\n");
          if (alertEmailList.includes(enteredEmail[enteredEmail.length - 1])) {
            newData[e.target.name] = alertEmailList.join("\n");
          }
        }
        break;

      case "actionRequired":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            actionRequired: false,
            actionRequiredMsg: "",
          });
        } else {
          setUserError({
            ...userError,
            actionRequired: false,
            actionRequiredMsg: "",
          });
        }
        setClickDisable(false);
        break;

      case "maxDailySpend":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            maxDailySpend: true,
            maxDailySpendMsg: "This field is required",
          });
          setClickDisable(true);
        } else {
          if (regexPatterns.pattern.test(e.target.value) === false) {
            setUserError({
              ...userError,
              maxDailySpend: true,
              maxDailySpendMsg: "Invalid Format",
            });
            setClickDisable(true);
          } else if (regexPatterns.pattern.test(e.target.value) === true) {
            const dailyInKB = convertToBytes(e.target.value);
            const monthlyInKB = convertToBytes(userData?.monthlyLimit);
            if (dailyInKB > monthlyInKB) {
              setUserError({
                ...userError,
                maxDailySpend: true,
                maxDailySpendMsg:
                  "Max daily spend should not be greater than monthly limit.",
              });
              setClickDisable(true);
            } else {
              setUserError({
                ...userError,
                maxDailySpend: false,
                maxDailySpendMsg: "",
              });
              setClickDisable(false);
            }
          } else if (
            e.target.value === "" ||
            e.target.value.match(/^ *$/) !== null
          ) {
            setUserError({
              ...userError,
              maxDailySpend: true,
              maxDailySpendMsg: "This field is required",
            });
            setClickDisable(true);
          } else {
            setUserError({
              ...userError,
              maxDailySpend: false,
              maxDailySpendMsg: "",
            });
            setClickDisable(false);
          }
        }
        break;

      default:
        break;
    }

    setUserData(newData);
  };

  const areAllFieldsFilled =
    userData.includeDailyDataFreeLimit === 0
      ? userError.monthlyLimitMsg === "" &&
        userError.alertEmailMsg === "" &&
        userError.maxBytesContentMsg === "" &&
        userError.maxDailySpendMsg === "" &&
        userError.testWhitelistMsg === ""
      : userData?.maxDailySpend !== "" &&
        userError.monthlyLimitMsg === "" &&
        userError.alertEmailMsg === "" &&
        userError.maxBytesContentMsg === "" &&
        userError.maxDailySpendMsg === "" &&
        userError.testWhitelistMsg === "";
  const validate = (data) => {
    const userErrors = { ...userError };
    const objString = JSON.stringify(data?.alertEmail);
    const hasComma = objString.includes(",");

    let count = 0;
    if (data?.monthlyLimit === "") {
      userErrors.monthlyLimit = false;
      userErrors.monthlyLimitMsg = "";
    } else {
      if (regexPatterns.pattern.test(data?.monthlyLimit) === false) {
        userErrors.monthlyLimit = true;
        userErrors.monthlyLimitMsg = "Invalid Format";
        count++;
      } else {
        userErrors.monthlyLimit = false;
        userErrors.monthlyLimitMsg = "";
      }
    }
    if (hasComma) {
      const alertEmailList = objString.replace(/,\s+/g, ",").split(",");
      alertEmailList.pop();
      const enteredEmail = objString.split(",");
      if (
        alertEmailList.includes(enteredEmail[enteredEmail.length - 1].trim())
      ) {
        userData.alertEmail = alertEmailList;
        userErrors.alertEmail = true;
        userErrors.alertEmailMsg = "Email already exists.";
        count++;
      }
    }
    if (data.alertEmail === "") {
      userErrors.alertEmail = false;
      userErrors.alertEmailMsg = "";
    } else if (!regexPatterns.emailRegex.test(data.alertEmail)) {
      userErrors.alertEmail = true;
      userErrors.alertEmailMsg = "Enter a valid list of email addresses";
      count++;
    } else {
      userErrors.alertEmail = false;
      userErrors.alertEmailMsg = "";
    }

    if (data?.includeDailyDataFreeLimit === 1) {
      if (data?.maxDailySpend === "") {
        userErrors.maxDailySpend = true;
        userErrors.maxDailySpendMsg = "This field is required";
        count++;
      } else {
        if (regexPatterns.pattern.test(data?.maxDailySpend) === false) {
          userErrors.maxDailySpend = true;
          userErrors.maxDailySpendMsg = "Invalid Format";
          count++;
        } else {
          userErrors.maxDailySpend = false;
          userErrors.maxDailySpendMsg = "";
        }
      }

      if (data?.actionRequired === "") {
        userErrors.actionRequired = false;
        userErrors.actionRequiredMsg = "";
      } else {
        userErrors.actionRequired = false;
        userErrors.actionRequiredMsg = "";
      }
    }

    if (data?.maxBytesContent === "") {
      userErrors.maxBytesContent = false;
      userErrors.maxBytesContentMsg = "";
    } else {
      if (regexPatterns.pattern.test(data?.maxBytesContent) === false) {
        userErrors.maxBytesContent = true;
        userErrors.maxBytesContentMsg = "Invalid Format";
        count++;
      } else {
        userErrors.maxBytesContent = false;
        userErrors.maxBytesContentMsg = "";
      }
    }

    setUserError(userErrors);
    return count;
  };
  const handleSave = () => {
    let errorCount = validate(userData);
    if (errorCount === 0) {
      const type = "PROD";
      const applicationId = clickedAppId;

      let payload = {
        applicationType: "wrap",

        monthlyLimit:
          userData?.monthlyLimit && convertToBytes(userData?.monthlyLimit),
        alertEmail: userData?.alertEmail,
        includeDailyDataFreeLimit: userData?.includeDailyDataFreeLimit,

        actionRequired: userData?.actionRequired,
        maxDailySpend:
          userData?.maxDailySpend && convertToBytes(userData?.maxDailySpend),
        testWhiteList: userData?.testWhitelist,
        useTestWhitelist: userData?.useWhiteList,
        maxBytesContent:
          userData?.maxBytesContent &&
          convertToBytes(userData?.maxBytesContent),
      };
      setLoad(true);
      setDisableButton(true);
      Services.updateWrapSecurityRules(type, applicationId, payload)
        .then((res) => {
          if (res?.data.status === "success") {
            showSuccess(WarningMessages?.updated);
            appDetailsRefresh(true);
          }
          handleCancel();
        })
        .catch((err) => {});
      appDetailsRefresh(false);
    } else {
      return false;
    }
  };
  const handleCancel = () => {
    setUserError({
      monthlyLimit: false,
      monthlyLimitMsg: "",
      alertEmail: false,
      alertEmailMsg: "",
      maxBytesContent: false,
      maxBytesContentMsg: "",
      testWhitelist: false,
      testWhitelistMsg: "",
      maxDailySpend: false,
      maxDailySpendMsg: "",
    });
    getSecurityDetails();
    setClickDisable(true);
    setLoad(false);
    setDisableButton(false);
    setCancelDisable(true);
  };

  async function getSecurityDetails() {
    setLoading(true);
    Services.getSecurityTabDetails({
      applicationId: clickedAppId,
      deploymentType: "PROD",
    })
      .then((res) => {
        setLoading(false);
        setUserData({
          ...userData,
          maxBytesContent: res?.data?.maxBytesContent
            ? formatBytes(res?.data?.maxBytesContent)
            : "",
          testWhitelist: res?.data?.whiteList ? res?.data?.whiteList : "",
          useWhiteList: res?.data?.useWhiteList ? res?.data?.useWhiteList : 0,

          monthlyLimit: res?.data?.monthlyLimit
            ? formatBytes(res?.data?.monthlyLimit)
            : "",
          alertEmail: res?.data?.alertEmail ? res?.data?.alertEmail : "",
          includeDailyDataFreeLimit:
            res?.data?.actionRequired || res?.data?.maxDailySpend ? 1 : 0,

          actionRequired: res?.data?.actionRequired
            ? res?.data?.actionRequired
            : "0",
          maxDailySpend: res?.data?.maxDailySpend
            ? formatBytes(res?.data?.maxDailySpend)
            : "",
        });
      })
      .catch((err) => {});
  }

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    getSecurityDetails();
    //eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="center" mt="2px">
        {loading ? (
          <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
        ) : null}
      </Box>
      <Box
        sx={{
          width: {
            xs: "50px",
            sm: "356px",
            md: "500px",
            lg: "700px",
            xl: "1000px",
          },
        }}
      >
        <Typography
          sx={{
            color: "#01537A",
            fontSize: "18px",

            marginTop: "10px",
          }}
        >
          Data Usage Alerts
        </Typography>
        <Divider
          sx={{
            color: "#DFDFDF",
            alignContent: "center",
            marginBottom: "10px",
            borderBottomWidth: 1,
          }}
        />
        <TextField
          sx={{
            width: "100%",
            "& input::placeholder": {
              color: "#757373",
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "15px",
                lg: "16px",
              },
              opacity: "1",
              fontWeight: "400",
            },
            marginBottom: "5px",
            marginTop: "10px",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!accessPermissionFields() && (
                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                )}
              </InputAdornment>
            ),
            readOnly: accessPermissionFields() && true,
          }}
          value={userData?.monthlyLimit}
          error={!!userError?.monthlyLimit}
          onChange={(e) => handleInputChange(e)}
          name="monthlyLimit"
          variant="standard"
          placeholder="Data Usage Alerts Limits"
          helperText={userError?.monthlyLimit ? userError?.monthlyLimitMsg : ""}
        />
        {userData?.monthlyLimit && (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              letterSpacing: "0.4px",
              color: "#01537A",
            }}
          >
            Data Usage Alerts Limits
          </Typography>
        )}

        <TextField
          sx={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
            "& input::placeholder": {
              color: "#757373",
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "15px",
                lg: "16px",
              },
              opacity: "1",
              fontWeight: "400",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!accessPermissionFields() && (
                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                )}
              </InputAdornment>
            ),
            readOnly: accessPermissionFields() && true,
          }}
          value={userData?.alertEmail}
          error={!!userError?.alertEmail}
          onChange={(e) => handleInputChange(e)}
          name="alertEmail"
          placeholder="Alert Email Addresses"
          variant="standard"
          helperText={userError?.alertEmail ? userError?.alertEmailMsg : ""}
        />
        {userData?.alertEmail && (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              letterSpacing: "0.4px",
              color: "#01537A",
            }}
          >
            Alert Email Addresses
          </Typography>
        )}

        <Box
          sx={{
            borderRadius: "6px",
            borderBottomWidth: 1,
            marginBottom:
              userData.includeDailyDataFreeLimit === 1 ? "20px" : "10px",
            border:
              userData.includeDailyDataFreeLimit === 1 &&
              "1px solid var(--grey-4, #B1AEAE)",
            marginTop:
              userData.includeDailyDataFreeLimit === 1 ? "20px" : "10px",
            padding:
              userData.includeDailyDataFreeLimit === 1 ? "12px 12px" : "0px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "14px",
                lg: "14px",
                xl: "14px",
              },
              color: "#1B2935",
            }}
          >
            Include Daily #datafree Data Limit Alerting?
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>No</Typography>
            <IOSSwitch
              name="includeDailyDataFreeLimit"
              checked={Boolean(userData.includeDailyDataFreeLimit)}
              onChange={handleSwitch}
              inputProps={{ "aria-label": "ant design" }}
              disabled={accessPermissionFields()}
            />
            <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>Yes</Typography>
          </Stack>
          {userData.includeDailyDataFreeLimit === 1 && (
            <>
              <TextField
                sx={{
                  width: "100%",
                  marginTop: "15px",
                  marginBottom: "5px",
                  "& input::placeholder": {
                    color: "#757373",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    opacity: "1",
                    fontWeight: "400",
                  },
                }}
                value={userData?.maxDailySpend}
                error={!!userError?.maxDailySpend}
                onChange={(e) => handleInputChange(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!accessPermissionFields() && (
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      )}
                    </InputAdornment>
                  ),
                  readOnly: accessPermissionFields() && true,
                }}
                name="maxDailySpend"
                placeholder="Daily #Datafree Data Limit (Bytes)"
                variant="standard"
                helperText={
                  userError?.maxDailySpend ? userError?.maxDailySpendMsg : ""
                }
              />
              {userData?.maxDailySpend !== "" && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    letterSpacing: "0.4px",
                    color: "#01537A",
                  }}
                >
                  Daily #Datafree Data Limit (Bytes)
                </Typography>
              )}
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <Select
                  inputProps={{
                    sx: {
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  inputlabelprops={{
                    sx: {
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                      fontWeight: 400,
                    },
                  }}
                  onChange={(e) => handleInputChange(e)}
                  value={userData?.actionRequired}
                  error={userError?.actionRequired}
                  name="actionRequired"
                >
                  <MenuItem value={"0"}>Send Alerts Only</MenuItem>
                  <MenuItem value={"1"}>Send Alerts and Stop App</MenuItem>
                </Select>
                <FormHelperText
                  sx={{
                    color: "#d32f2f",
                  }}
                >
                  {userError?.actionRequired
                    ? userError?.actionRequiredMsg
                    : ""}
                </FormHelperText>
              </FormControl>

              {userData?.actionRequired !== "" && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    letterSpacing: "0.4px",
                    color: "#01537A",
                  }}
                >
                  Required Action on Daily Limit
                </Typography>
              )}
            </>
          )}
        </Box>

        <Typography
          sx={{
            fontWeight: "400",
            fontSize: {
              xs: "14px",
              sm: "16px",
              md: "18px",
              lg: "18px",
              xl: "18px",
            },
            color: "#01537A",
          }}
        >
          Content Objects
        </Typography>
        <Divider
          sx={{
            borderRadius: "6px",
            borderBottomWidth: 1,
            marginBottom: "10px",
          }}
        ></Divider>
        <TextField
          sx={{
            width: "100%",
            "& input::placeholder": {
              color: "#757373",
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "15px",
                lg: "16px",
              },
              opacity: "1",
              fontWeight: "400",
            },
            marginBottom: "5px",
            marginTop: "10px",
          }}
          value={userData?.maxBytesContent}
          error={!!userError?.maxBytesContent}
          onChange={(e) => handleInputChange(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!accessPermissionFields() ? (
                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                ) : (
                  ""
                )}
              </InputAdornment>
            ),
            readOnly: accessPermissionFields() && true,
          }}
          name="maxBytesContent"
          variant="standard"
          placeholder="Max bytes per content object"
          helperText={
            userError?.maxBytesContent ? userError?.maxBytesContentMsg : ""
          }
        />
        {userData?.maxBytesContent && (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              letterSpacing: "0.4px",
              color: "#01537A",
            }}
          >
            Max bytes per content object
          </Typography>
        )}

        <Box
          sx={{
            width: {
              xs: "50px",
              sm: "273px",
              md: "500px",
              lg: "700px",
              xl: "1000px",
            },
          }}
        >
          <FormGroup>
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: userData.useWhiteList ? "black" : "#757373",
                },
              }}
              checked={Boolean(userData.useWhiteList)}
              name="useWhiteList"
              onChange={handleChange}
              control={<Checkbox />}
              label="use WhiteList"
              disabled={accessPermissionFields()}
            />
          </FormGroup>
          <TextField
            sx={{
              width: "100%",
              "& textarea::placeholder": {
                color: "#757373",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "15px",
                  lg: "16px",
                },
                opacity: "1",
                fontWeight: "400",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ alignSelf: "flex-end", marginBottom: "7px" }}
                >
                  {!accessPermissionFields() && (
                    <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                  )}
                </InputAdornment>
              ),
              readOnly: accessPermissionFields() && true,
            }}
            value={userData?.testWhitelist}
            error={!!userError?.testWhitelist}
            onChange={(e) => handleInputChange(e)}
            multiline
            maxRows={Infinity}
            name="testWhitelist"
            variant="standard"
            placeholder="Test Whitelist"
            helperText={
              userError?.testWhitelist ? userError?.testWhitelistMsg : ""
            }
          />
          {userData?.testWhitelist && (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "16px",
                  lg: "16px",
                  xl: "16px",
                },
                letterSpacing: "0.4px",
                color: "#01537A",
              }}
            >
              Test Whitelist
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",

          justifyContent: "space-between",
          paddingTop: "30px",
          paddingRight: "20px",
          paddingBottom: "30px",
        }}
      >
        <Button
          className={accessPermissionFields() ? "disable" : null}
          onClick={handleCancel}
          disabled={cancelDisable}
          sx={{
            "&.Mui-disabled": {
              color: "#757373",
              textTransform: "capitalize",
              padding: " 5px 40px 5px 40px",
              borderColor: "#757373 !important",
            },
            padding: " 5px 40px 5px 40px",
            width: "fit-content",
            color: accessPermissionFields() ? "#373737" : "#01537A",
            borderColor: accessPermissionFields()
              ? "#373737"
              : "#01537A !important",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          className={
            accessPermissionFields() || disableButton ? "disable" : null
          }
          onClick={handleSave}
          disabled={!areAllFieldsFilled || clickDisable}
          sx={{
            padding: " 0px 50px 0px 50px",
            width: "fit-content",
            background: accessPermissionFields()
              ? "#373737!important"
              : "#01537A!important",
            textTransform: "capitalize",
            "&.Mui-disabled": {
              background: "#757373 !important",
              color: "white",
              textTransform: "capitalize",
              padding: "0px 50px",
            },
          }}
          variant="contained"
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <span style={{ marginRight: "5px" }}>Save</span>
            {load ? (
              <Loader
                type={"spinningBubbles"}
                color={"white"}
                height={20}
                width={20}
              />
            ) : (
              ""
            )}
          </Box>
        </Button>
      </Box>
    </Box>
  );
}
