import React, { useState, useEffect, useContext } from "react";
import DataTable from "./dataTable";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import {
  Container,
  InputAdornment,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Paper,
  Divider,
  Button,
  ButtonGroup,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
import DoneIcon from "@mui/icons-material/Done";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import Service from "../../api/services";
import PaginationRounded from "../pagination";
import PaginationHeader from "./paginationHeader";
import {
  gridStringOrNumberComparator,
  gridNumberComparator,
} from "@mui/x-data-grid";
import Loader from "../../components/loader";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ModalViewButton from "./modalViewButton";
import ConfirmationPopup from "../../components/confirmationPopup";
import ProgressBar from "./progressBar";
import { WarningMessages } from "../../config/messages";
import useToast from "../../hooks/useToast";
import {
  statusContext,
  switchStatusContext,
  archivedStatusContext,
} from "../../context/serviceContext";

const idComparator = (v1, v2, param1, param2) => {
  return gridNumberComparator(v1.Id, v2.Id, param1, param2);
};

const nameComparator = (v1, v2, param1, param2) => {
  if (v1.vals !== "" && v2.vals !== "") {
    return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
  }
};

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      boxShadow: 2,
      border: "none",
      maxWidth: "150px",
      color: "#000 !important",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderTopRightRadius: "6px",
      borderTopLeftRadius: "6px",
      marginTop: "10px",
      "& .Mui-focused ": {
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
        color: "#000 !important",
      },
      "& .MuiMenuItem-root": {
        color: "#000",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        fontSize: "16px",
        backgroundColor: "transparent",
        padding: "5px",
      },
      "& .MuiMenuItem-root: last-child": {
        color: "#000",
        borderBottom: "none",
        fontSize: "16px",
        backgroundColor: "#fff",
        "&:focus": {
          color: "#01537A",
        },
      },
      "& .Mui-selected:hover": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .Mui-selected": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .MuiBox-root:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        borderRadius: "6px",
        borderBottom: "none",
      },
      "& .MuiBox-root": {
        padding: "8px 10px",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: 2,
        paddingTop: "15px",
        borderRadius: "6px",
      },
      "& .MuiList-root": {
        padding: "0 15px",
      },
    },
  },
};

const NewIcon = (props) => (
  <KeyboardArrowDownIcon {...props} sx={{ color: "#01537A !important" }} />
);

const Stack1 = styled(Box)({
  color: "#01537A",
  fontWeight: "400",
  fontSize: "12px",
  letterSpacing: "0.4px",
  lineHeight: "24px",
  width: "150px",
  top: "5.5px",
});

const StackBox = styled(Box)({
  padding: "10px 0px 10px 0px",
  width: "170px",
  textTransform: "capitalize",
});

const Stack2 = styled(Box)({
  color: "#373737",
  fontWeight: "400",
  fontSize: "16px",
  width: "356px",
  height: "24px",
  lineHeight: "24px",
});

const ChannelsPanel = () => {
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  let params = useParams();
  const userRole = localStorage.getItem("role");
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );

  const changePagination = (event, value) => {
    setPage(value - 1);
  };

  const handlePageSize = (e) => {
    setSize(e.target.value);
    setPage(0);
  };

  const { showSuccess, showWarning } = useToast();

  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const [totalCount, setTotalCount] = useState(0);
  async function getLicenseeDetails() {
    setLoading(true);
    Service.LicenseeChannels({ licenseeId: params?.compId }, page, size)
      .then((res) => {
        setResData(res?.data?.channelDetails);
        setTotalCount(res?.data?.totalCount);
        setLoading(false);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getLicenseeDetails();
    //eslint-disable-next-line
  }, [page, size]);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    //eslint-disable-next-line
    []
  );

  function DetailPanelContent({ row: rowProp }) {
    const apiRef = useGridApiContext();

    const expandRows = apiRef.current.state.detailPanel.expandedRowIds;

    expandRows.map((item) =>
      item !== rowProp.companyId ? apiRef.current.toggleDetailPanel(item) : ""
    );

    const handleClose = () => {
      apiRef.current.toggleDetailPanel(rowProp.companyId);
    };

    const [saveClick, setSaveClick] = useState(false);
    const updateChannel = () => {
      if (saveData?.channelName === "") {
        setSaveError({
          ...saveError,
          channelName: true,
          nameMsg: "This field is required",
        });
      } else if (existData === true) {
        setSaveError({
          ...saveError,
          channelName: true,
          nameMsg: "Company name already exists",
        });
      } else {
        setSaveClick(true);
        Service.updateChannel(saveData, saveData?.channelId)
          .then((res) => {
            if (res?.data.status === "success") {
              showSuccess(res?.data.message);
              getLicenseeDetails();
              handleClose();
            } else {
              showWarning(res?.data.message);
              getLicenseeDetails();
              handleClose();
            }
          })
          .catch((err) => {})
          .finally(() => {
            setSaveClick(false);
          });
      }
    };

    const [saveData, setSaveData] = useState({
      editorId: localStorage.getItem("userid"),
    });

    const [existData, setExistData] = useState();

    const [saveError, setSaveError] = useState({
      channelName: false,
      nameMsg: "",
    });

    const setStatus = (stat) => {
      setSaveData({
        ...saveData,
        channelStatus: stat,
      });
    };

    const handleEdit = (e) => {
      const copyData = { ...saveData };
      copyData[e.target.name] = e.target.value;
      if (e.target.name === "channelName") {
        if (e.target.value !== saveData?.channelName) {
          checkName(e.target.value);
        }
      }
      setSaveData(copyData);
    };

    function checkName(nameVal) {
      if (nameVal === "" || nameVal.match(/^ *$/) !== null) {
        setSaveError({
          ...saveError,
          channelName: true,
          nameMsg: "This field required",
        });
      } else {
        Service.checkCompanyName({
          companyId: saveData?.channelId,
          name: nameVal,
        })
          .then((res) => {
            setExistData(res?.data.status);
            if (res.data?.status === true) {
              setSaveError({
                ...saveError,
                channelName: true,
                nameMsg: "Company name already exists",
              });
            } else {
              setSaveError({
                ...saveError,
                channelName: false,
                nameMsg: "",
              });
            }
          })
          .catch((err) => {});
      }
    }

    const accessPermission = (status, checkStatus) => {
      if (accessControls() && !archivedStatusContextVal) {
        if (status === checkStatus) {
          return "btn-active";
        } else {
          return "btn-nonactive";
        }
      } else {
        if (archivedStatusContextVal) {
          if (status === checkStatus) {
            return "btn-active disable";
          } else {
            return "btn-nonactive disable";
          }
        } else {
          if (status === checkStatus) {
            return "btn-active";
          } else {
            return "btn-nonactive";
          }
        }
      }
    };

    useEffect(() => {
      const newData = { ...saveData };
      newData["channelId"] = rowProp.companyId ? rowProp.companyId : "";
      newData["channelStatus"] = rowProp.status ? rowProp.status : "";
      newData["channelName"] = rowProp.name ? rowProp.name : "";
      setSaveData(newData);
      //eslint-disable-next-line
    }, [rowProp]);
    return (
      <Dialog
        open={true}
        maxWidth="xl"
        scroll={"body"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "24px",
              color: "#01537A",
              marginTop: "10px",
              marginLeft: "34px",

              padding: { lg: "20px 0px", md: "20px 0px" },
            }}
          >
            Update Channel Details
          </Typography>
          <CloseSharpIcon
            sx={{
              cursor: "pointer",
              color: "#01537A",
            }}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: "0px 40px" }}>
            <Box
              sx={{
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
                display: "flex",
                columnGap: { lg: 10, md: 5, xs: 3 },
              }}
            >
              <Paper
                sx={{
                  maxWidth: {
                    xs: "380px",
                    sm: "400px",
                    md: "450px",
                    lg: "450px",
                  },
                  width: "100%",
                  height: "auto",
                  padding: "30px",
                  display: "flex",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  justifyContent: "space-between",
                }}
              >
                <Stack1
                  direction="column"
                  spacing={2}
                  sx={{ paddingLeft: { xs: "0px", md: "10px" } }}
                >
                  <StackBox>Company ID </StackBox>
                  <StackBox>Company Type</StackBox>
                  <StackBox>Licensee Owner</StackBox>
                  <StackBox>Created</StackBox>
                </Stack1>
                <Stack2
                  direction="column"
                  spacing={2}
                  sx={{
                    marginLeft: "10px",
                  }}
                >
                  <StackBox>{rowProp?.companyId}</StackBox>
                  <StackBox>{rowProp?.companyType.toLowerCase()} </StackBox>
                  <StackBox className="wrapText">
                    {rowProp?.owner ? rowProp?.owner : "-"}
                  </StackBox>
                  <StackBox>
                    {rowProp?.created
                      ? moment(rowProp?.created).format("DD MMM YYYY HH:mm")
                      : "-"}
                  </StackBox>
                </Stack2>
              </Paper>
              <Box sx={{ marginTop: { xs: "20px", md: "0px" } }}>
                <Box>
                  <TextField
                    sx={{
                      width: {
                        xs: "380px",
                        sm: "400px",
                        md: "450px",
                        lg: "565px",
                        xl: "650px",
                      },
                      color: "#231F20",
                    }}
                    value={saveData?.channelName ? saveData?.channelName : ""}
                    name="channelName"
                    onBlur={handleEdit}
                    onChange={handleEdit}
                    error={saveError?.channelName}
                    helperText={
                      saveError?.channelName ? saveError?.nameMsg : ""
                    }
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {!accessPermissionFields() && (
                            <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                          )}
                        </InputAdornment>
                      ),
                      readOnly: accessPermissionFields() && true,
                    }}
                  />

                  <Typography
                    sx={{
                      width: "85px",
                      height: "24px",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "24px",
                      letterSpacing: "0.4px",
                      color: "#01537A",
                    }}
                  >
                    Channel Name
                  </Typography>
                </Box>

                <Box
                  sx={{
                    marginTop: "27px",
                  }}
                >
                  <Typography
                    sx={{
                      width: "45px",
                      height: "15px",
                      fontWeight: "400",
                      color: "#1B2935",
                      marginBottom: "8px",
                    }}
                  >
                    Status
                  </Typography>
                  <ButtonGroup
                    disabled={rowProp?.status === "Archived" && true}
                    sx={{
                      width: {
                        xs: "380px",
                        sm: "400px",
                        md: "450px",
                        lg: "565px",
                        xl: "650px",
                      },
                      height: { xs: "35px", lg: "40px" },
                      fontWeight: 400,
                    }}
                  >
                    {rowProp?.status === "New" && (
                      <Button
                        type="button"
                        sx={{
                          width: "100%",
                          fontSize: { xs: "12px", md: "14px", lg: "16px" },
                          textTransform: "capitalize",
                        }}
                        startIcon={
                          saveData?.channelStatus === "New" ? (
                            <DoneIcon
                              xs={{ fontColor: "#01537A !important" }}
                            />
                          ) : (
                            ""
                          )
                        }
                        className={accessPermission(
                          "New",
                          saveData?.channelStatus
                        )}
                        onClick={() => setStatus("New")}
                      >
                        New
                      </Button>
                    )}

                    <Button
                      type="button"
                      sx={{
                        width: "100%",
                        fontSize: { xs: "12px", md: "14px", lg: "16px" },
                        textTransform: "capitalize",
                      }}
                      startIcon={
                        saveData?.channelStatus === "Approved" ? (
                          <DoneIcon xs={{ fontColor: "#01537A !important" }} />
                        ) : (
                          ""
                        )
                      }
                      className={accessPermission(
                        "Approved",
                        saveData?.channelStatus
                      )}
                      onClick={() => setStatus("Approved")}
                    >
                      Approved
                    </Button>

                    <Button
                      type="button"
                      sx={{
                        width: "100%",
                        fontSize: { xs: "12px", md: "14px", lg: "16px" },
                        textTransform: "capitalize",
                      }}
                      startIcon={
                        saveData?.channelStatus === "Suspended" ? (
                          <DoneIcon sx={{ fontColor: "#01537A" }} />
                        ) : (
                          ""
                        )
                      }
                      className={accessPermission(
                        "Suspended",
                        saveData?.channelStatus
                      )}
                      onClick={() => setStatus("Suspended")}
                    >
                      Suspended
                    </Button>

                    <Button
                      type="button"
                      sx={{
                        width: "100%",
                        fontSize: { xs: "12px", md: "14px", lg: "16px" },
                        textTransform: "capitalize",
                      }}
                      startIcon={
                        saveData?.channelStatus === "Archived" ? (
                          <DoneIcon sx={{ fontColor: "#01537A" }} />
                        ) : (
                          ""
                        )
                      }
                      className={accessPermission(
                        "Archived",
                        saveData?.channelStatus
                      )}
                      onClick={() => setStatus("Archived")}
                    >
                      Archived
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>

            <Divider
              sx={{
                alignContent: "center",
                color: "#DFDFDF",
                margin: "60px 0px 20px 0px",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "30px 0px",
                gap: 2,
              }}
            >
              <Button
                className={accessPermissionButton("modal-cancel")}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                sx={{
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "unset !important",
                  },
                }}
                className={accessPermissionButton("modal-save")}
                disabled={saveClick}
                variant="contained"
                onClick={updateChannel}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Save</span>
                  {saveClick ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"white"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  function DialogBoxContent({
    bulkLoading,
    progress,
    updateStatus,
    successMessage,
    errorMessage,
    items,
  }) {
    if (bulkLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "60px 40px 80px 30px",
            width: { xs: "380px", sm: "400px", md: "600px", xl: "700px" },
          }}
        >
          <ProgressBar value={progress} />
        </Box>
      );
    } else if (updateStatus && !bulkLoading) {
      return (
        <Box
          sx={{
            padding: "20px 30px 20px 10px",
            display: "flex",
            flexDirection: "column",
            width: { xs: "380px", sm: "400px", md: "600px", xl: "700px" },
          }}
        >
          {successMessage !== null && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{ color: "#4CAF50" }}
              >{`Success: ${" "}`}</Typography>

              <Typography sx={{ color: "#373737" }}>
                {successMessage}
              </Typography>
            </Box>
          )}

          {errorMessage && errorMessage.length > 0 && (
            <Box>
              {errorMessage.map((errors) => (
                <Box sx={{ width: "100%" }} key={errors.id}>
                  <Typography>
                    <span style={{ color: "#FF5252" }}>Error {errors.id}:</span>
                    <span
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      {errors.error}.{" "}
                    </span>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      {errors.errorMessage}
                    </span>
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            width: "100%",
            alignItems: "center",
            padding: "10px 30px 0px 30px",
          }}
        >
          <Typography
            sx={{
              color: "#01537A",
              fontWeight: 300,
              fontSize: "20px",
              lineHeight: "30px",
            }}
          >
            You are updating {items.length} records , are you sure you want to
            make these changes? Some of these changes might be permanent.
          </Typography>

          <Divider
            sx={{
              color: "#DFDFDF",
              alignContent: "center",
              marginTop: "40px",
            }}
          />
        </Box>
      );
    }
  }
  const [saveClick, setSaveClick] = useState(false);
  const handleSave = () => {
    if (items.length > 0) {
      setOpen(true);
    } else {
      showWarning(WarningMessages?.noActivity);
    }
  };

  const cancelUpdate = () => {
    setSelectValue("");
    getLicenseeDetails();
  };
  DetailPanelContent.propTypes = {
    row: PropTypes.object.isRequired,
  };

  CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
  };

  function CustomDetailPanelToggle() {
    return <ModalViewButton />;
  }

  const accessControls = () => {
    if (userRole === "ROLE_ADMIN") {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionFields = () => {
    if (archivedStatusContextVal) {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionButton = (btnName) => {
    if (archivedStatusContextVal) {
      return `${btnName}-btn disable`;
    } else {
      return `${btnName}-btn`;
    }
  };

  const textStyle = {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "400",
    fontSize: "16px",
  };
  const textStyle2 = {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "400",
    fontSize: "16px",
  };
  const textStyle1 = {
    color: "#231F20",
    fontWeight: "400",
    fontSize: "14px",
  };
  const columns = [
    {
      flex: 1,
      field: "name",
      headerName: "ID",
      width: 170,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        Id: row.companyId,
      }),
      sortComparator: idComparator,
      renderCell: (params) => {
        return (
          <div>
            <span style={textStyle1}>{params.row.companyId}</span>
          </div>
        );
      },
    },

    {
      flex: 1,
      field: "channelName",
      headerName: "Channel Name",
      width: 340,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.name,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span style={textStyle2}>
              {params.row.name ? params.row.name : ""}
            </span>
          </div>
        );
      },
    },

    {
      flex: 1,
      field: "status",
      headerName: "Status",
      minWidth: 170,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.status,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <>
            {params.row.status !== "Archived" && !accessPermissionFields() ? (
              <FormControl
                variant="standard"
                sx={{
                  minWidth: 120,
                  backgroundColor: "transparent !important",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "400",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
                <Select
                  sx={{ backgroundColor: "transparent !important" }}
                  labelId="demo-simple-select-standard-label"
                  id={"status" + params.row.companyId}
                  defaultValue={
                    selectValue !== undefined ? selectValue : params.row.status
                  }
                  onChange={(e) =>
                    handleStatus(
                      params.row.companyId,
                      e.target.value,
                      params.row.status
                    )
                  }
                  MenuProps={MenuProps}
                  disableUnderline={true}
                  IconComponent={NewIcon}
                >
                  {params.row.status === "New" && (
                    <MenuItem value="New">New</MenuItem>
                  )}

                  <MenuItem value="Approved">Approved</MenuItem>

                  <MenuItem value="Archived">Archived</MenuItem>
                  <MenuItem value="Suspended">Suspended</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <span>{params.row.status}</span>
            )}
          </>
        );
      },
    },

    {
      flex: 1,
      field: "accAlert",
      headerName: "Account #datafree Usage Alert",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.accAlert,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span style={textStyle}>{params.row.accAlert}</span>
          </div>
        );
      },
    },

    {
      flex: 1,
      field: "created",
      headerName: "Created",
      width: 120,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.created,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <Box sx={textStyle1}>
            {moment(params.row.created).format("DD MMM YYYY")}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.row.companyId} />
      ),
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState([]);
  const handleClose = () => {
    setUpdateStatus(false);
    setItems([]);
    setOpen(false);
  };
  const [bulkLoading, setBulkLoading] = useState(false);

  const handleBulkSave = () => {
    setBulkLoading(true);
    setSaveClick(true);
    setUpdateStatus(true);
    const newObject = {
      items: items,
      editorId: localStorage.getItem("userid"),
    };
    Service.CompanyStatusUpdation(newObject, params?.compId)
      .then((res) => {
        if (res?.data.status === "success") {
          setBulkLoading(false);
          cancelUpdate();
          setSuccessMessage(res?.data.successMessage);
          setErrorMessage(res?.data.errorMessage);
          setSelectValue();
        } else {
          getLicenseeDetails();
          cancelUpdate();
          setBulkLoading(false);
          setSelectValue();
        }
        setSaveClick(false);
      })
      .catch((err) => {});
  };
  const [progress, setProgress] = React.useState(10);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  const [items, setItems] = useState([]);

  const handleStatus = (companyIds, value, originalValue) => {
    if (value === "Archived") {
      setCompanyID(companyIds);
      setSelectValue(originalValue);
      setConfirmationBox(true);
    }
    changeStatus(companyIds, value);
  };

  const changeStatus = (companyIds, value) => {
    const result = items.find(({ companyId }) => companyId === companyIds);
    if (result !== undefined) {
      result["status"] = value;
    } else {
      setItems([
        ...items,
        {
          companyId: companyIds,
          status: value,
        },
      ]);
    }
  };

  const warnMsg = `Are your sure you want to archive this channel? \n This action cannot be undone and all users in this channel will be archived. `;
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [companyID, setCompanyID] = useState();
  const [selectValue, setSelectValue] = useState();

  const handleConfirmation = () => {
    items.pop();
    document.getElementById("status" + companyID).innerHTML = selectValue;
    setCompanyID("");
    setSelectValue();
    setConfirmationBox(false);
  };

  const confirmSave = () => {
    setConfirmationBox(false);
  };
  return (
    <Container
      maxWidth="xxl"
      style={{
        padding: "0",
      }}
    >
      {!loading && resData ? (
        <>
          {totalCount !== 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                pb: "5px",
                pr: "2px",
              }}
            >
              <PaginationHeader
                page={page}
                size={size}
                dataLength={resData.length}
                totalCount={totalCount}
              />
            </Box>
          ) : null}
          <Box
            sx={{
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor:
                  switchStatusContextVal && statusContextVal
                    ? "#373737"
                    : "#01537A !important",
                color: "white",
              },
            }}
          >
            <DataTable
              rows={resData}
              columns={columns}
              tableName="usersPanel"
              loading={loading}
              getDetailPanelContent={getDetailPanelContent}
            />
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loader
            type={"spokes"}
            color={"#01537A"}
            height={40}
            width={40}
          />
        </Box>
      )}
      {!loading && totalCount >= 10 && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Typography
            component="span"
            variant="body2"
            sx={{ color: "#01537A" }}
          >
            Items per page :{" "}
          </Typography>
          <FormControl variant="standard">
            <Select
              value={size}
              sx={{
                width: "55px",
                margin: "0px 10px 0px 5px",
                backgroundColor: "#8080802b",
                color: "#000",
                borderBottom: "1px solid #01537A",
                textAlign: "center",
                "&:after": {
                  borderBottom: "1.5px solid #01537A",
                },
                "&hover": {
                  borderBottom: "1px solid #01537A",
                },
              }}
              onChange={handlePageSize}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>{Number(10).toLocaleString()}</MenuItem>
              <MenuItem value={25}>{Number(25).toLocaleString()}</MenuItem>
              <MenuItem value={50}>{Number(50).toLocaleString()}</MenuItem>
              <MenuItem value={100}>{Number(100).toLocaleString()}</MenuItem>
            </Select>
          </FormControl>
          <PaginationRounded
            changeHandle={changePagination}
            page={page + 1}
            Count={totalCount}
            size={size}
          />
        </Box>
      )}
      {!loading && totalCount > 0 && (
        <Box
          sx={{
            padding: "20px 0px 10px 0px",
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button
            className={accessPermissionButton("cancel")}
            variant="outlined"
            onClick={cancelUpdate}
          >
            Cancel
          </Button>

          <Button
            sx={{
              "&.Mui-disabled": {
                cursor: "not-allowed !important",
                pointerEvents: "unset !important",
              },
            }}
            className={accessPermissionButton("save")}
            variant="contained"
            disabled={saveClick}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Box>
      )}
      <Dialog open={open} onClose={handleClose} scroll={"body"} maxWidth="xl">
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                marginTop: "10px",
                color: "#01537A",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "46px",
                width: "658px",
                height: "40px",
              }}
            >
              Bulk Update
            </Typography>
            {bulkLoading ? (
              ""
            ) : (
              <CloseSharpIcon
                sx={{
                  cursor: "pointer",
                  color: "#01537A",
                }}
                onClick={handleClose}
              />
            )}
          </Box>
          {updateStatus && (
            <Typography
              sx={{ padding: "0px 30px 0px 10px", color: "#373737" }}
            >{`Updated ${items.length}  ${
              items.length > 1 ? "records :" : "record :"
            } `}</Typography>
          )}
        </DialogTitle>

        <DialogContent>
          <DialogBoxContent
            bulkLoading={bulkLoading}
            progress={progress}
            updateStatus={updateStatus}
            successMessage={successMessage}
            errorMessage={errorMessage}
            items={items}
          />
        </DialogContent>

        <DialogActions>
          {!bulkLoading && !updateStatus && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 84px 30px 84px",
                gap: 2,
              }}
            >
              <Button
                className="modal-cancel-btn"
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                sx={{
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "unset !important",
                  },
                }}
                className="modal-save-btn"
                onClick={handleBulkSave}
                variant="contained"
              >
                Save
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>

      {confirmationBox && (
        <ConfirmationPopup
          open={confirmationBox}
          handleClose={handleConfirmation}
          confirmSave={confirmSave}
          message={warnMsg}
          type={"warning"}
        />
      )}
    </Container>
  );
};

export default ChannelsPanel;
