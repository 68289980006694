import {
  Box,
  Container,
  Typography,
  Divider,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  InputAdornment,
  Stack,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Services from "../../../api/services";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/loader";
import useToast from "../../../hooks/useToast";

import {
  switchStatusContext,
  statusContext,
  archivedStatusContext,
} from "../../../context/serviceContext";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Tooltips from "../../../components/tooltips";
import { regexPatterns } from "../../../config/regex";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: " 10px", paddingLeft: "20px" }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const tabStyle = {
  color: "#01537A",
  fontWeight: 500,
  "&.MuiSelected": {
    color: "#01537A",
  },
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#01537A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const DisabledButton = styled(Button)({
  background: "#01537A !important",
  color: "white",
  padding: "0px 50px",
  textTransform: "capitalize",
  "&.Mui-disabled": {
    background: "#757373 !important",
    color: "white",
    textTransform: "capitalize",
    padding: "0px 50px",
  },
});

export default function Production({ applicationDetails, appDetailsRefresh }) {
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const [value, setValue] = React.useState(0);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickDisable, setClickDisable] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [cancelDisable, setCancelDisable] = useState(true);
  const location = useLocation();
  const clickedAppId = location.state?.clickedAppId;

  const { showSuccess, showError } = useToast();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [userData, setUserData] = useState({
    monthlyLimit: "",
    alertEmail: "",
    includeDailyDataFreeLimit: 0,
    maxDailySpend: "",
    actionRequired: "0",
    contentType: "",
    maxBytesContent: "",
    applicationId: clickedAppId,
    editorId: localStorage.getItem("userid"),
    deploymentType: "PROD",
    deploymentId: "",
  });

  const [userError, setUserError] = useState({
    monthlyLimit: false,
    monthlyLimitMsg: "",
    alertEmail: false,
    alertEmailMsg: "",
    maxDailySpend: false,
    maxDailySpendMsg: "",
    actionRequired: false,
    actionRequiredMsg: "",
    contentType: false,
    contentTypeMsg: "",
    maxBytesContent: false,
    maxBytesContentMsg: "",
  });

  const areAllFieldsFilled =
    userData.includeDailyDataFreeLimit === 0
      ? userData.alertEmail !== "" &&
        userError.alertEmailMsg === "" &&
        userError.monthlyLimitMsg === "" &&
        userError.maxDailySpendMsg === "" &&
        userError.actionRequiredMsg === "" &&
        userError.contentTypeMsg === "" &&
        userError.maxBytesContentMsg === ""
      : userData.alertEmail !== "" &&
        userData?.maxDailySpend !== "" &&
        userError.alertEmailMsg === "" &&
        userError.monthlyLimitMsg === "" &&
        userError.maxDailySpendMsg === "" &&
        userError.actionRequiredMsg === "" &&
        userError.contentTypeMsg === "" &&
        userError.maxBytesContentMsg === "";

  function formatBytes(bytes) {
    let decimals = 1;
    if (bytes === 0) return "0";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  const convertToBytes = (value) => {
    const units = {
      B: 1,
      KB: 1024,
      MB: 1024 * 1024,
      GB: 1024 * 1024 * 1024,
      TB: 1024 * 1024 * 1024 * 1024,
    };
    const unitMatch = value.match(/[a-zA-Z]+$/);
    let unit = "B"; // Default unit to bytes if not provided
    let number = parseFloat(value);

    if (unitMatch) {
      unit = unitMatch[0];
      number = value.replace(unit, "").trim(); // Remove the unit from the value
    }

    return number * (units[unit.toUpperCase()] || 1);
  };

  const handleInputChange = (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    setCancelDisable(false);

    switch (e.target.name) {
      case "monthlyLimit":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            monthlyLimit: false,
            monthlyLimitMsg: "",
          });
        } else {
          if (regexPatterns.pattern.test(e.target.value) === false) {
            setUserError({
              ...userError,
              monthlyLimit: true,
              monthlyLimitMsg: "Invalid Format",
            });
            setClickDisable(true);
          } else if (regexPatterns.pattern.test(e.target.value) === true) {
            const dailyInKB = convertToBytes(userData?.maxDailySpend);
            const monthlyInKB = convertToBytes(e.target.value);
            if (dailyInKB > monthlyInKB) {
              setUserError({
                ...userError,
                monthlyLimit: false,
                monthlyLimitMsg: "",
                maxDailySpend: true,
                maxDailySpendMsg:
                  "Max daily spend should not be greater than monthly limit.",
              });
              setClickDisable(true);
            } else {
              setUserError({
                ...userError,
                monthlyLimit: false,
                monthlyLimitMsg: "",
                maxDailySpend: false,
                maxDailySpendMsg: "",
              });
              setClickDisable(false);
            }
          } else {
            setUserError({
              ...userError,
              monthlyLimit: false,
              monthlyLimitMsg: "",
            });
            setClickDisable(false);
          }
        }
        break;

      case "alertEmail":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            alertEmail: true,
            alertEmailMsg: "This field is required",
          });
          setClickDisable(true);
        } else {
          if (!regexPatterns.emailRegex.test(e.target.value)) {
            setUserError({
              ...userError,
              alertEmail: true,
              alertEmailMsg: "Enter a valid list of email addresses",
            });
            setClickDisable(true);
          } else {
            setUserError({
              ...userError,
              alertEmail: false,
              alertEmailMsg: "",
            });
            setClickDisable(false);
          }

          const alertEmailList = newData.alertEmail
            .replace(/,\s+/g, ",")
            .split(",");
          alertEmailList.pop();
          const enteredEmail = e.target.value.split(",");
          if (alertEmailList.includes(enteredEmail[enteredEmail.length - 1])) {
            newData[e.target.name] = alertEmailList;
          }
        }
        break;

      case "maxDailySpend":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            maxDailySpend: true,
            maxDailySpendMsg: "This field is required",
          });
          setClickDisable(true);
        } else {
          if (regexPatterns.pattern.test(e.target.value) === false) {
            setUserError({
              ...userError,
              maxDailySpend: true,
              maxDailySpendMsg: "Invalid Format",
            });
            setClickDisable(true);
          } else if (regexPatterns.pattern.test(e.target.value) === true) {
            const dailyInKB = convertToBytes(e.target.value);
            const monthlyInKB = convertToBytes(userData?.monthlyLimit);
            if (dailyInKB > monthlyInKB) {
              setUserError({
                ...userError,
                maxDailySpend: true,
                maxDailySpendMsg:
                  "Max daily spend should not be greater than monthly limit.",
              });
              setClickDisable(true);
            } else {
              setUserError({
                ...userError,
                maxDailySpend: false,
                maxDailySpendMsg: "",
              });
              setClickDisable(false);
            }
          } else {
            setUserError({
              ...userError,
              maxDailySpend: false,
              maxDailySpendMsg: "",
            });
            setClickDisable(false);
          }
        }
        break;

      case "actionRequired":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            actionRequired: false,
            actionRequiredMsg: "",
          });
        } else {
          setUserError({
            ...userError,
            actionRequired: false,
            actionRequiredMsg: "",
          });
          setClickDisable(false);
        }
        break;

      case "contentType":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            contentType: false,
            contentTypeMsg: "",
          });
        } else {
          if (!regexPatterns.contentTypePattern.test(e.target.value)) {
            setUserError({
              ...userError,
              contentType: true,
              contentTypeMsg: "Please enter a valid content type",
            });
            setClickDisable(true);
          } else {
            setUserError({
              ...userError,
              contentType: false,
              contentTypeMsg: "",
            });
            setClickDisable(false);
          }
          const contentTypeList = newData.contentType
            .replace(/,\s+/g, ",")
            .split(",");
          contentTypeList.pop();
          const enteredContentType = e.target.value.split(",");
          if (
            contentTypeList.includes(
              enteredContentType[enteredContentType.length - 1]
            )
          ) {
            const NewContentType = String(contentTypeList);
            newData[e.target.name] = NewContentType;
          }
          setClickDisable(false);
        }
        break;

      case "maxBytesContent":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            maxBytesContent: false,
            maxBytesContentMsg: "",
          });
        } else {
          if (regexPatterns.pattern.test(e.target.value) === false) {
            setUserError({
              ...userError,
              maxBytesContent: true,
              maxBytesContentMsg: "Invalid Format",
            });
            setClickDisable(true);
          } else {
            setUserError({
              ...userError,
              maxBytesContent: false,
              maxBytesContentMsg: "",
            });
            setClickDisable(false);
          }
        }
        break;

      default:
        break;
    }

    setUserData(newData);
  };
  const [resData, setResData] = useState();
  async function getReachTabDetails() {
    setLoading(true);
    Services.getSecurityTabDetails({
      applicationId: clickedAppId,
      deploymentType: "PROD",
    })
      .then((res) => {
        setLoading(false);
        setResData(res?.data);
        if (res?.data?.applicationDeploymentId !== "") {
          setUserData({
            ...userData,
            monthlyLimit: res?.data?.monthlyLimit
              ? formatBytes(res?.data?.monthlyLimit)
              : "",
            alertEmail: res?.data?.alertEmail ? res?.data?.alertEmail : "",
            includeDailyDataFreeLimit:
              res?.data?.actionRequired || res?.data?.maxDailySpend ? 1 : 0,

            deploymentId: res?.data?.applicationDeploymentId
              ? res?.data?.applicationDeploymentId
              : "",

            maxBytesContent: res?.data?.maxBytesContent
              ? formatBytes(res?.data?.maxBytesContent)
              : "",

            actionRequired: res?.data?.actionRequired
              ? res?.data?.actionRequired
              : "0",
            maxDailySpend: res?.data?.maxDailySpend
              ? formatBytes(res?.data?.maxDailySpend)
              : "",

            contentType: res?.data?.contentType ? res?.data?.contentType : "",
          });
        }
      })
      .catch((err) => {});
  }

  const handleSave = () => {
    let errorCount = validate(userData);
    if (errorCount === 0) {
      setLoad(true);
      setDisableButton(true);
      let payload = {
        monthlyLimit:
          userData?.monthlyLimit && convertToBytes(userData?.monthlyLimit),
        alertEmail: userData?.alertEmail,
        includeDailyDataFreeLimit: userData?.includeDailyDataFreeLimit,
        maxDailySpend:
          userData?.maxDailySpend && convertToBytes(userData?.maxDailySpend),

        actionRequired: userData?.actionRequired,
        contentType: userData?.contentType,
        maxBytesContent:
          userData?.maxBytesContent &&
          convertToBytes(userData?.maxBytesContent),

        applicationId: clickedAppId,
        editorId: localStorage.getItem("userid"),
        deploymentType: "PROD",

        deploymentId: userData?.deploymentId,
      };
      Services.reachTabDetails(payload)
        .then((res) => {
          setLoad(false);
          if (res?.data.status === "success") {
            getReachTabDetails();
            showSuccess(res?.data.message);
          } else {
            showError(res?.data.message);
          }
          appDetailsRefresh(true);
          handleCancel();
        })
        .catch((err) => {});
      appDetailsRefresh(false);
    } else {
      return false;
    }
  };

  const validate = (data) => {
    const userErrors = { ...userError };
    const objString = JSON.stringify(data?.alertEmail);

    const hasComma = objString.includes(",");

    let count = 0;
    if (data?.monthlyLimit === "") {
      userErrors.monthlyLimit = false;
      userErrors.monthlyLimitMsg = "";
    } else {
      if (regexPatterns.pattern.test(data?.monthlyLimit) === false) {
        userErrors.monthlyLimit = true;
        userErrors.monthlyLimitMsg = "Invalid Format";
        count++;
      } else {
        userErrors.monthlyLimit = false;
        userErrors.monthlyLimitMsg = "";
      }
    }
    if (hasComma) {
      const alertEmailList = objString.replace(/,\s+/g, ",").split(",");
      alertEmailList.pop();
      const enteredEmail = objString.split(",");
      if (alertEmailList.includes(enteredEmail[enteredEmail.length - 1])) {
        userData.alertEmail = alertEmailList;
        userErrors.alertEmail = true;
        userErrors.alertEmailMsg = "Email already exists.";
        count++;
      }
    }
    if (data.alertEmail === "") {
      userErrors.alertEmail = true;
      userErrors.alertEmailMsg = "This field is required";
      count++;
    } else if (!regexPatterns.emailRegex.test(data.alertEmail)) {
      userErrors.alertEmail = true;
      userErrors.alertEmailMsg = "Enter a valid list of email addresses";
      count++;
    } else {
      userErrors.alertEmail = false;
      userErrors.alertEmailMsg = "";
    }

    if (data?.includeDailyDataFreeLimit === 1) {
      if (data?.maxDailySpend === "") {
        userErrors.maxDailySpend = true;
        userErrors.maxDailySpendMsg = "This field is required";
        count++;
      } else {
        if (regexPatterns.pattern.test(data?.maxDailySpend) === false) {
          userErrors.maxDailySpend = true;
          userErrors.maxDailySpendMsg = "Invalid Format";
          count++;
        } else {
          userErrors.maxDailySpend = false;
          userErrors.maxDailySpendMsg = "";
        }
      }

      if (data?.actionRequired === "") {
        userErrors.actionRequired = false;
        userErrors.actionRequiredMsg = "";
      } else {
        userErrors.actionRequired = false;
        userErrors.actionRequiredMsg = "";
      }
    } else if (data?.includeDailyDataFreeLimit === 0) {
      if (data?.contentType === "") {
        userErrors.contentType = false;
        userErrors.contentTypeMsg = "";
      } else {
        if (
          regexPatterns.contentTypePattern.test(data?.contentType) === false
        ) {
          userErrors.contentType = true;
          userErrors.contentTypeMsg = "Invalid Format";
          count++;
        } else {
          userErrors.contentType = false;
          userErrors.contentTypeMsg = "";
        }
      }
    }

    if (data?.maxBytesContent === "") {
      userErrors.maxBytesContent = false;
      userErrors.maxBytesContentMsg = "";
    } else {
      if (regexPatterns.pattern.test(data?.maxBytesContent) === false) {
        userErrors.maxBytesContent = true;
        userErrors.maxBytesContentMsg = "Invalid Format";
        count++;
      } else {
        userErrors.maxBytesContent = false;
        userErrors.maxBytesContentMsg = "";
      }
    }

    setUserError(userErrors);
    return count;
  };

  const handleCancel = () => {
    setUserData({
      ...userData,
      monthlyLimit: "",
      alertEmail: "",
      includeDailyDataFreeLimit: 0,
      maxDailySpend: "",
      actionRequired: "0",
      contentType: "",
      maxBytesContent: "",
    });
    setUserError({
      ...userError,
      monthlyLimit: false,
      monthlyLimitMsg: "",
      alertEmail: false,
      alertEmailMsg: "",
      maxDailySpend: false,
      maxDailySpendMsg: "",
      actionRequired: false,
      actionRequiredMsg: "",
      contentType: false,
      contentTypeMsg: "",
      maxBytesContent: false,
      maxBytesContentMsg: "",
    });
    getReachTabDetails();
    setClickDisable(true);
    setLoad(false);
    setDisableButton(false);
    setCancelDisable(true);
  };

  const handleSwitch = (e) => {
    setCancelDisable(false);
    const typeValue = e.target.checked ? 1 : 0;
    if (typeValue === 0) {
      setUserData({
        ...userData,
        maxDailySpend: "",
        actionRequired: "0",
        includeDailyDataFreeLimit: typeValue,
      });
      setClickDisable(false);
      setUserError({
        ...userError,
        maxDailySpend: false,
        maxDailySpendMsg: "",
      });
    } else if (typeValue === 1) {
      if (!userData?.maxDailySpend) {
      }
      setUserData({
        ...userData,
        includeDailyDataFreeLimit: typeValue,
        maxDailySpend: resData?.maxDailySpend ? resData?.maxDailySpend : "",
        actionRequired: resData?.actionRequired ? resData?.actionRequired : "0",
      });
      setClickDisable(false);
    }
  };

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    getReachTabDetails();
    //eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xxl">
      <Box sx={{ width: "100%", background: "white" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: "#01537A",
                marginBottom: 4,
                position: "absolute",
                width: "6.1rem",
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab style={tabStyle} label="Security" {...a11yProps(0)} />
          </Tabs>
          <Divider
            sx={{
              color: "#DFDFDF",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              marginTop: "6px",
              borderBottomWidth: 1,
            }}
          ></Divider>
        </Box>
        <TabPanel value={value} index={0}>
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="2px"
            >
              {loading ? (
                <Loader
                  type={"spokes"}
                  color={"#01537A"}
                  height={40}
                  width={40}
                />
              ) : null}
            </Box>
            <Box
              sx={{
                width: {
                  xs: "50px",
                  sm: "240px",
                  md: "500px",
                  lg: "700px",
                  xl: "1000px",
                },
              }}
            >
              <TextField
                sx={{
                  width: "100%",
                  "& input::placeholder": {
                    color: "#757373",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    opacity: "1",
                    fontWeight: "400",
                  },
                  marginBottom: "5px",
                  marginTop: "10px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!accessPermissionFields() && (
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      )}
                    </InputAdornment>
                  ),
                  readOnly: accessPermissionFields() && true,
                }}
                value={userData?.monthlyLimit}
                error={!!userError?.monthlyLimit}
                onChange={handleInputChange}
                name="monthlyLimit"
                variant="standard"
                placeholder="Enter the data usage alert limits (bytes)"
                helperText={
                  userError?.monthlyLimit ? userError?.monthlyLimitMsg : ""
                }
              />
              {userData?.monthlyLimit !== "" && (
                <Typography
                  sx={{
                    width: "fit-content",
                    color: accessPermissionFields() ? "#373737" : "#01537A",
                    borderColor: accessPermissionFields()
                      ? "#373737"
                      : "#01537A !important",
                    textTransform: "capitalize",
                  }}
                  variant="outlined"
                >
                  Data usage alert limits bytes
                </Typography>
              )}
              <TextField
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "5px",
                  "& input::placeholder": {
                    color: "#757373",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    opacity: "1",
                    fontWeight: "400",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!accessPermissionFields() && (
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      )}
                    </InputAdornment>
                  ),
                  readOnly: accessPermissionFields() && true,
                }}
                value={userData?.alertEmail}
                error={!!userError?.alertEmail}
                onChange={handleInputChange}
                name="alertEmail"
                placeholder="Enter the alert Email Addresses"
                variant="standard"
                helperText={
                  userError?.alertEmail ? userError?.alertEmailMsg : ""
                }
              />
              {userData?.alertEmail !== "" && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    letterSpacing: "0.4px",
                    color: "#01537A",
                  }}
                >
                  Alert Email Addresses
                </Typography>
              )}
              <Box
                sx={{
                  borderRadius: "6px",
                  borderBottomWidth: 1,
                  marginBottom:
                    userData.includeDailyDataFreeLimit === 1 ? "20px" : "10px",
                  border:
                    userData.includeDailyDataFreeLimit === 1 &&
                    "1px solid #373737",
                  padding:
                    userData.includeDailyDataFreeLimit === 1 ? "10px" : "0px",
                  marginTop:
                    userData.includeDailyDataFreeLimit === 1 ? "20px" : "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    color: "#1B2935",
                  }}
                >
                  Include Daily #datafree Data Limit Alerting?
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    No
                  </Typography>
                  <IOSSwitch
                    name="includeDailyDataFreeLimit"
                    checked={Boolean(userData.includeDailyDataFreeLimit)}
                    onChange={handleSwitch}
                    inputProps={{ "aria-label": "ant design" }}
                    disabled={accessPermissionFields()}
                  />
                  <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    Yes
                  </Typography>
                </Stack>
                {userData.includeDailyDataFreeLimit === 1 && (
                  <>
                    <TextField
                      sx={{
                        width: "100%",
                        marginTop: "15px",
                        marginBottom: "5px",
                        "& input::placeholder": {
                          color: "#757373",
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                          opacity: "1",
                          fontWeight: "400",
                        },
                      }}
                      value={userData?.maxDailySpend}
                      error={!!userError?.maxDailySpend}
                      onChange={handleInputChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!accessPermissionFields() && (
                              <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                            )}
                          </InputAdornment>
                        ),
                        readOnly: accessPermissionFields() && true,
                      }}
                      name="maxDailySpend"
                      placeholder="Daily #Datafree Data Limit (Bytes)"
                      variant="standard"
                      helperText={
                        userError?.maxDailySpend
                          ? userError?.maxDailySpendMsg
                          : ""
                      }
                    />
                    {userData?.maxDailySpend !== "" && (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                          letterSpacing: "0.4px",
                          color: "#01537A",
                        }}
                      >
                        Daily #Datafree Data Limit (Bytes)
                      </Typography>
                    )}
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <Select
                        inputProps={{
                          sx: {
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "16px",
                              lg: "16px",
                              xl: "16px",
                            },
                            fontWeight: 400,
                            letterSpacing: 0.5,
                            color: "rgba(0, 0, 0, 0.87)",
                          },
                        }}
                        inputlabelprops={{
                          sx: {
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "16px",
                              lg: "16px",
                              xl: "16px",
                            },
                            fontWeight: 400,
                          },
                        }}
                        onChange={handleInputChange}
                        value={userData?.actionRequired}
                        error={userError?.actionRequired}
                        name="actionRequired"
                      >
                        <MenuItem value={"0"}>Send Alerts Only</MenuItem>
                        <MenuItem value={"1"}>
                          Send Alerts and Stop App
                        </MenuItem>
                      </Select>
                      <FormHelperText
                        sx={{
                          color: "#d32f2f",
                        }}
                      >
                        {userError?.actionRequired
                          ? userError?.actionRequiredMsg
                          : ""}
                      </FormHelperText>
                    </FormControl>

                    {userData?.actionRequired !== "" && (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                          letterSpacing: "0.4px",
                          color: "#01537A",
                        }}
                      >
                        Required Action on Daily Limit
                      </Typography>
                    )}
                  </>
                )}
              </Box>

              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                    md: "18px",
                    lg: "18px",
                    xl: "18px",
                  },
                  color: "#01537A",
                }}
              >
                Content Objects
              </Typography>
              <Divider
                sx={{
                  borderRadius: "6px",
                  borderBottomWidth: 1,
                  marginBottom: "20px",
                }}
              ></Divider>
              <TextField
                sx={{
                  width: "100%",
                  "& input::placeholder": {
                    color: "#757373",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    opacity: "1",
                    fontWeight: "400",
                  },
                  marginBottom: "5px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!accessPermissionFields() && (
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      )}
                    </InputAdornment>
                  ),
                  readOnly: accessPermissionFields() && true,
                }}
                value={userData?.maxBytesContent}
                error={!!userError?.maxBytesContent}
                onChange={handleInputChange}
                name="maxBytesContent"
                placeholder="Enter the max bytes per content object"
                variant="standard"
                helperText={
                  userError?.maxBytesContent
                    ? userError?.maxBytesContentMsg
                    : ""
                }
              />
              {userData?.maxBytesContent !== "" && (
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                      letterSpacing: "0.4px",
                      color: "#01537A",
                      lineHeight: "18.75px",
                    }}
                  >
                    Max bytes per content object
                  </Typography>
                </Box>
              )}

              <TextField
                sx={{
                  width: "100%",
                  "& input::placeholder": {
                    color: "#757373",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    opacity: "1",
                    fontWeight: "400",
                  },
                  marginBottom: "5px",
                  marginTop: "10px",
                }}
                value={userData?.contentType}
                error={!!userError?.contentType}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {userData?.contentType ? (
                        ""
                      ) : (
                        <Tooltips type="mimeTypes">
                          <ErrorOutlineIcon
                            sx={{
                              cursor: "pointer",
                              bottom: "7px",
                              color: "#01537A",
                              fontSize: {
                                xs: "13px",
                                sm: "15px",
                                md: "19px",
                                lg: "19px",
                                xl: "19px",
                              },
                              position: "absolute",
                              left: {
                                xs: "50px",
                                sm: "214px",
                                md: "412px",
                                xl: "411px",
                              },
                            }}
                          />
                        </Tooltips>
                      )}

                      {!accessPermissionFields() && (
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      )}
                    </InputAdornment>
                  ),
                  readOnly: accessPermissionFields() && true,
                }}
                name="contentType"
                variant="standard"
                placeholder="Mime Types to be blocked (Multiple types can be added)"
                helperText={
                  userError?.contentType ? userError?.contentTypeMsg : ""
                }
              />
              {userData?.contentType !== "" && (
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                      letterSpacing: "0.4px",
                      color: "#01537A",
                      lineHeight: "18.75px",
                    }}
                  >
                    Mime Types to be blocked (Multiple types can be added)
                  </Typography>

                  <Tooltips type="mimeTypes">
                    <ErrorOutlineIcon
                      sx={{
                        cursor: "pointer",
                        marginLeft: "15px",
                        color: "#01537A",
                        fontSize: "19px",
                      }}
                    />
                  </Tooltips>
                </Box>
              )}
            </Box>
            {/* save and Cancel */}
            <Box
              sx={{
                display: "flex",
                width: "100%",

                justifyContent: "space-between",
                paddingTop: "30px",
                paddingRight: "20px",
                paddingBottom: "30px",
              }}
            >
              <Button
                className={accessPermissionFields() ? "disable" : null}
                onClick={handleCancel}
                disabled={cancelDisable}
                sx={{
                  "&.Mui-disabled": {
                    color: "#757373",
                    textTransform: "capitalize",
                    padding: " 5px 40px 5px 40px",
                    borderColor: "#757373 !important",
                  },
                  padding: " 5px 40px 5px 40px",
                  width: "fit-content",
                  color: accessPermissionFields() ? "#373737" : "#01537A",
                  borderColor: accessPermissionFields()
                    ? "#373737"
                    : "#01537A !important",
                  textTransform: "capitalize",
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <DisabledButton
                className={
                  accessPermissionFields() || disableButton ? "disable" : null
                }
                onClick={handleSave}
                disabled={!areAllFieldsFilled || clickDisable}
                sx={{
                  padding: " 0px 50px 0px 50px",
                  width: "fit-content",
                  background: accessPermissionFields()
                    ? "#373737!important"
                    : "#01537A !important",
                  textTransform: "capitalize",
                }}
                variant="contained"
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Save</span>
                  {load ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"white"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </DisabledButton>
            </Box>
          </>
        </TabPanel>
      </Box>
    </Container>
  );
}
