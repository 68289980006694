import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Services from "../api/services";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltips from "../components/tooltips";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";

export default function UrlCheck({ gatewayDomain }) {
  const [healthCheckStatus, setHealthCheckStatus] = useState("checking");
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  const getStatusIcon = (status) => {
    switch (status) {
      case "success":
        return (
          <TaskAltRoundedIcon sx={{ color: "#4CAF50", fontSize: "18px" }} />
        );
      case "error":
        return (
          <HighlightOffRoundedIcon
            sx={{ color: "#F44336", fontSize: "18px" }}
          />
        );
      case "failed":
        return (
          <HighlightOffRoundedIcon
            sx={{ color: "#F44336", fontSize: "18px" }}
          />
        );
      case "checking":
        return <LoopRoundedIcon sx={{ color: "#0398D2", fontSize: "18px" }} />;
      default:
        return null;
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "success":
        return "Healthy";
      case "error":
        return "Failed";
      case "failed":
        return "Failed";
      case "checking":
        return "Checking";
      default:
        return "";
    }
  };

  const renderStatus = (status) => (
    <Box display="flex" alignItems="center" marginTop="10px">
      {getStatusIcon(status)}
      <Typography sx={{ marginLeft: "8px", color: "#757373" }}>
        {getStatusText(status)}
      </Typography>
    </Box>
  );

  useEffect(() => {
    const healthCheck = async () => {
      if (retryCount >= 2) {
        return;
      }

      setHealthCheckStatus("checking");
      setError(null);

      try {
        const res = await Services.healthCheck({ gatewayDomain });
        const status = res?.data?.status || "failed";
        setHealthCheckStatus(status);

        if (status === "failed" || status === "error") {
          setRetryCount((prevRetryCount) => prevRetryCount + 1);
        }
      } catch (err) {
        setRetryCount((prevRetryCount) => prevRetryCount + 1);
        if (retryCount >= 2) {
          setError("Failed to fetch health check status");
          setHealthCheckStatus("failed");
        }
      }
    };

    if (retryCount < 3 && healthCheckStatus !== "success") {
      healthCheck();
    }
  }, [gatewayDomain, retryCount, healthCheckStatus]);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography
          sx={{
            color: "#01537A",
            fontSize: "16px",
            fontWeight: "400",
            marginTop: "20px",
          }}
        >
          Health Check
        </Typography>
        <Tooltips type="healthCheck">
          <InfoOutlinedIcon
            sx={{
              color: "#01537A",
              fontSize: "17px",
              marginLeft: "3px",
              marginTop: "20px",
            }}
          />
        </Tooltips>
      </Box>

      {/* Status rendering */}
      {renderStatus(healthCheckStatus)}

      {error && (
        <Typography sx={{ color: "#F44336", marginTop: "10px" }}>
          {error}
        </Typography>
      )}
    </Box>
  );
}
