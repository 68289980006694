import React, { useState, useContext, useEffect } from "react";
import { Box, TextField, Typography, Button, ButtonGroup } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {
  statusContext,
  archivedStatusContext,
  switchStatusContext,
} from "../../../../context/serviceContext";
import Services from "../../../../api/services";
import { useParams, useLocation } from "react-router-dom";
import Loader from "../../../../components/loader";
import useToast from "../../../../hooks/useToast";
import CheckIcon from "@mui/icons-material/Check";
import { WarningMessages } from "../../../../config/messages";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function EntryPoint({
  applicationDetails,
  refreshApi,
  appDetailsRefresh,
  getValidateStatus,
  isShow,
  setShow,
}) {
  const params = useParams();
  const location = useLocation();
  const selectedAppId = location.state.clickedAppId;
  const [isValid, setValid] = useState(true);
  const [loadData, setLoadData] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableXml, setDisableXmlButton] = useState(false);
  const [loads, setLoads] = useState(false);
  const [load, setLoad] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [cancelDisable, setCancelDisable] = useState(true);
  const [errorMsgDisplay, setErrorMsgDisplay] = useState(false);
  const [xmlData, setXmlData] = useState({
    xmlDocFile: "",
    xmlFile: "",
    fileType: "appNode",
    entryPoint: "",
    xmlField: "",
    xmlValidate: false,
  });

  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const { showSuccess, showError } = useToast();

  const handleInputChange = (e) => {
    const newData = { ...xmlData };
    newData[e.target.name] = e.target.value;
    setErrorMsgDisplay(true);
    setValidate(0);
    setShow(false);
    if (e.target.value.length !== 0) {
      setXmlData(newData);
      getValidateStatus(false);
      setDisableXmlButton(false);
      setCancelDisable(false);
      setValid(true);
    } else {
      setXmlData(newData);
      setDisableXmlButton(true);
    }
  };

  const handleType = (typeValue) => {
    if (typeValue === "appNode") {
      setXmlData({
        ...xmlData,
        fileType: typeValue,
        xmlFile: xmlData?.xmlFile,
      });
    } else {
      setXmlData({
        ...xmlData,
        fileType: typeValue,
        xmlDocFile: xmlData?.xmlDocFile,
      });
    }
  };

  const handleSave = () => {
    let payload = {
      applicationType: "wrap",
      xmlStatus: validate,
      deploymentType: "DEV",
      type: xmlData?.fileType === "xml" ? "xml" : "appNode",
    };

    setDisableButton(true);
    setLoad(true);
    Services.updateEntryPoint(selectedAppId, payload)
      .then((res) => {
        setDisableButton(false);
        setLoad(false);
        if (res?.data.status === "success") {
          setDisableXmlButton(true);
          showSuccess(WarningMessages?.updated);
          appDetailsRefresh(true);
          setValidate(1);
        }
        getWrapEntryPoint();
        setLoad(false);
        setDisableButton(false);
        setValid(true);
        setLoads(false);
        setErrorMsgDisplay(false);
        setCancelDisable(true);
      })
      .catch((err) => {});
    appDetailsRefresh(false);
  };
  const handleCancel = () => {
    getWrapEntryPoint();
    setLoad(false);
    setDisableButton(false);
    setValid(true);
    setLoads(false);
    setValidate();
    setErrorMsgDisplay(false);

    setCancelDisable(true);
    setShow(false);
  };
  const [validate, setValidate] = useState();
  const [loading, setLoading] = useState(false);

  const validateXML = () => {
    setCancelDisable(false);
    setDisableXmlButton(true);
    setLoads(true);
    setValidate();
    const data = {
      applicationId: selectedAppId,
      deploymentType: "DEV",
      docXml: xmlData?.xmlDocFile,
      appNodeXml: xmlData?.xmlFile,
    };
    Services.validateXML(data)
      .then((res) => {
        setLoads(false);
        if (res.status === 200) {
          setValidate(res.data?.status === "success" ? 1 : 0);
          if (res.data?.status === "success") {
            setValid(false);
            setDisableButton(false);
            setDisableXmlButton(true);
            getValidateStatus(true);
          } else {
            setValid(true);
            setDisableButton(true);
            getValidateStatus(false);
            setDisableXmlButton(false);

            showError(WarningMessages?.xmlNotValid);
          }
        }
      })
      .catch((error) => {});
  };

  async function getWrapEntryPoint() {
    setLoading(true);
    Services.getWrapEntryPoint({
      applicationId: selectedAppId,
      deploymentType: "DEV",
    })
      .then((res) => {
        if (res.status === 200) {
          setLoadData(
            res?.data?.xmlValidated ? res?.data?.xmlValidated : false
          );
          setXmlData({
            ...xmlData,
            xmlDocFile: res?.data?.xmlDocFile ? res?.data?.xmlDocFile : "",
            xmlFile: res?.data?.xmlFile ? res?.data?.xmlFile : "",
            xmlValidate: res?.data?.xmlValidated
              ? res?.data?.xmlValidated
              : false,
            xmlField:
              xmlData.fileType === "appNode"
                ? res?.data?.xmlFile === undefined
                  ? ""
                  : res?.data?.xmlFile
                : res?.data?.xmlDocFile === undefined
                ? ""
                : res?.data?.xmlDocFile,
            fileType: "appNode",
          });
          setLoading(false);
        }
      })
      .catch((err) => {});
  }

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const accessPermission = (status, checkStatus) => {
    if (
      accessControls() &&
      !switchStatusContextVal &&
      !archivedStatusContextVal &&
      applicationDetails?.data?.status !== "Deleted"
    ) {
      if (status === checkStatus) {
        return "btn-active";
      } else {
        return "btn-nonactive";
      }
    } else if (applicationDetails?.data?.status === "Deleted") {
      if (status === checkStatus) {
        return "btn-disable-active disable";
      } else {
        return "btn-disable-nonactive disable";
      }
    } else {
      if (userRole === "ROLE_CHANNEL") {
        if (status === checkStatus) {
          return "btn-disable-active disable";
        } else {
          return "btn-disable-nonactive disable";
        }
      } else {
        if (statusContextVal) {
          if (status === checkStatus) {
            return "btn-disable-active disable";
          } else {
            return "btn-disable-nonactive disable";
          }
        } else if (archivedStatusContextVal) {
          if (status === checkStatus) {
            return "btn-active disable";
          } else {
            return "btn-nonactive disable";
          }
        } else {
          if (
            status === checkStatus ||
            applicationDetails?.data?.status === "Deleted"
          ) {
            return "btn-active";
          } else {
            return "btn-nonactive";
          }
        }
      }
    }
  };
  const userRole = localStorage.getItem("role");

  const accessControls = () => {
    if (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE") {
      return true;
    } else {
      return false;
    }
  };
  const tld = "testct.datafr.ee";

  const devXmlAssistant = () => {
    if (applicationDetails?.data?.devEntryPointUrl) {
      window.open(
        `http://validator.${tld}/validate/gui?url=${applicationDetails?.data?.devEntryPointUrl}`,
        "_blank"
      );
    }
  };

  const disableValidateXml = !xmlData?.xmlFile && !xmlData?.xmlDocFile;

  useEffect(() => {
    if (refreshApi) {
      setTriggerRefresh(true);
    }
  }, [refreshApi]);

  useEffect(() => {
    if (triggerRefresh) {
      setLoading(true);
      getWrapEntryPoint();
      setTriggerRefresh(false);
    }
    // eslint-disable-next-line
  }, [triggerRefresh]);

  useEffect(() => {
    getWrapEntryPoint();

    //eslint-disable-next-line
  }, [loadData]);

  useEffect(() => {
    if (validate === 0) {
      setErrorMsgDisplay(true);
    } else {
      setErrorMsgDisplay(false);
    }
    //eslint-disable-next-line
  }, [validate]);
  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="center" mt="2px">
        {loading ? (
          <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
        ) : null}
      </Box>
      <Box
        sx={{
          width: {
            xs: "50px",
            sm: "339px",
            md: "500px",
            lg: "700px",
            xl: "1000px",
          },
        }}
      >
        <Box>
          {" "}
          {isShow && (
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <CheckIcon
                sx={{
                  color: "#4CAF50",
                }}
              />
              <Typography
                sx={{
                  color: "#4CAF50",
                }}
              >
                XML Validated
              </Typography>
            </Box>
          )}
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              marginTop: "10px",
              color: "#1B2935",
            }}
          >
            File
          </Typography>
          <ButtonGroup>
            <Button
              type="button"
              sx={{
                width: "290px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                handleType("appNode");
              }}
              startIcon={xmlData?.fileType === "appNode" ? <DoneIcon /> : ""}
              className={accessPermission("appNode", xmlData?.fileType)}
            >
              App Node
            </Button>

            <Button
              type="button"
              sx={{
                width: "290px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                handleType("xml");
              }}
              startIcon={xmlData?.fileType === "xml" ? <DoneIcon /> : ""}
              className={accessPermission("xml", xmlData?.fileType)}
            >
              XML
            </Button>
          </ButtonGroup>
        </Box>

        {errorMsgDisplay && (
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              marginTop: "20px",
            }}
          >
            <InfoOutlinedIcon
              sx={{
                color: "#B3261E",
              }}
            />
            <Typography
              sx={{
                color: "#B3261E",
              }}
            >
              XML Not Validated
            </Typography>
          </Box>
        )}

        {xmlData?.fileType === "appNode" ? (
          <TextField
            sx={{
              width: "100%",
              marginTop: "20px",
            }}
            id="standard-multiline-flexible"
            inputProps={{
              readOnly: accessPermissionFields() && true,
            }}
            multiline
            maxRows={Infinity}
            variant="standard"
            name="xmlFile"
            value={xmlData?.xmlFile !== null ? xmlData?.xmlFile : ""}
            onChange={handleInputChange}
          />
        ) : (
          <TextField
            sx={{
              width: "100%",
              marginTop: "20px",
            }}
            id="standard-multiline-flexible"
            inputProps={{
              readOnly: accessPermissionFields() && true,
            }}
            multiline
            maxRows={Infinity}
            variant="standard"
            name="xmlDocFile"
            value={xmlData?.xmlDocFile !== null ? xmlData?.xmlDocFile : ""}
            onChange={handleInputChange}
          />
        )}

        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexDirection: { sm: "column", md: "row" },
            gap: "10px",
          }}
        >
          <Button
            onClick={devXmlAssistant}
            className={accessPermissionFields() ? "disable" : null}
            sx={{
              padding: " 5px 40px 5px 40px",
              width: "fit-content",

              color: accessPermissionFields() ? "#373737" : "#01537A",
              borderColor: accessPermissionFields()
                ? "#373737"
                : "#01537A !important",
              textTransform: "capitalize",
              fontWeight: "400",
            }}
            variant="outlined"
          >
            Launch XML Assistant
          </Button>
          <Button
            className={
              accessPermissionFields() || disableXml ? "disable" : null
            }
            disabled={disableXml || disableValidateXml}
            sx={{
              "&.Mui-disabled": {
                color: "#757373 !important",
                textTransform: "capitalize",
                padding: " 5px 40px 5px 40px",
                borderColor: "#757373 !important",
              },
              padding: "5px 50px 5px 50px",
              width: "fit-content",
              color: accessPermissionFields()
                ? "#373737!important"
                : "#01537A!important",
              borderColor: accessPermissionFields()
                ? "#373737!important"
                : "#01537A !important",
              textTransform: "capitalize",
              fontWeight: "400",
            }}
            variant="outlined"
            onClick={validateXML}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <span style={{ marginRight: "5px" }}>Validate XML</span>
              {loads ? (
                <Loader
                  type={"spinningBubbles"}
                  color={"#01537A"}
                  height={20}
                  width={20}
                />
              ) : (
                ""
              )}
            </Box>
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          width: {
            xs: "50px",
            sm: "382px",
            md: "622px",
            lg: "867px",
            xl: "1172px",
          },
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
          paddingTop: "30px",
          paddingRight: "20px",
          paddingBottom: "30px",
        }}
      >
        <Button
          className={accessPermissionFields() ? "disable" : null}
          onClick={handleCancel}
          disabled={cancelDisable}
          sx={{
            "&.Mui-disabled": {
              color: "#757373",
              textTransform: "capitalize",
              padding: " 5px 40px 5px 40px",
              borderColor: "#757373 !important",
            },
            padding: " 5px 40px 5px 40px",
            width: "fit-content",
            color: accessPermissionFields() ? "#373737" : "#01537A",
            borderColor: accessPermissionFields()
              ? "#373737"
              : "#01537A !important",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          className={
            accessPermissionFields() || disableButton ? "disable" : null
          }
          onClick={handleSave}
          disabled={isValid}
          sx={{
            padding: " 0px 50px 0px 50px",
            width: "fit-content",
            background: accessPermissionFields()
              ? "#373737!important"
              : "#01537A !important",
            textTransform: "capitalize",
            "&.Mui-disabled": {
              background: "#757373 !important",
              color: "white",
              textTransform: "capitalize",
              padding: "0px 50px",
            },
          }}
          variant="contained"
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <span style={{ marginRight: "5px" }}>Save</span>
            {load ? (
              <Loader
                type={"spinningBubbles"}
                color={"white"}
                height={20}
                width={20}
              />
            ) : (
              ""
            )}
          </Box>
        </Button>
      </Box>
    </Box>
  );
}
