import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
const Footer = () => {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          width: "100%",
          height: 10,
          "&:hover": {
            backgroundColor: "white",
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      ></Box>
    </Container>
  );
};
export default Footer;