import React, { useState, useEffect, useContext } from "react";
import DataTable from "./dataTable";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import {
  Container,
  InputAdornment,
  Typography,
  FormControl,
  MenuItem,
  Select,
  TextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Service from "../../api/services";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
import { Paper, Divider, Button, ButtonGroup } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import PaginationHeader from "./paginationHeader";
import PaginationRounded from "../pagination";
import {
  gridStringOrNumberComparator,
  gridNumberComparator,
} from "@mui/x-data-grid";
import Loader from "../../components/loader";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PopUp from "./popUp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ModalViewButton from "./modalViewButton";
import {
  statusContext,
  switchStatusContext,
  archivedStatusContext,
} from "../../context/serviceContext";
import ProgressBar from "./progressBar";
import ConfirmationPopup from "../../components/confirmationPopup";
import { WarningMessages } from "../../config/messages";
import Tooltips from "../../components/tooltips";
import useToast from "../../hooks/useToast";

const idComparator = (v1, v2, param1, param2) => {
  return gridNumberComparator(v1.Id, v2.Id, param1, param2);
};

const nameComparator = (v1, v2, param1, param2) => {
  if (v1.vals !== "" && v2.vals !== "") {
    return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
  }
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    maxWidth: "100%",
    height: "auto",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "0px 2.68852px 2.68852px rgba(0, 0, 0, 0.25)",
    borderRadius: "3px",
  },
}));

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      boxShadow: 2,
      border: "none",
      maxWidth: "150px",
      color: "#000 !important",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderTopRightRadius: "6px",
      borderTopLeftRadius: "6px",
      marginTop: "10px",
      "& .Mui-focused ": {
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
        color: "#000 !important",
      },
      "& .MuiMenuItem-root": {
        color: "#000",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        fontSize: "16px",
        backgroundColor: "transparent",
        padding: "5px",
      },
      "& .MuiMenuItem-root: last-child": {
        color: "#000",
        borderBottom: "none",
        fontSize: "16px",
        backgroundColor: "#fff",
        "&:focus": {
          color: "#01537A",
        },
      },
      "& .Mui-selected:hover": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .Mui-selected": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .MuiBox-root:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        borderRadius: "6px",
        borderBottom: "none",
      },
      "& .MuiBox-root": {
        padding: "8px 10px",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: 2,
        paddingTop: "15px",
        borderRadius: "6px",
      },
      "& .MuiList-root": {
        padding: "0 15px",
      },
    },
  },
};

const NewIcon = (props) => (
  <KeyboardArrowDownIcon {...props} sx={{ color: "#01537A !important" }} />
);

const Stack1 = styled(Box)({
  color: "#01537A",
  fontWeight: "400",
  fontSize: "12px",
  letterSpacing: "0.4px",
  lineHeight: "24px",
  width: "150px",
  top: "5.5px",
});

const StackBox = styled(Box)({
  padding: "10px",
  width: "170px",
  textTransform: "capitalize",
});

const Stack2 = styled(Box)({
  color: "#373737",
  fontWeight: "400",
  fontSize: "16px",
  width: "356px",
  height: "24px",
  lineHeight: "24px",
});

const PublisherList = () => {
  const { showSuccess, showWarning } = useToast();
  const statusContextVal = JSON.parse(useContext(statusContext));
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState();
  const userRole = localStorage.getItem("role");

  let regExp =
    /^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i;

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const changePagination = (event, value) => {
    setPage(value - 1);
  };

  const handlePageSize = (e) => {
    setSize(e.target.value);
    setPage(0);
  };
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const clickedCompId = location.state?.clickedCompId;
  async function getPublisherDetails() {
    setLoading(true);
    Service.getPublishers({ channelId: clickedCompId }, page, size)
      .then((res) => {
        setResData(res?.data.publisherDetails);
        setTotalCount(res?.data.totalCount);
        setLoading(false);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getPublisherDetails();
    //eslint-disable-next-line
  }, [page, size]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setUpdateStatus(false);
    setItems([]);
    setOpen(false);
    getPublisherDetails();
  };
  const [bulkLoading, setBulkLoading] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState([]);
  const handleBulkSave = () => {
    setBulkLoading(true);
    setSaveClick(true);
    setUpdateStatus(true);
    const newObject = {
      items: items,
      editorId: localStorage.getItem("userid"),
    };
    Service.CompanyStatusUpdation(newObject, clickedCompId)
      .then((res) => {
        if (res?.data.status === "success") {
          setBulkLoading(false);
          cancelUpdate();
          setSuccessMessage(res?.data.successMessage);
          setErrorMessage(res?.data.errorMessage);
          setSelectValue();
        } else {
          handleClose();
          setBulkLoading(false);
          setSelectValue();
          showWarning(res?.data.message);
        }
        setSaveClick(false);
      })
      .catch((err) => {});
  };
  const [progress, setProgress] = React.useState(10);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  const warnMsg = `Are your sure you want to archive this customer? \n This action cannot be undone and all applications in this customer will be deleted. `;
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [companyID, setCompanyID] = useState();
  const [selectValue, setSelectValue] = useState();
  const [items, setItems] = useState([]);
  const handleStatus = (companyIds, value, originalValue) => {
    if (value === "Archived") {
      setCompanyID(companyIds);
      setSelectValue(originalValue);
      setConfirmationBox(true);
    }
    changeStatus(companyIds, value);
  };

  const changeStatus = (companyIds, value) => {
    const result = items.find(({ companyId }) => companyId === companyIds);
    if (result !== undefined) {
      result["status"] = value;
    } else {
      setItems([
        ...items,
        {
          companyId: companyIds,
          status: value,
        },
      ]);
    }
  };

  const handleConfirmation = () => {
    items.pop();
    document.getElementById("status" + companyID).innerText = selectValue;
    setCompanyID(null);
    setSelectValue();
    setConfirmationBox(false);
  };

  const confirmSave = () => {
    setConfirmationBox(false);
  };

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    //eslint-disable-next-line
    []
  );

  const [saveClick, setSaveClick] = useState(false);

  const handleSave = () => {
    if (items.length > 0) {
      setOpen(true);
    } else {
      showWarning(WarningMessages?.noActivity);
    }
  };

  const cancelUpdate = () => {
    getPublisherDetails();
  };

  const accessControls = () => {
    if (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE") {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionFields = () => {
    if (statusContextVal || archivedStatusContextVal) {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionButton = (btnName) => {
    if (statusContextVal) {
      if (archivedStatusContextVal) {
        return `${btnName}-btn disable`;
      } else {
        return `${btnName}-disable-btn disable`;
      }
    } else {
      if (archivedStatusContextVal) {
        return `${btnName}-btn disable`;
      } else {
        return `${btnName}-btn`;
      }
    }
  };

  function DetailPanelContent({ row: rowProp }) {
    const apiRef = useGridApiContext();
    const expandRows = apiRef.current.state.detailPanel.expandedRowIds;

    expandRows.map((item) =>
      item !== rowProp.companyId ? apiRef.current.toggleDetailPanel(item) : ""
    );

    const handleClose = () => {
      apiRef.current.toggleDetailPanel(rowProp.companyId);
      setLoad(false);
    };

    const [saveData, setSaveData] = useState({
      publisherId: "",
      publisherName: "",
      status: "",
      channelCustomerId: "",
      accountmanagerEmail: "",
      dataUsage: "",
      usageAlertEmail: "",
      editorId: localStorage.getItem("userid"),
    });

    const [saveError, setSaveError] = useState({
      publisherName: false,
      nameMsg: "",
    });

    const handleEdit = (e) => {
      const newData = { ...saveData };
      newData[e.target.name] = e.target.value;

      if (e.target.name === "accountmanagerEmail") {
        if (e.target.value === "") {
          setSaveError({
            ...saveError,
            accountmanagerEmail: false,
            emailMsg: "",
          });
        } else {
          if (regExp.test(e.target.value) === false) {
            setSaveError({
              ...saveError,
              accountmanagerEmail: true,
              emailMsg: "Enter a valid Email ID",
            });
          } else {
            setSaveError({
              ...saveError,
              accountmanagerEmail: false,
              emailMsg: "",
            });
          }
        }
      }

      if (e.target.name === "usageAlertEmail") {
        if (e.target.value === "") {
          setSaveError({
            ...saveError,
            usageAlertEmail: false,
            alertEmailMsg: "",
          });
        } else {
          if (regExp.test(e.target.value) === false) {
            setSaveError({
              ...saveError,
              usageAlertEmail: true,
              alertEmailMsg: "Enter a valid Email ID",
            });
          } else {
            setSaveError({
              ...saveError,
              usageAlertEmail: false,
              alertEmailMsg: "",
            });
          }
        }
      }
      setSaveData(newData);
    };

    const setStatus = (stat) => {
      setSaveData({
        ...saveData,
        status: stat,
      });
    };

    const validate = () => {
      const dupSaveError = { ...saveError };
      let count = 0;

      if (saveData?.accountmanagerEmail === "") {
        dupSaveError.accountmanagerEmail = false;
        dupSaveError.emailMsg = "";
      } else {
        if (regExp.test(saveData?.accountmanagerEmail) === false) {
          dupSaveError.accountmanagerEmail = true;
          dupSaveError.emailMsg = "Enter a valid Email ID";
          count++;
        } else {
          dupSaveError.accountmanagerEmail = false;
          dupSaveError.emailMsg = "";
        }
      }

      if (saveData?.usageAlertEmail === "") {
        dupSaveError.usageAlertEmail = false;
        dupSaveError.alertEmailMsg = "";
      } else {
        if (regExp.test(saveData?.usageAlertEmail) === false) {
          dupSaveError.usageAlertEmail = true;
          dupSaveError.alertEmailMsg = "Enter a valid Email ID";
          count++;
        } else {
          dupSaveError.usageAlertEmail = false;
          dupSaveError.alertEmailMsg = "";
        }
      }

      setSaveError(dupSaveError);
      return count;
    };

    const [saveClick, setSaveClick] = useState(false);
    const [load, setLoad] = useState(false);
    const handleSave = () => {
      const errorCount = validate();
      if (errorCount === 0) {
        setSaveClick(true);
        setLoad(true);

        let payload = {
          accountmanagerEmail: saveData?.accountmanagerEmail,
          channelCustomerId: saveData?.channelCustomerId,
          companyId: saveData?.publisherId,
          companyStatus: saveData?.status,
          dataUsage: saveData?.dataUsage,
          editorId: saveData?.editorId,
          name: saveData?.publisherName,
          usageAlertEmail: saveData?.usageAlertEmail,
        };
        Service.updatePublisher(payload, saveData?.publisherId)
          .then((res) => {
            setSaveClick(false);
            setLoad(false);

            if (res?.data.status === "success") {
              showSuccess(res?.data.message);
              getPublisherDetails();
              handleClose();
            } else {
              showWarning(res?.data.message);
              getPublisherDetails();
              handleClose();
            }
          })
          .catch((err) => {});
      }
    };

    const accessPermission = (status, checkStatus) => {
      if (
        accessControls() &&
        !switchStatusContextVal &&
        !archivedStatusContextVal
      ) {
        if (status === checkStatus) {
          return "btn-active";
        } else {
          return "btn-nonactive";
        }
      } else {
        if (statusContextVal) {
          if (status === checkStatus) {
            return "btn-disable-active disable";
          } else {
            return "btn-disable-nonactive disable";
          }
        } else if (archivedStatusContextVal) {
          if (status === checkStatus) {
            return "btn-active disable";
          } else {
            return "btn-nonactive disable";
          }
        } else {
          if (status === checkStatus) {
            return "btn-active";
          } else {
            return "btn-nonactive";
          }
        }
      }
    };

    useEffect(() => {
      const newData = { ...saveData };
      newData["publisherId"] = rowProp.companyId;
      newData["status"] = rowProp.status;
      newData["publisherName"] = rowProp.name;
      newData["channelCustomerId"] = rowProp.channelCusIdentifier
        ? rowProp.channelCusIdentifier
        : "";
      newData["accountmanagerEmail"] = rowProp.accountManagerEmail
        ? rowProp.accountManagerEmail
        : "";
      newData["dataUsage"] = rowProp.dataUsageAlert
        ? rowProp.dataUsageAlert
        : "";
      newData["usageAlertEmail"] = rowProp.usageAlertEmail
        ? rowProp.usageAlertEmail
        : "";
      setSaveData(newData);
      //eslint-disable-next-line
    }, [rowProp]);

    return (
      <Dialog
        open={true}
        maxWidth="xl"
        scroll={"body"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              marginTop: "10px",
              marginLeft: "34px",
              fontSize: "24px",
              color: "#01537A",
              padding: { lg: "20px 0px", md: "20px 0px" },
            }}
          >
            Update Customer Details
          </Typography>
          <CloseSharpIcon
            sx={{
              color: "#373737",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: "0px 40px" }}>
            <Box
              sx={{
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
                display: "flex",
                columnGap: { xl: 10, lg: 8, md: 5, xs: 3 },
              }}
            >
              <Paper
                sx={{
                  maxWidth: {
                    xs: "380px",
                    sm: "400px",
                    md: "450px",
                    lg: "420px",
                    xl: "450px",
                  },
                  width: "100%",
                  height: "200px",
                  padding: "30px",
                  display: "flex",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginTop: "10px",
                }}
              >
                <Stack1
                  direction="column"
                  spacing={2}
                  sx={{ paddingLeft: { xs: "20px", md: "10px" } }}
                >
                  <StackBox>Company ID </StackBox>
                  <StackBox>Company Type</StackBox>
                  <StackBox>Created</StackBox>
                </Stack1>
                <Stack2 direction="column" spacing={2}>
                  <StackBox>{rowProp?.companyId}</StackBox>
                  <StackBox>
                    {rowProp?.companyType === "CUSTOMER" ||
                    rowProp?.companyType === "RESELLER"
                      ? "Customer"
                      : rowProp?.companyType.toLowerCase()}{" "}
                  </StackBox>
                  <StackBox>
                    {rowProp?.created
                      ? moment(rowProp?.created).format("DD MMM YYYY HH:mm")
                      : "-"}
                  </StackBox>
                </Stack2>
              </Paper>
              <Box>
                <Box
                  sx={{
                    paddingTop: { xs: "20px", lg: "0px" },
                  }}
                >
                  <Box>
                    <FormControl
                      sx={{
                        maxWidth: {
                          xs: "380px",
                          sm: "400px",
                          md: "450px",
                          lg: "565px",
                          xl: "650px",
                        },
                        width: "100%",
                        paddingTop: 2,
                      }}
                      variant="standard"
                    >
                      <TextField
                        sx={{
                          fontWeight: "400px",
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "16px",
                          },
                          lineHeight: "18px",
                          letterSpacing: "0.5px",
                          color: "rgba(0, 0, 0, 0.87) !important",
                          fontStyle: "normal",
                          padding: "4px 0 0px",
                        }}
                        value={saveData?.accountmanagerEmail}
                        name="accountmanagerEmail"
                        variant="standard"
                        onChange={handleEdit}
                        error={!!saveError?.accountmanagerEmail}
                        helperText={
                          saveError?.accountmanagerEmail
                            ? saveError?.emailMsg
                            : ""
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!accessPermissionFields() && (
                                <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                              )}
                            </InputAdornment>
                          ),
                          readOnly: accessPermissionFields() && true,
                        }}
                      />
                    </FormControl>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "24px",
                        letterSpacing: "0.4px",
                        color: "#01537A",
                      }}
                    >
                      Account Manager Email
                    </Typography>
                  </Box>
                  <Box>
                    <FormControl
                      sx={{
                        maxWidth: {
                          xs: "380px",
                          sm: "400px",
                          md: "450px",
                          lg: "565px",
                          xl: "650px",
                        },
                        width: "100%",
                        paddingTop: 2,
                      }}
                      variant="standard"
                    >
                      <TextField
                        sx={{
                          fontWeight: "400px",
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "16px",
                          },
                          lineHeight: "19px",
                          letterSpacing: "0.5px",
                          color: "rgba(0, 0, 0, 0.87) !important",
                          fontStyle: "normal",
                          padding: "4px 0 0px",
                        }}
                        type="text"
                        name="dataUsage"
                        value={saveData?.dataUsage}
                        variant="standard"
                        onChange={handleEdit}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!accessPermissionFields() && (
                                <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                              )}
                            </InputAdornment>
                          ),
                          readOnly: accessPermissionFields() && true,
                        }}
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                          letterSpacing: "0.4px",
                          color: "#01537A",
                        }}
                      >
                        Monthly Account Alert Limit
                      </Typography>
                      <Tooltips type="monthlyAccAlert">
                        <ErrorOutlineIcon
                          sx={{
                            marginLeft: "20px",
                            color: "#01537A",
                            fontSize: "15px",
                          }}
                        />
                      </Tooltips>
                    </Box>
                  </Box>
                  <Box>
                    <FormControl
                      sx={{
                        maxWidth: {
                          xs: "380px",
                          sm: "400px",
                          md: "450px",
                          lg: "565px",
                          xl: "650px",
                        },
                        width: "100%",
                        paddingTop: 2,
                      }}
                      variant="standard"
                    >
                      <TextField
                        sx={{
                          fontWeight: "400px",
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "16px",
                          },
                          lineHeight: "18px",
                          letterSpacing: "0.5px",
                          color: "rgba(0, 0, 0, 0.87) !important",
                          fontStyle: "normal",
                          padding: "4px 0 0px",
                        }}
                        variant="standard"
                        type="text"
                        name="usageAlertEmail"
                        value={saveData?.usageAlertEmail}
                        helperText={
                          saveError?.usageAlertEmail
                            ? saveError?.alertEmailMsg
                            : ""
                        }
                        error={!!saveError?.usageAlertEmail}
                        onChange={handleEdit}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!accessPermissionFields() && (
                                <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                              )}
                            </InputAdornment>
                          ),
                          readOnly: accessPermissionFields() && true,
                        }}
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",

                          letterSpacing: "0.4px",
                          color: "#01537A",
                        }}
                      >
                        Usage Alert Email
                      </Typography>
                      <Tooltips type="usageAlertEmail">
                        <ErrorOutlineIcon
                          sx={{
                            marginLeft: "20px",
                            fontSize: "15px",
                            color: "#01537A",
                          }}
                        />
                      </Tooltips>
                    </Box>
                  </Box>
                  <Box>
                    <FormControl
                      sx={{
                        maxWidth: {
                          xs: "380px",
                          sm: "400px",
                          md: "450px",
                          lg: "565px",
                          xl: "650px",
                        },
                        width: "100%",
                        paddingTop: 2,
                      }}
                      variant="standard"
                    >
                      <TextField
                        sx={{
                          fontWeight: "400px",
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "16px",
                          },
                          lineHeight: "18px",
                          letterSpacing: "0.5px",
                          color: "rgba(0, 0, 0, 0.87) !important",
                          fontStyle: "normal",
                          padding: "4px 0 0px",
                        }}
                        type="text"
                        name="channelCustomerId"
                        value={saveData?.channelCustomerId}
                        variant="standard"
                        onChange={handleEdit}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!accessPermissionFields() && (
                                <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                              )}
                            </InputAdornment>
                          ),
                          readOnly: accessPermissionFields() && true,
                        }}
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                          letterSpacing: "0.4px",
                          color: "#01537A",
                        }}
                      >
                        Channel Customer Identifier
                      </Typography>
                      <Tooltips type="channelCustId">
                        <ErrorOutlineIcon
                          sx={{
                            marginLeft: "20px",
                            color: "#01537A",
                            fontSize: "15px",
                          }}
                        />
                      </Tooltips>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "27px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#1B2935",
                      }}
                    >
                      Status
                    </Typography>
                    <ButtonGroup
                      sx={{
                        width: {
                          xs: "380px",
                          md: "450px",
                          lg: "565px",
                          xl: "650px",
                        },
                        height: { xs: "35px", lg: "40px" },
                        fontWeight: 400,
                      }}
                    >
                      {rowProp.status === "New" && (
                        <Button
                          type="button"
                          sx={{
                            width: "100%",
                            textTransform: "capitalize",
                            fontSize: { xs: "12px", md: "14px", lg: "16px" },
                          }}
                          startIcon={
                            saveData?.status === "New" ? <DoneIcon /> : ""
                          }
                          className={accessPermission("New", saveData?.status)}
                          onClick={() => setStatus("New")}
                        >
                          New
                        </Button>
                      )}

                      <Button
                        type="button"
                        sx={{
                          width: "100%",
                          fontSize: { xs: "12px", md: "14px", lg: "16px" },
                          textTransform: "capitalize",
                        }}
                        startIcon={
                          saveData?.status === "Approved" ? <DoneIcon /> : ""
                        }
                        className={accessPermission(
                          "Approved",
                          saveData?.status
                        )}
                        onClick={() => setStatus("Approved")}
                      >
                        Approved
                      </Button>
                      <Button
                        type="button"
                        sx={{
                          width: "100%",
                          fontSize: { xs: "12px", md: "14px", lg: "16px" },
                          textTransform: "capitalize",
                        }}
                        startIcon={
                          saveData?.status === "Suspended" ? <DoneIcon /> : ""
                        }
                        className={accessPermission(
                          "Suspended",
                          saveData?.status
                        )}
                        onClick={() => setStatus("Suspended")}
                      >
                        Suspended
                      </Button>
                      <Button
                        type="button"
                        sx={{
                          width: "100%",
                          fontSize: { xs: "12px", md: "14px", lg: "16px" },
                          textTransform: "capitalize",
                        }}
                        startIcon={
                          saveData?.status === "Archived" ? <DoneIcon /> : ""
                        }
                        className={accessPermission(
                          "Archived",
                          saveData?.status
                        )}
                        onClick={() => setStatus("Archived")}
                      >
                        Archived
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Divider
              sx={{
                alignContent: "center",
                color: "#DFDFDF",
                margin: "60px 0px 20px 0px",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "30px 0px",
                gap: 3,
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                className={accessPermissionButton("modal-cancel")}
              >
                Cancel
              </Button>

              <Button
                sx={{
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "unset !important",
                  },
                }}
                className={accessPermissionButton("modal-save")}
                disabled={saveClick}
                variant="contained"
                onClick={handleSave}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Save</span>
                  {load ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"white"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  DetailPanelContent.propTypes = {
    row: PropTypes.object.isRequired,
  };

  CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
  };

  function CustomDetailPanelToggle() {
    return <ModalViewButton />;
  }

  const textStyle = {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "400",
    fontSize: "16px",
  };
  const textStyle2 = {
    color: "#0094CD",
    fontWeight: "400",
    fontSize: "16px",
    cursor: "pointer",
  };
  const textStyle1 = {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "400",
    fontSize: "14px",
  };
  const columns = [
    {
      flex: 1,
      field: "name",
      headerName: "ID",
      width: 170,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        Id: row.companyId,
      }),
      sortComparator: idComparator,
      renderCell: (params) => {
        return (
          <div>
            <span style={textStyle1}>{params.row.companyId}</span>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "publisherName",
      headerName: "Customer Name",
      width: 340,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.name,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <HtmlTooltip
            placement="bottom-end"
            title={
              <React.Fragment>
                <PopUp value={params?.row} />
              </React.Fragment>
            }
          >
            <div>
              <span style={textStyle2}>{params.row.name}</span>
            </div>
          </HtmlTooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 170,
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.status,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <>
            {params.row.status !== "Archived" ? (
              <FormControl
                variant="standard"
                sx={{
                  minWidth: 120,
                  backgroundColor: "transparent !important",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "400",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
                <Select
                  sx={{ backgroundColor: "transparent !important" }}
                  labelId="demo-simple-select-standard-label"
                  id={"status" + params.row.companyId}
                  defaultValue={
                    selectValue !== undefined ? selectValue : params.row.status
                  }
                  onChange={(e) =>
                    handleStatus(
                      params.row.companyId,
                      e.target.value,
                      params.row.status
                    )
                  }
                  MenuProps={MenuProps}
                  disableUnderline={true}
                  IconComponent={NewIcon}
                >
                  {params.row.status === "New" && (
                    <MenuItem value="New">New</MenuItem>
                  )}

                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Suspended">Suspended</MenuItem>
                  <MenuItem value="Archived">Archived</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <span>{params.row.status}</span>
            )}
          </>
        );
      },
    },
    {
      field: "accAlert",
      headerName: "Account #datafree Usage Alert",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.accAlert,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            <span style={textStyle}>{params.row.accAlert}</span>
          </div>
        );
      },
    },
    {
      field: "created",
      headerName: "Created",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.created,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <Box sx={textStyle1}>
            {moment(params.row.created).format("DD MMM YYYY")}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.row.companyId} />
      ),
    },
  ];

  return (
    <Box>
      <Container
        maxWidth="xxl"
        style={{
          padding: "0",
        }}
      >
        {resData && !loading ? (
          <>
            {totalCount !== 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  pb: "5px",
                  pr: "2px",
                }}
              >
                <PaginationHeader
                  page={page}
                  size={size}
                  dataLength={resData.length}
                  totalCount={totalCount}
                />
              </Box>
            ) : null}
            <Box
              sx={{
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor:
                    switchStatusContextVal && statusContextVal
                      ? "#373737"
                      : "#01537A !important",
                  color: "white",
                },
              }}
            >
              <DataTable
                rows={resData}
                columns={columns}
                tableName="usersPanel"
                loading={loading}
                getDetailPanelContent={getDetailPanelContent}
              />
            </Box>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
          </Box>
        )}
        {!loading && totalCount >= 10 ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Typography
              component="span"
              variant="body2"
              sx={{ color: "#01537A" }}
            >
              Items per page :{" "}
            </Typography>
            <FormControl variant="standard">
              <Select
                value={size}
                sx={{
                  width: "55px",
                  margin: "0px 10px 0px 5px",
                  backgroundColor: "#8080802b",
                  color: "#000",
                  borderBottom: "1px solid #01537A",
                  textAlign: "center",
                  "&:after": {
                    borderBottom: "1.5px solid #01537A",
                  },
                  "&hover": {
                    borderBottom: "1px solid #01537A",
                  },
                }}
                onChange={handlePageSize}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>{Number(10).toLocaleString()}</MenuItem>
                <MenuItem value={25}>{Number(25).toLocaleString()}</MenuItem>
                <MenuItem value={50}>{Number(50).toLocaleString()}</MenuItem>
                <MenuItem value={100}>{Number(100).toLocaleString()}</MenuItem>
              </Select>
            </FormControl>
            <PaginationRounded
              changeHandle={changePagination}
              page={page + 1}
              Count={totalCount}
              size={size}
            />
          </Box>
        ) : null}

        {!loading && totalCount > 0 && (
          <Box
            sx={{
              padding: "20px 0px 10px 0px",
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              className={accessPermissionButton("cancel")}
              variant="outlined"
              onClick={cancelUpdate}
            >
              Cancel
            </Button>

            <Button
              sx={{
                "&.Mui-disabled": {
                  cursor: "not-allowed !important",
                  pointerEvents: "unset !important",
                },
              }}
              className={accessPermissionButton("save")}
              variant="contained"
              disabled={saveClick}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Box>
        )}
      </Container>
      <Dialog open={open} onClose={handleClose} scroll={"body"} maxWidth="xl">
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                marginTop: "10px",
                color: "#01537A",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "46px",
                width: "658px",
                height: "40px",
              }}
            >
              Bulk Update
            </Typography>
            {bulkLoading ? (
              ""
            ) : (
              <CloseSharpIcon
                sx={{
                  color: "#373737",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
            )}
          </Box>
          {updateStatus && (
            <Typography
              sx={{ padding: "0px 30px 0px 10px", color: "#373737" }}
            >{`Updated ${items.length}  ${
              items.length > 1 ? "records :" : "record :"
            } `}</Typography>
          )}
        </DialogTitle>

        <DialogContent>
          {bulkLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "60px 40px 80px 30px",
                width: { xs: "380px", sm: "400px", md: "600px", xl: "700px" },
              }}
            >
              <ProgressBar value={progress} />
            </Box>
          ) : updateStatus && !bulkLoading ? (
            <Box
              sx={{
                padding: "20px 30px 20px 10px",
                display: "flex",
                flexDirection: "column",
                width: { xs: "380px", sm: "400px", md: "600px", xl: "700px" },
              }}
            >
              {successMessage !== null && (
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{ color: "#4CAF50" }}
                  >{`Success: ${" "}`}</Typography>

                  <Typography sx={{ color: "#373737" }}>
                    {successMessage}
                  </Typography>
                </Box>
              )}

              {errorMessage && errorMessage.length > 0 && (
                <Box>
                  {errorMessage.map((errors, index) => (
                    <Box sx={{ width: "100%" }} key={index}>
                      <Typography>
                        <span style={{ color: "#FF5252" }}>
                          Error {index + 1}:{" "}
                        </span>
                        <span
                          style={{
                            fontWeight: "700",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          {errors.error}.{" "}
                        </span>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                        >
                          {errors.errorMessage}
                        </span>
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                padding: "10px 30px 0px 30px",
              }}
            >
              <Typography
                sx={{
                  color: "#01537A",
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "30px",
                }}
              >
                You are updating {items.length} records , are you sure you want
                to make these changes? Some of these changes might be permanent.
              </Typography>

              <Divider
                sx={{
                  color: "#DFDFDF",
                  alignContent: "center",
                  marginTop: "40px",
                }}
              />
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          {!bulkLoading && !updateStatus && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 84px 30px 84px",
                gap: 2,
              }}
            >
              <Button
                className="modal-cancel-btn"
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                className="modal-save-btn"
                onClick={handleBulkSave}
                variant="contained"
              >
                Save
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
      {confirmationBox && (
        <ConfirmationPopup
          open={confirmationBox}
          handleClose={handleConfirmation}
          confirmSave={confirmSave}
          message={warnMsg}
          type={"warning"}
        />
      )}
    </Box>
  );
};
export default PublisherList;
