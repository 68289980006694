import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Box,
  Typography,
  Paper,
  Divider,
  FormHelperText,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import fileUpload from "../../assets/img/fileUpload.svg";
import Service from "../../api/services";
import { useDropzone } from "react-dropzone";
import splashCam from "../../assets/img/splashCam.svg";
import Loader from "../../components/loader";
import imgFrame from "../../assets/img/imgFrame.png";
import { WarningMessages } from "../../config/messages";
import useToast from "../../hooks/useToast";

export default function ImgPopUp({
  open,
  handleClose,
  imgs,
  defaultImg,
  appId,
  refreshData,
  imgType,
  type,
}) {
  const { showSuccess, showError } = useToast();
  const [imgName, setImgName] = useState(imgs);
  const [imgError, setImgError] = useState({
    image: false,
    imgMsg: "",
  });
  const [load, setLoad] = useState(false);
  const [removeLoad, setRemoveLoad] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [imgData, setImgData] = useState();
  const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
  const API_URL = process.env.REACT_APP_API_URL;
  const MAX_FILE_SIZE = 1024; // 1MB
  const validateFile = (file) => {
    if (!allowedExtensions.exec(file.name)) {
      return {
        image: true,
        imgMsg: "Selected file is not supported",
      };
    }
    return {
      image: false,
      imgMsg: "",
    };
  };

  const validateIconImage = (img, file) => {
    if (img.width < 120) {
      return {
        image: true,
        imgMsg: "Minimum width must be 120px",
      };
    }

    if (img.height < 120) {
      return {
        image: true,
        imgMsg: "Minimum height must be 120px",
      };
    }
    if (file.size / 1024 > MAX_FILE_SIZE) {
      return {
        image: true,
        imgMsg: "Maximum size 1MB",
      };
    }
    return {
      image: false,
      imgMsg: "",
    };
  };

  const validateSplashImage = (img, file) => {
    if (img.width < 1080) {
      return {
        image: true,
        imgMsg: "Minimum width must be 1080px",
      };
    }

    if (img.height < 1920) {
      return {
        image: true,
        imgMsg: "Minimum height must be 1920px",
      };
    }

    const fileValidation = validateFile(file);
    if (fileValidation.image) {
      return fileValidation;
    }

    if (file.size / 1024 > MAX_FILE_SIZE) {
      return {
        image: true,
        imgMsg: "Maximum size 1MB",
      };
    }

    return {
      image: false,
      imgMsg: "",
    };
  };

  const onDrop = useCallback((acceptedFiles) => {
    const newData = { ...imgData };
    let img = new Image();
    let file = acceptedFiles[0];
    img.src = URL.createObjectURL(file);

    const imgError = validateFile(file);

    if (imgError.image) {
      setImgError({
        ...imgError,
        image: true,
      });
      setImgName(imgs);
    } else {
      img.onload = () => {
        let imgValidation;
        if (imgType === "iconImage") {
          imgValidation = validateIconImage(img, file);
        } else if (imgType === "splashImage") {
          imgValidation = validateSplashImage(img, file);
        }

        if (imgValidation.image) {
          setImgError({
            ...imgError,
            image: true,
            imgMsg: imgValidation.imgMsg,
          });
        } else {
          setImgName(URL.createObjectURL(file));
          setImgError({
            ...imgError,
            image: false,
            imgMsg: "",
          });
          newData["file_obj"] = file;
          setImgData(newData);
        }
      };
    }
    //eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleClear = () => {
    setImgName();
    handleClose();
  };

  const uploadSplashImage = (values) => {
    return Service.uploadSplashImage(values)
      .then((res) => {
        if (res.data.status === "success") {
          showSuccess(res?.data.message);
          handleClose();
          refreshData(imgName);
          setLoad(false);
          setDisableButton(false);
        } else {
          showError(res?.data.message);
          handleClose();
          refreshData(imgName);
          setLoad(false);
          setDisableButton(false);
        }
      })
      .catch((err) => {
        showError(WarningMessages?.errorImg);
      });
  };

  const uploadReachIcon = (values) => {
    return Service.uploadReachIcon(values)
      .then((res) => {
        if (res.data.status === "success") {
          showSuccess(res?.data.message);
          handleClose();
          refreshData(imgName);
          setLoad(false);
          setDisableButton(false);
        } else {
          showError(res?.data.message);
          handleClose();
          refreshData(imgName);
          setLoad(false);
          setDisableButton(false);
        }
      })
      .catch((err) => {
        showError(WarningMessages?.errorImg);
      });
  };

  const handleImageSave = () => {
    let appIds = appId;
    let values = imgData?.file_obj !== undefined ? new FormData() : "";
    setLoad(true);
    setDisableButton(true);
    if (imgData?.file_obj !== undefined && imgData?.file_obj !== null) {
      values.append(
        imgType === "splashImage" ? "splashImage" : "iconImage",
        imgData?.file_obj ? imgData?.file_obj : ""
      );
      values.append("applicationId", appIds);

      if (values.toString().trim().length > 0) {
        const uploadFunction =
          imgType === "splashImage" ? uploadSplashImage : uploadReachIcon;

        uploadFunction(values);
      }
    } else {
      setImgError({
        ...imgError,
        image: true,
        imgMsg: "Please choose an image to upload!",
      });
      setLoad(false);
      setDisableButton(false);
    }
  };

  const removeSplashImage = (values) => {
    return Service.removeSplashImg(values)
      .then((res) => {
        if (res.data.status === "success") {
          showSuccess(res?.data.message);

          refreshData(API_URL + res?.data?.splashImage);
          setRemoveLoad(false);
          setDisableButton(false);
        }
      })
      .catch((err) => {
        showError(WarningMessages?.errorImg);
      });
  };

  const removeReachIcon = (values) => {
    return Service.removeReachIcon(values)
      .then((res) => {
        if (res.data.status === "success") {
          showSuccess(res?.data.message);

          refreshData(API_URL + res?.data?.iconImage);
          setRemoveLoad(false);
          setDisableButton(false);
        }
      })
      .catch((err) => {
        showError(WarningMessages?.errorImg);
      });
  };

  const handleImgRemove = () => {
    setRemoveLoad(true);
    setDisableButton(true);
    const payload = {
      applicationId: appId,
      editorId: localStorage.getItem("userid"),
      type: type,
    };
    const data = {
      applicationId: appId,
    };
    if (imgName === defaultImg) {
      setImgError({
        ...imgError,
        image: true,
        imgMsg: "You don't have an image to remove !",
      });
      setRemoveLoad(false);
      setDisableButton(false);
    } else {
      imgType === "splashImage"
        ? removeSplashImage(data)
        : removeReachIcon(payload);
      setImgName(defaultImg);
    }
  };

  useEffect(() => {
    setImgName(imgs);
  }, [imgs]);

  return (
    <>
      <Dialog
        open={open}
        scroll={"body"}
        maxWidth="xl"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "15px",
            }}
          >
            <Typography
              sx={{
                color: "#01537A",
                fontWeight: "400",
                fontSize: { xs: "17px", md: "24px" },
                lineHeight: "26px",
              }}
            >
              {imgType === "iconImage" ? "Application Image" : "Splash Image"}
            </Typography>
            <CloseSharpIcon
              sx={{
                color: "#757373",
                cursor: "pointer",
              }}
              onClick={handleClear}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: {
                xs: "10px 30px 10px 0px",
                md:
                  imgType === "iconImage"
                    ? "10px 150px 0px 40px"
                    : "10px 150px 15px 40px",
              },
            }}
          >
            <Typography
              sx={{ color: "#01537A", fontWeight: 400, fontSize: "16px" }}
            >
              {imgType === "iconImage"
                ? " A picture helps people recognize your application."
                : "This image is the loading image that will appear when your #datafree app is opened."}
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: "15px",
                columnGap: { xs: "0px", md: "27px" },
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              {imgType === "iconImage" ? (
                <Box
                  sx={{
                    position: "relative",
                    width: "172px",

                    marginTop: "20px",
                  }}
                >
                  <Paper
                    sx={{
                      background: "#fff",
                      width: "135px",
                      height: "134px",
                      borderRadius: "14PX",
                      color: "#01537A",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <img
                      style={{ borderRadius: "16px" }}
                      alt="Datafree"
                      src={imgName}
                      width="135px"
                      height="135px"
                    />
                  </Paper>
                </Box>
              ) : imgType === "splashImage" ? (
                <>
                  <Box sx={{ position: "relative" }}>
                    <img
                      style={{ position: "absolue" }}
                      src={imgFrame}
                      alt="imgFrame"
                      width="105.929px"
                      height="214px"
                    />

                    <img
                      style={{
                        position: "absolute",
                        left: "25px",
                        bottom: "204px",
                        zIndex: "2",
                      }}
                      src={splashCam}
                      alt="splashCam"
                    />
                    <img
                      style={{
                        position: "absolute",
                        left: "7px",
                        bottom: "12px",

                        borderRadius: "7px",
                      }}
                      src={imgName}
                      alt="defaultSplash"
                      width="91px"
                      height="199px"
                    />
                  </Box>
                </>
              ) : (
                ""
              )}

              <Box>
                <Typography
                  sx={{
                    color: "#5A5656",
                    fontWeight: 400,
                    fontSize: "14px",
                    mt: { xs: 3, md: 0 },
                  }}
                >
                  {imgType === "iconImage"
                    ? "(Min. dimensions of 120px X 120px)"
                    : imgType === "splashImage"
                    ? "(Min. dimensions of 1080px X 1920px, Max size = 1MB)"
                    : ""}
                </Typography>
                <Box
                  sx={{
                    width: { xs: "350px", sm: "400px", md: "500px" },
                    height: imgType === "iconImage" ? "94px" : "166px",
                    backgroundColor: "#EBEAEA",
                    border: "1px dashed #696767",
                    borderRadius: "20px",
                    marginTop: imgType === "iconImage" ? "20px" : "13px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  {...getRootProps({ className: "dropzone" })}
                >
                  <input {...getInputProps()} />
                  <Box
                    component="img"
                    src={fileUpload}
                    alt=""
                    height="30px"
                  ></Box>
                  <Typography
                    component="span"
                    sx={{
                      color: "#000000",
                      fontWeight: 400,
                      fontSize: "16px",
                      textAlign: "center",
                      pt: "10px",
                      display: "inline",
                    }}
                  >
                    Drag files here or
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {" click "}
                    </Typography>
                    to upload
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            {imgError?.image ? (
              <FormHelperText
                sx={{
                  color: "#d32f2f",
                  textAlign: "center",
                  marginLeft: imgType === "iconImage" ? "100px" : "",
                }}
              >
                {imgError?.imgMsg}
              </FormHelperText>
            ) : (
              ""
            )}
          </Box>
          <Divider
            sx={{
              color: "#DFDFDF",
              margin: { xs: "15px 26px 0px 10px", md: "15px 26px 0px 40px" },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              padding: { xs: "0px 40px 30px 25px", md: "0px 40px 30px 55px" },
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Button
              sx={{
                color: "#01537A !important",
                border: "1px solid #01537A !important",
                textTransform: "capitalize",
                backgroundColor: "#fff !important",
                width: "130px",
              }}
              onClick={handleClear}
              variant="contained"
            >
              Cancel
            </Button>
            <Box
              sx={{
                mt: { xs: 3, md: 0 },
              }}
            >
              <Button
                className={disableButton ? "disable" : null}
                sx={{
                  color: "#01537A !important",
                  border: "1px solid #01537A !important",
                  textTransform: "capitalize",
                  backgroundColor: "#fff !important",
                  width: "140px",
                }}
                onClick={handleImgRemove}
                variant="contained"
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Remove</span>
                  {removeLoad ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"#01537A"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
              <Button
                className={disableButton ? "disable" : null}
                sx={{
                  backgroundColor: "#01537A !important",
                  marginLeft: "30px",
                  textTransform: "capitalize",
                  width: "150px",
                }}
                variant="contained"
                onClick={handleImageSave}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Save</span>
                  {load ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"white"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
