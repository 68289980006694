import React from "react";
import { Box, Typography } from "@mui/material";

export default function PaginationHeader({ page, size, totalCount, dataLength }) {
  return (
    <Box>
      <Typography
        sx={{ fontSize: "14px", fontWeight: 400, color: "#373737" }}
      >{`${page * size + 1}-${page * size + dataLength} of ${totalCount}`}</Typography>
    </Box>
  );
}
