import {
  Container,
  Paper,
  Typography,
  InputAdornment,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import Layout from "../../components/layout";
import { Box, Divider, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import defaultUser from "../../assets/img/defaultUser.svg";
import CreateIcon from "@mui/icons-material/Create";
import CompanySearchModal from "../../components/User/companySearchModal";
import SearchList from "../../components/User/searchList";
import Services from "../../api/services";
import BreadCrumb from "../../components/breadCrumb";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import useToast from "../../hooks/useToast";

const UserRole = styled(Button)({
  color: "#01537A",
  width: "102px",
  height: "53px",
  display: "flex",
  borderColor: "#01537A !important",
  textTransform: "capitalize",
  backgroundColor: "white",
  border: "1px solid #01537A",
  "&:hover": {
    backgroundColor: "white",
    color: "#01537A",
  },
});

export default function ChannelAddUser() {
  const { showSuccess, showError } = useToast();
  let regExp =
    /^[A-Za-z0-9_!#$%&'*+=?`{|}~^.-]*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/i;
  const [imgSrc, setImgSrc] = useState(defaultUser);
  const [loading, setLoading] = useState(false);
  const switchRole = localStorage.getItem("switchRole");
  const switchedUser = JSON.parse(localStorage.getItem("switchedUser"));
  let navigate = useNavigate();
  const setCompanyId = () => {
    if (switchRole) {
      if (switchedUser) {
        if (switchRole === "channel") {
          return localStorage.getItem("channelId");
        } else if (switchRole === "user" || switchRole === "customer") {
          return localStorage.getItem("customerId");
        } else {
          return localStorage.getItem("companyId");
        }
      } else {
        return localStorage.getItem("companyId");
      }
    } else {
      return localStorage.getItem("companyId");
    }
  };
  const [userData, setUserData] = useState({
    userName: "",
    email: "",
    companyId: setCompanyId(),
  });

  const [userError, setUserError] = useState({
    userName: false,
    userMsg: "",
    email: "",
    emailMsg: "",
    companyId: false,
    companyMsg: "",
    owningCompany: false,
    owningCompanyMsg: "",
  });
  const [addUserClick, setAddUserClick] = useState(false);
  const validate = (data) => {
    const userErrors = { ...userError };
    let count = 0;
    if (data?.userName === "") {
      userErrors.userName = true;
      userErrors.userMsg = "This field is required";
      count++;
    } else {
      userErrors.userName = false;
      userErrors.userMsg = "";
    }
    if (data?.email === "") {
      userErrors.email = true;
      userErrors.emailMsg = "This field is required";

      count++;
    } else if (regExp.test(data?.email) === false) {
      userErrors.email = true;
      userErrors.emailMsg = "Enter a valid Email ID";

      count++;
    } else {
      userErrors.email = false;
      userErrors.emailMsg = "";
    }
    if (switchRole) {
      if (switchedUser) {
        if (switchRole === "user" || switchRole === "customer") {
          if (type === "customer") {
            userErrors.owningCompany = false;
            userErrors.owningCompanyMsg = "";
          } else {
            if (data.companyId === "" && type !== "channel") {
              userErrors.owningCompany = true;
              userErrors.owningCompanyMsg = "Please select an owning company";
              count++;
            } else {
              userErrors.owningCompany = false;
              userErrors.owningCompanyMsg = "";
            }
          }
        } else {
          if (data.companyId === "" && type !== "channel") {
            userErrors.owningCompany = true;
            userErrors.owningCompanyMsg = "Please select an owning company";
            count++;
          } else {
            userErrors.owningCompany = false;
            userErrors.owningCompanyMsg = "";
          }
        }
      }
    } else {
      if (data.companyId === "" && type !== "channel") {
        userErrors.owningCompany = true;
        userErrors.owningCompanyMsg = "Please select an owning company";
        count++;
      } else {
        userErrors.owningCompany = false;
        userErrors.owningCompanyMsg = "";
      }
    }
    setUserError(userErrors);
    return count;
  };

  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const copyData = { ...userData };
    copyData["fileobj"] = event.target.files[0];
    setUserData(copyData);
    setImgSrc(URL.createObjectURL(event.target.files[0]));
  };

  const [load, setLoad] = useState(false);

  const handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    let errorCount = validate(userData);
    if (errorCount === 0) {
      setLoad(true);
      setAddUserClick(true);
      formData.append("name", userData["userName"]);
      formData.append("email", userData["email"]);
      formData.append("companyId", userData["companyId"]);
      formData.append("userType", type === "admin" ? "internal" : "publisher");
      formData.append(
        "userImage",
        userData["fileobj"] ? userData["fileobj"] : ""
      );
      formData.append("editorid", localStorage.getItem("userid"));
      Services.createUser(formData)
        .then((res) => {
          setLoad(false);
          setAddUserClick(false);
          if (res?.data.status === "success") {
            localStorage.setItem("parentCompId", userData?.companyId);
            showSuccess(res?.data.message);
            navigate("/user-details/", {
              state: { clickedUserId: res.data.id },
            });
          } else {
            showError(res?.data.message);
          }
        })
        .catch((err) => {
          setAddUserClick(false);
        });
    }
  };

  const handleClear = () => {
    setUserData({
      userName: "",
      email: "",
      companyId: setCompanyId(),
    });
    setUserError({
      userName: false,
      userMsg: "",
      email: "",
      emailMsg: "",
    });
    setImgSrc(defaultUser);
    setListOpen(false);
    setType(setUserType());
  };

  const handleChange = (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "userName") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          userName: true,
          userMsg: "This field is required",
        });
      } else {
        setUserError({
          ...userError,
          userName: false,
          userMsg: "",
        });
      }
    } else if (e.target.name === "email") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          email: true,
          emailMsg: "This field is required",
        });
      } else {
        if (regExp.test(e.target.value) === false) {
          setUserError({
            ...userError,
            email: true,
            emailMsg: "Enter a valid Email ID",
          });
        } else {
          setUserError({
            ...userError,
            email: false,
            emailMsg: "",
          });
        }
      }
    }

    setUserData(newData);
  };

  const [searchData, setSearchData] = useState();
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const searchModal = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchData(e.target.value.trim());
      setUserError({
        owningCompany: false,
        owningCompanyMsg: "",
      });
      setSearchModalOpen(true);
    }
  };

  const [allData, setAllData] = useState();
  const [listOpen, setListOpen] = useState(false);
  const companyValues = (data) => {
    const copyData = { ...userData };
    copyData["companyId"] = data.companyId;
    setAllData([data]);
    setUserData(copyData);
    setSearchModalOpen(false);
    setListOpen(true);
  };

  const handleData = () => {
    setSearchModalOpen(true);
  };

  const searchChange = (e) => {
    if (e.target.value !== "") {
      setUserError({
        owningCompany: false,
        owningCompanyMsg: "",
      });
    } else {
      setUserError({
        owningCompany: true,
        owningCompanyMsg: "Please select an owning company",
      });
    }
  };
  const [companyData, setCompanyData] = useState();
  const companyDetails = () => {
    if (switchedUser) {
      setLoading(true);
      const companyId = () => {
        if (switchedUser) {
          if (switchRole === "licensee") {
            return localStorage.getItem("licenseeId");
          } else if (switchRole === "channel") {
            return localStorage.getItem("channelId");
          } else if (switchRole === "user" || switchRole === "customer") {
            return localStorage.getItem("customerId");
          }
        } else {
          setLoading(false);
          localStorage.getItem("companyId");
        }
      };
      Services.LicenseeAccountSettings({ companyId: companyId() })
        .then((res) => {
          setLoading(false);
          setCompanyData(res?.data);
        })
        .catch((err) => {});
    }
  };
  const displayCompanyDetails = () => {
    if (switchRole) {
      if (switchedUser) {
        if (switchRole === "licensee") {
          if (type === "licensee") {
            return true;
          } else {
            return false;
          }
        } else if (switchRole === "channel") {
          if (type === "channel") {
            return true;
          } else {
            return false;
          }
        } else if (switchRole === "user" || switchRole === "customer") {
          if (type === "customer") {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const channelButton = () => {
    if (switchRole) {
      if (switchedUser) {
        if (switchRole === "channel" || switchRole === "licensee") {
          return true;
        } else if (switchRole === "user" || switchRole === "customer") {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const customerButton = () => {
    if (switchRole) {
      if (switchedUser) {
        if (switchRole === "user" || switchRole === "customer") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const setUserType = () => {
    if (switchedUser) {
      if (switchRole === "channel") {
        return "channel";
      } else if (switchRole === "user" || switchRole === "customer") {
        return "customer";
      }
    } else {
      return "channel";
    }
  };
  const [type, setType] = useState(setUserType());

  const changeType = (typeValue) => {
    setType(typeValue);
    setListOpen(false);
    if (typeValue === "channel") {
      userData["companyId"] = localStorage.getItem("companyId");
    } else if (typeValue === "customer") {
      if (switchRole) {
        if (switchedUser) {
          if (switchRole === "user" || switchRole === "customer") {
            userData["companyId"] = localStorage.getItem("customerId");
          }
        } else {
          userData["companyId"] = "";
        }
      } else {
        userData["companyId"] = "";
      }
    }
    setAllData([]);
  };

  const [role, setRole] = useState();

  const getRoles = () => {
    const userRole = localStorage.getItem("role");
    if (userRole === "ROLE_ADMIN") {
      setRole("admin");
    } else if (userRole === "ROLE_LICENSEE") {
      setRole("licensee");
    } else if (userRole === "ROLE_CHANNEL") {
      setRole("channel");
    } else {
      setRole("customer");
    }
  };

  const [breadCrumbData, setBreadCrumbData] = useState();
  const getBreadCrumbList = () => {
    setLoading(true);
    Services.breadCrumbList(localStorage.getItem("companyId"))
      .then((res) => {
        setBreadCrumbData(res?.data);
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getRoles();
    getBreadCrumbList();
  }, []);
  useEffect(() => {
    companyDetails();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (switchRole) {
      handleClear();
    }
    //eslint-disable-next-line
  }, [switchedUser]);
  return (
    <CookiesProvider>
      <Layout>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt="2px"
        >
          {loading ? (
            <Loader
              type={"spokes"}
              color={"#01537A"}
              height={40}
              width={40}
              loading={loading.toString()}
            />
          ) : null}
        </Box>
        <Box sx={{ padding: "0px 0px 15px 25px" }}>
          {switchedUser ? (
            companyData?.parentCompany && !loading ? (
              <BreadCrumb value={companyData} other="AddUser" />
            ) : (
              ""
            )
          ) : breadCrumbData?.parentCompany && !loading ? (
            <BreadCrumb value={breadCrumbData} other="AddUser" />
          ) : (
            ""
          )}
        </Box>
        <Box>
          <Container maxWidth="xxl">
            <Box
              sx={{
                boxShadow: 2,
                padding: "20px 0px 20px 56px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  lineHeight: "30px",
                  color: "#01537A",
                  fontSize: "33px",
                }}
              >
                Add User
              </Typography>
              <Divider
                sx={{
                  backgroundColor: "#0094CD",
                  borderRadius: "6px",
                  marginTop: "20px",
                  borderBottomWidth: 1,
                  marginBottom: "20px",
                }}
              />
              <Box
                sx={{
                  maxWidth: {
                    xs: "100%",
                    sm: "500px",
                    md: "550px",
                    lg: "750px",
                  },
                  width: "100%",
                }}
              >
                {customerButton() ? (
                  <>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "30px",
                        color: "#01537A",
                      }}
                    >
                      1.User Role
                    </Typography>

                    <Divider
                      sx={{
                        borderRadius: "6px",
                        borderBottomWidth: 1,
                        marginBottom: "20px",
                      }}
                    ></Divider>
                  </>
                ) : (
                  ""
                )}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    padding: "0px 42px 30px 0px",
                    gap: 2,
                  }}
                >
                  {channelButton() && (
                    <UserRole
                      variant="contained"
                      onClick={() => changeType("channel")}
                      className={type === "channel" ? "save-btn" : "cancel-btn"}
                    >
                      Channel
                    </UserRole>
                  )}
                  {customerButton() ? (
                    <UserRole
                      variant="contained"
                      onClick={() => changeType("customer")}
                      className={
                        type === "customer" ? "save-btn" : "cancel-btn"
                      }
                    >
                      Customer
                    </UserRole>
                  ) : (
                    ""
                  )}
                </Box>

                {role !== type && (
                  <>
                    {customerButton() ? (
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "18px",
                          lineHeight: "30px",
                          color: "#01537A",
                          marginTop: "20px",
                        }}
                      >
                        2.Company
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "18px",
                          lineHeight: "30px",
                          color: "#01537A",
                        }}
                      >
                        1.Company
                      </Typography>
                    )}
                    <Divider
                      sx={{
                        borderRadius: "6px",
                        borderBottomWidth: 1,
                        marginBottom: "15px",
                      }}
                    ></Divider>
                    <Typography
                      sx={{ color: "#373737", textTransform: "capitalize" }}
                    >
                      {type}
                    </Typography>
                    {displayCompanyDetails() ? (
                      <Box
                        sx={{
                          maxWidth: {
                            xs: "100%",
                            sm: "500px",
                            md: "550px",
                            lg: "550px",
                          },
                          width: "100%",
                          fontSize: "34px",
                          display: "flex",
                        }}
                      >
                        <SearchList
                          companyData={companyData}
                          type={type}
                          changeBtn={true}
                        />
                      </Box>
                    ) : listOpen ? (
                      <Box
                        sx={{
                          maxWidth: {
                            xs: "100%",
                            sm: "500px",
                            md: "550px",
                            lg: "550px",
                          },
                          width: "100%",
                          fontSize: "34px",
                          display: "flex",
                        }}
                      >
                        <SearchList
                          data={allData}
                          changeBtn={listOpen}
                          onData={handleData}
                        />
                      </Box>
                    ) : (
                      <FormControl
                        error={!!userError?.owningCompany}
                        sx={{ width: { xs: "100%", md: "100%", lg: "720px" } }}
                      >
                        <Box
                          sx={{
                            maxWidth: { xs: "100%", md: "100%", lg: "720px" },
                            width: "100%",
                            fontSize: "34px",
                            display: "flex",
                            border: !userError?.owningCompany
                              ? "1px solid #01537A"
                              : "1.5px solid #d32f2f",
                            borderRadius: "4px",
                          }}
                        >
                          <Paper
                            component="form"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              height: "38px",
                            }}
                          >
                            <IconButton sx={{ p: "10px" }} aria-label="menu">
                              <SearchIcon
                                style={{
                                  color: "#01537A",
                                }}
                                sx={{
                                  fontSize: "24px",
                                }}
                              />
                            </IconButton>
                            <InputBase
                              width="100%"
                              style={{ ml: 1, flex: 1, color: "#373737" }}
                              placeholder={`Search ${type} `}
                              onKeyDown={searchModal}
                              onChange={searchChange}
                            ></InputBase>
                          </Paper>
                        </Box>
                        <FormHelperText>
                          {userError?.owningCompanyMsg}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </>
                )}
                {customerButton() ? (
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "30px",
                      color: "#01537A",
                      marginTop: "20px",
                    }}
                  >
                    {role !== type ? `3.User Details` : `2.User Details`}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "30px",
                      color: "#01537A",
                      marginTop: "20px",
                    }}
                  >
                    2.User Details
                  </Typography>
                )}
                <Divider
                  sx={{
                    borderRadius: "6px",
                    borderBottomWidth: 1,
                    marginBottom: "20px",
                  }}
                ></Divider>

                <Box
                  sx={{
                    display: "flex",
                    gap: "27px",
                    marginBottom: "50px",
                    flexDirection: { xs: "column", md: "row" },
                    rowGap: 2,
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        position: "relative",
                        width: "125px",
                        height: "125px",
                        textAlign: "center",
                        fontSize: "0.875rem",
                        fontWeight: "700",
                        marginBottom: "10px",
                      }}
                    >
                      <Paper
                        style={{
                          background: "#fff",
                          width: "125px",
                          height: "125px",
                          borderRadius: "14PX",
                          color: "#01537A",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <img
                          style={{ borderRadius: "50%", padding: "2px 2px" }}
                          alt="Datafree"
                          src={imgSrc === null ? defaultUser : imgSrc}
                          rounded="lg"
                          width="125px"
                          height="125px"
                        />
                      </Paper>
                      <input
                        style={{ display: "none" }}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                      />

                      <IconButton
                        onClick={handleClick}
                        style={{
                          position: "absolute",
                          backgroundColor: "#01537A",
                          color: "#FFFFFF",
                          width: "22.71px",
                          height: "22.71px",
                          top: imgSrc === defaultUser ? "" : "98px",
                          fontSize: "15px",
                          right: "14px",
                          bottom: "14px",
                        }}
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <CreateIcon fontSize="11px" />
                      </IconButton>
                    </Box>
                    <Typography
                      sx={{
                        color: "#01537A",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                      * optional
                    </Typography>
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { xs: "400px", md: "565px" },
                      width: "100%",
                      rowGap: "7px",
                      marginTop: "5px",
                    }}
                    autoComplete="off"
                  >
                    <TextField
                      required
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          letterSpacing: 0.5,
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                      inputlabelprops={{
                        style: { fontSize: 16, fontWeight: 400 },
                      }}
                      value={userData?.userName}
                      error={!!userError?.userName}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                      name="userName"
                      variant="standard"
                      helperText={userError?.userName ? userError?.userMsg : ""}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        letterSpacing: "0.4px",
                        color: "#01537A",
                      }}
                    >
                      User Name
                    </Typography>
                    <TextField
                      required
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          letterSpacing: 0.5,
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                      inputlabelprops={{
                        style: { fontSize: 16, fontWeight: 400 },
                      }}
                      value={userData?.email}
                      error={!!userError?.email}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                      name="email"
                      variant="standard"
                      helperText={userError?.email ? userError?.emailMsg : ""}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        letterSpacing: "0.4px",
                        color: "#01537A",
                      }}
                    >
                      Email
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "95%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    className="cancel-btn"
                    sx={{
                      width: "150px",
                      height: "40px",
                      fontWeight: "400",
                      color: "#01537A",
                      textTransform: "capitalize",
                      backgroundColor: "white",
                      border: "1px solid #01537A",
                    }}
                    variant="outlined"
                    onClick={handleClear}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="save-btn"
                    variant="contained"
                    onClick={handleSave}
                    disabled={addUserClick}
                    sx={{
                      width: "  150px",
                      height: " 40px",
                      fontWeight: "400",
                      color: "#01537A",
                      textTransform: "capitalize",
                      backgroundColor: "white",
                      border: "1px solid #01537A",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <span style={{ marginRight: "5px" }}>Add User</span>
                      {load ? (
                        <Loader
                          type={"spinningBubbles"}
                          color={"white"}
                          height={20}
                          width={20}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Layout>
      {searchModalOpen && (
        <CompanySearchModal
          type={type}
          role={role}
          searchData={searchData}
          open={searchModalOpen}
          companyValues={companyValues}
          setSearchModalOpen={setSearchModalOpen}
        />
      )}
    </CookiesProvider>
  );
}
