import React from "react";
import { Typography, Divider, Button, TextField, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 30,
  height: 16,
  padding: 0,
  display: "flex",

  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,

    "&.Mui-checked": {
      transform: "translateX(14px)",
      color: "#01537A",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: "3px solid #01537A!important",
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "white",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#01537A",

    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 8,
    height: 8,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    border: "3px solid #01537A!important",
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "white",
    boxSizing: "border-box",
  },
}));

export default function DataSortPanelNew({
  role,
  handleSelect,
  data,
  publisherList,
  filterOptions,
  handleKeyPress,
  handleApplicationSwitch,
  prodApplication,
  handleDataUsageSwitch,
  dateRange,
  disableRefresh,
  onRefresh,
  type,
  date,
  handleDateChange,
  maxDate,
  minDate,
  setOpenDateRangePicker,
  openDateRangePicker,
  differenceInDays,
  areAllReportingFieldsFilled,
  tabType,
}) {

  const switchRole = localStorage.getItem("switchRole");
  const switchedUser = JSON.parse(localStorage.getItem("switchedUser"));
  const publisherDropDown = () => {
    if (role === "ROLE_USER") {
      return false;
    } else {
      if (switchRole) {
        if (switchedUser) {
          if (switchRole === "user" || switchRole === "customer") {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };
  function TruncatedLabelValue({ label, value }) {
    const truncatedValue =
      label.length >= 24
        ? `${label.substring(0, 21)}...  [${value}]`
        : `${label} [${value}]`;

    return (
      <div>
        <span>{truncatedValue}</span>
      </div>
    );
  }

  return (
    <Box
      sx={{
        width: { xs: "30%", md: "30%", lg: "24%", xl: "17%" },
        padding: "28px 8px 16px 8px",
        minHeight: "400px",
        height: "100%",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      {publisherDropDown() && (
        <>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              marginBottom: "16px",
            }}
          >
            View Data For:
          </Typography>

          <Autocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => handleSelect(e, value, "publisher")}
            ListboxProps={{
              sx: {
                border: "1px solid #B1AEAE",
                borderRadius: "0px",
                overflowY: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "& .MuiMenuItem-root": {
                  color: "#01537A",
                  borderBottom: "1px solid #01537A",
                  fontSize: "16px",
                  backgroundColor: "#fff",
                  padding: "5px 0px",
                },
              },
            }}
            value={data?.publisher ? data?.publisher : ""}
            key={data?.publisher ? data?.publisher : ""}
            options={
              publisherList?.map((item) => ({
                value: item.companyId,
                id: item.companyId,
                label: item.name,
              })) || []
            }
            sx={{
              "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                visibility: "hidden",
              },
            }}
            getOptionLabel={(option) => option.label || ""}
            renderOption={(props, option) => {
              const { label, value } = option;
              return (
                <span
                  {...props}
                  value={value}
                  label={label}
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}
                  key={value}
                >
                  {label}
                </span>
              );
            }}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  label="Select customer"
                  onKeyDown={handleKeyPress}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  sx={{
                    "& label": {
                      color: "#000A26!important",
                    },
                    "& .MuiAutocomplete-option": {
                      color: "#01537A",
                    },

                    "& input::placeholder": {
                      color: "#757373",
                      fontSize: "15px",
                    },
                    "& .MuiInputBase-input": {
                      borderWidth: `1px !important`,
                      position: "relative",
                      fontSize: 16,
                      padding: "1px 5px",
                      borderRadius: "8px",
                      color: "#373737",
                    },
                    "& fieldset": {
                      borderWidth: `1px !important`,
                      borderRadius: "4px 4px 0px 0px",
                      borderColor: "#B1AEAE !important",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "black !important",
                    },
                  }}
                />{" "}
              </>
            )}
          />
        </>
      )}

      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "16px",
          marginBottom: "20px",
          marginTop: role !== "ROLE_USER" && "16px",
        }}
      >
        Applications
      </Typography>
      <Stack
        sx={{
          marginBottom: "20px",
        }}
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <Typography>All</Typography>
        <AntSwitch
          name="application"
          checked={data?.application === "singleApplication"}
          onChange={handleApplicationSwitch}
          inputProps={{ "aria-label": "ant design" }}
        />
        <Typography>Single application </Typography>
      </Stack>

      {data?.application === "singleApplication" && (
        <>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => handleSelect(e, value, "selectApplication")}
            ListboxProps={{
              sx: {
                border: "1px solid #B1AEAE",
                borderRadius: "0px",
                overflowY: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "& .MuiMenuItem-root": {
                  color: "#01537A",
                  borderBottom: "1px solid #01537A",
                  fontSize: "16px",
                  backgroundColor: "#fff",
                  padding: "5px 0px",
                },
              },
            }}
            value={data?.selectApplication ? data?.selectApplication : ""}
            key={data?.selectApplication ? data?.selectApplication : ""}
            options={
              prodApplication?.map((item) => ({
                value: item.appId,
                id: item.appId,
                label: item.appName,
              })) || []
            }
            sx={{
              "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                visibility: "hidden",
              },
              marginTop: "20px",
            }}
            getOptionLabel={(option) =>
              `${option.label || ""}${option.value ? ` [${option.value}]` : ""}`
            }
            renderOption={(props, option) => {
              const { label, value } = option;
              return (
                <span
                  {...props}
                  value={value}
                  label={label}
                  style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}
                  key={Math.random()}
                >
                  <TruncatedLabelValue label={label} value={value} />
                </span>
              );
            }}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  label=" Select Application"
                  onKeyDown={handleKeyPress}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  placeholder={"Select Application"}
                  sx={{
                    "& label": {
                      color: "#000A26!important",
                    },
                    "& .MuiAutocomplete-option": {
                      color: "#01537A",
                    },
                    "& input::placeholder": {
                      color: "#757373",
                      fontSize: "15px",
                    },
                    "& .MuiInputBase-input": {
                      borderWidth: `1px !important`,
                      position: "relative",
                      fontSize: 16,
                      padding: "1px 5px",
                      borderRadius: "8px",
                      color: "#373737",
                    },
                    "& fieldset": {
                      borderWidth: `1px !important`,
                      borderRadius: "4px 4px 0px 0px",

                      borderColor: "#B1AEAE !important",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "black !important",
                    },
                  }}
                />{" "}
              </>
            )}
          />
        </>
      )}
      <Divider
        sx={{
          color: "#757373",
          alignContent: "center",
          marginBottom: "16px",
          marginTop: "16px",
          borderWidth: tabType === "newTab" ? "0.5px" : "0px",
        }}
      />

      {tabType === "newTab" ? (
        <>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              marginBottom: "16px",
              marginTop: "5px",
            }}
          >
            {data?.application === "singleApplication"
              ? "Data usage:"
              : "Data Range:"}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Range</Typography>
            <AntSwitch
              checked={data?.dataUsage === "TODAY"}
              onChange={handleDataUsageSwitch}
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>Today</Typography>
          </Stack>
          <Divider
            sx={{
              color: "#757373",
              alignContent: "center",
              marginBottom: "16px",
              marginTop: "16px",
              borderWidth: "0.5px",
            }}
          />
          {data?.dataUsage === "RANGE" && (
            <>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              >
                {type === "dataUsage" ? "Date Range:" : "Month Range:"}
              </Typography>
              <FormControl fullWidth>
                <Autocomplete
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, value) => handleSelect(e, value, "monthRange")}
                  ListboxProps={{
                    sx: {
                      border: "1px solid #B1AEAE",
                      borderRadius: "0px",
                      overflowY: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      "& .MuiMenuItem-root": {
                        color: "#01537A",
                        borderBottom: "1px solid #01537A",
                        fontSize: "16px",
                        backgroundColor: "#fff",
                        padding: "5px 0px",
                      },
                    },
                  }}
                  value={data?.monthRange ? data?.monthRange : ""}
                  key={data?.monthRange ? data?.monthRange : ""}
                  options={
                    dateRange?.map((item) => ({
                      value: item.value,
                      id: item.id,
                      label: item.name,
                    })) || []
                  }
                  sx={{
                    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                      visibility: "hidden",
                    },
                  }}
                  getOptionLabel={(option) => option.label || ""}
                  renderOption={(props, option) => {
                    const { label, value } = option;
                    return (
                      <span
                        {...props}
                        value={value}
                        label={label}
                        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}
                        key={Math.random()}
                      >
                        {label}
                      </span>
                    );
                  }}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label={
                          type === "dataUsage"
                            ? "Select date range"
                            : "Select month range"
                        }
                        onKeyDown={handleKeyPress}
                        InputProps={{
                          ...params.InputProps,
                          readOnly: true,
                          onFocus: (event) => event.target.blur(),
                        }}
                        sx={{
                          "& label": {
                            color: "#000A26!important",
                          },
                          "& .MuiAutocomplete-option": {
                            color: "#01537A",
                          },
                          "& input::placeholder": {
                            color: "#757373",
                            fontSize: "15px",
                          },
                          "& .MuiInputBase-input": {
                            borderWidth: `1px !important`,
                            position: "relative",
                            fontSize: 16,
                            padding: "1px 5px",
                            borderRadius: "8px",
                            color: "#373737",
                          },
                          "& fieldset": {
                            borderWidth: `1px !important`,
                            borderRadius: "4px 4px 0px 0px",

                            borderColor: "#B1AEAE !important",
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "black !important",
                          },
                        }}
                      />{" "}
                    </>
                  )}
                />
              </FormControl>
              <Divider
                sx={{
                  color: "#757373",
                  alignContent: "center",
                  marginBottom: "20px",
                  marginTop: "15px",
                }}
              />
            </>
          )}

          {data?.monthRange &&
            data?.monthRange?.value === "CUSTOM" &&
            data?.dataUsage === "RANGE" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["SingleInputDateRangeField"]}>
                  <DateRangePicker
                    format="DD/MM/YYYY"
                    sx={{
                      label: { color: "black!important" },
                      minWidth: "0px!important",
                      marginBottom: "10px",
                      "& .MuiPickersDay-dayRangeStart, & .MuiPickersDay-dayRangeEnd":
                        {
                          background: "red!important",
                          colour: "red!important",
                        },
                    }}
                    label="Select date range"
                    slots={{ field: SingleInputDateRangeField }}
                    value={date}
                    onChange={handleDateChange}
                    minDate={minDate}
                    maxDate={dayjs()}
                    slotProps={{
                      textField: {
                        InputProps: {
                          endAdornment: (
                            <DateRangeOutlinedIcon
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          ),
                        },
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            )}
        </>
      ) : (
        ""
      )}
       <Button
          startIcon={<CloseIcon sx={{ padding: { xs: "3px", md: "0px" } }} />}
          onClick={onRefresh}
          variant="outlined"
          disabled={areAllReportingFieldsFilled && disableRefresh}
          sx={{
            "&.Mui-disabled": {
              color: "#757373 !important",
              textTransform: "none",
              padding: "2px 15px 2px 15px",
              border: "1px solid #757373!important",
            },
            color: "rgba(1, 83, 122, 1)",
            border: "1px solid rgba(1, 83, 122, 1)!important",
            marginLeft: "3px",
            marginBottom: "20px",
            textTransform: "none",
            fontSize: { xs: "10px", md: "14px" },
            marginTop: "15px",
            fontWeight: "400",
            padding: { xs: "3px", md: "2px 15px 2px 15px" },
          }}
        >
          Clear Filters
        </Button>
    </Box>
  );
}
