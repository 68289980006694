import { Outlet, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const PublicRoutes = () => {
  let auth = { token: false };
  let checkToken = sessionStorage.getItem("access_token");
  let expirationTime;
  let exp;
  if (checkToken !== "undefined" && checkToken !== null) {
    auth = { token: true };
    exp = jwtDecode(checkToken)?.exp;
    expirationTime = exp * 1000 - 60000;
    if (Date.now() >= expirationTime) {
      localStorage.clear();
      auth = { token: false };
    } else {
      auth = { token: true };
    }
  } else {
    localStorage.clear();
    auth = { token: false };
  }

  return auth.token ? <Navigate to="/" /> : <Outlet />;
};
export default PublicRoutes;
