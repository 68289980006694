import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import CompanySearchModal from "./companySearchModal";
import SearchList from "./searchList";

export default function AddRole({ companyDetails, changeData, validData }) {
  const [isValid, setValid] = useState(validData);
  const [change, setChange] = useState(false);
  // eslint-disable-next-line
  const [roleStatus, setRoleStatus] = useState("Active");
  const [type, setType] = useState("customer");
  const [searchData, setSearchData] = useState();
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const [userData, setUserData] = useState({
    companyId: "",
    roleStatus: roleStatus,
    type: type,
  });

  const [allData, setAllData] = useState();
  const searchModal = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchData(e.target.value.trim());
      setSearchModalOpen(true);
    }
  };
  const handleChange = (e) => {
    e.target.value === "" ? setValid(true) : setValid(false);
  };
  const companyValues = (data) => {
    const copyData = { ...userData };
    copyData["companyId"] = data.companyId;
    setUserData(copyData);
    setAllData([data]);
    setSearchModalOpen(false);
    setListOpen(true);
  };

  const changeType = (typeValue) => {
    setChange(true);
    setType(typeValue);
    setListOpen(false);
    const copyData = { ...userData };
    copyData["type"] = typeValue;
    setUserData(copyData);
  };

  const handleData = () => {
    setSearchModalOpen(true);
  };

  useEffect(() => {
    setValid(validData);
  }, [validData]);

  useEffect(() => {
    changeData(change);
    companyDetails(userData);
    //eslint-disable-next-line
  }, [userData]);

  return (
    <Paper
      sx={{
        maxWidth: { xs: "400px", md: "500px", lg: "596px", xl: "830px" },
        width: "100%",
        height: "auto",
        padding: "17px",
        display: "flex",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        justifyContent: "space-between",
        marginTop: "2px",
        borderRadius: "0px",
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "#373737",
            fontWeight: "700",
            lineHeight: "25.44px",
            fontSize: "18px",
            paddingBottom: "13px",
            paddingTop: "13px",
          }}
        >
          ADD NEW ROLE
        </Typography>

        <Typography
          sx={{
            color: "#373737",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "16.96px",
            paddingBottom: "13px",
          }}
        >
          Role
        </Typography>

        <ButtonGroup
          aria-label="outlined button group"
          sx={{
            width: {
              xs: "340px",
              md: "450px",
              lg: "565px",
              xl: "790px",
            },
            height: { xs: "35px", lg: "40px" },
            fontWeight: 400,
          }}
        >
          <Button
            onClick={() => changeType("licensee")}
            className={type === "licensee" ? "btn-active" : "btn-nonactive"}
            type="button"
            sx={{
              width: "100%",
              fontSize: { xs: "12px", md: "14px", lg: "16px" },
              textTransform: "capitalize",
            }}
            startIcon={type === "licensee" ? <DoneIcon /> : ""}
          >
            Licensee
          </Button>

          <Button
            onClick={() => changeType("channel")}
            className={type === "channel" ? "btn-active" : "btn-nonactive"}
            type="button"
            sx={{
              width: "100%",
              fontSize: { xs: "12px", md: "14px", lg: "16px" },
              textTransform: "capitalize",
            }}
            startIcon={type === "channel" ? <DoneIcon /> : ""}
          >
            Channel
          </Button>

          <Button
            onClick={() => changeType("customer")}
            className={type === "customer" ? "btn-active" : "btn-nonactive"}
            type="button"
            sx={{
              width: "100%",
              fontSize: { xs: "12px", md: "14px", lg: "16px" },
              textTransform: "capitalize",
            }}
            startIcon={type === "customer" ? <DoneIcon /> : ""}
          >
            Customer
          </Button>
        </ButtonGroup>
        <>
          <Typography
            sx={{
              color: "#373737",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "16.96px",
              paddingBottom: "13px",
              paddingTop: "20px",
            }}
          >
            Company Name
          </Typography>
          <Typography
            sx={{
              fontWeight: "400",
              color: "#373737",
              fontSize: "16px",
              lineHeight: "18px",
              paddingBottom: "5px",
              textTransform: "capitalize",
            }}
          >
            {type}
          </Typography>

          {listOpen ? (
            <Box
              sx={{
                maxWidth: { xs: "100%", md: "100%", lg: "100%" },
                width: "100%",
                fontSize: "34px",
                display: "flex",
                flex: "0 0 720px",
              }}
            >
              <SearchList
                data={allData}
                changeBtn={listOpen}
                onData={handleData}
                page={true}
              />
            </Box>
          ) : (
            <FormControl
              error={!!isValid}
              sx={{
                width: { xs: "100%", md: "450px", lg: "480px", xl: "550px" },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  fontSize: "34px",
                  display: "flex",
                  border: !isValid ? "1px solid #01537A" : "1px solid #d32f2f",
                  borderRadius: "4px",
                }}
              >
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "38px",
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <SearchIcon
                      style={{
                        color: "#01537A",
                      }}
                      sx={{
                        fontSize: "24px",
                      }}
                    />
                  </IconButton>

                  <InputBase
                    width="100%"
                    style={{ ml: 1, flex: 1, color: "#373737" }}
                    placeholder={`Search ${type} `}
                    onKeyDown={searchModal}
                    onChange={handleChange}
                  ></InputBase>
                </Paper>
              </Box>
              <FormHelperText>
                {isValid ? "Please choose a company" : ""}
              </FormHelperText>
            </FormControl>
          )}
        </>
      </Box>
      {searchModalOpen && (
        <CompanySearchModal
          type={type}
          role={type}
          searchData={searchData}
          open={searchModalOpen}
          companyValues={companyValues}
          setSearchModalOpen={setSearchModalOpen}
        />
      )}
    </Paper>
  );
}
