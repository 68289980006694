import { Box, Typography, Paper } from "@mui/material";

export default function PortsAndProtocols({ applicationDetails }) {
  const portsProtocol = applicationDetails?.data?.portsProtocol;

  return (
    <Box
      sx={{
        width: "90%",
        textAlign: "left",
        marginLeft: "5px",
        marginBottom: "20px",
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: "16px", color: "#1B2935" }}>
        Ports and Protocols
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "16px",
          marginBottom: "40px",
        }}
      >
        {/* Protocol */}
        <Paper
          sx={{ width: "30%", padding: "14px", border: "1px solid #e0e0e0" }}
        >
          <Typography
            variant="subtitle1"
            sx={{ color: "#1B2935", marginBottom: "10px" }}
          >
            Protocol
          </Typography>

          <Box
            sx={{
              borderBottom: "2px solid #e0e0e0",
              width: "90%",
              margin: "2 auto 12px ",
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {portsProtocol?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  textAlign: "left",
                  borderBottom: "1px solid #e0e0e0",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  width: "90%",
                  margin: "2 auto",
                }}
              >
                <span>{item.portProtocol.protocol}</span>
              </Box>
            ))}
          </Box>
        </Paper>

        <Paper
          sx={{ width: "30%", padding: "16px", border: "1px solid #e0e0e0" }}
        >
          <Typography
            variant="subtitle1"
            sx={{ color: "#1B2935", marginBottom: "10px" }}
          >
            Start of Port Range
          </Typography>

          <Box
            sx={{
              borderBottom: "2px solid #e0e0e0",
              width: "90%",
              margin: "2 auto 12px auto",
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {portsProtocol?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  textAlign: "left",
                  borderBottom: "1px solid #e0e0e0",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  width: "90%",
                  margin: "2 auto",
                }}
              >
                <span>{item.portStart || "-"}</span>
              </Box>
            ))}
          </Box>
        </Paper>

        <Paper
          sx={{
            width: "30%",
            padding: "16px",
            border: "1px solid #e0e0e0",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ color: "#1B2935", marginBottom: "10px" }}
          >
            End of Port Range
          </Typography>

          {/* Line after the title */}
          <Box
            sx={{
              borderBottom: "2px solid #e0e0e0",
              width: "90%",
              margin: "2 auto 12px auto",
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {portsProtocol?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  textAlign: "left",
                  borderBottom: "1px solid #e0e0e0",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  width: "90%",
                  margin: "2 auto ",
                }}
              >
                <span>{item.portEnd || "-"}</span>
              </Box>
            ))}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
