import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Avatar,
  TextField,
  InputAdornment,
  Stack,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import reachDefaultIcon from "../../assets/img/reachDefaultIcon.svg";
import ddirecticon from "../../assets/img/ddirecticon.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import ImgPopUp from "./imgPopUp";
import ConfirmationPopup from "../confirmationPopup";
import saveButton from "../../assets/img/saveButton.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import wrapLogo from "../../assets/img/wrap-logo.png";
import reachLogo from "../../assets/img/reachLogo.svg";
import WrapIcon from "../../assets/img/wrap-icon-image.png";
import switchAppIcon from "../../assets/img/swichIcon.svg";
import Services from "../../api/services";
import useToast from "../../hooks/useToast";

import { useNavigate, useLocation } from "react-router-dom";
import {
  switchStatusContext,
  statusContext,
  archivedStatusContext,
} from "../../context/serviceContext";
import splashCam from "../../assets/img/splashCam.svg";
import defaultSplash from "../../assets/img/defaultSplash.jpg";
import imgFrame from "../../assets/img/imgFrame.png";
import Loader from "../../components/loader";
import switchlogo from "../../assets/img/switchlogo.svg";
import sdirectlogo from "../../assets/img/sdirectlogo.svg";
import connectlogo from "../../assets/img/connectlogo.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltips from "../tooltips";
import { WarningMessages } from "../../config/messages";
import { regexPatterns } from "../../config/regex";
import { companyDetailPage } from "../../utils/roleHelper";

const API_URL = process.env.REACT_APP_API_URL;
export default function BasicDetails({
  productType,
  applicationDetails,
  sendLoadingStatus,
  onDataUpdate,
  loadData,
}) {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#01537A",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const commonTextFieldStyles = {
    width: "619px", // Full width
    marginTop: "10px",
    marginBottom: "10px",
    fontFamily: "Roboto",
    fontWeight: "400",
    backgroundColor: "#F5F5F5",
    fontSize: "16px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px", // Border radius for the entire TextField
      height: "42px", // Fixed height for the TextField
      "& fieldset": {
        borderColor: "#757373", // Border color
      },
      "&.Mui-disabled": {
        backgroundColor: "#F5F5F5", // Background color for disabled state
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px 16px", // Padding inside the input
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(16px, 12px) scale(1)", // Label position when not shrunk
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(16px, -6px) scale(0.75)", // Label position when shrunk
    },
  };

  const { showSuccess } = useToast();

  let navigate = useNavigate();
  const location = useLocation();
  const clickedAppId = location.state.clickedAppId;
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const [splashImgSrc, setSplashImgSrc] = useState();
  const [splashImgPopup, setSplashImgPopup] = useState(false);
  const [imgPopup, setImgPopUp] = useState(false);
  const [imgSrc, setImgSrc] = useState();
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [editData, setEditData] = useState({
    appName: "",
    googleAppId: "",
  });
  const [editDataError, setEditDataError] = useState({
    appName: false,
    appNameMsg: "",
    googleAppId: false,
    googleAppIdMsg: "",
  });

  const [editIcon, setEditIcon] = useState(false);
  const [editGoogleIdIcon, setEditGoogleIdIcon] = useState(false);
  const [load, setLoad] = useState(true);
  const [iconLoad, setIconLoad] = useState(true);
  const [saveLoad, setSaveLoad] = useState(false);
  const [saveGoogleIdLoad, setSaveGoogleIdLoad] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const warnMsg = `Are you sure you want delete this application?
  This action cannot be undone. `;

  const getImage = (e) => {
    setImgPopUp(true);
  };

  const handleImgPop = () => {
    setImgPopUp(false);
  };

  const refreshData = (imgValue) => {
    setImgSrc(imgValue);
    setIconLoad(false);
  };

  const handleConfirmation = () => {
    setConfirmationBox(false);
  };
  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const confirmSave = () => {};

  const textFieldRef = useRef(null);
  const textFieldRefGoogleRef = useRef(null);
  const handleEdit = (type) => {
    if (type === "appName") {
      setEditIcon(true);
      if (textFieldRef.current) {
        textFieldRef.current.focus();
        textFieldRef.current.setSelectionRange(
          textFieldRef.current.value.length,
          textFieldRef.current.value.length
        );
      }
    } else {
      setEditGoogleIdIcon(true);
      if (textFieldRefGoogleRef.current) {
        textFieldRefGoogleRef.current.focus();
        textFieldRefGoogleRef.current.setSelectionRange(
          textFieldRefGoogleRef.current.value.length,
          textFieldRefGoogleRef.current.value.length
        );
      }
    }
  };

  let googleAppIdRegex = /^[a-z][a-z0-9_]*(\.[a-z][a-z0-9_]*)+[0-9a-z_]?$/i;
  let appNameLengthRegex = /^.{0,32}$/;
  const [checkValue, setCheckValue] = useState();
  const handleChange = (e) => {
    if (editIcon) {
      if (e.target.name === "appName") {
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setEditDataError({
            ...editDataError,
            appName: true,
            appNameMsg: "This field is required",
          });
        } else if (e.target.value !== applicationDetails?.data?.appName) {
          Services.checkApplicationName({ applicationName: e.target.value })
            .then((res) => {
              setCheckValue(res.data.status);
              switch (true) {
                case res.data.status === true:
                  setEditDataError({
                    ...editDataError,
                    appName: true,
                    appNameMsg: "Application name already exists",
                  });
                  break;
                case !appNameLengthRegex.test(e.target.value):
                  setEditDataError({
                    ...editDataError,
                    appName: true,
                    appNameMsg: "Cannot exceed more than 32 characters",
                  });
                  break;
                case e.target.value === "" ||
                  e.target.value.match(/^ *$/) !== null:
                  setEditDataError({
                    ...editDataError,
                    appName: true,
                    appNameMsg: "This field is required",
                  });
                  break;
                case !regexPatterns.appNameRegex.test(e.target.value):
                  setEditDataError({
                    ...editDataError,
                    appName: true,
                    appNameMsg: "No special characters are allowed",
                  });
                  break;
                default:
                  setEditDataError({
                    ...editDataError,
                    appName: false,
                    appNameMsg: "",
                  });
                  break;
              }
            })
            .catch((err) => {});
        } else {
          setCheckValue(false);
          setEditDataError({
            ...editDataError,
            appName: false,
            appNameMsg: "",
          });
        }
      }

      setEditData({
        ...editData,
        appName: e.target.value,
      });
    } else {
      setEditIcon(false);
    }

    if (editGoogleIdIcon) {
      if (e.target.name === "googleAppId") {
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setEditDataError({
            ...editDataError,
            googleAppId: true,
            googleAppIdMsg: "This field is required",
          });
        } else if (e.target.value !== applicationDetails?.data?.googleAppId) {
          if (!googleAppIdRegex.test(e.target.value)) {
            setEditDataError({
              ...editDataError,
              googleAppId: true,
              googleAppIdMsg: "Please enter a valid google ID",
            });
          } else if (
            e.target.value === "" ||
            e.target.value.match(/^ *$/) !== null
          ) {
            setEditDataError({
              ...editDataError,
              googleAppId: true,
              googleAppIdMsg: "This field is required",
            });
          } else {
            setEditDataError({
              ...editDataError,
              googleAppId: false,
              googleAppIdMsg: "",
            });
          }
        } else {
          setEditDataError({
            ...editDataError,
            googleAppId: false,
            googleAppIdMsg: "",
          });
        }
      }

      setEditData({
        ...editData,
        googleAppId: e.target.value,
      });
    } else {
      setEditGoogleIdIcon(false);
    }
  };

  const handleChildDataChange = (data) => {
    onDataUpdate(data);
  };

  const handleFieldClose = (type) => {
    if (type === "appName") {
      setEditIcon(false);
      setEditDataError({
        ...editDataError,
        appName: false,
        appNameMsg: "",
      });
      setEditData({
        ...editData,
        appName: applicationDetails?.data?.appName
          ? applicationDetails?.data?.appName
          : "",
      });
    } else {
      setEditGoogleIdIcon(false);
      setEditDataError({
        ...editDataError,
        googleAppId: false,
        googleAppIdMsg: "",
      });
      setEditData({
        ...editData,
        googleAppId: applicationDetails?.data?.googleAppId
          ? applicationDetails?.data?.googleAppId
          : "",
      });
    }
  };

  const handleSave = (type) => {
    if (type === "appName") {
      if (
        editData?.appName !== "" &&
        editData?.appName.match(/^ *$/) === null
      ) {
        switch (true) {
          case regexPatterns.appNameRegex.test(editData?.appName) === false:
            setEditDataError({
              ...editDataError,
              appName: true,
              appNameMsg: "No special characters are allowed",
            });
            break;
          case appNameLengthRegex.test(editData?.appName) === false:
            setEditDataError({
              ...editDataError,
              appName: true,
              appNameMsg: " Cannot exceed more than 32 characters",
            });
            break;
          case checkValue === true:
            setEditDataError({
              ...editDataError,
              appName: true,
              appNameMsg: "Application name already exists",
            });
            break;
          default:
            setEditDataError({
              ...editDataError,
              appName: false,
              appNameMsg: "",
            });

            let payload = "";
            payload = {
              applicationId: clickedAppId,
              editorId: localStorage.getItem("userid"),
              appName: editData?.appName,
            };
            setSaveLoad(true);
            Services.changeAppDetails(clickedAppId, payload)
              .then((res) => {
                if (res?.data.status === "success") {
                  setEditIcon(false);
                  showSuccess(WarningMessages?.updated);
                  handleChildDataChange(true);
                  setSaveLoad(false);

                  setEditData({
                    ...editData,
                    appName: editData?.appName,
                  });
                }
              })
              .catch((err) => {});
        }
      } else {
        setEditDataError({
          ...editDataError,
          appName: true,
          appNameMsg: "This field is required",
        });
      }
    } else {
      if (editData?.googleAppId === "" || editData?.googleAppId === undefined) {
        setEditDataError({
          ...editDataError,
          googleAppId: true,
          googleAppIdMsg: "This field is required",
        });
      } else if (googleAppIdRegex.test(editData?.googleAppId) === false) {
        setEditDataError({
          ...editDataError,
          googleAppId: true,
          googleAppIdMsg: "Please enter a valid google ID",
        });
      } else {
        setEditDataError({
          ...editDataError,
          googleAppId: false,
          googleAppIdMsg: "",
        });

        let payload = {
          applicationId: clickedAppId,
          editorId: localStorage.getItem("userid"),
          googleAppId: editData?.googleAppId,
        };
        setSaveGoogleIdLoad(true);
        Services.changeAppDetails(clickedAppId, payload)
          .then((res) => {
            if (res?.data.status === "success") {
              setEditGoogleIdIcon(false);
              showSuccess(WarningMessages?.updated);
              handleChildDataChange(true);
              setSaveGoogleIdLoad(false);

              setEditData({
                ...editData,
                googleAppId: editData?.googleAppId,
              });
            }
          })
          .catch((err) => {});
      }
    }
  };

  const handleSplashImgPop = () => {
    setSplashImgPopup(false);
  };
  const refreshDataSplashImg = (imgValue) => {
    setSplashImgSrc(imgValue);
  };
  const getSplashImage = (e) => {
    setSplashImgPopup(true);
  };

  const [defaultImg, setDefaultImg] = useState();
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    if (value != null || "") {
      showSuccess(WarningMessages?.copied);
    }
  };
  const navigateCustomer = () => {
    if (localStorage.getItem("role") === "ROLE_USER") {
      navigate("/");
    } else {
      companyDetailPage(applicationDetails?.data?.publisherId, "CUSTOMER");
      navigate("/customer");
    }
  };

  const getAdornment = () => {
    if (!productType) {
      return "";
    } else if (!editIcon && !accessPermissionFields()) {
      return (
        <EditOutlinedIcon
          onClick={() => {
            handleEdit("appName");
          }}
          sx={{ fontSize: "15px", cursor: "pointer" }}
        />
      );
    } else {
      return "";
    }
  };

  const getStartURL = (url) => {
    if (url?.startURLProd) {
      return url?.startURLProd;
    } else {
      return url?.startURLDev;
    }
  };

  useEffect(() => {
    sendLoadingStatus(true);
    setEditData({
      ...editData,
      appName: applicationDetails?.data.appName
        ? applicationDetails?.data.appName
        : "",
      googleAppId: applicationDetails?.data.googleAppId
        ? applicationDetails?.data.googleAppId
        : "",
    });
    const imageValue = applicationDetails?.data.iconImage;
    const productType = applicationDetails?.data.productType;
    if (imageValue !== undefined) {
      setImgSrc(API_URL + imageValue);
      sendLoadingStatus(false);
      setIconLoad(false);
    }
    switch (productType) {
      case "#datafree REACH":
        setDefaultImg(reachDefaultIcon);
        break;
      case "#datafree WRAP":
        setDefaultImg(WrapIcon);
        break;
      case "#datafree SWITCH":
        setDefaultImg(switchAppIcon);
        break;
      default:
        break;
    }

    const splashImageValue = applicationDetails?.data.splashImage;
    if (splashImageValue !== undefined) {
      setSplashImgSrc(API_URL + splashImageValue);
      setLoad(false);
      sendLoadingStatus(false);
    }
    handleChildDataChange(loadData);
    //eslint-disable-next-line
  }, [applicationDetails]);

  return (
    <Box
      sx={{
        marginTop: "10px",
        display: { xs: "grid", sm: "flex" },
        gridTemplateColumns: {
          xs: "auto",

          md: "repeat(3, 1fr)",
          lg: "repeat(3, 1fr)",
        },
        flexDirection: { sm: "row" },
        gap: {
          xs: "60px",
          sm: "40px",
          md: "50px",
          lg: "60px",
          xl: "70px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: { sm: "30px", xl: "60px" },
          flexDirection: { sm: "column", lg: "row" },
        }}
      >
        <Box
          sx={{
            width: "fit-content",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
              color: "#01537A",
              fontWeight: "400",
              letterSpacing: "1.1px",
              marginBottom: "5px",
            }}
          >
            Application Image
          </Typography>
          <Box
            sx={{
              position: "relative",
              width: "172px",
              height: "171px",
            }}
          >
            <Paper
              sx={{
                background: "#fff",
                width: "135px",
                height: "134px",
                borderRadius: "14PX",
                color: "#01537A",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              {iconLoad ? (
                <Box sx={{ position: "relative", top: "48px", left: "46px" }}>
                  <Loader
                    type={"spokes"}
                    color={"#01537A"}
                    height={40}
                    width={40}
                  />
                </Box>
              ) : (
                <img src={imgSrc} alt="App Icon" width="135px" height="135px" />
              )}
            </Paper>

            <IconButton
              style={{
                position: "absolute",
                background: accessPermissionFields() ? "#373737" : "#01537A",
                color: "#FFFFFF",
                width: "22.71px",
                height: "22.71px",
                top: imgSrc === reachDefaultIcon ? "116px" : "117px",
                fontSize: "15px",
                right: imgSrc === reachDefaultIcon ? "33px" : "31px",
                bottom: "0px",
              }}
              className={accessPermissionFields() ? "disable" : null}
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={getImage}
            >
              <EditOutlinedIcon fontSize="11px" />
            </IconButton>
          </Box>

          <Typography
            sx={{
              fontSize: "10px",
              color: "#01537A",
              fontWeight: "400",
              lineHeight: "106%",
              letterSpacing: "1.1px",
            }}
          >
            Product
          </Typography>
          {productType === "#datafree REACH" ? (
            <Box
              sx={{
                display: "flex",
                boxShadow: "1",
                width: "135px",
                padding: "8px 0px",
                borderRadius: "12px",
                height: "51px",
              }}
            >
              <img alt="reachLogo" src={reachLogo} width="140px" />
            </Box>
          ) : productType === "#datafree WRAP" ? (
            <Box
              sx={{
                display: "flex",
                boxShadow: "1",
                width: "135px",
                padding: "8px 0px",
                borderRadius: "12px",
                height: "51px",
              }}
            >
              <img alt="wrapIcon" src={wrapLogo} width="140px" />
            </Box>
          ) : productType === "#datafree SWITCH" ? (
            <Box
              sx={{
                display: "flex",
                boxShadow: "1",
                width: "135px",
                padding: "8px 0px",
                borderRadius: "12px",
                height: "51px",
              }}
            >
              <img
                alt="switchlogo"
                src={switchlogo}
                width="140px"
                style={{ padding: "7px" }}
              />
            </Box>
          ) : productType === "Connect Workspace" ? (
            <Box
              sx={{
                display: "flex",
                boxShadow: "1",
                width: "135px",
                padding: "8px 0px",
                borderRadius: "12px",
                height: "51px",
              }}
            >
              <img
                alt="connectlogo"
                src={connectlogo}
                width="140px"
                style={{ padding: "7px" }}
              />
            </Box>
          ) : productType === "D-Direct" ? (
            <Box
              sx={{
                display: "flex",
                boxShadow: "1",
                width: "135px",
                padding: "8px 0px",
                borderRadius: "12px",
                height: "51px",
              }}
            >
              <img
                alt="ddirecticon"
                src={ddirecticon}
                width="140px"
                style={{ padding: "7px" }}
              />
            </Box>
          ) : productType === "#datafree DIRECT" ? (
            <Box
              sx={{
                display: "flex",
                boxShadow: "1",
                width: "135px",
                padding: "8px 0px",
                borderRadius: "12px",
                height: "51px",
              }}
            >
              <img
                alt="sdirectlogo"
                src={sdirectlogo}
                width="140px"
                style={{ padding: "7px" }}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>

        {productType === "#datafree WRAP" ||
        productType === "#datafree SWITCH" ? (
          <Box>
            <Typography
              sx={{
                fontSize: "10px",
                color: "#01537A",
                fontWeight: "400",
                letterSpacing: "1.1px",
                marginBottom: "5px",
              }}
            >
              Splash Image
            </Typography>
            <Box sx={{ position: "relative" }}>
              <img
                style={{ position: "absolue" }}
                src={imgFrame}
                alt="imgFrame"
                width="105.929px"
                height="214px"
              />

              {load ? (
                <Box
                  sx={{ position: "relative", bottom: "133px", left: "32px" }}
                >
                  <Loader
                    type={"spokes"}
                    color={"#01537A"}
                    height={40}
                    width={40}
                  />
                </Box>
              ) : (
                <>
                  <img
                    style={{
                      position: "absolute",
                      left: "25px",
                      bottom: "204px",
                      zIndex: "2",
                    }}
                    src={splashCam}
                    alt="splashCam"
                  />
                  <img
                    style={{
                      position: "absolute",
                      left: "7px",
                      bottom: "12px",

                      borderRadius: "7px",
                    }}
                    src={splashImgSrc}
                    alt="defaultSplash"
                    width="91px"
                    height="199px"
                  />
                </>
              )}

              <IconButton
                style={{
                  position: "absolute",
                  background: accessPermissionFields() ? "#373737" : "#01537A",
                  color: "#FFFFFF",
                  width: "22.71px",
                  height: "22.71px",
                  top: "193px",
                  fontSize: "15px",
                  right: "0px",
                  left: "84px",
                  bottom: "0px",
                }}
                className={accessPermissionFields() ? "disable" : null}
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={getSplashImage}
              >
                <EditOutlinedIcon fontSize="11px" />
              </IconButton>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            sm: "column",
            md:
              productType === "#datafree WRAP" || "#datafree SWITCH"
                ? "column"
                : "row",
            lg: "row",
          },
          gap: "50px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              marginBottom: "8px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "14px",
                letterSpacing: "1.1px",
                color: "#373737",
              }}
            >
              Customer:{" "}
            </Typography>
            <Typography
              onClick={navigateCustomer}
              sx={{
                lineHeight: "14px",
                fontWeight: "700",
                letterSpacing: "1.1px",
                color: "#01537A",
                textTransform: "uppercase",
                marginLeft: "2px",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              {" "}
              {applicationDetails?.data?.publisher &&
                applicationDetails?.data?.publisher}
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: "flex" }}>
              <Box>
                <TextField
                  inputRef={textFieldRef}
                  sx={{
                    width: {
                      xs: "10px",
                      sm: "390px",
                      md:
                        productType === "#datafree WRAP" || "#datafree SWITCH"
                          ? "470px"
                          : "250px",
                      lg:
                        productType === "#datafree WRAP" || "#datafree SWITCH"
                          ? "300px"
                          : "350px",
                      xl:
                        productType === "#datafree WRAP" || "#datafree SWITCH"
                          ? "400px"
                          : "470px",
                    },
                  }}
                  inputProps={{
                    sx: {
                      fontSize: "28px",
                      fontWeight: 400,
                      lineHeight: "25px",
                      color: editIcon ? "#01537A" : "#757373",
                      letterSpacing: 0.5,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    },
                  }}
                  inputlabelprops={{
                    style: { fontSize: 16, fontWeight: 400 },
                  }}
                  error={!!editDataError?.appName}
                  helperText={
                    editDataError?.appName ? editDataError?.appNameMsg : ""
                  }
                  value={
                    editData?.appName !== undefined ? editData?.appName : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {getAdornment()}
                      </InputAdornment>
                    ),
                  }}
                  name="appName"
                  variant="standard"
                  onChange={handleChange}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "0.4px",
                    color: "#01537A",
                    marginTop: "5px",

                    marginBottom:
                      productType !== "#datafree WRAP" ? "15px" : "20px",
                  }}
                >
                  App Name
                </Typography>
              </Box>
              {editIcon && (
                <Box
                  sx={{
                    marginLeft: "15px",
                    display: "flex",
                    gap: "10px",
                    marginTop: "12px",
                  }}
                >
                  {saveLoad ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"#01537A"}
                      height={30}
                      width={30}
                    />
                  ) : (
                    <Avatar
                      onClick={() => {
                        handleSave("appName");
                      }}
                      alt="saveButton"
                      src={saveButton}
                      sx={{
                        cursor: "pointer",
                        width: "35px",
                        height: "35px",
                      }}
                    />
                  )}

                  <Box
                    onClick={() => {
                      handleFieldClose("appName");
                    }}
                    sx={{
                      border: "1px solid #B3261E",
                      cursor: "pointer",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50px",
                      padding: "10px",
                      textAlign: "center",
                      position: "relative",
                    }}
                  >
                    {" "}
                    <CloseOutlinedIcon
                      sx={{
                        position: "absolute",
                        color: "#B3261E",
                        left: "5px",
                        top: "5px",
                      }}
                    />{" "}
                  </Box>
                </Box>
              )}
            </Box>

            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#1B2935",
                  padding: "10px 0px 10px 0px",
                  marginTop: "5px",
                }}
              >
                NAT Gateway Required
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography
                  sx={{
                    color: "#757373",
                  }}
                >
                  No
                </Typography>
                <IOSSwitch
                  disabled={true}
                  defaultChecked={
                    applicationDetails?.data?.natGatewayRequired ? true : false
                  }
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography
                  sx={{
                    color: "#757373",
                  }}
                >
                  Yes
                </Typography>
              </Stack>
            </>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", lg: "row" },

            gap: editIcon || editGoogleIdIcon ? "40px" : "100px",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "10px",
                sm: "391px",
                md:
                  productType === "#datafree WRAP" || "#datafree SWITCH"
                    ? "500px"
                    : "250px",
                lg:
                  productType === "#datafree WRAP" || "#datafree SWITCH"
                    ? "350px"
                    : "400px",
                xl:
                  productType === "#datafree WRAP" || "#datafree SWITCH"
                    ? "420px"
                    : "500px",
              },
            }}
          >
            {productType !== "#datafree SWITCH" && (
              <>
                <TextField
                  required
                  sx={{
                    width: "100%",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {applicationDetails?.data.startURL !== undefined &&
                          applicationDetails?.data.startURL !== null &&
                          applicationDetails?.data.startURL !== "" && (
                            <ContentCopyIcon
                              onClick={() => {
                                handleCopy(applicationDetails?.data.startURL);
                              }}
                              sx={{
                                fontSize: "15px",
                                cursor: applicationDetails?.data.startURL
                                  ? "pointer"
                                  : "no-drop",
                              }}
                            />
                          )}
                      </InputAdornment>
                    ),
                    style: {
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  inputlabelprops={{
                    style: { fontSize: 16, fontWeight: 400 },
                  }}
                  value={getStartURL(applicationDetails?.data)}
                  name="startUrl"
                  variant="standard"
                />

                <Box
                  sx={{
                    display: "flex",
                    marginTop: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      letterSpacing: "0.4px",
                      color: "#01537A",
                      marginBottom: "20px",
                    }}
                  >
                    Start URL
                  </Typography>

                  <Tooltips
                    type={
                      productType === "#datafree WRAP"
                        ? "wrapStartUrl"
                        : "startUrl"
                    }
                  >
                    <ErrorOutlineIcon
                      sx={{
                        marginLeft: "5px",
                        color: "#01537A",
                        fontSize: "15px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltips>
                </Box>
              </>
            )}

            {productType === "#datafree REACH" && (
              <>
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {applicationDetails?.data.datafreeDevDomain !==
                          undefined &&
                          applicationDetails?.data.datafreeDevDomain !== null &&
                          applicationDetails?.data.datafreeDevDomain !== "" && (
                            <ContentCopyIcon
                              onClick={() => {
                                handleCopy(
                                  applicationDetails?.data.datafreeDevDomain
                                );
                              }}
                              sx={{
                                fontSize: "15px",
                                cursor: applicationDetails?.data
                                  .datafreeDevDomain
                                  ? "pointer"
                                  : "no-drop",
                              }}
                            />
                          )}
                      </InputAdornment>
                    ),
                    style: {
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  inputlabelprops={{
                    style: { fontSize: 16, fontWeight: 400 },
                  }}
                  value={
                    applicationDetails
                      ? applicationDetails?.data.datafreeDevDomain
                      : ""
                  }
                  name="developUrl"
                  variant="standard"
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "0.4px",
                    color: "#01537A",
                    marginTop: "5px",
                    marginBottom: "20px",
                  }}
                >
                  Development Datafree Domain
                </Typography>
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {applicationDetails?.data.datafreeProdDomain !==
                          undefined &&
                          applicationDetails?.data.datafreeProdDomain !==
                            null &&
                          applicationDetails?.data.datafreeProdDomain !==
                            "" && (
                            <ContentCopyIcon
                              onClick={() => {
                                handleCopy(
                                  applicationDetails?.data.datafreeProdDomain
                                );
                              }}
                              sx={{
                                fontSize: "15px",
                                cursor: applicationDetails?.data
                                  .datafreeProdDomain
                                  ? "pointer"
                                  : "no-drop",
                              }}
                            />
                          )}
                      </InputAdornment>
                    ),
                    style: {
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  inputlabelprops={{
                    style: { fontSize: 16, fontWeight: 400 },
                  }}
                  name="productionUrl"
                  value={
                    applicationDetails
                      ? applicationDetails?.data.datafreeProdDomain
                      : ""
                  }
                  variant="standard"
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "0.4px",
                    color: "#01537A",
                    marginTop: "5px",
                    marginBottom: "20px",
                  }}
                >
                  Production Datafree Domain
                </Typography>
              </>
            )}

            {productType === "#datafree SWITCH" && (
              <>
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  inputProps={{
                    style: {
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  inputlabelprops={{
                    style: { fontSize: 16, fontWeight: 400 },
                  }}
                  name="securityKey"
                  value={
                    applicationDetails?.data.securityKey
                      ? applicationDetails?.data.securityKey
                      : ""
                  }
                  variant="standard"
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "0.4px",
                    color: "#01537A",
                    marginTop: "5px",
                    marginBottom: "20px",
                  }}
                >
                  Datafree Platform Security Key
                </Typography>
              </>
            )}

            <>
              <TextField
                sx={{
                  width: "100%",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {applicationDetails?.data.gatewayDomain !== undefined &&
                        applicationDetails?.data.gatewayDomain !== null &&
                        applicationDetails?.data.gatewayDomain !== "" && (
                          <ContentCopyIcon
                            onClick={() => {
                              handleCopy(
                                applicationDetails?.data.gatewayDomain
                              );
                            }}
                            sx={{
                              fontSize: "15px",
                              cursor: applicationDetails?.data.gatewayDomain
                                ? "pointer"
                                : "no-drop",
                            }}
                          />
                        )}
                    </InputAdornment>
                  ),
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                }}
                inputlabelprops={{
                  style: { fontSize: 16, fontWeight: 400 },
                }}
                name="gatwayDomain"
                value={
                  applicationDetails?.data.gatewayDomain
                    ? applicationDetails?.data.gatewayDomain
                    : ""
                }
                variant="standard"
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                  marginTop: "5px",
                }}
              >
                Service Domain
              </Typography>
            </>
          </Box>
        </Box>
      </Box>

      {confirmationBox && (
        <ConfirmationPopup
          open={confirmationBox}
          handleClose={handleConfirmation}
          confirmSave={confirmSave}
          message={warnMsg}
          type={"warning"}
          buttonMode={"Deleted"}
        />
      )}
      {imgPopup && (
        <ImgPopUp
          open={imgPopup}
          handleClose={handleImgPop}
          imgs={imgSrc}
          defaultImg={defaultImg}
          appId={clickedAppId}
          refreshData={refreshData}
          imgType={"iconImage"}
          type={
            productType === "#datafree REACH"
              ? "reach"
              : productType === "#datafree WRAP"
              ? "wrap"
              : productType === "#datafree SWITCH"
              ? "switch"
              : ""
          }
        />
      )}

      {splashImgPopup && (
        <ImgPopUp
          open={splashImgPopup}
          handleClose={handleSplashImgPop}
          imgs={splashImgSrc}
          defaultImg={defaultSplash}
          appId={clickedAppId}
          refreshData={refreshDataSplashImg}
          imgType={"splashImage"}
        />
      )}
    </Box>
  );
}
