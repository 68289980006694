import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "chartjs-adapter-date-fns";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { formatNumberWithSpaces } from "../../utils/formatNumber";

import { Divider, Typography } from "@mui/material";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

export default function TimeSeriesLineChart({
  heading,
  yAxis,
  xAxis,
  resData,
  type,
  range,
  startDate,
  endDate,
}) {
  const [allRange, setAllRange] = useState([]);
  const [transformedData, setTransformedData] = useState({
    focusminutesperuser: [],
  });

  useEffect(() => {
    setAllRange(resData?.focusMinsReport?.focusMinsPerUser ?? []);
  }, [resData]);

  function getAll(categories, inputData) {
    if (categories?.length !== 0) {
      return categories.reduce((acc, category) => {
        acc[category] = inputData.map((item) => [
          new Date(item.date).getTime(),
          item[category],
        ]);
        return acc;
      }, {});
    }
  }

  useEffect(() => {
    let categories = [];

    categories = ["focusminutesperuser"];

    const data = getAll(categories, allRange);
    setTransformedData(data);
  }, [allRange]);

  const labels =
    resData?.focusMinsReport?.focusMinsPerUser &&
    resData?.focusMinsReport?.focusMinsPerUser.map((item) => item?.date);

  const datasets = [
    {
      label: "Date",

      data: transformedData?.focusminutesperuser ?? [],

      backgroundColor: "#4CAF50",
      borderColor: "#4CAF50",
      borderWidth: 2,
      lineTension: 0,
      fill: false,
      pointRadius: 0,
    },
  ];

  const series = [
    {
      name: yAxis,
      data: datasets[0]?.data ? datasets[0]?.data : [],
    },
  ];

  const option = {
    tooltip: {
      enabled: true,
      hideEmptySeries: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let yAxis = "";
        let content = "";
        const xAxisValue = w.globals.seriesX[0][dataPointIndex];
        const formattedXAxisValue = moment(xAxisValue).format("DD/MM/YYYY");

        content += `<div class="header-box"><span style= "font-size: 16px;"> Summary</span><span  style="font-weight:600 ;font-size: 16px;"> :</span><span  style="font-weight:600 ;margin-left:5px ;font-size: 16px;">${formattedXAxisValue}</span><br></div>`;

        series.forEach((s, index) => {
          const name = w.globals.seriesNames[index];
          const value = s[dataPointIndex];
          const color = w.globals.colors[index];

          yAxis += `<div style="display: flex; justify-content: space-between; align-items: center;  width: calc(100% - 10px);;">

          <div style="display: flex;" >
          <div style="color:${color};font-size: 30px;">&#x25CF;</div>
           <div style="font-size: 16px;margin-top:10px">${
             name === "Total Focus Time per User (min)" ? "Total Focus" : ""
           }</div>
           </div>
           <div style="font-size: 16px;margin-top:5px"> ${
             isNaN(value) ? 0 : formatNumberWithSpaces(value)
           }<br>
           </div>
           </div>`;
        });

        content += `<div class="middle-box">${yAxis}</div>`;

        return '<div class="custom-tooltip">' + content + "</div>";
      },
    },
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#4CAF50"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      colors: "#4CAF50",
      width: 2,
    },
    title: {
      text: heading,
      align: "left",
      style: {
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Roboto",
        color: "rgba(0, 0, 0, 0.87)",
      },
    },

    grid: {
      show: true,
      borderColor: "#D3D6DF",
      strokeDashArray: 7,
      position: "front",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    xaxis: {
      type: labels && labels.length < 32 ? "category" : "datetime",
      labels:
        labels?.length < 32
          ? {
              formatter: function (val, timestamp) {
                return moment(timestamp).format("DD/MM");
              },
              style: {
                colors: [],
                fontSize: "12px",
                fontWeight: 700,
              },
              rotate: -45,
            }
          : {
              rotate: -45,
              style: {
                colors: [],
                fontSize: "12px",
                fontWeight: 700,
              },
            },

      title: {
        text: xAxis,
        offsetX: -10,
        offsetY: labels?.length <= 31 ? -14 : 0,
        style: {
          color: undefined,
          fontSize: "12px",
          fontWeight: 600,
        },
      },
      axisBorder: {
        show: true,
        color: "#01537A",
        height: 1,
        width: "100%",
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#01537A",
        height: 6,
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: true,
      seriesName: undefined,
      opposite: false,
      reversed: false,
      logarithmic: false,
      logBase: 10,
      tickAmount: undefined,
      forceNiceScale: false,
      floating: false,
      decimalsInFloat: undefined,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "12px",
          fontWeight: 300,
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
      },
      axisBorder: {
        show: false,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      title: {
        text: yAxis,
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      crosshairs: {
        show: false,
        position: "back",
        stroke: {
          color: "#b6b6b6",
          width: 2,
          dashArray: 0,
        },
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
    },
    legend: {
      show: true,
      position: "bottom",
      showForSingleSeries: true,
      onItemHover: {
        highlightDataSeries: false,
      },
      onItemClick: {
        toggleDataSeries: false, // Prevents series from being toggled
      },
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
    },
  };
  return (
    <Box sx={{ padding: "10px" }}>
      {!resData?.focusMinsReport?.focusMinsPerUser ||
      (resData?.focusMinsReport?.focusMinsPerUser &&
        resData?.focusMinsReport?.focusMinsPerUser?.length === 0) ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "8px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                letterSpacing: "0px",
              }}
            >
              {heading}
            </Typography>

            <MenuIcon
              sx={{
                color: "#6e8192",
                fontSize: "21px",
              }}
            />
          </Box>

          <Divider sx={{ marginBottom: "20px", border: "1px solid #B1AEAE" }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
              padding: "1rem",
              color: "#5A5656",
              height: "285px",
              width: "100%",
            }}
          >
            No reports to show
          </Box>
        </>
      ) : (
        <Box sx={{ height: { lg: "290px", xl: "500px" } }}>
          <ReactApexChart
            options={option}
            series={series}
            type="line"
            height="100%"
            width="100%"
          />
        </Box>
      )}
    </Box>
  );
}
