import {
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import Loader from "../../../../components/loader";
import Services from "../../../../api/services";
import { useLocation } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  statusContext,
  archivedStatusContext,
  switchStatusContext,
} from "../../../../context/serviceContext";
import useToast from "../../../../hooks/useToast";
import { regexPatterns } from "../../../../config/regex";

import "react-toastify/dist/ReactToastify.css";

export default function ApkConfig({
  applicationDetails,
  appDetailsRefresh,
  sendDataToDetailPage,
}) {
  const location = useLocation();
  const selectedAppId = location.state.clickedAppId;
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickDisable, setClickDisable] = useState(false);
  const [isValid, setValid] = useState(false);
  const [cancelDisable, setCancelDisable] = useState(true);
  const [clientVersionListLoaded, setClientVersionListLoaded] = useState(false);
  const [configData, setConfigData] = useState({
    configName: "",
    description: "",
    platform: "",
    googleAppId: "",
    packageSuffix: "PROD",
    distributionEmail: "",
    gatewaydomain: "",
    piwikSdk: false,
    locationService: false,
    storagePermission: false,
  });

  const [userError, setUserError] = useState({
    configName: false,
    configNameMsg: "",
    description: false,
    descriptionMsg: "",
    platform: false,
    platformMsg: "",
    googleAppId: false,
    googleAppIdMsg: "",
    packageSuffix: false,
    packageSuffixMsg: "",
    distributionEmail: false,
    distributionEmailMsg: "",
    gatewaydomain: false,
    gatewaydomainMsg: "",
  });

  const areAllFieldsFilled =
    configData.configName !== "" &&
    configData.description !== "" &&
    configData.platform !== "" &&
    configData.distributionEmail !== "" &&
    userError.distributionEmailMsg === "" &&
    userError.configNameMsg === "" &&
    userError.descriptionMsg === "";

  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );

  const { showSuccess, showError } = useToast();

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleInputChange = (e) => {
    const newData = { ...configData };
    newData[e.target.name] = e.target.value;
    setCancelDisable(false);

    switch (e.target.name) {
      case "configName":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            configName: true,
            configNameMsg: "This field is required",
          });
        } else {
          if (!regexPatterns.appConfigRegex.test(e.target.value)) {
            setUserError({
              ...userError,
              configName: true,
              configNameMsg: "No special characters are allowed",
            });
          } else if (!regexPatterns.appNameLengthRegex.test(e.target.value)) {
            setUserError({
              ...userError,
              configName: true,
              configNameMsg: " Cannot exceed more than 32 characters ",
            });
          } else {
            setUserError({
              ...userError,
              configName: false,
              configNameMsg: "",
            });
            configData?.configName !== e.target.value
              ? setValid(true)
              : setValid(false);
          }
        }
        break;

      case "description":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            description: true,
            descriptionMsg: "This field is required",
          });
        } else {
          if (!regexPatterns.descriptionRegex.test(e.target.value)) {
            setUserError({
              ...userError,
              description: true,
              descriptionMsg: "No special characters are allowed",
            });
          } else if (
            !regexPatterns.descriptionLengthRegex.test(e.target.value)
          ) {
            setUserError({
              ...userError,
              description: true,
              descriptionMsg: " Cannot exceed more than 500 characters ",
            });
          } else {
            setUserError({
              ...userError,
              description: false,
              descriptionMsg: "",
            });
            configData?.description !== e.target.value
              ? setValid(true)
              : setValid(false);
          }
        }
        break;

      case "platform":
        if (e.target.value === "") {
          setUserError({
            ...userError,
            platform: true,
            platformMsg: "This field is required",
          });
        } else {
          setUserError({
            ...userError,
            platform: false,
            platformMsg: "",
          });
          configData?.platform !== e.target.value
            ? setValid(true)
            : setValid(false);
        }
        break;

      case "packageSuffix":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            packageSuffix: true,
            packageSuffixMsg: "This field is required",
          });
        } else {
          setUserError({
            ...userError,
            packageSuffix: false,
            packageSuffixMsg: "",
          });
          configData?.packageSuffix !== e.target.value
            ? setValid(true)
            : setValid(false);
        }
        break;

      case "googleAppId":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            googleAppId: true,
            googleAppIdMsg: "This field is required",
          });
        } else {
          setUserError({
            ...userError,
            googleAppId: false,
            googleAppIdMsg: "",
          });
          configData?.googleAppId !== e.target.value
            ? setValid(true)
            : setValid(false);
        }
        break;

      case "gatewaydomain":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            gatewaydomain: true,
            gatewaydomainMsg: "This field is required",
          });
        } else {
          setUserError({
            ...userError,
            gatewaydomain: false,
            gatewaydomainMsg: "",
          });
          configData?.gatewaydomain !== e.target.value
            ? setValid(true)
            : setValid(false);
        }
        break;

      case "distributionEmail":
        if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
          setUserError({
            ...userError,
            distributionEmail: true,
            distributionEmailMsg: "This field is required",
          });
        } else {
          if (!regexPatterns.emailRegex.test(e.target.value)) {
            setUserError({
              ...userError,
              distributionEmail: true,
              distributionEmailMsg: "Enter a valid list of email addresses",
            });
          } else {
            setUserError({
              ...userError,
              distributionEmail: false,
              distributionEmailMsg: "",
            });
            configData?.distributionEmail !== e.target.value
              ? setValid(true)
              : setValid(false);
          }

          const distributionEmailList = newData.distributionEmail
            .replace(/,\s+/g, ",")
            .split(",");
          distributionEmailList.pop();
          const enteredEmail = e.target.value.split(",");
          if (
            distributionEmailList.includes(
              enteredEmail[enteredEmail.length - 1].trim()
            )
          ) {
            newData[e.target.name] = distributionEmailList;
          }
        }
        break;

      default:
        break;
    }

    setConfigData(newData);
  };

  const handleChange = (event) => {
    setCancelDisable(false);
    const { name, checked } = event.target;

    switch (name) {
      case "piwikSdk":
        setConfigData({
          ...configData,
          piwikSdk: checked ? ["piwik sdk"] : "",
        });
        setValid(true);
        break;

      case "locationService":
        setConfigData({
          ...configData,
          locationService: checked ? 1 : 0,
        });
        setValid(true);
        break;

      case "storagePermission":
        setConfigData({
          ...configData,
          storagePermission: checked ? 1 : 0,
        });
        setValid(true);
        break;

      default:
        break;
    }
  };

  const handleSave = () => {
    const type = "PROD";
    const applicationId = selectedAppId;
    let payload = {
      applicationType: "wrap",
      name: configData?.configName,
      description: configData?.description,
      googleAppId: configData?.googleAppId,
      packageSuffix: configData?.packageSuffix,

      distributionEmail: configData?.distributionEmail,
      includeSdks: configData?.piwikSdk ? configData?.piwikSdk : null,
      locationPermission: configData?.locationService,
      storagePermission: configData?.storagePermission,
      platform: configData?.platform,
    };
    setLoad(true);
    setClickDisable(true);
    Services.updateWrapApkConfig(type, applicationId, payload)
      .then((res) => {
        if (res?.data.status === "success") {
          showSuccess(res?.data?.message);
          appDetailsRefresh(true);
          handleCancel();
        } else {
          showError(res?.data?.message);
          appDetailsRefresh(true);
          handleCancel();
        }
        setClickDisable(false);
        handleCancel();
      })
      .catch((err) => {});
    appDetailsRefresh(false);
  };
  const handleCancel = () => {
    setUserError({
      configName: false,
      configNameMsg: "",
      description: false,
      descriptionMsg: "",
      platform: false,
      platformMsg: "",
      googleAppId: false,
      googleAppIdMsg: "",

      distributionEmail: false,
      distributionEmailMsg: "",
    });
    getWrapApkConfig();
    setValid(false);
    setLoad(false);
    setClickDisable(false);
    setCancelDisable(true);
  };

  const [value, setValue] = useState();
  const [clientVersionList, setClientVersionList] = useState({});
  async function getClientVersionDetails() {
    Services.clientVersionsList({
      productId: "1",
      deploymentType: "PROD",
    })
      .then((res) => {
        if (res?.status === 200) {
          setClientVersionList(res?.data);
          setClientVersionListLoaded(true);
          setValue(res.data[0].id);
        }
      })
      .catch((err) => {});
  }

  async function getWrapApkConfig() {
    setLoading(true);
    Services.getWrapApkConfig({
      applicationId: selectedAppId,
      deploymentType: "PROD",
    })
      .then((res) => {
        if (res?.status === 200) {
          sendDataToDetailPage(res?.data);
          setConfigData({
            ...configData,
            configName: res?.data?.name ? res?.data?.name : "",
            description: res?.data?.description ? res?.data?.description : "",
            platform:
              res?.data?.platform === null
                ? value
                : res?.data?.platform === undefined
                ? value
                : res?.data?.platform === ""
                ? value
                : res?.data?.platform,

            distributionEmail: res?.data?.distributionEmail
              ? res?.data?.distributionEmail
              : "",
            locationService: res?.data?.locationPermission
              ? res?.data?.locationPermission
              : 0,
            storagePermission: res?.data?.storagePermission
              ? res?.data?.storagePermission
              : 0,

            piwikSdk: res?.data?.includeSdks ? [res?.data?.includeSdks] : "",
            googleAppId: res?.data.googleAppId,
            packageSuffix: res?.data.packageSuffix,
          });
          if (!res?.data?.platform) {
            setValid(true);
          }
          setLoading(false);
        }
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getWrapApkConfig();
    getClientVersionDetails();
    // eslint-disable-next-line
  }, [value]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" mt="2px">
        {loading ? (
          <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
        ) : null}
      </Box>
      <Box
        sx={{
          display: { xs: "grid", md: "flex" },
          gap: "40px",
        }}
      >
        {clientVersionListLoaded ? (
          <>
            <Box
              sx={{
                width: {
                  xs: "50px",
                  sm: "366px",
                  md: "400px",
                  lg: "400px",
                  xl: "500px",
                },
              }}
            >
              <TextField
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                  "& input::placeholder": {
                    color: "#757373",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    opacity: "1",
                    fontWeight: "400",
                  },
                }}
                InputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {!accessPermissionFields() && (
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      )}
                    </InputAdornment>
                  ),
                  readOnly: accessPermissionFields() && true,
                }}
                value={configData?.configName ? configData?.configName : ""}
                error={!!userError?.configName}
                onChange={handleInputChange}
                name="configName"
                placeholder="Name"
                variant="standard"
                helperText={
                  userError?.configName ? userError?.configNameMsg : ""
                }
              />
              {configData?.configName && (
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#01537A",
                  }}
                >
                  Name
                </Typography>
              )}
              <TextField
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  marginTop: "10px",
                  "& input::placeholder": {
                    color: "#757373",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    opacity: "1",
                    fontWeight: "400",
                  },
                }}
                error={!!userError?.description}
                onChange={handleInputChange}
                InputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {!accessPermissionFields() && (
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      )}
                    </InputAdornment>
                  ),
                  readOnly: accessPermissionFields() && true,
                }}
                placeholder="Description"
                name="description"
                variant="standard"
                value={configData?.description ? configData?.description : ""}
                helperText={
                  userError?.description ? userError?.descriptionMsg : ""
                }
              />
              {configData?.description && (
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#01537A",
                  }}
                >
                  Description
                </Typography>
              )}
              <FormControl
                variant="standard"
                sx={{ width: "100%", marginTop: "15px" }}
              >
                {clientVersionListLoaded ? (
                  <Select
                    inputProps={{
                      style: {
                        fontSize: 16,
                        fontWeight: 400,
                        letterSpacing: 0.5,
                        color: "rgba(0, 0, 0, 0.87)",
                      },
                    }}
                    inputlabelprops={{
                      style: { fontSize: 16, fontWeight: 400 },
                    }}
                    onChange={handleInputChange}
                    value={configData?.platform || ""}
                    error={userError?.platform}
                    name="platform"
                  >
                    {clientVersionList.length > 0 ? (
                      clientVersionList?.map((item) => (
                        <MenuItem value={item?.id} key={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No data found</MenuItem>
                    )}
                  </Select>
                ) : (
                  ""
                )}
                <FormHelperText
                  sx={{
                    color: "#d32f2f",
                  }}
                >
                  {userError?.platform ? userError?.platformMsg : ""}
                </FormHelperText>
              </FormControl>
              {configData?.platform && (
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#01537A",
                  }}
                >
                  Datafree Platform
                </Typography>
              )}

              {/* <TextField
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  marginTop: "10px",
                  "& input::placeholder": {
                    color: "#757373",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    opacity: "1",
                    fontWeight: "400",
                  },
                }}
                inputlabelprops={{
                  style: { fontSize: 16, fontWeight: 400 },
                }}
                InputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {!accessPermissionFields() && (
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      )}
                    </InputAdornment>
                  ),
                  readOnly: accessPermissionFields() && true,
                }}
                value={configData?.googleAppId || ""}
                name="googleAppId"
                variant="standard"
                onChange={handleInputChange}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                  marginTop: "5px",
                  marginBottom: "0px",
                }}
              >
                Google App ID
              </Typography> */}

              {/* <TextField
                sx={{
                  width: "100%",
                  marginTop: "10px",
                }}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    </InputAdornment>
                  ),
                }}
                value={
                  configData?.packageSuffix ? configData?.packageSuffix : ""
                }
                error={!!userError?.packageSuffix}
                onChange={handleInputChange}
                name="packageSuffix"
                helperText={
                  userError?.packageSuffix ? userError?.packageSuffixMsg : ""
                }
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                  marginTop: "5px",
                  marginBottom: "0px",
                }}
              >
                Package Suffix
              </Typography> */}
              {/* 
              <TextField
                sx={{
                  width: "100%",
                }}
                InputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                }}
                inputlabelprops={{
                  style: { fontSize: 16, fontWeight: 400 },
                }}
                name="gatwayDomain"
                value={
                  applicationDetails?.data.gatewayDomain
                    ? applicationDetails?.data.gatewayDomain
                    : ""
                }
                variant="standard"
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                  marginTop: "5px",
                }}
              >
                Datafree Gateway domain
              </Typography> */}
              <TextField
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  marginTop: "10px",
                  "& input::placeholder": {
                    color: "#757373",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    opacity: "1",
                    fontWeight: "400",
                  },
                }}
                InputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {!accessPermissionFields() && (
                        <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                      )}
                    </InputAdornment>
                  ),
                  readOnly: accessPermissionFields() && true,
                }}
                placeholder="Distribution Email"
                value={
                  configData?.distributionEmail
                    ? configData?.distributionEmail
                    : ""
                }
                error={!!userError?.distributionEmail}
                onChange={handleInputChange}
                name="distributionEmail"
                variant="standard"
                helperText={
                  userError?.distributionEmail
                    ? userError?.distributionEmailMsg
                    : ""
                }
              />
              {configData?.distributionEmail && (
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#01537A",
                  }}
                >
                  Distribution Email
                </Typography>
              )}
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#01537A",
                }}
              >
                Include SDKs
              </Typography>
              <FormGroup>
                <FormControlLabel
                  checked={Boolean(configData.piwikSdk)}
                  name="piwikSdk"
                  onChange={handleChange}
                  control={<Checkbox />}
                  label="piwik sdk"
                  disabled={accessPermissionFields()}
                  style={{ color: "var(--black-06, rgba(0, 0, 0, 0.60))" }}
                />
              </FormGroup>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#01537A",
                  marginTop: "30px",
                }}
              >
                App permissions
              </Typography>
              <FormGroup>
                <FormControlLabel
                  checked={Boolean(configData.locationService)}
                  name="locationService"
                  onChange={handleChange}
                  control={<Checkbox />}
                  label="Allow App to request location Permission on install"
                  disabled={accessPermissionFields()}
                  style={{ color: "var(--black-06, rgba(0, 0, 0, 0.60))" }}
                />
                <FormControlLabel
                  checked={Boolean(configData.storagePermission)}
                  name="storagePermission"
                  onChange={handleChange}
                  control={<Checkbox />}
                  label="Allow App to request Storage Permission on install"
                  disabled={accessPermissionFields()}
                  style={{ color: "var(--black-06, rgba(0, 0, 0, 0.60))" }}
                />
              </FormGroup>
            </Box>
          </>
        ) : (
          ""
        )}
      </Box>

      <Box
        sx={{
          width: {
            xs: "50px",
            sm: "382px",
            md: "622px",
            lg: "100%",
            xl: "100%",
          },
          display: "flex",

          gap: "10px",
          justifyContent: "space-between",
          paddingTop: "30px",
          paddingRight: "20px",
          paddingBottom: "30px",
        }}
      >
        <Button
          onClick={handleCancel}
          className={accessPermissionFields() ? "disable" : null}
          disabled={cancelDisable}
          sx={{
            "&.Mui-disabled": {
              color: "#757373",
              textTransform: "capitalize",
              padding: " 5px 40px 5px 40px",
              borderColor: "#757373 !important",
            },
            padding: " 5px 40px 5px 40px",
            width: "fit-content",
            color: accessPermissionFields() ? "#373737" : "#01537A",
            borderColor: accessPermissionFields()
              ? "#373737"
              : "#01537A !important",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          className={
            accessPermissionFields() || clickDisable ? "disable" : null
          }
          onClick={handleSave}
          disabled={!areAllFieldsFilled || !isValid}
          sx={{
            padding: " 0px 50px 0px 50px",
            width: "fit-content",
            background: accessPermissionFields()
              ? "#373737"
              : "#01537A !important",
            textTransform: "capitalize",
            "&.Mui-disabled": {
              background: "#757373 !important",
              color: "white",
              textTransform: "capitalize",
              padding: "0px 50px",
            },
          }}
          variant="contained"
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <span style={{ marginRight: "5px" }}>Save</span>
            {load ? (
              <Loader
                type={"spinningBubbles"}
                color={"white"}
                height={20}
                width={20}
              />
            ) : (
              ""
            )}
          </Box>
        </Button>
      </Box>
    </>
  );
}
