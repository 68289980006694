export const WarningMessages = {
  apkCreated: "Apk successfully created",
  getAppStatus:
    "Publication has been submitted. This may take a while. To see the status of this publication, click Get App Status",
  applicationCreated: "Application has been successfully created",
  userNotActive: "User is not active. Please contact admin !",
  noActivity: "No actions to save !",
  updated: "Successfully updated",
  copied: "Copied to clipboard",
  xmlNotValid: "XML is not validated. Please add a valid XML",
  configMatched: "Config Successfully Matched",
  configCloned: "PRODUCTION APPLICATION CREATED.",
  //configCloned: "Config successfully cloned.",

  errorImg: "Something went wrong while image upload!",
  noDevVersion: "Sorry! You don't have a DEV version to clone",
  invalidCredential: "Invalid credentials.",
  startUrl:
    "The Start URL is the URL of the website that should be made datafree.",
  wrapStartUrl:
    "The Start URL is the URL that will open in the Webview when the Wrap app is first launched.",
  natGateway:
    "A NAT Gateway should be used when traffic from our optimiser needs to be whitelisted. Using a NAT Gateway allows for a single IP address to be whitelisted. For example, if a WAF is in place on the origin server then a NAT Gateway should be used.",
  mimeTypes:
    "MIME Types you want to block are to be added in the format type/subtype, eg. image/jpg. For more information on MIME Types see this link. <MIME types (IANA media types) - HTTP | MDN>",
  buildHistory:
    "Build history is a chronological record of all the previous build records of the application.A.apk file can also be downloaded with all of the build records.",
  buildApk:
    "An APK (Android Package)is a compressed archive file that contains all the necessary components of an Android app, including the code (compiled Java or Kotlin files),resources (such as images and XML files), and the AndroidManifest.xml file that provides essential information about the app.",
  xmlAssistant:
    "The XML Assistant is a XML Editor that makes editing of XML files easier.",
  subDomain: "Subdomain is the secondary domain for an application.",
  dataFreeUsage: "Total Datafree data usage in selected date range.",
  totalDataUsage: "Total data usage in selected date range.",
  avgUserData: "Average user Datafree data usage in selected date range.",
  totalUserDataUsage: "Total user data usage in selected date range.",
  dailyActiveUsers: "Total Active users today ",
  weeklyActiveUsers: "Total Active users in the last 7 days",
  monthlyActiveUsers: "Total Active users in the last 28 days",
  avgFocusTime: "Average focus time in selected date range.",
  maxFocusTime: "Maximum user focus time in selected date range.",
  minFocusTime: "Minimum user focus time in selected date range.",
  avgNavigation: "Average navigations in selected date range.",
  totalNavigation: "Total navigations in selected date range.",
  monthlyDataUsage: "Datafree Data Usage per user.",
  channelCustId:
    "This Identifier is used by certain licensees in order to match the customer to the customer in their system. This identifier is seen primarily in billing reports and is used by the licensee during their invoicing process.",
  monthlyAccAlert:
    "Data usage amount (in MB or GB) that is used to trigger alerts, sent at 50%, 80% and 100% of the limit that is entered. The total datafree data usage for all apps in the account is used to determine when alerts are sent.",
  usageAlertEmail:
    "The email address that the Monthly Account Alerts are sent to. A single email address can be entered.",
  totalDataFreeDownload:
    "Total Datafree data downloaded for today from 00h00 (UTC)",
  totalDownload: "Total data downloaded for today from 00h00 (UTC)",
  totalDatafreeUpload:
    "Total Datafree data uploaded for today from 00h00 (UTC)",
  totalUpload: "Total data uploaded for today from 00h00 (UTC)",
  usagePerHr: "Datafree Data Usage per user.",
  dow: "Day of Week",
  noDataAppStatus: "No app status to display",
  healthCheck: "Indicates availability of D-Direct Service",
  ddirectService: "Used by Customer to point at D-Direct App",
  ddirectEndPoint: "Domain or IP where D-Direct App forwards traffic to",
};
