import React from "react";
import TableView from "./tableView";
import ChartView from "./chartView";
import { Box } from "@mui/material";
import DataSortPanelNew from "./dataSortPanelNew";

export default function NewTab({
  viewType,
  role,
  prodApplication,
  publisherList,
  disableRefresh,
  openDateRangePicker,
  handleApplicationSwitch,
  handleSelect,
  data,
  filterOptions,
  handleKeyPress,
  createFilterOptions,
  handleDataUsageSwitch,
  dateRange,
  onRefresh,
  date,
  handleDateChange,
  maxDate,
  minDate,
  setOpenDateRangePicker,
  differenceInDays,
  resData,
  loading,
  handleSearch,
  totalCount,
  size,
  handlePageSize,
  changePagination,
  page,
  csvDatas,
  areAllReportingFieldsFilled,
  companyName,
  range,
  startDate,
  endDate,
}) {

  return (
    <Box
      sx={{
        display: "flex",
        gap: "36px",
      }}
    >
      <DataSortPanelNew
        tabType={"newTab"}
        role={role}
        handleSelect={handleSelect}
        data={data}
        publisherList={publisherList}
        filterOptions={filterOptions}
        handleKeyPress={handleKeyPress}
        handleApplicationSwitch={handleApplicationSwitch}
        createFilterOptions={createFilterOptions}
        prodApplication={prodApplication}
        handleDataUsageSwitch={handleDataUsageSwitch}
        dateRange={dateRange}
        disableRefresh={disableRefresh}
        onRefresh={onRefresh}
        type="dataUsage"
        date={date}
        handleDateChange={handleDateChange}
        maxDate={maxDate}
        minDate={minDate}
        setOpenDateRangePicker={setOpenDateRangePicker}
        openDateRangePicker={openDateRangePicker}
        differenceInDays={differenceInDays}
        areAllReportingFieldsFilled={areAllReportingFieldsFilled}
      />
      {viewType === "tableView" ? (
        <TableView
          data={data}
          resData={resData}
          loading={loading}
          handleSearch={handleSearch}
          totalCount={totalCount}
          size={size}
          handlePageSize={handlePageSize}
          changePagination={changePagination}
          page={page}
          csvDatas={csvDatas}
          companyName={companyName}
          role={role}
        />
      ) : (
        <ChartView
          data={data}
          resData={resData}
          loading={loading}
          companyName={companyName}
          role={role}
          range={range}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </Box>
  );
}
