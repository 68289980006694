import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import sortIcon from "../../assets/img/sortIcon.png";

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
      }}
    >
      No Results Found
    </Box>
  );
}

function CustomUnsortedIcon() {
  return <img src={sortIcon} alt="" />;
}

export default function ColumnGroupDataTable(tableData) {
  return (
    <Box sx={{ height: "auto" }}>
      <DataGridPro
        localeText={{ noRowsLabel: "No Results Found" }}
        rows={tableData?.rows}
        columns={tableData?.columns}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={tableData?.columnGroupingModel}
        hideFooter={true}
        autoHeight
        pageSize={tableData.size}
        getRowId={(row) => {
          return Math.random();
        }}
        loading={tableData?.loading}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoRowsOverlay,
          ColumnUnsortedIcon: CustomUnsortedIcon,
          ColumnSortedAscendingIcon: CustomUnsortedIcon,
          ColumnSortedDescendingIcon: CustomUnsortedIcon,
        }}
        initialState={{
          pinnedColumns: { left: ["name"] },
        }}
        disableColumnPinning={false}
        disableColumnMenu
        hideCellRightBorder={true}
        rowThreshold={9}
        disableExtendRowFullWidth={true}
        sortingOrder={["desc", "asc"]}
        sx={{
          "& .MuiDataGrid-columnHeader--filledGroup": {
            backgroundColor: "#01537A",
            color: "white",
            height: "calc(100% - 20px)",
            position: "relative",
            "&::before": {
              content: "''",
              position: "absolute",
              top: "35%",
              bottom: "35%",
              left: 0,
              width: "1px",
              backgroundColor: "white",
            },

            "& .MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: "center",
            },

            "& .MuiDataGrid-columnHeaderTitle": {
              flex: 1,
              textAlign: "center",
            },
          },
          "& .MuiDataGrid-columnHeader--emptyGroup": {
            backgroundColor: "#01537A",
          },

          boxShadow: "none",
          bgcolor: "#fff",
          borderRadius: "1px",
          padding: "0px",
          overflow: "none",
          border: "1px solid transparent",
          borderBottom: "1.14491px solid rgba(0, 0, 0, 0.12)",
          "& .Mui-focused ": {
            borderRadius: "0",
          },

          "& .MuiDataGrid-columnHeaders": {
            border: "none",

            backgroundColor: "#F2F2F2",
            position: "sticky",
            zIndex: 1,
            top: "63px",
          },

          "& .MuiDataGrid-pinnedColumns": {
            zIndex: 1,
            boxShadow: "none",
            overflow: "hidden",
            backgroundColor: "rgb(255, 255, 255)",
            marginBottom: "1px",
          },

          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiSvgIcon-root": {
            color: "#F2F2F2",
          },
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
          },
          "*::-webkit-scrollbar": {
            width: "5px",
            height: "9px",
          },
          "& .MuiDataGrid-container--top": {
            top: "60px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "visible !important",
            marginTop: "0px !important",
          },
          "& .MuiDataGrid-main": {
            overflow: "visible",
            height: "auto",
            zIndex: 12,
          },
        }}
      ></DataGridPro>
    </Box>
  );
}
