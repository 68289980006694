import { Box, Container, Divider } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ApkConfig from "../Development/apkConfig";
import LookAndFeel from "../Development/lookAndFeel";
import EntryPoint from "../Development/entryPoint";
import WrapSecurity from "../Development/security";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: " 10px", paddingLeft: "20px" }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const tabStyle = {
  color: "#01537A",
  fontWeight: 500,

  "&.MuiSelected": {
    color: "#01537A",
  },
};

export default function Development({
  applicationDetails,
  refreshApi,
  apiRefresh,
  sendDataToDetailPage
}) {
  const [value, setValue] = React.useState(0);
  const [isShow, setShow] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const appDetailsRefresh = (data) => {
    apiRefresh(data);
  };

  const getValidateStatus = (data) => {
    
    setShow(data);
  }
  // eslint-disable-next-line
  const [dataFromConfig, setDataFromConfig] = useState('');

  const handleData = (data) => {
    setDataFromConfig(data);
    sendDataToDetailPage(data)
  };
  return (
    <Container maxWidth="xxl">
      <Box
        sx={{
          width: "100%",
          background: "white",
          maxWidth: {
            xs: "210px",
            sm: "420px",
            md: "initial",
            lg: "initial",
            xl: "initial",
          },
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#01537A",
              },
            }}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab style={tabStyle} label="APK configuration" {...a11yProps(0)} />
            <Tab style={tabStyle} label="Entry Point" {...a11yProps(1)} />
            <Tab style={tabStyle} label="Security" {...a11yProps(2)} />
            <Tab style={tabStyle} label="Look and Feel" {...a11yProps(3)} />
          </Tabs>
          <Divider
            sx={{
              color: "#DFDFDF",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              marginTop: "15px",
              borderBottomWidth: 1,
            }}
          ></Divider>
          {value === 1 && !isShow && (
            <Box
              sx={{
                marginTop: "20px",
                textAlign: "center",
                background: "#01537A",
                width: "100%",
                color: "#FFFFFF",
                padding: "5px",
              }}
            >
              XML needs to be validated before you can save the application
            </Box>
          )}
        </Box>
        <TabPanel value={value} index={0}>
          <ApkConfig
            applicationDetails={applicationDetails}
            refreshApi={refreshApi}
            appDetailsRefresh={appDetailsRefresh}
            sendDataToDetailPage={handleData}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EntryPoint
            applicationDetails={applicationDetails}
            refreshApi={refreshApi}
            appDetailsRefresh={appDetailsRefresh}
            getValidateStatus={getValidateStatus}
            isShow={isShow}
            setShow={setShow}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <WrapSecurity
            applicationDetails={applicationDetails}
            refreshApi={refreshApi}
            appDetailsRefresh={appDetailsRefresh}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <LookAndFeel
            applicationDetails={applicationDetails}
            refreshApi={refreshApi}
            appDetailsRefresh={appDetailsRefresh}
          />
        </TabPanel>
      </Box>
    </Container>
  );
}
