import React, { useEffect, useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Error from "../assets/img/error.png";
import Warning from "../assets/img/warning.png";
import Success from "../assets/img/success.png";
import Loader from "../components/loader";

export default function ConfirmationPopup({
  open,
  handleClose,
  confirmSave,
  message,
  type,
  isShow,
  buttonMode,
}) {
  const [disableButton, setDisableButton] = useState(false);
  const [load, setLoad] = useState(false);
  const handleClear = () => {
    handleClose();
    setDisableButton(false);
    setLoad(false);
  };

  const handleSave = () => {
    setDisableButton(true);
    setLoad(true);
    confirmSave();
  };

  useEffect(() => {}, []);

  return (
    <>
      <Dialog
        open={open}
        scroll={"body"}
        maxWidth="xs"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          "& .MuiDialog-paper ": {
            borderBottom:
              type === "warning"
                ? "5px solid #ff9800"
                : type === "error"
                ? "5px solid #FF5252"
                : type === "success"
                ? "5px solid #4CAF50"
                : "",
          },
          textTransform: "uppercase",
        }}
      >
        <DialogContent sx={{ display: "flex", padding: "28px 24px 0px 35px" }}>
          <Box
            sx={{
              padding: {
                display: "flex",
                justifyContent: "space-between",
                gap: 18,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={
                  type === "warning"
                    ? Warning
                    : type === "error"
                    ? Error
                    : type === "success"
                    ? Success
                    : ""
                }
                alt=""
              />
            </Box>
            <Typography
              style={{ whiteSpace: "pre-line" }}
              sx={{
                color: "#373737",
                fontWeight: 400,
                fontSize: "16px",
                width: "270px",
              }}
            >
              {message}
            </Typography>
            <CloseSharpIcon
              sx={{
                color: "#373737",
                cursor: "pointer",
              }}
              onClick={handleClear}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {type === "warning" && !isShow && (
            <Box
              sx={{
                padding: "0px 30px 20px 90px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Button
                sx={{
                  width: "200px",
                  color: "#373737 !important",
                  border: "1px solid #373737 !important",
                  backgroundColor: "#fff !important",
                  textTransform: "capitalize",
                  marginBottom: { xs: "10px", md: "0px" },
                }}
                onClick={handleClear}
                variant="contained"
              >
                Cancel
              </Button>
              {buttonMode === "Deleted" ? (
                <Button
                  className={disableButton ? "disable" : null}
                  onClick={handleSave}
                  sx={{
                    padding: " 0px 50px 0px 50px",
                    width: "fit-content",
                    backgroundColor: "#fff !important",
                    textTransform: "capitalize",
                    border: "1px solid #373737 !important",
                    color: "#373737 !important",
                    marginLeft: { xs: "0px", md: "30px" },
                  }}
                  variant="contained"
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <span style={{ marginRight: "5px" }}>Deleted</span>
                    {load ? (
                      <Loader
                        type={"spinningBubbles"}
                        color={"#ff9800"}
                        height={20}
                        width={20}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Button>
              ) : (
                <Button
                  sx={{
                    width: "200px",
                    border: "1px solid #373737 !important",
                    color: "#373737 !important",
                    marginLeft: { xs: "0px", md: "30px" },
                    textTransform: "capitalize",
                    backgroundColor: "#fff !important",
                  }}
                  variant="contained"
                  onClick={handleSave}
                >
                  {buttonMode ? buttonMode : "Archive"}
                </Button>
              )}
            </Box>
          )}
          {isShow && (
            <Button
              sx={{
                width: "100px",
                color: "#373737 !important",
                border: "1px solid #373737 !important",
                backgroundColor: "#fff !important",
                textTransform: "capitalize",
                marginBottom: { xs: "10px", md: "0px" },
                marginRight: "20px",
              }}
              onClick={handleClear}
              variant="contained"
            >
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
