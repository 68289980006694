import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Add from "../assets/img/addNew.svg";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import TuneIcon from "@mui/icons-material/Tune";
import BusinessIcon from "@mui/icons-material/Business";
import AvatarLogo from "../assets/img/avatar.svg";

import Profileavatar from "../assets/img/noImages.png";
import RefreshIcon from "@mui/icons-material/Refresh";
import LogoPopUp from "../components/logoPopUp";
import useToast from "../hooks/useToast";
import {
  List,
  ListItem,
  Paper,
  ListItemAvatar,
  Container,
  Avatar,
  OutlinedInput,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import avatar from "../assets/img/defaultUserImg.png";
import Graph from "./graph";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LayersIcon from "@mui/icons-material/Layers";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Fab from "@mui/material/Fab";
import Services from "../api/services";
import moment from "moment";
import Loader from "../components/loader";
import { useNavigate } from "react-router-dom";
import SnackbarWrapper from "./snackBar";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { useCookies } from "react-cookie";
import Stack from "@mui/material/Stack";
import {
  userContext,
  userDispatchContext,
  switchStatusDispatchContext,
  companyDispatchContext,
} from "../context/serviceContext";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import WrapIcon from "../assets/img/wrap-icon-image.png";
import switchAppIcon from "../assets/img/swichIcon.svg";
import reachDefaultIcon from "../assets/img/reachDefaultIcon.svg";
import { useUser } from "../context/userContext";
import { getRoleMap } from "../utils/roleHelper";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#0094CD",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0094CD",
    width: "120px",
    height: "45px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    justifyContent: "center",
    padding: "15px",
    marginBottom: "5px",
  },
}));

export default function Dashboard() {
  const { showError } = useToast();
  const userContextVal = useContext(userContext);
  const userDispatchContextVal = useContext(userDispatchContext);
  let switchStatusContextVal = useContext(switchStatusDispatchContext);
  const [companyImage, setImageData] = useState();
  const navigate = useNavigate();
  let API_URL = process.env.REACT_APP_API_URL;
  const [cookies] = useCookies(["searchArray"]);
  const [allData, setAllData] = useState();
  const [latestPublisher, setLatestPublisher] = useState();
  const [latestApps, setLatestApps] = useState();
  const [activeUsers, setActiveUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState();
  const [opens, setOpens] = React.useState(false);
  const [isMount, setIsMount] = React.useState();
  const [isCompanyHovered, setIsCompanyHovered] = useState(false);
  const [isAppHovered, setIsAppHovered] = useState(false);
  const [isActiveHovered, setIsActiveHovered] = useState(false);

  const handleOpen = () => {
    setOpens(true);
  };
  const handleCloses = () => {
    setOpens(false);
  };
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;

  const { userImage } = useUser();

  const MenuProps = {
    PaperProps: {
      sx: {
        boxShadow: "none",
        border: "1px solid #01537A",
        bgcolor: "#fff",
        maxWidth: "160px",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        borderTopRightRadius: "0",
        borderTopLeftRadius: "0",
        paddingRight: "8px",
        "& .Mui-focused ": {
          borderBottomRightRadius: "0",
          borderBottomLeftRadius: "0",
        },
        "& .MuiOutlinedInput-root": {
          fontWeight: "700",
          color: "#01537A",
        },
        "& .MuiMenuItem-root": {
          color: "#01537A",
          borderBottom: "1px solid #01537A",
          fontSize: "17px",
          backgroundColor: "#fff",
          padding: "6px 0px",
        },
        "& .MuiMenuItem-root: last-child": {
          color: "#01537A",
          borderBottom: "none",
          fontSize: "17px",
          backgroundColor: "#fff",
          padding: "5px 0px",
        },
        "& .Mui-selected": {
          backgroundColor: "rgba(1, 83, 122, 0.1)",
          borderRadius: "6px !important",
        },
        "& .Mui-selected:hover": {
          backgroundColor: "rgba(1, 83, 122, 0.1)",
          borderRadius: "6px !important",
          padding: "3px",
        },
        "& .MuiBox-root:hover": {
          backgroundColor: "rgba(1, 83, 122, 0.1)",
          borderRadius: "4px",
        },
        "& .MuiBox-root": {
          padding: "5px 10px",
          width: "100%",
        },
        "& .MuiList-root": {
          padding: "0 15px",
        },
        "& .MuiInputBase-input": {
          padding: "6px 10px 7px",
        },
      },
    },
  };
  const companyDispatchContextVal = useContext(companyDispatchContext);
  const [companyData, setCompanyData] = useState();
  async function getData() {
    setLoading(true);
    const companyId = localStorage.getItem("companyId");
    const email = localStorage.getItem("username");

    try {
      const { data } = await Services.AdminIndex({ companyId, email });

      setAllData(data);
      setImageData(data.companyLogo);
      if (data?.companyLogo) {
        setImgSrc(API_URL + data?.companyLogo);
      } else {
        setImgSrc(Profileavatar);
      }

      setLatestPublisher(data.latestCompanies);
      setLatestApps(data.latestApps);
      setActiveUsers(data.recentlyActiveUsers);
      setCompanyData(data.companyDetails);
      setLoading(false);

      if (data.companyDetails?.status === "Suspended") {
        localStorage.setItem("switchStatus", true);
        switchStatusContextVal(true);
      } else {
        localStorage.setItem("switchStatus", false);
        switchStatusContextVal(false);
      }

      const { notificationMessage } = data;
      if (notificationMessage?.length > 0) {
        setOpen(true);
        setNotifyMessage(notificationMessage[0].notification_text);
      }
    } catch (error) {}
  }

  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [searchKey, setSearchKey] = useState();
  const goToSearch = (e) => {
    setSearchKey(e.target.value.trim());
    if (e.key === "Enter") {
      e.preventDefault();
      navigate("/search", { state: { key: e.target.value.trim() } });
    }
  };
  const goToSearchs = (e) => {
    e.preventDefault();
    navigate("/search", { state: { key: searchKey } });
  };

  const goToSearchPrevious = (keyVal) => {
    navigate("/search", { state: { key: keyVal } });
  };

  const getChanges = (changedVal) => {
    if (changedVal < 0) {
      return (
        <>
          {Math.abs(changedVal)}
          <ArrowDownwardIcon style={{ color: "#FF5252", fontSize: 14 }} />
        </>
      );
    } else if (changedVal > 0) {
      return (
        <>
          {Math.abs(changedVal)}
          <ArrowUpwardIcon style={{ color: "#4CAF50", fontSize: 14 }} />
        </>
      );
    } else {
      return "No Change";
    }
  };

  const getprodType = (app) => {
    if (app?.apps?.productId) {
      return (
        <>
          <span>
            {app?.apps?.productId?.productId === 7
              ? "D-Direct"
              : app?.apps?.productId?.productId === 1
              ? "Wrap"
              : app?.apps?.productId?.productId === 2
              ? "Max"
              : app?.apps?.productId?.productId === 3
              ? "Switch"
              : app?.apps?.productId?.productId === 4
              ? "Reach"
              : app?.apps?.productId?.productId === 5
              ? "S-Direct"
              : "Connect"}
          </span>
        </>
      );
    }
  };
  const addUser = () => {
    localStorage.setItem("switchedUser", JSON.stringify(false));
    navigate("/create-user");
  };
  const addApplication = () => {
    localStorage.setItem("switchedUser", JSON.stringify(false));
    navigate("/create-application");
  };
  const redirectPages = (tabval, type) => {
    navigate("/search", {
      state: { key: searchKey, tabVal: tabval, type: type },
    });
  };

  const userDetailPage = (userId, userRole, companyId) => {
    localStorage.setItem("parentCompId", companyId);
    userRole !== null && localStorage.setItem("selectType", userRole);
    navigate("/user-details", { state: { clickedUserId: userId } });
  };

  const companyDetailPage = (id, type) => {
    const roleMap = getRoleMap();
    const selectedRole = roleMap[type];

    if (selectedRole) {
      localStorage.setItem("switchRole", selectedRole.role);
      localStorage.setItem(selectedRole.idKey, id);

      Object.keys(roleMap).forEach((key) => {
        if (key !== type) {
          localStorage.removeItem(roleMap[key].idKey);
        }
      });
      navigate(selectedRole.path, { state: { clickedCompId: id } });
    }
  };

  const applicationDetailPage = (value) => {
    navigate("/application-details", {
      state: {
        clickedAppId: value?.applicationId,
        productType: value?.productId?.productType,
      },
    });
  };

  const redirectViewall = (tabval) => {
    navigate("/search", {
      state: { key: searchKey, tabVal: tabval, sortType: "Last Changed" },
    });
  };

  const setCompany = (value) => {
    localStorage.setItem("switchedUser", JSON.stringify(false));
    localStorage.setItem("companyType", value);
    companyDispatchContextVal(value);
    navigate("/create-company");
  };

  const [selected, setSelected] = useState("7");
  const handleGraph = (event) => {
    setSelected(event.target.value);
  };
  const companyId = localStorage.getItem("companyId");
  const [graphData, setGraphData] = useState();
  const [load, setLoad] = useState(false);
  async function graphDatas() {
    setLoad(true);
    const companyId = localStorage.getItem("companyId");
    Services.getGraph({ reportPeriod: selected, companyId: companyId })
      .then((res) => {
        setGraphData(res?.data);
        setLoad(false);
      })
      .catch((error) => {
        if (error?.response.status === 401) {
          localStorage.clear();
        } else {
          showError("Something went wrong please reload the page!.");
        }
      });
  }

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter === 59) {
        getData();
        graphDatas();
        setCounter(0);
      } else {
        setCounter((counter) => counter + 1);
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };

    //eslint-disable-next-line
  }, [counter]);

  const refreshData = () => {
    setCounter(0);
    getData();
    graphDatas();
  };

  function getAppIcon(app) {
    const defaultIcon = "defaultappicon.svg";
    const productType = app?.apps?.productId?.productType;

    if (app.apps?.icon !== defaultIcon) {
      return API_URL + app?.apps?.icon;
    } else {
      if (productType === "#datafree WRAP") {
        return WrapIcon;
      } else if (productType === "#datafree REACH") {
        return reachDefaultIcon;
      } else if (productType === "#datafree SWITCH") {
        return switchAppIcon;
      } else if (productType === "#datafree DIRECT") {
        return switchAppIcon;
      } else if (productType === "D-Direct") {
        return switchAppIcon;
      } else if (productType === "Connect Workspace") {
        return switchAppIcon;
      } else {
        return AvatarLogo;
      }
    }
  }

  const navigateReporting = () => {
    navigate("/reporting");
  };
  const [imgPopup, setImgPopUp] = useState(false);
  const [imgSrc, setImgSrc] = useState(Profileavatar);
  const handleImgPop = () => {
    setImgPopUp(false);
  };
  const getImage = () => {
    setImgPopUp(true);
  };
  const refreshImgData = (imgValue) => {
    setImgSrc(imgValue);
    userDispatchContextVal(true);
  };

  useEffect(() => {
    setIsMount(true);
    getData();
    setLoading(true);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMount !== undefined) {
      getData();
      userDispatchContextVal(false);
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [userContextVal]);

  useEffect(() => {
    if (isMount !== undefined) {
      getData();
      setLoading(true);
    }
    //eslint-disable-next-line
  }, [userImage]);

  useEffect(() => {
    graphDatas();
    //eslint-disable-next-line
  }, [selected]);

  return (
    <Box className="Wrapper">
      <Box>
        <Container maxWidth="xxl">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="2px"
          >
            {loading ? (
              <Loader
                type={"spokes"}
                color={"#01537A"}
                height={40}
                width={40}
              />
            ) : null}
          </Box>
          <Box
            className="WrapperHeader"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="25px 0px 40px 0px"
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                style={{
                  position: "relative",
                  margin: "auto",
                  width: "70px",
                  height: "70px",
                }}
              >
                <Paper
                  style={{
                    background: "#fff",
                    width: "65px",
                    height: "65px",
                    borderRadius: "50px",
                    color: "#01537A",
                    fontSize: "54px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {companyImage !== null && companyImage !== undefined ? (
                    <Avatar
                      alt="Datafree"
                      src={API_URL + companyImage}
                      rounded="lg"
                      sx={{
                        width: "70px",
                        height: "70px",
                      }}
                    />
                  ) : (
                    <img
                      alt="Datafree"
                      src={Profileavatar}
                      rounded="lg"
                      width="45px"
                      height="45px"
                    />
                  )}
                </Paper>
                <IconButton
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    right: "5px",
                    background: "#01537A",
                    color: "#fff",
                    fontSize: "15px",
                    width: "20px",
                    height: "20px",
                    top: "49px",
                  }}
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={getImage}
                >
                  <CameraAltOutlinedIcon fontSize="14px" />
                </IconButton>
              </Box>
              <Box
                sx={{
                  marginLeft: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    color: "#01537A",
                    fontWeight: "700",
                    fontSize: {
                      xs: "14px",
                      sm: "22px",
                      md: "33px",
                      lg: "33px",
                    },
                    textTransform: "capitalize",
                  }}
                >
                  {companyData?.name.toLowerCase()}
                </Typography>
                <Typography
                  sx={{
                    color: "#5A5656",
                    fontWeight: "400",
                    fontSize: {
                      xs: "10px",
                      sm: "16px",
                      md: "20px",
                      lg: "20px",
                    },
                    marginTop: "-5px",
                  }}
                >
                  Admin Dashboard
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <BootstrapTooltip title="Since last refresh" placement="top">
                <RefreshIcon
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    color: "#01537A",
                    cursor: "pointer",
                  }}
                  sx={{
                    "&:focus": {
                      animation: "spin 2s linear infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(360deg)",
                        },
                        "100%": {
                          transform: "rotate(0deg)",
                        },
                      },
                    },
                  }}
                  onClick={refreshData}
                />
              </BootstrapTooltip>
              <Typography
                sx={{
                  marginLeft: "5px",
                  color: "#01537A",
                  fontWeight: "500",
                  fontSize: { xs: "9px", md: "12px", lg: "14px" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Last refresh :{" "}
                {counter === 0
                  ? "Just now"
                  : counter > 1
                  ? counter + " minutes ago"
                  : counter + " minute ago"}
              </Typography>
            </Box>
          </Box>
          <Box container="true" columnspacing={5}>
            <Box
              sx={{
                display: "grid",
                columnGap: 3,
                rowGap: { xs: "2rem", lg: "0" },
                gridTemplateColumns: { md: "auto", lg: "repeat(2, 1fr)" },
                padding: { xs: "15px", md: "0px" },
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  columnGap: {
                    xs: "2rem",
                    md: "3rem",
                    lg: "5rem",
                    xl: "5rem",
                  },
                  rowGap: { xs: "2rem", lg: "1rem" },
                  gridTemplateColumns: {
                    xs: "auto",
                    md: "repeat(4, 1fr)",
                    lg: "repeat(4, 1fr)",
                  },
                  gridTemplateRows: "repeat(1, 120px)",
                }}
              >
                <Card
                  sx={{ minWidth: { xs: 50, md: 132 } }}
                  style={{
                    backgroundColor: "rgba(1, 83, 122, 0.1)",
                    overflow: "inherit",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <Fab
                    onClick={() => addApplication()}
                    size="small"
                    color="primary"
                    aria-label="add"
                    style={{
                      background: "#01537A",
                      borderRadius: "50px",
                      position: "absolute",
                      top: "-20px",
                      right: "-20px",
                      color: "#fff",
                      fontSize: "34px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "35px",
                      height: "32px",
                      zIndex: 2,
                    }}
                  >
                    <img src={Add} alt="" height="28px"></img>
                  </Fab>
                  <CardContent
                    onClick={() => redirectPages(3)}
                    align="center"
                    style={{ padding: "10px" }}
                  >
                    <Typography
                      sx={{ fontSize: 52 }}
                      color="#01537A"
                      lineHeight="55px"
                    >
                      {allData ? allData?.applicationCount : 0}
                    </Typography>
                    <Typography style={{ color: "#01537A", fontSize: "20px" }}>
                      Applications
                    </Typography>
                    <Box
                      style={{
                        color: "#01537A",
                        fontSize: 13,
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <BootstrapTooltip
                        title="In the last 7 days"
                        placement="right"
                      >
                        <span style={{ display: "flex" }}>
                          {getChanges(allData?.appsChanges7days)}
                        </span>
                      </BootstrapTooltip>
                    </Box>
                  </CardContent>
                </Card>
                <Card
                  sx={{ minWidth: { xs: 50, md: 132 } }}
                  style={{
                    backgroundColor: "rgba(1, 83, 122, 0.1)",
                    overflow: "inherit",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <Fab
                    onClick={() => addUser()}
                    size="small"
                    color="primary"
                    aria-label="add"
                    style={{
                      background: "#01537A",
                      borderRadius: "50px",
                      position: "absolute",
                      top: "-20px",
                      right: "-20px",
                      color: "#fff",
                      fontSize: "34px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "35px",
                      height: "32px",
                      zIndex: 2,
                    }}
                  >
                    <img src={Add} alt="" height="28px"></img>
                  </Fab>
                  <CardContent
                    onClick={() => redirectPages(2)}
                    align="center"
                    style={{ padding: "10px" }}
                  >
                    <Typography
                      sx={{ fontSize: 52 }}
                      color="#01537A"
                      lineHeight="55px"
                    >
                      {allData ? allData?.userCount : 0}
                    </Typography>
                    <Typography style={{ color: "#01537A", fontSize: "20px" }}>
                      Users
                    </Typography>
                    <Box
                      style={{
                        color: "#01537A",
                        fontSize: 13,
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <BootstrapTooltip
                        title="In the last 7 days"
                        placement="right"
                      >
                        <span style={{ display: "flex" }}>
                          {getChanges(allData?.usersChange7days)}
                        </span>
                      </BootstrapTooltip>
                    </Box>
                  </CardContent>
                </Card>
                <Card
                  sx={{ minWidth: { xs: 50, md: 132 } }}
                  style={{
                    backgroundColor: "rgba(1, 83, 122, 0.1)",
                    overflow: "inherit",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    style={{
                      background: "#01537A",
                      borderRadius: "50px",
                      position: "absolute",
                      top: "-20px",
                      right: "-20px",
                      color: "#fff",
                      fontSize: "34px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "35px",
                      height: "32px",
                      zIndex: 2,
                    }}
                  >
                    <img
                      src={Add}
                      alt=""
                      height="28px"
                      onClick={() => setCompany("customer")}
                    ></img>
                  </Fab>
                  <CardContent
                    align="center"
                    style={{ padding: "10px" }}
                    onClick={() => redirectPages(1, "Customer")}
                  >
                    <Typography
                      sx={{ fontSize: 52 }}
                      color="#01537A"
                      lineHeight="55px"
                    >
                      {allData ? allData?.approvedCompaniesCount : 0}
                    </Typography>
                    <Typography style={{ color: "#01537A", fontSize: "20px" }}>
                      Customers
                    </Typography>
                    <Box
                      style={{
                        color: "#01537A",
                        fontSize: 13,
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <BootstrapTooltip
                        title="In the last 7 days"
                        placement="right"
                      >
                        <span style={{ display: "flex" }}>
                          {getChanges(allData?.companiesChange7days)}
                        </span>
                      </BootstrapTooltip>
                    </Box>
                  </CardContent>
                </Card>
                <Box
                  sx={{
                    display: "grid",
                    columnGap: { xs: "2rem", lg: "0" },
                    rowGap: 4,
                    gridTemplateColumns: {
                      xs: "repeat(2, 1fr)",
                      md: "repeat(1, 1fr)",
                      lg: "repeat(1, 1fr)",
                    },
                    gridTemplateRows: {
                      xs: "repeat(1, 44px)",
                      lg: "repeat(2, 44px)",
                    },
                  }}
                >
                  <Card
                    sx={{ minWidth: 141 }}
                    style={{
                      backgroundColor: "rgba(1, 83, 122, 0.1)",
                      overflow: "inherit",
                      position: "relative",
                      cursor: "pointer",
                      width: "160px",
                    }}
                  >
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="add"
                      style={{
                        background: "#01537A",
                        borderRadius: "50px",
                        position: "absolute",
                        top: "-20px",
                        right: "-20px",
                        color: "#fff",
                        fontSize: "34px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "35px",
                        height: "32px",
                        zIndex: 2,
                      }}
                    >
                      <img
                        src={Add}
                        alt=""
                        height="28px"
                        onClick={() => setCompany("channel")}
                      ></img>
                    </Fab>
                    <CardContent
                      align="center"
                      style={{ padding: "7px 12px" }}
                      onClick={() => redirectPages(1, "Channel")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          color: "#01537A",
                          fontSize: "20px",
                        }}
                      >
                        <Typography
                          component="span"
                          sx={{
                            fontWeight: "700",
                            color: "#01537A",
                            fontSize: "20px",
                            paddingRight: "5px",
                          }}
                        >
                          {allData ? allData?.channelCompanyCount : 0}
                        </Typography>{" "}
                        Channels
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{ minWidth: 141 }}
                    style={{
                      backgroundColor: "rgba(1, 83, 122, 0.1)",
                      overflow: "inherit",
                      position: "relative",
                      cursor: "pointer",
                      width: "160px",
                    }}
                  >
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="add"
                      style={{
                        background: "#01537A",
                        borderRadius: "50px",
                        position: "absolute",
                        top: "-20px",
                        right: "-20px",
                        color: "#fff",
                        fontSize: "34px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "35px",
                        height: "32px",
                        zIndex: 2,
                      }}
                    >
                      <img
                        src={Add}
                        alt=""
                        height="28px"
                        onClick={() => setCompany("licensee")}
                      ></img>
                    </Fab>
                    <CardContent
                      align="center"
                      style={{ padding: "7px 12px" }}
                      onClick={() => redirectPages(1, "Licensee")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          color: "#01537A",
                          fontSize: "20px",
                        }}
                      >
                        <Typography
                          component="span"
                          sx={{
                            fontWeight: "700",
                            color: "#01537A",
                            fontSize: "20px",
                            paddingRight: "5px",
                          }}
                        >
                          {allData ? allData?.licenseeCompanyCount : 0}
                        </Typography>{" "}
                        Licensees
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  columnGap: {
                    xs: "2rem",
                    md: "3rem",
                    lg: "3rem",
                    xl: "5rem",
                  },
                  rowGap: 2,
                  gridTemplateColumns: {
                    xs: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                  },
                  gridTemplateRows: "repeat(1, 120px)",
                }}
              >
                <Card
                  sx={{
                    minWidth: 132,
                    gridRow: "1",
                    gridColumn: { xs: "auto", lg: "3 / 4" },
                  }}
                  style={{
                    backgroundColor: "rgba(1, 83, 122, 0.1)",
                    overflow: "inherit",
                    position: "relative",
                  }}
                >
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="add"
                    style={{
                      background:
                        allData?.alertSentStatus > 0 ? "#FF5252" : "#757373",
                      borderRadius: "50px",
                      position: "absolute",
                      top: "-20px",
                      right: "-20px",
                      color: "#fff",
                      fontSize: "34px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "35px",
                      height: "32px",
                      zIndex: 2,
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          lineHeight: "10px",
                          fontWeight: "400",
                          color: "#fff",
                          fontSize: "14px",
                        }}
                      >
                        {allData?.alertSentStatus
                          ? allData?.alertSentStatus
                          : 0}
                      </Typography>
                      <Typography
                        style={{
                          lineHeight: "10px",
                          fontWeight: "300",
                          color: "#fff",
                          fontSize: "11px",
                          textTransform: "lowercase",
                        }}
                      >
                        new
                      </Typography>
                    </Box>
                  </Fab>
                  <CardContent align="center" style={{ padding: "10px" }}>
                    <Typography
                      sx={{ fontSize: 52 }}
                      color="#01537A"
                      lineHeight="55px"
                    >
                      {allData?.companiesExceedDataLimit
                        ? allData?.companiesExceedDataLimit
                        : 0}
                    </Typography>
                    <Typography style={{ color: "#01537A" }}>
                      Accounts
                    </Typography>
                    <Box
                      style={{ color: "#01537A", fontSize: 11 }}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <KeyboardArrowRightIcon
                        style={{ color: "#01537A", fontSize: 14 }}
                      />
                      80% Monthly Limit
                    </Box>
                  </CardContent>
                </Card>

                <Card
                  sx={{
                    minWidth: 132,
                    gridRow: "1",
                    gridColumn: { xs: "auto", lg: "4 / 5" },
                  }}
                  style={{
                    backgroundColor: "rgba(1, 83, 122, 0.1)",
                    overflow: "inherit",
                    position: "relative",
                  }}
                >
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="add"
                    style={{
                      background:
                        allData?.alertSentTodayApps > 0 ? "#FB8C00" : "#757373",
                      borderRadius: "50px",
                      position: "absolute",
                      top: "-20px",
                      right: "-20px",
                      color: "#fff",
                      fontSize: "34px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "35px",
                      height: "32px",
                      zIndex: 2,
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          lineHeight: "10px",
                          fontWeight: "400",
                          color: "#fff",
                          fontSize: "14px",
                        }}
                      >
                        {allData?.alertSentTodayApps
                          ? allData?.alertSentTodayApps
                          : 0}
                      </Typography>
                      <Typography
                        style={{
                          lineHeight: "10px",
                          fontWeight: "300",
                          color: "#fff",
                          fontSize: "11px",
                          textTransform: "lowercase",
                        }}
                      >
                        new
                      </Typography>
                    </Box>
                  </Fab>
                  <CardContent align="center" style={{ padding: "10px" }}>
                    <Typography
                      sx={{ fontSize: 52 }}
                      color="#01537A"
                      lineHeight="55px"
                    >
                      {allData?.appsExceedDataLimit
                        ? allData?.appsExceedDataLimit
                        : 0}
                    </Typography>
                    <Typography style={{ color: "#01537A" }}>Apps</Typography>
                    <Box
                      style={{ color: "#01537A", fontSize: 11 }}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <KeyboardArrowRightIcon
                        style={{ color: "#01537A", fontSize: 14 }}
                      />
                      80% Monthly Limit
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        style={{
          background: "#01537A",
          padding: "70px 40px",
          margin: "40px 0",
        }}
      >
        <Container>
          <Paper
            component="form"
            sx={{
              p: "0px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <SearchIcon
                style={{
                  color: "#01537A",
                  fontSize: "34px",
                }}
              />
            </IconButton>
            <InputBase
              width="100%"
              style={{ ml: 1, flex: 1, color: "#373737" }}
              placeholder="Search for apps, users or companies"
              onKeyUp={goToSearch}
            ></InputBase>
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={goToSearchs}
            >
              <TuneIcon
                style={{
                  color: "#0094CD",
                  fontSize: "34px",
                  transform: "rotate(90deg)",
                }}
              />
            </IconButton>
          </Paper>
          <Stack direction="row" spacing={1} sx={{ marginTop: "10px" }}>
            {cookies.searchArray && !localStorage.getItem("switchRole")
              ? cookies.searchArray
                  .filter(
                    (item) =>
                      parseInt(item.id) === allData?.userId &&
                      item.role === localStorage.getItem("role")
                  )
                  .map((items) =>
                    items.values.map((keys, index) => {
                      let keyval = keys.toLowerCase();
                      return (
                        <Chip
                          label={
                            keyval.charAt(0).toUpperCase() + keyval.slice(1)
                          }
                          key={index}
                          sx={{
                            color: "#000 !important",
                            width: "135px",
                            maxWidth: "16ch",
                            height: "26px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "14px",
                            boxShadow: "4px 4px 12px rgba(0, 0, 0, 0.15)",
                            backgroundColor: "#E6EEF2 !important",
                          }}
                          onClick={() => goToSearchPrevious(keys)}
                        />
                      );
                    })
                  )
              : null}
          </Stack>
        </Container>
      </Box>
      <Box>
        <Container maxWidth="xxl">
          <Box
            sx={{
              display: "grid",
              columnGap: { md: 3, lg: 10 },
              rowGap: 2,
              gridTemplateColumns: { xs: "auto", md: "repeat(3, 1fr)" },
              minHeight: "700px",
            }}
          >
            <Card
              style={{
                backgroundColor: "#fff",
                overflow: "inherit",
                position: "relative",
              }}
            >
              <CardContent>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paper
                      style={{
                        background: "#01537A",
                        width: "42px",
                        height: "42px",
                        borderRadius: "50px",
                        color: "#fff",
                        fontSize: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "3px solid #fff",
                      }}
                    >
                      <BusinessIcon fontSize="20px" />
                    </Paper>
                    <Typography
                      style={{
                        marginLeft: "15px",
                        fontWeight: "700",
                      }}
                      sx={{
                        fontSize: {
                          xs: "18px",
                          sm: "21px",
                          md: "16px",
                          lg: "21px",
                        },
                      }}
                    >
                      Latest Companies
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      marginLeft: "15px",
                      fontWeight: "600",
                      color: "#0094CD",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    sx={{
                      fontSize: { xs: "16px", md: "12px", lg: "16px" },
                    }}
                    onClick={() => redirectViewall(1)}
                  >
                    View All
                  </Box>
                </Box>
                <Box>
                  {latestPublisher && latestPublisher.length > 0 ? (
                    latestPublisher.map((pub, index) => (
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          bgcolor: "#F2F2F2",
                          borderRadius: "12px",
                          marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        key={index}
                        onMouseEnter={() => setIsCompanyHovered(index)}
                        onMouseLeave={() => setIsCompanyHovered(false)}
                        onClick={() =>
                          pub?.company?.companyId &&
                          companyDetailPage(
                            pub?.company?.companyId,
                            pub?.company?.companyType
                          )
                        }
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <Paper
                              style={{
                                background: "#fff",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                                color: "#01537A",
                                fontSize: "34px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {pub?.company?.logo !== null &&
                              pub?.company?.logo !== undefined ? (
                                <Avatar
                                  alt="Datafree"
                                  src={API_URL + pub?.company?.logo}
                                  rounded="lg"
                                  width="52"
                                  height="52"
                                />
                              ) : (
                                <BusinessIcon />
                              )}
                            </Paper>
                          </ListItemAvatar>
                          <Box
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "0px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color:
                                  isCompanyHovered === index
                                    ? "#0094CD"
                                    : "#23262F",
                                textDecoration:
                                  isCompanyHovered === index ? "underline" : "",
                              }}
                            >
                              {`${pub?.company?.name} - ${
                                pub?.company?.companyType === "CUSTOMER"
                                  ? "Customer"
                                  : pub?.company?.companyType.toLowerCase()
                              }`}
                            </Typography>
                            <Typography
                              style={{
                                color: "#23262F",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {`${pub?.parentCompany?.name} ${
                                pub?.parentCompany?.companyType === "CUSTOMER"
                                  ? "Customer"
                                  : pub?.parentCompany?.companyType === "BINU"
                                  ? "Admin"
                                  : pub?.parentCompany?.companyType.toLowerCase()
                              }`}
                            </Typography>
                            <Typography
                              style={{
                                color: "#B1AEAE",
                                fontSize: 12,
                                fontWeight: "400",
                              }}
                            >
                              {`${moment(pub.updatedDate).format(
                                "DD MMM YYYY, HH:mm,"
                              )} by ${
                                pub?.editorBy !== null
                                  ? pub?.editorBy
                                  : "Not Captured"
                              }`}
                            </Typography>
                          </Box>
                        </ListItem>
                      </List>
                    ))
                  ) : (
                    <Box sx={{ textAlign: "center" }}>No Data found</Box>
                  )}
                </Box>
              </CardContent>
            </Card>
            <Card
              style={{
                backgroundColor: "#fff",
                overflow: "inherit",
                position: "relative",
              }}
            >
              <CardContent>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paper
                      style={{
                        background: "#01537A",
                        width: "42px",
                        height: "42px",
                        borderRadius: "50px",
                        color: "#fff",
                        fontSize: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "3px solid #fff",
                      }}
                    >
                      <LayersIcon fontSize="20px" />
                    </Paper>
                    <Typography
                      style={{
                        marginLeft: "15px",
                        fontWeight: "700",
                      }}
                      sx={{
                        fontSize: {
                          xs: "18px",
                          sm: "21px",
                          md: "14px",
                          lg: "21px",
                        },
                      }}
                    >
                      Latest Applications
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      marginLeft: "15px",
                      fontWeight: "600",
                      color: "#0094CD",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    sx={{
                      fontSize: { xs: "16px", md: "12px", lg: "16px" },
                    }}
                    onClick={() => redirectViewall(3)}
                  >
                    View All
                  </Box>
                </Box>
                <Box>
                  {latestApps && latestApps.length > 0 ? (
                    latestApps.map((app, index) => (
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          bgcolor: "#F2F2F2",
                          borderRadius: "12px",
                          marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        key={index}
                        onMouseEnter={() => setIsAppHovered(index)}
                        onMouseLeave={() => setIsAppHovered(false)}
                        onClick={() =>
                          app?.apps?.applicationId &&
                          applicationDetailPage(app?.apps)
                        }
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <Paper
                              style={{
                                background: "#fff",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50px",
                                color: "#01537A",
                                fontSize: "34px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <Avatar
                                alt="Datafree"
                                src={getAppIcon(app)}
                                rounded="lg"
                                width="52"
                                height="52"
                              />
                            </Paper>
                          </ListItemAvatar>
                          <Box
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "0px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color:
                                  isAppHovered === index
                                    ? "#0094CD"
                                    : "#23262F",
                                textDecoration:
                                  isAppHovered === index ? "underline" : "",
                              }}
                            >
                              {app?.apps.length !== 0
                                ? app.apps?.name.toLowerCase() + " - "
                                : ""}{" "}
                              {getprodType(app)}
                            </Typography>
                            <Typography
                              style={{
                                color: "#23262F",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {app?.apps.length !== 0
                                ? `${app?.apps?.publisherId.name.toLowerCase()} ${
                                    app?.apps?.publisherId.companyType ===
                                    "CUSTOMER"
                                      ? "Customer"
                                      : app?.apps?.publisherId.companyType.toLowerCase()
                                  }`
                                : ""}
                            </Typography>
                            <Typography
                              style={{
                                color: "#B1AEAE",
                                fontSize: 12,
                                fontWeight: "400",
                              }}
                            >
                              {`${moment(app.updatedDate).format(
                                "DD MMM YYYY, HH:mm,"
                              )} by ${
                                app?.editorBy !== null
                                  ? app?.editorBy
                                  : "Not Captured"
                              }`}
                            </Typography>
                          </Box>
                        </ListItem>
                      </List>
                    ))
                  ) : (
                    <Box sx={{ color: "#000", padding: "7px" }}>
                      No changes to see here.
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
            <Card
              style={{
                backgroundColor: "#fff",
                overflow: "inherit",
                position: "relative",
              }}
            >
              <CardContent>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Paper
                      style={{
                        background: "#01537A",
                        width: "42px",
                        height: "42px",
                        borderRadius: "50px",
                        color: "#fff",
                        fontSize: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "3px solid #fff",
                      }}
                    >
                      <PeopleAltIcon fontSize="20px" />
                    </Paper>
                    <Typography
                      style={{
                        marginLeft: "15px",
                        fontWeight: "700",
                      }}
                      sx={{
                        fontSize: {
                          xs: "18px",
                          sm: "21px",
                          md: "14px",
                          lg: "21px",
                        },
                      }}
                    >
                      Recently Active
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      marginLeft: "15px",
                      fontWeight: "600",
                      color: "#0094CD",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    sx={{
                      fontSize: { xs: "16px", md: "12px", lg: "16px" },
                    }}
                    onClick={() => redirectViewall(2)}
                  >
                    View All
                  </Box>
                </Box>
                <Box>
                  {activeUsers ? (
                    activeUsers.map((user, index) => (
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          bgcolor: "#F2F2F2",
                          borderRadius: "12px",
                          marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        key={index}
                        onMouseEnter={() => setIsActiveHovered(index)}
                        onMouseLeave={() => setIsActiveHovered(false)}
                        onClick={() =>
                          user?.user.userId &&
                          userDetailPage(
                            user?.user.userId,
                            user?.user.userRole,
                            user?.user?.companyId
                          )
                        }
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <Paper
                              style={{
                                background: "#fff",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50px",
                                color: "#01537A",
                                fontSize: "34px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <Avatar
                                alt=""
                                src={
                                  user?.user.userId ===
                                    localStorage.getItem("userid") &&
                                  userImage !== ""
                                    ? userImage
                                    : user?.user?.userImage !== null
                                    ? API_URL + user?.user?.userImage
                                    : avatar
                                }
                              />
                            </Paper>
                          </ListItemAvatar>
                          <Box
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "0px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color:
                                  isActiveHovered === index
                                    ? "#0094CD"
                                    : "#23262F",
                                textDecoration:
                                  isActiveHovered === index ? "underline" : "",
                              }}
                            >
                              {user?.user.name}
                            </Typography>
                            <Typography
                              style={{
                                color: "#23262F",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {user?.user.companyName}
                            </Typography>
                            <Typography
                              style={{
                                color: "#B1AEAE",
                                fontSize: 12,
                                fontWeight: "400",
                              }}
                            >
                              {moment(user.login_time).format(
                                "DD MMM YYYY, HH:mm"
                              )}
                            </Typography>
                          </Box>
                        </ListItem>
                      </List>
                    ))
                  ) : (
                    <Box sx={{ color: "#000", padding: "7px" }}>
                      No active users yet.
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
      <Box
        style={{
          background: "rgba(1, 83, 122, 0.1)",
          padding: "70px 0px",
          margin: "40px 0",
        }}
      >
        <Container maxWidth="xxl">
          <Card
            sx={{ minWidth: { xs: 50, md: 140 } }}
            style={{
              backgroundColor: "#fff",
              overflow: "inherit",
              position: "relative",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: {
                    xs: "flex-start",
                    md: "center",
                    lg: "center",
                  },
                  mb: { xs: "15px", md: "0", lg: "15px" },
                  flexDirection: { xs: "column", md: "row", lg: "row" },
                }}
              >
                <Box>
                  <Typography
                    style={{
                      fontSize: "20px",
                      textTransform: "uppercase",
                      fontWeight: "500",
                      color: "#373737",
                    }}
                  >
                    All Data usage over time
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: { xs: "15px", md: "0", lg: "0" },
                  }}
                >
                  <FormControl
                    sx={{
                      mr: 1,
                      maxWidth: 160,
                      mt: { xs: "10px", md: "0", lg: "0" },
                    }}
                    size="small"
                    style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#01537A",
                      borderColor: "#01537A",
                    }}
                  >
                    <Select
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#01537A",
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          htmlColor="#01537A"
                          sx={{
                            position: "absolute",
                            right: "5px",
                            cursor: "pointer",
                            color: "#1537A",
                          }}
                          onClick={handleOpen}
                        />
                      )}
                      open={opens}
                      onOpen={handleOpen}
                      onClose={handleCloses}
                      MenuProps={MenuProps}
                      defaultValue={selected}
                      key={selected}
                      onChange={handleGraph}
                      input={
                        <OutlinedInput
                          sx={{
                            borderColor: "#01537A !important",
                            color: "#01537A",
                            "& fieldset": {
                              borderColor: "#01537A !important",
                            },
                          }}
                        />
                      }
                    >
                      <MenuItem value="7">Last 7 Days</MenuItem>
                      <MenuItem value="14">Last 14 Days</MenuItem>
                      <MenuItem value="30">Last 30 Days</MenuItem>
                      <MenuItem value="60">Last 60 Days</MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    onClick={navigateReporting}
                    variant="contained"
                    style={{
                      background: "#01537A",
                      fontWeight: "400",
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                    sx={{
                      mt: { xs: "10px", md: "0", lg: "0" },
                    }}
                  >
                    Go to Reporting
                  </Button>
                </Box>
              </Box>
              <Graph
                resData={graphData}
                selected={selected}
                loading={load}
                yAxis={"Data usage (MB)"}
                xAxis={"Day of month"}
                type={"dashboard"}
              />
            </CardContent>
          </Card>
        </Container>
      </Box>
      {imgPopup && (
        <LogoPopUp
          open={imgPopup}
          handleClose={handleImgPop}
          imgs={imgSrc}
          defaultImg={Profileavatar}
          compId={companyId ? companyId : ""}
          refreshData={refreshImgData}
        />
      )}
      <SnackbarWrapper
        open={open}
        onClose={handleClose}
        message={notifyMessage}
      />
    </Box>
  );
}
