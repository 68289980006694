import React from "react";
import { MenuItem, Checkbox, ListItemText } from "@mui/material";

export default function CustomCheckBox({
  selectedValue,
  menuList,
  handleChange,
  setSelectedValue,
  allBox,
}) {
  return (
    <>
      {allBox === "no" ? (
        <div>
          {menuList &&
            menuList.map((name) => (
              <MenuItem key={name}>
                <Checkbox
                  checked={selectedValue.includes(name)}
                  onChange={() => handleChange(name)}
                  sx={{
                    padding: "0px",
                    paddingRight: "10px",

                    "&.Mui-checked": {
                      color: "#01537A !important",
                    },
                  }}
                />
                <ListItemText
                  sx={{
                    color: "#01537A",
                  }}
                  primary={name}
                />
              </MenuItem>
            ))}
        </div>
      ) : (
        <div>
          <MenuItem>
            {menuList && selectedValue && (
              <Checkbox
                checked={
                  selectedValue.length === menuList.length ||
                  (selectedValue.length > 0 &&
                    selectedValue.length < menuList.length)
                }
                indeterminate={selectedValue.length > 0}
                onChange={() => {
                  if (selectedValue.length === menuList.length) {
                    setSelectedValue([]);
                  } else {
                    setSelectedValue([...menuList]);
                  }
                }}
                sx={{
                  padding: "0px",
                  paddingRight: "10px",
                  "&.Mui-checked": {
                    color: "#01537A !important",
                  },
                }}
              />
            )}
           
            <ListItemText
              sx={{
                color: "#01537A",
              }}
              primary="All"
            />
          
          </MenuItem>
          {menuList &&
            menuList.slice(0, 15).map((name) => (
              <MenuItem key={name}>
                <Checkbox
                  checked={selectedValue && selectedValue.includes(name)}
                  onChange={() => handleChange(name)}
                  sx={{
                    padding: "0px",
                    paddingRight: "10px",

                    "&.Mui-checked": {
                      color: "#01537A !important",
                    },
                  }}
                />
                <ListItemText
                  sx={{
                    color: "#01537A",
                  }}
                  primary={name}
                />
              </MenuItem>
            ))}
        </div>
      )}
    </>
  );
}
