import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PaginationItem from "@mui/material/PaginationItem";

export default function PaginationRounded(props) {
  let pageCount = Math.ceil(props.Count / props.size);
  return (
    <Stack spacing={2}>
      <Pagination
        sx={{
          "& .Mui-selected": {
            backgroundColor: "#FFF !important",
            color: "#0094CD",
            borderColor: "#0094CD",
          },
          "& .Mui-disabled": {
            backgroundColor: "#919EAB !important",
            color: "#C4CDD5",
            borderColor: "#919EAB",
          },
        }}
        count={pageCount}
        page={props.page}
        showFirstButton
        showLastButton
        renderItem={(item) => (
          <PaginationItem
            components={{
              first: KeyboardDoubleArrowLeftIcon,
              last: KeyboardDoubleArrowRightIcon,
            }}
            {...item}
          />
        )}
        variant="outlined"
        shape="rounded"
        onChange={props.changeHandle}
        siblingCount={1}
      />
    </Stack>
  );
}
