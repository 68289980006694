import * as React from "react";
import {
  ListItemText,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      boxShadow: "none",
      border: "1px solid #01537A",
      bgcolor: "#fff",
      maxWidth: "150px",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderTopRightRadius: "0",
      borderTopLeftRadius: "0",
      "& .Mui-focused ": {
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
      },
      "& .MuiOutlinedInput-root": {
        fontWeight: "700",
        color: "#01537A",
      },
      "& .MuiMenuItem-root": {
        color: "#01537A",
        borderBottom: "1px solid #01537A",
        fontSize: "16px",
        backgroundColor: "#fff",
        padding: "5px 0px",
      },
      "& .MuiMenuItem-root: last-child": {
        color: "#01537A",
        borderBottom: "none",
        fontSize: "16px",
        backgroundColor: "#fff",
        padding: "5px 0px",
      },
      "& .Mui-selected": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
      },
      "& .Mui-selected:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
      },
      "& .MuiBox-root:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        borderRadius: "4px",
      },
      "& .MuiBox-root": {
        padding: "5px 10px",
        width: "100%",
      },
      "& .MuiList-root": {
        padding: "0 15px",
      },
      "& .MuiInputBase-input": {
        padding: "6px 10px 7px",
      },
    },
  },
};

export default function SelectCheckmarks(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderValue = (selected) => {
    if (!selected) {
      return (
        <Typography
          sx={{
            color: "#01537A",
            fontSize: "17px",
          }}
        >
          Sort By
        </Typography>
      );
    }

    return selected;
  };
  return (
    <div>
      <Select
        onChange={props.handleChange}
        displayEmpty
        renderValue={renderValue}
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={() => (
          <KeyboardArrowDownIcon
            htmlColor="#01537A"
            sx={{
              position: "absolute",

              right: "25px",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          />
        )}
        input={
          <OutlinedInput
            sx={{
              borderColor: "#01537A !important",
              color: "#01537A",
              width: "170px",
              height: "34px",
              fontSize: "16px",
              borderRadius: "6px",
              "& fieldset": { borderColor: "#01537A !important" },
            }}
          />
        }
        MenuProps={MenuProps}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={props.value ? props.value : ""}
      >
        <MenuItem value="">
          <ListItemText primary="Sort By" />
        </MenuItem>

        {props.menuValues.map((name) => (
          <MenuItem key={name} value={name}>
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
