import axios from "axios";
import { API_URL, authRequests, endPoints } from "./constants";
import axiosInstance from "./interceptor.js";

class Service {
  //login
  async Login(data) {
    const response = await axios.post(`${API_URL}${authRequests.login}`, data);
    return response;
  }

  async logout() {
    const response = await axiosInstance.post(authRequests.logout);
    return response;
  }

  async appInfo() {
    const response = await axios.get(`${API_URL}/app_info`, {
      withCredentials: false,
    });
    return response;
  }

  //Get admin dashboard
  async AdminIndex(data) {
    const response = await axiosInstance.post(endPoints.adminIndex, data);
    return response;
  }

  //Get Search Result
  async Search(data, page, size) {
    const url = `${endPoints.searchList}?page=${page}&limit=${size}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // get licensee user details
  async LicenseeUsers(data, page, size) {
    const url = `${endPoints.licenseeusers}?page=${page}&limit=${size}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // get licensee channel details
  async LicenseeChannels(data, page, size) {
    const url = `${endPoints.licenseechannels}?page=${page}&limit=${size}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // get licensee service details
  async LicenseeServices(data, page, size) {
    const url = `${endPoints.licenseeservices}?page=${page}&limit=${size}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // get licensee gateway domains details
  async gateWayDomain(data, page, size) {
    const url = `${endPoints.licenseeGatewayDomain}?page=${page}&limit=${size}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // get publihser details
  async getPublishers(data, page, size) {
    const url = `${endPoints.publisherList}?page=${page}&limit=${size}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // update publihser details
  async updatePublisher(data, publisherId) {
    const url = `${endPoints.updatePublisher}${publisherId}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //Get data Usage Result
  async dataUsageGraph(data) {
    const response = await axiosInstance.post(endPoints.dataUsageGraph, data);
    return response;
  }

  // get licensee gateway domains details
  async getApplications(data, page, size) {
    const url = `${endPoints.applications}?page=${page}&limit=${size}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // update publisher application details
  async updateApplication(data, applicationId) {
    const url = `${endPoints.updateApplication}${applicationId}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // delete schedule report
  async deleteReport(reportId) {
    const url = `${endPoints.deleteReport}${reportId}`;
    const response = await axiosInstance.get(url);
    return response;
  }

  //bulk updation of application status and revenuType
  async bulkUpdation(data) {
    const response = await axiosInstance.post(endPoints.bulkUpdation, data);
    return response;
  }

  //Get Graph Result
  async getGraph(data) {
    const response = await axiosInstance.post(endPoints.graphView, data);
    return response;
  }

  //Get top apps
  async getTopApps(data) {
    const response = await axiosInstance.post(endPoints.topApps, data);
    return response;
  }

  // get licensee account settingf details
  async LicenseeAccountSettings(data) {
    const response = await axiosInstance.post(endPoints.accountSettings, data);
    return response;
  }

  //get Company List
  async getCompanies(data) {
    const response = await axiosInstance.post(endPoints.getCompanyList, data);
    return response;
  }

  //get Company Change log
  async getChangeLog(data, page, size) {
    const url = `${endPoints.getChangeLog}?page=${page}&limit=${size}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //check company name
  async checkCompanyName(data) {
    const response = await axiosInstance.post(endPoints.checkCompanyName, data);
    return response;
  }

  //check application name
  async checkApplicationName(data) {
    const response = await axiosInstance.post(
      endPoints.checkApplicationName,
      data
    );
    return response;
  }
  //check reach app
  async checkReachApp(data) {
    const response = await axiosInstance.post(endPoints.checkReachApp, data);
    return response;
  }

  //audit Log
  async auditLog(data) {
    const response = await axiosInstance.post(endPoints.auditLog, data);
    return response;
  }

  //get Company Change log
  async updateCompanyDetails(data, companyId) {
    const url = `${endPoints.updateCompanyDetails}${companyId}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // get serviceTypes
  async serviceTypesList() {
    const response = await axiosInstance.get(endPoints.getServiceTypes);
    return response;
  }

  async supportedProducts(data) {
    const response = await axiosInstance.post(
      endPoints.getSupportedProducts,
      data
    );
    return response;
  }

  async AddLicenseeService(data) {
    const response = await axiosInstance.post(
      endPoints.addLicenseeService,
      data
    );
    return response;
  }

  //update user details
  async updateUser(data, userId) {
    const url = `${endPoints.updateUser}${userId}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //update licensee service details
  async updateLicenseeService(data, licenseeServiceId) {
    const url = `${endPoints.updateLicenseeService}${licenseeServiceId}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //update licensee channel details
  async updateChannel(data, channelId) {
    const url = `${endPoints.updateChannel}${channelId}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //update licensee channel details
  async updateGateway(data) {
    const response = await axiosInstance.post(endPoints.updateGateway, data);
    return response;
  }

  //update logo
  async uploadLogo(data) {
    const response = await axiosInstance.post(endPoints.uploadLogo, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }

  //remove logo
  async removeLogo(data) {
    const response = await axiosInstance.post(endPoints.removeLogo, data);
    return response;
  }

  // reset password request
  async resetPassRequest(data) {
    const response = await axiosInstance.post(
      endPoints.resetPasswordRequest,
      data
    );
    return response;
  }

  // reset password
  async resetPassword(data) {
    const response = await axiosInstance.post(authRequests.resetPassword, data);
    return response;
  }

  //Get Search Result
  async userBulkUpdation(data, companyId) {
    // const url = `${endPoints.userBulkUpdation}?companyId=${companyId}`;
    const response = await axiosInstance.post(endPoints.userBulkUpdation, data);
    return response;
  }

  // channel and publisher tab status bulk updation
  async CompanyStatusUpdation(data) {
    const response = await axiosInstance.post(
      endPoints.companyStatusUpdation,
      data
    );
    return response;
  }
  //list licensee companies
  async licenseeList() {
    const response = await axiosInstance.get(endPoints.licenseeCompanyList);
    return response;
  }

  //list channel companies
  async channelList() {
    const response = await axiosInstance.post(endPoints.channelCompanyList);
    return response;
  }

  //list channel by licensee ID
  async channelListById(data) {
    const response = await axiosInstance.post(endPoints.channelListById, data);
    return response;
  }

  //company search in add user
  async companySearch(data, page) {
    const url = `${endPoints.companySearch}?page=${page}&limit=3`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //breadcrumb llist
  async breadCrumbList(data) {
    const url = `${endPoints.breadCrumbList}/${data}`;
    const response = await axiosInstance.get(url, data);
    return response;
  }

  // add users in admin
  async createUser(data) {
    const response = await axiosInstance.post(endPoints.createUser, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }

  // get user details in admin
  async userDetails(data) {
    const response = await axiosInstance.post(endPoints.userDetails, data);
    return response;
  }

  // get user details in admin
  async addNewRole(data) {
    // const url = `${endPoints.addNewRole}${userid}`;
    const response = await axiosInstance.post(endPoints.addNewRole, data);
    return response;
  }

  // create company
  async createCompany(data) {
    const response = await axiosInstance.post(endPoints.createCompany, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }

  //check licensee code
  async checkLicenseeShortCode(data) {
    const response = await axiosInstance.post(
      endPoints.checkLicenseeShortCode,
      data
    );
    return response;
  }

  //bulk role status change in user Details
  async roleStatusChange(Id, data) {
    const url = `${endPoints.roleStatusChange}/${Id}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //get schedule report
  async getScheduleReport(reportId) {
    const url = `${endPoints.getScheduleReport}/${reportId}`;
    const response = await axiosInstance.post(url);
    return response;
  }

  //owning company search in add user
  async owningCompanySearch(data, page) {
    const url = `${endPoints.owningCompanySearch}?page=${page}&limit=3`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //update user image
  async uploadUserImage(data) {
    const response = await axiosInstance.post(endPoints.uploadUserImage, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }

  //remove user image
  async removeUserImage(data) {
    const response = await axiosInstance.post(endPoints.removeUserImage, data);
    return response;
  }

  //get multiple roles
  async userRoles(data) {
    const response = await axiosInstance.post(endPoints.userRoles, data);
    return response;
  }

  //swtich dashboards based on the roles
  async swtichDasboard(data) {
    const response = await axiosInstance.post(endPoints.swtichDasboard, data);
    return response;
  }

  async getAccessToken(data) {
    const response = await axios.post(
      `${API_URL}${endPoints.getAccessToken}`,
      data,
      { withCredentials: false }
    );
    return response;
  }

  //get user details
  async getUserCompany() {
    const response = await axiosInstance.get(endPoints.getUserCompany);
    return response;
  }

  //get header data
  async getHeaderData(data) {
    const response = await axiosInstance.post(endPoints.getHeaderData, data);
    return response;
  }

  // create application
  async createApplication(data) {
    const response = await axiosInstance.post(
      endPoints.createApplication,
      data
    );
    return response;
  }
  // reach tab details
  async reachTabDetails(data) {
    const response = await axiosInstance.post(endPoints.reachTabDetails, data);
    return response;
  }

  // get reach and wrap security tab details
  async getSecurityTabDetails(data) {
    const response = await axiosInstance.post(
      endPoints.getSecurityTabDetails,
      data
    );
    return response;
  }
  // get wrap apkConfig tab details
  async getWrapApkConfig(data) {
    const response = await axiosInstance.post(endPoints.getWrapApkConfig, data);
    return response;
  }

  // get wrap client-version list
  async clientVersionsList(data) {
    const response = await axiosInstance.post(
      endPoints.clientVersionsList,
      data
    );
    return response;
  }
  // get prodAppList
  async prodAppList(data) {
    const response = await axiosInstance.post(endPoints.prodAppList, data);
    return response;
  }
  // get publisher company list
  async publisherCompany(data) {
    const response = await axiosInstance.post(endPoints.publisherCompany, data);
    return response;
  }
  // create report
  async createReport(data) {
    const response = await axiosInstance.post(endPoints.createReport, data);
    return response;
  }

  // update schedule report
  async updateScheduleReport(data) {
    const response = await axiosInstance.post(
      endPoints.updateScheduleReport,
      data
    );
    return response;
  }

  async getReportDetails(data, page, size) {
    const url = `${endPoints.getReportDetails}?page=${page}&limit=${size}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // get new Report
  async getNewReport(data) {
    const response = await axiosInstance.post(endPoints.getNewReport, data);
    return response;
  }
  // get new Graph Report
  async getNewGraphReport(data) {
    const response = await axiosInstance.post(endPoints.getNewReport, data);
    return response;
  }

  // get monthly Report
  async getMonthlySummaryReport(data) {
    const response = await axiosInstance.post(
      endPoints.getMonthlySummaryReport,
      data
    );
    return response;
  }

  // get build-types list
  async buildTypesList(data) {
    const response = await axiosInstance.post(endPoints.buildTypesList, data);
    return response;
  }

  // create build apk
  async createBuildApk(data) {
    const response = await axiosInstance.post(endPoints.createBuildApk, data);
    return response;
  }

  // get build apk details
  async getBuildApk(data) {
    const response = await axiosInstance.post(endPoints.getBuildApk, data);
    return response;
  }

  // get wrap lookFeel tab details
  async getWrapLookFeel(data) {
    const response = await axiosInstance.post(endPoints.getWrapLookFeel, data);
    return response;
  }

  //update wrap lookFeel tab details
  async updateWrapLookFeel(type, Id, data) {
    const url = `${endPoints.updateWrapLookFeel}/${type}/${Id}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //update wrap ApkConfig tab details
  async updateWrapApkConfig(type, Id, data) {
    const url = `${endPoints.updateWrapApkConfig}/${type}/${Id}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //update wrap SecurityRules tab details
  async updateWrapSecurityRules(type, Id, data) {
    const url = `${endPoints.updateWrapSecurityRules}/${type}/${Id}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  // get wrap entryPoint tab details
  async getWrapEntryPoint(data) {
    const response = await axiosInstance.post(
      endPoints.getWrapEntryPoint,
      data
    );
    return response;
  }

  // check prod app exist
  async prodAppExist(data) {
    const response = await axiosInstance.post(endPoints.prodAppExist, data);
    return response;
  }

  //get application details
  async getApplicationDetails(data) {
    // const url = `${endPoints.getApplicationDetails}/${id}`;
    const response = await axiosInstance.post(
      endPoints.getApplicationDetails,
      data
    );
    return response;
  }

  //get Reach Application Change log
  async getReachChangeLog(data) {
    const url = `${endPoints.getReachChangeLog}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //change application name

  async changeAppDetails(id, data) {
    const url = `${endPoints.changeAppDetails}/${id}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //delete version Reach
  async deleteApplication(data) {
    const response = await axiosInstance.post(
      endPoints.deleteApplication,
      data
    );
    return response;
  }

  //delete version wrap
  async deleteAppVersion(data) {
    const response = await axiosInstance.post(endPoints.deleteAppVersion, data);
    return response;
  }

  //publish app
  async publishApp(data) {
    const response = await axiosInstance.post(endPoints.publishApp, data);
    return response;
  }

  //get build history wrap
  async getBuildHistory(data) {
    const response = await axiosInstance.post(endPoints.getBuildHistory, data);
    return response;
  }

  //get App Status
  async getAppStatus(data) {
    const response = await axiosInstance.post(endPoints.getAppStatus, data);
    return response;
  }

  //update entry point deleteAppVersion

  async updateEntryPoint(appId, data) {
    const url = `${endPoints.updateEntryPoint}/${appId}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  //upload Reach Icon
  async uploadReachIcon(data) {
    const response = await axiosInstance.post(endPoints.uploadReachIcon, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  //upload Splash Image
  async uploadSplashImage(data) {
    const response = await axiosInstance.post(
      endPoints.uploadSplashImage,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  }

  //remove Reach image
  async removeReachIcon(data) {
    const response = await axiosInstance.post(endPoints.removeReachIcon, data);
    return response;
  }
  //remove Splash image
  async removeSplashImg(data) {
    const response = await axiosInstance.post(endPoints.removeSplashImg, data);
    return response;
  }

  //get Gateway Domain for adding applications
  async getGatewayDomain(data) {
    const response = await axiosInstance.post(endPoints.getGatewayDomain, data);
    return response;
  }

  async licenseeServiceBulkUpdate(data) {
    const response = await axiosInstance.post(
      endPoints.licenseeServiceBulkUpdate,
      data
    );
    return response;
  }

  //get Gateway Domain for adding applications
  async gatewayDomainExists(data) {
    const response = await axiosInstance.post(
      endPoints.gatewayDomainExists,
      data
    );
    return response;
  }

  // get activity logs
  async ActivityLog(data, page, size) {
    const url = `${endPoints.activityLog}?page=${page}&limit=${size}`;
    const response = await axiosInstance.post(url, data);
    return response;
  }

  async cloneApplication(data) {
    const response = await axiosInstance.post(endPoints.cloneApplication, data);
    return response;
  }
  //  Validate config XML
  async validateXML(data) {
    const response = await axiosInstance.post(endPoints.validateXML, data);
    return response;
  }
  async healthCheck(data) {
    const response = await axiosInstance.post(endPoints.healthCheck, data);
    return response;
  }
}

export default new Service();
