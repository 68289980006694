import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { WarningMessages } from "../config/messages";

export default function Tooltips({ children, type }) {
  const [position, setPosition] = useState("top");

  const NAtTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#1A2942E5",
      color: "#FFFFFF",
      borderRadius: "4px",
    },
    "& .MuiTooltip-arrow": {
      color: "#1A2942E5",
      marginLeft: "0px",
      fontSize: "25px",

      top:
        type === "startUrl" || type === "wrapStartUrl"
          ? "59px !important"
          : type === "mimeTypes"
          ? "92px !important"
          : type === "natGateway"
          ? "125px !important"
          : "",

      "::before": {
        width: position === "top" && "50px",
        height: position === "top" && "50px",
        backgroundColor: "#1A2942E5",

        clipPath:
          position === "top"
            ? "polygon(60% 20%, 10% 60%, 80% 80%)"
            : "polygon(0% 0%, 72% 21%, 15% 82%)",
      },
    },
  }));
  const [showTooltip, setShowTooltip] = useState(true);

  const handleMouseEnter = () => {
    if (!showTooltip) {
      setShowTooltip(true);
    }
  };

  const getText = (type) => {
    const warningMap = {
      startUrl: WarningMessages?.startUrl,
      natGateway: WarningMessages?.natGateway,
      mimeTypes: WarningMessages?.mimeTypes,
      buildHistory: WarningMessages?.buildHistory,
      buildApk: WarningMessages?.buildApk,
      xmlAssistant: WarningMessages?.xmlAssistant,
      subDomain: WarningMessages?.subDomain,
      wrapStartUrl: WarningMessages?.wrapStartUrl,
      dataFreeUsage: WarningMessages?.dataFreeUsage,
      totalDataUsage: WarningMessages?.totalDataUsage,
      avgUserData: WarningMessages?.avgUserData,
      totalUserDataUsage: WarningMessages?.totalUserDataUsage,
      dailyActiveUsers: WarningMessages?.dailyActiveUsers,
      weeklyActiveUsers: WarningMessages?.weeklyActiveUsers,
      monthlyActiveUsers: WarningMessages?.monthlyActiveUsers,
      avgFocusTime: WarningMessages?.avgFocusTime,
      maxFocusTime: WarningMessages?.maxFocusTime,
      minFocusTime: WarningMessages?.minFocusTime,
      avgNavigation: WarningMessages?.avgNavigation,
      totalNavigation: WarningMessages?.totalNavigation,
      monthlyDataUsage: WarningMessages?.monthlyDataUsage,
      channelCustId: WarningMessages?.channelCustId,
      monthlyAccAlert: WarningMessages?.monthlyAccAlert,
      usageAlertEmail: WarningMessages?.usageAlertEmail,
      totalDataFreeDownload: WarningMessages?.totalDataFreeDownload,
      totalDownload: WarningMessages?.totalDownload,
      totalDatafreeUpload: WarningMessages?.totalDatafreeUpload,
      totalUpload: WarningMessages?.totalUpload,
      usagePerHr: WarningMessages?.usagePerHr,
      dow: WarningMessages?.dow,
      healthCheck: WarningMessages?.healthCheck,
      ddirectService: WarningMessages?.ddirectService,
      ddirectEndPoint: WarningMessages?.ddirectEndPoint,
    };

    return warningMap[type] || null;
  };

  useEffect(() => {
    if (
      type === "startUrl" ||
      type === "natGateway" ||
      type === "mimeTypes" ||
      type === "buildHistory" ||
      type === "buildApk" ||
      type === "xmlAssistant" ||
      type === "subDomain" ||
      type === "wrapStartUrl" ||
      type === "channelCustId" ||
      type === "monthlyAccAlert" ||
      type === "usageAlertEmail"
    ) {
      setPosition("top");
    } else {
      setPosition("bottom-start");
    }
    //eslint-disable-next-line
  }, [type]);

  return (
    <NAtTooltip
      placement={position}
      title={
        showTooltip && (
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Typography
              sx={{
                textAlign: "justify",
                textJustify: "inter-word",
                wordSpacing: type === "natGateway" ? "3px" : "normal",
                letterSpacing: "normal",
                hyphens: "auto",
                margin: "0 auto",
                fontSize: "11px",
                padding: "10px",
              }}
            >
              {getText(type)}
            </Typography>
          </Box>
        )
      }
      arrow
      onMouseEnter={handleMouseEnter}
    >
      {children}
    </NAtTooltip>
  );
}
