import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Box,
  Typography,
  Paper,
  Divider,
  FormHelperText,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import fileUpload from "../assets/img/fileUpload.svg";
import Service from "../api/services";
import { useDropzone } from "react-dropzone";
import Loader from "../components/loader";
import { useUser } from "../context/userContext";
import useToast from "../hooks/useToast";

export default function LogoPopUp({
  open,
  handleClose,
  imgs,
  defaultImg,
  compId,
  refreshData,
  uploadType,
}) {
  const [imgName, setImgName] = useState(imgs);
  const [imgError, setImgError] = useState({
    image: false,
    imgMsg: "",
  });

  const { showSuccess, showError } = useToast();

  const { updateUserImage } = useUser();

  const [load, setLoad] = useState(false);
  const [removeLoad, setRemoveLoad] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [imgData, setImgData] = useState();

  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  const MAX_FILE_SIZE = 1024; // 1MB
  const onDrop = useCallback((acceptedFiles) => {
    const newData = { ...imgData };
    let img = new Image();
    let file = acceptedFiles[0];
    img.src = URL.createObjectURL(file);

    if (!allowedExtensions.exec(file.name)) {
      setImgName(defaultImg);
      setImgError({
        ...imgError,
        image: true,
        imgMsg: "Selected file is not supported",
      });
    } else {
      img.onload = () => {
        if (img.width < 120) {
          setImgError({
            ...imgError,
            image: true,
            imgMsg: "Minimum width must be 120px",
          });
        } else if (img.height < 120) {
          setImgError({
            ...imgError,
            image: true,
            imgMsg: "Minimum height must be 120px",
          });
        } else if (file.size / 1024 > MAX_FILE_SIZE) {
          setImgError({
            ...imgError,
            image: true,
            imgMsg: "Maximum size 1MB",
          });
        } else {
          setImgName(URL.createObjectURL(file));
          setImgError({
            ...imgError,
            image: false,
            imgMsg: "",
          });
          newData["file_obj"] = file;
          setImgData(newData);
        }
      };
    }

    //eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleClear = () => {
    setImgName();
    handleClose();
  };

  const handleImageSave = () => {
    let compIds = compId;
    let values = imgData?.file_obj !== undefined ? new FormData() : "";
    setLoad(true);
    setDisableButton(true);
    if (imgData?.file_obj !== undefined && imgData?.file_obj !== null) {
      if (uploadType === "user") {
        values.append("userImage", imgData?.file_obj ? imgData?.file_obj : "");
        values.append("userId", compIds);
        values.append("editorId", localStorage.getItem("userid"));
        if (values.toString().trim().length > 0) {
          Service.uploadUserImage(values)
            .then((res) => {
              if (res.data.status === "success") {
                showSuccess(res?.data.message);
                handleClose();
                setLoad(false);
                setDisableButton(false);
                updateUserImage(imgName);
                refreshData(imgName);
              }
            })
            .catch((err) => {});
        }
      } else {
        values.append("logoImage", imgData?.file_obj ? imgData?.file_obj : "");
        values.append("companyId", compIds);
        values.append("editorId", localStorage.getItem("userid"));
        if (values.toString().trim().length > 0) {
          Service.uploadLogo(values)
            .then((res) => {
              if (res.data.status === "success") {
                showSuccess(res?.data.message);
                handleClose();
                setLoad(false);
                setDisableButton(false);
                refreshData(imgName);
              } else {
                showError(res?.data.message);
                handleClose();
                setLoad(false);
                setDisableButton(false);
              }
            })
            .catch((err) => {});
        }
      }
    } else {
      setImgError({
        ...imgError,
        image: true,
        imgMsg: "Please choose an image to upload !",
      });
      setLoad(false);
      setDisableButton(false);
    }
  };

  const handleImgRemove = () => {
    const newData = { ...imgData };
    newData["file_obj"] = null;
    setImgData(newData);
    setRemoveLoad(true);
    setDisableButton(true);
    if (imgName === defaultImg) {
      setImgError({
        ...imgError,
        image: true,
        imgMsg: "You don't have an image to remove !",
      });
      setRemoveLoad(false);
      setDisableButton(false);
    } else {
      setImgName(defaultImg);
      if (uploadType === "user") {
        Service.removeUserImage({
          userId: compId,
          editorId: localStorage.getItem("userid"),
        })
          .then((res) => {
            if (res.data.status === "success") {
              showSuccess(res?.data.message);
              setRemoveLoad(false);
              setDisableButton(false);
              updateUserImage(defaultImg);

              refreshData(imgName);
            }
          })
          .catch((err) => {});
      } else {
        Service.removeLogo({
          companyId: compId,
          editorId: localStorage.getItem("userid"),
        })
          .then((res) => {
            if (res.data.status === "success") {
              showSuccess(res?.data.message);
              setRemoveLoad(false);
              setDisableButton(false);
              refreshData(defaultImg);
            }
          })
          .catch((err) => {});
      }
    }
  };

  useEffect(() => {
    setImgName(imgs);
  }, [imgs]);

  return (
    <>
      <Dialog
        open={open}
        scroll={"body"}
        maxWidth="xl"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "15px",
            }}
          >
            <Typography
              sx={{
                color: "#01537A",
                fontWeight: "400",
                fontSize: { xs: "17px", md: "24px" },
                lineHeight: "26px",
              }}
            >
              Logo Image
            </Typography>
            <CloseSharpIcon
              sx={{
                color: "#757373",
                cursor: "pointer",
              }}
              onClick={handleClear}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: {
                xs: "10px 30px 10px 0px",
                md: "10px 150px 15px 40px",
              },
            }}
          >
            <Typography
              sx={{ color: "#01537A", fontWeight: 400, fontSize: "16px" }}
            >
              A picture helps people recognize your company.
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: "15px",
                columnGap: { xs: "0px", md: "27px" },
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "172px",
                  height: "171px",
                  textAlign: "center",
                  fontSize: "0.875rem",
                  fontWeight: "700",
                }}
              >
                <Paper
                  style={{
                    background: "#fff",
                    width: "165px",
                    height: "165px",
                    borderRadius: "14PX",
                    color: "#01537A",
                    padding: "9px 9px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <img
                    style={{ borderRadius: "50%", padding: "5px 5px" }}
                    alt="Datafree"
                    src={imgName}
                    rounded="lg"
                    width="150px"
                    height="150px"
                  />
                </Paper>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#5A5656",
                    fontWeight: 400,
                    fontSize: "14px",
                    mt: { xs: 3, md: 0 },
                  }}
                >
                  (Min. dimensions of 120px X 120px)
                </Typography>
                <Box
                  sx={{
                    width: { xs: "350px", sm: "400px", md: "500px" },
                    height: "123px",
                    backgroundColor: "#EBEAEA",
                    border: "1px dashed #696767",
                    borderRadius: "20px",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  {...getRootProps({ className: "dropzone" })}
                >
                  <input {...getInputProps()} />
                  <Box
                    component="img"
                    src={fileUpload}
                    alt=""
                    height="30px"
                  ></Box>
                  <Typography
                    component="span"
                    sx={{
                      color: "#000000",
                      fontWeight: 400,
                      fontSize: "16px",
                      textAlign: "center",
                      pt: "10px",
                      display: "inline",
                    }}
                  >
                    Drag files here or
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {" click "}
                    </Typography>
                    to upload
                  </Typography>
                </Box>
                {imgError?.image ? (
                  <FormHelperText
                    sx={{ color: "#d32f2f", textAlign: "center" }}
                  >
                    {imgError?.imgMsg}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
          <Divider
            sx={{
              color: "#DFDFDF",
              margin: { xs: "15px 26px 0px 10px", md: "15px 26px 0px 40px" },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              padding: { xs: "0px 40px 30px 25px", md: "0px 40px 30px 55px" },
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Button
              sx={{
                color: "#01537A !important",
                border: "1px solid #01537A !important",
                textTransform: "capitalize",
                backgroundColor: "#fff !important",
                width: "130px",
              }}
              onClick={handleClear}
              variant="contained"
            >
              Cancel
            </Button>
            <Box
              sx={{
                mt: { xs: 3, md: 0 },
              }}
            >
              <Button
                className={disableButton ? "disable" : null}
                sx={{
                  color: "#01537A !important",
                  border: "1px solid #01537A !important",
                  textTransform: "capitalize",
                  backgroundColor: "#fff !important",
                  width: "140px",
                }}
                onClick={handleImgRemove}
                variant="contained"
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Remove</span>
                  {removeLoad ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"#01537A"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
              <Button
                className={disableButton ? "disable" : null}
                sx={{
                  backgroundColor: "#01537A !important",
                  marginLeft: "30px",
                  textTransform: "capitalize",
                  width: "150px",
                }}
                variant="contained"
                onClick={handleImageSave}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Save</span>
                  {load ? (
                    <Loader
                      type={"spinningBubbles"}
                      color={"white"}
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
