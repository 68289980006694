import React from "react";
import MonthlyTableView from "./monthlyTableView";
import { Box } from "@mui/material";
import MonthlyChartView from "./monthlyChartView";
import DataSortPanelNew from "./dataSortPanelNew";
import { createFilterOptions } from "@mui/material/Autocomplete";

export default function MonthlyTab({
  viewType,
  role,
  handleSelect,
  data,
  publisherList,
  filterOptions,
  handleKeyPress,
  handleApplicationSwitch,
  prodApplication,
  handleDataUsageSwitch,
  dateRange,
  disableRefresh,
  onRefresh,
  loading,
  resData,
  areAllReportingFieldsFilled,
  csvDatas,
  companyName
}) {
 
  return (
    <Box
      sx={{
        display: "flex",
        gap: "40px",
      }}
    >
      <DataSortPanelNew
        tabType={"monthly"}
        role={role}
        handleSelect={handleSelect}
        data={data}
        publisherList={publisherList}
        filterOptions={filterOptions}
        handleKeyPress={handleKeyPress}
        handleApplicationSwitch={handleApplicationSwitch}
        createFilterOptions={createFilterOptions}
        prodApplication={prodApplication}
        handleDataUsageSwitch={handleDataUsageSwitch}
        dateRange={dateRange}
        disableRefresh={disableRefresh}
        onRefresh={onRefresh}
        areAllReportingFieldsFilled={areAllReportingFieldsFilled}
      />
      {viewType === "tableView" ? (
        <MonthlyTableView
          data={data}
          resData={resData?.finalReportResponse}
          loading={loading}
          csvDatas={csvDatas}
          companyName={companyName}
           role={role}
        />
      ) : (
        <MonthlyChartView data={data} resData={resData?.finalReportResponse} loading={loading}  companyName={companyName} role={role} />
      )}
    </Box>
  );
}
