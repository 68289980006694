import Layout from "../../components/layout";
import Dashboard from "../../components/dashboard";
import { CookiesProvider } from "react-cookie";
import LicenseeDashboard from "../../components/licenseeDashboard";
import ChannelDashboard from "../../components/channelDashboard";
import UserDashboard from "../../components/publisherDashboard";

export default function Admin() {
  const path = window.location.pathname.split("/");
  let role = "";
  if (path.length === 2) {
    role = path[1];
    localStorage.setItem("switchRole", role);
  } else {
    localStorage.removeItem("switchRole");
  }
  return (
    <CookiesProvider>
      <Layout>
        {role === "licensee" ? (
          <LicenseeDashboard />
        ) : role === "channel" ? (
          <ChannelDashboard />
        ) : role === "customer" ? (
          <UserDashboard />
        ) : (
          <Dashboard />
        )}
      </Layout>
    </CookiesProvider>
  );
}
