import React, { createContext, useState, useContext } from "react";

const BreadCrumbContext = createContext();

export const BreadCrumbProvider = ({ children }) => {
  const [breadCrumbData, setBreadCrumbData] = useState("");

  const updateBreadCrumbData = (data) => {
    setBreadCrumbData(data);
  };

  return (
    <BreadCrumbContext.Provider value={{ breadCrumbData, updateBreadCrumbData }}>
      {children}
    </BreadCrumbContext.Provider>
  );
};

export const useBreadCrumbUserData = () => useContext(BreadCrumbContext);
