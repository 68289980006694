import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, TextField } from "@mui/material";
import ddirecticon from "../../assets/img/ddirecticon.svg";

import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import sdirectlogo from "../../assets/img/sdirectlogo.svg";
import connectlogo from "../../assets/img/connectlogo.svg";
import { companyDetailPage } from "../../utils/roleHelper";

const API_URL = process.env.REACT_APP_API_URL;
export default function BasicDetailsDirects({
  productType,
  applicationDetails,
  sendLoadingStatus,
  onDataUpdate,
  loadData,
}) {
  const commonTextFieldStyles = {
    width: "619px",
    marginTop: "10px",
    marginBottom: "10px",
    fontFamily: "Roboto",
    fontWeight: "400",
    backgroundColor: "#F5F5F5",
    fontSize: "16px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "42px",
      "& fieldset": {
        borderColor: "#757373",
      },
      "&:hover fieldset": {
        borderColor: "#757373",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#757373",
      },
      "&.Mui-disabled": {
        backgroundColor: "#F5F5F5",
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px 16px",
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(16px, 12px) scale(1)",
      color: "#757373",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(16px, -6px) scale(0.75)",
      color: "#757373",
    },
    "& .Mui-focused .MuiInputLabel-root": {
      color: "#757373",
    },
  };

  const readOnlyInputProps = {
    readOnly: true,
  };

  let navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState();
  const [iconLoad, setIconLoad] = useState(true);
  const [defaultImg, setDefaultImg] = useState();

  const navigateCustomer = () => {
    if (localStorage.getItem("role") === "ROLE_USER") {
      navigate("/");
    } else {
      companyDetailPage(applicationDetails?.data?.publisherId, "CUSTOMER");
      navigate("/customer");
    }
  };

  useEffect(() => {
    sendLoadingStatus(true);

    const imageValue = applicationDetails?.data.iconImage;
    const productType = applicationDetails?.data.productType;
    if (productType === "Connect Workspace") {
      const connectImage = applicationDetails?.data.connectImage;
      if (connectImage !== undefined) {
        setImgSrc(API_URL + connectImage);
      }
    }
    if (imageValue !== undefined) {
      setImgSrc(API_URL + imageValue);

      sendLoadingStatus(false);
      setIconLoad(false);
    }
    switch (productType) {
      case "Connect Workspace":
        setDefaultImg(connectlogo);
        break;
      case "#datafree DIRECT":
        setDefaultImg(sdirectlogo);
        break;
      case "D-Direct":
        setDefaultImg(ddirecticon);
        break;
      default:
        break;
    }

    //eslint-disable-next-line
  }, [applicationDetails]);

  const defaultIcons = {
    "Connect Workspace": connectlogo,
    "D-Direct": ddirecticon,
    "#datafree DIRECT": sdirectlogo,
  };

  const defaultIcon = defaultIcons[productType];

  return (
    <Box
      sx={{
        marginTop: "10px",
        display: { xs: "grid", sm: "flex" },
        gridTemplateColumns: {
          xs: "auto",

          md: "repeat(3, 1fr)",
          lg: "repeat(3, 1fr)",
        },
        flexDirection: { sm: "row" },
        gap: {
          xs: "60px",
          sm: "40px",
          md: "50px",
          lg: "60px",
          xl: "70px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: { sm: "30px", xl: "60px" },
          flexDirection: { sm: "column", lg: "row" },
        }}
      >
        <Box
          sx={{
            width: "fit-content",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
              color: "#01537A",
              fontWeight: "400",
              letterSpacing: "1.1px",
              marginBottom: "5px",
            }}
          >
            Application Image
          </Typography>
          <Box
            sx={{
              position: "relative",
              width: "172px",
              height: "171px",
            }}
          >
            <Paper
              sx={{
                background: "#fff",
                width: "135px",
                height: "134px",
                borderRadius: "14PX",
                color: "#01537A",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              {iconLoad ? (
                <Box sx={{ position: "relative", top: "48px", left: "46px" }}>
                  <Loader
                    type={"spokes"}
                    color={"#01537A"}
                    height={40}
                    width={40}
                  />
                </Box>
              ) : (
                <img
                  src={imgSrc || defaultIcon}
                  alt="App Icon"
                  width="135px"
                  height="135px"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultIcon;
                  }}
                />
              )}
            </Paper>
          </Box>

          <Typography
            sx={{
              fontSize: "10px",
              color: "#01537A",
              fontWeight: "400",
              lineHeight: "106%",
              letterSpacing: "1.1px",
            }}
          >
            Product
          </Typography>
          <Box
            sx={{
              display: "flex",
              boxShadow: "1",
              width: "135px",
              padding: "8px 0px",
              borderRadius: "12px",
              height: "51px",
              position: "relative",
            }}
          >
            {iconLoad ? (
              <Box sx={{ position: "absolute", top: "8px", left: "48px" }}>
                <Loader
                  type={"spokes"}
                  color={"#01537A"}
                  height={40}
                  width={40}
                />
              </Box>
            ) : (
              <img
                alt="productimage"
                src={defaultImg}
                width="140px"
                style={{ padding: "7px" }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            sm: "column",
            md: "row",
            lg: "row",
          },
          gap: "50px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              marginBottom: "8px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "14px",
                letterSpacing: "1.1px",
                color: "#373737",
              }}
            >
              Customer:{" "}
            </Typography>
            <Typography
              onClick={navigateCustomer}
              sx={{
                lineHeight: "14px",
                fontWeight: "700",
                letterSpacing: "1.1px",
                color: "#01537A",
                textTransform: "uppercase",
                marginLeft: "2px",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              {" "}
              {applicationDetails?.data?.publisher &&
                applicationDetails?.data?.publisher}
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: "flex" }}>
              <Box>
                <TextField
                  sx={commonTextFieldStyles}
                  id="outlined-required"
                  label="App Name"
                  value={applicationDetails?.data?.appName}
                  InputProps={readOnlyInputProps}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Box>
            {productType === "Connect Workspace" && (
              <Box sx={{ display: "flex" }}>
                <Box>
                  <TextField
                    sx={commonTextFieldStyles}
                    id="outlined-required"
                    label="Workspace Title"
                    value={applicationDetails?.data?.workspaceTitle}
                    InputProps={readOnlyInputProps}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
