import { Box, Paper, Typography, Avatar } from "@mui/material";
import React from "react";
import triangle from "../../assets/img/triangle.svg";
import moment from "moment";
import UrlCheck from "../urlCheck";

export default function VersionPanel({ applicationDetails, appId }) {
  const getStatusColor = (status) => {
    switch (status) {
      case "Live":
        return "#4CAF50"; // Green color for live status
    }
  };

  const status = applicationDetails?.data?.status;
  const productType = applicationDetails?.data?.productType;
  const gatewayDomain = applicationDetails?.data?.gatewayDomain;

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
      }}
    >
      <Paper
        sx={{
          width: "227px",
          padding: "30px",
        }}
      >
        <Box
          sx={{
            background: "#01537A",
            color: "white",
            textAlign: "center",
            padding: "10px",
            borderRadius: "50px",
            fontWeight: "700",
          }}
        >
          <Typography sx={{ fontWeight: "700", fontSize: "14px" }}>
            ID:{" "}
            <Typography
              sx={{ fontWeight: "700", fontSize: "20px", marginLeft: "5px" }}
              component={"span"}
            >
              {appId !== undefined ? appId : "-"}
            </Typography>{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "34px",
            marginTop: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {" "}
            <Typography
              sx={{
                color: "#01537A",
                fontsize: "16px",
                fontWeight: "400",
              }}
            >
              Last Update
            </Typography>
            <Typography
              sx={{
                color: "#01537A",
                fontsize: "16px",
                fontWeight: "400",
              }}
              component={"span"}
            >
              {moment(applicationDetails?.data?.lastUpdate).format(
                "DD MMM YYYY, HH:mm"
              )}
            </Typography>
            {(productType === "Connect Workspace" ||
              productType === "D-Direct" ||
              productType === "#datafree DIRECT") && (
              <>
                <Typography
                  sx={{
                    color: "#01537A",
                    fontsize: "16px",
                    fontWeight: "400",
                    marginTop: "20px",
                  }}
                >
                  Created Date
                </Typography>
                <Typography
                  sx={{
                    color: "#01537A",
                    fontsize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {moment(applicationDetails?.data?.created).format(
                    "DD MMM YYYY, HH:mm"
                  )}
                </Typography>

                <Typography
                  sx={{
                    color: "#01537A",
                    fontsize: "16px",
                    fontWeight: "400",
                    marginTop: "20px",
                  }}
                >
                  App status
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      backgroundColor: getStatusColor(status),
                      marginRight: 1,
                    }}
                  />
                  <Typography variant="body2">
                    {applicationDetails?.data?.status}
                  </Typography>
                </Box>

                {productType === "D-Direct" && (
                  <UrlCheck gatewayDomain={gatewayDomain} />
                )}
              </>
            )}
          </Box>
        </Box>
      </Paper>
      <Box
        sx={{
          position: "absolute",
          top: "85px",
          left: "224px",
        }}
      >
        <img src={triangle} alt="traingle" />
      </Box>
    </Box>
  );
}
