import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
export default function DataCards({ data, loading }) {
  return (
    <Box container="true" columnspacing={5}>
      <Box
        sx={{
          marginTop: "30px",
          marginBottom: "10px",
          display: "grid",
          columnGap: {
            xs: "2rem",
            md: "1rem",
            lg: "1rem",
            xl: "1rem",
          },
          rowGap: { xs: "2rem", lg: "1rem" },
          gridTemplateColumns: {
            xs: "auto",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
          },
          gridTemplateRows: "repeat(1, 120px)",
        }}
      >
        <Card
          sx={{
            minWidth: {
              xs: "187px",
              sm: "100px",
              md: "108px",
              lg: "113px",
              xl: "187px",
            },
            minHeight: "112px",
            background: "#FFFFFF",
            border: "1px solid #0094CD",
          }}
        >
          <CardContent align="center" sx={{ padding: "10px" }}>
            <Typography
              sx={{
                fontSize: { xs: "35px", md: "30px", xl: "35px" },
                color: "#01537A",
                lineHeight: "55px",
                fontWeight: "700",
              }}
            >
              {!data?.totalDatafreeMb || loading ? 0 : data?.totalDatafreeMb}
            </Typography>
            <Typography
              sx={{
                color: "#01537A",
                fontSize: { xs: "14px", md: "12px", xl: "14px" },
              }}
            >
              Total #Datafree <br />
              Data
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            minWidth: {
              xs: "187px",
              sm: "100px",
              md: "108px",
              lg: "113px",
              xl: "187px",
            },
            minHeight: "112px",
            background: "#FFFFFF",
            border: "1px solid #0094CD",
          }}
        >
          <CardContent align="center" sx={{ padding: "10px" }}>
            <Typography
              sx={{
                fontSize: { xs: "35px", md: "30px", xl: "35px" },
                color: "#01537A",
                lineHeight: "55px",
                fontWeight: "700",
              }}
            >
                {!data?.totalDataMB || loading ? 0 : data?.totalDataMB}
            </Typography>
            <Typography
              sx={{
                color: "#01537A",
                fontSize: { xs: "14px", md: "12px", xl: "14px" },
              }}
            >
              Total Data (MB)
            </Typography>
          </CardContent>
        </Card>
        
      </Box>
    </Box>
  );
}
