import {
  Box,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
} from "@mui/material";
import React from "react";
import Tooltips from "../../../tooltips";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "react-toastify/dist/ReactToastify.css";
import { parseISO, isAfter } from "date-fns";
import moment from "moment";
import AppDefinitionTable from "./apDefnitionTable";

export default function AppDefinition({ applicationDetails }) {
  const commonTextFieldStyles = {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
    fontFamily: "Roboto",
    fontWeight: "400",
    backgroundColor: "#F5F5F5",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "42px",
      "& fieldset": {
        borderColor: "#000",
      },
      "&.Mui-disabled": {
        backgroundColor: "#F5F5F5",
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px 16px",
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(16px, 12px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(16px, -6px) scale(0.75)",
    },
  };
  const readOnlyInputProps = {
    readOnly: true,
  };

  const appDefinition = applicationDetails?.data?.endPointConfigs;

  const getLatestIndex = (data) => {
    let latestIndex = 0;
    let latestDate = parseISO(data[0]?.created || "");

    data.forEach((item, index) => {
      const currentDate = parseISO(item.created || "");
      if (!item.created) {
        console.error(
          `Missing 'created' date for item at index ${index}`,
          item
        );
        return;
      }
      if (isAfter(currentDate, latestDate)) {
        latestDate = currentDate;
        latestIndex = index;
      }
    });

    return latestIndex;
  };
  const latestIndex = getLatestIndex(appDefinition);

  return (
    <>
      <Box sx={{ flexGrow: 1, padding: "12px" }}>
        {/* TextField Row Section */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              sx={commonTextFieldStyles}
              id="accept-proxy"
              label="Accept Proxy Protocol Header"
              value={
                applicationDetails?.data?.acceptProxyProtocol
                  ? String(applicationDetails?.data?.acceptProxyProtocol)
                      .charAt(0)
                      .toUpperCase() +
                    String(applicationDetails?.data?.acceptProxyProtocol)
                      .slice(1)
                      .toLowerCase()
                  : ""
              }
              InputProps={readOnlyInputProps}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              sx={commonTextFieldStyles}
              id="d-direct-service"
              label="D-Direct Service"
              value={applicationDetails?.data?.licService}
              InputProps={readOnlyInputProps}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              sx={commonTextFieldStyles}
              id="service-sub-type"
              label="Service Sub Type"
              value={applicationDetails?.data?.licenseeServiceType}
              InputProps={readOnlyInputProps}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: "16px" }}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              sx={commonTextFieldStyles}
              id="send-proxy"
              label="Send Proxy Protocol Headers"
              value={
                appDefinition[0]?.sendProxyProtocol
                  ? String(appDefinition[0].sendProxyProtocol)
                      .charAt(0)
                      .toUpperCase() +
                    String(appDefinition[0].sendProxyProtocol)
                      .slice(1)
                      .toLowerCase()
                  : ""
              }
              InputProps={readOnlyInputProps}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        </Grid>

        {/* Table Section */}
        {/* <AppDefinitionTable></AppDefinitionTable> */}
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6" sx={{ marginBottom: "16px" }}>
            APP DEFINITION
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "5px" }}></TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid #E0E0E0", width: "200px" }}
                  >
                    <Box display="flex" alignItems="center">
                      <span>Public Domain for App</span>
                      <Tooltips type="ddirectService">
                        <ErrorOutlineIcon
                          sx={{
                            cursor: "pointer",
                            color: "#01537A",
                            fontSize: {
                              xs: "13px",
                              sm: "15px",
                              md: "19px",
                              lg: "19px",
                              xl: "19px",
                            },
                            marginLeft: "8px", // Add some space between the text and the icon
                          }}
                        />
                      </Tooltips>
                    </Box>
                  </TableCell>

                  <TableCell
                    sx={{ borderRight: "1px solid #E0E0E0", width: "200px" }}
                  >
                    <Box display="flex" alignItems="center">
                      <span>Customer End point</span>
                      <Tooltips type="ddirectEndPoint">
                        <ErrorOutlineIcon
                          sx={{
                            cursor: "pointer",
                            color: "#01537A",
                            fontSize: {
                              xs: "13px",
                              sm: "15px",
                              md: "19px",
                              lg: "19px",
                              xl: "19px",
                            },
                            marginLeft: "8px", // Add some space between the text and the icon
                          }}
                        />
                      </Tooltips>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid #E0E0E0", width: "200px" }}
                  >
                    Created
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid #E0E0E0", width: "200px" }}
                  >
                    Published
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appDefinition.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {" "}
                      {index === latestIndex && (
                        <Chip
                          label="LATEST"
                          sx={{ backgroundColor: "#99B3BF" }}
                          size="small"
                        />
                      )}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #E0E0E0" }}>
                      {item.publicAddress}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #E0E0E0" }}>
                      {item.endPointAddress}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #E0E0E0" }}>
                      {moment(item.created).format("DD MMM YYYY, HH:mm")}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid #E0E0E0" }}>
                      {item.ipConfigApproved === "true" ? "True" : "False"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}
