import Layout from "../../components/layout";
import { CookiesProvider } from "react-cookie";
import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  FormHelperText,
  Button,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  InputAdornment,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import defaultImg from "../../assets/img/noImage.png";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Services from "../../api/services";
import FormControl from "@mui/material/FormControl";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChangeLog from "../../components/AccountSettings/changeLog";
import Users from "../../components/AccountSettings/users";
import Channels from "../../components/AccountSettings/channels";
import LicenseeServices from "../../components/AccountSettings/licenseeServices";
import GatewayDomain from "../../components/AccountSettings/gatewayDomain";
import Divider from "@mui/material/Divider";
import useResponsive from "../../components/useResponsive";
import DoneIcon from "@mui/icons-material/Done";
import ButtonGroup from "@mui/material/ButtonGroup";
import "../../App.css";
import Loader from "../../components/loader";
import BreadCrumb from "../../components/breadCrumb";
import moment from "moment";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import LogoPopUp from "../../components/logoPopUp";
import ConfirmationPopup from "../../components/confirmationPopup";
import {
  userDispatchContext,
  archivedStatusContext,
  archivedStatusDispatchContext,
} from "../../context/serviceContext";
import { useNavigate } from "react-router-dom";
import useToast from "../../hooks/useToast";

const API_URL = process.env.REACT_APP_API_URL;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
  color: "#01537A",
  fontWeight: 500,
  "&.Mui-selected": {
    color: "#01537A",
  },
};

const Stack1 = styled(Typography)({
  color: "#01537A",
  fontWeight: "400",
  fontSize: "12px",
  letterSpacing: "0.4px",
  lineHeight: "24px",
  width: "150px",
  top: "5.5px",
});

const Stack2 = styled(Typography)({
  color: "#373737",
  fontWeight: "400",
  fontSize: "16px",
  width: "200px",
  height: "24px",
  lineHeight: "24px",
});

const StackBox = styled(Box)({
  padding: "10px",
});

const Status = styled(Button)({
  color: "#01537A",
  fontWeight: "400",
  fontSize: "16px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  padding: "0px",
  textTransform: "capitalize",
  textAlign: "center",
  borderColor: " white !important",
  marginTop: "6px",
});

export default function LicenseeAccount() {
  const { showSuccess, showError, showWarning } = useToast();
  const userDispatchContextVal = useContext(userDispatchContext);
  let archivedStatusDispatchContextVal = useContext(
    archivedStatusDispatchContext
  );
  let archivedStatusContextVal = JSON.parse(useContext(archivedStatusContext));
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);
  const [resData, setResData] = useState(null);
  const [imgSrc, setImgSrc] = useState(defaultImg);
  const location = useLocation();
  const clickedCompId = location.state?.clickedCompId;
  const userRole = localStorage.getItem("role");
  const [value, setValue] = React.useState(0);
  const isMobile = useResponsive("down", "md");
  let regExp = /[a-z]/i;
  const warnMsg = `Are your sure you want to archive this licensee? \n  This action cannot be undone and all users in this licensee will be archived.`;
  const [msg, setMsg] = useState(warnMsg);

  const [imgPopup, setImgPopUp] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleImgPop = () => {
    setImgPopUp(false);
  };

  const refreshData = (imgValue) => {
    setImgSrc(imgValue);
    userDispatchContextVal(true);
  };

  const [editData, setEditData] = useState({
    name: "",
  });
  const [editError, setEditError] = useState({
    name: false,
    nameMsg: "",
  });

  const [serviceData, setServiceData] = useState({
    companyId: clickedCompId,
    licenseeServiceTypes: "",
    licenseeServiceStatus: "",
    nonProxyProtocol: "",
    proxyProtocol: "",
    serviceName: "",
    licenseeServiceDescription: "",
    licenseeServiceZerorated: 0,
    licenseeProxyProtocolHeaders: "",
    natGatewayReq: "",
    supportedProducts: [],
    editorId: localStorage.getItem("userid"),
  });
  const [gatewayData, setgatewayData] = useState({
    companyName: "",
    managedBy: "",
    gatewayDomain: "",
    description: "",
    servicesName: "",
    status: "",
  });
  const [serviceError, setServiceError] = useState({
    licenseeServiceTypes: false,
    licenseeServiceStatus: false,
    proxyDomain: false,
    nonProxyProtocol: false,
    proxyProtocol: false,
    serviceName: false,
    licenseeServiceDescription: false,
    licenseeServiceZerorated: false,
    licenseeProxyProtocolHeaders: false,
    natGatewayReq: false,
    supportedProducts: false,
    nameMsg: "",
    descriptionMsg: "",
    serviceMsg: "",
    statusMsg: "",
    zeroRatedMsg: "",
    proxyMsg: "",
    nonProxyMsg: "",
    natMsg: "",
    headerMsg: "",
    productMsg: "",
  });
  const [gatewayError, setgatewayError] = useState({
    companyName: false,
    managedBy: false,
    gatewayDomain: false,
    description: false,
    servicesName: false,
    status: false,
    companyMsg: "",
    managedByMsg: "",
    gatewayDomainMsg: "",
    descriptionMsg: "",
    servicesMsg: "",
    statusMsg: "",
  });

  const NewIcon = (props) => (
    <KeyboardArrowDownIcon {...props} sx={{ color: "#01537A !important" }} />
  );

  const [open, setOpen] = React.useState(false);
  const [openGateway, setOpenGateway] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenGateway = () => {
    setOpenGateway(true);
  };
  const handleClose = () => {
    setOpen(false);
    setServiceData({
      companyId: clickedCompId,
      licenseeServiceTypes: "",
      licenseeServiceStatus: "",
      nonProxyProtocol: "",
      proxyProtocol: "",
      serviceName: "",
      licenseeServiceDescription: "",
      licenseeServiceZerorated: 0,
      licenseeProxyProtocolHeaders: "",
      natGatewayReq: "",
      supportedProducts: [],
      editorId: localStorage.getItem("userid"),
    });
    setServiceError({
      licenseeServiceTypes: false,
      licenseeServiceStatus: false,
      nonProxyProtocol: false,
      proxyProtocol: false,
      serviceName: false,
      licenseeServiceDescription: false,
      licenseeServiceZerorated: false,
      licenseeProxyProtocolHeaders: false,
      natGatewayReq: false,
      supportedProducts: false,
      nameMsg: "",
      descriptionMsg: "",
      serviceMsg: "",
      statusMsg: "",
      zeroRatedMsg: "",
      proxyMsg: "",
      nonProxyMsg: "",
      natMsg: "",
      headerMsg: "",
      productMsg: "",
    });
    setSupportProducts({});
    setCheckedProducts({});
  };
  const handleCloseGateway = () => {
    setOpenGateway(false);
    setgatewayData({
      companyName: "",
      managedBy: "",
      gatewayDomain: "",
      description: "",
      servicesName: "",
      status: "",
    });
    setgatewayError({
      companyName: false,
      managedBy: false,
      gatewayDomain: false,
      description: false,
      servicesName: false,
      status: false,
      companyMsg: "",
      managedByMsg: "",
      gatewayDomainMsg: "",
      descriptionMsg: "",
      servicesMsg: "",
      statusMsg: "",
    });
  };

  const GatewayUpdate = (stat, attribute) => {
    const newData = { ...gatewayData };
    newData[attribute] = stat;
    if (attribute === "status") {
      if (stat === "") {
        setgatewayError({
          ...gatewayError,
          status: true,
          statusMsg: "This field is required",
        });
      } else {
        setgatewayError({
          ...gatewayError,
          status: false,
          statusMsg: "",
        });
      }
    }
    setgatewayData(newData);
  };
  const handleAddService = (e) => {
    const newData = { ...serviceData };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "serviceName") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setServiceError({
          ...serviceError,
          serviceName: true,
          nameMsg: "This field is required",
        });
      } else {
        setServiceError({
          ...serviceError,
          serviceName: false,
          nameMsg: "",
        });
      }
    } else if (e.target.name === "licenseeServiceDescription") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setServiceError({
          ...serviceError,
          licenseeServiceDescription: true,
          descriptionMsg: "This field is required",
        });
      } else {
        setServiceError({
          ...serviceError,
          licenseeServiceDescription: false,
          descriptionMsg: "",
        });
      }
    } else if (e.target.name === "licenseeServiceTypes") {
      if (e.target.value === "") {
        setServiceError({
          ...serviceError,
          licenseeServiceTypes: true,
          serviceMsg: "This field is required",
        });
      } else {
        getSupportedProducts(e.target.value);
        setServiceError({
          ...serviceError,
          licenseeServiceTypes: false,
          serviceMsg: "",
        });
      }
    } else if (e.target.name === "licenseeServiceZerorated") {
      if (e.target.value === "") {
        setServiceError({
          ...serviceError,
          licenseeServiceZerorated: true,
          zeroRatedMsg: "This field is required",
        });
      } else {
        setServiceError({
          ...serviceError,
          licenseeServiceZerorated: false,
          zeroRatedMsg: "",
        });
      }
    } else if (e.target.name === "licenseeServiceStatus") {
      if (e.target.value === "") {
        setServiceError({
          ...serviceError,
          licenseeServiceStatus: true,
          statusMsg: "This field is required",
        });
      } else {
        setServiceError({
          ...serviceError,
          licenseeServiceStatus: false,
          statusMsg: "",
        });
      }
    } else if (e.target.name === "natGatewayReq") {
      if (e.target.value === "") {
        setServiceError({
          ...serviceError,
          natGatewayReq: true,
          natMsg: "This field is required",
        });
      } else {
        setServiceError({
          ...serviceError,
          natGatewayReq: false,
          natMsg: "",
        });
      }
    } else if (e.target.name === "nonProxyProtocol") {
      if (e.target.value === "") {
        setServiceError({
          ...serviceError,
          nonProxyProtocol: true,
          nonProxyMsg: "This field is required",
        });
      } else {
        if (e.target.value < 0 || e.target.value > 9999) {
          setServiceError({
            ...serviceError,
            nonProxyProtocol: true,
            nonProxyMsg: "Please enter a value between 0 and 9999.",
          });
        } else if (regExp.test(e.target.value) === true) {
          setServiceError({
            ...serviceError,
            nonProxyProtocol: true,
            nonProxyMsg: "Please enter a positive non-decimal number",
          });
        } else {
          setServiceError({
            ...serviceError,
            nonProxyProtocol: false,
            nonProxyMsg: "",
          });
        }
      }
    } else if (e.target.name === "proxyProtocol") {
      if (e.target.value === "") {
        setServiceError({
          ...serviceError,
          proxyProtocol: true,
          proxyMsg: "This field is required",
        });
      } else {
        if (e.target.value < 0 || e.target.value > 9999) {
          setServiceError({
            ...serviceError,
            proxyProtocol: true,
            proxyMsg: "Please enter a value between 0 and 9999.",
          });
        } else if (regExp.test(e.target.value) === true) {
          setServiceError({
            ...serviceError,
            proxyProtocol: true,
            proxyMsg: "Please enter a positive non-decimal number",
          });
        } else {
          setServiceError({
            ...serviceError,
            proxyProtocol: false,
            proxyMsg: "",
          });
        }
      }
    } else if (e.target.name === "licenseeProxyProtocolHeaders") {
      if (e.target.value === "") {
        setServiceError({
          ...serviceError,
          licenseeProxyProtocolHeaders: true,
          headerMsg: "This field is required",
        });
      } else {
        setServiceError({
          ...serviceError,
          licenseeProxyProtocolHeaders: false,
          headerMsg: "",
        });
      }
    } else if (e.target.name === "natGatewayReq") {
      if (e.target.value === "") {
        setServiceError({
          ...serviceError,
          natGatewayReq: true,
          natMsg: "This field is required",
        });
      } else {
        setServiceError({
          ...serviceError,
          natGatewayReq: false,
          natMsg: "",
        });
      }
    }
    setServiceData(newData);
  };

  const [supportProducts, setSupportProducts] = useState({});
  const [checkedProducts, setCheckedProducts] = useState({});
  const getSupportedProducts = (servicetype) => {
    setSupportProducts({});
    setCheckedProducts({});
    serviceData.supportedProducts.splice(
      0,
      serviceData.supportedProducts.length
    );
    if (servicetype !== "") {
      Services.supportedProducts({ id: servicetype })
        .then((res) => {
          setSupportProducts(res?.data?.products);
          let dupCheckedProducts = { ...checkedProducts };
          for (let i = 0; i <= res?.data?.products.length; i++) {
            dupCheckedProducts[i] = res?.data?.products[i].productId;
            serviceData?.supportedProducts.push(
              res.data?.products[i].productId
            );
            if (i === res.data?.products.length - 1) {
              setCheckedProducts(dupCheckedProducts);
            }
          }
        })
        .catch((err) => {});
    }
  };

  const handleCheck = (e, checkValue, index) => {
    if (checkedProducts[index] === checkValue) {
      let dupCheckedProducts = { ...checkedProducts };
      dupCheckedProducts[index] = 0;
      setCheckedProducts(dupCheckedProducts);
      let newSelected = { ...serviceData.supportedProducts };
      newSelected[index] = 0;
      serviceData.supportedProducts.splice(index, 1);
    } else {
      let dupCheckedProducts = { ...checkedProducts };
      dupCheckedProducts[index] = checkValue;
      setCheckedProducts(dupCheckedProducts);
      serviceData.supportedProducts.push(checkValue);
    }
  };
  const handleGateway = (e) => {
    const newData = { ...gatewayData };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "companyName") {
      if (e.target.value === "") {
        setgatewayError({
          ...gatewayError,
          companyName: true,
          companyMsg: "This field is required",
        });
      } else {
        setgatewayError({
          ...gatewayError,
          companyName: false,
          companyMsg: "",
        });
      }
    } else if (e.target.name === "managedBy") {
      if (e.target.value === "") {
        setgatewayError({
          ...gatewayError,
          managedBy: true,
          managedByMsg: "This field is required",
        });
      } else {
        setgatewayError({
          ...gatewayError,
          managedBy: false,
          managedByMsg: "",
        });
      }
    } else if (e.target.name === "gatewayDomain") {
      if (e.target.value === "") {
        setgatewayError({
          ...gatewayError,
          gatewayDomain: true,
          gatewayDomainMsg: "This field is required",
        });
      } else {
        setgatewayError({
          ...gatewayError,
          gatewayDomain: false,
          gatewayDomainMsg: "",
        });
      }
    } else if (e.target.name === "description") {
      if (e.target.value === "") {
        setgatewayError({
          ...gatewayError,
          description: true,
          descriptionMsg: "This field is required",
        });
      } else {
        setgatewayError({
          ...gatewayError,
          description: false,
          descriptionMsg: "",
        });
      }
    } else if (e.target.name === "servicesName") {
      if (e.target.value === "") {
        setgatewayError({
          ...gatewayError,
          servicesName: true,
          servicesMsg: "This field is required",
        });
      } else {
        setgatewayError({
          ...gatewayError,
          servicesName: false,
          servicesMsg: "",
        });
      }
    }
    setgatewayData(newData);
  };
  const validate = (data) => {
    const servicesError = { ...serviceError };
    let count = 0;
    if (data?.serviceName === "") {
      servicesError.serviceName = true;
      servicesError.nameMsg = "This field required";
      count++;
    } else {
      servicesError.serviceName = false;
      servicesError.nameMsg = "";
    }

    if (data?.licenseeServiceDescription === "") {
      servicesError.licenseeServiceDescription = true;
      servicesError.descriptionMsg = "This field required";
      count++;
    } else {
      servicesError.licenseeServiceDescription = false;
      servicesError.descriptionMsg = "";
    }

    if (data?.licenseeServiceTypes === "") {
      servicesError.licenseeServiceTypes = true;
      servicesError.serviceMsg = "This field required";
      count++;
    } else {
      servicesError.licenseeServiceTypes = false;
      servicesError.serviceMsg = "";
    }

    if (data?.licenseeServiceZerorated === "") {
      servicesError.licenseeServiceZerorated = true;
      servicesError.zeroRatedMsg = "This field required";
      count++;
    } else {
      servicesError.licenseeServiceZerorated = false;
      servicesError.zeroRatedMsg = "";
    }

    if (data?.licenseeServiceStatus === "") {
      servicesError.licenseeServiceStatus = true;
      servicesError.statusMsg = "This field required";
      count++;
    } else {
      servicesError.licenseeServiceStatus = false;
      servicesError.statusMsg = "";
    }

    if (data?.licenseeServiceTypes === "D-DIRECT") {
      if (data?.licenseeProxyProtocolHeaders === "") {
        servicesError.licenseeProxyProtocolHeaders = true;
        servicesError.headerMsg = "This field required";
        count++;
      } else {
        servicesError.licenseeProxyProtocolHeaders = false;
        servicesError.headerMsg = "";
      }

      if (data?.proxyProtocol === "") {
        servicesError.proxyProtocol = true;
        servicesError.proxyMsg = "This field required";
        count++;
      } else {
        if (data?.proxyProtocol < 0 || data?.proxyProtocol > 9999) {
          servicesError.proxyProtocol = true;
          servicesError.proxyMsg = "Please enter a value between 0 and 9999.";
          count++;
        } else if (regExp.test(data?.proxyProtocol) === true) {
          servicesError.proxyProtocol = true;
          servicesError.proxyMsg = "Please enter a positive non-decimal number";
          count++;
        } else {
          servicesError.proxyProtocol = false;
          servicesError.proxyMsg = "";
        }
      }

      if (data?.nonProxyProtocol === "") {
        servicesError.nonProxyProtocol = true;
        servicesError.nonProxyMsg = "This field required";
        count++;
      } else {
        if (data?.nonProxyProtocol < 0 || data?.nonProxyProtocol > 9999) {
          servicesError.nonProxyProtocol = true;
          servicesError.nonProxyMsg =
            "Please enter a value between 0 and 9999.";
          count++;
        } else if (regExp.test(data?.nonProxyProtocol) === true) {
          servicesError.nonProxyProtocol = true;
          servicesError.nonProxyMsg =
            "Please enter a positive non-decimal number";
          count++;
        } else {
          servicesError.nonProxyProtocol = false;
          servicesError.nonProxyMsg = "";
        }
      }
    }

    if (
      data?.licenseeServiceTypes === "REACH-SWITCH-GATEWAY" ||
      data?.licenseeServiceTypes === "WRAP-MAX-GATEWAY"
    ) {
      if (data?.natGatewayReq === "") {
        servicesError.natGatewayReq = true;
        servicesError.natMsg = "This field required";
        count++;
      } else {
        servicesError.natGatewayReq = false;
        servicesError.natMsg = "";
      }
    }

    if (
      data?.licenseeServiceTypes !== "CONNECT_CONFIG" &&
      data?.licenseeServiceTypes !== ""
    ) {
      if (data?.supportedProducts.length === 0) {
        servicesError.supportedProducts = true;
        servicesError.productMsg = "This field required";
        count++;
      } else {
        servicesError.supportedProducts = false;
        servicesError.productMsg = "";
      }
    } else {
      servicesError.supportedProducts = false;
      servicesError.productMsg = "";
    }
    setServiceError(servicesError);
    return count;
  };
  const validateGateway = (data) => {
    const nwerror = { ...gatewayError };
    let count = 0;
    if (data?.companyName === "") {
      nwerror.companyName = true;
      nwerror.companyMsg = "This field required";
      count++;
    } else {
      nwerror.companyName = false;
      nwerror.companyMsg = "";
    }

    if (data?.managedBy === "") {
      nwerror.managedBy = true;
      nwerror.managedByMsg = "This field required";
      count++;
    } else {
      nwerror.managedBy = false;
      nwerror.managedByMsg = "";
    }

    if (data?.gatewayDomain === "") {
      nwerror.gatewayDomain = true;
      nwerror.gatewayDomainMsg = "This field required";
      count++;
    } else {
      nwerror.gatewayDomain = false;
      nwerror.gatewayDomainMsg = "";
    }

    if (data?.description === "") {
      nwerror.description = true;
      nwerror.descriptionMsg = "This field required";
      count++;
    } else {
      nwerror.description = false;
      nwerror.descriptionMsg = "";
    }

    if (data?.servicesName === "") {
      nwerror.servicesName = true;
      nwerror.servicesMsg = "This field required";
      count++;
    } else {
      nwerror.servicesName = false;
      nwerror.servicesMsg = "";
    }
    if (data?.status === "") {
      nwerror.status = true;
      nwerror.statusMsg = "This field required";
      count++;
    } else {
      nwerror.status = false;
      nwerror.statusMsg = "";
    }
    setgatewayError(nwerror);
    return count;
  };

  const [licenseeClick, setLicenseeClick] = useState(false);
  const handleSave = (e) => {
    e.preventDefault();
    let errorCount = validate(serviceData);
    if (errorCount === 0) {
      setLicenseeClick(true);
      Services.AddLicenseeService(serviceData)
        .then((res) => {
          setLicenseeClick(false);
          if (res?.data.status === "success") {
            showSuccess(res?.data.message);
            handleClose();
            getBasicDetails();
            serviceRef.current.getServiceList();
          }
        })
        .catch((err) => {
          setSaveClick(false);
        });
    }
  };
  const handleGatewaySave = (e) => {
    e.preventDefault();
    let errorCount = validateGateway(gatewayData);
    if (errorCount === 0) {
    }
  };

  const handleChanges = (e) => {
    const newData = { ...editData };
    newData[e.target.name] = e.target.value;
    if (e.target.name === "name") {
      if (e.target.value !== resData?.name) {
        checkName(e.target.value);
      }
    }
    setEditData(newData);
  };

  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [type, setType] = useState("warning");
  const [statusValue, setStatusValue] = useState("");
  const setStatus = (status) => {
    const newData = { ...editData };
    if (status === "Archived") {
      setConfirmPopUp(true);
    }
    newData["companyStatus"] = status;
    setEditData(newData);
  };

  const closeConfirmationBox = () => {
    setConfirmPopUp(false);
    setType("warning");
    setMsg(warnMsg);
    editData["companyStatus"] = statusValue;
  };

  const confirmClick = () => {
    setConfirmPopUp(false);
  };
  const navigate = useNavigate();
  async function getBasicDetails() {
    setLoading(true);
    Services.LicenseeAccountSettings({ companyId: clickedCompId })
      .then((res) => {
        if (res?.data?.status === "failed") {
          navigate("/");
          showWarning(res?.data?.message);
          return;
        }
        setResData(res.data);
        setEditData({
          name: res?.data?.name,
          companyStatus: res?.data?.status,
          editorId: localStorage.getItem("userid"),
        });
        setStatusValue(res.data?.status);
        if (res.data?.status === "Archived") {
          localStorage.setItem("archivedStatus", true);
          archivedStatusDispatchContextVal(true);
        } else {
          localStorage.setItem("archivedStatus", false);
          archivedStatusDispatchContextVal(false);
        }
        if (res?.data?.logoImage !== null) {
          setImgSrc(API_URL + res?.data?.logoImage);
        } else {
          setImgSrc(defaultImg);
        }
        setLoading(false);
      })
      .catch((err) => {});
  }

  const cancelUpdate = () => {
    setEditError({
      name: false,
      nameMsg: "",
    });
    getBasicDetails();
  };

  const serviceRef = React.useRef(null);
  const [saveClick, setSaveClick] = useState(false);
  const updateCompanyDetails = () => {
    if (editData?.name === "") {
      setEditError({
        ...editError,
        name: true,
        nameMsg: "This field is required",
      });
    } else if (exist === true) {
      setEditError({
        ...editError,
        name: true,
        nameMsg: "Company name already exists",
      });
    } else {
      setSaveClick(true);
      setBtnLoading(true);
      Services.updateCompanyDetails(editData, clickedCompId)
        .then((res) => {
          setSaveClick(false);
          setBtnLoading(false);
          if (res?.data?.status === "success") {
            showSuccess(res?.data.message);
            cancelUpdate();
          } else if (res?.data.status === "Error") {
            showError(res?.data.message);
            cancelUpdate();
          }
        })
        .catch((err) => {
          setSaveClick(false);
        });
    }
  };

  const [exist, setExist] = useState();
  async function checkName(nameVal) {
    if (nameVal === "" || nameVal.match(/^ *$/) !== null) {
      setEditError({
        ...editError,
        name: true,
        nameMsg: "This field required",
      });
    } else {
      Services.checkCompanyName({ name: nameVal })
        .then((res) => {
          setExist(res?.data.status);
          if (res.data.status === true) {
            setEditError({
              ...editError,
              name: true,
              nameMsg: "Company name already exists",
            });
          } else {
            setEditError({
              ...editError,
              name: false,
              nameMsg: "",
            });
          }
        })
        .catch((err) => {});
    }
  }

  const getImage = () => {
    setImgPopUp(true);
  };

  const [serviceTypeList, setServiceTypeList] = useState({});
  const getServiceTypeDetails = () => {
    Services.serviceTypesList()
      .then((res) => {
        setServiceTypeList(res?.data);
      })
      .catch((err) => {});
  };

  const checkboxStyle = {
    "& .Mui-checked": {
      color: "#01537A",
    },
    "& .MuiCheckbox-root": {
      color: "#01537A",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "3rem",
      stroke: "aliceblue",
      strokeWidth: "1",
      color: "#01537A",
    },
    "&. MuiCheckbox-root:hover": {
      backgroundColor: "none",
    },
  };

  const accessControls = () => {
    if (userRole === "ROLE_ADMIN") {
      return true;
    } else {
      return false;
    }
  };

  const accessPermission = (status, checkStatus) => {
    if (accessControls() && !archivedStatusContextVal) {
      if (status === checkStatus) {
        return "btn-active";
      } else {
        return "btn-nonactive";
      }
    } else {
      if (userRole === "ROLE_LICENSEE") {
        if (status === checkStatus) {
          return "btn-disable-active disable";
        } else {
          return "btn-disable-nonactive disable";
        }
      } else {
        if (archivedStatusContextVal) {
          if (status === checkStatus) {
            return "btn-active disable";
          } else {
            return "btn-nonactive disable";
          }
        } else {
          if (status === checkStatus) {
            return "btn-active";
          } else {
            return "btn-nonactive";
          }
        }
      }
    }
  };

  const accessPermissionFields = () => {
    if (archivedStatusContextVal) {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionButton = (btnName) => {
    if (archivedStatusContextVal) {
      return `${btnName}-btn disable`;
    } else {
      return `${btnName}-btn`;
    }
  };

  useEffect(() => {
    getBasicDetails();
    getServiceTypeDetails();
    //eslint-disable-next-line
  }, []);

  return (
    <CookiesProvider>
      <Layout>
        <Box>
          <Box sx={{ marginLeft: "40px" }}>
            {resData !== null && !loading ? (
              <BreadCrumb value={resData} other="Account Settings" />
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: { xs: "10px 30px 40px 40px", md: "10px 30px 25px 90px" },
              columnGap: { xs: "0px", md: "27px" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingLeft: "60px",
                width: "172px",
                height: "171px",
                p: 1,
                m: 1,
                textAlign: "center",
                fontSize: "0.875rem",
                fontWeight: "700",
              }}
            >
              <Paper
                style={{
                  background: "#fff",
                  width: "148px",
                  height: "148px",
                  borderRadius: "14PX",
                  color: "#01537A",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "6px 6px",
                }}
              >
                <img
                  style={{ borderRadius: "50%", padding: "2px 2px" }}
                  alt="Datafree"
                  src={imgSrc === null ? defaultImg : imgSrc}
                  rounded="lg"
                  width="135px"
                  height="135px"
                />
              </Paper>
              <IconButton
                style={{
                  position: "absolute",
                  background: accessPermissionFields() ? "#373737" : "#01537A",
                  color: "#FFFFFF",
                  width: "22.71px",
                  height: "22.71px",
                  top: imgSrc === defaultImg ? "112px" : "120px",
                  fontSize: "15px",
                  right: "36px",
                  bottom: "0px",
                }}
                className={accessPermissionFields() ? "disable" : null}
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={getImage}
              >
                <EditOutlinedIcon fontSize="11px" />
              </IconButton>
            </Box>
            {imgPopup && (
              <LogoPopUp
                open={imgPopup}
                handleClose={handleImgPop}
                imgs={imgSrc}
                defaultImg={defaultImg}
                compId={clickedCompId}
                refreshData={refreshData}
              />
            )}

            {confirmPopUp && (
              <ConfirmationPopup
                open={confirmPopUp}
                handleClose={closeConfirmationBox}
                message={msg}
                confirmSave={confirmClick}
                type={type}
              />
            )}

            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                padding: { xs: "12px", md: 0 },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "400px",
                  fontSize: { xs: "30px", sm: "35px", md: "40px" },
                  lineHeight: "18px",
                  letterSpacing: "0.5px",
                  color: "#757373 !important",
                  fontStyle: "normal",
                }}
              >
                {resData?.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "26.11px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                  paddingTop: "20px",
                }}
              >
                Licensee Details -{" "}
                {userRole === "ROLE_ADMIN"
                  ? "Admin View"
                  : userRole === "ROLE_LICENSEE"
                  ? "Licensee View"
                  : ""}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "0px 25px 0px 25px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Tabs
              sx={{
                maxwidth: {
                  xs: "402px",
                  md: "613px",
                  lg: "1440px",
                  xl: "1440px",
                },
                "& .MuiButtonBase-root-MuiTab-root": {
                  padding: " 15px 22px 15px 20px",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#01537A",
                },
              }}
              variant="scrollable"
              scrollButtons={isMobile ? true : false}
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tabStyle} label="company" {...a11yProps(0)} />
              <Tab sx={tabStyle} label="change log" {...a11yProps(1)} />
              <Tab sx={tabStyle} label="users" {...a11yProps(2)} />
              <Tab sx={tabStyle} label="channels" {...a11yProps(3)} />
              <Tab sx={tabStyle} label="licensee services" {...a11yProps(4)} />
              <Tab sx={tabStyle} label="gateway domain" {...a11yProps(5)} />
            </Tabs>
            {value === 4 && localStorage.getItem("role") === "ROLE_ADMIN" ? (
              <Button
                className={accessPermissionFields() ? "disable" : null}
                sx={{
                  backgroundColor: "#01537A !important",
                  textTransform: "capitalize",
                  width: "260px",
                  height: "45px",
                  padding: "15px",
                  fontWeight: "400",
                  fontSize: { xs: "13px", md: "15px", lg: "18px" },
                  lineHeight: "20px",
                }}
                variant="contained"
                onClick={handleOpen}
              >
                Add Licensee Service
              </Button>
            ) : value === 5 && localStorage.getItem("role") === "ROLE_ADMIN" ? (
              <Button
                onClick={handleOpenGateway}
                className={accessPermissionFields() ? "disable" : null}
                sx={{
                  backgroundColor: "#01537A !important",
                  textTransform: "capitalize",
                  width: "260px",
                  height: "45px",
                  padding: "15px",
                  fontWeight: "400",
                  fontSize: { xs: "13px", md: "15px", lg: "18px" },
                  lineHeight: "20px",
                }}
                variant="contained"
              >
                Add Gateway Domain
              </Button>
            ) : (
              ""
            )}
          </Box>
          <Divider
            sx={{
              color: "#DFDFDF",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              marginTop: "20px",
              borderBottomWidth: 2,
            }}
          ></Divider>

          <TabPanel value={value} index={0}>
            {loading && resData === null ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Loader
                  type={"spokes"}
                  color={"#01537A"}
                  height={40}
                  width={40}
                />
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    flexDirection: {
                      xs: "column",
                      lg: "row",
                    },
                    display: "flex",
                    paddingBottom: "60px",
                  }}
                >
                  <Paper
                    sx={{
                      maxWidth: { xs: "380px", md: "450px", lg: "450px" },
                      width: "100%",
                      height: "185px",
                      padding: "30px",
                      display: "flex",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack1 direction="column" spacing={2} component="span">
                      <StackBox>Company ID </StackBox>
                      <StackBox>Created</StackBox>
                      <StackBox>Licensee Shortcode</StackBox>
                    </Stack1>
                    <Stack2 component="span" direction="column" spacing={2}>
                      <StackBox>
                        {resData?.companyId ? resData?.companyId : "-"}{" "}
                      </StackBox>
                      <StackBox>
                        {resData?.created
                          ? moment(resData?.created).format(
                              "DD MMM YYYY, HH:mm"
                            )
                          : "-"}
                      </StackBox>
                      <StackBox>
                        {resData?.licenseeShortcode
                          ? resData?.licenseeShortcode
                          : "-"}
                      </StackBox>
                    </Stack2>
                  </Paper>

                  <Box
                    sx={{
                      paddingLeft: { xs: "0px", lg: "100px" },
                      paddingTop: { xs: "20px", lg: "0px" },
                    }}
                  >
                    <Box>
                      <FormControl
                        sx={{
                          width: {
                            xs: "380px",
                            md: "450px",
                            lg: "565px",
                            xl: "650px",
                          },
                        }}
                        variant="standard"
                      >
                        <TextField
                          sx={{
                            fontWeight: "400px",
                            fontSize: {
                              xs: "16px",
                              sm: "16px",
                              md: "16px",
                            },
                            lineHeight: "18px",
                            letterSpacing: "0.5px",
                            color: "rgba(0, 0, 0, 0.87) !important",
                            fontStyle: "normal",
                            padding: "4px 0 0px",
                          }}
                          name="name"
                          variant="standard"
                          onChange={handleChanges}
                          onKeyUp={handleChanges}
                          error={!!editError?.name}
                          required
                          helperText={editError?.name ? editError?.nameMsg : ""}
                          value={editData?.name}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {!accessPermissionFields() && (
                                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                                )}
                              </InputAdornment>
                            ),
                            readOnly: accessPermissionFields() && true,
                          }}
                        />
                      </FormControl>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "24px",
                          letterSpacing: "0.4px",
                          color: "#01537A",
                        }}
                      >
                        Company Name
                      </Typography>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          color: "#1B2935",
                        }}
                      >
                        Status
                      </Typography>
                      <ButtonGroup
                        aria-label="outlined button group"
                        sx={{
                          width: {
                            xs: "380px",
                            md: "450px",
                            lg: "565px",
                            xl: "650px",
                          },
                          height: { xs: "35px", lg: "40px" },
                          fontWeight: 400,
                        }}
                      >
                        {editData?.companyStatus === "New" && (
                          <Button
                            type="button"
                            sx={{
                              width: "100%",
                              fontSize: { xs: "12px", md: "14px", lg: "16px" },
                              textTransform: "capitalize",
                            }}
                            startIcon={
                              editData?.companyStatus === "New" ? (
                                <DoneIcon />
                              ) : (
                                ""
                              )
                            }
                            className={accessPermission(
                              "New",
                              editData?.companyStatus
                            )}
                            onClick={() => setStatus("New")}
                          >
                            Approved
                          </Button>
                        )}
                        <Button
                          type="button"
                          sx={{
                            width: "100%",
                            fontSize: { xs: "12px", md: "14px", lg: "16px" },
                            textTransform: "capitalize",
                          }}
                          startIcon={
                            editData?.companyStatus === "Approved" ? (
                              <DoneIcon />
                            ) : (
                              ""
                            )
                          }
                          className={accessPermission(
                            "Approved",
                            editData?.companyStatus
                          )}
                          onClick={() => setStatus("Approved")}
                        >
                          Approved
                        </Button>
                        <Button
                          type="button"
                          sx={{
                            width: "100%",
                            fontSize: { xs: "12px", md: "14px", lg: "16px" },
                            textTransform: "capitalize",
                          }}
                          startIcon={
                            editData?.companyStatus === "Archived" ? (
                              <DoneIcon />
                            ) : (
                              ""
                            )
                          }
                          className={accessPermission(
                            "Archived",
                            editData?.companyStatus
                          )}
                          onClick={() => setStatus("Archived")}
                        >
                          Archived
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ color: "#DFDFDF" }}></Divider>
                <Box
                  sx={{
                    padding: "20px 0px 10px 0px",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                  }}
                >
                  <Button
                    className={accessPermissionButton("cancel")}
                    variant="outlined"
                    onClick={cancelUpdate}
                  >
                    Cancel
                  </Button>

                  <Button
                    sx={{
                      "&.Mui-disabled": {
                        cursor: "not-allowed !important",
                        pointerEvents: "unset !important",
                      },
                    }}
                    className={accessPermissionButton("save")}
                    variant="contained"
                    disabled={saveClick}
                    onClick={() => updateCompanyDetails()}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <span style={{ marginRight: "5px" }}>Save</span>
                      {btnloading ? (
                        <Loader
                          type={"spinningBubbles"}
                          color={"white"}
                          height={20}
                          width={20}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Button>
                </Box>
              </Box>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangeLog />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Users />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Channels />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <LicenseeServices ref={serviceRef} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <GatewayDomain />
          </TabPanel>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={"body"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#01537A",
                  fontWeight: "400",
                  fontSize: "24px",
                  lineHeight: "26px",
                  width: "253px",
                  height: "27px",
                }}
              >
                Add Licensee Service
              </Typography>
              <CloseSharpIcon
                onClick={handleClose}
                sx={{
                  color: "#757373",
                  cursor: "pointer",
                }}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: { xs: "400px", md: "500px" },
                width: "100%",
                rowGap: "4px",
                marginTop: "8px",
              }}
              autoComplete="off"
            >
              <TextField
                required
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                }}
                inputlabelprops={{ style: { fontSize: 16, fontWeight: 400 } }}
                defaultValue={serviceData?.serviceName}
                error={serviceError?.serviceName}
                onChange={handleAddService}
                name="serviceName"
                variant="standard"
                helperText={
                  serviceError?.serviceName ? serviceError?.nameMsg : ""
                }
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                }}
              >
                Service Name
              </Typography>
              <TextField
                required
                name="licenseeServiceDescription"
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                }}
                onChange={handleAddService}
                defaultValue={serviceData?.licenseeServiceDescription}
                error={serviceError?.licenseeServiceDescription}
                helperText={
                  serviceError?.licenseeServiceDescription
                    ? serviceError?.descriptionMsg
                    : ""
                }
                inputlabelprops={{ style: { fontSize: 16, fontWeight: 400 } }}
                variant="standard"
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                }}
              >
                Description
              </Typography>

              <FormControl variant="standard" sx={{ width: "100%" }}>
                <Select
                  inputProps={{
                    style: {
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  inputlabelprops={{
                    style: { fontSize: 16, fontWeight: 400 },
                  }}
                  onChange={handleAddService}
                  value={serviceData?.licenseeServiceTypes ?? null}
                  error={serviceError?.licenseeServiceTypes}
                  name="licenseeServiceTypes"
                  IconComponent={NewIcon}
                >
                  {serviceTypeList.length > 0
                    ? serviceTypeList?.map((item, index) => (
                        <MenuItem value={item?.service} key={index}>
                          {item?.service}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
                <FormHelperText
                  sx={{
                    color: "#d32f2f",
                  }}
                >
                  {serviceError?.licenseeServiceTypes
                    ? serviceError?.serviceMsg
                    : ""}
                </FormHelperText>
              </FormControl>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                }}
              >
                Service Type
              </Typography>

              <Box
                sx={{
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                    letterSpacing: "0.5px",
                    color: "rgba(0, 0, 0, 0.87)",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  Supported Products
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {supportProducts && supportProducts.length > 0
                    ? supportProducts.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              onClick={(e) =>
                                handleCheck(e, item.productId, index)
                              }
                              checked={
                                checkedProducts[index] === item.productId
                                  ? true
                                  : false
                              }
                              sx={checkboxStyle}
                              checkedIcon={
                                <CheckBoxOutlinedIcon
                                  sx={{ color: "#01537A" }}
                                />
                              }
                            />
                          }
                          label={item.productName}
                        />
                      ))
                    : null}
                </Box>
                <FormHelperText
                  sx={{
                    color: "#d32f2f",
                  }}
                >
                  {serviceError?.supportedProducts
                    ? serviceError?.productMsg
                    : ""}
                </FormHelperText>
              </Box>

              <Box
                sx={{
                  marginBottom: "15px",
                }}
              >
                {serviceData?.licenseeServiceTypes === "REACH-SWITCH-GATEWAY" ||
                serviceData?.licenseeServiceTypes === "WRAP-MAX-GATEWAY" ? (
                  <Box
                    sx={{
                      marginBottom: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#1B2935",
                      }}
                    >
                      NAT Gateway required?
                    </Typography>

                    <ButtonGroup>
                      <Status
                        sx={{
                          width: { xs: "200px", md: "250px" },
                        }}
                        defaultValue={serviceData?.natGatewayReq}
                        value="1"
                        name="natGatewayReq"
                        startIcon={
                          serviceData?.natGatewayReq === "1" ? <DoneIcon /> : ""
                        }
                        className={
                          serviceData?.natGatewayReq === "1"
                            ? "btn-activeAdd"
                            : "btn-nonactiveAdd"
                        }
                        onClick={handleAddService}
                      >
                        {" "}
                        Yes
                      </Status>

                      <Status
                        sx={{
                          width: { xs: "200px", md: "250px" },
                        }}
                        defaultValue={serviceData?.natGatewayReq}
                        value="0"
                        name="natGatewayReq"
                        startIcon={
                          serviceData?.natGatewayReq === "0" ? <DoneIcon /> : ""
                        }
                        className={
                          serviceData?.natGatewayReq === "0"
                            ? "btn-activeAdd"
                            : "btn-nonactiveAdd"
                        }
                        onClick={handleAddService}
                      >
                        No
                      </Status>
                    </ButtonGroup>
                    <FormHelperText
                      sx={{
                        color: "#d32f2f",
                      }}
                    >
                      {serviceError?.natGatewayReq ? serviceError?.natMsg : ""}
                    </FormHelperText>
                  </Box>
                ) : serviceData?.licenseeServiceTypes === "D-DIRECT" ? (
                  <Box
                    sx={{
                      marginBottom: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#1B2935",
                      }}
                    >
                      Accept Proxy Protocol Headers
                    </Typography>

                    <ButtonGroup>
                      <Status
                        sx={{
                          width: { xs: "188px", sm: "200px", md: "250px" },
                        }}
                        defaultValue={serviceData?.licenseeProxyProtocolHeaders}
                        value="1"
                        name="licenseeProxyProtocolHeaders"
                        startIcon={
                          serviceData?.licenseeProxyProtocolHeaders === "1" ? (
                            <DoneIcon />
                          ) : (
                            ""
                          )
                        }
                        className={
                          serviceData?.licenseeProxyProtocolHeaders === "1"
                            ? "btn-activeAdd"
                            : "btn-nonactiveAdd"
                        }
                        onClick={handleAddService}
                      >
                        {" "}
                        True
                      </Status>

                      <Status
                        sx={{
                          width: { xs: "188px", sm: "200px", md: "250px" },
                        }}
                        defaultValue={serviceData?.licenseeProxyProtocolHeaders}
                        value="0"
                        name="licenseeProxyProtocolHeaders"
                        startIcon={
                          serviceData?.licenseeProxyProtocolHeaders === "0" ? (
                            <DoneIcon />
                          ) : (
                            ""
                          )
                        }
                        className={
                          serviceData?.licenseeProxyProtocolHeaders === "0"
                            ? "btn-activeAdd"
                            : "btn-nonactiveAdd"
                        }
                        onClick={handleAddService}
                      >
                        False
                      </Status>
                    </ButtonGroup>
                    <FormHelperText
                      sx={{
                        color: "#d32f2f",
                      }}
                    >
                      {serviceError?.licenseeProxyProtocolHeaders
                        ? serviceError?.headerMsg
                        : ""}
                    </FormHelperText>

                    <TextField
                      required
                      sx={{ width: "100%", marginTop: "10px" }}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          letterSpacing: 0.5,
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                      inputlabelprops={{
                        style: { fontSize: 16, fontWeight: 400 },
                      }}
                      defaultValue={serviceData?.nonProxyProtocol}
                      error={serviceError?.nonProxyProtocol}
                      onChange={handleAddService}
                      name="nonProxyProtocol"
                      variant="standard"
                      helperText={
                        serviceError?.nonProxyProtocol
                          ? serviceError?.nonProxyMsg
                          : ""
                      }
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        letterSpacing: "0.4px",
                        color: "#01537A",
                        marginTop: "3px",
                      }}
                    >
                      Max Enpoints Non Proxy Protocol
                    </Typography>

                    <TextField
                      required
                      sx={{ width: "100%", marginTop: "10px" }}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          letterSpacing: 0.5,
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                      inputlabelprops={{
                        style: { fontSize: 16, fontWeight: 400 },
                      }}
                      defaultValue={serviceData?.proxyProtocol}
                      error={serviceError?.proxyProtocol}
                      onChange={handleAddService}
                      name="proxyProtocol"
                      variant="standard"
                      helperText={
                        serviceError?.proxyProtocol
                          ? serviceError?.proxyMsg
                          : ""
                      }
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        letterSpacing: "0.4px",
                        color: "#01537A",
                        marginTop: "3px",
                      }}
                    >
                      Max Enpoints Proxy Protocol
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              <Box>
                <Box
                  sx={{
                    marginBottom: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#1B2935",
                    }}
                  >
                    Zero Rated
                  </Typography>

                  <ButtonGroup>
                    <Status
                      sx={{
                        width: { xs: "188px", sm: "200px", md: "250px" },
                      }}
                      defaultValue={serviceData?.licenseeServiceZerorated}
                      value="1"
                      name="licenseeServiceZerorated"
                      startIcon={
                        serviceData?.licenseeServiceZerorated === "1" ? (
                          <DoneIcon />
                        ) : (
                          ""
                        )
                      }
                      className={
                        serviceData?.licenseeServiceZerorated === "1"
                          ? "btn-activeAdd"
                          : "btn-nonactiveAdd"
                      }
                      onClick={handleAddService}
                    >
                      {" "}
                      Yes
                    </Status>

                    <Status
                      sx={{
                        width: { xs: "188px", sm: "200px", md: "250px" },
                      }}
                      defaultValue={serviceData?.licenseeServiceZerorated}
                      value="0"
                      name="licenseeServiceZerorated"
                      startIcon={
                        serviceData?.licenseeServiceZerorated === "0" ? (
                          <DoneIcon />
                        ) : (
                          ""
                        )
                      }
                      className={
                        serviceData?.licenseeServiceZerorated === "0"
                          ? "btn-activeAdd"
                          : "btn-nonactiveAdd"
                      }
                      onClick={handleAddService}
                    >
                      No
                    </Status>
                  </ButtonGroup>
                  <FormHelperText
                    sx={{
                      color: "#d32f2f",
                    }}
                  >
                    {serviceError?.licensee_service_zerorated
                      ? serviceError?.zeroRatedMsg
                      : ""}
                  </FormHelperText>
                </Box>
                <Box
                  sx={{
                    marginBottom: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#1B2935",
                    }}
                  >
                    Status
                  </Typography>

                  <ButtonGroup>
                    <Status
                      sx={{
                        width: { xs: "188px", sm: "200px", md: "250px" },
                      }}
                      defaultValue={serviceData?.licenseeServiceStatus}
                      name="licenseeServiceStatus"
                      startIcon={
                        serviceData?.licenseeServiceStatus === "LIVE" ? (
                          <DoneIcon />
                        ) : (
                          ""
                        )
                      }
                      className={
                        serviceData?.licenseeServiceStatus === "LIVE"
                          ? "btn-activeAdd"
                          : "btn-nonactiveAdd"
                      }
                      value="LIVE"
                      onClick={handleAddService}
                    >
                      Live
                    </Status>

                    <Status
                      sx={{
                        width: { xs: "188px", sm: "200px", md: "250px" },
                      }}
                      defaultValue={serviceData?.licenseeServiceStatus}
                      name="licenseeServiceStatus"
                      startIcon={
                        serviceData?.licenseeServiceStatus === "OFF" ? (
                          <DoneIcon />
                        ) : (
                          ""
                        )
                      }
                      className={
                        serviceData?.licenseeServiceStatus === "OFF"
                          ? "btn-activeAdd"
                          : "btn-nonactiveAdd"
                      }
                      value="OFF"
                      onClick={handleAddService}
                    >
                      Off
                    </Status>
                  </ButtonGroup>
                  <FormHelperText
                    sx={{
                      color: "#d32f2f",
                    }}
                  >
                    {serviceError?.licenseeServiceStatus
                      ? serviceError?.statusMsg
                      : ""}
                  </FormHelperText>
                </Box>
              </Box>
              <Divider
                sx={{
                  alignContent: "center",
                  color: "#DFDFDF",
                  top: "10px",
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px 20px 30px 20px",
                gap: 2,
              }}
            >
              <Button
                className="cancel-btn"
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                sx={{
                  "&.Mui-disabled": {
                    cursor: "not-allowed !important",
                    pointerEvents: "unset !important",
                  },
                }}
                className="save-btn"
                disabled={licenseeClick}
                onClick={handleSave}
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openGateway}
          onClose={handleClose}
          scroll={"body"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <Typography
                sx={{
                  color: "#01537A",
                  fontWeight: "400",
                  fontSize: { xs: "17px", md: "24px" },
                  lineHeight: "26px",
                }}
              >
                Company Specific Gateway Domain Creation
              </Typography>
              <CloseSharpIcon
                onClick={handleCloseGateway}
                sx={{
                  color: "#757373",
                  cursor: "pointer",
                }}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: { xs: "400px", md: "500px" },
                width: "100%",
                rowGap: "4px",
                marginTop: "8px",
              }}
              autoComplete="off"
            >
              <TextField
                required
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                }}
                inputlabelprops={{ style: { fontSize: 16, fontWeight: 400 } }}
                defaultValue={gatewayData?.companyName}
                error={gatewayError?.companyName}
                onChange={handleGateway}
                name="companyName"
                variant="standard"
                helperText={
                  gatewayError?.companyName ? gatewayError?.companyMsg : ""
                }
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                }}
              >
                Company Name
              </Typography>

              <FormControl variant="standard" sx={{ width: "100%" }}>
                <Select
                  inputProps={{
                    style: {
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  inputlabelprops={{
                    style: { fontSize: 16, fontWeight: 400 },
                  }}
                  onChange={handleGateway}
                  value={gatewayData?.managedBy ?? null}
                  name="managedBy"
                  IconComponent={NewIcon}
                >
                  <MenuItem value="D-Direct">D-Direct</MenuItem>
                  <MenuItem value="Connect">Connect</MenuItem>
                </Select>
                <FormHelperText
                  sx={{
                    color: "#d32f2f",
                  }}
                >
                  {gatewayError?.managedBy ? gatewayError?.managedByMsg : ""}
                </FormHelperText>
              </FormControl>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                }}
              >
                Managed By
              </Typography>

              <FormControl variant="standard" sx={{ width: "100%" }}>
                <Select
                  inputProps={{
                    style: {
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  inputlabelprops={{
                    style: { fontSize: 16, fontWeight: 400 },
                  }}
                  onChange={handleGateway}
                  value={gatewayData?.gatewayDomain ?? null}
                  name="gatewayDomain"
                  IconComponent={NewIcon}
                >
                  <MenuItem value="D-Direct">D-Direct</MenuItem>
                  <MenuItem value="Connect">Connect</MenuItem>
                </Select>
                <FormHelperText
                  sx={{
                    color: "#d32f2f",
                  }}
                >
                  {gatewayError?.gatewayDomain
                    ? gatewayError?.gatewayDomainMsg
                    : ""}
                </FormHelperText>
              </FormControl>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                }}
              >
                #datafree Gateway Domain
              </Typography>
              <TextField
                required
                name="description"
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                }}
                onChange={handleGateway}
                defaultValue={gatewayData?.description}
                error={gatewayError?.description}
                helperText={
                  gatewayError?.description ? gatewayError?.descriptionMsg : ""
                }
                inputlabelprops={{ style: { fontSize: 16, fontWeight: 400 } }}
                variant="standard"
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                }}
              >
                Description
              </Typography>

              <Box
                sx={{
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                    letterSpacing: "0.5px",
                    color: "rgba(0, 0, 0, 0.87)",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  Points to Licensee Services
                </Typography>
              </Box>

              <FormControl variant="standard" sx={{ width: "100%" }}>
                <Select
                  inputProps={{
                    style: {
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 0.5,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  inputlabelprops={{
                    style: { fontSize: 16, fontWeight: 400 },
                  }}
                  onChange={handleGateway}
                  value={gatewayData?.servicesName ?? null}
                  name="servicesName"
                  IconComponent={NewIcon}
                >
                  <MenuItem value="D-Direct">D-Direct</MenuItem>
                  <MenuItem value="Connect">Connect</MenuItem>
                </Select>
                <FormHelperText
                  sx={{
                    color: "#d32f2f",
                  }}
                >
                  {gatewayError?.servicesName ? gatewayError?.servicesMsg : ""}
                </FormHelperText>
              </FormControl>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: "#01537A",
                }}
              >
                Licensee Services Name
              </Typography>

              <Box>
                <Box
                  sx={{
                    marginBottom: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#1B2935",
                    }}
                  >
                    Status
                  </Typography>

                  <ButtonGroup>
                    <Status
                      sx={{
                        width: { xs: "188px", sm: "200px", md: "250px" },
                      }}
                      defaultValue={gatewayData?.status}
                      name="status"
                      startIcon={
                        gatewayData?.status === "Live" ? <DoneIcon /> : ""
                      }
                      className={
                        gatewayData?.status === "Live"
                          ? "btn-activeAdd"
                          : "btn-nonactiveAdd"
                      }
                      onClick={() => GatewayUpdate("Live", "status")}
                    >
                      Live
                    </Status>

                    <Status
                      sx={{
                        width: { xs: "188px", sm: "200px", md: "250px" },
                      }}
                      defaultValue={gatewayData?.status}
                      name="status"
                      startIcon={
                        gatewayData?.status === "Off" ? <DoneIcon /> : ""
                      }
                      className={
                        gatewayData?.status === "Off"
                          ? "btn-activeAdd"
                          : "btn-nonactiveAdd"
                      }
                      onClick={() => GatewayUpdate("Off", "status")}
                    >
                      Off
                    </Status>
                  </ButtonGroup>
                  <FormHelperText
                    sx={{
                      color: "#d32f2f",
                    }}
                  >
                    {gatewayError?.status ? gatewayError?.statusMsg : ""}
                  </FormHelperText>
                </Box>
              </Box>

              <Box
                sx={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                <Divider
                  sx={{
                    alignContent: "center",
                    color: "#DFDFDF",
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px 20px 30px 20px",
                gap: 2,
              }}
            >
              <Button
                className="cancel-btn"
                onClick={handleCloseGateway}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                className="cancel-btn"
                onClick={handleGatewaySave}
                variant="contained"
                sx={{
                  backgroundColor: "#01537A!important",
                  color: "#fff!important",
                }}
              >
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Layout>
    </CookiesProvider>
  );
}
