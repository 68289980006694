import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function Applications({ applicationDetails }) {
  const applicationsGrid =
    applicationDetails?.data?.securityRules?.includedApps;
  const rows = applicationsGrid.map((item, index) => ({
    id: index + 1,
    name: item.label || "-",
    googleAppId: item.id || "-",
    sideloadUrl: item.sideloadUrl || "-",
  }));

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 170,
      headerAlign: "center",
      align: "center",
      headerClassName: "data-grid-header",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography>{params.row.name ? params.row.name : "-"}</Typography>
      ),
    },
    {
      field: "googleAppId",
      headerName: (
        <Box display="flex" alignItems="center">
          Google App ID
          <Tooltip title="This is the Google Application ID" arrow>
            <HelpOutlineIcon
              style={{ marginLeft: 5, fontSize: 16, color: "white" }}
            />
          </Tooltip>
        </Box>
      ),
      width: 300,
      headerAlign: "center",
      align: "center",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography>
          {params.row.googleAppId ? params.row.googleAppId : "-"}
        </Typography>
      ),
      headerClassName: "data-grid-header",
    },
    {
      field: "sideloadUrl",
      headerName: "Sideload URL",
      width: 700,
      headerAlign: "center",
      align: "center",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography>
          {params.row.sideloadUrl ? params.row.sideloadUrl : "-"}
        </Typography>
      ),
      headerClassName: "data-grid-header",
    },
  ];

  return (
    <Box sx={{ height: 400, width: "100%", marginBottom: "50px" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        pagination
        sx={{
          "& .data-grid-header": {
            backgroundColor: "#01537A",
            color: "#FFFFFF",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#01537A",
          },

          "& .MuiDataGrid-columnHeader--sorted": {
            color: "black",
          },

          bgcolor: "#fff",
          borderRadius: "1px",
          padding: "0px",
          overflow: "none",
          border: "1px solid transparent",
          borderBottom: "1.14491px solid rgba(0, 0, 0, 0.12)",
          "& .Mui-focused ": {
            borderRadius: "0",
          },

          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-row": {
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& .MuiDataGrid-cell": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 500,
            color: "#fff",
            fontSize: "15px",
            zIndex: 9999,
          },
        }}
      />
    </Box>
  );
}
