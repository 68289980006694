import React, { useState, useEffect, useContext } from "react";
import DataTable from "./dataTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import PaginationHeader from "./paginationHeader";
import PaginationRounded from "../pagination";
import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import Loader from "../../components/loader";
import Service from "../../api/services";
import useToast from "../../hooks/useToast";
import {
  statusContext,
  switchStatusContext,
  archivedStatusContext,
} from "../../context/serviceContext";

const nameComparator = (v1, v2, param1, param2) => {
  if (v1.vals !== "" && v2.vals !== "") {
    return gridStringOrNumberComparator(v1.vals, v2.vals, param1, param2);
  }
};

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      boxShadow: 2,
      border: "none",
      maxWidth: "150px",
      color: "#000 !important",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderTopRightRadius: "6px",
      borderTopLeftRadius: "6px",
      marginTop: "10px",
      "& .Mui-focused ": {
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
        color: "#000 !important",
      },
      "& .MuiMenuItem-root": {
        color: "#000",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        fontSize: "16px",
        backgroundColor: "transparent",
        padding: "5px",
      },
      "& .MuiMenuItem-root: last-child": {
        color: "#000",
        borderBottom: "none",
        fontSize: "16px",
        backgroundColor: "#fff",
        "&:focus": {
          color: "#01537A",
        },
      },
      "& .Mui-selected:hover": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .Mui-selected": {
        color: "#01537A",
        backgroundColor: "transparent !important",
      },
      "& .MuiBox-root:hover": {
        backgroundColor: "rgba(1, 83, 122, 0.1)",
        borderRadius: "6px",
        borderBottom: "none",
      },
      "& .MuiBox-root": {
        padding: "8px 10px",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: 2,
        paddingTop: "15px",
        borderRadius: "6px",
      },
      "& .MuiList-root": {
        padding: "0 15px",
      },
    },
  },
};

const NewIcon = (props) => (
  <KeyboardArrowDownIcon {...props} sx={{ color: "#01537A !important" }} />
);

export default function GatewayDomainPanel(props) {
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState();
  const userRole = localStorage.getItem("role");
  const { showSuccess, showError } = useToast();
  const [items, setItems] = useState([]);
  const handleStatus = (gatewayName, value) => {
    const result = items.find(({ gateway }) => gateway === gatewayName);
    if (result !== undefined) {
      result["status"] = value;
    } else {
      setItems([
        ...items,
        {
          gateway: gatewayName,
          status: value,
        },
      ]);
    }
  };
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const changePagination = (event, value) => {
    setPage(value - 1);
  };

  const handlePageSize = (e) => {
    setSize(e.target.value);
    setPage(0);
  };
  const [saveClick, setSaveClick] = useState(false);
  const handleSave = () => {
    setSaveClick(true);
    const newObject = {
      item: items,
      editorId: localStorage.getItem("userid"),
    };
    Service.updateGateway(newObject)
      .then((res) => {
        if (res.data.status === "success") {
          showSuccess(res.data.message);
        } else if (res.data.status === "failed") {
          showError(res.data.message);
        }
        setSaveClick(false);
        setItems([]);
        cancelUpdate();
      })
      .catch((err) => {});
  };

  const cancelUpdate = () => {
    getGatewayDetails();
  };

  const [totalCount, setTotalCount] = useState(0);

  const location = useLocation();
  const clickedCompId = location.state?.clickedCompId;
  async function getGatewayDetails() {
    setLoading(true);
    Service.gateWayDomain({ licenseeId: clickedCompId }, page, size)
      .then((res) => {
        setResData(res?.data.domainDeatails);
        setTotalCount(res?.data.totalCount);
        setLoading(false);
      })
      .catch((err) => {});
  }

  const accessControls = () => {
    if (userRole === "ROLE_ADMIN") {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionFields = () => {
    if (archivedStatusContextVal) {
      return true;
    } else {
      return false;
    }
  };

  const accessPermissionButton = (btnName) => {
    if (archivedStatusContextVal) {
      return `${btnName}-btn disable`;
    } else {
      return `${btnName}-btn`;
    }
  };

  useEffect(() => {
    getGatewayDetails();
    //eslint-disable-next-line
  }, [size, page]);
  const textStyle = {
    color: "#231F20",
    fontWeight: 400,
    fontSize: "14px",
    textOverflow: "clip",
    whiteSpace: "break-spaces",
  };
  const columns = [
    {
      flex: 1,
      field: "name",
      headerName: "#datafree Gateway Domain",
      width: 230,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.gateway,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return <Box sx={textStyle}>{params.row.gateway}</Box>;
      },
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 220,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.description,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return <Box sx={textStyle}>{params.row.description}</Box>;
      },
    },

    {
      flex: 1,
      field: "status",
      headerName: "Status",
      width: 140,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.status,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <>
            {accessControls() && !accessPermissionFields() ? (
              <FormControl
                variant="standard"
                sx={{
                  minWidth: 100,
                  backgroundColor: "transparent !important",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "400",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
                <Select
                  sx={{ backgroundColor: "transparent !important" }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  defaultValue={params.row.status === "LIVE" ? "LIVE" : "OFF"}
                  onChange={(e) =>
                    handleStatus(params.row.gateway, e.target.value)
                  }
                  MenuProps={MenuProps}
                  disableUnderline={true}
                  IconComponent={NewIcon}
                >
                  <MenuItem value="LIVE">Live</MenuItem>
                  <MenuItem value="OFF">Off</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <span>{params.row.status}</span>
            )}
          </>
        );
      },
    },

    {
      flex: 1,
      field: "useForProducts",
      headerName: "Use For products",
      width: 180,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals: row.products && row.products.length > 0 ? row.products[0] : "-",
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            {params.row.products && params.row.products.length > 0
              ? params.row.products.map((item, index) => (
                  <span style={textStyle} key={index}>
                    {item}
                  </span>
                ))
              : "-"}
          </div>
        );
      },
    },

    {
      flex: 1,
      field: "pointsToService",
      headerName: " Points to Service",
      width: 190,
      headerClassName: "super-app-theme--header",
      valueGetter: (value, row) => ({
        vals:
          row.pointService && row.pointService.length > 0
            ? row.pointServices[0]
            : "-",
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        return (
          <div>
            {params.row.pointService && params.row.pointService.length > 0
              ? params.row.pointService.map((item, index) => (
                  <span style={textStyle} key={index}>
                    {item}
                  </span>
                ))
              : "-"}
          </div>
        );
      },
    },
  ];

  return (
    <Box>
      <Container
        maxWidth="xxl"
        style={{
          padding: "0",
        }}
      >
        {resData && !loading ? (
          <>
            {totalCount !== 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  pb: "5px",
                  pr: "2px",
                }}
              >
                <PaginationHeader
                  page={page}
                  size={size}
                  dataLength={resData.length}
                  totalCount={totalCount}
                />
              </Box>
            ) : null}
            <Box
              sx={{
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor:
                    switchStatusContextVal && statusContextVal
                      ? "#373737"
                      : "#01537A !important",
                  color: "white",
                },
              }}
            >
              <DataTable
                rows={resData}
                columns={columns}
                size={resData.length}
                tableName="usersPanel"
                loading={loading}
              />
            </Box>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Loader
              type={"spokes"}
              color={"#01537A"}
              delay={0}
              height={40}
              width={40}
            />
          </Box>
        )}

        {!loading && totalCount >= 10 && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Typography
              component="span"
              variant="body2"
              sx={{ color: "#01537A" }}
            >
              Items per page :{" "}
            </Typography>
            <FormControl variant="standard">
              <Select
                value={size}
                sx={{
                  width: "55px",
                  margin: "0px 10px 0px 5px",
                  backgroundColor: "#8080802b",
                  color: "#000",
                  borderBottom: "1px solid #01537A",
                  textAlign: "center",
                  "&:after": {
                    borderBottom: "1.5px solid #01537A",
                  },
                  "&hover": {
                    borderBottom: "1px solid #01537A",
                  },
                }}
                onChange={handlePageSize}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>{Number(10).toLocaleString()}</MenuItem>
                <MenuItem value={25}>{Number(25).toLocaleString()}</MenuItem>
                <MenuItem value={50}>{Number(50).toLocaleString()}</MenuItem>
                <MenuItem value={100}>{Number(100).toLocaleString()}</MenuItem>
              </Select>
            </FormControl>
            <PaginationRounded
              changeHandle={changePagination}
              page={page + 1}
              Count={totalCount}
              size={size}
            />
          </Box>
        )}
        {!loading && totalCount > 0 && accessControls() && (
          <Box
            sx={{
              padding: "20px 0px 10px 0px",
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              className={accessPermissionButton("cancel")}
              variant="outlined"
              onClick={cancelUpdate}
            >
              Cancel
            </Button>

            <Button
              className={accessPermissionButton("save")}
              sx={{
                "&.Mui-disabled": {
                  cursor: "not-allowed !important",
                  pointerEvents: "unset !important",
                },
              }}
              variant="contained"
              disabled={saveClick}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
}
