import React, { useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import Filter from "../assets/img/filter.svg";
export default function AdvancedFilterLabel({ sentToFilter }) {
  const [advanceClosed, setAdvanceClosed] = useState(false);
  return (
    <Paper
      elevation={2}
      sx={{
        width: { xs: "20%", sm: "30%", md: "20%" },
        height: "fit-content",
        padding: "10px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        boxShadow: !advanceClosed
        ? "1px 0px 0px 0px rgba(0,0,0,0.1), 0px 0px 0px 0px rgba(0,0,0,0.14), -1px -1px 0px 0px rgba(0,0,0,0.1)"
        : "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom:  !advanceClosed && "1px solid #01537a",
          height: advanceClosed? "43px":"49px",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <img
            component="img"
            style={{
              height: 20,
              width: 20,
              marginRight: 10,
              marginLeft: 0,
              marginTop: 8,
            }}
            alt="Filter"
            src={Filter}
          />
          <Typography
            sx={{
              fontSize: "24px",
              color: "#01537A",
              fontWeight: 700,
            }}
          >
            Filters
          </Typography>
        </Box>
        {advanceClosed ? (
          <KeyboardArrowDownOutlinedIcon
            onClick={() => {
              sentToFilter(false);
              setAdvanceClosed(false);
            }}
            sx={{
              marginRight: "10px",
              marginTop: "7px",
              cursor: "pointer",
            }}
          />
        ) : (
          <KeyboardArrowUpOutlinedIcon
            onClick={() => {
              sentToFilter(true);
              setAdvanceClosed(true);
            }}
            sx={{
              marginRight: "10px",
              marginTop: "7px",
              cursor: "pointer",
            }}
          />
        )}
      </Box>
    </Paper>
  );
}
