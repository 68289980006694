import axios from "axios";
import { API_URL } from "./constants";
import { toast } from "react-toastify";
import { WarningMessages } from "../config/messages";

const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
const CONGNITO_APP_CLIENT_ID = process.env.REACT_APP_CONGNITO_APP_CLIENT_ID;
const tokenEndPoint = "https://" + COGNITO_DOMAIN + "/oauth2/token";
const refreshToken = sessionStorage.getItem("refresh_token");
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

axios.defaults.withCredentials = true;

const instance = axios.create({
  withCredentials: true,
  baseURL: `${API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

//Request Intercepter
instance.interceptors.request.use(
  (request) => {
    const access_token = sessionStorage.getItem("access_token");
    const idToken = sessionStorage.getItem("id_token");

    if (!access_token) {
      request.warn("Access token is missing or invalid");
      return request;
    } else {
      request.headers.Authorization = `Bearer ${access_token}`;
      request.headers["X-API-IDENTITY"] = idToken;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "The ID token has expired."
    ) {
      if (!refreshToken) {
        return Promise.reject(error);
      }
      try {
        const response = await refreshTokens();
        error.config.headers.Authorization = `Bearer ${response?.data?.access_token}`;
        error.config.headers["X-API-IDENTITY"] = response.data.id_token;
        const retryResponse = await axios.request(error.config);
        return retryResponse;
      } catch (error) {
        return Promise.reject(error);
      }
    } else if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "Invalid credentials."
    ) {
      toast.warning(
        error.response.data.message
          ? error.response.data.message
          : WarningMessages?.invalidCredential
      );
      setTimeout(logout, 4000);
    } else {
      return Promise.reject(error);
    }
  }
);

//logOut
async function logout() {
  localStorage.clear();
  sessionStorage.clear();
  window.location.href =
    "https://" +
    COGNITO_DOMAIN +
    "/logout?client_id=" +
    CONGNITO_APP_CLIENT_ID +
    "&logout_uri=" +
    REDIRECT_URL;
}
// Refresh the tokens
async function refreshTokens() {
  try {
    const response = await axios.post(
      tokenEndPoint,
      `grant_type=refresh_token&client_id=${CONGNITO_APP_CLIENT_ID}&refresh_token=${refreshToken}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    sessionStorage.setItem("access_token", response?.data?.access_token);
    sessionStorage.setItem("id_token", response?.data?.id_token);
    return response;
  } catch (error) {
    if (error.response) {
      if (
        error.response.status === 400 &&
        (error.response.data.error === "unsupported_grant_type" ||
          error.response.data.error === "invalid_grant")
      ) {
        console.error("Refresh token is invalid. Please log in again.");
      }
    }
  }
}
export default instance;
