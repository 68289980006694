import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment,
  Typography,
} from "@mui/material";

import {
  statusContext,
  archivedStatusContext,
  switchStatusContext,
} from "../../../../context/serviceContext";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useLocation } from "react-router-dom";
import Services from "../../../../api/services";
import useToast from "../../../../hooks/useToast";
import Loader from "../../../../components/loader";
import { WarningMessages } from "../../../../config/messages";
import { regexPatterns } from "../../../../config/regex";

export default function WrapSecurity({
  applicationDetails,
  refreshApi,
  appDetailsRefresh,
}) {
  const [clickDisable, setClickDisable] = useState(true);
  const [load, setLoad] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [cancelDisable, setCancelDisable] = useState(true);
  const [userData, setUserData] = useState({
    maxBytes: "",
    testWhitelist: "",
    useWhiteList: 0,
  });

  const [userError, setUserError] = useState({
    maxBytes: false,
    maxBytesMsg: "",
    testWhitelist: false,
    testWhitelistMsg: "",
  });

  const location = useLocation();
  const clickedAppId = location.state?.clickedAppId;
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );

  const { showSuccess } = useToast();

  const handleInputChange = (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    setCancelDisable(false);
    if (e.target.name === "maxBytes") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          maxBytes: false,
          maxBytesMsg: "",
        });
        setClickDisable(false);
      } else {
        if (regexPatterns.maxBytesRegex.test(e.target.value) === false) {
          setUserError({
            ...userError,
            maxBytes: true,
            maxBytesMsg: "only digits accepted",
          });
          setClickDisable(true);
        } else {
          setUserError({
            ...userError,
            maxBytes: false,
            maxBytesMsg: "",
          });
          setClickDisable(false);
        }
      }
    } else if (e.target.name === "testWhitelist") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          testWhitelist: false,
          testWhitelistMsg: "",
        });
        setClickDisable(false);
      } else {
        const hasInvalidLine = e.target.value
          .split("\n")
          .some((line) => !regexPatterns.testWhitelistRegex.test(line));
        if (hasInvalidLine) {
          setUserError({
            ...userError,
            testWhitelist: true,
            testWhitelistMsg: "Please enter a test whitelist",
          });
          setClickDisable(true);
        } else {
          setUserError({
            ...userError,
            testWhitelist: false,
            testWhitelistMsg: "",
          });
          setClickDisable(false);
        }

        const alertEmailList = newData.testWhitelist.split("\n");
        alertEmailList.pop();
        const enteredEmail = e.target.value.split("\n");
        if (alertEmailList.includes(enteredEmail[enteredEmail.length - 1])) {
          newData[e.target.name] = alertEmailList.join("\n");
        }
      }
    }
    setUserData(newData);
  };
  const handleChange = (event) => {
    setCancelDisable(false);
    const value = event.target.checked ? 1 : 0;
    setClickDisable(false);
    setUserData({
      ...userData,
      useWhiteList: value,
    });
  };
  const handleSave = () => {
    const type = "DEV";
    const applicationId = clickedAppId;

    let payload = {
      testWhiteList: userData?.testWhitelist,
      useTestWhitelist: userData?.useWhiteList,
      maxBytesContent: userData?.maxBytes,
      applicationType: "wrap",
    };
    setDisableButton(true);
    setLoad(true);
    Services.updateWrapSecurityRules(type, applicationId, payload)
      .then((res) => {
        if (res?.data.status === "success") {
          showSuccess(WarningMessages?.updated);

          setDisableButton(false);
          appDetailsRefresh(true);
        }
        handleCancel();
      })
      .catch((err) => {});
    appDetailsRefresh(false);
  };

  const handleCancel = () => {
    setUserError({
      maxBytes: false,
      maxBytesMsg: "",
      testWhitelist: false,
      testWhitelistMsg: "",
    });
    getSecurityDetails();
    setLoad(false);
    setClickDisable(true);
    setDisableButton(false);
    setCancelDisable(true);
  };

  async function getSecurityDetails() {
    setLoading(true);
    Services.getSecurityTabDetails({
      applicationId: clickedAppId,
      deploymentType: "DEV",
    })
      .then((res) => {
        setLoading(false);
        setUserData({
          ...userData,
          maxBytes: res?.data?.maxBytesContent
            ? res?.data?.maxBytesContent
            : "",
          testWhitelist: res?.data?.whiteList ? res?.data?.whiteList : "",
          useWhiteList: res?.data?.useWhiteList,
        });
      })
      .catch((err) => {});
  }

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (refreshApi) {
      setTriggerRefresh(true);
    }
  }, [refreshApi]);

  useEffect(() => {
    if (triggerRefresh) {
      setLoading(true);
      getSecurityDetails();
      setTriggerRefresh(false);
    }
    // eslint-disable-next-line
  }, [triggerRefresh]);

  useEffect(() => {
    getSecurityDetails();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" mt="2px">
        {loading ? (
          <Loader type={"spokes"} color={"#01537A"} height={40} width={40} />
        ) : null}
      </Box>
      <Box
        sx={{
          width: {
            xs: "50px",
            sm: "273px",
            md: "500px",
            lg: "700px",
            xl: "1000px",
          },
        }}
      >
        <TextField
          sx={{
            width: "100%",
            "& input::placeholder": {
              color: "#757373",
              fontSize: "14px",
              opacity: "1",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!accessPermissionFields() && (
                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                )}
              </InputAdornment>
            ),
            readOnly: accessPermissionFields() && true,
          }}
          value={userData?.maxBytes}
          error={!!userError?.maxBytes}
          onChange={handleInputChange}
          placeholder="Max Bytes per content object"
          name="maxBytes"
          variant="standard"
          helperText={userError?.maxBytes ? userError?.maxBytesMsg : ""}
        />
        {userData?.maxBytes && (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              letterSpacing: "0.4px",
              color: "#01537A",
            }}
          >
            Max Bytes per content object
          </Typography>
        )}
        <FormGroup>
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                color: userData.useWhiteList ? "black" : "#757373",
              },
            }}
            checked={Boolean(userData.useWhiteList)}
            name="useWhiteList"
            onChange={handleChange}
            control={<Checkbox />}
            label="use WhiteList"
            disabled={accessPermissionFields()}
          />
        </FormGroup>
        <TextField
          sx={{
            width: "100%",
            "& textarea::placeholder": {
              color: "#757373!important",
              fontSize: "14px",
              opacity: "1",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ alignSelf: "flex-end", marginBottom: "7px" }}
              >
                {!accessPermissionFields() && (
                  <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                )}
              </InputAdornment>
            ),
            readOnly: accessPermissionFields() && true,
          }}
          value={userData?.testWhitelist}
          error={!!userError?.testWhitelist}
          onChange={handleInputChange}
          name="testWhitelist"
          multiline
          maxRows={Infinity}
          variant="standard"
          placeholder="Test Whitelist"
          helperText={
            userError?.testWhitelist ? userError?.testWhitelistMsg : ""
          }
        />
        {userData?.testWhitelist && (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              letterSpacing: "0.4px",
              color: "#01537A",
            }}
          >
            Test Whitelist
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          width: {
            xs: "50px",
            sm: "273px",
            md: "622px",
            lg: "867px",
            xl: "1172px",
          },
          gap: "10px",
          justifyContent: "space-between",
          paddingTop: "30px",
          paddingRight: "20px",
          paddingBottom: "30px",
        }}
      >
        <Button
          className={accessPermissionFields() ? "disable" : null}
          onClick={handleCancel}
          disabled={cancelDisable}
          sx={{
            "&.Mui-disabled": {
              color: "#757373",
              textTransform: "capitalize",
              padding: " 5px 40px 5px 40px",
              borderColor: "#757373 !important",
            },
            padding: " 5px 40px 5px 40px",
            width: "fit-content",
            color: accessPermissionFields() ? "#373737" : "#01537A",
            borderColor: accessPermissionFields()
              ? "#373737"
              : "#01537A !important",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          className={
            accessPermissionFields() || disableButton ? "disable" : null
          }
          onClick={handleSave}
          disabled={clickDisable}
          sx={{
            padding: " 0px 50px 0px 50px",
            width: "fit-content",
            background: accessPermissionFields()
              ? "#373737!important"
              : "#01537A !important",
            textTransform: "capitalize",
            "&.Mui-disabled": {
              background: "#757373 !important",
              color: "white",
              textTransform: "capitalize",
              padding: "0px 50px",
            },
          }}
          variant="contained"
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <span style={{ marginRight: "5px" }}>Save</span>
            {load ? (
              <Loader
                type={"spinningBubbles"}
                color={"white"}
                height={20}
                width={20}
              />
            ) : (
              ""
            )}
          </Box>
        </Button>
      </Box>
    </>
  );
}
