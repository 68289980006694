import { Typography, Divider, Button, Box } from "@mui/material";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Services from "../../api/services";
import { useLocation } from "react-router-dom";
import Loader from "../../components/loader";
import { WarningMessages } from "../../config/messages";
import useToast from "../../hooks/useToast";

export default function ConfigPopUp({
  open,
  handleClose,
  productType,
  onDataFromChild,
}) {
  const handleClear = () => {
    handleClose();
    setLoad(false);
  };

  const { showSuccess } = useToast();

  const location = useLocation();
  const selectedAppId = location.state.clickedAppId;
  const [disableButton, setDisableButton] = useState(false);
  const [load, setLoad] = useState(false);

  const handleSave = () => {
    setLoad(true);
    setDisableButton(true);
    Services.cloneApplication({
      applicationId: selectedAppId,
      cloningFrom: "PROD",
      applicationType: productType.split(" ")[1].toLowerCase(),
    })
      .then((res) => {
        if (res?.data.status === "success") {
          handleClose();
          showSuccess(WarningMessages?.configCloned);
          setDisableButton(false);
          setLoad(false);
          onDataFromChild(0);
        }
      })
      .catch((err) => {});
  };

  return (
    <Dialog
      open={open}
      onClose={handleClear}
      maxWidth="xl"
      scroll={"body"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            color: "#01537A",
            fontSize: "24px",
          }}
        >
          {" "}
          Clone this Configuration to Development.
        </Typography>

        <CloseOutlinedIcon
          onClick={handleClear}
          sx={{ color: "#757373", fontSize: "20px", cursor: "pointer" }}
        />
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "16px",
            color: "#333333",
            marginBottom: "30px",
            marginTop: "10px",
          }}
        >
          Cloning this config to development will duplicate all the settings you
          have set out here to development.
        </Typography>

        <Divider
          sx={{
            color: "#DFDFDF",
            backgroundColor: "#FFFFFF",
            borderRadius: "6px",
            borderBottomWidth: 1,
            marginTop: "40px",
          }}
        ></Divider>
      </DialogContent>
      <DialogActions
        sx={{
          paddingBottom: "20px",
          paddingRight: "30px",
          gap: "27px",
        }}
      >
        <Button
          onClick={handleClear}
          sx={{
            padding: " 5px 50px 5px 50px",
            width: "fit-content",
            color: "#01537A",
            borderColor: "#01537A !important",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          className={disableButton ? "disable" : null}
          onClick={handleSave}
          sx={{
            padding: "5px 50px 5px 50px",
            width: "fit-content",
            background: "#01537A !important",
            textTransform: "capitalize",
            "&.Mui-disabled": {
              background: "#757373 !important",
              color: "white",
              textTransform: "capitalize",
              padding: "0px 50px",
            },
          }}
          variant="contained"
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <span style={{ marginRight: "5px" }}>Clone</span>
            {load ? (
              <Loader
                type={"spinningBubbles"}
                color={"white"}
                height={20}
                width={20}
              />
            ) : (
              ""
            )}
          </Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
