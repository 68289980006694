import {
  Box,
  Container,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Services from "../../../api/services";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/loader";
import {
  statusContext,
  archivedStatusContext,
  switchStatusContext,
} from "../../../context/serviceContext";
import Tooltips from "../../../components/tooltips";
import useToast from "../../../hooks/useToast";
import { regexPatterns } from "../../../config/regex";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: " 10px", paddingLeft: "20px" }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const tabStyle = {
  color: "#01537A",
  fontWeight: 500,

  "&.MuiSelected": {
    color: "#01537A",
  },
};

export default function Development({
  applicationDetails,
  refreshApi,
  appDetailsRefresh,
}) {
  const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
  const statusContextVal = JSON.parse(useContext(statusContext));
  const archivedStatusContextVal = JSON.parse(
    useContext(archivedStatusContext)
  );
  const [value, setValue] = React.useState(0);
  const [load, setLoad] = useState(false);
  const [clickDisable, setClickDisable] = useState(true);
  const [cancelDisable, setCancelDisable] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const location = useLocation();
  const clickedAppId = location.state?.clickedAppId;

  const { showSuccess, showError } = useToast();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [userData, setUserData] = useState({
    maxBytesContent: "",
    contentType: "",
    editorId: localStorage.getItem("userid"),
    applicationId: clickedAppId ? clickedAppId : "",
    deploymentType: "DEV",
    deploymentId: "",
  });
  const [userError, setUserError] = useState({
    maxBytesContent: false,
    maxBytesContentMsg: "",
    contentType: false,
    contentTypeMsg: "",
  });

  const areAllFieldsFilled =
    userError.maxBytesContentMsg === "" && userError.contentTypeMsg === "";
  const handleInputChange = (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    setCancelDisable(false);
    if (e.target.name === "maxBytesContent") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          maxBytesContent: false,
          maxBytesContentMsg: "",
        });
        setClickDisable(false);
      } else {
        if (
          regexPatterns.maxBytesContentPattern.test(e.target.value) === false
        ) {
          setUserError({
            ...userError,
            maxBytesContent: true,
            maxBytesContentMsg: "Invalid Format",
          });
          setClickDisable(true);
        } else {
          setUserError({
            ...userError,
            maxBytesContent: false,
            maxBytesContentMsg: "",
          });
          setClickDisable(false);
        }
      }
    } else if (e.target.name === "contentType") {
      if (e.target.value === "" || e.target.value.match(/^ *$/) !== null) {
        setUserError({
          ...userError,
          contentType: false,
          contentTypeMsg: "",
        });
        setClickDisable(false);
      } else {
        if (
          !regexPatterns.contentTypePattern.test(
            e.target.value.replace(/\s*,\s*/g, ",")
          )
        ) {
          setUserError({
            ...userError,
            contentType: true,
            contentTypeMsg: "Please enter a valid content type",
          });

          setClickDisable(true);
        } else {
          setUserError({
            ...userError,
            contentType: false,
            contentTypeMsg: "",
          });

          setClickDisable(false);
        }

        const contentTypeList = newData.contentType
          .replace(/\s*,\s*/g, ",")
          .split(",");

        contentTypeList.pop();

        const enteredContentType = e.target.value.split(",");

        if (
          contentTypeList.includes(
            enteredContentType[enteredContentType.length - 1].trim()
          )
        ) {
          const NewContentType = String(contentTypeList).trim();
          newData[e.target.name] = NewContentType;
        } else {
          newData[e.target.name] = String(
            e.target.value.replace(/\s*,\s*/g, ",").split(",")
          );
        }
        setClickDisable(false);
      }
    }
    setUserData(newData);
  };
  function formatBytes(bytes) {
    let decimals = 1;
    if (bytes === 0) return "0";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  const convertToBytes = (value) => {
    const units = {
      B: 1,
      KB: 1024,
      MB: 1024 * 1024,
      GB: 1024 * 1024 * 1024,
      TB: 1024 * 1024 * 1024 * 1024,
    };
    const unitMatch = value.match(/[a-zA-Z]+$/);
    let unit = "B"; // Default unit to bytes if not provided
    let number = parseFloat(value);

    if (unitMatch) {
      unit = unitMatch[0];
      number = value.replace(unit, "").trim(); // Remove the unit from the value
    }
    return number * (units[unit.toUpperCase()] || 1);
  };
  const handleSave = (e) => {
    e.preventDefault();
    let errorCount = validate(userData);
    if (errorCount === 0) {
      setLoad(true);
      setDisableButton(true);
      let payload = {
        maxBytesContent: userData?.maxBytesContent
          ? convertToBytes(userData?.maxBytesContent)
          : "",
        contentType: userData?.contentType ? userData?.contentType : "",
        editorId: localStorage.getItem("userid"),
        applicationId: clickedAppId,
        deploymentType: "DEV",
        deploymentId: userData?.deploymentId ? userData?.deploymentId : "",
      };

      Services.reachTabDetails(payload)
        .then((res) => {
          setLoad(false);
          if (res?.data.status === "success") {
            showSuccess(res?.data.message);
            appDetailsRefresh(true);
          } else {
            showError(res?.data.message);
            appDetailsRefresh(true);
          }
          handleCancel();
          setDisableButton(false);
        })
        .catch((err) => {});
      appDetailsRefresh(false);
    }
  };

  const validate = (data) => {
    const userErrors = { ...userError };
    let count = 0;
    if (data?.maxBytesContent === "") {
      userErrors.maxBytesContent = false;
      userErrors.maxBytesContentMsg = "";
    } else {
      if (
        regexPatterns.maxBytesContentPattern.test(userData?.maxBytesContent) ===
        false
      ) {
        userErrors.maxBytesContent = true;
        userErrors.maxBytesContentMsg = "Invalid Format";
        count++;
      } else {
        userErrors.maxBytesContent = false;
        userErrors.maxBytesContentMsg = "";
      }
    }
    if (data?.contentType === "") {
      userErrors.contentType = false;
      userErrors.contentTypeMsg = "";
    } else {
      if (
        regexPatterns.contentTypePattern.test(userData?.contentType) === false
      ) {
        userErrors.contentType = true;
        userErrors.contentTypeMsg =
          "Please enter a valid content type eg: test/test1.test-1";
        count++;
      } else {
        userErrors.contentType = false;
        userErrors.contentTypeMsg = "";
      }
    }

    setUserError(userErrors);
    return count;
  };

  const handleCancel = () => {
    setUserError({
      ...userError,
      maxBytesContent: false,
      maxBytesContentMsg: "",
      contentType: false,
      contentTypeMsg: "",
    });
    getReachTabDetails();
    setLoad(false);
    setClickDisable(true);
    setCancelDisable(true);
  };

  async function getReachTabDetails() {
    setLoading(true);
    Services.getSecurityTabDetails({
      applicationId: clickedAppId,
      deploymentType: "DEV",
    })
      .then((res) => {
        setLoading(false);
        if (res?.data?.applicationDeploymentId !== "") {
          setUserData({
            ...userData,
            deploymentId: res?.data?.applicationDeploymentId
              ? res?.data?.applicationDeploymentId
              : "",
            contentType: res?.data?.contentType ? res?.data?.contentType : "",
            maxBytesContent: res?.data?.maxBytesContent
              ? formatBytes(res?.data?.maxBytesContent)
              : formatBytes(res?.data?.maxBytesContent) === "0"
              ? 0
              : "",
          });
        }
      })
      .catch((err) => {});
  }

  const accessPermissionFields = () => {
    if (switchStatusContextVal) {
      if (statusContextVal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (archivedStatusContextVal) {
        return true;
      } else {
        return false;
      }
    }
  };
  useEffect(() => {
    if (refreshApi) {
      setTriggerRefresh(true);
    }
  }, [refreshApi]);

  useEffect(() => {
    if (triggerRefresh) {
      setLoading(true);
      getReachTabDetails();

      setTriggerRefresh(false);
    }
    // eslint-disable-next-line
  }, [triggerRefresh]);

  useEffect(() => {
    getReachTabDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xxl">
      <Box sx={{ width: "100%", background: "white" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: "#01537A",
                marginBottom: 4,
                position: "absolute",
                width: "6.1rem",
                left: "24px",
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab style={tabStyle} label="Data controls" {...a11yProps(0)} />
          </Tabs>
          <Divider
            sx={{
              color: "#DFDFDF",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              marginTop: "6px",
              borderBottomWidth: 1,
            }}
          ></Divider>
        </Box>
        <TabPanel value={value} index={0}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="2px"
          >
            {loading ? (
              <Loader
                type={"spokes"}
                color={"#01537A"}
                height={40}
                width={40}
              />
            ) : null}
          </Box>
          <Box
            sx={{
              width: {
                xs: "50px",
                sm: "273px",
                md: "500px",
                lg: "700px",
                xl: "1000px",
              },
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextField
              sx={{
                width: "100%",
                position: "relative",
                "& input::placeholder": {
                  color: "#757373",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  opacity: "1",
                  fontWeight: "400",
                },
              }}
              value={userData?.maxBytesContent}
              error={!!userError?.maxBytesContent}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
              name="maxBytesContent"
              variant="standard"
              placeholder="Enter the Max bytes per content object"
              helperText={
                userError?.maxBytesContent ? userError?.maxBytesContentMsg : ""
              }
            />
            {userData?.maxBytesContent !== "" && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  letterSpacing: "0.4px",
                  color: "#01537A",
                }}
              >
                Max bytes per content object
              </Typography>
            )}
            <TextField
              sx={{
                width: "100%",
                "& input::placeholder": {
                  color: "#757373",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                  opacity: "1",
                  fontWeight: "400",
                  lineHeight: "18.75px",
                },
              }}
              value={userData?.contentType}
              error={!!userError?.contentType}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {userData?.contentType ? (
                      ""
                    ) : (
                      <Tooltips type="mimeTypes">
                        <ErrorOutlineIcon
                          sx={{
                            cursor: "pointer",
                            bottom: "7px",
                            color: "#01537A",
                            fontSize: {
                              xs: "13px",
                              sm: "15px",
                              md: "19px",
                              lg: "19px",
                              xl: "19px",
                            },
                            position: "absolute",
                            left: {
                              xs: "50px",
                              sm: "214px",
                              md: "412px",
                              xl: "411px",
                            },
                          }}
                        />
                      </Tooltips>
                    )}

                    {!accessPermissionFields() && (
                      <EditOutlinedIcon sx={{ fontSize: "15px" }} />
                    )}
                  </InputAdornment>
                ),
                readOnly: accessPermissionFields() && true,
              }}
              name="contentType"
              placeholder="Mime Types to be blocked (Multiple types can be added)"
              variant="standard"
              helperText={
                userError?.contentType ? userError?.contentTypeMsg : ""
              }
            />
            {userData?.contentType !== "" && (
              <Box
                sx={{
                  display: "flex",
                  marginTop: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    letterSpacing: "0.4px",
                    color: "#01537A",
                    lineHeight: "18.75px",
                  }}
                >
                  Mime Types to be blocked (Multiple types can be added)
                </Typography>

                <Tooltips type="mimeTypes">
                  <ErrorOutlineIcon
                    sx={{
                      marginLeft: "15px",
                      color: "#01537A",
                      fontSize: "19px",
                      cursor: "pointer",
                    }}
                  />
                </Tooltips>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",

              justifyContent: "space-between",
              paddingTop: "30px",
              paddingRight: "20px",
              paddingBottom: "30px",
            }}
          >
            <Button
              className={accessPermissionFields() ? "disable" : null}
              onClick={handleCancel}
              disabled={cancelDisable}
              sx={{
                "&.Mui-disabled": {
                  color: "#757373",
                  textTransform: "capitalize",
                  padding: " 5px 40px 5px 40px",
                  borderColor: "#757373 !important",
                },
                padding: " 5px 40px 5px 40px",
                width: "fit-content",

                color: accessPermissionFields() ? "#373737" : "#01537A",
                borderColor: accessPermissionFields()
                  ? "#373737"
                  : "#01537A !important",
                textTransform: "capitalize",
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              className={
                accessPermissionFields() || disableButton ? "disable" : null
              }
              onClick={handleSave}
              disabled={!areAllFieldsFilled || clickDisable}
              sx={{
                padding: " 0px 50px 0px 50px",
                width: "fit-content",
                background: accessPermissionFields()
                  ? "#373737!important"
                  : "#01537A !important",
                textTransform: "capitalize",
                "&.Mui-disabled": {
                  background: "#757373 !important",
                  color: "white",
                  textTransform: "capitalize",
                  padding: "0px 50px",
                },
              }}
              variant="contained"
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <span style={{ marginRight: "5px" }}>Save</span>
                {load ? (
                  <Loader
                    type={"spinningBubbles"}
                    color={"white"}
                    height={20}
                    width={20}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Button>
          </Box>
        </TabPanel>
      </Box>
    </Container>
  );
}
