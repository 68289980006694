import React from "react";
import { Box, Typography } from "@mui/material";
function Banner() {
  return (
    <Box
      style={{
        background: "#373737",
        padding: "15px",
        color: "#FFFFFF",
        textAlign: "center",
      }}
    >
      <Typography sx={{fontWeight: 500,fontSize: {xs: "14px", md:"15px", lg: "17px"}}}>
        This account has been suspended. You will be able to view everything,
        but no changes can be made. To change this, please contact support.
      </Typography>
    </Box>
  );
}

export default Banner;
