import React, { useState, useContext, useEffect } from "react";
import { Button, Box, Typography } from "@mui/material";
import { CardContent, Card } from "@mui/material";
import dataFreeLogo1 from "../assets/img/dataFreeLogo1.svg";
import dataFreePortalLogo from "../assets/img/datafreeportallogo .png";
import Services from "../api/services";
import Loader from "../components/loader";
import {
  statusDispatchContext,
  switchStatusDispatchContext,
} from "../context/serviceContext";
import { useNavigate } from "react-router-dom";
import { WarningMessages } from "../config/messages";
import useToast from "../hooks/useToast";
import {
  getRandomString,
  hashToBase64url,
  encryptStringWithSHA256,
} from "../utils/formatNumber";

const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
const CONGNITO_APP_CLIENT_ID = process.env.REACT_APP_CONGNITO_APP_CLIENT_ID;
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export default function Login() {
  let switchStatusDispatchContextVal = useContext(switchStatusDispatchContext);
  let statusDispatchContextVal = useContext(statusDispatchContext);
  const [load, setLoad] = useState(false);
  const [clickDisable, setClickDisable] = useState(false);
  let navigate = useNavigate();

  const { showWarning } = useToast();

  const cognitoLogin = async () => {
    setLoad(true);
    setClickDisable(true);
    var state = getRandomString();
    sessionStorage.setItem("pkce_state", state);
    var code_verifier = getRandomString();
    sessionStorage.setItem("code_verifier", code_verifier);
    var arrayHash = await encryptStringWithSHA256(code_verifier);
    var code_challenge = hashToBase64url(arrayHash);
    sessionStorage.setItem("code_challenge", code_challenge);
    window.location.href =
      "https://" +
      COGNITO_DOMAIN +
      "/oauth2/authorize?response_type=code&state=" +
      state +
      "&client_id=" +
      CONGNITO_APP_CLIENT_ID +
      "&redirect_uri=" +
      REDIRECT_URL +
      "&scope=openid&code_challenge_method=S256&code_challenge=" +
      code_challenge;
  };

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  const logout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href =
      "https://" +
      COGNITO_DOMAIN +
      "/logout?client_id=" +
      CONGNITO_APP_CLIENT_ID +
      "&logout_uri=" +
      REDIRECT_URL;
  };

  const setSession = async (accessTokenRes) => {
    sessionStorage.setItem("access_token", accessTokenRes.status.access_token);
    sessionStorage.setItem(
      "refresh_token",
      accessTokenRes.status.refresh_token
    );
    sessionStorage.setItem("id_token", accessTokenRes.status.id_token);
  };

  const getAccessToken = async () => {
    if (!code) return;
    setLoad(true);
    setClickDisable(true);
    const state = urlParams.get("state");
    if (sessionStorage.getItem("pkce_state") !== state) {
      logout();
      return;
    }

    const data = {
      code_verifier: sessionStorage.getItem("code_verifier"),
      grant_type: "authorization_code",
      client_id: CONGNITO_APP_CLIENT_ID,
      redirect_uri: REDIRECT_URL,
      code: urlParams.get("code"),
    };

    try {
      const { data: accessTokenRes } = await Services.getAccessToken(data);
      setSession(accessTokenRes);

      const userCompanyRes = await Services.getUserCompany();

      if (userCompanyRes.status !== 200) return;
      if (userCompanyRes.data.length > 1) {
        navigate("/listRoles");
      } else if (userCompanyRes.data.length === 1) {
        const { role, companyId, userName, companyStatus, userId } =
          userCompanyRes.data[0];

        Services.auditLog({ companyId: companyId })
          .then((res) => {
            if (!localStorage.getItem("role")) {
              localStorage.setItem("role", role);
              localStorage.setItem("companyId", companyId);
              localStorage.setItem("username", userName);
              localStorage.setItem("companyStatus", companyStatus);
              localStorage.setItem("userid", userId);

              if (companyStatus === "Suspended") {
                localStorage.setItem("status", true);
                localStorage.setItem("switchStatus", true);
                switchStatusDispatchContextVal(true);
                statusDispatchContextVal(true);
              } else {
                localStorage.setItem("status", false);
                localStorage.setItem("switchStatus", false);
                switchStatusDispatchContextVal(false);
                statusDispatchContextVal(false);
              }
              window.location.href = "/";
            }
          })
          .catch((err) => {});
      } else {
        showWarning(WarningMessages?.userNotActive);
        setTimeout(logout, 4000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAccessToken();
    //eslint-disable-next-line
  }, []);

  return (
    <Box
      elevation={10}
      style={{
        height: "100vh",
        margin: "auto",
        display: "flex",
        alignItems: "center",
      }}
      sx={{
        width: { xl: "620px", md: "620px", sm: "620px", xs: "300px" },
      }}
    >
      <Card
        style={{
          width: "100%",
          background: "#fff",
        }}
      >
        <CardContent>
          <Box
            align="center"
            component="form"
            sx={{
              padding: "10px",
              "& .MuiFormControl-fullWidth ": { m: 1, width: "25ch" },
              marginTop: "7px",
            }}
            autoComplete="off"
          >
            <Box>
              <img
                alt="dataFreePortalLogo"
                src={dataFreePortalLogo}
                width={"90%"}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: {
                      xl: "32px",
                      md: "32px",
                      sm: "32px",
                      xs: "20px",
                    },
                    color: "#373737",
                    fontFamily: "Roboto",
                    letterSpacing: "-0.5px",
                  }}
                >
                  By
                </Typography>
                <img
                  style={{
                    marginTop: "7px",
                  }}
                  alt="dataFreeLogo1"
                  src={dataFreeLogo1}
                  width={"27%"}
                />
              </Box>
            </Box>
            <Button
              style={{
                background: "#01537A",
                marginTop: "15px",
                fontFamily: "Roboto Medium",
                width: "95px",
                height: "40px",
                padding: "10px 24px",
                borderRadius: "5px",
                fontSize: "14px",
                fontWeight: "400",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              sx={{
                color: "#fff !important",
                textTransform: "none",
                "&.Mui-disabled": {
                  cursor: "not-allowed !important",
                  pointerEvents: "unset !important",
                },
              }}
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              disabled={clickDisable}
              onClick={cognitoLogin}
            >
              {load ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ marginRight: "5px" }}>Loading</span>
                  <Loader
                    type={"spinningBubbles"}
                    color={"#ffff"}
                    height={20}
                    width={20}
                  />
                </Box>
              ) : (
                <span>Login</span>
              )}
            </Button>
          </Box>

          <Box
            style={{
              textAlign: "right",
              marginTop: "10px",
            }}
          ></Box>
        </CardContent>
      </Card>
    </Box>
  );
}
