import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  ButtonGroup,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ReportingIcon from "../../assets/img/reportingIcon.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useResponsive from "../../components/useResponsive";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import NewTab from "../../components/Report/newTab";
import ScheduledTab from "../../components/Report/scheduledTab";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import MonthlyTab from "../../components/Report/monthlyTab";
import Services from "../../api/services";
import dayjs from "dayjs";
import { createFilterOptions } from "@mui/material/Autocomplete";
import moment from "moment";
import DataCards from "../../components/Report/dataCards";
import BreadCrumb from "../../components/breadCrumb";
import useToast from "../../hooks/useToast";
import { CookiesProvider } from "react-cookie";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#0094CD",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0094CD",
    width: "120px",
    height: "45px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    justifyContent: "center",
    padding: "15px",
    marginBottom: "5px",
  },
}));

const tabStyle = {
  color: "#0000008A",
  fontWeight: 500,
  "&.Mui-selected": {
    color: "#01537A",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReportingDetails() {
  const { showSuccess } = useToast();
  // eslint-disable-next-line
  let navigate = useNavigate();
  const switchRole = localStorage.getItem("switchRole");
  const switchedUser = JSON.parse(localStorage.getItem("switchedUser"));
  const [value, setValue] = React.useState(0);
  const role = localStorage.getItem("role");
  const companyId = localStorage.getItem("companyId");
  const companyName = localStorage.getItem("companyname");
  const [data, setData] = useState({
    publisher: "",
    monthRange: { id: 1, label: "Last 28 days", value: "LAST 28 DAYS" },
    dataUsage: "RANGE",
    application: "all",
    selectApplication: "",
  });

  const TAB_0 = 0;
  const TAB_1 = 1;
  const TAB_2 = 2;
  const [prodApplication, setProdApplication] = useState();
  const [publisherList, setPublisherList] = useState();
  const [disableRefresh, setDisableRefresh] = useState(true);
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [scheduleResData, setScheduleResData] = useState();
  const [scheduleTotalCount, setScheduleTotalCount] = useState();
  const [scheduleSearch, setScheduleSearch] = useState();
  const [callReport, setCallReport] = useState();
  const isMobile = useResponsive("down", "md");
  const [counter, setCounter] = useState(0);
  const [view, setView] = useState("tableView");
  const [bannerDisplay, setBannerDisplay] = useState(true);
  const [search, setSearch] = useState("");
  const [resData, setResData] = useState();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [size, setSize] = useState(10);
  const [resMonthData, setResMonthData] = useState();
  const [monthloading, setMonthLoading] = useState(false);
  const [csvDatas, setCsvDatas] = useState();
  const maxDate = dayjs();
  const daysAgo = dayjs().subtract(90, "day");
  const [date, setDate] = React.useState([daysAgo, dayjs()]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
    setSize(10);
    setData({
      publisher: data?.publisher,
      monthRange: { id: 1, label: "Last 28 days", value: "LAST 28 DAYS" },
      dataUsage: data?.dataUsage,
      application: data?.application,
      selectApplication: data?.selectApplication
      ,
    });
  };
  const setType = (type) => {
    setView(type);
  };

  const handleClose = () => {
    setBannerDisplay(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleScheduleSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setScheduleSearch(e.target.value);
    }
  };

  const callScheduleReport = (data) => {
    setCallReport(data);
  };

  if (callReport) {
    getReportDetails();
    setCallReport(false);
  }

  const handleApplicationSwitch = (e) => {
    setResData();
    setDisableRefresh(false);
    const value = e.target.checked ? "singleApplication" : "all";
    if (value === "all") {
      setData({
        ...data,
        application: value,
        selectApplication: "",
      });
    } else {
      setData({
        ...data,
        application: value,
      });
    }
  };

  const handleDataUsageSwitch = (e) => {
    setDisableRefresh(false);
    const value = e.target.checked ? "TODAY" : "RANGE";
    setResData();
    setData({
      ...data,
      dataUsage: value,
    });
  };

  const { subMonths, format } = require("date-fns");

  const dateRange = [
    { id: 1, name: "Last 28 days", value: "LAST 28 DAYS" },
    { id: 5, name: "Custom (up to 90 days)", value: "CUSTOM" },
  ];

  function addMonthEntries() {
    const today = new Date();
    for (let i = 1; i <= 3; i++) {
      const targetDate = subMonths(today, i);
      const monthName = format(targetDate, "MMMM/yyyy");
      const entry = {
        id: 5 - i,
        name: monthName && moment(monthName).format("MMMM yyyy"),
        value: monthName,
      };
      dateRange.splice(1, 0, entry);
    }
  }

  addMonthEntries();
  const handleSelect = (e, value, type) => {
    e.preventDefault();
    const newData = { ...data };

    switch (type) {
      case "publisher":
        newData.publisher = value;
        if (data?.application === "all") {
          newData.selectApplication = 0;
        } else {
          newData.selectApplication = null;
        }
        break;
      case "monthRange":
        newData.monthRange = value;
        if (value?.label === "Custom") {
          setOpenDateRangePicker(true);
        } else {
          setDate([daysAgo, dayjs()]);
          setOpenDateRangePicker(false);
        }
        break;
      case "selectApplication":
        newData.selectApplication = value;
        break;
      default:
        break;
    }

    setData(newData);
  };

  const onRefresh = () => {
    setDisableRefresh(true);
    setData({
      publisher: "",
      monthRange: { id: 1, label: "Last 28 days", value: "LAST 28 DAYS" },
      dataUsage: "RANGE",
      application: "all",
      selectApplication: "",
    });
  };

  const filterOptions = createFilterOptions({
    stringify: ({ label }) => `${label}`,
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const minDate = maxDate.subtract(90, "day");
  const handleDateChange = (dates) => {
    setDate(dates);
  };
  // const powerBiUrl = () => {
  //   window.open("https://powerbi.com");
  // };
  const differenceInDays = date[1] && date[1].diff(date[0] && date[0], "day");
  const [disableDelete, setDisableDelete] = useState(false);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const handleRowDelete = (reportId) => {
    setDisableDelete(true);
    setScheduleLoading(true);
    Services.deleteReport(reportId)
      .then((res) => {
        if (res?.data?.status === "success") {
          setDisableDelete(false);
          setScheduleLoading(false);
          showSuccess(res?.data.message);
          getReportDetails();
        }
      })
      .catch((err) => {});
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearch(e.target.value);
    }
  };

  const handlePageSize = (e) => {
    setSize(e.target.value);
    setPage(0);
  };
  const changePagination = (event, value) => {
    setPage(value - 1);
  };
  const refreshData = () => {
    setCounter(0);
    setPage(0);
    setSize(10);
    switch (value) {
      case TAB_0:
        getNewReport();
        getNewGraphReport();
        break;
      case TAB_1:
        getMonthlySummaryReport();
        break;
      case TAB_2:
        getReportDetails();
        break;
      default:
        break;
    }
  };

  const areAllReportingFieldsFilled =
    data?.monthRange?.label === "Last 28 days" &&
    data?.publisher?.label === undefined &&
    data?.selectApplication?.label === undefined;
  const companyIds = () => {
    if (role === "ROLE_USER") {
      return companyId ? companyId : "";
    } else {
      if (switchRole) {
        if (switchedUser) {
          if (switchRole === "user" || switchRole === "customer") {
            return localStorage.getItem("customerId")
              ? localStorage.getItem("customerId")
              : 0;
          } else {
            return data?.publisher?.value ? data?.publisher?.value : "";
          }
        } else {
          return data?.publisher?.value ? data?.publisher?.value : "";
        }
      } else {
        return data?.publisher?.value ? data?.publisher?.value : "";
      }
    }
  };
  async function getNewReport() {
    setLoading(true);
    let payload =
      data?.dataUsage === "RANGE" && data?.monthRange?.value === "CUSTOM"
        ? {
            type: "Table",
            companyId: companyIds(),
            appId:
              data?.application === "all"
                ? data?.selectApplication?.value
                  ? data?.selectApplication?.value
                  : 0
                : data?.selectApplication?.value
                ? data?.selectApplication?.value
                : null,
            range:
              data?.dataUsage === "RANGE"
                ? data?.monthRange?.value
                : data?.dataUsage,
            startDate: date[0]
              ? `${moment(date[0].$d).format("YYYY/MM/DD")}`
              : "",
            endDate: date[1]
              ? `${moment(date[1].$d).format("YYYY/MM/DD")}`
              : "",
          }
        : (data?.dataUsage !== "RANGE" || data?.dataUsage === "RANGE") &&
          data?.monthRange?.value !== "CUSTOM"
        ? {
            type: "Table",
            companyId: companyIds(),
            appId:
              data?.application === "all"
                ? data?.selectApplication?.value
                  ? data?.selectApplication?.value
                  : 0
                : data?.selectApplication?.value
                ? data?.selectApplication?.value
                : null,
            range:
              data?.dataUsage === "RANGE"
                ? data?.monthRange?.value
                : data?.dataUsage,
          }
        : {
            type: "Table",
            companyId: companyIds(),
            appId:
              data?.application === "all"
                ? data?.selectApplication?.value
                  ? data?.selectApplication?.value
                  : 0
                : data?.selectApplication?.value
                ? data?.selectApplication?.value
                : null,
            range:
              data?.dataUsage === "RANGE"
                ? data?.monthRange?.value
                : data?.dataUsage,
          };
    Services.getNewReport(payload)
      .then((res) => {
        if (res?.status === 200) {
          setResData(res?.data);
          setLoading(false);
          setTotalCount(res?.data?.totalCount);
          let csvData = [];
          setCsvDatas("");
          switch (true) {
            case data?.application === "all" && data?.dataUsage === "TODAY":
              res?.data?.resultData.forEach((item) => {
                let object = {
                  hourOfDay: item?.hourofday ? item?.hourofday : 0,
                  uploadDatafree: item?.uploaddatafree
                    ? item?.uploaddatafree
                    : 0,
                  uploadPaid: item?.uploadpaid ? item?.uploadpaid : 0,
                  uploadWifi: item?.uploadwifi ? item?.uploadwifi : 0,
                  uploadOther: item?.uploadunclassified
                    ? item?.uploadunclassified
                    : 0,
                  downloadDatafree: item?.downloaddatafree
                    ? item?.downloaddatafree
                    : 0,
                  downloadPaid: item?.downloadpaid ? item?.downloadpaid : 0,
                  downloadWifi: item?.downloadwifi ? item?.downloadwifi : 0,
                  downloadOther: item?.downloadunclassified
                    ? item?.downloadunclassified
                    : 0,
                  totalUsage: item?.totaldatamb ? item?.totaldatamb : 0,
                };
                csvData.push(object);
              });
              setCsvDatas(csvData);
              break;
            case data?.application === "all" && data?.dataUsage !== "TODAY":
              res?.data?.resultData.forEach((item) => {
                let object = {
                  date: item?.date ? moment(item?.date).format("YYYY/MM") : "",
                  datafree: item.free ? item.free : 0,
                  paid: item.paid ? item.paid : 0,
                  wifi: item.wifi ? item.wifi : 0,
                  total: item.total ? item.total : 0,
                  other: item.other ? item.other : 0,
                };
                csvData.push(object);
              });
              setCsvDatas(csvData);
              break;
            case data?.application === "singleApplication" &&
              data?.dataUsage === "TODAY":
              res?.data?.resultData.forEach((item) => {
                let object = {
                  hourOfDay: item?.hourofday
                    ? moment(item?.hourofday).format("YYYY/MM")
                    : "",
                  uploadDatafree: item?.uploaddatafree
                    ? item?.uploaddatafree
                    : 0,
                  uploadPaid: item?.uploadpaid ? item?.uploadpaid : 0,
                  uploadWifi: item?.uploadwifi ? item?.uploadwifi : 0,
                  uploadOther: item?.uploadunclassified
                    ? item?.uploadunclassified
                    : 0,
                  downloadDatafree: item?.downloaddatafree
                    ? item?.downloaddatafree
                    : 0,
                  downloadPaid: item?.downloadpaid ? item?.downloadpaid : 0,
                  downloadWifi: item?.downloadwifi ? item?.downloadwifi : 0,
                  downloadOther: item?.downloadunclassified
                    ? item?.downloadunclassified
                    : 0,
                  totalUsage: item?.totaldatamb ? item?.totaldatamb : 0,
                };
                csvData.push(object);
              });
              setCsvDatas(csvData);
              break;
            case data?.application === "singleApplication" &&
              data?.dataUsage !== "TODAY":
              res?.data?.resultData.forEach((item) => {
                let object = {
                  month: item?.date ? moment(item?.date).format("YYYY/MM") : "",
                  usersTotal: item?.totalusers ? item?.totalusers : 0,
                  usersNew: item?.newusers ? item?.newusers : 0,
                  usersExisting: item?.existingusers ? item?.existingusers : 0,
                  datafree: item?.free ? item?.free : 0,
                  paid: item?.paid ? item?.paid : 0,
                  wifi: item?.wifi ? item?.wifi : 0,
                  other: item?.other ? item?.other : 0,
                  totalUsage: item?.total ? item?.total : 0,
                  usagePerUser: item?.mbperuser ? item?.mbperuser : 0,
                };
                csvData.push(object);
              });
              setCsvDatas(csvData);
              break;
            default:
              break;
          }
        }
      })
      .catch((err) => {});
  }
  async function getNewGraphReport() {
    setLoading(true);
    let payload =
      data?.dataUsage === "RANGE" && data?.monthRange?.value === "CUSTOM"
        ? {
            type: "Graph",
            companyId: companyIds(),
            appId:
              data?.application === "all"
                ? data?.selectApplication?.value
                  ? data?.selectApplication?.value
                  : 0
                : data?.selectApplication?.value
                ? data?.selectApplication?.value
                : null,
            range:
              data?.dataUsage === "RANGE"
                ? data?.monthRange?.value
                : data?.dataUsage,
            startDate: date[0]
              ? `${moment(date[0].$d).format("YYYY/MM/DD")}`
              : "",

            endDate: date[1]
              ? `${moment(date[1].$d).format("YYYY/MM/DD")}`
              : "",
          }
        : (data?.dataUsage !== "RANGE" || data?.dataUsage === "RANGE") &&
          data?.monthRange?.value !== "CUSTOM"
        ? {
            type: "Graph",
            companyId: companyIds(),
            appId:
              data?.application === "all"
                ? data?.selectApplication?.value
                  ? data?.selectApplication?.value
                  : 0
                : data?.selectApplication?.value
                ? data?.selectApplication?.value
                : null,
            range:
              data?.dataUsage === "RANGE"
                ? data?.monthRange?.value
                : data?.dataUsage,
          }
        : {
            type: "Graph",
            companyId: companyIds(),
            appId:
              data?.application === "all"
                ? data?.selectApplication?.value
                  ? data?.selectApplication?.value
                  : 0
                : data?.selectApplication?.value
                ? data?.selectApplication?.value
                : null,
            range:
              data?.dataUsage === "RANGE"
                ? data?.monthRange?.value
                : data?.dataUsage,
          };
    Services.getNewGraphReport(payload)
      .then((res) => {
        if (res?.status === 200) {
          setResData(res?.data);
          setLoading(false);
          setTotalCount(res?.data?.totalCount);
          let csvData = [];
          setCsvDatas("");
          switch (true) {
            case data?.application === "all" && data?.dataUsage === "TODAY":
              res?.data?.resultData.forEach((item) => {
                let object = {
                  hourOfDay: item?.hourofday ? item?.hourofday : "",
                  uploadDatafree: item?.uploaddatafree
                    ? item?.uploaddatafree
                    : 0,
                  uploadPaid: item?.uploadpaid ? item?.uploadpaid : 0,
                  uploadWifi: item?.uploadwifi ? item?.uploadwifi : 0,
                  uploadOther: item?.uploadunclassified
                    ? item?.uploadunclassified
                    : 0,
                  downloadDatafree: item?.downloaddatafree
                    ? item?.downloaddatafree
                    : 0,
                  downloadPaid: item?.downloadpaid ? item?.downloadpaid : 0,
                  downloadWifi: item?.downloadwifi ? item?.downloadwifi : 0,
                  downloadOther: item?.downloadunclassified
                    ? item?.downloadunclassified
                    : 0,
                  totalUsage: item?.totaldatamb ? item?.totaldatamb : 0,
                };
                csvData.push(object);
              });
              setCsvDatas(csvData);
              break;
            case data?.application === "all" && data?.dataUsage !== "TODAY":
              res?.data?.resultData.forEach((item) => {
                let object = {
                  date: item?.date ? moment(item?.date).format("YYYY/MM") : "",
                  datafree: item.free ? item.free : 0,
                  paid: item.paid ? item.paid : 0,
                  wifi: item.wifi ? item.wifi : 0,
                  total: item.total ? item.total : 0,
                  other: item.other ? item.other : 0,
                };
                csvData.push(object);
              });
              setCsvDatas(csvData);
              break;
            case data?.application === "singleApplication" &&
              data?.dataUsage === "TODAY":
              res?.data?.resultData.forEach((item) => {
                let object = {
                  hourOfDay: item?.hourofday
                    ? moment(item?.hourofday).format("YYYY/MM")
                    : "",
                  uploadDatafree: item?.uploaddatafree
                    ? item?.uploaddatafree
                    : 0,
                  uploadPaid: item?.uploadpaid ? item?.uploadpaid : 0,
                  uploadWifi: item?.uploadwifi ? item?.uploadwifi : 0,
                  uploadOther: item?.uploadunclassified
                    ? item?.uploadunclassified
                    : 0,
                  downloadDatafree: item?.downloaddatafree
                    ? item?.downloaddatafree
                    : 0,
                  downloadPaid: item?.downloadpaid ? item?.downloadpaid : 0,
                  downloadWifi: item?.downloadwifi ? item?.downloadwifi : 0,
                  downloadOther: item?.downloadunclassified
                    ? item?.downloadunclassified
                    : 0,
                  totalUsage: item?.totaldatamb ? item?.totaldatamb : 0,
                };
                csvData.push(object);
              });
              setCsvDatas(csvData);
              break;
            case data?.application === "singleApplication" &&
              data?.dataUsage !== "TODAY":
              res?.data?.resultData.forEach((item) => {
                let object = {
                  month: item?.date ? moment(item?.date).format("YYYY/MM") : "",
                  usersTotal: item?.totalusers ? item?.totalusers : 0,
                  usersNew: item?.newusers ? item?.newusers : 0,
                  usersExisting: item?.existingusers ? item?.existingusers : 0,
                  datafree: item?.free ? item?.free : 0,
                  paid: item?.paid ? item?.paid : 0,
                  wifi: item?.wifi ? item?.wifi : 0,
                  other: item?.other ? item?.other : 0,
                  totalUsage: item?.total ? item?.total : 0,
                  usagePerUser: item?.mbperuser ? item?.mbperuser : 0,
                };
                csvData.push(object);
              });
              setCsvDatas(csvData);
              break;
            default:
              break;
          }
        }
      })
      .catch((err) => {});
  }

  async function getProdAppList() {
    const appCompanyId = () => {
      if (role === "ROLE_USER") {
        return companyId ? companyId : 0;
      } else {
        if (switchRole) {
          if (switchedUser) {
            if (switchRole === "user" || switchRole === "customer") {
              return localStorage.getItem("customerId")
                ? localStorage.getItem("customerId")
                : 0;
            } else {
              return data?.publisher?.value ? data?.publisher?.value : 0;
            }
          } else {
            return data?.publisher?.value ? data?.publisher?.value : 0;
          }
        } else {
          return data?.publisher?.value ? data?.publisher?.value : 0;
        }
      }
    };
    Services.prodAppList({
      companyId: appCompanyId(),
    })
      .then((res) => {
        if (res?.status === 200) {
          setProdApplication(res?.data?.applications);
        }
      })
      .catch((err) => {});
  }
  const [companyData, setCompanyData] = useState();
  const companyDetails = () => {
    if (switchedUser) {
      const companyId = () => {
        setPageLoading(true);
        if (switchedUser) {
          switch (switchRole) {
            case "licensee":
              return localStorage.getItem("licenseeId");
            case "channel":
              return localStorage.getItem("channelId");
            case "user":
            case "customer":
              return localStorage.getItem("customerId");
            default:
              break;
          }
        } else {
          setPageLoading(false);
          return localStorage.getItem("companyId");
        }
      };
      Services.LicenseeAccountSettings({ companyId: companyId() })
        .then((res) => {
          setPageLoading(false);
          setCompanyData(res?.data);
        })
        .catch((err) => {});
    }
  };
  const [breadCrumbData, setBreadCrumbData] = useState();
  async function getMonthlySummaryReport() {
    setMonthLoading(true);
    Services.getMonthlySummaryReport({
      companyId: companyIds(),
      appId:
        data?.application === "all"
          ? data?.selectApplication?.value
            ? data?.selectApplication?.value
            : 0
          : data?.selectApplication?.value
          ? data?.selectApplication?.value
          : null,
    })
      .then((res) => {
        let csvData = [];
        setCsvDatas("");
        if (res?.status === 200) {
          setResMonthData(res?.data);
          setMonthLoading(false);
          res?.data?.finalReportResponse.forEach((item) => {
            let object = {
              month: item?.month ? moment(item?.month).format("YYYY/MM") : "",
              uploadDatafree: item?.uploaddatafreeMB
                ? item?.uploaddatafreeMB
                : 0,
              uploadPaid: item?.uploadPaidMB ? item?.uploadPaidMB : 0,
              uploadWifi: item?.uploadWiFiMB ? item?.uploadWiFiMB : 0,
              uploadOther: item?.uploadUnclassifiedMB
                ? item?.uploadUnclassifiedMB
                : 0,
              downloadDatafree: item?.downloaddatafreeMB
                ? item?.downloaddatafreeMB
                : 0,
              downloadPaid: item?.downloadPaidMB ? item?.downloadPaidMB : 0,
              downloadWifi: item?.downloadWiFiMB ? item?.downloadWiFiMB : 0,
              downloadOther: item?.downloadUnclassifiedMB
                ? item?.downloadUnclassifiedMB
                : 0,
              totalUsage: item?.totalDataMB ? item?.totalDataMB : 0,
            };
            csvData.push(object);
          });
          setCsvDatas(csvData);
        }
      })
      .catch((err) => {});
  }
  async function getReportDetails() {
    const companyId = () => {
      if (switchedUser) {
        switch (switchRole) {
          case "licensee":
            return localStorage.getItem("licenseeId");
          case "channel":
            return localStorage.getItem("channelId");
          case "user":
          case "customer":
            return localStorage.getItem("customerId");
          default:
            return localStorage.getItem("companyId");
        }
      } else {
        setLoading(false);
        return localStorage.getItem("companyId");
      }
    };

    setScheduleLoading(true);
    let payload = {
      companyId: companyId(),
      metadata: scheduleSearch ? scheduleSearch : "",
    };
    Services.getReportDetails(payload, page, size)
      .then((res) => {
        if (res?.status === 200) {
          setScheduleResData(res?.data?.scheduleReports);
          setScheduleTotalCount(res?.data?.totalCount);
          setScheduleLoading(false);
        }
      })
      .catch((err) => {});
  }

  const setCompanyId = () => {
    if (switchRole) {
      switch (switchRole) {
        case "licensee":
          if (switchedUser) {
            return localStorage.getItem("licenseeId");
          } else {
            return localStorage.getItem("companyId");
          }
        case "channel":
          if (switchedUser) {
            return localStorage.getItem("channelId");
          } else {
            return localStorage.getItem("companyId");
          }
        case "user":
        case "customer":
          if (switchedUser) {
            return localStorage.getItem("customerId");
          } else {
            return localStorage.getItem("companyId");
          }
        default:
          return localStorage.getItem("companyId");
      }
    } else {
      return localStorage.getItem("companyId");
    }
  };
  async function getPublisherCompany() {
    Services.publisherCompany({
      companyId: setCompanyId(),
    })
      .then((res) => {
        if (res?.status === 200) {
          setPublisherList(res?.data?.publishers);
        }
      })
      .catch((err) => {});
  }
  const getBreadCrumbList = () => {
    setPageLoading(true);
    Services.breadCrumbList(localStorage.getItem("companyId"))
      .then((res) => {
        setBreadCrumbData(res?.data);
        setPageLoading(false);
      })

      .catch((err) => {});
  };
  useEffect(() => {
    getReportDetails();
    // eslint-disable-next-line
  }, [scheduleSearch, page, size]);

  useEffect(() => {
    setLoading(true);
    getBreadCrumbList();
  }, []);

  useEffect(() => {
    getPublisherCompany();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    switch (value) {
      case TAB_0:
        if (view === "chartView") {
          getNewGraphReport();
        } else {
          getNewReport();
        }
        break;
      case TAB_1:
        getMonthlySummaryReport();
        break;
      case TAB_2:
        getReportDetails();
        break;
      default:
        break;
    }

    // eslint-disable-next-line
  }, [
    value,
    data?.publisher,
    data?.application,
    data?.selectApplication,
    data?.monthRange,
    search,
    data?.dataUsage,
    date,
    page,
    size,
    view,
    scheduleSearch,
  ]);

  useEffect(() => {
    getProdAppList();
    // eslint-disable-next-line
  }, [data?.publisher]);

  useEffect(() => {
    companyDetails();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (switchRole) {
      getProdAppList();
      companyDetails();
      getReportDetails();
      getBreadCrumbList();
      getPublisherCompany();
      onRefresh();
      refreshData();
    }
    //eslint-disable-next-line
  }, [switchedUser]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (counter === 59) {
        setCounter(0);
      } else {
        setCounter((counter) => counter + 1);
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };

    //eslint-disable-next-line
  }, [counter]);

  return (
    <CookiesProvider>
      <Layout>
        <Container maxWidth="xxl">
          {/* breadcrumb and button */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="2px"
          ></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              component={"span"}
              sx={{
                color: "#5A5656",
                fontWeight: "400",
                lineHeight: "42.61px",
                width: "300px",
                height: "18px",
                marginBottom: "30px",
                fontSize: { xs: "10px", md: "12px" },
              }}
            >
              {switchedUser ? (
                companyData?.parentCompany && !pageLoading ? (
                  <BreadCrumb value={companyData} other="Reporting" />
                ) : (
                  ""
                )
              ) : breadCrumbData?.parentCompany && !pageLoading ? (
                <BreadCrumb value={breadCrumbData} other="Reporting" />
              ) : (
                ""
              )}
            </Box>
          </Box>
          {/* heading and refresh  */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p="10px 0px 0px 0px"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                sx={{
                  background: "#fff",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50px",
                  color: "#01537A",
                  fontSize: "54px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="ReportingIcon"
                  src={ReportingIcon}
                  rounded="lg"
                  width="22px"
                  height="22px"
                />
              </Paper>
              <Typography
                sx={{
                  marginLeft: "15px",
                  color: "#01537A",
                  fontWeight: "700",
                  fontSize: {
                    xs: "14px",
                    sm: "22px",
                    md: "32px",
                    lg: "32px",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "capitalize",
                  cursor: "pointer",
                }}
              >
                Reporting
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <BootstrapTooltip title="Since last refresh" placement="top">
                <RefreshIcon
                  sx={{
                    fontWeight: "600",
                    fontSize: "20px",
                    color: "#01537A",
                    cursor: "pointer",
                    "&:focus": {
                      animation: "spin 2s linear infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(360deg)",
                        },
                        "100%": {
                          transform: "rotate(0deg)",
                        },
                      },
                    },
                  }}
                  onClick={refreshData}
                />
              </BootstrapTooltip>
              <Typography
                sx={{
                  marginLeft: "5px",
                  color: "#01537A",
                  fontWeight: "500",
                  fontSize: { xs: "9px", md: "12px", lg: "14px" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Last refresh :{" "}
                {counter === 0
                  ? "Just now"
                  : counter > 1
                  ? counter + " minutes ago"
                  : counter + " minute ago"}
              </Typography>
            </Box>
          </Box>
          {value === 1 && (
            <DataCards data={resMonthData} loading={monthloading} />
          )}

          {/* tab panel */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <Tabs
              sx={{
                maxwidth: {
                  xs: "402px",
                  md: "613px",
                  lg: "1440px",
                  xl: "1440px",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#01537A",
                },
              }}
              variant="scrollable"
              scrollButtons={isMobile ? true : false}
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tabStyle} label="Data Usage" {...a11yProps(0)} />
              <Tab sx={tabStyle} label="Monthly Summary" {...a11yProps(1)} />
              <Tab sx={tabStyle} label="Scheduled" {...a11yProps(2)} />
            </Tabs>
            {value !== 2 && (
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: "14px", margin: "10px", color: "#757373" }}
                >
                  View:
                </Typography>
                <ButtonGroup variant="outlined">
                  <Button
                    sx={{
                      width: "45px",
                      height: "40px",
                      border:
                        view === "chartView"
                          ? "1.5px solid #01537A!important"
                          : "1.5px solid #757373!important",
                    }}
                    onClick={() => setType("chartView")}
                  >
                    <ShowChartIcon
                      sx={{
                        color:
                          view === "chartView"
                            ? " #01537A!important"
                            : " #757373!important",
                      }}
                    />
                  </Button>
                  <Button
                    sx={{
                      width: "45px",
                      height: "40px",
                      border:
                        view === "tableView"
                          ? "1.5px solid #01537A!important"
                          : "1.5px solid #757373!important",
                    }}
                    onClick={() => setType("tableView")}
                  >
                    <TableRowsOutlinedIcon
                      sx={{
                        color:
                          view === "tableView"
                            ? " #01537A!important"
                            : " #757373!important",
                      }}
                    />
                  </Button>
                </ButtonGroup>
              </Box>
            )}
          </Box>

          <TabPanel
            sx={{
              "& .css-19kzrtu": {
                padding: "0px",
                paddingTop: "13px",
              },
            }}
            value={value}
            index={0}
          >
            <NewTab
              totalCount={totalCount}
              viewType={view}
              role={role}
              prodApplication={prodApplication}
              publisherList={publisherList}
              disableRefresh={disableRefresh}
              openDateRangePicker={openDateRangePicker}
              handleApplicationSwitch={handleApplicationSwitch}
              handleSelect={handleSelect}
              data={data}
              filterOptions={filterOptions}
              handleKeyPress={handleKeyPress}
              createFilterOptions={createFilterOptions}
              handleDataUsageSwitch={handleDataUsageSwitch}
              dateRange={dateRange}
              onRefresh={onRefresh}
              date={date}
              handleDateChange={handleDateChange}
              maxDate={maxDate}
              minDate={minDate}
              setOpenDateRangePicker={setOpenDateRangePicker}
              differenceInDays={differenceInDays}
              resData={resData}
              loading={loading}
              handleSearch={handleSearch}
              size={size}
              handlePageSize={handlePageSize}
              changePagination={changePagination}
              page={page}
              csvDatas={csvDatas}
              areAllReportingFieldsFilled={areAllReportingFieldsFilled}
              companyName={companyName}
              range={
                data?.monthRange?.value
                  ? data?.monthRange?.value
                  : "LAST 28 DAYS"
              }
              startDate={
                date[0] ? `${moment(date[0].$d).format("YYYY/MM/DD")}` : ""
              }
              endDate={
                date[1] ? `${moment(date[1].$d).format("YYYY/MM/DD")}` : ""
              }
            />
          </TabPanel>
          <TabPanel
            sx={{
              "& .css-19kzrtu": {
                padding: "0px",
                paddingTop: "40px",
              },
            }}
            value={value}
            index={1}
          >
            <MonthlyTab
              viewType={view}
              role={role}
              handleSelect={handleSelect}
              data={data}
              publisherList={publisherList}
              filterOptions={filterOptions}
              handleKeyPress={handleKeyPress}
              handleApplicationSwitch={handleApplicationSwitch}
              prodApplication={prodApplication}
              handleDataUsageSwitch={handleDataUsageSwitch}
              dateRange={dateRange}
              disableRefresh={disableRefresh}
              onRefresh={onRefresh}
              resData={resMonthData}
              loading={monthloading}
              areAllReportingFieldsFilled={areAllReportingFieldsFilled}
              csvDatas={csvDatas}
              companyName={companyName}
            />
          </TabPanel>
          <TabPanel
            sx={{
              "& .css-19kzrtu": {
                padding: "0px",
                paddingTop: "35px",
              },
            }}
            value={value}
            index={2}
          >
            <ScheduledTab
              handleRowDelete={handleRowDelete}
              handleScheduleSearch={handleScheduleSearch}
              scheduleSearch={scheduleSearch}
              handleClickOpen={handleClickOpen}
              scheduleResData={scheduleResData}
              scheduleLoading={scheduleLoading}
              handleClickClose={handleClickClose}
              callScheduleReport={callScheduleReport}
              open={open}
              scheduleTotalCount={scheduleTotalCount}
              size={size}
              handlePageSize={handlePageSize}
              changePagination={changePagination}
              page={page}
              role={role}
              disableDelete={disableDelete}
              setConfirmPopUp={setConfirmPopUp}
              confirmPopUp={confirmPopUp}
            />
          </TabPanel>
        </Container>
      </Layout>
      <Box
        sx={{
          width: "100%",
          height: "100px",
        }}
      ></Box>
      {bannerDisplay && (
        <Box
          sx={{
            background: "#01537A",
            padding: "10px",
            color: "#FFFFFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "50px",
            bottom: "0px",
            width: "100%",
            position: "fixed",
            zIndex: "999",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InfoOutlinedIcon sx={{ fontSize: "35px" }} />
            <Typography
              sx={{
                marginLeft: "30px",
              }}
            >
              This is an important message that has been posted.
              <span
                onClick={() => window.open("https://pub.bi.nu/", "_blank")}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Click here to read more.
              </span>
            </Typography>
          </Box>
          <Box>
            <CloseSharpIcon
              sx={{
                cursor: "pointer",
                marginLeft: "50px",
              }}
              onClick={handleClose}
            />
          </Box>
        </Box>
      )}
    </CookiesProvider>
  );
}
