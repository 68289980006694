import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useToast = () => {
  const showSuccess = (message) => toast.success(message);
  const showError = (message) => toast.error(message);
  const showWarning = (message) => toast.warning(message);

  return { showSuccess, showError, showWarning };
};

export default useToast;
