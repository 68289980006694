export const regexPatterns = {
    maxBytesContentPattern:
        /(?!0+$)(^\d+(\.\d+)?$)|(^\d+(\.\d{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^\d+(,\d{2,4})+(\.\d{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^\d+(,\d{2,4})+$)|(^\d+(M|G|Mb|Gb|Kb|K)$)|(^\d+(,\d{2,4})+(M|G|Mb|Gb|Kb|K)$)/i,
    contentTypePattern:
        /^[a-z]+\/(\*|[a-z]+[-+.a-z0-9]*)(,[a-z]+\/(\*|[a-z]+[-+.a-z0-9]*))*$/,
    pattern:
        /(?!0+$)(^\d+(\.\d+)?$)|(^\d+(\.\d{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^\d+(,\d{2,4})+(\.\d{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^\d+(,\d{2,4})+$)|(^\d+(M|G|Mb|Gb|Kb|K)$)|(^\d+(,\d{2,4})+(M|G|Mb|Gb|Kb|K)$)/i,
    emailRegex:
        /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,[\s]*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/,
    appNameRegex: /^[a-zA-Z0-9-_ ]+$/,
    appNameLengthRegex: /^.{0,32}$/,
    descriptionLengthRegex: /^.{0,500}$/,
    descriptionRegex: /^[A-Za-z0-9 -]+$/,
    maxBytesRegex: /^[0-9\b]+$/,
    testWhitelistRegex: /^((^\*\.)|[a-zA-Z0-9-_])*([.][.a-zA-Z0-9-_]+)$/m,
    colorRegex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
    appConfigRegex: /^[A-Za-z0-9 ]+$/
};
