import React from "react";
import { Helmet } from "react-helmet";

export default function Meta() {
  return (
    <Helmet>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name="description" content="Datafree uses the ‘reverse billing’ model adapted from the telecommunication industry, which enables us to deliver content from websites, apps and dedicated IP addresses to users at no mobile data cost to them, even with no airtime or data balance."/>
      <meta name="keywords" content="datafree,biNu app,reach"/> 
      <meta charset="utf-8" />
      <link rel="icon" href="/datafree_defaulticon.png" />
      <title>Datafree Portal</title>
    </Helmet>
  );
}
