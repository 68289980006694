import React from "react";
import {
  Paper,
  Box,
  Typography,
  TextField,
  FormControl,
  Divider,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";

import CloseIcon from "@mui/icons-material/Close";
import CustomCheckBox from "./customCheckBox";

export default function AdvancedFilter({
  value,
  handleTypefilter,
  type,
  typeValues,
  isAllSelected,
  handleSystemfilter,
  system,
  systemValues,
  handleKeyPress,
  filterOptions,
  companyDatas,
  ownCompanys,
  getAppsOwnCompany,
  getOwnCompany,
  load,
  statusValue,
  state,
  stateValues,
  handleStatefilter,
  handleStatusfilter,
  iconStyle,
  status,
  handleRevenueFilter,
  revenue,
  revenueType,
  setState,
  setType,
  setStatus,
  setSystem,
  setRevenue,
  handleClearFilter,
}) {
  const role = localStorage.getItem("role");
  const switchRole = localStorage.getItem("switchRole");
  const isOwnCompanysEmpty =
    ownCompanys && Object.values(ownCompanys).every((value) => value === "");

  const disableButton = () => {
    if (state && state.length) {
      return false;
    } else if (isOwnCompanysEmpty === false) {
      return false;
    } else if (type && type.length) {
      return false;
    } else if (status && status.length) {
      return false;
    } else if (system && system.length) {
      return false;
    } else if (revenue && revenue.length) {
      return false;
    } else return true;
  };

  const owningCompanyShow = () => {
    if (role === "ROLE_ADMIN" && switchRole === null) {
      if (value === 0 || value === 1 || value === 2 || value === 3) {
        return true;
      } else {
        return false;
      }
    } else if (role === "ROLE_ADMIN" && switchRole !== null) {
      if (switchRole === "channel") {
        if (value === 1) {
          return false;
        } else if (value === 0 || value === 2 || value === 3) {
          return true;
        } else {
          return false;
        }
      } else if (switchRole === "licensee") {
        if (value === 0 || value === 1 || value === 2 || value === 3) {
          return true;
        } else {
          return false;
        }
      } else if (switchRole === "user" || switchRole === "customer") {
        if (value === 0 || value === 1 || value === 2 || value === 3) {
          return false;
        } else {
          return false;
        }
      }
    } else if (role === "ROLE_LICENSEE" && switchRole === null) {
      if (value === 0 || value === 1 || value === 2 || value === 3) {
        return true;
      } else {
        return false;
      }
    } else if (role === "ROLE_LICENSEE" && switchRole !== null) {
      if (switchRole === "channel") {
        if (value === 1) {
          return false;
        } else if (value === 0 || value === 2 || value === 3) {
          return true;
        } else {
          return false;
        }
      } else if (switchRole === "licensee") {
        if (value === 0 || value === 1 || value === 2 || value === 3) {
          return true;
        } else {
          return false;
        }
      } else if (switchRole === "user" || switchRole === "customer") {
        if (value === 0 || value === 1 || value === 2 || value === 3) {
          return false;
        } else {
          return false;
        }
      }
    } else if (role === "ROLE_CHANNEL" && switchRole === null) {
      if (value === 1) {
        return false;
      } else if (value === 0 || value === 2 || value === 3) {
        return true;
      } else {
        return false;
      }
    } else if (role === "ROLE_CHANNEL" && switchRole !== null) {
      if (switchRole === "user" || switchRole === "customer") {
        if (value === 0 || value === 1 || value === 2 || value === 3) {
          return false;
        } else {
          return false;
        }
      }
    } else if (role === "ROLE_USER" && switchRole === null) {
      if (value === 0 || value === 1 || value === 2 || value === 3) {
        return false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <Paper
      elevation={2}
      sx={{
        width: { xs: "20%", sm: "30%", md: "20%" },
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        position: "sticky",
        top: "75px",
        flexShrink: "0",
        background: "var(--White, #FFF)",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25) !important",
        height: { xs: "456px", xl: "755px" },
        overflowY: "scroll",
        overflowX: "none",
        "&::-webkit-scrollbar": {
          width: "5px",
          height: "9px",
        },
        "&::-webkit-scrollbar-thumb": {
          outline: "1px solid #01537A",
          borderRadius: "6px",
          backgroundColor: "#01537A!important",
        },
      }}
    >
      <Box>
        {owningCompanyShow() && (
          <Box>
            <Typography
              sx={{
                marginLeft: "10px",
                marginBottom: "8px",
                marginTop: "16px",
                color: "rgba(1, 83, 122, 1)",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "normal",
              }}
            >
              Owning Company
            </Typography>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              loading={load}
              onChange={
                value === 0 || value === 1 || value === 2
                  ? (e, value) => getOwnCompany(e, value)
                  : (e, value) => getAppsOwnCompany(e, value)
              }
              ListboxProps={{
                sx: {
                  border: "1px solid #01537A",
                  borderRadius: "8px",
                },
              }}
              value={ownCompanys ? ownCompanys : ""}
              key={ownCompanys ? ownCompanys : ""}
              options={
                companyDatas?.map((option) => ({
                  value: option?.companyId,
                  id: option?.companyId,
                  label: option?.companyName,
                })) || []
              }
              sx={{
                color: "#1537A",
                boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: "#ffff",
                "& .MuiOutlinedInput-root": {
                  padding: "2px",
                  borderRadius: "8px",
                },
                "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                  visibility: "hidden",
                },
              }}
              getOptionLabel={(option) => option.label || ""}
              renderOption={(props, option) => {
                const { label, value } = option;
                return (
                  <span
                    {...props}
                    value={value}
                    style={{ color: "#01537A" }}
                    key={value}
                  >
                    {label}
                  </span>
                );
              }}
              filterOptions={filterOptions}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    onKeyDown={handleKeyPress}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" sx={iconStyle}>
                          <SearchIcon
                            sx={{
                              color: "#01537A",
                              fontSize: "22px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={
                      value === 1 || value === 2
                        ? "search company"
                        : "search company"
                    }
                    sx={{
                      "& .MuiAutocomplete-option": {
                        color: "#01537A",
                      },
                      "& input::placeholder": {
                        color: "#757373",
                        fontSize: "15px",
                      },
                      "& .MuiInputBase-input": {
                        borderWidth: `1px !important`,
                        position: "relative",
                        fontSize: 16,
                        padding: "1px 5px",
                        borderRadius: "8px",
                        color: "#01537A",
                      },
                      "& fieldset": {
                        borderWidth: `1px !important`,
                        padding: "0px !important",
                        borderColor: "#01537A !important",
                      },
                    }}
                  />{" "}
                </>
              )}
            />
            <Divider
              sx={{
                marginRight: "7px",
                marginLeft: "7px",
                marginTop: "20px",
                marginBottom: "25px",
                borderBottomWidth: 1,
                background: "rgba(1, 83, 122, 1)",
              }}
            />
          </Box>
        )}

        {((value === 1 &&
          (role === "ROLE_ADMIN" || role === "ROLE_LICENSEE") &&
          switchRole !== "null" &&
          switchRole !== "channel") ||
          (value === 2 &&
            role !== "ROLE_USER" &&
            switchRole !== "null" &&
            switchRole !== "user") ||
          value === 3) && (
          <Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "rgba(1, 83, 122, 1)",
                  marginLeft: "10px",
                }}
              >
                {value === 1 ? "Type" : value === 2 ? "Role" : "Type"}
              </Typography>
              <FormControl
                size="small"
                sx={{
                  m: 1,
                  minWidth: 100,
                  backgroundColor: "#ffffff",
                }}
                style={{
                  borderColor: "#01537A !important",
                  borderRadius: "6px",
                }}
              >
                <CustomCheckBox
                  handleChange={handleTypefilter}
                  selectedValue={type}
                  menuList={typeValues}
                  setSelectedValue={setType}
                />
              </FormControl>
            </Box>
            <Divider
              sx={{
                marginRight: "7px",
                marginLeft: "7px",
                marginTop: "20px",
                marginBottom: "25px",
                borderBottomWidth: 1,
                background: "rgba(1, 83, 122, 1)",
              }}
            />
          </Box>
        )}
        {value === 3 && (
          <Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "rgba(1, 83, 122, 1)",
                  marginLeft: "10px",
                }}
              >
                {value === 1 ? "Type" : value === 2 ? "Role" : "System"}
              </Typography>
              <FormControl
                size="small"
                sx={{
                  m: 1,
                  minWidth: 100,
                  backgroundColor: "#ffffff",
                }}
                style={{
                  borderColor: "#01537A !important",
                  borderRadius: "4px",
                }}
              >
                <CustomCheckBox
                  handleChange={handleSystemfilter}
                  selectedValue={system}
                  menuList={systemValues}
                  setSelectedValue={setSystem}
                />
              </FormControl>
            </Box>
            <Divider
              sx={{
                marginRight: "7px",
                marginLeft: "7px",
                marginTop: "20px",
                marginBottom: "25px",
                borderBottomWidth: 1,
                background: "rgba(1, 83, 122, 1)",
              }}
            />
          </Box>
        )}
        {(value === 0 || value === 1) && (
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "rgba(1, 83, 122, 1)",
                marginLeft: "10px",
              }}
            >
              Status
            </Typography>
            <FormControl
              size="small"
              sx={{
                m: 1,
                minWidth: 100,
                backgroundColor: "#ffffff",
              }}
              style={{
                borderColor: "#01537A",
                borderRadius: "4px",
              }}
            >
              <CustomCheckBox
                handleChange={handleStatefilter}
                selectedValue={state}
                menuList={stateValues}
                setSelectedValue={setState}
              />
            </FormControl>
          </Box>
        )}
        {(value === 2 || value === 3) && (
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "rgba(1, 83, 122, 1)",
                marginLeft: "10px",
              }}
            >
              Status
            </Typography>
            <FormControl
              size="small"
              sx={{
                m: 1,
                minWidth: 100,
                backgroundColor: "#ffffff",
              }}
              style={{
                borderColor: "#01537A",
                borderRadius: "4px",
              }}
            >
              <CustomCheckBox
                handleChange={handleStatefilter}
                selectedValue={state}
                menuList={stateValues}
                setSelectedValue={setState}
                allBox={"no"}
              />
            </FormControl>
          </Box>
        )}

        {value === 3 &&
          ((role === "ROLE_LICENSEE" &&
            switchRole !== "null" &&
            switchRole !== "channel") ||
            (switchRole !== "null" &&
              switchRole === "licensee" &&
              switchRole !== "channel")) && (
            <Box>
              <Divider
                sx={{
                  marginRight: "7px",
                  marginLeft: "7px",
                  marginTop: "20px",
                  marginBottom: "25px",
                  borderBottomWidth: 1,
                  background: "rgba(1, 83, 122, 1)",
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "rgba(1, 83, 122, 1)",
                    marginLeft: "10px",
                  }}
                >
                  Revenue Type
                </Typography>
                <FormControl
                  size="small"
                  sx={{ m: 1, minWidth: 100, backgroundColor: "#ffffff" }}
                  style={{
                    borderColor: "#01537A !important",
                    borderRadius: "4px",
                  }}
                >
                  <CustomCheckBox
                    handleChange={handleRevenueFilter}
                    selectedValue={revenue}
                    menuList={revenueType}
                    setSelectedValue={setRevenue}
                  />
                </FormControl>
              </Box>
            </Box>
          )}
        {value === 2 && (
          <Box>
            <Divider
              sx={{
                marginRight: "7px",
                marginLeft: "7px",
                marginTop: "20px",
                marginBottom: "25px",
                borderBottomWidth: 1,
                background: "rgba(1, 83, 122, 1)",
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "rgba(1, 83, 122, 1)",
                  marginLeft: "10px",
                }}
              >
                Verified
              </Typography>
              <FormControl
                size="small"
                sx={{
                  m: 1,
                  minWidth: 100,
                  backgroundColor: "#ffffff",
                }}
                style={{
                  borderColor: "#01537A",
                  borderRadius: "4px",
                }}
              >
                <CustomCheckBox
                  handleChange={handleStatusfilter}
                  selectedValue={status}
                  menuList={statusValue}
                  setSelectedValue={setStatus}
                />
              </FormControl>
            </Box>
          </Box>
        )}
        <Button
          startIcon={<CloseIcon sx={{ padding: { xs: "3px", md: "0px" } }} />}
          onClick={handleClearFilter}
          variant="outlined"
          disabled={disableButton()}
          sx={{
            "&.Mui-disabled": {
              color: "#757373 !important",
              textTransform: "none",
              padding: "2px 15px 2px 15px",
              border: "1px solid #757373!important",
            },
            color: "rgba(1, 83, 122, 1)",
            border: "1px solid rgba(1, 83, 122, 1)!important",
            marginLeft: "17px",
            marginBottom: "20px",
            textTransform: "none",
            fontSize: { xs: "10px", md: "14px" },
            marginTop: "15px",
            fontWeight: "400",
            padding: { xs: "3px", md: "2px 15px 2px 15px" },
          }}
        >
          Clear Filters
        </Button>
      </Box>
    </Paper>
  );
}
