import { Typography, Divider, Button, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Services from "../../api/services";
import { useLocation } from "react-router-dom";
import Loader from "react-loading";
import { WarningMessages } from "../../config/messages";
import useToast from "../../hooks/useToast";

export default function ConfigProd({
  open,
  handleClose,
  productType,
  onDataFromChild,
}) {
  const handleClear = () => {
    handleClose();
    setLoad(false);
  };

  const { showSuccess, showWarning } = useToast();

  const [appData, setAppData] = useState();
  const [load, setLoad] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const location = useLocation();
  const clickedAppId = location.state?.clickedAppId;

  const handleSave = () => {
    setLoad(true);
    setDisableButton(true);
    Services.cloneApplication({
      applicationId: clickedAppId,
      cloningFrom: "DEV",
      applicationType: productType.split(" ")[1].toLowerCase(),
    })
      .then((res) => {
        if (res?.data.status === "success") {
          handleClose();
          showSuccess(WarningMessages?.configCloned);
          setDisableButton(false);
          setLoad(false);
          onDataFromChild(1);
        } else {
          setDisableButton(false);
          setLoad(false);
          onDataFromChild(0);
          handleClose();
          showWarning(WarningMessages?.noDevVersion);
        }
      })
      .catch((err) => {});
  };

  async function getReachAppDetails() {
    Services.getSecurityTabDetails({
      applicationId: clickedAppId,
      deploymentType: "PROD",
    })
      .then((res) => {
        setAppData(res?.data?.applicationDeploymentId);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getReachAppDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClear}
      maxWidth="xl"
      scroll={"body"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            color: "#01537A",
            fontSize: "24px",
          }}
        >
          {" "}
          Create Production version of App
        </Typography>

        <CloseOutlinedIcon
          onClick={handleClear}
          sx={{ color: "#757373", fontSize: "20px", cursor: "pointer" }}
        />
      </DialogTitle>
      <DialogContent>
        {appData === undefined || appData === "" ? (
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              color: "#333333",
              marginBottom: "30px",
              marginTop: "10px",
            }}
          >
            Registering a production version for this config will duplicate the
            settings from the development app
            <br />
            to a new production app.There are some extra details you can add in
            the production environment
            <br />
            <br />
            Do you wish to conitune ?
          </Typography>
        ) : (
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              color: "#333333",
              marginBottom: "30px",
              marginTop: "10px",
            }}
          >
            Registering a production version for this config will duplicate the
            settings from the development app
            <br />
            to a new production app.There are some extra details you can add in
            the production environment
            <br />
            <br />
            Do you wish to conitune ?
          </Typography>
        )}

        <Divider
          sx={{
            color: "#DFDFDF",
            backgroundColor: "#FFFFFF",
            borderRadius: "6px",
            borderBottomWidth: 1,
            marginTop: "40px",
          }}
        ></Divider>
      </DialogContent>
      <DialogActions
        sx={{
          paddingBottom: "20px",
          paddingRight: "30px",
          gap: "27px",
        }}
      >
        <Button
          onClick={handleClear}
          sx={{
            padding: " 5px 50px 5px 50px",
            width: "fit-content",
            color: "#01537A",
            borderColor: "#01537A !important",
            textTransform: "capitalize",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          className={disableButton ? "disable" : null}
          onClick={handleSave}
          sx={{
            padding: "5px 50px 5px 50px",
            width: "fit-content",
            background: "#01537A !important",
            textTransform: "capitalize",
            "&.Mui-disabled": {
              background: "#757373 !important",
              color: "white",
              textTransform: "capitalize",
              padding: "0px 50px",
            },
          }}
          variant="contained"
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <span style={{ marginRight: "5px" }}>Continue</span>
            {load ? (
              <Loader
                type="spinningBubbles"
                color="white"
                delay={0}
                height={20}
                width={20}
              />
            ) : (
              ""
            )}
          </Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
