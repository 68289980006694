import React from "react";
import completedIcon from "../../assets/img/completedIcon.svg";
import failedIcon from "../../assets/img/failedIcon.svg";
import moment from "moment";
import { Divider, Box, Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Loader from "../../components/loader";
export default function GetAppStatus({
  appStatus,
  getAppStatusData,
  handleAppStatusClose,
  timeInWords,
  statusLoading,
}) {
  return (
    <Dialog
      maxWidth="xl"
      open={appStatus}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "#01537A",
              fontWeight: "400",
              fontSize: "24px",
              lineHeight: "26px",
              width: "253px",
              height: "27px",
              marginTop: "10px",
            }}
          >
            Get App Status
          </Typography>
          <CloseSharpIcon
            onClick={handleAppStatusClose}
            sx={{
              color: "#757373",
              cursor: "pointer",
            }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {statusLoading ? (
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              width: { sm: "300px", md: "300px", lg: "500px" },
              marginBottom: "20px",
            }}
          >
            <Loader
              type={"spokes"}
              color={"#01537A"}
              height={40}
              width={40}
            />
          </Box>
        ) : getAppStatusData ? (
          getAppStatusData?.message ||
          !getAppStatusData?.publishedDate ||
          getAppStatusData?.status === "failed" ? (
            <Typography
              sx={{
                marginBottom: "25px",
                color: "#757373",
              }}
            >
              You have not published your application yet. Please publish your
              application for you to see the <br />
              status of your application and to see the changes take effect.
            </Typography>
          ) : (
            <>
              <Box sx={{ display: "flex", gap: "40px" }}>
                <Box
                  sx={{
                    width: "187px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>App Id:</Typography>

                    <Typography
                      sx={{
                        color: "#757373",
                      }}
                    >
                      {getAppStatusData?.appId && getAppStatusData?.appId}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>Primary/Failover:</Typography>

                    <Typography
                      sx={{
                        color: "#757373",
                      }}
                    >
                      {getAppStatusData?.primaryFailover &&
                        getAppStatusData?.primaryFailover}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>Region:</Typography>

                    <Typography
                      sx={{
                        color: "#757373",
                      }}
                    >
                      {getAppStatusData?.region && getAppStatusData?.region}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "301px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>Published Date:</Typography>

                    <Typography
                      sx={{
                        color: "#757373",
                      }}
                    >
                      {getAppStatusData?.publishedDate
                        ? moment(
                            getAppStatusData?.publishedDate.replace(/_/g, "/")
                          ).format("DD MMM YYYY hh:mm")
                        : ""}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>Status Date</Typography>

                    <Typography
                      sx={{
                        color: "#757373",
                      }}
                    >
                      {getAppStatusData?.statusDate
                        ? moment(
                            getAppStatusData?.statusDate.replace(/_/g, "/")
                          ).format("DD MMM YYYY  hh:mm")
                        : ""}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography>Status</Typography>

                  <Box
                    sx={{
                      background:
                        getAppStatusData?.statusCode === "FAILED"
                          ? "#FF5252"
                          : "#c1d8e3",
                      width: "183px",
                      height: "56px",
                      borderRadius: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    {getAppStatusData?.statusCode ? (
                      getAppStatusData?.statusCode === "COMPLETED" ? (
                        <img src={completedIcon} alt="completedIcon" />
                      ) : getAppStatusData?.statusCode === "FAILED" ? (
                        <img src={failedIcon} alt="failedIcon" />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    <Typography
                      sx={{
                        color: "#01537A",
                        fontSize: "18px",
                        lineHeight: "19.6px",
                        textTransform: "capitalize",
                      }}
                    >
                      {getAppStatusData?.statusCode &&
                        getAppStatusData?.statusCode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "#757373",
                  paddingTop: "30px",
                  paddingBottom: "30px",
                }}
              >
                {getAppStatusData?.primaryFailover &&
                  getAppStatusData?.region &&
                  `Region ${getAppStatusData.region} - ${
                    getAppStatusData.primaryFailover
                  } completed ${timeInWords ? timeInWords : ""}`}
              </Typography>
            </>
          )
        ) : (
          <Typography
            sx={{
              marginBottom: "25px",
              color: "#757373",
            }}
          >
            You have not published your application yet. Please publish your
            application for you to see the <br />
            status of your application and to see the changes take effect.
          </Typography>
        )}

        <Divider
          sx={{
            color: "#DFDFDF",
            backgroundColor: "#FFFFFF",
            width: "100%",
            borderBottomWidth: 1,
            marginBottom: "20px",
          }}
        ></Divider>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button
            className="save-btn"
            onClick={handleAppStatusClose}
            variant="contained"
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
