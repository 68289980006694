import React,{useContext} from 'react'
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {IconButton} from "@mui/material";
import { switchStatusContext, statusContext } from '../../context/serviceContext';

export default function ModalViewButton() {
    const switchStatusContextVal = JSON.parse(useContext(switchStatusContext));
    const statusContextVal = JSON.parse(useContext(statusContext));
    return (
      <IconButton
        size="small"
        sx={{ backgroundColor: "#fff !important", boxShadow: 3 }}
      >
        <RemoveRedEyeIcon
          fontSize="inherit"
          sx={{
            color: switchStatusContextVal && statusContextVal ? "#373737 !important" : "#01537A !important",
            cursor: "pointer",
          }}
        />
      </IconButton>
    )
}
